<template>
  <div
    class="d-flex flex-column flex-grow-1"
    :class="{
      'component-wrapper':
        $route.name == 'adminMonitoringReport' ||
        $route.name == 'monitoringReport',
    }"
  >
    <v-progress-circular
      size="80"
      width="4"
      indeterminate
      color="primary"
      v-if="loader"
      class="ma-auto"
    ></v-progress-circular>

    <div
      :class="
        $route.name != 'editUserUserReport'
          ? 'pa-5'
          : !fromMonitoring
          ? 'scrollable'
          : ''
      "
      v-else
    >
      <div class="header d-flex mb-4 pt-1">
        <v-btn
          icon
          @click="onBack"
          class="back-btn"
          v-if="
            $route.name != 'editUserUserReport' &&
              $route.name != 'editUserHistoryReport'
          "
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <div
          class="text-h6"
          :class="{
            'text-center mx-auto':
              $route.name != 'editUserUserReport' &&
              $route.name != 'editUserHistoryReport',
          }"
        >
          {{
            report.questionnaire.questionnaireShortName
              ? report.questionnaire.questionnaireShortName
              : report.questionnaire.questionnaireName
          }}
          Report
        </div>
      </div>

      <v-expansion-panels
        v-if="
          $route.name != 'editUserUserReport' &&
            $route.name != 'editUserHistoryReport'
        "
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ report.user.firstName }}
            {{ report.user.lastName }}</v-expansion-panel-header
          >

          <v-expansion-panel-content>
            <div class="d-flex mb-4">
              <div class="flex-1">
                <div class="d-flex align-center mb-2">
                  <div class="subtitle-2 font-weight-medum mr-1">Identity:</div>

                  <div class="body-2">{{ report.user.identityNumber }}</div>
                </div>

                <div class="d-flex align-center mb-2">
                  <div class="subtitle-2 font-weight-medum mr-1">Position:</div>

                  <div class="body-2">{{ report.user.positionName }}</div>
                </div>
              </div>

              <div
                class="flex-1 description d-flex flex-column"
                v-if="report.questionnaire.descriptionHtml"
              >
                <div class="subtitle-1 font-weight-medium mb-4">
                  Description
                </div>

                <div class="scrollable">
                  <div class="body-2">
                    <div v-html="report.questionnaire.descriptionHtml"></div>
                  </div>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div
        class="subtitle-2 mt-4"
        v-if="report.questionnaire.questionnaireTypeId == 'ability'"
      >
        Execution Time:
        {{ dayjs.utc(report.completionTimeSec * 1000).format("mm:ss") }}
        minutes
      </div>

      <div class="d-flex align-center mt-4">
        <v-menu
          offset-y
          :close-on-content-click="false"
          v-if="
            (report.questionnaire.questionnaireTypeId == 'elearn' &&
              report.pass &&
              report.printouts &&
              report.printouts.length) ||
              (report.questionnaire.questionnaireTypeId != 'elearn' &&
                report.printouts &&
                report.printouts.length)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="white--text"
              depressed
              color="filyellow"
              small
              v-bind="attrs"
              v-on="on"
              >Create PDF
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <div class="white d-flex flex-column">
            <v-btn
              text
              v-for="pdf in report.printouts"
              :key="pdf.printoutId"
              @click="onGetReportPdf(pdf.printoutId, reportId)"
              :loading="
                getPdfLoader.open && getPdfLoader.pdfType == pdf.printoutId
              "
              >{{ pdf.printoutName }}</v-btn
            >
          </div>
        </v-menu>

        <v-menu offset-y :close-on-content-click="false" v-if="report.hasGreek">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" depressed small v-bind="attrs" v-on="on"
              >{{ activeLanguage.viewValue }}
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <div class="white d-flex flex-column">
            <v-btn
              text
              v-for="lang in languages"
              :key="lang.value"
              @click="
                lang.value != activeLanguage.value && onGetReport(lang.value)
              "
              >{{ lang.viewValue }}</v-btn
            >
          </div>
        </v-menu>

        <template v-if="$route.name == 'monitoringReport'">
          <v-checkbox
            label="Reviewed"
            class="ma-0 pa-0 ml-4"
            hide-details
            dense
            :ripple="false"
            readonly
            @click="onReviewed"
            :disabled="reviewedLoader"
            :input-value="isReviewed"
          ></v-checkbox>

          <v-menu offset-x :close-on-content-click="false" v-model="menu">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                small
                depressed
                class="ml-4"
                @click="onGetUsersToNotify"
                >Send Notification</v-btn
              >
            </template>

            <div class="white pa-2 d-flex flex-column">
              <v-list dense class="pa-0">
                <v-list-item
                  v-for="user in usersToNotify"
                  :key="user.clientUserId"
                >
                  <v-checkbox
                    :value="user.clientUserId"
                    v-model="usersToNotifySelected"
                    class="pa-0 ma-0"
                    dense
                    hide-details
                    :ripple="false"
                  ></v-checkbox>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ user.firstName }} {{ user.lastName }} ({{
                        user.userEmail
                      }})</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-btn
                small
                depressed
                @click="onSendNotif"
                :loading="sendNotifLoader"
                :disabled="!usersToNotifySelected.length"
                class="align-self-center"
                >Send Notification</v-btn
              >
            </div>
          </v-menu>
        </template>
      </div>

      <v-tabs v-model="tab" class="mb-4 mt-4">
        <v-tab v-if="report.showAnswers">Answers</v-tab>

        <v-tab v-if="report.showSummary">Summary</v-tab>

        <v-tab v-if="report.showGraph">Graph</v-tab>

        <v-tab v-if="report.showBasic">Basic Report</v-tab>

        <v-tab v-if="report.showLie">Lie Factors</v-tab>

        <template v-if="report.extraReports">
          <v-tab
            v-for="(tab, index) in report.extraReports"
            :key="`${index}- ${tab.reportId}`"
            >{{ tab.reportName }}</v-tab
          >
        </template>

        <template v-if="report.summaries && report.summaries.length">
          <v-tab
            v-for="(summary, index) in report.summaries"
            :key="`${index}- ${summary.summaryId}`"
            >{{ summary.summaryName }}</v-tab
          >
        </template>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-if="report.showAnswers">
          <FormReport />
        </v-tab-item>

        <v-tab-item v-if="report.showSummary">
          <AbilitySummary
            v-if="report.questionnaire.questionnaireTypeId == 'ability'"
          />

          <ElearningReport
            v-else-if="report.questionnaire.questionnaireTypeId == 'elearn'"
          />

          <PsychoSummary v-else />
        </v-tab-item>

        <v-tab-item v-if="report.showGraph">
          <AbilityGraph
            v-if="report.questionnaire.questionnaireTypeId == 'ability'"
          />

          <PsychoGraph v-else />
        </v-tab-item>

        <v-tab-item v-if="report.showBasic">
          <PsychoFactors :factors="report.factors" />
        </v-tab-item>

        <v-tab-item v-if="report.showLie">
          <PsychoFactors :factors="report.lieFactors" />
        </v-tab-item>

        <template v-if="report.extraReports && report.extraReports.length">
          <v-tab-item
            v-for="(tabItem, index) in report.extraReports"
            :key="`${index}- ${tabItem.tabName}`"
          >
            <PsychoExtraTabs :tab="tabItem" />
          </v-tab-item>
        </template>

        <template v-if="report.summaries && report.summaries.length">
          <v-tab-item
            v-for="(summary, index) in report.summaries"
            :key="`${index}- ${summary.summaryId}`"
          >
            <PsychoIndividualAbility :summary="summary" />
          </v-tab-item>
        </template>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import PsychoSummary from "@/views/admin/assignment/assignment-dialog/report/psycho/PsychoSummary";
import PsychoFactors from "@/views/admin/assignment/assignment-dialog/report/psycho/PsychoFactors";
import PsychoGraph from "@/views/admin/assignment/assignment-dialog/report/psycho/PsychoGraph";
import PsychoExtraTabs from "@/views/admin/assignment/assignment-dialog/report/psycho/PsychoExtraTabs";
import PsychoIndividualAbility from "@/views/admin/assignment/assignment-dialog/report/psycho/PsychoIndividualAbility";
import AbilitySummary from "@/views/admin/assignment/assignment-dialog/report/ability/AbilitySummary";
import AbilityGraph from "@/views/admin/assignment/assignment-dialog/report/ability/AbilityGraph";
import FormReport from "@/views/admin/assignment/assignment-dialog/report/FormReport";
import ElearningReport from "@/views/admin/assignment/assignment-dialog/report/ElearningReport";

export default {
  props: ["reportIdProp", "fromMonitoring"],
  components: {
    PsychoSummary,
    PsychoFactors,
    PsychoGraph,
    PsychoExtraTabs,
    AbilitySummary,
    AbilityGraph,
    ElearningReport,
    FormReport,
    PsychoIndividualAbility,
  },
  data() {
    return {
      tab: 0,
      loader: false,
      getPdfLoader: {
        open: false,
        pdfType: null,
      },
      reviewedLoader: false,
      sendNotifLoader: false,
      isReviewed: false,
      usersToNotify: null,
      menu: false,
      usersToNotifySelected: [],
      activeLanguage: null,
      languages: [
        {
          value: "en",
          viewValue: "English",
        },
        {
          value: "el",
          viewValue: "Ελληνικά",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      report: (state) => state.assignments.report,
      showReport: (state) => state.assignments.showReport,
    }),
    reportId() {
      if (
        this.$route.name == "adminMonitoringReport" ||
        this.$route.name == "monitoringReport" ||
        this.$route.name == "editUserUserReport" ||
        this.$route.name == "reportLink"
      )
        return this.$route.params.reportId;

      if (this.reportIdProp) return this.reportIdProp;

      return this.showReport.id;
    },
  },
  async created() {
    this.activeLanguage = this.languages[0];

    await this.onGetReport();
  },
  methods: {
    ...mapMutations(["toggleShowReport"]),
    ...mapActions([
      "getReport",
      "getReportPdf",
      "monitorReview",
      "monitorNotify",
      "getUsersToNotify",
    ]),
    async onGetReport(lang = "en") {
      this.loader = true;

      try {
        if (this.$route.params.reportId != null)
          await this.getReport({ reportId: this.$route.params.reportId, lang });
        else await this.getReport({ reportId: this.reportId, lang });

        this.activeLanguage = this.languages.find((l) => l.value == lang);

        this.isReviewed = this.report.isReviewed;
      } catch (e) {
        console.log(e);
      }

      this.loader = false;

      if (this.$route.name == "monitoringReport") {
        this.onGetUsersToNotify();
      }
    },
    onBack() {
      if (this.fromMonitoring) this.$emit("returnBack");
      if (this.$route.name == "monitoringReport") {
        this.$router.push({ path: "/superadmin/monitoring" });
        return;
      }

      if (this.$route.name == "adminMonitoringReport") {
        this.$router.push({ path: "/monitoring" });
        return;
      }

      if (this.$route.name == "reportLink") {
        this.$router.push({ path: "/assignment" });
        return;
      }

      if (this.reportIdProp) this.$emit("closeReport");

      this.toggleShowReport({
        open: false,
      });
    },
    async onGetReportPdf(pdfType, id) {
      this.getPdfLoader = {
        open: true,
        pdfType,
      };

      try {
        await this.getReportPdf({
          pdfType,
          id,
          lang: this.activeLanguage.value,
        });
      } catch (e) {
        console.log(e);
      }

      this.getPdfLoader = {
        open: false,
        pdfType: null,
      };
    },
    async onReviewed() {
      this.reviewedLoader = true;

      try {
        await this.monitorReview(this.$route.params.reportId);

        this.isReviewed = !this.isReviewed;
      } catch (e) {
        console.log(e);
      }

      this.reviewedLoader = false;
    },
    async onGetUsersToNotify() {
      try {
        this.usersToNotify = await this.getUsersToNotify(
          this.$route.params.reportId
        );
      } catch (e) {
        console.log(e);
      }
    },
    async onSendNotif() {
      this.sendNotifLoader = true;

      try {
        await this.monitorNotify({
          assignUserQuestId: this.$route.params.reportId,
          clientUserIds: this.usersToNotifySelected,
        });

        this.usersToNotifySelected = [];
        this.menu = false;
      } catch (e) {
        console.log(e);
      }

      this.sendNotifLoader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.border-wrapper {
  border: 1px solid #e0e0e0;
}

.description {
  height: 200px;
}
</style>
