<template>
  <div class="d-flex flex-column">
    <v-btn
      small
      icon
      class="align-self-end mt-2 mr-2"
      @click="$emit('closeDialog')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="pa-4 pt-0 d-flex flex-column">
      <v-progress-circular
        size="60"
        width="3"
        indeterminate
        color="primary"
        v-if="loader"
        class="align-self-center"
      ></v-progress-circular>

      <template v-else>
        <v-tabs v-model="tab" class="mb-2">
          <v-tab>Message</v-tab>

          <v-tab>Sent to users</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div class="d-flex flex-column pa-4">
              <div class="d-flex align-center mb-4">
                <div class="subtitle-1 font-weight-medium mr-1">Title:</div>

                <div class="body-1">
                  {{ currentMessage.messageTitle }}
                </div>
              </div>

              <div class="d-flex align-center mb-4">
                <div class="subtitle-1 font-weight-medium mr-1">
                  Importance:
                </div>

                <div class="body-2 text-capitalize">
                  {{ currentMessage.importance }}
                </div>
              </div>

              <div class="subtitle-1 font-weight-medium mb-1">Message:</div>

              <div v-html="currentMessage.messageBody"></div>
            </div>
          </v-tab-item>

          <v-tab-item>
            <div class="d-flex flex-column pa-4">
              <div class="subtitle-2">Sent to clients</div>

              <div class="mb-2">
                <v-chip
                  small
                  class="ma-2"
                  v-for="client in currentMessage.sendToClients"
                  :key="client.clientId"
                >
                  {{ client.companyName }}
                </v-chip>
              </div>

              <div class="subtitle-2">Roles</div>

              <div class="mb-2">
                <v-chip
                  small
                  class="ma-2"
                  v-for="client in currentMessage.sendToPlatformAccesses"
                  :key="client.itemKey"
                >
                  {{ client.itemValue }}
                </v-chip>
              </div>

              <v-data-table
                :headers="headers"
                :items="paginatedUsers"
                hide-default-footer
                @page-count="pageCount = $event"
                class="mb-3"
                :items-per-page="pageSize"
              >
                <template v-slot:[`item.user`]="{ item }">
                  {{ item.firstName }} {{ item.lastName }}
                </template>

                <template v-slot:[`item.messageDateOpened`]="{ item }">
                  <div
                    class="success--text font-weight-bold body-2"
                    v-if="item.messageDateOpened"
                  >
                    {{
                      dayjs
                        .utc(item.messageDateOpened)
                        .local()
                        .format("DD/MM/YYYY HH:mm")
                    }}
                  </div>

                  <div class="body-2 font-weight-bold" v-else>-</div>
                </template>
              </v-data-table>

              <v-pagination v-model="page" :length="pages"></v-pagination>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: ["message"],
  data() {
    return {
      loader: false,
      tab: 0,
      headers: [
        { text: "User", value: "user", sortable: false },
        { text: "Client", value: "clientName", sortable: false },
        { text: "Role", value: "platformAccessName", sortable: false },
        { text: "Opened", value: "messageDateOpened", sortable: false },
      ],
      page: 1,
      pageSize: 5,
      pages: 1,
    };
  },
  computed: {
    ...mapState({
      currentMessage: (state) => state.superAdminDashboard.currentMessage,
    }),
    paginatedUsers() {
      return this.currentMessage.users.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      );
    },
  },
  async created() {
    this.loader = true;

    try {
      await this.getSuperAdminMessage(this.message.messageId);
    } catch (e) {
      console.log(e);
    }

    this.loader = false;

    this.pages = Math.ceil(this.currentMessage.users.length / 10);
  },
  methods: {
    ...mapActions(["getSuperAdminMessage"]),
    ...mapMutations(["setSuperAdminCurrentMessage"]),
  },
  destroyed() {
    this.setSuperAdminCurrentMessage(null);
  },
};
</script>

<style lang="scss" scoped>
</style>
