<template>
  <div class="white d-flex flex-column">
    <div class="primary py-4 d-flex align-center ">
      <div class="px-4 body-1 white--text mr-auto">
        Send proposed Filistos Trainings
      </div>
      <v-btn icon @click="$emit('closeDialog')" class="mr-2">
        <v-icon color="white">
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <div class="white text-center px-2 py-3">
      The proposed Filistos Trainings will be assigned to the selected users and
      forwarded to them. Are you sure you want to continue?
    </div>
    <div class="align-self-center flex-grow-1 py-2">
      <v-btn outlined color="error" class="mr-2" @click="$emit('closeDialog')">
        Cancel
      </v-btn>
      <v-btn color="success" @click="onSend">
        OK
      </v-btn>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  props: {
    selectedAssignments: Array,
  },

  methods: {
    ...mapMutations(["toggleSnackbar"]),
    async onSend() {
      const selected = this.selectedAssignments.map(
        (el) => el.assignUserQuestId
      );
      try {
        axios.post(
          `${process.env.VUE_APP_BASE_URL}/clientadmin/AddProposedFilistosTrainingAssignments`,
          {
            fromAssignUserQuestIds: selected,
          }
        );
        this.toggleSnackbar({
          open: true,
          color: "success",
          text: "The proposed Filistos trainings have been successfully sent.",
          timeout: 5000,
        });
        this.$emit("closeDialog");
      } catch (e) {
        this.toggleSnackbar({
          open: true,
          color: "error",
          text: "An error has been occured",
          timeout: 5000,
        });
      }
    },
  },
};
</script>

<style></style>
