import axios from "axios";
export default {
  state: {
    notification: null,
    notifications: {
      results: [],
      rowCount: 0,
    },
    notificationsParams: {
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: [],
      search: "",
    },
    form: {
      title: null,
      body: null,
      importance: null,
      sendToClientUserIds: [],
    },
    getNotificationsLoader: false,
  },
  getters: {
    notificationUserIdsMapped(state) {
      return state.form.sendToClientUserIds.map((user) => {
        return user.clientUserId;
      });
    },
  },

  mutations: {
    getNotification(state, notification) {
      state.notification = notification;
    },
    setNotificationForm(state, field) {
      state.form[field.key] = field.value;
    },
    resetNotificationForm(state) {
      state.form = {
        title: null,
        body: null,
        importance: null,
        sendToClientUserIds: [],
      };
    },

    getNotifications(state, notifications) {
      state.notifications = notifications;
    },
    toggleGetNotificationsLoader(state, loader) {
      state.getNotificationsLoader = loader;
    },
    setNotificationsParams(state, params) {
      state.notificationsParams = {
        ...state.notificationsParams,
        ...params,
      };
    },
  },
  actions: {
    async getNotifications({ commit, state, rootState }) {
      try {
        const role = rootState.auth.role;

        commit("toggleGetNotificationsLoader", true);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${
            role == "co" ? "ClientAdmin" : "GroupManager"
          }/GetMobileNotificationsOfMyCompany`,
          {
            params: {
              pageSize:
                state.notificationsParams.itemsPerPage != -1
                  ? state.notificationsParams.itemsPerPage
                  : 1000,
              pageNo: state.notificationsParams.page,
              ...(state.notificationsParams.search && {
                search: state.notificationsParams.search,
              }),
            },
          }
        );

        commit("getNotifications", res.data);

        commit("toggleGetNotificationsLoader", false);
      } catch (e) {
        commit("toggleGetNotificationsLoader", false);

        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },

    async getNotification({ commit, rootState }, notification_id) {
      try {
        const role = rootState.auth.role;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${
            role == "co" ? "ClientAdmin" : "GroupManager"
          }/GetNotification/${notification_id}`
        );

        commit("getNotification", res.data);
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },

    async addNotification({ commit, getters, state, dispatch }) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/AddMobileNotification`,
          {
            title: state.form.title,
            importance: state.form.importance,
            bodyHtml: state.form.body,
            sendToClientUserIds: state.form.sendToClientUserIds.map(
              (user) => user.clientUserId
            ),
          }
        );

        await dispatch("getInfo");

        dispatch("getNotifications");
        commit("resetNotificationForm");

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Notification has been sent!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
  },
};
