<template>
  <div>
    <div class="subtitle-1" :class="{ 'mb-4': !summary.summaryIntro }">
      Introduction
    </div>

    <div class="body-2 mb-4" v-if="summary.summaryIntro">
      {{ summary.summaryIntro }}
    </div>

    <div
      class="d-flex flex-column"
      v-for="item in summary.summaryItems"
      :key="item.itemId"
    >
      <div class="subtitle-1">{{ item.ItemName }}</div>

      <div class="body-2" v-if="item.itemDescription">
        {{ item.itemDescription }}
      </div>

      <div class="progress rounded px-4 mt-2">
        <div
          class="progress-item subtitle-2 black--text"
          :style="{
            left: item.plusMinus < 0 ? 'calc(100% - 5px)' : 'calc(0% + 5px)',
          }"
          :class="{ end: item.plusMinus < 0 }"
        >
          {{ item.minScore }}
        </div>

        <div
          class="progress-item subtitle-2 black--text"
          :style="{
            [item.plusMinus < 0 ? 'right' : 'left']: `${
              (item.step1 / item.maxScore) * 100
            }%`,
          }"
        >
          {{ item.step1 }}
        </div>

        <div
          class="progress-item subtitle-2 black--text"
          :style="{
            [item.plusMinus < 0 ? 'right' : 'left']: `${
              (item.step2 / item.maxScore) * 100
            }%`,
          }"
        >
          {{ item.step2 }}
        </div>

        <div
          class="progress-item subtitle-2 black--text"
          :style="{
            left: item.plusMinus < 0 ? 'calc(0% + 5px)' : 'calc(100% - 5px)',
          }"
          :class="{ end: item.plusMinus >= 0 }"
        >
          {{ item.maxScore }}
        </div>

        <div
          class="score caption font-weight-bold px-1 rounded"
          :class="{
            yellow: item.colorFlag == 'Y',
            red: item.colorFlag == 'R',
            green: item.colorFlag == 'G',
          }"
          :style="{
            [item.plusMinus < 0 ? 'right' : 'left']: `${
              (item.score / item.maxScore) * 100
            }%`,
          }"
        >
          {{ Math.floor(item.score) }}
        </div>
      </div>

      <v-divider class="mt-8 mb-2"></v-divider>
    </div>
  </div>
</template>

<script>
export default {
  props: ["summary"],
  created() {
    console.log(this.summary);
  },
};
</script>

<style lang="scss" scoped>
.progress {
  height: 25px;
  background: linear-gradient(
    to right,
    #f44336 0%,
    #ff9800 33%,
    #ffeb3b 66%,
    #4caf50 100%
  );
  position: relative;

  .progress-item {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.end {
      transform: translate(-100%, -50%);
    }
  }

  .score {
    position: absolute;
    bottom: -23px;
  }
}
</style>
