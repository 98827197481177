var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"scrollable"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"mb-1",attrs:{"dense":"","outlined":"","label":"Identity*","value":_vm.personalDetailsForm.identity,"error-messages":_vm.identityErrors},on:{"input":function($event){return _vm.setPersonalDetailsForm({ value: $event, key: 'identity' })}}}),_c('v-text-field',{staticClass:"mb-1",attrs:{"dense":"","outlined":"","label":"First Name*","value":_vm.personalDetailsForm.firstName,"error-messages":_vm.v.$dirty && !_vm.v.form.personalDetails.firstName.required
          ? 'This field is required'
          : ''},on:{"input":function($event){return _vm.setPersonalDetailsForm({ value: $event, key: 'firstName' })}}}),_c('v-text-field',{staticClass:"mb-1",attrs:{"dense":"","outlined":"","label":"Last Name*","value":_vm.personalDetailsForm.lastName,"error-messages":_vm.v.$dirty && !_vm.v.form.personalDetails.lastName.required
          ? 'This field is required'
          : ''},on:{"input":function($event){return _vm.setPersonalDetailsForm({ value: $event, key: 'lastName' })}}}),(_vm.platformAccess != 'basic')?_c('v-text-field',{staticClass:"mb-1",attrs:{"disabled":Boolean(_vm.selectedUser) &&
          _vm.selectedUser.platformAccessId == _vm.platformAccess,"dense":"","outlined":"","label":"Username*","value":_vm.personalDetailsForm.username,"error-messages":_vm.usernameErrors},on:{"input":function($event){return _vm.setPersonalDetailsForm({ value: $event, key: 'username' })}}}):_vm._e(),_c('v-text-field',{attrs:{"dense":"","outlined":"","label":("Email " + (_vm.platformAccess && _vm.platformAccess != 'basic' ? '*' : '')),"type":"email","value":_vm.personalDetailsForm.email,"error-messages":_vm.emailErrors},on:{"input":function($event){return _vm.setPersonalDetailsForm({ value: $event, key: 'email' })}}}),_c('v-radio-group',{staticClass:"mt-0",attrs:{"row":""},model:{value:(_vm.positionType),callback:function ($$v) {_vm.positionType=$$v},expression:"positionType"}},_vm._l((_vm.userTypes),function(type){return _c('v-radio',{key:type.itemKey,attrs:{"value":type.itemKey,"label":type.itemValue,"ripple":false}})}),1),_c('v-select',{attrs:{"dense":"","outlined":"","label":"Position*","items":_vm.positions,"item-value":"itemKey","item-text":"itemValue","value":_vm.personalDetailsForm.position,"error-messages":_vm.v.$dirty && !_vm.v.form.personalDetails.position.required
          ? 'This field is required'
          : ''},on:{"input":function($event){return _vm.setPersonalDetailsForm({ value: $event, key: 'position' })}}}),(_vm.role == 'gm' && !_vm.isEdit)?_c('v-select',{attrs:{"dense":"","outlined":"","label":"Group(s)*","items":_vm.allGroups,"item-value":"groupId","item-text":"groupName","multiple":"","value":_vm.personalDetailsForm.groups,"error-messages":_vm.v.$dirty && !_vm.v.form.personalDetails.groups.required
          ? 'This field is required'
          : ''},on:{"input":function($event){return _vm.setPersonalDetailsForm({ value: $event, key: 'groups' })}}}):_vm._e(),_c('v-select',{staticClass:"mb-1",attrs:{"dense":"","outlined":"","label":"Nationality*","items":_vm.countries,"item-value":"itemKey","item-text":"itemValue","value":_vm.personalDetailsForm.nationality,"error-messages":_vm.v.$dirty && !_vm.v.form.personalDetails.nationality.required
          ? 'This field is required'
          : ''},on:{"input":function($event){return _vm.setPersonalDetailsForm({ value: $event, key: 'nationality' })}}}),_c('v-select',{staticClass:"mb-1",attrs:{"dense":"","outlined":"","label":"Language*","items":_vm.languages,"item-value":"itemKey","item-text":"itemValue","value":_vm.personalDetailsForm.language,"error-messages":_vm.v.$dirty && !_vm.v.form.personalDetails.language.required
          ? 'This field is required'
          : ''},on:{"input":function($event){return _vm.setPersonalDetailsForm({ value: $event, key: 'language' })}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-1",attrs:{"value":_vm.formattedDate,"dense":"","prepend-inner-icon":"mdi-calendar","outlined":"","clearable":"","label":"Birthdate","readonly":"","hint":_vm.personalDetailsForm.birthDate
              ? ("Age: " + (new Date().getFullYear() -
                  _vm.dayjs(_vm.personalDetailsForm.birthDate).year()))
              : '',"persistent-hint":""},on:{"click:clear":function($event){return _vm.setPersonalDetailsForm({ value: null, key: 'birthDate' })}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"value":_vm.personalDetailsForm.birthDate,"max":_vm.maxBirthDate},on:{"input":function($event){return _vm.setPersonalDetailsForm({ value: $event, key: 'birthDate' })},"change":function($event){_vm.dateMenu = false}}})],1),_c('v-select',{staticClass:"mb-1",attrs:{"dense":"","outlined":"","label":"Gender","items":_vm.genders,"item-value":"itemKey","item-text":"itemValue","value":_vm.personalDetailsForm.gender},on:{"input":function($event){return _vm.setPersonalDetailsForm({ value: $event, key: 'gender' })}}}),_c('v-select',{staticClass:"mb-1",attrs:{"dense":"","outlined":"","label":"Status","items":_vm.maritalStatuses,"item-value":"itemKey","item-text":"itemValue","value":_vm.personalDetailsForm.maritalStatus},on:{"input":function($event){return _vm.setPersonalDetailsForm({ value: $event, key: 'maritalStatus' })}}}),_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Kids","type":"number","value":_vm.personalDetailsForm.kids},on:{"input":function($event){return _vm.setPersonalDetailsForm({ value: $event, key: 'kids' })}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }