<template>
  <div>
    <div class="d-flex flex-column align-center">
      <v-radio-group
        v-model="period"
        row
        dense
        class="align-self-center mb-5"
        hide-details
        @change="onGetDashboardAssignments"
        :readonly="loader"
      >
        <v-radio label="Monthly" value="month"></v-radio>

        <v-radio label="Daily" value="day"></v-radio>
      </v-radio-group>

      <div class="d-flex align-self-center mb-5">
        <v-select
          dense
          outlined
          hide-details
          label="Year"
          class="flex-grow-0"
          :items="years"
          v-model="year"
          @change="onGetDashboardAssignments"
          :loading="loader"
        ></v-select>

        <v-select
          dense
          outlined
          hide-details
          label="Month"
          class="flex-grow-0 ml-5"
          :items="months"
          item-text="value"
          item-value="key"
          v-model="month"
          v-if="period == 'day'"
          @change="onGetDashboardAssignments"
          :loading="loader"
        ></v-select>
      </div>

      <v-select
        label="Client"
        outlined
        dense
        hide-details
        :items="allClients"
        item-value="clientId"
        item-text="companyName"
        v-model="client"
        @change="onGetDashboardAssignments"
        clearable
        v-if="isSuperadmin"
      ></v-select>
    </div>

    <v-row no-gutters>
      <v-col cols="12" lg="6" class="pa-5">
        <div class="d-flex flex-column">
          <apexchart
            type="line"
            height="300"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </v-col>

      <v-col cols="12" lg="6" class="pa-5">
        <div class="text-center text-h6">Selected Period</div>

        <div class="text-center body-2 mb-5">
          (<span v-if="period == 'day'"
            >{{
              months.find(
                (month) => month.key == dashboardAssignmentsParams.month
              ).value
            }} </span
          >{{ dashboardAssignmentsParams.year }})
        </div>

        <StatisticsTable :isFiltered="true" :isSuperadmin="isSuperadmin" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import months from "@/months.json";
import StatisticsTable from "@/views/admin/dashboard/assignments/StatisticsTable";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: ["isSuperadmin"],
  components: {
    apexchart: VueApexCharts,
    StatisticsTable,
  },
  data() {
    return {
      period: "month",
      years: [],
      year: null,
      month: null,
      months,
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ["#3276b1", "#71843f"],
        stroke: {
          curve: "straight",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        yaxis: {
          min: 0,
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "right",
        },
      },
      loader: false,
      client: null,
    };
  },
  computed: {
    ...mapState({
      info: (state) => state.base.info,
      dashboardAssignmentsParams: (state) =>
        state.dashboard.dashboardAssignmentsParams,
      allClients: (state) => state.base.allClients,
    }),
    dashboardAssignments() {
      return this.$store.state[
        this.isSuperadmin ? "superAdminDashboard" : "dashboard"
      ].dashboardAssignments;
    },
  },
  created() {
    const companyStartYear = this.dayjs(this.info?.startDate).year();

    const currentYear = new Date().getFullYear();

    for (let i = 0; i <= currentYear - companyStartYear; i++) {
      this.years.push(companyStartYear + i);
    }

    this.year = currentYear;
    this.month = this.months[0].key;
  },
  methods: {
    ...mapMutations(["setDashboardAssignmentsParams"]),
    ...mapActions([
      "getDashboardAssignments",
      "getSuperAdminDashboardAssignments",
    ]),
    async onGetDashboardAssignments() {
      this.loader = true;

      try {
        this.setDashboardAssignmentsParams({
          year: this.year,
          month: this.period == "month" ? null : this.month,
        });

        if (this.isSuperadmin) {
          this.getSuperAdminDashboardAssignments({
            clientId: this.client,
          });
        } else {
          this.getDashboardAssignments();
        }
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  watch: {
    dashboardAssignments: {
      immediate: true,
      handler(newValue) {
        if (this.period == "month") {
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              categories: months.map((month) => month.value),
            },
          };
        } else {
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              categories: Array.from(
                { length: newValue.assignmentItems.length },
                (_, i) => `${i + 1}`
              ),
            },
          };
        }

        this.series = [
          {
            name: "Assigned",
            data: newValue.assignmentItems,
          },

          {
            name: "Answered",
            data: newValue.finalizedAssignmentItems,
          },
        ];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
