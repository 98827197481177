<template>
  <div>
    <v-list dense nav>
      <div v-for="(link, index) in navLinks" :key="index">
        <v-list-item
          class="white--text"
          :class="{
            'mb-1': index != navLinks.length - 1,
            active: link.name.includes($route.name),
          }"
          :to="link.path"
          v-if="link.path"
        >
          <v-list-item-icon>
            <v-icon color="white">{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["isCollapsed"],
  data() {
    return {
      navLinks: [
        {
          icon: "mdi-view-dashboard",
          text: "Dashboard",
          path: "/superadmin",
          name: ["superadminDashboard"],
        },
        {
          icon: "mdi-bank",
          text: "Clients",
          path: "/superadmin/clients",
          name: [
            "clients",
            "newUserBasicData",
            "editUserBasicData",
            "editQuestionnaires",
            "editUserTransactions",
            "editUserMobileApps",
            "newUserTransaction",
            "editUserAdmins",
            "editUserAdminNew",
            "editUserAdminEdit",
            "editUserUsers",
            "editUserUserAssignments",
            "editUserUserReport",
            "editUserUserData",
            "editUserUserGroups",
          ],
        },
        {
          icon: "mdi-format-list-bulleted",
          text: "Questionnaires",
          path: "/superadmin/questionnaires",
          name: [
            "questionnaires",
            "questionnairesOfType",
            "questionnaireBasicData",
            "questionnaireSection",
            "questionnaireFactors",
            "questionnaireFactor",
            "questionnaireQuestion",
          ],
        },
        {
          icon: "mdi-monitor",
          text: "Monitoring",
          path: "/superadmin/monitoring",
          name: ["monitoring", "monitoringReport"],
        },
        {
          icon: "mdi-chart-bar",
          text: "Comparison",
          path: "/superadmin/comparison",
          name: ["superAdminComparisonPossible", "superAdminComparisonResults"],
        },
        {
          icon: "mdi-finance",
          text: "Statistics",
          path: "/superadmin/statistics",
          name: ["superAdminStatistics"],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.auth.role,
    }),
  },
  created() {
    if (this.role == "allpsysup") {
      this.navLinks = [
        {
          icon: "mdi-view-dashboard",
          text: "Dashboard",
          path: "/allpsysup",
          name: ["allpsysupDashboard"],
        },
        {
          icon: "mdi-account",
          text: "Self Evaluations",
          path: "/allpsysup/self-evaluations",
          name: ["allpsysupSelfEvaluations", "allpsysupSelfEvaluation"],
        },
        {
          icon: "mdi-account-multiple",
          text: "Cases",
          path: "/allpsysup/cases",
          name: ["allpsysupCases", "allpsysupCase"],
        },
        {
          icon: "mdi-chart-bar",
          text: "Comparison",
          path: "/allpsysup/comparison",
          name: ["allPsysupComparisonPossible", "allPsysupComparisonResults"],
        },
      ];
    }
  },
};
</script>

<style lang="scss" >
.v-list-item--active::before {
  opacity: 0 !important;
}

.active::before {
  opacity: 0.2 !important;
}
</style>
