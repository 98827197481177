<template>
  <div class="wrapper rounded flex-grow-1 d-flex flex-column">
    <div class="d-flex align-center mb-2">
      <div class="mr-2 subtitle-2">Selected Users:</div>
      <div class="subtitle-2">{{ form.sendToClientUserIds.length }}</div>
    </div>

    <div class="d-flex align-start mb-2">
      <div class="mr-5 d-flex align-start mr-auto">
        <v-text-field
          outlined
          dense
          label="Search"
          class="mr-2"
          prepend-inner-icon="mdi-magnify"
          hint="Search by Identity, Last Name, First Name, Email"
          persistent-hint
          :value="usersParams.search"
          @input="setUserParams({ search: $event })"
          :readonly="getUsersLoader"
        ></v-text-field>

        <v-btn
          icon
          color="primary"
          v-if="usersParams.search"
          @click="setUserParams({ search: '' })"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <FilterBy
        :fields="filterFields"
        :loader="getUsersLoader"
        @filterChange="onFilterChange"
      />

      <ShowPerPage
        class="mx-2"
        :currentPerPage="usersParams.itemsPerPage"
        :options="showPerPageOptions"
      />

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="primary"
            depressed
            :loading="actionsLoader"
          >
            Actions
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item link @click="onSelectAll">
            <v-list-item-title
              >Select all
              {{
                hasFilter || usersParams.search ? "(Filtered)" : ""
              }}</v-list-item-title
            >
          </v-list-item>

          <v-list-item link @click="onUnselectAll">
            <v-list-item-title
              >Unselect all
              {{
                hasFilter || usersParams.search ? "(Filtered)" : ""
              }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-data-table
      :headers="headers"
      :items="!getUsersLoader ? users.results : []"
      :server-items-length="users.rowCount"
      :loading="getUsersLoader"
      @update:options="onGetUsers"
      :options="options"
      hide-default-footer
      show-select
      mobile-breakpoint="0"
    >
      <template slot="header.data-table-select">
        <div style="cursor: none"></div>
      </template>

      <template v-slot:[`item.data-table-select`]="{ item }">
        <v-checkbox
          dense
          hide-details
          class="pa-0 ma-0"
          :ripple="false"
          :value="{
            clientUserId: item.clientUserId,
            identityNumber: item.identityNumber,
            firstName: item.firstName,
            lastName: item.lastName,
            userEmail: item.userEmail,
          }"
          v-model="form.sendToClientUserIds"
        ></v-checkbox>
      </template>

      <template v-slot:[`item.userEmail`]="{ item }">
        {{ item.userEmail ? item.userEmail : "-" }}
      </template>

      <template v-slot:[`item.languageName`]="{ item }">
        {{ item.languageName ? item.languageName : "-" }}
      </template>
    </v-data-table>

    <div
      class="pagination-wrapper py-3 mt-auto"
      v-if="usersParams.itemsPerPage != -1"
    >
      <v-pagination
        :length="users.pageCount"
        v-model="usersParams.page"
        :disabled="getUsersLoader"
        v-if="users.pageCount"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import FilterBy from "@/components/FilterBy";
import { mapActions, mapMutations, mapState } from "vuex";
import ShowPerPage from "@/components/ShowPerPage";

export default {
  components: {
    FilterBy,
    ShowPerPage,
  },
  data() {
    return {
      headers: [
        { text: "Identity", value: "identityNumber" },
        { text: "Last Name", value: "lastName" },
        { text: "First Name", value: "firstName" },
        { text: "Email", value: "userEmail" },
        { text: "Nationality", value: "nationalityName" },
        { text: "Language", value: "languageName" },
        { text: "Position", value: "positionName" },
      ],
      timeout: null,
      actionsLoader: false,
      showPerPageOptions: [],
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.users.users,
      usersParams: (state) => state.users.usersParams,
      getUsersLoader: (state) => state.users.getUsersLoader,
      countries: (state) => state.base.lists.countries,
      platformAccesses: (state) => state.base.lists.platformAccesses,
      languages: (state) => state.base.lists.languages,
      genders: (state) => state.base.lists.genders,
      userTypes: (state) => state.base.lists.userTypes,
      allGroups: (state) => state.base.allGroups,
    }),
    form: {
      set(value) {
        console.log(value);
      },
      get() {
        return this.$store.state.notifications.form;
      },
    },

    options() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.usersParams;

      return {
        itemsPerPage,
        page,
        sortBy,
        sortDesc,
      };
    },
    filterFields() {
      return [
        {
          key: "groupId",
          value: "Group",
          values: this.allGroups,
          selectedValue: this.usersParams.groupId,
        },
        {
          key: "nationalityId",
          value: "Nationality",
          values: this.countries,
          selectedValue: this.usersParams.nationalityId,
        },
        {
          key: "languageId",
          value: "Language",
          values: this.languages,
          selectedValue: this.usersParams.languageId,
        },
        {
          key: "position",
          value: "Position",
          checkboxValues: this.userTypes,
          selectedValue: this.usersParams.position,
        },
      ];
    },
    hasFilter() {
      return (
        Boolean(this.usersParams.groupId) ||
        Boolean(this.usersParams.nationalityId) ||
        Boolean(this.usersParams.languageId) ||
        Boolean(this.usersParams.position.positionId)
      );
    },
  },
  created() {
    this.setUserParams({
      itemsPerPage: 5,
      withMobileLicense: true,
    });

    this.togglePreselectedUsersBanner(false);

    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setUserParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setUserParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setUserParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setUserParams,
      },
    ];
  },
  methods: {
    ...mapActions(["getUsers"]),
    ...mapMutations([
      "setUserParams",
      "setNotificationForm",
      "togglePreselectedUsersBanner",
    ]),
    async onGetUsers(event) {
      if (this.getUsersLoader) return;

      let { itemsPerPage, page, sortBy, sortDesc } = event || {};

      if (event) {
        this.setUserParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      } //this happens because this method is called from either @update:options or selectedGroups watcher or union wathcer

      try {
        await this.getUsers();
      } catch (e) {
        console.log(e);
      }
    },
    onFilterChange(e) {
      this.setUserParams({
        [e.field]: e.value,
      });

      this.onGetUsers();
    },
    async onSelectAll() {
      this.actionsLoader = true;

      try {
        const allUsersWithCurrentFilters = await this.getUsers(true);

        const selectedUsers = [
          ...this.form.sendToClientUserIds,
          ...allUsersWithCurrentFilters.filter(
            (user) =>
              this.form.sendToClientUserIds.findIndex(
                (selectedUser) => selectedUser.clientUserId == user.clientUserId
              ) == -1
          ),
        ];
        console.log(selectedUsers);

        this.setNotificationForm({
          value: selectedUsers,
          key: "sendToClientUserIds",
        });
      } catch (e) {
        console.log(e);
      }

      this.actionsLoader = false;
    },
    async onUnselectAll() {
      this.actionsLoader = true;

      try {
        const allUsersWithCurrentFilters = await this.getUsers(true);

        const selectedUsers = this.form.sendToClientUserIds.filter(
          (user) =>
            allUsersWithCurrentFilters.findIndex(
              (selectedUser) => selectedUser.clientUserId == user.clientUserId
            ) == -1
        );

        this.setNotificationForm({
          value: selectedUsers,
          key: "sendToClientUserIds",
        });
      } catch (e) {
        console.log(e);
      }

      this.actionsLoader = false;
    },
  },
  watch: {
    "usersParams.search"() {
      clearTimeout(this.timeout);

      if (this.getUsersLoader) return;

      this.timeout = setTimeout(() => {
        this.setUserParams({
          page: 1,
        });

        this.onGetUsers();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
