<template>
  <div class="component-wrapper px-5 pt-5 d-flex flex-column">
    <v-progress-circular
      size="80"
      width="4"
      indeterminate
      color="primary"
      v-if="loader"
      class="ma-auto"
    ></v-progress-circular>

    <template v-else>
      <div class="header d-flex justify-center">
        <v-btn
          icon
          @click="$router.push(`/assignee/${$route.params.userAssignmentId}`)"
          class="back-btn"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <div class="text-h6 text-center">
          {{ questionnaire.questionnaireName }}
        </div>
      </div>

      <div
        class="d-flex align-center justify-center text-center"
        v-if="questionnaire.questionnaireTypeId != 'ability'"
      >
        <div
          class="subtitle-2 mr-2"
          :class="{
            'error--text': answeredQuestions.length != totalQuestions,
            'success--text': answeredQuestions.length == totalQuestions,
          }"
        >
          Total Answered:
        </div>

        <div
          class="subtitle-2"
          :class="{
            'error--text': answeredQuestions.length != totalQuestions,
            'success--text': answeredQuestions.length == totalQuestions,
          }"
        >
          {{ answeredQuestions.length }} / {{ totalQuestions }}
        </div>
      </div>

      <div
        class="progress-wrapper align-self-center d-flex flex-column mt-4"
        v-if="questionnaire.questionnaireTypeId == 'ability'"
      >
        <v-progress-linear
          :value="remainingPercentage"
          v-if="!questionnaire.isFinal"
        ></v-progress-linear>

        <div class="d-flex align-center align-self-end mt-1">
          <div class="subtitle-2 success--text mr-2">Total Answered:</div>

          <div class="subtitle-2 success--text">
            {{ answeredQuestions.length }} / {{ totalQuestions }}
          </div>

          <div class="subtitle-2 mx-4">|</div>

          <div class="subtitle-2 mr-2">
            {{ questionnaire.isFinal ? "Execution TIme" : "Remaining" }}:
          </div>

          <div class="subtitle-2">
            {{
              dayjs
                .utc(
                  (questionnaire.isFinal
                    ? questionnaire.completionTimeSec
                    : remaining) * 1000
                )
                .format("mm:ss")
            }}
          </div>
        </div>
      </div>

      <v-divider class="my-5"></v-divider>

      <div
        class="form-wrapper align-self-center mb-5 success pa-2 rounded"
        v-if="questionnaire.isFinal"
      >
        <div class="white--text body-2 font-weight-medium">
          This questionnaire is finalized and submitted, so you cannot make any
          further changes.
        </div>
      </div>

      <div class="d-flex align-center justify-center mb-2">
        <v-btn
          x-small
          class="mr-4 white--text"
          depressed
          color="filyellow"
          @click="onPrevSection"
          width="80px"
        >
          <v-icon left>mdi-chevron-left</v-icon>
          Previous
        </v-btn>

        <div class="subtitle-2 mr-4">
          section {{ currentSection + 1 }} /
          {{ questionnaire.sections.length }}
        </div>

        <v-btn
          x-small
          depressed
          color="filyellow"
          class="white--text"
          @click="onNextSection"
          width="80px"
        >
          Next
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>

      <v-btn
        depressed
        x-small
        color="primary"
        class="align-self-center"
        @click="dialog = !dialog"
        v-if="section && section.introHtml"
      >
        <v-icon small>mdi-information-variant</v-icon>
        Section Instructions
      </v-btn>

      <form @submit.prevent="submit" class="flex-grow-1 d-flex flex-column">
        <div class="scrollable form-wrapper align-self-center mb-5">
          <div
            class="subtitle-1 font-weight-medium"
            :class="{
              'mb-1': section.sectionDescription,
              'mb-4': !section.sectionDescription,
            }"
          >
            {{ section.sectionName }}
          </div>

          <div class="body-2 ml-2 mb-3">{{ section.sectionDescription }}</div>

          <Questions
            :questions="section.questions"
            :v="$v.questionnaire.sections.$each[currentSection].questions"
            @inputChanged="onInputChanged"
          />
        </div>

        <div
          class="form-wrapper align-self-center mb-2"
          v-if="$v.$dirty && $v.$invalid"
        >
          <div class="caption font-weight-medium error--text">
            You can save the current state of your answers as a draft for a
            later review.
          </div>

          <div class="caption font-weight-medium error--text">
            Before the final submission you have to complete all mandatory
            questions marked in red.
          </div>
        </div>

        <div
          class="d-flex align-center align-self-center"
          v-if="!questionnaire.isFinal"
        >
          <v-btn
            class="align-self-center white--text mr-4"
            color="filyellow"
            depressed
            type="button"
            outlined
            :loading="draftLoader"
            @click="submit(false)"
            v-if="questionnaire.questionnaireTypeId != 'ability'"
            >Draft</v-btn
          >

          <v-btn
            class="align-self-center white--text"
            color="filyellow"
            depressed
            type="button"
            :loading="submitLoader"
            @click="submit(true)"
            :disabled="
              questionnaire.questionnaireTypeId == 'ability' && $v.$invalid
            "
            >Submit</v-btn
          >
        </div>
      </form>
    </template>

    <v-dialog v-model="discardDialog.open" max-width="500px">
      <div class="white pa-4 d-flex flex-column align-center">
        <div class="subtitle-1 font-weight-medium text-center mb-1">
          You have unsaved changes
        </div>

        <div class="body-2 text-center mb-3">
          Are you sure you want to leave? All unsaved changes will be lost if
          you leave this page.
        </div>

        <v-btn
          class="mt-4 mb-2"
          depressed
          color="primary"
          @click="discardDialog = { ...discardDialog, open: false }"
          >Stay on this page</v-btn
        >

        <v-btn color="error" text @click="onDiscard"
          >Discard changes and leave</v-btn
        >
      </div>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="1200px">
      <div class="white d-flex flex-column" v-if="section && section.introHtml">
        <v-btn class="ml-auto mt-1 mr-1" icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <div class="pa-4">
          <div v-html="section.introHtml"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { requiredIf } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      loader: false,
      submitLoader: false,
      draftLoader: false,
      initialData: null,
      discardDialog: {
        open: false,
        to: null,
        allowLeave: false,
      },
      interval: null,
      remaining: null,
      remainingPercentage: null,
      totalQuestions: 0,
      answeredQuestions: [],
      currentSection: 0,
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      questionnaire: (state) => state.assignee.questionnaire,
    }),
    section() {
      return this.questionnaire?.sections[this.currentSection];
    },
    hasChanges() {
      return this.initialData != JSON.stringify(this.questionnaire);
    },
  },
  validations: {
    questionnaire: {
      sections: {
        $each: {
          questions: {
            $each: {
              answerValue: {
                required: requiredIf(function (nestedModel) {
                  return nestedModel.isMandatory;
                }),
              },
              subQuestions: {
                $each: {
                  answerValue: {
                    required: requiredIf(function (nestedModel) {
                      return nestedModel.isMandatory;
                    }),
                  },
                },
              }, //supports up to 2 levels of questions hardcoded. if more levels then a more customizable solution should be found.
            },
          },
        },
      },
    },
  },
  async created() {
    this.loader = true;

    try {
      await this.getQuestionnaire({
        questionnaireId: this.$route.params.questionnaireId,
        language: this.$route.params.language,
      });
    } catch (e) {
      console.log(e);
    }

    this.loader = false;

    if (!this.questionnaire)
      this.$router.push(`/assignee/${this.$route.params.userAssignmentId}`);

    this.initialData = JSON.stringify(this.questionnaire);

    this.questionnaire.sections.forEach((section) => {
      this.totalQuestions += section.questions.length;
    });

    if (this.questionnaire.questionnaireTypeId == "ability") {
      if (!this.questionnaire.isFinal) {
        this.remaining = this.questionnaire.timeLimitSec;

        this.interval = setInterval(() => {
          this.remaining--;

          this.remainingPercentage = parseFloat(
            ((100 * this.remaining) / this.questionnaire.timeLimitSec).toFixed(
              2
            )
          );

          if (!this.remaining) {
            clearInterval(this.interval);

            this.toggleSnackbar({
              open: true,
              color: "warning",
              text: "You have reached the time limit. The questionnaire has been submitted automatically, with the questions you answered until now.",
              timeout: -1,
            });

            this.submit(true, true);
          }
        }, 1000);
      } else {
        this.questionnaire.sections.forEach((section) => {
          section.questions.forEach((q) => {
            if (q.answerValue) this.answeredQuestions.push(q);
          });
        });
      }
    }
  },
  methods: {
    ...mapMutations(["toggleSnackbar"]),
    ...mapActions(["getQuestionnaire", "answerQuestionnaire"]),
    onInputChanged(e) {
      if (this.$v.$dirty && this.$v.$invalid) this.$v.$reset();

      const found = this.answeredQuestions.find(
        (q) => q.questionId == e.questionId
      );

      if (!found)
        this.answeredQuestions.push({
          questionId: e.questionId,
          answerValue: e.answerValue,
        });
    },
    flattenQuestions(questionsToFlat) {
      let flattenedQuestions = [];
      let flattenedSubQuestions = [];

      flattenedQuestions = questionsToFlat.map((question) => {
        if (question.subQuestions?.length) {
          question.subQuestions.forEach((subquestion) => {
            flattenedSubQuestions.push({
              questionId: subquestion.questionId,
              answerTypeId: subquestion.answerTypeId,
              answerValue:
                subquestion.answerTypeId == "datetime" &&
                subquestion.answerValue
                  ? this.dayjs.utc(subquestion.answerValue).toISOString()
                  : subquestion.answerValue,
              answerChoiceScaleId: subquestion.answerChoiceScaleId,
            });
          });
        }

        return {
          questionId: question.questionId,
          answerTypeId: question.answerTypeId,
          answerValue:
            question.answerTypeId == "datetime" && question.answerValue
              ? this.dayjs.utc(question.answerValue).toISOString()
              : question.answerValue,
          answerChoiceScaleId: question.answerChoiceScaleId,
        };
      });

      return [...flattenedQuestions, ...flattenedSubQuestions];
    },
    onNextSection() {
      if (this.currentSection + 1 >= this.questionnaire.sections.length) return;
      this.currentSection++;
    },
    onPrevSection() {
      if (!this.currentSection) return;
      this.currentSection--;
    },
    async submit(isFinal, forced = false) {
      if (isFinal && !forced) {
        this.$v.$touch();

        if (this.$v.$invalid) return;

        this.submitLoader = true;
      } else {
        this.draftLoader = true;
      }

      let flattenedQuestions = [];

      this.questionnaire.sections.forEach((section) => {
        flattenedQuestions = [
          ...flattenedQuestions,
          ...this.flattenQuestions(section.questions),
        ];
      });

      try {
        await this.answerQuestionnaire({
          id: this.$route.params.questionnaireId,
          isFinal,
          answers: flattenedQuestions,
          completionTimeSec: this.questionnaire.timeLimitSec - this.remaining,
          forced,
        });
      } catch (e) {
        console.log(e);
      }

      this.discardDialog.allowLeave = true;

      this.$router.push(`/assignee/${this.$route.params.userAssignmentId}`);

      this.submitLoader = false;
      this.draftLoader = false;
    },
    onDiscard() {
      this.discardDialog = {
        ...this.discardDialog,
        open: false,
        allowLeave: true,
      };

      if (this.discardDialog.to) {
        this.$router.push({ path: this.discardDialog.to });
      } else {
        this.$router.go(-1);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    //DISCARD DIALOG LOGIC
    if (this.hasChanges && !this.discardDialog.allowLeave) {
      this.discardDialog = {
        open: true,
        to: to?.path,
        allowLeave: false,
      };

      next(false);
    } else {
      next();
    }
  },
  destroyed() {
    this.$store.commit("getQuestionnaire");

    if (this.interval) clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped >
.form-wrapper {
  width: 800px;

  @media only screen and (max-width: 840px) {
    width: 100%;
  }
}

.progress-wrapper {
  width: 80%;
}
</style>
