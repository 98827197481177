import axios from "axios";

export default {
  state: {
    usersParams: {
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: [],
      search: "",
      selectedGroups: [], //multiple group filter
      groupId: null, //single group filter
      union: "all",
      nationalityId: null,
      platformAccessId: null,
      languageId: null,
      gender: null,
      position: {
        positionType: null,
        positionId: null,
      },
      onTheGoInvitationStatus: null,
      clientId: null,
    },
    users: {
      results: [],
      rowCount: 0,
    },
    usersFetched: false,
    currentUser: null,
  },
  mutations: {
    USERSNEW_setUsersParams(state, params) {
      state.usersParams = {
        ...state.usersParams,
        ...params,
      };
    },
    USERSNEW_getUsers(state, users) {
      state.users = users;
    },
    USERSNEW_toggleUsersFetched(state, fetched) {
      state.usersFetched = fetched;
    },
    USERSNEW_setCurrentUser(state, user) {
      state.currentUser = user;
    },
    USERSNEW_clearUsersState(state) {
      state.usersParams = {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
        search: "",
        selectedGroups: [],
        groupId: null,
        union: "all",
        nationalityId: null,
        platformAccessId: null,
        languageId: null,
        gender: null,
        position: {
          positionType: null,
          positionId: null,
        },
        onTheGoInvitationStatus: null,
      };
      state.users = {
        results: [],
        rowCount: 0,
      };
      state.usersFetched = false;
      state.currentUser = null;
    },
  },
  actions: {
    //GET ALL USERS
    async USERSNEW_getUsers({ commit, state, rootState }, refresh) {
      if (state.clientsFetched && !refresh) return;
      const role = rootState.auth.role;
      const roleControllers = {
        co: "ClientAdmin",
        gm: "GroupManager",
        filistos: "SuperAdmin",
      };

      try {
        let selectedGroupsParams = `${
          state.usersParams.selectedGroups.length ? "?" : ""
        }`;

        state.usersParams.selectedGroups.forEach((group, index) => {
          selectedGroupsParams += `groupId=${group}${
            index < state.usersParams.selectedGroups.length - 1 ? "&" : ""
          }`;
        });

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${roleControllers[role]}/GetUsers${
            selectedGroupsParams ? selectedGroupsParams : ""
          }`,
          {
            params: {
              pageSize:
                state.usersParams.itemsPerPage != -1
                  ? state.usersParams.itemsPerPage
                  : 10000,
              pageNo: state.usersParams.page,
              ...(state.usersParams.sortBy[0] && {
                sortBy: state.usersParams.sortBy[0],
              }),
              ...(state.usersParams.sortDesc[0] && { dir: "desc" }),
              ...(state.usersParams.search && {
                search: state.usersParams.search,
              }),
              ...(state.usersParams.nationalityId && {
                nationalityId: state.usersParams.nationalityId,
              }),
              ...(state.usersParams.platformAccessId && {
                platformAccessId: state.usersParams.platformAccessId,
              }),
              ...(state.usersParams.languageId && {
                languageId: state.usersParams.languageId,
              }),
              ...(state.usersParams.gender && {
                gender: state.usersParams.gender,
              }),
              ...(state.usersParams.position.positionId && {
                positionId: state.usersParams.position.positionId,
              }),
              ...(state.usersParams.selectedGroups.length > 1 && {
                groupUnionType: state.usersParams.union,
              }),
              ...(state.usersParams.groupId && {
                groupId: state.usersParams.groupId,
              }),
              ...(state.usersParams.onTheGoInvitationStatus && {
                onTheGoInvitationStatus:
                  state.usersParams.onTheGoInvitationStatus,
              }),
              ...(state.usersParams.clientId && {
                clientId: state.usersParams.clientId,
              }),
            },
          }
        );

        commit("USERSNEW_getUsers", res.data);
        commit("USERSNEW_toggleUsersFetched", true);
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    //GET ONE USER
    async USERSNEW_getUser({ commit, state, rootState }, payload) {
      if (state.currentUser?.clientUserId == payload.userId && !payload.refresh)
        return;

      try {
        const role = rootState.auth.role;
        const roleControllers = {
          co: "ClientAdmin",
          gm: "GroupManager",
          filistos: "SuperAdmin",
        };

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${roleControllers[role]}/GetUser/${payload.userId}`
        );

        commit("USERSNEW_setCurrentUser", res.data);
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async USERSNEW_inviteToSelfEval({ commit }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/SuperAdmin/EnableOnTheGoSupport`,
          payload
        );

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Self Support has been successfully enabled!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },

    async USERSNEW_disableSelfEval({ commit }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/superadmin/DisableOnTheGoSupport`,
          payload
        );

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Self Support has been successfully disabled!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },

    async USERSNEW_inviteHubSupport({ commit }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/SuperAdmin/enableHubSupport`,
          payload
        );

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Case has been successfully enabled!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },

    async USERSNEW_exportUsersExcel({ commit, rootState }, clientUserIds) {
      try {
        const role = rootState.auth.role;
        const roleControllers = {
          co: "ClientAdmin",
          gm: "GroupManager",
          filistos: "SuperAdmin",
        };

        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/${roleControllers[role]}/DownloadUsersExcel`,
          {
            clientUserIds,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/vnd.ms-excel",
            },
          }
        );

        const filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0];

        let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
        var fileURL = window.URL.createObjectURL(blob);
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Excel file has been exported!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
  },
};
