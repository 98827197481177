<template>
  <div class="flex-grow-1 px-4 grey lighten-5 d-flex flex-column">
    <v-progress-circular
      size="80"
      width="4"
      indeterminate
      color="primary"
      class="align-self-center ma-12"
      v-if="loader"
    ></v-progress-circular>

    <div>
      <v-chip
        class="ma-2 white--text"
        v-for="factor in selectedFactors"
        :key="factor.factorId"
        small
        close
        color="filyellow"
        @click:close="onFactorRemove(factor.factorId)"
      >
        {{ factor.factorName }}
      </v-chip>
    </div>

    <form @submit.prevent="submit" class="flex-grow-1 d-flex flex-column pb-1">
      <div class="scrollable">
        <v-list dense v-if="!loader" class="grey lighten-5">
          <v-list-item v-for="factor in mappedFactors" :key="factor.factorId">
            <div class="d-flex flex-grow-1">
              <v-list-item-content>
                <v-list-item-title class="rounded pa-1">
                  <div class="d-flex align-center">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      dense
                      :ripple="false"
                      :value="factor"
                      v-if="!factor.subFactors || !factor.subFactors.length"
                      v-model="selectedFactors"
                    >
                    </v-checkbox>

                    <span> {{ factor.factorName }}</span>
                  </div>
                </v-list-item-title>

                <v-list
                  dense
                  v-if="factor.subFactors && factor.subFactors.length"
                  class="grey lighten-5"
                >
                  <v-list-item
                    v-for="subfactor in factor.subFactors"
                    :key="subfactor.factorId"
                  >
                    <div class="d-flex flex-grow-1">
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-regular">
                          <div class="d-flex align-center">
                            <v-checkbox
                              class="ma-0 pa-0"
                              hide-details
                              dense
                              :ripple="false"
                              :value="subfactor"
                              v-model="selectedFactors"
                            >
                            </v-checkbox>

                            <span> {{ subfactor.factorName }}</span>
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </div>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list>
      </div>

      <v-btn
        depressed
        color="filyellow"
        class="white--text align-self-center"
        type="submit"
        :disabled="!hasChanges"
        :loading="submitLoader"
      >
        Save
      </v-btn>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      loader: false,
      selectedFactors: [],
      initialData: null,
      submitLoader: false,
    };
  },
  computed: {
    ...mapState({
      currentQuestion: (state) => state.questionnaires.currentQuestion,
      currentSection: (state) => state.questionnaires.currentSection,
      currentQuestionnaireFactors: (state) =>
        state.questionnaires.currentQuestionnaireFactors,
    }),
    mappedFactors() {
      return this.currentQuestionnaireFactors.map((f) => {
        let subFactors;

        if (f.subFactors && f.subFactors.length) {
          subFactors = f.subFactors.map((sf) => {
            return {
              factorId: sf.factorId,
              factorName: sf.factorName,
            };
          });
        }

        return {
          factorId: f.factorId,
          factorName: f.factorName,
          ...(subFactors && { subFactors }),
        };
      });
    },
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !=
        JSON.stringify(
          [...this.selectedFactors].sort((a, b) =>
            a.factorName > b.factorName
              ? 1
              : b.factorName > a.factorName
              ? -1
              : 0
          )
        )
      );
    },
  },
  async created() {
    this.loader = true;

    try {
      await this.getAllFactorsOfQuestionnaire();
    } catch (e) {
      console.log(e);
    }

    this.loader = false;

    this.initFactorData();
  },
  methods: {
    ...mapActions(["getAllFactorsOfQuestionnaire", "updateQuestion"]),
    initFactorData() {
      this.selectedFactors = [...this.currentQuestion.relatedFactors];

      this.initialData = [...this.selectedFactors].sort((a, b) =>
        a.factorName > b.factorName ? 1 : b.factorName > a.factorName ? -1 : 0
      );
    },
    onFactorRemove(factorId) {
      const index = this.selectedFactors.findIndex(
        (f) => f.factorId == factorId
      );

      if (index != -1) this.selectedFactors.splice(index, 1);
    },
    async submit() {
      this.submitLoader = true;

      try {
        await this.updateQuestion({
          sectionId: this.currentSection.sectionId,
          questionId: this.currentQuestion.questionId,
          relatedFactorIds: this.selectedFactors.map((f) => {
            return f.factorId;
          }),
        });

        this.initFactorData();
      } catch (e) {
        console.log(e);
      }

      this.submitLoader = false;
    },
  },
  destroyed() {
    this.$store.commit("getAllFactorsOfQuestionnaire", null);
  },
};
</script>

<style lang="scss" scoped>
</style>
