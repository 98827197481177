<template>
  <div class="component-wrapper px-4 pt-4 grey lighten-3 d-flex flex-column">
    <div class="d-flex mb-4">
      <v-icon class="mr-2">mdi-finance</v-icon>

      <div class="body-1 font-weight-medium">Statistics</div>
    </div>

    <div class="wrapper rounded flex-grow-1 d-flex flex-column pa-4">
      <form class="d-flex align-center mb-2" @submit.prevent="submit">
        <v-select
          label="Client"
          outlined
          dense
          hide-details
          :items="allClients"
          item-value="clientId"
          item-text="companyName"
          class="flex-grow-0 mr-2"
          clearable
          v-if="role == 'filistos'"
          v-model="selectedClientId"
        ></v-select>

        <v-menu
          max-width="290"
          v-if="role != 'psysupport' && role != 'allpsysup'"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-text-field
                outlined
                dense
                hide-details
                label="Select Questionnaire"
                clearable
                class="mr-2"
                readonly
                append-icon="mdi-menu-down"
                :value="
                  selectedQuestionnaire
                    ? selectedQuestionnaire.questionnaireName
                    : null
                "
                @click:clear="selectedQuestionnaire = null"
              >
              </v-text-field>
            </div>
          </template>

          <div class="white pa-4 questionnaire-menu">
            <div
              v-for="questionnaireType in questionnaires"
              :key="questionnaireType.questionnaireTypeId"
            >
              <div class="subtitle-2">
                {{ questionnaireType.questionnaireTypeName }}
              </div>

              <div class="pl-2">
                <div
                  v-for="questionnaire in questionnaireType.questionnaires"
                  :key="questionnaire.questionnaireId"
                >
                  <div
                    class="body-2 py-1 px-2"
                    :class="{
                      'font-weight-medium':
                        questionnaire.subQuestionnaires &&
                        questionnaire.subQuestionnaires.length,
                      questionnaire:
                        !questionnaire.subQuestionnaires ||
                        !questionnaire.subQuestionnaires.length,
                    }"
                    @click="
                      (!questionnaire.subQuestionnaires ||
                        !questionnaire.subQuestionnaires.length) &&
                        (selectedQuestionnaire = questionnaire)
                    "
                  >
                    {{ questionnaire.questionnaireName }}
                  </div>

                  <div
                    class="pl-4"
                    v-if="
                      questionnaire.subQuestionnaires &&
                        questionnaire.subQuestionnaires.length
                    "
                  >
                    <div
                      v-for="subQuestionnaire in questionnaire.subQuestionnaires"
                      :key="subQuestionnaire.questionnaireId"
                    >
                      <div
                        class="body-2 py-1 px-2 questionnaire"
                        @click="selectedQuestionnaire = subQuestionnaire"
                      >
                        <span class="font-weight-bold mr-1">-</span>
                        {{ subQuestionnaire.questionnaireName }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-menu>

        <v-menu max-width="290" :close-on-content-click="false" ref="dateFrom">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateFrom ? dayjs(dateFrom).format('DD/MM/YYYY') : ''"
              dense
              prepend-inner-icon="mdi-calendar"
              outlined
              label="Date From"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              hide-details
              class="flex-grow-0 mr-2"
              @click:clear="dateFrom = null"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="dateFrom"
            @change="$refs.dateFrom.save()"
          ></v-date-picker>
        </v-menu>

        <v-menu max-width="290" :close-on-content-click="false" ref="dateTo">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateTo ? dayjs(dateTo).format('DD/MM/YYYY') : ''"
              dense
              prepend-inner-icon="mdi-calendar"
              outlined
              label="Date To"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              class="flex-grow-0 mr-2"
              clearable
              @click:clear="dateTo = null"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="dateTo"
            @change="$refs.dateTo.save()"
          ></v-date-picker>
        </v-menu>

        <v-btn
          depressed
          color="filyellow"
          class="white--text ml-auto"
          type="submit"
          :disabled="!selectedQuestionnaire"
          :loading="loader"
        >
          Search
        </v-btn>
      </form>

      <v-btn
        class="align-self-start"
        depressed
        color="primary"
        @click="exportExcel"
        :disabled="!items.length"
        :loading="excelLoader"
        >Export excel</v-btn
      >

      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="10000"
        hide-default-footer
        hide-default-header
        id="statistics-table"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead class="thead">
            <tr>
              <th class="rotate" v-for="(h, index) in headers" :key="index">
                <div>
                  <span
                    :class="{ 'font-weight-black black--text': !h.child }"
                    >{{ h.text }}</span
                  >
                </div>
              </th>
            </tr>
          </thead>
        </template>

        <template v-slot:item="item">
          <tr v-if="item.item" class="body">
            <td v-for="(h, index) in headers" :key="index">
              <div
                class="d-flex align-center"
                v-if="Object(item.item[h.value]) === item.item[h.value]"
                :class="{
                  link: item.item[h.value] && item.item[h.value].fraction,
                }"
                @click="
                  openDialog(
                    {
                      positionId: item.item.positionId,
                      positionName: item.item.positionName,
                    },
                    {
                      nationalityId: item.item[h.value].natId,
                      nationalityName: h.text,
                    }
                  )
                "
              >
                <span class="mr-2">
                  {{
                    item.item[h.value].fraction
                      ? item.item[h.value].fraction
                      : "-"
                  }}
                </span>

                <v-progress-circular
                  size="20"
                  width="2"
                  indeterminate
                  color="primary"
                  v-if="
                    dialogLoader &&
                      dialog.positionId == item.item.positionId &&
                      dialog.nationalityId == item.item[h.value].natId
                  "
                ></v-progress-circular>
              </div>

              <div v-else>
                <div
                  v-if="
                    Date.parse(item.item[h.value]) && isNaN(item.item[h.value])
                  "
                >
                  {{
                    dayjs
                      .utc(item.item[h.value])
                      .local()
                      .format("DD/MM/YYYY")
                  }}
                </div>

                <div v-else>
                  {{ item.item[h.value] ? item.item[h.value] : "-" }}
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="dialog.open" max-width="1250px">
      <div class="white dialog-wrapper scrollable-dialog">
        <div class="d-flex mx-1 mt-1" v-if="!showReport">
          <div class="body-1 font-weight-medium mr-auto ml-1">
            {{ dialog.positionName }} | {{ dialog.nationalityName }}
          </div>

          <v-btn
            icon
            small
            class="ml-auto mt-1 mr-1"
            @click="dialog.open = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-btn
          class="align-self-start ma-2"
          depressed
          color="primary"
          @click="exportExcelUsers"
          :disabled="!items.length"
          :loading="excelUserLoader"
          >Export excel</v-btn
        >
        <div class="pa-4 pt-0 white" v-if="!showReport">
          <v-tabs v-model="tab">
            <v-tab :disabled="tabLoader">Not Completed </v-tab>

            <v-tab :disabled="tabLoader"> Completed </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-data-table
                :headers="assignmentsHeaders"
                hide-default-footer
                :items="unFinilized.data"
                class="mb-3"
                :items-per-page="-1"
              >
                <template v-slot:[`item.report`]="{ item }">
                  <v-btn
                    small
                    @click="showReport = item.assignUserQuestId"
                    width="60px"
                    v-if="tab == 1"
                    >report</v-btn
                  >

                  <div v-else>
                    <v-menu
                      top
                      right
                      :close-on-content-click="false"
                      offset-y
                      offset-x
                      ref="remindAllMenu"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          depressed
                          color="primary"
                          class="align-self-start mb-2"
                          small
                        >
                          <v-icon left>mdi-send</v-icon>
                          Remind
                        </v-btn>
                      </template>

                      <div class="white pa-5">
                        <div class="body-2 mb-4">
                          Are you sure you want to remind the user?
                        </div>

                        <v-btn
                          small
                          depressed
                          @click="onRemindAssignment(item)"
                          :loading="remindLoader"
                          color="filyellow"
                          class="white--text"
                          >Remind</v-btn
                        >
                      </div>
                    </v-menu>
                  </div>
                </template>
              </v-data-table>
            </v-tab-item>

            <v-tab-item>
              <v-data-table
                :headers="assignmentsHeaders"
                hide-default-footer
                :items="finalized.data"
                class="mb-3"
                :items-per-page="-1"
              >
                <template v-slot:[`item.report`]="{ item }">
                  <v-btn
                    small
                    @click="showReport = item.assignUserQuestId"
                    width="60px"
                    v-if="
                      tab == 1 &&
                        ((selectedQuestionnaire.questionnaireTypeId ==
                          'elearn' &&
                          item.elearningPassed) ||
                          selectedQuestionnaire.questionnaireTypeId !=
                            'elearn') &&
                        (checkGmRoleType == 'adminRights' ||
                          checkGmRoleType == 'gmCanSeeEverything')
                    "
                    >report</v-btn
                  >

                  <div class="justify-start d-flex align-center">
                    <div
                      class="white--text error pa-1 rounded"
                      v-if="
                        tab == 1 &&
                          selectedQuestionnaire.questionnaireTypeId ==
                            'elearn' &&
                          !item.elearningPassed
                      "
                    >
                      Failed
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <AssignmentReport
          @closeReport="showReport = false"
          v-else
          :reportIdProp="showReport"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AssignmentReport from "@/views/admin/assignment/assignment-dialog/report/AssignmentReport";
import axios from "axios";

export default {
  components: {
    AssignmentReport,
  },

  data() {
    return {
      remindLoader: false,
      selectedQuestionnaire: null,
      dateFrom: this.dayjs()
        .subtract(2, "months")
        .toISOString()
        .substr(0, 10),
      dateTo: null,
      loader: false,
      headers: [],
      items: [],
      dialog: { open: false, positionId: null, nationalityId: null },
      dialogLoader: false,
      finalized: {
        fetched: false,
        data: [],
      },
      unFinilized: {
        fetched: false,
        data: [],
      },
      tab: 0,
      tabLoader: false,
      excelUserLoader: false,
      excelLoader: false,
      assignmentsHeaders: [
        { text: "Client", value: "clientName" },
        { text: "Full Name", value: "clientUserFullName" },
        { text: "Nationality", value: "nationalityName" },
        { text: "Position", value: "positionName" },
        { text: "Report", value: "report" },
      ],
      showReport: false,
      selectedClientId: null,
    };
  },
  computed: {
    ...mapState({
      questionnaires: (state) => state.base.questionnaires,
      role: (state) => state.auth.role,
      allClients: (state) => state.base.allClients,
      info: (state) => state.base.info,
    }),

    checkGmRoleType() {
      if (this.role == "gm") {
        if (
          this.info.currentLogin.gmCanAccessElearning ||
          this.info.currentLogin.gmCanAccessForms
        ) {
          return "gmCanSeeReport";
        } else if (this.info.currentLogin.gmCanReadResults) {
          return "gmCanSeeEverything";
        } else {
          return "gmCanSeeNothing";
        }
      } else {
        return "adminRights";
      }
    },

    controller() {
      const roleControllers = {
        co: "ClientAdmin",
        gm: "GroupManager",
        filistos: "SuperAdmin",
      };

      return roleControllers[this.role];
    },
  },
  methods: {
    ...mapActions(["remindAssignment"]),

    async onRemindAssignment(item) {
      this.remindLoader = true;

      try {
        console.log(item);
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientUser/RemindAssignment`,
          {
            assignUserQuestId: item.assignUserQuestId,
            userIds: [item.clientUserId],
          }
        );

        this.$store.commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "User has been reminded!",
          timeout: 5000,
        });
      } catch (e) {
        console.log(e);
      }
      this.remindLoader = false;
    },

    async exportExcel() {
      this.excelLoader = true;

      const res = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/${this.controller}/DownloadStatisticsToExcel`,
        {
          questionnaireId: this.selectedQuestionnaire.questionnaireId,
          dateFrom: this.dateFrom
            ? this.dayjs.utc(this.dateFrom).toISOString()
            : null,
          dateTo: this.dateTo
            ? this.dayjs.utc(this.dateTo).toISOString()
            : null,
          clientId: this.role == "filistos" ? this.selectedClientId : undefined,
        },
        {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/vnd.ms-excel",
          },
        }
      );

      const filename = res.headers["content-disposition"]
        .split("filename=")[1]
        .split(";")[0];

      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      var fileURL = window.URL.createObjectURL(blob);
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);
      fileLink.click();

      this.excelLoader = false;
    },

    async exportExcelUsers() {
      this.excelUserLoader = true;
      const res = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/clientuser/DownloadAssignmentsOfNatAndRankToExcel`,
        {
          questionnaireId: this.selectedQuestionnaire.questionnaireId,
          dateFrom: this.dateFrom
            ? this.dayjs.utc(this.dateFrom).toISOString()
            : null,
          dateTo: this.dateTo
            ? this.dayjs.utc(this.dateTo).toISOString()
            : null,
          positionId:
            this.dialog.positionId == "sumOfNat"
              ? null
              : this.dialog.positionId,
          // isFinalized: true,
          nationalityId:
            this.dialog.nationalityId == "sumOfRank"
              ? null
              : this.dialog.nationalityId,
          clientId: this.role == "filistos" ? this.selectedClientId : undefined,
        },
        {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/vnd.ms-excel",
          },
        }
      );

      const filename = res.headers["content-disposition"]
        .split("filename=")[1]
        .split(";")[0];

      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      var fileURL = window.URL.createObjectURL(blob);
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);
      fileLink.click();

      this.excelUserLoader = false;
    },

    async openDialog(position, nationality) {
      this.dialog = {
        ...this.dialog,
        ...position,
        ...nationality,
      };

      this.dialogLoader = true;

      try {
        this.unFinilized.data = (
          await this.fetchAssignments(false)
        ).data?.results;

        this.unFinilized.fetched = true;

        this.dialog = { ...this.dialog, open: true };
      } catch (e) {
        console.log(e);

        this.dialog = { open: false, positionId: null, nationalityId: null };

        this.finalized = {
          fetched: false,
          data: [],
        };

        this.unFinilized = {
          fetched: false,
          data: [],
        };
      }

      this.dialogLoader = false;
    },
    async fetchAssignments(isFinalized) {
      return axios.post(
        `${process.env.VUE_APP_BASE_URL}/${this.controller}/GetAssignmentsOfNatAndRank`,
        {
          questionnaireId: this.selectedQuestionnaire.questionnaireId,
          dateFrom: this.dateFrom
            ? this.dayjs.utc(this.dateFrom).toISOString()
            : null,
          dateTo: this.dateTo
            ? this.dayjs.utc(this.dateTo).toISOString()
            : null,
          positionId:
            this.dialog.positionId == "sumOfNat"
              ? null
              : this.dialog.positionId,
          nationalityId:
            this.dialog.nationalityId == "sumOfRank"
              ? null
              : this.dialog.nationalityId,
          isFinalized,
          clientId: this.role == "filistos" ? this.selectedClientId : undefined,
        }
      );
    },
    async submit() {
      this.loader = true;

      try {
        this.headers = (
          await axios.post(
            `${process.env.VUE_APP_BASE_URL}/${this.controller}/GetStatisticsColumns`,
            {
              questionnaireId: this.selectedQuestionnaire.questionnaireId,
              dateFrom: this.dateFrom
                ? this.dayjs.utc(this.dateFrom).toISOString()
                : null,
              dateTo: this.dateTo
                ? this.dayjs.utc(this.dateTo).toISOString()
                : null,
              clientId:
                this.role == "filistos" ? this.selectedClientId : undefined,
            }
          )
        ).data?.results;

        this.headers = this.headers.filter(
          (header) => header.value != "positionId"
        );

        this.items = (
          await axios.post(
            `${process.env.VUE_APP_BASE_URL}/${this.controller}/Statistics`,
            {
              questionnaireId: this.selectedQuestionnaire.questionnaireId,
              dateFrom: this.dateFrom
                ? this.dayjs.utc(this.dateFrom).toISOString()
                : null,
              dateTo: this.dateTo
                ? this.dayjs.utc(this.dateTo).toISOString()
                : null,
              clientId:
                this.role == "filistos" ? this.selectedClientId : undefined,
            }
          )
        ).data.results;
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    onShowReport(item) {
      console.log(item);
    },
  },
  watch: {
    "dialog.open"(newValue) {
      if (!newValue) {
        this.dialog = { open: false, positionId: null, nationalityId: null };

        this.finalized = {
          fetched: false,
          data: [],
        };

        this.unFinilized = {
          fetched: false,
          data: [],
        };

        this.tab = 0;

        this.showReport = false;
      }
    },
    async tab(newValue) {
      if (newValue == 1 && !this.finalized.fetched) {
        this.tabLoader = true;

        try {
          this.finalized.data = (
            await this.fetchAssignments(true)
          ).data?.results;
          this.finalized.fetched = true;
        } catch (e) {
          console.log(e);
        }

        this.tabLoader = false;
      }
    },
  },
};
</script>

<style lang="scss">
.questionnaire-menu {
  height: 400px;
  overflow-y: auto;
}

.thead {
  height: 140px;
}

th.rotate {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
  padding: 0 !important;
}

th.rotate > div {
  transform: 
    /* Magic Numbers */ translate(50%, 54px)
    /* 45 is really 360 - 45 */ rotate(270deg);
  width: 30px;
}
th.rotate > div > span {
  padding: 5px 10px;
}

tr.body {
  td {
    padding: 0 8px !important;
    border-right: 1px solid #ccc;
    white-space: nowrap;
  }
}

.filred-bg {
  background-color: rgb(255, 208, 208);
}

.filyellow-bg {
  background-color: rgb(255, 255, 208);
}

.filgreen-bg {
  background-color: rgb(208, 255, 208);
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.theme--light.v-data-table#statistics-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none;
}

.questionnaire,
.period {
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
}
</style>
