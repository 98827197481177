<template>
  <div class="component-wrapper px-4 pt-4 grey lighten-3 d-flex flex-column">
    <div class="d-flex mb-4">
      <v-icon class="mr-2">mdi-chart-bar</v-icon>

      <div class="body-1 font-weight-medium">Comparison</div>
    </div>

    <div class="wrapper rounded flex-grow-1 d-flex flex-column pa-4">
      <form
        class="d-flex align-center mb-2"
        @submit.prevent="onSelectQuestionnaire"
      >
        <div class="d-flex flex-column flex-grow-1 mr-4">
          <div class="d-flex align-center mb-2">
            <v-menu
              max-width="290"
              v-if="role != 'psysupport' && role != 'allpsysup'"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    label="Select Questionnaire"
                    clearable
                    class="mr-2"
                    readonly
                    append-icon="mdi-menu-down"
                    :value="
                      selectedQuestionnaire
                        ? selectedQuestionnaire.questionnaireName
                        : null
                    "
                    @click:clear="selectedQuestionnaire = null"
                  >
                  </v-text-field>
                </div>
              </template>

              <div class="white pa-4 questionnaire-menu">
                <div
                  v-for="questionnaireType in questionnaires"
                  :key="questionnaireType.questionnaireTypeId"
                >
                  <div class="subtitle-2">
                    {{ questionnaireType.questionnaireTypeName }}
                  </div>

                  <div class="pl-2">
                    <div
                      v-for="questionnaire in questionnaireType.questionnaires"
                      :key="questionnaire.questionnaireId"
                    >
                      <div
                        class="body-2 py-1 px-2"
                        :class="{
                          'font-weight-medium':
                            questionnaire.subQuestionnaires &&
                            questionnaire.subQuestionnaires.length,
                          questionnaire:
                            !questionnaire.subQuestionnaires ||
                            !questionnaire.subQuestionnaires.length,
                        }"
                        @click="
                          (!questionnaire.subQuestionnaires ||
                            !questionnaire.subQuestionnaires.length) &&
                            (selectedQuestionnaire = questionnaire)
                        "
                      >
                        {{ questionnaire.questionnaireName }}
                      </div>

                      <div
                        class="pl-4"
                        v-if="
                          questionnaire.subQuestionnaires &&
                            questionnaire.subQuestionnaires.length
                        "
                      >
                        <div
                          v-for="subQuestionnaire in questionnaire.subQuestionnaires"
                          :key="subQuestionnaire.questionnaireId"
                        >
                          <div
                            class="body-2 py-1 px-2 questionnaire"
                            @click="selectedQuestionnaire = subQuestionnaire"
                          >
                            <span class="font-weight-bold mr-1">-</span>
                            {{ subQuestionnaire.questionnaireName }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-menu>

            <v-select
              label="Client"
              outlined
              dense
              hide-details
              :items="allClients"
              item-value="clientId"
              item-text="companyName"
              class="flex-grow-0 mr-2"
              @change="onGetClient"
              clearable
              :loading="clientLoader"
              v-if="role == 'filistos' || role == 'allpsysup'"
              v-model="selectedClientId"
            ></v-select>

            <v-select
              label="Questionnaire"
              outlined
              dense
              hide-details
              :items="[
                { label: 'Self Support', value: 'onthego' },
                { label: 'Case', value: 'hub' },
              ]"
              item-value="value"
              item-text="label"
              class="flex-grow-0 mr-2"
              clearable
              v-if="role == 'allpsysup' || role == 'psysupport'"
              v-model="selectedApp"
              @input="onQuestionnaireChange"
            ></v-select>

            <v-select
              label="Factor Groups"
              outlined
              dense
              hide-details
              :items="factorGroups"
              item-value="factorGroupId"
              item-text="factorGroupName"
              class="flex-grow-0 mr-2"
              clearable
              v-if="(role == 'co' || role == 'gm') && info.hasFactorGroups"
              v-model="selectedFactorGroups"
              :disabled="!selectedQuestionnaire"
              :loading="assignmentLoader"
              multiple
              chips
              deletable-chips
              scrollable
            >
              <!-- <template #selection="{ item }">
                <v-chip color="primary">{{ item.factorGroupName }}</v-chip>
              </template> -->
            </v-select>

            <v-menu
              max-width="290"
              :close-on-content-click="false"
              ref="dateFrom"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateFrom ? dayjs(dateFrom).format('DD/MM/YYYY') : ''"
                  dense
                  prepend-inner-icon="mdi-calendar"
                  outlined
                  label="Date From"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  hide-details
                  class="flex-grow-0 mr-2"
                  @click:clear="
                    dateFrom = null;
                    onQuestionnaireChange();
                  "
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="dateFrom"
                @change="onQuestionnaireChange()"
              ></v-date-picker>
            </v-menu>

            <v-menu
              max-width="290"
              :close-on-content-click="false"
              ref="dateTo"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateTo ? dayjs(dateTo).format('DD/MM/YYYY') : ''"
                  dense
                  prepend-inner-icon="mdi-calendar"
                  outlined
                  label="Date To"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  class="flex-grow-0 mr-2"
                  clearable
                  @click:clear="
                    dateTo = null;
                    onQuestionnaireChange;
                  "
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="dateTo"
                @change="onQuestionnaireChange"
              ></v-date-picker>
            </v-menu>
          </div>

          <div class="d-flex align-center">
            <v-autocomplete
              outlined
              dense
              hide-details
              label="Select Assignment"
              clearable
              class="flex-grow-0 mr-2"
              :items="assignments"
              item-text="assignmentName"
              item-value="assignmentId"
              :disabled="!selectedQuestionnaire"
              :loading="assignmentLoader"
              v-model="selectedAssignments"
              multiple
              v-if="role != 'psysupport' && role != 'allpsysup'"
            >
            </v-autocomplete>

            <v-select
              outlined
              dense
              hide-details
              class="flex-grow-0 mr-2"
              label="Nationality"
              :items="countries"
              item-value="itemKey"
              item-text="itemValue"
              clearable
              v-model="nationality"
              v-if="role != 'psysupport' && role != 'allpsysup'"
            ></v-select>

            <v-radio-group
              v-model="positionType"
              class="flex-grow-0 pa-0 ma-0 mr-2"
              dense
              hide-details
              v-if="role != 'psysupport' && role != 'allpsysup'"
            >
              <v-radio
                v-for="type in userTypes"
                :key="type.itemKey"
                :value="type.itemKey"
                :label="type.itemValue"
                :ripple="false"
                class="mb-1"
              ></v-radio>
            </v-radio-group>

            <v-select
              dense
              outlined
              hide-details
              label="Position"
              :items="positions"
              item-value="itemKey"
              item-text="itemValue"
              v-model="position"
              class="flex-grow-0"
              clearable
              multiple
              v-if="role != 'psysupport' && role != 'allpsysup'"
            ></v-select>
          </div>
        </div>

        <v-btn
          type="submit"
          depressed
          color="filyellow"
          class="white--text"
          :disabled="
            ((!selectedClientId || !selectedQuestionnaire) &&
              role == 'filistos') ||
              ((!selectedClientId || !selectedApp) && role == 'allpsysup') ||
              (!selectedApp && role == 'psysupport')
          "
          :loading="isFirstCall && loader"
          >Search</v-btn
        >
      </form>

      <template v-if="userQuestsFetched">
        <div class="scrollable mb-2">
          <v-data-table
            :headers="headers"
            :items="userQuests.results"
            :server-items-length="userQuests.rowCount"
            show-select
            checkbox-color="primary"
            v-model="selectedUserQuests"
            item-key="assignUserQuestId"
            :options.sync="options"
            @update:options="onGetUserQuests(true)"
            hide-default-footer
            :loading="!isFirstCall && loader"
          >
          </v-data-table>
        </div>

        <v-btn
          @click="onCompare"
          class="mt-auto align-self-center white--text"
          depressed
          color="filyellow"
          :disabled="!selectedUserQuests.length"
          >Compare</v-btn
        >
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      selectedQuestionnaire: null,
      headers: [
        { text: "Full Name", value: "fullName" },
        { text: "Nationality", value: "nationalityName" },
        { text: "Position", value: "positionName" },
        { text: "Questionnaire", value: "questionnaireName" },
        { text: "Assignment", value: "assignment" },
      ],
      factorGroups: [],
      selectedFactorGroups: [],
      selectedUserQuests: [],
      isFirstCall: true,
      userQuestsFetched: false,
      loader: false,
      assignments: [],
      assignmentLoader: false,
      selectedAssignments: [],
      dateFrom: this.dayjs()
        .subtract(2, "months")
        .toISOString()
        .substr(0, 10),
      dateTo: null,
      nationality: null,
      positionType: null,
      position: [],
      initAssignments: true,
      selectedClient: null,
      selectedClientId: null,
      clientLoader: false,
      selectedApp: null,
    };
  },
  computed: {
    ...mapState({
      questionnaires: (state) => state.base.questionnaires,
      role: (state) => state.auth.role,
      allClients: (state) => state.base.allClients,
      countries: (state) => state.base.lists.countries,
      userQuestsParams: (state) => state.comparison.userQuestsParams,
      userQuests: (state) => state.comparison.userQuests,
      factorGroupIds: (state) => state.comparison.factorGroupIds,

      info: (state) => state.base.info,
    }),
    userTypes() {
      if (this.role == "filistos") {
        return this.selectedClient?.userTypes
          ? this.selectedClient?.userTypes
          : [];
      } else {
        return this.$store.state.base.lists.userTypes;
      }
    },
    positions() {
      if (this.role == "filistos") {
        if (!this.positionType) return [];

        return this.selectedClient?.workPositions?.find(
          (wp) => wp.itemType == this.positionType
        )?.items;
      } else {
        if (!this.positionType) return [];

        return this.$store.state.base.lists[this.positionType];
      }
    },
    options: {
      get() {
        const { itemsPerPage, page, sortBy, sortDesc } = this.userQuestsParams;

        return {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        };
      },
      set(value) {
        let { itemsPerPage, page, sortBy, sortDesc } = value;

        this.setUserQuestsParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      },
    },
  },
  created() {
    if (this.role == "psysupport" || this.role == "allpsysup") {
      this.headers = [
        { text: "Full Name", value: "fullName" },
        { text: "Nationality", value: "nationalityName" },
        { text: "Position", value: "positionName" },
      ];
    }
    this.selectedFactorGroups = this.factorGroupIds;

    this.selectedQuestionnaire = this.userQuestsParams?.questionnaire;

    this.dateFrom = this.userQuestsParams.dateFrom
      ? this.dayjs
          .utc(this.userQuestsParams.dateFrom)
          .local()
          .toISOString()
          .substr(0, 10)
      : this.dayjs()
          .subtract(2, "months")
          .toISOString()
          .substr(0, 10);

    this.dateTo = this.userQuestsParams.dateTo
      ? this.dayjs
          .utc(this.userQuestsParams.dateTo)
          .local()
          .toISOString()
          .substr(0, 10)
      : null;

    this.selectedAssignments = this.userQuestsParams.assignments;
    this.nationality = this.userQuestsParams.nationalityId;
    this.positionType = this.userQuestsParams.position.positionType;
    this.position = this.userQuestsParams.position.positionIds;
    this.selectedClient = this.userQuestsParams.client;
    this.selectedClientId = this.selectedClient?.clientId;

    if (this.selectedQuestionnaire) {
      this.userQuestsFetched = true;
    }
  },
  methods: {
    ...mapMutations([
      "setUserQuestsParams",
      "setUserQuestsToCompare",
      "setFactorGroupIds",
      "clearComparisonState",
      "setPositionsToCompare",
    ]),
    ...mapActions([
      "getUserQuests",
      "getComparisonAssignmentsList",
      "getComparisonClient",
    ]),
    async onGetClient(e) {
      if (!this.selectedClientId) return;

      this.assignmentLoader = true;

      try {
        if (e) {
          this.selectedClient = await this.getComparisonClient(e);
        } else {
          this.selectedClient = null;
        }

        this.setUserQuestsParams({
          client: this.selectedClient,
        });

        if (this.role == "filistos") {
          this.assignments = await this.getComparisonAssignmentsList({
            questionnaireId: this.selectedQuestionnaire?.questionnaireId,
            dateFrom: this.dateFrom
              ? this.dayjs.utc(this.dateFrom).toISOString()
              : null,
            dateTo: this.dateTo
              ? this.dayjs.utc(this.dateTo).toISOString()
              : null,
            clientId: this.selectedClient?.clientId,
          });
        }
      } catch (e) {
        console.log(e);
      }

      this.assignmentLoader = false;
    },
    async onQuestionnaireChange() {
      this.$refs?.dateFrom?.save();
      this.$refs?.dateTo?.save();
      // console.log(this.selectedClient, "selected client");

      if (this.role == "filistos") {
        if (!this.selectedQuestionnaire || !this.selectedClientId) return;
      } else {
        if (!this.selectedQuestionnaire) return;
      }

      this.assignmentLoader = true;
      try {
        this.assignments = await this.getComparisonAssignmentsList({
          questionnaireId: this.selectedQuestionnaire.questionnaireId,
          dateFrom: this.dateFrom
            ? this.dayjs.utc(this.dateFrom).toISOString()
            : null,
          dateTo: this.dateTo
            ? this.dayjs.utc(this.dateTo).toISOString()
            : null,
          client: this.selectedClient.clientId,
        });

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/clientuser/GetFactorGroups`,
          {
            params: {
              questionnaireId: this.selectedQuestionnaire.questionnaireId,
            },
          }
        );
        this.factorGroups = res.data.results;
      } catch (e) {
        console.log(e);
      }

      this.assignmentLoader = false;
    },
    async onGetUserQuests(dataTableFirstCall) {
      if (dataTableFirstCall && this.isFirstCall) {
        this.isFirstCall = false;
        return;
      }

      this.loader = true;

      try {
        await this.getUserQuests();
        this.userQuestsFetched = true;
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    async onSelectQuestionnaire() {
      this.setUserQuestsParams({
        questionnaire: this.selectedQuestionnaire,
        assignments: this.selectedAssignments,
        dateFrom: this.dateFrom
          ? this.dayjs.utc(this.dateFrom).toISOString()
          : null,
        dateTo: this.dateTo ? this.dayjs.utc(this.dateTo).toISOString() : null,
        nationalityId: this.nationality,
        position: {
          positionType: this.positionType,
          positionIds: this.position,
        },
        selectedApp: this.selectedApp,
        clientId: this.selectedClient?.clientId,
      });

      this.onGetUserQuests();
    },
    async onCompare() {
      try {
        const assignUserQuestIds = this.selectedUserQuests.map(
          (uq) => uq.assignUserQuestId
        );

        const positionIds = this.selectedUserQuests.map((uq) => uq.positionId);

        this.setUserQuestsToCompare(assignUserQuestIds);
        this.setFactorGroupIds(this.selectedFactorGroups);
        this.setPositionsToCompare(positionIds);
        this.set;
        const roles = {
          co: "",
          gm: "",
          filistos: "/superadmin",
          psysupport: "/psysupport",
          allpsysup: "/allpsysup",
        };

        this.$router.push({
          path: `${roles[this.role]}/comparison/results`,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    selectedQuestionnaire(newValue) {
      if (!newValue) {
        this.isFirstCall = true;
        this.userQuestsFetched = false;
        this.assignments = [];
        this.selectedAssignments = [];
      } else {
        if (this.initAssignments) {
          this.initAssignments = false;
        } else {
          this.selectedAssignments = [];
        }
        this.onQuestionnaireChange();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name != "adminComparisonResults" &&
      to.name != "superAdminComparisonResults" &&
      to.name != "psysupComparisonResults" &&
      to.name != "allPsysupComparisonResults"
    )
      this.clearComparisonState();

    next();
  },
};
</script>

<style lang="scss" scoped>
.questionnaire-menu {
  height: 400px;
  overflow-y: auto;
}

.questionnaire,
.period {
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
}
</style>
