<template>
  <div class="component-wrapper px-5 pt-5 d-flex flex-column">
    <v-btn depressed small @click="$router.go(-1)">
      <v-icon left> mdi-chevron-left </v-icon>
      Back
    </v-btn>

    <iframe
      :src="`${baseUrl}/quiz/${questionnaire.url}`"
      class="flex-grow-1"
    ></iframe>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL.split("/api")[0],
    };
  },
  computed: {
    ...mapState({
      questionnaire: (state) => state.assignee.questionnaire,
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
