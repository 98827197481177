<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="primary pa-3">
      <div class="header white--text d-flex justify-center">
        <v-btn icon class="back-btn right">
          <v-icon color="white" @click="toggleDialog({ open: false })"
            >mdi-close</v-icon
          >
        </v-btn>

        <div class="text-h6 text-center">My Licenses</div>
      </div>
    </div>

    <div class="scrollable pa-5">
      <div
        v-for="qtype in questionnaires"
        :key="qtype.questionnaireTypeId"
        class="mb-2"
      >
        <div class="subtitle-1 font-weight-bold">
          {{ qtype.questionnaireTypeName }}
        </div>

        <div
          class="pl-5 d-flex align-center"
          v-for="q in qtype.questionnaires"
          :key="q.questionnaireId"
        >
          <div class="body-2 mr-2">
            {{ q.questionnaireName }}
          </div>

          <div class="subtitle-2">
            ({{ q.isUnlimited ? "Unlimited" : q.remainingUnits }})
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      questionnaires: (state) => state.base.questionnaires,
    }),
  },
  methods: {
    ...mapMutations(["toggleDialog"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
