<template>
  <div class="pa-5 d-flex flex-1 white">
    <div class="d-flex flex-column flex-1">
      <div class="mr-5 mb-5 elevation-2 pa-5 rounded">
        <div
          class="d-flex align-center panel-header"
          @click="basicInfoPanel = !basicInfoPanel"
        >
          <div class="subtitle-2">Basic Information</div>

          <v-icon class="ml-auto">
            {{ basicInfoPanel ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </div>

        <div class="d-flex pt-5 mb-5" v-if="basicInfoPanel">
          <div class="d-flex flex-column mr-5">
            <div class="d-flex mb-2">
              <div class="body-2 mr-16">Title:</div>
              <div class="body-2 font-weight-medium">
                {{
                  currentNotification.title ? currentNotification.title : "-"
                }}
              </div>
            </div>
            <div class="d-flex mb-2">
              <div class="body-2 mr-5">Importance:</div>
              <div class="body-2 font-weight-medium">
                {{
                  currentNotification.importance
                    ? currentNotification.importance
                    : "-"
                }}
              </div>
            </div>
            <div class="d-flex mb-1">
              <div class="body-2 mr-8">Message:</div>
              <div
                class="body-2 mt-1"
                v-html="currentNotification.bodyHtml"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column flex-1">
      <div
        class="mb-5 elevation-2 pa-5 rounded d-flex flex-column"
        :class="{ 'flex-1': usersPanel }"
      >
        <div
          class="d-flex align-center justify-space-between panel-header"
          @click="usersPanel = !usersPanel"
          :class="{ 'mb-5': usersPanel }"
        >
          <div class="subtitle-2">Users</div>

          <v-icon>
            {{ usersPanel ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </div>

        <template v-if="usersPanel">
          <div class="body-2" v-if="!currentNotification.receivers.length">
            No users added.
          </div>

          <div class="scrollable" v-else>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Identity</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Email</th>
                    <th class="text-left">Date Opened</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="user in currentNotification.receivers"
                    :key="user.clientUserId"
                  >
                    <td>{{ user.identityNumber }}</td>
                    <td>{{ user.firstName }} {{ user.lastName }}</td>
                    <td>{{ user.userEmail ? user.userEmail : "-" }}</td>
                    <td>
                      {{
                        user.messageDateOpened
                          ? dayjs
                              .utc(user.messageDateOpened)
                              .local()
                              .format("DD/MM/YYYY")
                          : "-"
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      basicInfoPanel: true,
      usersPanel: true,
    };
  },

  computed: {
    ...mapState({
      currentNotification: (state) => state.notifications.notification,
    }),
  },
};
</script>

<style>
.scrollable {
  height: 208px;
}

.panel-header {
  cursor: pointer;
}
</style>