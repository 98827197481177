var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{staticClass:"my-2",staticStyle:{"width":"300px"},attrs:{"dense":"","outlined":"","hide-details":"","label":"Status","items":[
      { name: 'All', value: 'all' },
      { name: 'Green', value: 'green' },
      { name: 'Yellow and Red', value: 'yellowandred' } ],"item-value":"value","item-text":"name","clearable":""},on:{"change":function($event){return _vm.onFilterChange($event)}},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}}),_vm._l((_vm.graphReport.factors),function(factor,index){return _c('div',{key:(index + "-" + (factor.factorId)),class:{ 'mb-8': index != _vm.graphReport.factors.length - 1 }},[_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-1 d-flex align-center",attrs:{"cols":"4"}},[_c('div',{staticClass:"subtitle-2 mr-2"},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(factor.factorName)+" ")]),_c('div',{staticClass:"black--text"},[_c('div',{staticClass:"caption font-weight-bold"},[_vm._v("("+_vm._s(factor.aScore)+")")])])]),_c('v-col',{staticClass:"pa-1 pl-0 d-flex align-center justify-center",attrs:{"cols":"8"}},[_c('v-tooltip',{attrs:{"top":"","right":"","nudge-bottom":"5px","color":_vm.getFactorColor(factor.colorFlag)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-progress-linear',_vm._g(_vm._b({attrs:{"value":factor.aScore,"color":_vm.getFactorColor(factor.colorFlag),"height":"20px"}},'v-progress-linear',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(factor.factorName))]),_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(factor.aScore))])])])],1)],1),_vm._l((factor.subFactors),function(subfactor,index){return _c('v-row',{key:(index + "-" + (subfactor.factorId)),class:{ 'mb-1': index != factor.subFactors.length - 1 },attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-5 d-flex align-center",attrs:{"cols":"4"}},[_c('div',{staticClass:"caption mr-3"},[_vm._v(_vm._s(subfactor.factorName))]),_c('div',{class:_vm.statusFilter ==
            {
              'filyellow--text': subfactor.colorFlag == 'Y',
              'error--text': subfactor.colorFlag == 'R',
              'success--text': subfactor.colorFlag == 'G',
            }},[_c('div',{staticClass:"caption font-weight-bold"},[_vm._v("("+_vm._s(subfactor.aScore)+")")])])]),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"8"}},[_c('v-tooltip',{attrs:{"top":"","right":"","nudge-bottom":"5px","color":_vm.getColor(subfactor.colorFlag)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-progress-linear',_vm._g(_vm._b({attrs:{"value":subfactor.aScore,"color":_vm.getColor(subfactor.colorFlag),"height":"10px"}},'v-progress-linear',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(subfactor.factorName))]),_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(subfactor.aScore))])])])],1)],1)})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }