var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"border-wrapper pa-4 rounded mb-4"},[_c('div',{staticClass:"subtitle-1 text-center font-weight-medium mb-1"},[_vm._v(" Highest Factor ")]),_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-1 d-flex align-center"},[_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.report.highestFactor.factorName))])]),_c('v-col',{staticClass:"pa-1 d-flex align-center justify-center"},[_c('v-progress-linear',{attrs:{"value":_vm.report.highestFactor.aScore,"color":_vm.getColor(_vm.report.highestFactor.colorFlag)}})],1),_c('v-col',{staticClass:"pa-1 d-flex align-center justify-center"},[_c('div',{staticClass:"subtitle-2",class:{
            'filyellow--text': _vm.report.highestFactor.colorFlag == 'Y',
            'error--text': _vm.report.highestFactor.colorFlag == 'R',
            'success--text': _vm.report.highestFactor.colorFlag == 'G',
          }},[_vm._v(" "+_vm._s(_vm.report.highestFactor.aScore)+" ")])])],1),_c('div',{staticClass:"body-2 text-center"},[_vm._v(" "+_vm._s(_vm.report.highestFactor.factorText)+" ")])],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-1 mr-4"},[_c('div',{staticClass:"border-wrapper pa-4 rounded"},[_c('div',{staticClass:"subtitle-1 font-weight-medium mb-4"},[_vm._v("Positive Factors")]),_vm._l((_vm.report.positiveFactors),function(factor,index){return _c('v-row',{key:(index + "-" + (factor.factorId)),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-1 d-flex align-center"},[_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(factor.factorName))])]),_c('v-col',{staticClass:"pa-1 d-flex align-center justify-center"},[_c('v-progress-linear',{attrs:{"value":factor.aScore,"color":_vm.getColor(factor.colorFlag)}})],1),_c('v-col',{staticClass:"pa-1 d-flex align-center justify-center"},[_c('div',{staticClass:"subtitle-2",class:{
                'filyellow--text': factor.colorFlag == 'Y',
                'error--text': factor.colorFlag == 'R',
                'success--text': factor.colorFlag == 'G',
              }},[_vm._v(" "+_vm._s(factor.aScore)+" ")])])],1)})],2)]),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"border-wrapper pa-4 rounded"},[_c('div',{staticClass:"subtitle-1 font-weight-medium mb-4"},[_vm._v("Negative Factors")]),_vm._l((_vm.report.negativeFactors),function(factor,index){return _c('v-row',{key:(index + "-" + (factor.factorId)),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-1 d-flex align-center"},[_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(factor.factorName))])]),_c('v-col',{staticClass:"pa-1 d-flex align-center justify-center"},[_c('v-progress-linear',{attrs:{"value":factor.aScore,"color":_vm.getColor(factor.colorFlag)}})],1),_c('v-col',{staticClass:"pa-1 d-flex align-center justify-center"},[_c('div',{staticClass:"subtitle-2",class:{
                'filyellow--text': factor.colorFlag == 'Y',
                'error--text': factor.colorFlag == 'R',
                'success--text': factor.colorFlag == 'G',
              }},[_vm._v(" "+_vm._s(factor.aScore)+" ")])])],1)})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }