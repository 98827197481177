<template>
  <div
    class="flex-grow-1 d-flex flex-column white"
    style="height: auto;min-height: 800px;"
  >
    <v-progress-circular
      size="80"
      width="4"
      indeterminate
      color="primary"
      v-if="loader"
      class="ma-auto"
    ></v-progress-circular>

    <div v-else class="">
      <div class="d-flex align-center primary py-5 px-4">
        <div class="subtitle-2 white--text">
          {{ trainingNeeds[0].questionnaireName }}
        </div>

        <div class="text-h6 mx-4 white--text">|</div>

        <div class="body-2 mr-2 white--text">Completion Date:</div>

        <div class="caption px-1 rounded success white--text">
          {{ dayjs(trainingNeeds[0].dateCompleted).format("DD/MM/YYYY") }}
        </div>

        <v-btn icon class="ml-auto" @click="$emit('closeDialog')">
          <v-icon color="white">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <v-expansion-panels
        v-if="!showReportDialog"
        multiple
        v-model="factorsTabs"
        class="px-4"
      >
        <v-expansion-panel
          :readonly="factor.elearnings && factor.elearnings.length == 0"
          v-for="factor in trainingNeeds[0].factors"
          :key="factor.factorName"
        >
          <v-expansion-panel-header>
            <template v-slot:actions>
              <div class="d-flex align-center">
                <v-icon v-if="factor.elearnings.length > 0">
                  mdi-chevron-down
                </v-icon>
                <v-icon v-else> </v-icon>
              </div>
            </template>
            <div class="d-flex align-center">
              <div class="subtitle-2">
                {{ factor.factorName }}
              </div>

              <div class="text-h6 mx-4">|</div>

              <div
                class="bullet large filred"
                :class="factor.factorFlag == 'R' ? 'filred' : 'filyellow'"
              >
                {{ factor.factorResult }}
              </div>
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content expand class="pt-4">
            <v-data-table
              v-if="factor.elearnings"
              :items="factor.elearnings"
              :headers="elearningsHeaders"
              hide-default-footer
              checkbox-color="primary"
              class="mb-4"
              show-select
              :item-class="itemRowBackground"
            >
              <template v-slot:[`header.data-table-select`]></template>

              <template v-slot:[`item.data-table-select`]="{ item }">
                <v-simple-checkbox
                  color="primary"
                  :value="item.clientOwnsElearning"
                ></v-simple-checkbox>
              </template>
              <template
                v-slot:[`item.recommendedElearningAssignedOn`]="{ item }"
              >
                <div v-if="item.recommendedElearningAssignedOn">
                  {{
                    dayjs(item.recommendedElearningAssignedOn).format(
                      "DD/MM/YYYY"
                    )
                  }}
                </div>
                <div v-else>
                  -
                </div>
              </template>

              <template
                v-slot:[`item.recommendedElearningCompletedOn`]="{ item }"
              >
                <div v-if="item.recommendedElearningCompletedOn">
                  {{
                    dayjs(item.recommendedElearningCompletedOn).format(
                      "DD/MM/YYYY"
                    )
                  }}
                </div>
                <div v-else>
                  -
                </div>
              </template>

              <template v-slot:[`item.recommendedElearningPassed`]="{ item }">
                <div
                  v-if="
                    item.recommendedElearningPassed &&
                      info.currentLogin.gmCanAccessElearning
                  "
                  style="width: max-content"
                  class="px-1 white--text rounded caption"
                  :class="{
                    success: item.recommendedElearningPassed,
                    error: !item.recommendedElearningPassed,
                  }"
                >
                  {{ item.recommendedElearningPassed ? "Passed" : "Failed" }}
                </div>
                <div v-else>
                  -
                </div>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  v-if="
                    item.showReport && info.currentLogin.gmCanAccessElearning
                  "
                  small
                  @click="onShowReport(item, 1)"
                  width="60px"
                  >report</v-btn
                >
                <div v-else>
                  -
                </div>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- <div v-else>
        No available assignments
      </div> -->
      <AssignmentReport
        v-if="showReportDialog"
        :reportIdProp="showReportId"
        :fromMonitoring="true"
        @returnBack="
          showReportDialog = false;
          showReportId = null;
        "
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import AssignmentReport from "../../admin/assignment/assignment-dialog/report/AssignmentReport.vue";
export default {
  components: {
    AssignmentReport,
  },

  props: {
    questionnaire: Object,
  },

  async created() {
    console.log(this.info.currentLogin.gmCanAccessElearning);
    this.loader = true;
    try {
      console.log(this.questionnaire);
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/clientUser/GetFilistosTrainingNeedsForAssignment/${this.questionnaire.assignUserQuestId}`
      );

      this.trainingNeeds = res.data.assignments;
      this.onExpansionPanelChange(this.trainingNeeds[0].factors);
    } catch (e) {
      console.log(e);
    }

    this.loader = false;
  },

  data() {
    return {
      showReportDialog: false,
      showReportId: false,
      loader: false,
      tab: null,
      currentFactor: null,
      trainingNeeds: {},
      elearnings: [],
      selectedElearnigns: [],
      uniqueSelectedElearnings: [],
      factorsTabs: [],

      elearningsHeaders: [
        { text: "Elearning Name", value: "recommendedElearningName" },
        { text: "Assigned Date", value: "recommendedElearningAssignedOn" },
        { text: "Completion Date", value: "recommendedElearningCompletedOn" },
        { text: "Passed", value: "recommendedElearningPassed" },
        { text: "", value: "actions" },
      ],
    };
  },

  computed: {
    ...mapState({
      info: (state) => state.base.info,
    }),

    selectedElearnignsLength() {
      const found = this.selectedElearnigns.filter((el) => el.length > 0);
      if (found.length > 0) return false;
      else return true;
    },
  },

  methods: {
    ...mapMutations(["toggleShowReport"]),
    itemRowBackground(item) {
      return !item.clientOwnsElearning ? "training-style-2" : "";
    },
    onShowReport(item, panel) {
      this.showReportDialog = true;
      this.showReportId = item.recommendedElearningAUId;
    },

    onElearningsClick(item) {
      this.currentFactor = item.factorName;
      this.elearnings = item.elearnings;
    },

    onExpansionPanelChange(factors) {
      const tabs = factors.map((el, i) => {
        if (el.elearnings.length > 0) return i;
      });
      this.factorsTabs = tabs;
      this.selectedElearnigns = [];
      this.selectedElearnigns = [...factors.map((el) => [])];
    },

    async onAssign() {
      const flatSelectedElearnings = this.selectedElearnigns.flat();
      // Create a Map to store unique objects based on recommendedElearningId
      const uniqueObjectMap = new Map();

      // Iterate through the flattened array and add unique objects to the Map
      flatSelectedElearnings.forEach((item) => {
        uniqueObjectMap.set(item.recommendedElearningId, item);
      });

      // Extract values from the Map to get unique objects
      this.uniqueSelectedElearnings = Array.from(uniqueObjectMap.values());

      // Resulting array with unique objects based on recommendedElearningId
    },
  },
};
</script>

<style>
.training-style-2 {
  color: #a9a9a9;
  /* font-weight: bold; */
}
</style>
