<template>
  <div class="flex-grow-1 d-flex flex-column">
    <div class="d-flex flex-1">
      <div class="d-flex flex-column flex-1">
        <div class="mr-5 mb-5 elevation-2 pa-5 rounded">
          <div
            class="d-flex align-center justify-space-between panel-header"
            @click="basicInfoPanel = !basicInfoPanel"
          >
            <div class="subtitle-2">Basic Information</div>

            <v-icon>
              {{ basicInfoPanel ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </div>

          <div class="d-flex pt-5 mb-5" v-if="basicInfoPanel">
            <div class="d-flex flex-column mr-5">
              <div class="body-2 mb-1">Title:</div>
              <div class="body-2 mb-1">Importance:</div>
              <div class="body-2 mb-1">Message:</div>
            </div>

            <div class="d-flex flex-column">
              <div class="body-2 font-weight-medium mb-1">
                {{ form.title ? form.title : "-" }}
              </div>

              <div class="body-2 font-weight-medium mb-1">
                {{ form.importance ? form.importance : "-" }}
              </div>

              <div class="body-2 mt-2 mb-1" v-html="form.body"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column flex-1">
        <div
          class="mr-5 mb-5 elevation-2 pa-5 rounded d-flex flex-column"
          :class="{ 'flex-1': usersPanel }"
        >
          <div
            class="d-flex align-center justify-space-between panel-header"
            @click="usersPanel = !usersPanel"
            :class="{ 'mb-5': usersPanel }"
          >
            <div class="subtitle-2">Users</div>

            <v-icon>
              {{ usersPanel ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </div>

          <template v-if="usersPanel">
            <div class="body-2" v-if="!form.sendToClientUserIds.length">
              No users added.
            </div>

            <div class="scrollable" v-else>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Identity</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Email</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="user in form.sendToClientUserIds"
                      :key="user.clientUserId"
                    >
                      <td>{{ user.identityNumber }}</td>
                      <td>{{ user.firstName }} {{ user.lastName }}</td>
                      <td>{{ user.userEmail ? user.userEmail : "-" }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapGetters, mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
  props: ["isEdit"],
  data() {
    return {
      basicInfoPanel: true,
      questionnairesPanel: true,
      usersPanel: true,
      assessorsPanel: true,
      hasAssessor: false,
      possibleAssessors: [],
      selectedAssessor: [],
    };
  },
  computed: {
    ...mapState({
      form: (state) => state.notifications.form,
      currentAssignment: (state) => state.assignments.currentAssignment,
    }),
    ...mapGetters(["assigneeUserIdsMapped"]),
  },

  methods: {
    ...mapMutations(["setForm"]),
  },
};
</script>
  
  <style lang="scss" scoped>
.scrollable {
  height: 208px;
}

.panel-header {
  cursor: pointer;
}
</style>
  