<template>
  <div class="component-wrapper px-5 pt-5 d-flex flex-column">
    <div class="d-flex align-center justify-center mb-2">
      <div class="text-h6 mr-2">Title:</div>

      <div class="text-h6 font-weight-regular">
        {{ assignment.assignmentName }}
      </div>
    </div>

    <div class="d-flex align-center justify-center mb-2">
      <div class="subtitle-1 font-weight-medium mr-2">Sent:</div>

      <div class="body-1">
        {{ dayjs.utc(assignment.dateToSend).local().format("DD/MM/YYYY") }}
      </div>
    </div>

    <div class="d-flex align-center justify-center">
      <div class="subtitle-1 font-weight-medium mr-2">Ending:</div>

      <div class="body-1">
        {{ dayjs.utc(assignment.dateToEnd).local().format("DD/MM/YYYY") }}
      </div>
    </div>

    <v-divider class="my-4"></v-divider>

    <div class="text-h6 text-center mb-5">Questionnaires</div>

    <div class="d-flex flex-column align-self-center">
      <v-menu
        :close-on-content-click="false"
        max-width="290"
        v-for="(questionnaire, index) in assignment.questionnaires"
        :key="questionnaire.questionnaireId"
        offset-x
        right
        ref="languageMenu"
        :disabled="questionnaire.questionnaireTypeId == 'elearn'"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="pa-4 elevation-2 rounded d-flex align-center questionnaire"
            :class="{
              'mb-4': index != assignment.questionnaires.length - 1,
            }"
            v-bind="attrs"
            v-on="on"
            @click="toggleMenu(questionnaire)"
          >
            <div
              class="subtitle-1 font-weight-medium"
              :class="{
                'mr-8':
                  !questionnaire.isFinalized && !questionnaire.dateCompleted,
                'mr-2':
                  questionnaire.isFinalized || questionnaire.dateCompleted,
              }"
            >
              {{ questionnaire.questionnaireName }}
            </div>

            <div
              class="body-2 success--text font-weight-medium mr-8"
              v-if="questionnaire.isFinalized"
            >
              (finalized)
            </div>

            <div
              class="body-2 warning--text font-weight-medium mr-8"
              v-if="!questionnaire.isFinalized && questionnaire.dateCompleted"
            >
              (draft -
              {{
                dayjs
                  .utc(questionnaire.dateCompleted)
                  .local()
                  .format("DD/MM/YY HH:mm")
              }})
            </div>

            <v-icon color="filyellow" class="ml-auto">mdi-arrow-right</v-icon>
          </div>
        </template>

        <div class="white pa-2 d-flex flex-column">
          <v-progress-circular
            size="40"
            width="3"
            indeterminate
            color="filblue"
            v-if="loader"
            class="align-self-center"
          ></v-progress-circular>

          <template v-else>
            <div class="subtitle-1 font-weight-medium mb-4">
              Select Language
            </div>

            <v-list class="pa-0" dense>
              <v-list-item
                v-for="language in languages"
                :key="language.itemKey"
                link
                @click="
                  $router.push(
                    `/assignee/${assignment.userAssignmentId}/questionnaire/${questionnaire.assignUserQuesId}/${language.itemKey}`
                  )
                "
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ language.itemValue }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </template>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      menu: null,
      languages: [],
      loader: false,
    };
  },
  computed: {
    ...mapState({
      assignment: (state) => state.assignee.assignment,
    }),
  },
  methods: {
    ...mapMutations(["getAssigneeAssignment"]),
    ...mapActions(["getQuestionnaireLanguages"]),
    async toggleMenu(questionnaire) {
      if (questionnaire.questionnaireTypeId == "elearn") {
        this.$router.push(
          `/assignee/${this.assignment.userAssignmentId}/e-learning/${questionnaire.assignUserQuesId}`
        );

        return;
      }

      if (this.menu == questionnaire.questionnaireId) return;

      this.menu = questionnaire.questionnaireId;

      this.loader = true;

      try {
        this.languages = await this.getQuestionnaireLanguages(
          questionnaire.questionnaireId
        );
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    onSelectQuestionnaire(questionnaire) {
      if (questionnaire.questionnaireTypeId == "elearn") {
        this.$router.push(
          `/assignee/${this.assignment.userAssignmentId}/e-learning/${questionnaire.assignUserQuesId}`
        );
      } else {
        this.$router.push(
          `/assignee/${this.assignment.userAssignmentId}/questionnaire/${questionnaire.assignUserQuesId}`
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.questionnaire {
  cursor: pointer;
}
</style>
