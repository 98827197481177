import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import Vuelidate from "vuelidate";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

import Questions from "@/views/assignee/questionnaire/Questions";

Vue.component("Questions", Questions);

dayjs.extend(utc);

Vue.config.productionTip = false;

Vue.prototype.dayjs = dayjs;

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});

//====== AXIOS DEFAULT HEADER ======
if (store.getters.isLoggedIn) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${store.state.auth.token}`;
}

Vue.use(Vuelidate);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
