<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex align-center mb-5" v-if="$vuetify.breakpoint.xsOnly">
      <div class="subtitle-1 font-weight-medium">Step 2</div>

      <div class="body-1 mx-2">-</div>

      <div class="body-1">Add Questionnaires</div>
    </div>

    <div class="scrollable d-flex flex-column align-start pt-1">
      <div
        class="body-2 mb-5 font-weight-medium"
        v-if="!selectedQuestionnaires.length"
      >
        No questionnaires added.
      </div>

      <template v-else>
        <div class="body-2 mb-1 font-weight-medium">
          {{ selectedQuestionnaires.length }} questionnaire{{
            selectedQuestionnaires.length == 1 ? "" : "s"
          }}
          added.
        </div>

        <v-chip-group active-class="primary--text" class="mb-2" column>
          <v-chip
            v-for="questionnaire in selectedQuestionnaires"
            :key="questionnaire.questionnaireId"
            close
            small
            @click:close="onRemoveQuestionnaire(questionnaire.questionnaireId)"
            :color="getColor(questionnaire.questionnaireTypeId)"
            class="white--text"
          >
            {{
              questionnaire.questionnaireShortName
                ? questionnaire.questionnaireShortName
                : questionnaire.questionnaireName
            }}
          </v-chip>
        </v-chip-group>
      </template>

      <v-slide-group mandatory show-arrows v-model="category" class="mb-5">
        <v-slide-item
          v-slot="{ active, toggle }"
          v-for="(questionnaireType, index) in questionnaires"
          :key="questionnaireType.questionnaireTypeId"
          :value="questionnaireType.questionnaireTypeId"
        >
          <v-btn
            @click="toggle"
            :color="active ? getColor(category) : ''"
            depressed
            :class="{
              'mr-2': index != questionnaires.length - 1,
              'white--text': active,
            }"
            small
            >{{ questionnaireType.questionnaireTypeName }}</v-btn
          >
        </v-slide-item>
      </v-slide-group>

      <div
        v-for="questionnaire in filteredQuestionnaires"
        :key="questionnaire.questionnaireId"
      >
        <v-checkbox
          v-if="!questionnaire.isGroupHead"
          :value="questionnaire"
          dense
          :ripple="false"
          class="ma-0 pa-0 mb-3"
          hide-details
          @change="
            setForm({
              value: selectedQuestionnaires,
              key: 'questionnaireIds',
            })
          "
          v-model="selectedQuestionnaires"
          :color="getColor(category)"
        >
          <template v-slot:label>
            <div class="d-flex align-center">
              <div class="body-1 mr-2">
                {{
                  questionnaire.questionnaireShortName
                    ? questionnaire.questionnaireShortName
                    : questionnaire.questionnaireName
                }}
              </div>

              <div class="body-1 font-weight-medium">
                ({{
                  questionnaire.isUnlimited
                    ? "unlimited"
                    : questionnaire.remainingUnits
                }})
              </div>
            </div>
          </template>
        </v-checkbox>

        <v-expansion-panels
          v-else
          flat
          class="questionnaires-expansion-panel mb-3"
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-0">
              <div class="d-flex align-center">
                <v-checkbox
                  dense
                  :ripple="false"
                  class="ma-0 pa-0"
                  hide-details
                  @click.stop="onParentCheckboxClick(questionnaire)"
                  readonly
                  :value="questionnaire.questionnaireId"
                  :input-value="selectedParentQuestionnaires"
                  :color="getColor(category)"
                >
                </v-checkbox>

                <div
                  class="
                    body-1
                    font-weight-medium
                    grey--text
                    text--darken-3
                    mr-3
                  "
                >
                  {{
                    questionnaire.questionnaireShortName
                      ? questionnaire.questionnaireShortName
                      : questionnaire.questionnaireName
                  }}
                </div>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="pt-3">
              <v-checkbox
                v-for="subQuestionnaire in questionnaire.subQuestionnaires"
                :key="subQuestionnaire.questionnaireId"
                :value="subQuestionnaire"
                dense
                :ripple="false"
                class="ma-0 pa-0 mb-3"
                hide-details
                @change="onSubQuestionnaireChanged(questionnaire)"
                v-model="selectedQuestionnaires"
                :color="getColor(category)"
              >
                <template v-slot:label>
                  <div class="d-flex align-center">
                    <div class="body-1 mr-2">
                      {{
                        subQuestionnaire.questionnaireShortName
                          ? subQuestionnaire.questionnaireShortName
                          : subQuestionnaire.questionnaireName
                      }}
                    </div>

                    <div class="body-1 font-weight-medium">
                      ({{
                        subQuestionnaire.isUnlimited
                          ? "unlimited"
                          : subQuestionnaire.remainingUnits
                      }})
                    </div>
                  </div>
                </template>
              </v-checkbox>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      selectedQuestionnaires: [],
      selectedParentQuestionnaires: [],
      search: "",
      category: null,
    };
  },
  computed: {
    ...mapState({
      questionnaires: (state) => state.base.questionnaires,
      form: (state) => state.assignments.form,
    }),
    filteredQuestionnaires() {
      if (!this.search)
        return this.questionnaires.filter(
          (questionnaireType) =>
            questionnaireType.questionnaireTypeId == this.category
        )[0]?.questionnaires;

      return this.questionnaires
        .filter(
          (questionnaireType) =>
            questionnaireType.questionnaireTypeId == this.category
        )[0]
        ?.questionnaires?.filter((questionnaire) =>
          questionnaire.questionnaireName
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
    },
  },
  created() {
    this.category = this.questionnaires[0].questionnaireTypeId;

    this.selectedQuestionnaires = [...this.form.questionnaireIds];

    this.selectedParentQuestionnaires = [...this.form.parentQuestionnaires];
  },
  methods: {
    ...mapMutations(["setForm"]),
    getColor(categoryId) {
      if (categoryId == "ability") {
        return "secondary";
      } else if (categoryId == "elearn") {
        return "info";
      } else if (categoryId == "form") {
        return "filred";
      } else {
        return "filgreen";
      }
    },
    onRemoveQuestionnaire(questionnaireId) {
      const index = this.selectedQuestionnaires.findIndex(
        (questionnaire) => questionnaire.questionnaireId == questionnaireId
      ); //find index of questionnaire

      this.selectedQuestionnaires.splice(index, 1); //remove it from selected questionnaires

      this.setForm({
        value: this.selectedQuestionnaires,
        key: "questionnaireIds",
      }); //update the store prop
    },
    onParentCheckboxClick(questionnaire) {
      //when you check a parent checkbox
      const found = this.selectedParentQuestionnaires.findIndex(
        (q) => q == questionnaire.questionnaireId
      ); // try to find its id in the selectedParentQuestionnaires (contains only strings)

      if (found == -1) {
        //if is NOT found
        this.selectedParentQuestionnaires.push(questionnaire.questionnaireId); //push the id

        this.selectedQuestionnaires = [
          ...this.selectedQuestionnaires,
          ...questionnaire.subQuestionnaires.filter(
            (subq) =>
              this.selectedQuestionnaires.findIndex(
                (selq) => selq.questionnaireId == subq.questionnaireId
              ) == -1
          ),
        ]; //make the selected questionnaires to be the previous selected PLUS the subquestionnaires that are NOT selected.
      } else {
        this.selectedParentQuestionnaires.splice(found, 1); //else remove it

        this.selectedQuestionnaires = this.selectedQuestionnaires.filter(
          (q) =>
            questionnaire.subQuestionnaires.findIndex(
              (subq) => subq.questionnaireId == q.questionnaireId
            ) == -1
        ); //filter selected questionnaires to be the previous selected MINUS the ones that exist in the parent's subquestionnaires
      }

      this.setForm({
        value: this.selectedQuestionnaires,
        key: "questionnaireIds",
      });

      this.setForm({
        value: this.selectedParentQuestionnaires,
        key: "parentQuestionnaires",
      });

      //update the store
    },
    onSubQuestionnaireChanged(parentQuestionnaire) {
      //when a subquestionnaire is clicked
      const selectedSubQuestionnaires = this.selectedQuestionnaires.filter(
        (selq) =>
          parentQuestionnaire.subQuestionnaires.findIndex(
            (subq) => subq.questionnaireId == selq.questionnaireId
          ) != -1
      ); //find how many of its siblings are selected

      const found = this.selectedParentQuestionnaires.findIndex(
        (q) => q == parentQuestionnaire.questionnaireId
      ); //try to find if the parent is selected (in the array that contains strings of parent questionnaire ids)

      if (selectedSubQuestionnaires.length) {
        //if there are child questionnaires selected
        if (found == -1) {
          //then if the parent is NOT selected
          this.selectedParentQuestionnaires.push(
            parentQuestionnaire.questionnaireId
          ); //make him selected
        }
      } else {
        //if there are NOT child questionnaires selected
        if (found != -1) {
          //this if the parent IS selected
          this.selectedParentQuestionnaires.splice(found, 1); //unselect him
        }
      }

      this.setForm({
        value: this.selectedQuestionnaires,
        key: "questionnaireIds",
      });

      this.setForm({
        value: this.selectedParentQuestionnaires,
        key: "parentQuestionnaires",
      });
      //update the store
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
