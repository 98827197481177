<template>
  <div class="component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column">
    <v-row class="flex-grow-0">
      <v-col cols="12" lg="6" class="d-flex flex-column">
        <TotalUsers />
      </v-col>

      <v-col cols="12" lg="6" class="d-flex flex-column">
        <EvaluatedUsers />
      </v-col>
    </v-row>

    <v-row class="flex-grow-0">
      <v-col cols="12" lg="6" class="d-flex flex-column">
        <Nationality />
      </v-col>

      <v-col cols="12" lg="6" class="d-flex flex-column">
        <Messages />
      </v-col>
    </v-row>

    <v-row class="flex-grow-0">
      <v-col cols="12" class="d-flex flex-column">
        <AssignmentsWrapper />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TotalUsers from "@/views/admin/dashboard/TotalUsers";
import EvaluatedUsers from "@/views/admin/dashboard/EvaluatedUsers";
import Nationality from "@/views/admin/dashboard/Nationality";
import Messages from "@/views/admin/dashboard/messages/Messages";
import AssignmentsWrapper from "@/views/admin/dashboard/assignments/AssignmentsWrapper";

export default {
  components: {
    TotalUsers,
    EvaluatedUsers,
    Nationality,
    Messages,
    AssignmentsWrapper,
  },
};
</script>

<style lang="scss" scoped>
</style>
