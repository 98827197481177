<template>
  <div class="d-flex flex-column flex-grow-1">
    <v-btn
      color="filyellow"
      class="white--text align-self-end"
      depressed
      :to="`/superadmin/clients/${$route.params.clientId}/admins/new`"
      >Add User</v-btn
    >

    <v-divider class="my-4"></v-divider>

    <div class="d-flex">
      <v-text-field
        outlined
        dense
        label="Search"
        prepend-inner-icon="mdi-magnify"
        hint="Search by Identity, Last Name, First Name, Email, Mobile"
        persistent-hint
        :value="adminsParams.search"
        @input="setAdminsParams({ search: $event })"
        :readonly="loader"
        clearable
        class="align-self-start flex-grow-0 mr-auto"
      ></v-text-field>

      <ShowPerPage
        class="mr-2"
        :currentPerPage="adminsParams.itemsPerPage"
        :options="showPerPageOptions"
      />

      <FilterBy
        :fields="filterFields"
        :loader="loader"
        @filterChange="onFilterChange"
        class="mr-2"
      />

      <ColumnVisibility
        :headers="headers"
        @visibilityChanged="onVisibilityChanged"
        @initLoaderChanged="onVisibilityLoaderChanged"
        grid="adminsList"
      />
    </div>

    <v-data-table
      :headers="visibleHeaders"
      :items="admins.results"
      :server-items-length="admins.rowCount"
      class="clickable mb-4"
      checkbox-color="primary"
      hide-default-footer
      :options.sync="options"
      @update:options="onGetAdmins(true)"
      :loading="loader"
      :item-class="clientAdminRowClass"
      @click:row="
        !globalLoader &&
          $router.push({
            path: `/superadmin/clients/${$route.params.clientId}/admins/${$event.clientUserId}`,
          })
      "
    >
      <template v-slot:[`item.creationDate`]="{ item }">
        {{
          item.creationDate
            ? dayjs.utc(item.creationDate).local().format("DD/MM/YYYY")
            : "-"
        }}
      </template>

      <template v-slot:[`item.dateOfBirth`]="{ item }">
        {{
          item.dateOfBirth
            ? dayjs.utc(item.dateOfBirth).local().format("DD/MM/YYYY")
            : "-"
        }}
      </template>

      <template v-slot:[`item.userEmail`]="{ item }">
        {{ item.userEmail ? item.userEmail : "-" }}
      </template>

      <template v-slot:[`item.gender`]="{ item }">
        <span v-if="item.gender == 'm'">Male</span>
        <span v-else-if="item.gender == 'f'">Female</span>
        <span v-else-if="item.gender == 'o'">Other</span>
        <span v-else>-</span>
      </template>

      <template v-slot:[`item.canLogin`]="{ item }">
        <div class="bullet small filgreen" v-if="item.canLogin"></div>

        <div class="bullet small filred" v-else></div>
      </template>

      <template v-slot:[`item.marital`]="{ item }">
        {{
          item.marital
            ? item.marital.charAt(0).toUpperCase() + item.marital.slice(1)
            : "-"
        }}
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        {{ item.mobile ? item.mobile : "-" }}
      </template>

      <template v-slot:[`item.delete`]="{ item, index }">
        <v-menu
          top
          left
          :close-on-content-click="false"
          offset-y
          offset-x
          :ref="`deleteMenu${index}`"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              depressed
              x-small
              v-bind="attrs"
              v-on="on"
              :disabled="item.isClientAdmin"
              >Delete</v-btn
            >
          </template>

          <div class="white pa-5">
            <div class="body-2 mb-4 error--text">
              Are you sure you want to delete this user?
            </div>

            <v-btn
              small
              depressed
              color="error"
              @click="onDeleteUser(item, index)"
              :loading="deleteUserLoader == item.clientUserId"
              >Delete</v-btn
            >
          </div>
        </v-menu>
      </template>
    </v-data-table>

    <v-pagination
      class="mt-auto"
      :length="admins.pageCount"
      v-model="adminsParams.page"
      v-if="admins.pageCount"
    ></v-pagination>
  </div>
</template>

<script>
import FilterBy from "@/components/FilterBy";
import { mapActions, mapMutations, mapState } from "vuex";
import ShowPerPage from "@/components/ShowPerPage";
import ColumnVisibility from "@/components/ColumnVisibility";

export default {
  components: {
    FilterBy,
    ShowPerPage,
    ColumnVisibility,
  },
  data() {
    return {
      headers: [
        { text: "Creation Date", value: "creationDate" },
        { text: "Identity", value: "identityNumber" },
        { text: "Last Name", value: "lastName" },
        { text: "First Name", value: "firstName" },
        { text: "Birth Date", value: "dateOfBirth" },
        { text: "Email", value: "userEmail" },
        { text: "Gender", value: "gender" },
        { text: "Nationality", value: "nationalityName" },
        { text: "Language", value: "languageName" },
        { text: "Role", value: "platformAccessName" },
        { text: "Can Login", value: "canLogin" },
        { text: "Position", value: "positionName" },
        { text: "Marital Status", value: "marital" },
        { text: "Mobile", value: "mobile", sortable: false },
        { text: "Delete", value: "delete" },
      ],
      loader: false,
      timeout: null,
      isFirstCall: true,
      showPerPageOptions: [],
      visibleHeaders: [],
      visibilityLoader: false,
      deleteUserLoader: null,
    };
  },
  computed: {
    ...mapState({
      adminsParams: (state) => state.clients.adminsParams,
      admins: (state) => state.clients.admins,
      platformAccesses: (state) => state.base.lists.platformAccesses,
      globalLoader: (state) => state.base.globalLoader,
    }),
    options: {
      get() {
        const { itemsPerPage, page, sortBy, sortDesc } = this.adminsParams;

        return {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        };
      },
      set(value) {
        let { itemsPerPage, page, sortBy, sortDesc } = value;

        this.setAdminsParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      },
    },
    filterFields() {
      return [
        {
          key: "platformAccessId",
          value: "Role",
          values: this.platformAccessesMapped,
          selectedValue: this.adminsParams.platformAccessId,
        },
      ];
    },
    platformAccessesMapped() {
      return this.platformAccesses.filter(
        (p) => p.itemKey == "co" || p.itemKey == "psysupport"
      );
    },
  },
  created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setAdminsParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setAdminsParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setAdminsParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setAdminsParams,
      },
    ];
  },
  methods: {
    ...mapMutations(["setAdminsParams"]),
    ...mapActions(["getAdmins", "deleteAdmin"]),
    async onGetAdmins(refresh) {
      if (this.isFirstCall) {
        this.isFirstCall = false;
        return;
      }

      this.loader = true;

      try {
        await this.getAdmins(refresh);
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    onFilterChange(e) {
      this.setAdminsParams({
        [e.field]:
          (e.field == "dateFrom" || e.field == "dateTo") && e.value
            ? this.dayjs.utc(e.value).toISOString()
            : e.value,
      });

      this.onGetAdmins(true);
    },
    onVisibilityChanged(visibleHeaders) {
      this.visibleHeaders = visibleHeaders
        .filter((header) => header.visible)
        .map((header) => {
          return {
            text: header.text,
            value: header.value,
            ...(typeof header.sortable === "boolean" &&
              !header.sortable && {
                sortable: header.sortable,
              }),
          };
        });
    },
    onVisibilityLoaderChanged(loader) {
      this.visibilityLoader = loader;
    },
    async onDeleteUser(user, index) {
      this.deleteUserLoader = user.clientUserId;

      try {
        await this.deleteAdmin(user);
        this.onGetAdmins(true);
      } catch (e) {
        console.log(e);
      }

      this.$refs[`deleteMenu${index}`].save();
      this.deleteUserLoader = false;
    },
    clientAdminRowClass(item) {
      return item.isClientAdmin ? "font-weight-bold" : "";
    },
  },
  watch: {
    "adminsParams.search"() {
      clearTimeout(this.timeout);

      if (this.loader) return;

      this.timeout = setTimeout(() => {
        this.setAdminsParams({
          page: 1,
        });

        this.onGetAdmins(true);
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.bullet {
  &:not(:last-of-type) {
    margin-right: 4px;
  }

  &.small {
    height: 16px;
    width: 16px;
  }
}
</style>
