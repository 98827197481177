<template>
  <div>
    <div v-for="(question, index) in questions" :key="question.questionId">
      <!-- ====== TEXT (LABEL ONLY) ====== -->
      <div
        class="rounded pa-4 pt-2"
        :class="{
          'grey lighten-5':
            ((parentIndex == null || parentIndex == undefined) &&
              index % 2 == 0) ||
            (parentIndex != null &&
              parentIndex != undefined &&
              parentIndex % 2 == 0),
        }"
        v-if="question.inputTypeId == 'label'"
      >
        <div class="subtitle-2" v-html="question.questionText"></div>
      </div>

      <!-- ====== TEXTBOX ====== -->
      <div
        class="rounded pa-4 pt-2"
        :class="{
          'grey lighten-5':
            ((parentIndex == null || parentIndex == undefined) &&
              index % 2 == 0) ||
            (parentIndex != null &&
              parentIndex != undefined &&
              parentIndex % 2 == 0),
        }"
        v-if="question.inputTypeId == 'tbox'"
      >
        <div class="body-2" v-html="question.questionText"></div>

        <div class="caption ml-2" v-html="question.questionSubText"></div>

        <v-text-field
          dense
          outlined
          hide-details
          v-model="question.answerValue"
          :error="
            v &&
            v.$each[index] &&
            v.$each[index].answerValue.$dirty &&
            v.$each[index].answerValue.$invalid
          "
          :readonly="questionnaire.isFinal"
          @input="onInput($event, question)"
          class="mt-1"
        >
        </v-text-field>
      </div>

      <!-- ====== SELECT ====== -->
      <div
        class="rounded pa-4 pt-2"
        :class="{
          'grey lighten-5':
            ((parentIndex == null || parentIndex == undefined) &&
              index % 2 == 0) ||
            (parentIndex != null &&
              parentIndex != undefined &&
              parentIndex % 2 == 0),
        }"
        v-if="question.inputTypeId == 'drop'"
      >
        <div class="body-2" v-html="question.questionText"></div>

        <div class="caption ml-2" v-html="question.questionSubText"></div>

        <v-select
          dense
          outlined
          hide-details
          :items="question.answerChoices"
          item-text="display"
          item-value="id"
          v-model="question.answerValue"
          :error="
            v &&
            v.$each[index] &&
            v.$each[index].answerValue.$dirty &&
            v.$each[index].answerValue.$invalid
          "
          :readonly="questionnaire.isFinal"
          @change="onInput($event, question)"
          :clearable="!questionnaire.isFinal"
          class="mt-1"
        ></v-select>
      </div>

      <!-- ====== TEXTAREA ====== -->
      <div
        class="rounded pa-4 pt-2"
        :class="{
          'grey lighten-5':
            ((parentIndex == null || parentIndex == undefined) &&
              index % 2 == 0) ||
            (parentIndex != null &&
              parentIndex != undefined &&
              parentIndex % 2 == 0),
        }"
        v-if="question.inputTypeId == 'tarea'"
      >
        <div class="body-2" v-html="question.questionText"></div>

        <div class="caption ml-2" v-html="question.questionSubText"></div>

        <v-textarea
          dense
          outlined
          hide-details
          rows="2"
          no-resize
          v-model="question.answerValue"
          :error="
            v &&
            v.$each[index] &&
            v.$each[index].answerValue.$dirty &&
            v.$each[index].answerValue.$invalid
          "
          :readonly="questionnaire.isFinal"
          @input="onInput($event, question)"
          class="mt-1"
        ></v-textarea>
      </div>

      <!-- ====== DATEPICKER ====== -->
      <div
        class="rounded pa-4 pt-2"
        :class="{
          'grey lighten-5':
            ((parentIndex == null || parentIndex == undefined) &&
              index % 2 == 0) ||
            (parentIndex != null &&
              parentIndex != undefined &&
              parentIndex % 2 == 0),
        }"
        v-if="question.inputTypeId == 'calnd'"
      >
        <div class="body-2" v-html="question.questionText"></div>

        <div class="caption ml-2" v-html="question.questionSubText"></div>

        <v-menu
          max-width="290"
          :disabled="questionnaire.isFinal"
          :close-on-content-click="false"
          v-model="question.menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              class="flex-grow-0 mt-1"
              hide-details
              :value="formattedDate(question.answerValue)"
              prepend-inner-icon="mdi-calendar"
              :clearable="!questionnaire.isFinal"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="question.answerValue = null"
              color="filyellow"
              :error="
                v &&
                v.$each[index] &&
                v.$each[index].answerValue.$dirty &&
                v.$each[index].answerValue.$invalid
              "
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="question.answerValue"
            color="filyellow"
            :readonly="questionnaire.isFinal"
            @change="
              onInput($event, question);
              question.menu = false;
            "
          >
          </v-date-picker>
        </v-menu>
      </div>

      <!-- ====== RADIO BUTTON ====== -->
      <div
        class="rounded pa-4 pt-2"
        :class="{
          'grey lighten-5':
            !question.imageUrl &&
            (((parentIndex == null || parentIndex == undefined) &&
              index % 2 == 0) ||
              (parentIndex != null &&
                parentIndex != undefined &&
                parentIndex % 2 == 0)),
        }"
        v-if="question.inputTypeId == 'radio'"
      >
        <!-- ------ radio button with images ------ -->
        <template v-if="question.imageUrl">
          <div class="d-flex flex-column">
            <div class="body-2 font-weight-medium">
              {{ index + 1 }} / {{ questions.length }}
            </div>

            <img :src="`${baseUrl}/${question.imageUrl}`" class="mb-4" />

            <v-row>
              <v-col
                cols="4"
                v-for="answer in question.answerChoices"
                :key="`${question.questionId}-${answer.value}`"
              >
                <label>
                  <input
                    type="radio"
                    :value="answer.id"
                    hidden
                    v-model="question.answerValue"
                    @change="onInput('does not matter', question)"
                    :disabled="questionnaire.isFinal"
                  />

                  <div class="fake-checkbox mb-2"></div>

                  <img
                    :src="`${baseUrl}/${answer.imageUrl}`"
                    class="answer-img"
                    :class="{ final: questionnaire.isFinal }"
                  />
                </label>
              </v-col>
            </v-row>
          </div>

          <v-divider></v-divider>
        </template>

        <!-- ------ normal radio button  ------ -->
        <template v-else>
          <v-radio-group
            column
            dense
            hide-details
            row
            class="ma-0 pa-0 questionnaires-radio-group"
            v-model="question.answerValue"
            :error="
              v &&
              v.$each[index] &&
              v.$each[index].answerValue.$dirty &&
              v.$each[index].answerValue.$invalid
            "
            :readonly="questionnaire.isFinal"
            @change="onInput($event, question)"
          >
            <template v-slot:label>
              <div class="body-1" v-html="question.questionText"></div>

              <div class="body-2 ml-2" v-html="question.questionSubText"></div>
            </template>

            <div class="d-flex flex-wrap mt-2">
              <v-radio
                v-for="answer in question.answerChoices"
                :key="`${question.questionId}-${answer.value}`"
                :label="answer.display"
                :value="answer.id"
                :ripple="false"
                color="filyellow"
              ></v-radio>
            </div>
          </v-radio-group>
        </template>
      </div>

      <!-- ====== CHECKBOX ====== -->
      <div
        class="rounded pa-4 pt-2"
        :class="{
          'grey lighten-5':
            ((parentIndex == null || parentIndex == undefined) &&
              index % 2 == 0) ||
            (parentIndex != null &&
              parentIndex != undefined &&
              parentIndex % 2 == 0),
        }"
        v-if="question.inputTypeId == 'chbox'"
      >
        <div class="body-2" v-html="question.questionText"></div>

        <div class="caption ml-2" v-html="question.questionSubText"></div>

        <v-select
          dense
          outlined
          hide-details
          :items="question.answerChoices"
          item-text="display"
          item-value="id"
          v-model="question.answerValue"
          multiple
          chips
          deletable-chips
          small-chips
          :error="
            v &&
            v.$each[index] &&
            v.$each[index].answerValue.$dirty &&
            v.$each[index].answerValue.$invalid
          "
          :readonly="questionnaire.isFinal"
          @input="onInput($event, question)"
        ></v-select>
      </div>

      <!-- ========================== -->
      <!-- ====== SUBQUESTIONS ====== -->
      <!-- ========================== -->

      <Questions
        class="ml-8"
        v-if="question.subQuestions && question.subQuestions.length"
        :questions="question.subQuestions"
        :v="v ? v.$each[index].subQuestions : null"
        :parentIndex="index"
        @inputChanged="$emit('inputChanged', $event)"
        :reportQuestionnaire="reportQuestionnaire"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["questions", "v", "parentIndex", "reportQuestionnaire"],
  // created() {
  //   for (let i = 0; i < this.questions.length; i++) {
  //     this.questions[i]["menu"] = false;
  //   }

  //   console.log(this.questions);
  // },

  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL.split("/api")[0],
    };
  },
  computed: {
    ...mapState({
      assigneeQuestionnaire: (state) => state.assignee.questionnaire,
    }),
    questionnaire() {
      return this.reportQuestionnaire
        ? this.reportQuestionnaire
        : this.assigneeQuestionnaire;
    },
  },
  methods: {
    closeMenu(question) {
      this.questions.filter((el) => {
        if (el.questionId == question.questionId) {
          console.log("found?");
          el.menu = false;
          console.log(el.menu);
        }
      });
      console.log(this.questions);
    },

    formattedDate(date) {
      return date ? this.dayjs(date).format("DD/MM/YYYY") : "";
    },
    onInput(e, question) {
      if (!e || !e?.length) question.answerValue = null;

      this.$emit("inputChanged", question);
    },
  },
};
</script>

<style lang="scss" scoped>
.answer-img {
  width: 100%;
  object-fit: cover;
  border: 2px solid transparent;
}

.fake-checkbox {
  height: 10px;
  width: 10px;
  border: 1px solid #757575;
  border-radius: 50%;
}

[type="radio"] + .fake-checkbox + .answer-img:not(.final) {
  cursor: pointer;
}

/* CHECKED STYLES */
[type="radio"]:checked + .fake-checkbox + .answer-img {
  border: 2px solid #c19752;
  border-radius: 4px;
}

[type="radio"]:checked + .fake-checkbox {
  border: 1px solid #c19752;
  position: relative;

  &::after {
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #c19752;
  }
}
</style>
