<template>
  <v-navigation-drawer
    app
    clipped
    color="filblue"
    :mini-variant="$vuetify.breakpoint.mdAndUp && isCollapsed"
    :temporary="$vuetify.breakpoint.smAndDown"
    mobile-breakpoint="959"
    v-model="sidebar"
    id="sidebar"
    :class="{ 'pt-8': isCollapsed && $vuetify.breakpoint.mdAndUp }"
    @mouseenter.native="toggleExpandOnHover(false)"
    @mouseleave.native="toggleExpandOnHover(true)"
  >
    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-list dense nav class="pt-5">
        <v-list-group class="white--text" v-if="info">
          <template v-slot:appendIcon>
            <v-icon color="white">mdi-chevron-down</v-icon>
          </template>

          <template v-slot:activator>
            <div class="d-flex align-center mb-4">
              <img :src="`${baseUrl}/${info.logoUrl}`" class="logo mr-5" />

              <div class="white--text">
                <div class="body-1 font-weight-medium mb-1">
                  {{ info.companyName }}
                </div>

                <div class="body-2 username">{{ info.loggedOnUser }}</div>
              </div>
            </div>
          </template>

          <!-- <v-list-item class="white--text" link>
            <v-list-item-icon>
              <v-icon color="white">mdi-file-word</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Quick Manual</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="white--text" link>
            <v-list-item-icon>
              <v-icon color="white">mdi-fullscreen</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Fullscreen</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item
            link
            @click="
              toggleDialog({
                open: true,
                component: 'Licenses',
                width: '800px',
              })
            "
            class="white--text"
            v-if="role == 'co' || role == 'gm'"
          >
            <v-list-item-icon>
              <v-icon color="white">mdi-card-account-details</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>My Licenses</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="white--text"
            link
            @click="
              toggleDialog({
                open: true,
                component: 'EndUserLicense',
                width: '800px',
              })
            "
            v-if="role == 'co' || role == 'gm' || role == 'psysupport'"
          >
            <v-list-item-icon>
              <v-icon color="white">mdi-license</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>End User License Agreement</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="white--text"
            link
            @click="$emit('openSignoutDialog')"
          >
            <v-list-item-icon>
              <v-icon color="white">mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </div>

    <div class="divider my-2" v-if="$vuetify.breakpoint.smAndDown"></div>

    <div
      class="d-flex pt-1 pr-1"
      v-if="!isCollapsed || $vuetify.breakpoint.smAndDown"
    >
      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp"
        color="white"
        small
        icon
        @click="togglePin"
        class="ml-auto"
        :ripple="false"
      >
        <v-icon small>{{ isPinned ? "mdi-pin-off" : "mdi-pin" }}</v-icon>
      </v-btn>
    </div>

    <AdminSidebar
      v-if="role == 'co' || role == 'gm' || role == 'psysupport'"
      :isCollapsed="isCollapsed"
    />

    <SuperAdminSidebar v-if="role == 'filistos' || role == 'allpsysup'" />
  </v-navigation-drawer>
</template>

<script>
import AdminSidebar from "@/components/sidebar/AdminSidebar";
import SuperAdminSidebar from "@/components/sidebar/SuperAdminSidebar";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    AdminSidebar,
    SuperAdminSidebar,
  },
  data() {
    return {
      isCollapsed:
        this.$vuetify.breakpoint.mdAndUp &&
        !JSON.parse(localStorage.getItem("isPinned")),
      isPinned:
        this.$vuetify.breakpoint.mdAndUp &&
        JSON.parse(localStorage.getItem("isPinned")),
      baseUrl: process.env.VUE_APP_BASE_URL.split("/api")[0],
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.auth.role,
      sidebar: (state) => state.base.sidebar,
      info: (state) => state.base.info,
    }),
    sidebar: {
      set(value) {
        if (this.$vuetify.breakpoint.mdAndUp) {
          this.toggleSidebar(true);
        } else {
          this.toggleSidebar(value);
        }
      },
      get() {
        if (this.$vuetify.breakpoint.mdAndUp) return true;

        return this.$store.state.base.sidebar;
      },
    },
  },
  methods: {
    ...mapMutations(["toggleSidebar", "toggleDialog"]),
    toggleExpandOnHover(toggle) {
      if (this.$vuetify.breakpoint.smAndDown) return;
      if (this.isPinned) return;

      this.isCollapsed = toggle;
    },
    togglePin() {
      localStorage.setItem("isPinned", !this.isPinned);
      this.isPinned = !this.isPinned;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.divider {
  height: 1px;
  background-color: #757575;
}

.username {
  display: inline-block;
  width: 100px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>
