<template>
  <div class="pt-2">
    <v-row>
      <v-col cols="4">
        <form
          class="d-flex flex-column"
          @submit.prevent="submit"
          novalidate
          v-if="factor"
        >
          <v-text-field
            outlined
            dense
            hide-details
            label="ID"
            class="mb-4 flex-grow-0"
            :value="factor.factorId"
            disabled
          ></v-text-field>

          <v-text-field
            outlined
            dense
            hide-details
            label="Score Formula"
            class="mb-4 flex-grow-0"
            :value="factor.aScoreCalculation"
            disabled
          ></v-text-field>

          <v-text-field
            outlined
            dense
            hide-details
            label="Factor Name"
            v-model.number="factorName"
            class="flex-grow-0 mb-4"
            :error="$v.factorName.$dirty && $v.factorName.$invalid"
          >
          </v-text-field>

          <v-text-field
            outlined
            dense
            hide-details
            label="Mean"
            type="number"
            v-model.number="mean"
            class="flex-grow-0 mb-4"
            :error="$v.mean.$dirty && $v.mean.$invalid"
          >
          </v-text-field>

          <v-text-field
            outlined
            dense
            hide-details
            label="Standard Deviation"
            type="number"
            v-model.number="standardDeviation"
            class="flex-grow-0 mb-4"
            :error="
              $v.standardDeviation.$dirty && $v.standardDeviation.$invalid
            "
          >
          </v-text-field>

          <v-switch
            inset
            label="Lie"
            :ripple="false"
            :value="isLie"
            hide-details
            dense
            class="flex-grow-0 ma-0 pa-0 mb-4 align-self-start"
            readonly
          ></v-switch>

          <v-text-field
            outlined
            dense
            hide-details
            label="Positive / Negative"
            :value="plusMinusValue"
            class="flex-grow-0 mb-4"
            readonly
          >
          </v-text-field>

          <div
            class="body-2 font-weight-medium error--text mb-4"
            v-if="$v.$dirty && $v.$invalid"
          >
            Please fill the required fields.
          </div>

          <v-btn
            type="submit"
            depressed
            color="filyellow"
            class="white--text"
            :disabled="!hasChanges"
            :loading="loader"
            >Save</v-btn
          >
        </form>
      </v-col>

      <v-col cols="8" v-if="factor">
        <div class="subtitle-1 font-weight-medium mb-4">
          {{
            factor.relatedQuestions.length
              ? "Related Questions"
              : "No Related Questions for this factor"
          }}
        </div>

        <v-list>
          <v-list-item
            v-for="(question, index) in factor.relatedQuestions"
            :key="question.questionId"
          >
            <v-list-item-content>
              <div class="d-flex">
                <span class="font-weight-bold mr-4">{{ index + 1 }}.</span>
                {{ question.questionText }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  props: ["factor"],
  data() {
    return {
      positiveNegativeList: [
        { itemValue: 0, itemText: "-" },
        { itemValue: 1, itemText: "Positive" },
        { itemValue: -1, itemText: "Negative" },
      ],
      factorName: "",
      mean: "",
      standardDeviation: "",
      isLie: "",
      plusMinus: "",
      initialData: null,
      loader: false,
    };
  },
  validations: {
    factorName: { required },
    mean: { required },
    standardDeviation: { required },
  },
  computed: {
    plusMinusValue() {
      if (this.plusMinus == 0) return "-";
      if (this.plusMinus == 1) return "Positive";
      return "Negative";
    },
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !=
        JSON.stringify({
          factorName: this.factorName,
          mean: this.mean,
          standardDeviation: this.standardDeviation,
        })
      );
    },
  },
  created() {
    this.initFactorData();
  },
  methods: {
    ...mapActions(["updateFactor"]),
    initFactorData() {
      this.factorName = this.factor.factorName;
      this.mean = this.factor.mean;
      this.standardDeviation = this.factor.standardDeviation;
      this.isLie = this.factor.isLie;
      this.plusMinus = this.factor.plusMinus;

      this.initialData = {
        factorName: this.factorName,
        mean: this.mean,
        standardDeviation: this.standardDeviation,
      };
    },
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        await this.updateFactor({
          isChild: this.$route.name == "questionnaireChildFactor",
          factorId: this.factor.factorId,
          factorName: this.factorName,
          mean: this.mean,
          standardDeviation: this.standardDeviation,
        });

        this.initFactorData();
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  watch: {
    factor(newValue) {
      if (newValue) {
        this.initFactorData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
