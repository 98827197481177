<template>
  <div class="component-wrapper d-flex justify-center align-start pt-12 px-5">
    <div class="inner d-flex flex-column">
      <div class="text-h6 text-center mb-8">Set a new password</div>

      <form class="d-flex flex-column" @submit.prevent="submit">
        <v-text-field
          label="Password"
          type="password"
          dense
          outlined
          hint="Your password must be at least 8 characters long and contain at least one number and one character."
          persistent-hint
          class="mb-2"
          v-model="password"
          :error-messages="passwordErrors"
        >
        </v-text-field>

        <v-text-field
          label="Confirm Password"
          type="password"
          dense
          outlined
          class="mb-1"
          v-model="confirmPassword"
          :error-messages="confirmPasswordErrors"
        >
        </v-text-field>

        <v-btn
          class="align-self-center white--text"
          color="filyellow"
          depressed
          :loading="loader"
          type="submit"
          :disabled="
            !this.$v.password.required || !this.$v.confirmPassword.required
          "
        >
          Send
        </v-btn>
      </form>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { helpers } from "vuelidate/lib/validators";

const passwordValidator = helpers.regex(
  "alpha",
  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/
);

export default {
  data() {
    return {
      password: "",
      confirmPassword: "",
      loader: false,
    };
  },
  validations: {
    password: { required, passwordValidator },
    confirmPassword: { required, sameAsPassword: sameAs("password") },
  },
  computed: {
    passwordErrors() {
      if (!this.$v.password.$dirty) return "";

      if (!this.$v.password.$invalid) return "";

      return "Your password must be at least 8 characters long and contain at least one number and one character.";
    },
    confirmPasswordErrors() {
      if (!this.$v.confirmPassword.$dirty) return "";

      if (!this.$v.confirmPassword.$invalid) return "";

      return "Passwords do not match";
    },
  },
  methods: {
    ...mapActions(["resetPassword"]),
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        await this.resetPassword({
          password: this.password,
          token: this.$route.params.token,
        });
        this.$router.push({ path: "/login" });
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
