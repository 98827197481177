var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white dialog-wrapper scrollable-dialog"},[_c('div',{staticClass:"primary pa-4 d-flex align-start"},[_c('div',{staticClass:"text-h6 white--text"},[_vm._v(" "+_vm._s(_vm.isEdit && _vm.assignment ? _vm.assignment.assignmentName : "Add new Assignment")+" ")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","small":"","color":"white"},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.loader)?_c('v-progress-circular',{staticClass:"ma-auto",attrs:{"size":"80","width":"4","indeterminate":"","color":"primary"}}):[(_vm.preselectedUsersBanner || _vm.preselectedQuestionnairesBanner)?_c('div',{staticClass:"pa-4 pb-0"},[_c('v-alert',{staticClass:"mb-0",attrs:{"border":"top","color":"success","dense":"","dark":""}},[_vm._v(" "+_vm._s(_vm.form.assigneeUserIds.length)+" user"+_vm._s(_vm.form.assigneeUserIds.length == 1 ? "" : "s")+" "+_vm._s(_vm.preselectedQuestionnairesBanner ? ("and " + (_vm.form.questionnaireIds.length) + " questionnaire" + (_vm.form.questionnaireIds.length == 1 ? "" : "s") + " ") : "")+" "+_vm._s(_vm.form.assigneeUserIds.length == 1 && !_vm.form.questionnaireIds.length ? "has" : "have")+" been preselected ")])],1):_vm._e(),(_vm.isEdit && !_vm.showReport.open)?_c('v-slide-group',{staticClass:"px-4 pt-4",attrs:{"mandatory":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-slide-item',{attrs:{"value":"AssignmentStatus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mr-4",class:{
            'white--text': active,
          },attrs:{"depressed":"","color":active ? 'filyellow' : '',"small":""},on:{"click":toggle}},[_vm._v("Assignment Status")])]}}],null,false,1128796533)}),_c('v-slide-item',{attrs:{"value":"AssignmentData"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var active = ref.active;
          var toggle = ref.toggle;
return [_c('v-btn',{class:{
            'white--text': active,
          },attrs:{"depressed":"","color":active ? 'filyellow' : '',"small":""},on:{"click":toggle}},[_vm._v("Edit Assignment ")])]}}],null,false,952939867)}),(_vm.role == 'co' || _vm.role == 'gm')?_c('div',{staticClass:"ml-auto mb-1 d-flex align-center"},[_c('div',{staticClass:"body-2 mr-2"},[_vm._v("Created By: "+_vm._s(_vm.assignment.createdBy))])]):_vm._e()],1):_vm._e(),_c(_vm.activeComponent,{tag:"component",attrs:{"isEdit":_vm.isEdit},on:{"closeDialog":function($event){return _vm.$emit('closeDialog', $event)},"assignmentUpdated":function($event){_vm.tab = 'AssignmentStatus';
        _vm.onGetAssignment($event);}}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }