<template>
  <div>
    <div class="d-flex align-start mb-2" v-if="role != 'filistos'">
      <v-select
        outlined
        dense
        label="Add Group"
        class="mr-1"
        :items="allGroups"
        item-value="groupId"
        item-text="groupName"
        :readonly="addGroupLoader"
        v-model="selectedGroup"
        :error-messages="
          addGroupError ? 'User already exists in this group' : ''
        "
        @change="addGroupError = false"
      ></v-select>

      <v-btn
        depressed
        color="success"
        :disabled="!selectedGroup"
        :loading="addGroupLoader"
        @click="onAddGroup"
      >
        <v-icon left> mdi-plus </v-icon>
        Add
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="currentUser.groups"
      class="mb-5"
      hide-default-footer
    >
      <template v-slot:[`item.remove`]="{ item }">
        <v-menu
          top
          left
          :close-on-content-click="false"
          offset-y
          offset-x
          v-model="item.deleteMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small color="error" v-bind="attrs" v-on="on">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>

          <div class="white pa-5">
            <div class="body-2 mb-4 error--text">
              Are you sure you want to remove user {{ currentUser.firstName }}
              {{ currentUser.lastName }} from group {{ item.groupName }}?
            </div>

            <v-btn
              small
              depressed
              color="error"
              @click="onRemoveUserFromGroup(item)"
              :loading="loader"
              >Remove from Group</v-btn
            >
          </div>
        </v-menu>
      </template>

      <template v-slot:[`item.startDate`]="{ item }">
        {{ dayjs(item.startDate).format("DD/MM/YYYY") }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      headers: [
        { text: "Title", value: "groupName", sortable: false },
        { text: "Date In", value: "startDate", sortable: false },
        { text: "Remove", value: "remove", sortable: false },
      ],
      loader: false,
      groups: [],
      addGroupLoader: false,
      selectedGroup: null,
      addGroupError: false,
    };
  },
  computed: {
    ...mapState({
      allGroups: (state) => state.base.allGroups,
      currentUser: (state) => state.usersNew.currentUser,
      role: (state) => state.auth.role,
    }),
  },
  created() {
    this.groups = this.currentUser.groups.map((group) => {
      return {
        ...group,
        deleteMenu: false,
      };
    });

    if (this.role == "filistos") {
      const found = this.headers.findIndex((h) => h.value == "remove");
      this.headers.splice(found, 1);
    }
  },
  methods: {
    ...mapActions(["removeUserFromGroup", "assignUsersToGroup"]),
    async onAddGroup() {
      try {
        this.addGroupError = false;
        this.addGroupLoader = true;

        await this.assignUsersToGroup({
          addUserIds: [this.currentUser.clientUserId],
          groupId: this.selectedGroup,
          startDate: this.dayjs.utc(new Date()).toISOString(),
        });

        this.$emit("groupAdded");
      } catch (e) {
        console.log(e);

        if (e.response?.data?.error?.errorCode == 10405) {
          this.addGroupError = true;
        }
      }

      this.addGroupLoader = false;
    },
    async onRemoveUserFromGroup(group) {
      this.loader = true;

      try {
        await this.removeUserFromGroup({
          groupId: group.groupId,
          userId: this.currentUser.clientUserId,
          endDate: this.dayjs.utc().toISOString(),
        });

        this.$emit("groupAdded");
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
      group.deleteMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
