<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex align-center">
      <v-text-field
        outlined
        dense
        label="Search"
        prepend-inner-icon="mdi-magnify"
        hint="Search by factor title"
        persistent-hint
        clearable
        class="align-self-start flex-grow-0 mr-auto"
        v-model="search"
        @input="page = 1"
      ></v-text-field>

      <ShowPerPage :currentPerPage="pageSize" :options="showPerPageOptions" />
    </div>

    <v-data-table
      :headers="headers"
      :items="paginatedFactors"
      class="clickable mb-4"
      hide-default-footer
      @click:row="
        $router.push(
          `/superadmin/questionnaires/${$route.params.questionnaireTypeId}/${$route.params.questionnaireId}/factors/${$event.factorId}`
        )
      "
      :items-per-page="pageSize"
    >
      <template v-slot:[`item.isLie`]="{ item }">
        {{ item.isLie ? "yes" : "no" }}
      </template>

      <template v-slot:[`item.plusMinus`]="{ item }">
        {{
          item.plusMinus > 0
            ? "Positive"
            : item.plusMinus == 0
            ? ""
            : "Negative"
        }}
      </template>
    </v-data-table>

    <v-pagination v-model="page" :length="pages" class="mt-auto"></v-pagination>

    <div v-if="searchFactors">
      {{ searchFactors.length }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ShowPerPage from "@/components/ShowPerPage";

export default {
  components: {
    ShowPerPage,
  },
  data() {
    return {
      headers: [
        { text: "Title", value: "factorName" },
        { text: "Mean", value: "mean", sortable: false },
        { text: "SD", value: "standardDeviation", sortable: false },
        { text: "Lie", value: "isLie" },
        { text: "Positive/Negative", value: "plusMinus", sortable: false },
      ],
      pageSize: 10,
      page: 1,
      showPerPageOptions: [],
      search: null,
    };
  },
  computed: {
    ...mapState({
      currentQuestionnaire: (state) =>
        state.questionnaires.currentQuestionnaire,
    }),
    searchFactors() {
      return this.search
        ? this.currentQuestionnaire.factors.filter((factor) =>
            factor.factorName.toLowerCase().includes(this.search.toLowerCase())
          )
        : null;
    },
    paginatedFactors() {
      return this.totalFactors.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      );
    },
    totalFactors() {
      return this.search
        ? this.searchFactors
        : this.currentQuestionnaire.factors;
    },
    pages() {
      return Math.ceil(this.totalFactors.length / this.pageSize);
    },
  },
  created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setPerPage,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setPerPage,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setPerPage,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setPerPage,
      },
    ];
  },
  methods: {
    setPerPage(options) {
      this.page = options.page;
      this.pageSize = options.itemsPerPage == -1 ? 1000 : options.itemsPerPage;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
