<template>
  <div class="wrapper rounded flex-grow-1 d-flex flex-column">
    <div class="section-header d-flex align-center pa-1 rounded-tl rounded-tr">
      <v-icon class="mr-2">mdi-message-text</v-icon>

      <div class="body-2">Messages</div>
    </div>

    <div class="pb-4 d-flex flex-column flex-grow-1">
      <div class="pa-4">
        <v-btn
          class="align-self-start white--text"
          depressed
          color="filyellow"
          @click="
            dialog = { open: true, component: 'NewMessage', message: null }
          "
        >
          New Message
        </v-btn>
      </div>

      <div class="scrollable pa-4 mb-2">
        <div class="d-flex">
          <v-text-field
            outlined
            dense
            label="Search"
            prepend-inner-icon="mdi-magnify"
            hint="Search by Title"
            persistent-hint
            :value="messagesParams.search"
            @input="setSuperAdminMessagesParams({ search: $event })"
            :readonly="loader"
            clearable
            class="align-self-start flex-grow-0 mr-auto"
          ></v-text-field>

          <ShowPerPage
            :currentPerPage="messagesParams.itemsPerPage"
            :options="showPerPageOptions"
            class="mr-2"
          />

          <FilterBy
            :fields="filterFields"
            :loader="loader"
            @filterChange="onFilterChange"
          />
        </div>

        <v-data-table
          :headers="headers"
          :items="messages.results"
          :server-items-length="messages.rowCount"
          class="clickable"
          hide-default-footer
          @click:row="
            dialog = { open: true, component: 'Message', message: $event }
          "
          :options.sync="options"
          @update:options="onGetMessages(true)"
          :loading="loader"
        >
          <template v-slot:[`item.dateSent`]="{ item }">
            <div class="body-2">
              {{ dayjs.utc(item.dateSent).local().format("DD/MM/YYYY HH:mm") }}
            </div>
          </template>

          <template v-slot:[`item.messageTitle`]="{ item }">
            <div class="body-2 trimmed">
              {{ item.messageTitle }}
            </div>
          </template>
        </v-data-table>
      </div>

      <v-pagination
        :length="messages.pageCount"
        v-model="messagesParams.page"
        v-if="messages.pageCount"
      ></v-pagination>
    </div>

    <v-dialog
      v-model="dialog.open"
      max-width="800px"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <div class="white" v-if="dialog.open">
        <component
          :is="dialog.component"
          :message="dialog.message"
          @closeDialog="
            dialog = { open: false, component: null, message: null }
          "
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import NewMessage from "@/views/superadmin/dashboard/messages/NewMessage";
import Message from "@/views/superadmin/dashboard/messages/Message";
import FilterBy from "@/components/FilterBy";
import ShowPerPage from "@/components/ShowPerPage";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    NewMessage,
    Message,
    FilterBy,
    ShowPerPage,
  },
  data() {
    return {
      dialog: {
        open: false,
        component: null,
        message: null,
      },
      headers: [
        {
          text: "Time",
          value: "dateSent",
        },
        {
          text: "Title",
          value: "messageTitle",
          sortable: false,
        },
      ],
      loader: false,
      isFirstCall: true,
      showPerPageOptions: [],
    };
  },
  computed: {
    ...mapState({
      messages: (state) => state.superAdminDashboard.messages,
      messagesParams: (state) => state.superAdminDashboard.messagesParams,
    }),
    options: {
      get() {
        const { itemsPerPage, page, sortBy, sortDesc } = this.messagesParams;

        return {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        };
      },
      set(value) {
        let { itemsPerPage, page, sortBy, sortDesc } = value;

        this.setSuperAdminMessagesParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      },
    },
    filterFields() {
      return [
        {
          key: "dateFrom",
          value: "From",
          selectedValue: this.messagesParams.dateFrom
            ? this.dayjs
                .utc(this.messagesParams.dateFrom)
                .local()
                .toISOString()
                .substr(0, 10)
            : null,
        },
        {
          key: "dateTo",
          value: "To",
          selectedValue: this.messagesParams.dateTo
            ? this.dayjs
                .utc(this.messagesParams.dateTo)
                .local()
                .toISOString()
                .substr(0, 10)
            : null,
        },
        {
          key: "importance",
          value: "Importance",
          values: [
            { itemKey: "low", itemValue: "Low" },
            { itemKey: "medium", itemValue: "Medium" },
            { itemKey: "high", itemValue: "High" },
          ],
          selectedValue: this.messagesParams.importance,
        },
      ];
    },
  },
  created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setSuperAdminMessagesParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setSuperAdminMessagesParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setSuperAdminMessagesParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setSuperAdminMessagesParams,
      },
    ];
  },
  methods: {
    ...mapMutations(["setSuperAdminMessagesParams"]),
    ...mapActions(["getSuperAdminMessages"]),
    onFilterChange(e) {
      this.setSuperAdminMessagesParams({
        [e.field]:
          (e.field == "dateFrom" || e.field == "dateTo") && e.value
            ? this.dayjs.utc(e.value).toISOString()
            : e.value,
      });

      this.onGetMessages(true);
    },
    async onGetMessages(refresh) {
      if (this.isFirstCall) {
        this.isFirstCall = false;
        return;
      }

      this.loader = true;

      try {
        await this.getSuperAdminMessages(refresh);
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  watch: {
    "messagesParams.search"() {
      clearTimeout(this.timeout);

      if (this.loader) return;

      this.timeout = setTimeout(() => {
        this.setSuperAdminMessagesParams({
          page: 1,
        });

        this.onGetMessages(true);
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 500px;
}

.trimmed {
  width: 600px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;

  @media only screen and (max-width: 1904px) {
    width: 400px;
  }

  @media only screen and (max-width: 600px) {
    width: 160px;
  }
}
</style>
