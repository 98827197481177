import axios from "axios";

export default {
  state: {
    initLoader: false,
    initFetched: false,
    sidebar: false,
    snackbar: {
      open: false,
      text: null,
      color: null,
      timeout: null,
    },
    dialog: {
      open: false,
      component: null,
      width: null,
    },
    lists: {
      //init
      countries: [],
      genders: [],
      languages: [],
      maritalStatuses: [],
      emailStatuses: [],
      //getInfo
      groupTypes: [],
      platformAccesses: [],
      userTypes: [],
      assignmentSendTypes: [],
    },
    questionnaires: [],
    allGroups: [],
    info: null,
    discardDialog: {
      open: false,
      allowLeave: false,
      to: null,
    },
    globalLoader: false,
    allClients: [],
    failedNavigationTo: null,
  },
  mutations: {
    toggleInitLoader(state, loader) {
      state.initLoader = loader;
    },
    toggleInitFetched(state, fetched) {
      state.initFetched = fetched;
    },
    toggleSidebar(state, sidebar) {
      state.sidebar = sidebar;
    },
    toggleSnackbar(state, snackbar) {
      state.snackbar = {
        ...state.snackbar,
        ...snackbar,
      };
    },
    toggleDialog(state, dialog) {
      state.dialog = {
        ...state.dialog,
        ...dialog,
      };
    },
    getLists(state, list) {
      state.lists[list.key] = list.value;
    },
    setAllGroups(state, allGroups) {
      state.allGroups = allGroups;
    },
    getInfo(state, info) {
      state.info = info;
    },
    getQuestionnaires(state, questionnaires) {
      state.questionnaires = questionnaires;
    },
    toggleDiscardDialog(state, dialog) {
      state.discardDialog = {
        ...state.discardDialog,
        ...dialog,
      };
    },
    toggleGlobalLoader(state, loader) {
      state.globalLoader = loader;
    },
    setAllClients(state, allClients) {
      state.allClients = allClients;
    },
    setFailedNavigationTo(state, failedNavigationTo) {
      state.failedNavigationTo = failedNavigationTo;
    },
    clearBaseState(state) {
      state.questionnaires = [];
      state.allGroups = [];

      state.lists.groupTypes = [];
      state.lists.platformAccesses = [];
      state.lists.assignmentSendTypes = [];
      state.lists.questionnaires = [];
      state.info = null;

      state.lists.userTypes.forEach((workPosition) => {
        state.lists[workPosition.itemKey] = [];
      });

      state.lists.userTypes = [];

      state.allClients = [];

      state.failedNavigationTo = null;
    },
  },
  actions: {
    async init({ dispatch, commit, rootGetters, rootState, state }) {
      try {
        if (state.initFetched) return;

        commit("toggleInitLoader", true);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/System/GetGeneralInfo`
        );

        commit("getLists", { key: "countries", value: res.data.countries });
        commit("getLists", { key: "genders", value: res.data.genders });
        commit("getLists", { key: "languages", value: res.data.languages });
        commit("getLists", { key: "maritalStatuses", value: res.data.marital });
        commit("getLists", {
          key: "emailStatuses",
          value: res.data.emailStatus,
        });

        if (rootGetters.isLoggedIn) {
          if (rootState.auth.role == "co" || rootState.auth.role == "gm") {
            await Promise.all([
              dispatch("getInfo"),
              dispatch("getQuestionnaires"),
              dispatch("getGroupsList"),
            ]);
          }

          if (rootState.auth.role == "psysupport") {
            await Promise.all([dispatch("getInfo")]);
          }

          if (rootState.auth.role == "filistos") {
            await Promise.all([
              dispatch("getInfo"),
              dispatch("getQuestionnaires"),
              dispatch("getClientsList"),
            ]);
          }

          if (rootState.auth.role == "allpsysup") {
            await Promise.all([
              dispatch("getInfo"),
              dispatch("getClientsList"),
            ]);
          }
        }

        commit("toggleInitFetched", true);
        commit("toggleInitLoader", false);
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text:
            "Something went wrong on application initialization. Please try again later",
          timeout: -1,
        });

        throw e;
      }
    },
    async getInfo({ commit, rootState }, role) {
      try {
        const computedRole = role ? role : rootState.auth.role;
        let baseController;

        if (computedRole == "filistos") {
          baseController = "SuperAdmin";
        } else if (computedRole == "allpsysup") {
          baseController = "SuperPsySup";
        } else {
          baseController = "ClientUser";
        }

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${baseController}/GetInfo`
        );

        if (computedRole == "co" || computedRole == "gm") {
          commit("getLists", { key: "groupTypes", value: res.data.groupTypes });
          commit("getLists", {
            key: "platformAccesses",
            value: res.data.platfomAccess,
          });
          commit("getLists", { key: "userTypes", value: res.data.userTypes });
          commit("getLists", {
            key: "assignmentSendTypes",
            value: res.data.assignmentSendTypes,
          });

          res.data.workPositions.forEach((workPosition) => {
            commit("getLists", {
              key: workPosition.itemType,
              value: workPosition.items,
            });
          });

          const {
            assignmentSendTypes,
            groupTypes,
            platfomAccess,
            userTypes,
            workPositions,
            ...info
          } = res.data;

          commit("getInfo", info);
        } else {
          commit("getLists", {
            key: "platformAccesses",
            value: res.data.platfomAccess,
          });

          commit("getInfo", res.data);
        }
      } catch (e) {
        throw e;
      }
    },
    async getQuestionnaires({ commit, rootState }, role) {
      try {
        const computedRole = role ? role : rootState.auth.role;
        let res;

        if (computedRole == "co" || computedRole == "gm") {
          res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/ClientUser/GetQuestionnaires`
          );
        }

        if (computedRole == "filistos") {
          res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetQuestionnaires`
          );
        }

        commit("getQuestionnaires", res.data.results);
      } catch (e) {
        throw e;
      }
    },
    async getGroupsList({ commit, state, rootState }, payload) {
      //for dropdowns... different from groups module "getGroups"
      const computedRole = payload?.role ? payload?.role : rootState.auth.role;

      try {
        let res;

        if (!payload?.groupTypeId) {
          if (payload?.refresh || !state.allGroups.length) {
            res = await axios.get(
              `${process.env.VUE_APP_BASE_URL}/${
                computedRole == "co" ? "ClientAdmin" : "GroupManager"
              }/GetGroups `
            );
            commit("setAllGroups", res.data?.results);
          } else {
            return state.allGroups;
          }
        } else {
          res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/${
              computedRole == "co" ? "ClientAdmin" : "GroupManager"
            }/GetGroups`,
            {
              params: {
                groupTypeId: payload?.groupTypeId,
              },
            }
          );
        }

        return res.data?.results;
      } catch (e) {
        throw e;
      }
    },
    async getVisibleColumns({ commit }, gridName) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/System/GetVisibleColumns/${gridName}`
        );

        return res.data.results;
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async setVisibleColumns({ commit }, payload) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/System/SetVisibleColumns`,
          payload
        );

        return res.data.results;
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async getClientsList({ commit, rootState }, role) {
      try {
        const computedRole = role ? role : rootState.auth.role;
        let baseController;

        if (computedRole == "filistos") {
          baseController = "SuperAdmin";
        } else {
          baseController = "SuperPsySup";
        }

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${baseController}/GetClients`
        );

        commit("setAllClients", res.data.results);
      } catch (e) {
        throw e;
      }
    },
  },
};
