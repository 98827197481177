import axios from 'axios';

export default {
    state: {
        // CLIENTS
        clientsParams: {
            itemsPerPage: 10,
            page: 1,
            sortBy: [],
            sortDesc: [],
            search: "",
        },
        clients: {
            results: [],
            rowCount: 0
        },
        clientsFetched: false,
        currentClient: null,
        // questionnaires
        questionnaires: [],
        questionnairesFetched: false,
        // transactions
        transactionsParams: {
            clientId: null,
            itemsPerPage: 10,
            page: 1,
            sortBy: [],
            sortDesc: [],
            search: "",
            dateFrom: null,
            dateTo: null,
            transactionTypeId: null,
        },
        transactions: {
            results: [],
            rowCount: 0
        },
        transactionsFetched: false,
        // mobile apps
        mobileApps: null,
        mobileAppsFetched: false,
        //admins
        adminsParams: {
            itemsPerPage: 10,
            page: 1,
            sortBy: [],
            sortDesc: [],
            search: "",
            clientId: null,
            platformAccessId: null,
        },
        admins: {
            results: [],
            rowCount: 0
        },
        adminsFetched: false,
        currentAdmin: null
    },
    mutations: {
        // CLIENTS
        setClientsParams(state, params) {
            state.clientsParams = {
                ...state.clientsParams,
                ...params
            }
        },
        getClients(state, clients) {
            state.clients = clients;
        },
        toggleClientsFetched(state, fetched) {
            state.clientsFetched = fetched;
        },
        setCurrentClient(state, client) {
            state.currentClient = client;
        },
        // questionnaires
        getClientQuestionnaires(state, questionnaires) {
            state.questionnaires = questionnaires;
        },
        toggleQuestionnairesFetched(state, fetched) {
            state.questionnairesFetched = fetched;
        },
        // transactions
        setTransactionsParams(state, params) {
            state.transactionsParams = {
                ...state.transactionsParams,
                ...params
            }
        },
        getTransactions(state, transactions) {
            state.transactions = transactions;
        },
        toggleTransactionsFetched(state, fetched) {
            state.transactionsFetched = fetched;
        },
        // mobile apps
        getMobileAppsOfClient(state, mobileApps) {
            state.mobileApps = mobileApps;
        },
        toggleMobileAppsFetched(state, fetched) {
            state.mobileAppsFetched = fetched;
        },
        // admins
        setAdminsParams(state, params) {
            state.adminsParams = {
                ...state.adminsParams,
                ...params
            }
        },
        getAdmins(state, admins) {
            state.admins = admins;
        },
        toggleAdminsFetched(state, fetched) {
            state.adminsFetched = fetched;
        },
        setCurrentAdmin(state, user) {
            state.currentAdmin = user;
        },
        clearClientsState(state) {
            // CLIENTS
            state.clientsParams = {
                itemsPerPage: 10,
                page: 1,
                sortBy: [],
                sortDesc: [],
                search: "",
            };
            state.clients = {
                results: [],
                rowCount: 0
            };
            state.clientsFetched = false;
            state.currentClient = null;
            // questionnaires
            state.questionnaires = [];
            state.questionnairesFetched = null;
            // transactions
            state.transactionsParams = {
                clientId: null,
                itemsPerPage: 10,
                page: 1,
                sortBy: [],
                sortDesc: [],
                search: "",
                dateFrom: null,
                dateTo: null,
                transactionTypeId: null,
            };
            state.transactions = {
                results: [],
                rowCount: 0
            };
            state.transactionsFetched = false;
            // mobile apps
            state.mobileApp = null;
            state.mobileAppsFetched = false;
            // admins
            state.adminsParams = {
                itemsPerPage: 10,
                page: 1,
                sortBy: [],
                sortDesc: [],
                search: "",
                clientId: null,
                platformAccessId: null,
            };
            state.admins = {
                results: [],
                rowCount: 0
            };
            state.adminsFetched = false;
            state.currentAdmin = null;
        }
    },
    actions: {
        // CLIENTS
        async getClients({ commit, state }, refresh) {
            if (state.clientsFetched && !refresh) return;

            try {
                const res = await axios.get(
                    `${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetClients`,
                    {
                        params: {
                            pageSize: state.clientsParams.itemsPerPage != -1 ? state.clientsParams.itemsPerPage : 1000,
                            pageNo: state.clientsParams.page,
                            ...(state.clientsParams.sortBy[0] && { sortBy: state.clientsParams.sortBy[0] }),
                            ...(state.clientsParams.sortDesc[0] && { dir: "desc" }),
                            ...(state.clientsParams.search && { search: state.clientsParams.search }),
                        }
                    }
                );

                commit("getClients", res.data);
                commit("toggleClientsFetched", true);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async addClient({ commit }, payload) {
            try {
                const formData = new FormData();

                Object.keys(payload).forEach(key => {
                    formData.set(key, payload[key]);
                })

                await axios.post(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/AddClient`, formData);

                commit("toggleClientsFetched", false);
                commit("getClients", {
                    results: [],
                    rowCount: 0
                });

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Client has been successfully added!",
                    timeout: 5000
                })
            } catch (e) {
                if (e.response?.data?.error?.errorCode != 10405)
                    commit("toggleSnackbar", {
                        open: true,
                        color: "error",
                        text: "Something went wrong. Please try again later.",
                        timeout: 5000
                    })

                throw e;
            }
        },
        async getClient({ commit, state }, payload) {
            try {
                if (state.currentClient && !payload.refresh) return;

                commit("toggleGlobalLoader", true);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetClient/${payload.clientId}`);

                commit("setCurrentClient", res.data);
                commit("toggleGlobalLoader", false);

            } catch (e) {
                commit("toggleGlobalLoader", false);
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async updateClient({ commit }, payload) {
            const formData = new FormData();

            Object.keys(payload).forEach(key => {
                formData.set(key, payload[key]);
            })

            const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/UpdateClient`, formData);

            commit("setCurrentClient", res.data);
            commit("toggleClientsFetched", false);
            commit("getClients", {
                results: [],
                rowCount: 0
            });

            commit("toggleSnackbar", {
                open: true,
                color: "success",
                text: "Client has been successfully updated!",
                timeout: 5000
            })
        },
        // questionnaires
        async getClientQuestionnaires({ commit, state }, payload) {
            if (state.questionnairesFetched && !payload.refresh) return;

            try {
                commit("toggleGlobalLoader", true);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetClientQuestionnaires/${payload.clientId}`);

                commit("toggleQuestionnairesFetched", true);
                commit("toggleGlobalLoader", false);
                commit("getClientQuestionnaires", res.data.results);
            } catch (e) {
                commit("toggleGlobalLoader", false);
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        // transactions
        async getTransactions({ commit, state }, refresh) {
            if (state.transactionsFetched && !refresh) return;

            try {
                commit("toggleGlobalLoader", true);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetTransactions`, {
                    params: {
                        clientId: state.transactionsParams.clientId,
                        pageSize: state.transactionsParams.itemsPerPage != -1 ? state.transactionsParams.itemsPerPage : 1000,
                        pageNo: state.transactionsParams.page,
                        ...(state.transactionsParams.sortBy[0] && { sortBy: state.transactionsParams.sortBy[0] }),
                        ...(state.transactionsParams.sortDesc[0] && { dir: "desc" }),
                        ...(state.transactionsParams.search && { search: state.transactionsParams.search }),
                        ...(state.transactionsParams.dateFrom && { fromDate: state.transactionsParams.dateFrom }),
                        ...(state.transactionsParams.dateTo && { toDate: state.transactionsParams.dateTo }),
                        ...(state.transactionsParams.transactionTypeId && { transactionTypeId: state.transactionsParams.transactionTypeId }),
                    }
                });

                commit("toggleTransactionsFetched", true);
                commit("toggleGlobalLoader", false);
                commit("getTransactions", res.data);
            } catch (e) {
                commit("toggleGlobalLoader", false);
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async newTransaction({ commit }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/AddQuestionnairePurchase`, payload)

                commit("toggleTransactionsFetched", false);
                commit("toggleQuestionnairesFetched", false);

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Purchase has been added!",
                    timeout: 5000
                })
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        // mobile apps
        async getMobileAppsOfClient({ state, commit }, payload) {
            if (state.mobileAppsFetched && !payload.refresh) return;

            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetMobileSubscriptions`, {
                    params: {
                        clientId: payload.clientId
                    }
                })

                const mobileAppsObj = res.data.results.reduce(
                    (a, v) => ({ ...a, [v.mobileAppId]: v }),
                    {}
                );

                commit("getMobileAppsOfClient", mobileAppsObj);
                commit("toggleMobileAppsFetched", true);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async updateMobileAppSubscription({ commit, dispatch }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/UpdateMobileSubscription`, payload);

                commit("toggleMobileAppsFetched", false);

                dispatch("getMobileAppsOfClient", { clientId: payload.clientId });

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Subscription has been updated.",
                    timeout: 5000
                })
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        // admins
        async getAdmins({ commit, state }, refresh) {
            if (state.adminsFetched && !refresh) return;

            try {
                commit("toggleGlobalLoader", true);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetClientAdmins`, {
                    params: {
                        pageSize: state.adminsParams.itemsPerPage != -1 ? state.adminsParams.itemsPerPage : 1000,
                        pageNo: state.adminsParams.page,
                        ...(state.adminsParams.sortBy[0] && { sortBy: state.adminsParams.sortBy[0] }),
                        ...(state.adminsParams.sortDesc[0] && { dir: "desc" }),
                        clientId: state.adminsParams.clientId,
                        ...(state.adminsParams.search && { search: state.adminsParams.search }),
                        ...(state.adminsParams.platformAccessId && { platformAccessId: state.adminsParams.platformAccessId }),
                    }
                });

                commit("toggleAdminsFetched", true);
                commit("toggleGlobalLoader", false);
                commit("getAdmins", res.data);
            } catch (e) {
                commit("toggleGlobalLoader", false);
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async addEditAdmin({ commit, dispatch, state }, payload) {
            try {
                if (!payload.isEdit) {
                    await axios.post(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/AddUser`, payload);
                } else {
                    await axios.post(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/updateUser `, payload);
                }

                if (payload.isClientAdmin && state.currentClient.identityNumber != payload.identityNumber) {
                    dispatch("getClient", { clientId: state.currentClient.clientId, refresh: true });
                }

                commit("setAdminsParams", { clientId: payload.clientId });
                dispatch("getAdmins", true);

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: `User has been ${payload.isEdit ? "edited" : "added"} successfully!`,
                    timeout: 5000
                })
            } catch (e) {
                if (e.response.data.error.errorCode != 20403 && e.response.data.error.errorCode != 20402 && e.response.data.error.errorCode != 20412) {
                    commit("toggleSnackbar", {
                        open: true,
                        color: "error",
                        text: "Something went wrong. Please try again later.",
                        timeout: 5000
                    })
                }

                throw e;
            }
        },
        async getAdmin({ commit }, id) {
            try {
                commit("toggleGlobalLoader", true);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetUser/${id}`);

                commit("setCurrentAdmin", res.data);

                commit("toggleGlobalLoader", false);
            } catch (e) {
                commit("toggleGlobalLoader", false);

                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async deleteAdmin({ commit }, user) {
            try {
                await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/DeleteUser/${user.clientUserId}`);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
    }
}
