<template>
  <div class="component-wrapper px-4 pt-4 grey lighten-3 d-flex flex-column">
    <div class="header d-flex justify-center mb-4">
      <v-btn
        class="back-btn"
        icon
        small
        @click="
          $router.push({
            path: backPath,
          })
        "
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <div class="body-1 font-weight-medium text-center">
        Comparison Results
      </div>
    </div>

    <div class="wrapper rounded flex-grow-1 d-flex flex-column pa-4">
      <v-progress-circular
        size="120"
        width="4"
        indeterminate
        color="primary"
        class="ma-auto"
        v-if="loader"
      ></v-progress-circular>

      <template v-else>
        <div class="d-flex justify-end">
          <v-btn
            depressed
            color="primary"
            class="white--text"
            @click="onComparisonGetExcel"
            :loading="excelLoader"
            >Export Excel</v-btn
          >
        </div>

        <v-data-table
          :headers="headers"
          :items="comparisonResults.results"
          :items-per-page="10000"
          hide-default-footer
          hide-default-header
        >
          <template v-slot:header="{ props: { headers } }">
            <thead class="thead">
              <tr>
                <th class="rotate" v-for="(h, index) in headers" :key="index">
                  <div>
                    <span
                      :class="{ 'font-weight-black black--text': !h.child }"
                      >{{ h.text }}</span
                    >
                  </div>
                </th>
              </tr>
            </thead>
          </template>

          <template v-slot:item="item">
            <tr v-if="item.item" class="body">
              <td
                :class="{
                  'filred-bg':
                    Object(item.item[h.value]) === item.item[h.value] &&
                    item.item[h.value].color == 'R',
                  'filyellow-bg':
                    Object(item.item[h.value]) === item.item[h.value] &&
                    item.item[h.value].color == 'Y',
                  'filgreen-bg':
                    Object(item.item[h.value]) === item.item[h.value] &&
                    item.item[h.value].color == 'G',
                }"
                v-for="(h, index) in headers"
                :key="index"
              >
                <div
                  v-if="Object(item.item[h.value]) === item.item[h.value]"
                  :class="{
                    'font-weight-bold':
                      item.item[h.value].statistics == 'S' ||
                      item.item[h.value].statistics == 'A',
                  }"
                >
                  {{
                    item.item[h.value].aScore ? item.item[h.value].aScore : "-"
                  }}
                </div>

                <div v-else>
                  <div v-if="Date.parse(item.item[h.value])">
                    {{
                      dayjs
                        .utc(item.item[h.value])
                        .local()
                        .format("DD/MM/YYYY")
                    }}
                  </div>

                  <div v-else>
                    {{ item.item[h.value] ? item.item[h.value] : "-" }}
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      headers: [],
      items: [],
      loader: false,
      excelLoader: false,
    };
  },
  computed: {
    ...mapState({
      userQuestsParams: (state) => state.comparison.userQuestsParams,
      factorGroupIds: (state) => state.comparison.factorGroupIds,
      userQuestsToCompare: (state) => state.comparison.userQuestsToCompare,
      positionsToCompare: (state) => state.comparison.positionsToCompare,
      comparisonResults: (state) => state.comparison.comparisonResults,
      role: (state) => state.auth.role,
    }),
    backPath() {
      const roles = {
        co: "/",
        gm: "/",
        psysupport: "/psysupport/",
        allpsysup: "/allpsysup/",
        filistos: "/superadmin/",
      };

      return `${roles[this.role]}comparison`;
    },
  },
  async created() {
    this.loader = true;

    try {
      const res = await Promise.all([
        this.getComparisonColumns({
          questionnaireId:
            this.role == "psysupport" || this.role == "allpsysup"
              ? this.userQuestsParams.selectedApp
              : this.userQuestsParams.questionnaire.questionnaireId,
          factorGroupIds: this.factorGroupIds,
        }),
        this.getComparisonResults({
          questionnaireId:
            this.role == "allpsysup" || this.role == "psysupport"
              ? this.userQuestsParams.selectedApp
              : this.userQuestsParams?.questionnaire?.questionnaireId,
          assignUserQuestIds: this.userQuestsToCompare,
          positionIds: this.positionsToCompare,
          factorGroupIds: this.factorGroupIds,
        }),
      ]);

      this.headers = res[0];
    } catch (e) {
      console.log(e);
      this.$route.push("/comparison");
    }

    this.loader = false;
  },
  methods: {
    ...mapActions([
      "getComparisonColumns",
      "getComparisonResults",
      "comparisonGetExcel",
    ]),
    async onComparisonGetExcel() {
      this.excelLoader = true;

      try {
        await this.comparisonGetExcel();
      } catch (e) {
        console.log(e);
      }

      this.excelLoader = false;
    },
  },
};
</script>

<style lang="scss">
.thead {
  height: 140px;
}

th.rotate {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
  padding: 0 !important;
}

th.rotate > div {
  transform: 
    /* Magic Numbers */ translate(50%, 54px)
    /* 45 is really 360 - 45 */ rotate(270deg);
  width: 30px;
}
th.rotate > div > span {
  padding: 5px 10px;
}

tr.body {
  td {
    padding: 0 8px !important;
    border-right: 1px solid #ccc;
    white-space: nowrap;
  }
}

.filred-bg {
  background-color: rgb(255, 208, 208);
}

.filyellow-bg {
  background-color: rgb(255, 255, 208);
}

.filgreen-bg {
  background-color: rgb(208, 255, 208);
}
</style>
