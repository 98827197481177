<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex flex-grow-1">
      <v-tabs vertical id="sections-tabs">
        <v-tab
          v-for="section in currentQuestionnaire.sections"
          :key="section.sectionId"
          :disabled="globalLoader"
          :to="`/superadmin/questionnaires/${$route.params.questionnaireTypeId}/${$route.params.questionnaireId}/sections/${section.sectionId}`"
        >
          {{ section.sectionName }}
        </v-tab>
      </v-tabs>

      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      currentQuestionnaire: (state) =>
        state.questionnaires.currentQuestionnaire,
      globalLoader: (state) => state.base.globalLoader,
    }),
  },
};
</script>

<style lang="scss" scoped>
#sections-tabs.v-tabs {
  flex: 0 !important;
}
</style>
