<template>
  <div class="flex-grow-1 d-flex flex-column">
    <v-progress-circular
      size="80"
      width="4"
      indeterminate
      color="primary"
      v-if="loader"
      class="ma-auto"
    ></v-progress-circular>

    <div v-else class="scrollable px-4 pb-4">
      <v-expansion-panels
        v-if="trainingNeeds && trainingNeeds.length > 0"
        v-model="tab"
      >
        <v-expansion-panel
          @change="onExpansionPanelChange(trainingNeed.factors)"
          v-for="(trainingNeed, index) in trainingNeeds"
          :key="trainingNeed.assignmentUserQuestionnaireId"
        >
          <v-expansion-panel-header
            :class="{ 'grey lighten-3': tab === index }"
          >
            <div class="d-flex align-center">
              <div class="subtitle-2">
                {{ trainingNeed.questionnaireName }}
              </div>

              <div class="text-h6 mx-4">|</div>

              <div class="body-2 mr-2">Completion Date:</div>

              <div class="caption px-1 rounded success white--text">
                {{ dayjs(trainingNeed.dateCompleted).format("DD/MM/YYYY") }}
              </div>
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="pt-4">
            <v-expansion-panels multiple v-model="factorsTabs">
              <v-expansion-panel
                :readonly="factor.elearnings && factor.elearnings.length == 0"
                v-for="(factor, i) in trainingNeed.factors"
                :key="factor.factorName"
              >
                <v-expansion-panel-header>
                  <template v-slot:actions>
                    <div class="d-flex align-center">
                      <div class="subtitle-2 mr-1">
                        {{
                          factor.elearnings && factor.elearnings.length > 0
                            ? selectedElearnigns[i]
                              ? selectedElearnigns[i].length +
                                "/" +
                                factor.elearnings.length
                              : ""
                            : "No Available E-Learnings"
                        }}
                      </div>
                      <v-icon v-if="factor.elearnings.length > 0">
                        mdi-chevron-down
                      </v-icon>
                      <v-icon v-else> </v-icon>
                    </div>
                  </template>
                  <div class="d-flex align-center">
                    <div class="subtitle-2">
                      {{ factor.factorName }}
                    </div>

                    <div class="text-h6 mx-4">|</div>

                    <div
                      class="bullet large filred"
                      :class="factor.factorFlag == 'R' ? 'filred' : 'filyellow'"
                    >
                      {{ factor.factorResult }}
                    </div>
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content expand class="pt-4">
                  <v-data-table
                    v-model="selectedElearnigns[i]"
                    v-if="factor.elearnings"
                    :items="factor.elearnings"
                    :headers="elearningsHeaders"
                    hide-default-footer
                    show-select
                    checkbox-color="primary"
                    class="mb-4"
                    item-key="recommendedElearningId"
                    :item-class="itemRowBackground"
                  >
                    <template
                      v-slot:[`item.recommendedElearningAssignedOn`]="{ item }"
                    >
                      <div v-if="item.recommendedElearningAssignedOn">
                        {{
                          dayjs(item.recommendedElearningAssignedOn).format(
                            "DD/MM/YYYY"
                          )
                        }}
                      </div>
                      <div v-else>
                        -
                      </div>
                    </template>

                    <template
                      v-slot:[`item.recommendedElearningCompletedOn`]="{ item }"
                    >
                      <div v-if="item.recommendedElearningCompletedOn">
                        {{
                          dayjs(item.recommendedElearningCompletedOn).format(
                            "DD/MM/YYYY"
                          )
                        }}
                      </div>
                      <div v-else>
                        -
                      </div>
                    </template>

                    <template
                      v-slot:[`item.recommendedElearningPassed`]="{ item }"
                    >
                      <div
                        v-if="item.recommendedElearningPassed"
                        class="bullet large"
                        :class="
                          item.recommendedElearningPassed ? 'success' : 'error'
                        "
                      >
                        {{
                          item.recommendedElearningPassed
                            ? "mdi-check"
                            : "mdi-close"
                        }}
                      </div>
                      <div v-else>
                        -
                      </div>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn
                        v-if="item.showReport"
                        small
                        @click="onShowReport(item, 1)"
                        width="60px"
                        >report</v-btn
                      >
                      <div v-else>
                        -
                      </div>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <div style="width: 100%;" class="d-flex justify-end mt-4">
              <v-btn
                class="body-1 align-self-end"
                color="success"
                @click="onAssign"
                :disabled="selectedElearnignsLength"
              >
                Assign E-learnings
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-else>
        No available assignments
      </div>
    </div>
    <v-dialog
      v-if="assignmentDialog"
      v-model="assignmentDialog"
      max-width="1250px"
    >
      <AssignmentDialog
        :selectedUser="selectedUser"
        :eLearnings="uniqueSelectedElearnings"
        v-if="assignmentDialog"
        @closeDialog="assignmentDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import AssignmentDialog from "./AssignmentDialog.vue";
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  components: {
    AssignmentDialog,
  },

  async created() {
    this.loader = true;
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/clientUser/GetFilistosTrainingNeeds/${this.selectedUser.clientUserId}`
      );

      this.trainingNeeds = res.data.assignments;
    } catch (e) {
      console.log(e);
    }

    this.loader = false;
  },

  props: {
    selectedUser: Object,
  },

  data() {
    return {
      assignmentDialog: false,
      loader: false,
      tab: null,
      currentFactor: null,
      trainingNeeds: [],
      elearnings: [],
      selectedElearnigns: [],
      uniqueSelectedElearnings: [],
      factorsTabs: [],

      elearningsHeaders: [
        { text: "Elearning Name", value: "recommendedElearningName" },
        { text: "Assigned Date", value: "recommendedElearningAssignedOn" },
        { text: "Completion Date", value: "recommendedElearningCompletedOn" },
        { text: "Passed", value: "recommendedElearningPassed" },
        { text: "", value: "actions" },
      ],
    };
  },

  computed: {
    selectedElearnignsLength() {
      const found = this.selectedElearnigns.filter((el) => el.length > 0);
      if (found.length > 0) return false;
      else return true;
    },
  },

  methods: {
    ...mapMutations(["toggleShowReport"]),

    onShowReport(item, panel) {
      if (this.$route.name == "editUserUserAssignments") {
        this.$router.push(
          `/superadmin/clients/${this.$route.params.clientId}/users/${this.$route.params.userId}/report/${item.assignUserQuesId}`
        );
      } else {
        this.toggleShowReport({
          open: true,
          id: this.trainingNeeds[this.tab].assignmentUserQuestionnaireId,
          index: panel,
        });
      }
    },

    itemRowBackground(item) {
      return item.recommendedElearningAssignedOn != null ||
        item.recommendedElearningPassed
        ? "training-style-1"
        : "";
    },

    onElearningsClick(item) {
      this.currentFactor = item.factorName;
      this.elearnings = item.elearnings;
    },

    onExpansionPanelChange(factors) {
      const tabs = factors.map((el, i) => {
        if (el.elearnings.length > 0) return i;
      });
      this.factorsTabs = tabs;
      this.selectedElearnigns = [];
      this.selectedElearnigns = [...factors.map((el) => [])];
      console.log(this.selectedElearnigns);
    },

    async onAssign() {
      const flatSelectedElearnings = this.selectedElearnigns.flat();
      // Create a Map to store unique objects based on recommendedElearningId
      const uniqueObjectMap = new Map();

      // Iterate through the flattened array and add unique objects to the Map
      flatSelectedElearnings.forEach((item) => {
        uniqueObjectMap.set(item.recommendedElearningId, item);
      });

      // Extract values from the Map to get unique objects
      this.uniqueSelectedElearnings = Array.from(uniqueObjectMap.values());

      // Resulting array with unique objects based on recommendedElearningId
      this.assignmentDialog = true;
    },
  },
};
</script>

<style>
.training-style-1 {
  color: #739e73;
  /* font-weight: bold; */
}
</style>
