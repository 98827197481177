import axios from 'axios';
import dayjs from "dayjs"

export default {
    state: {
        assignment: null,
        questionnaire: null,
    },
    mutations: {
        getAssigneeAssignment(state, assignment) {
            state.assignment = assignment;
        },
        getQuestionnaire(state, questionnaire) {
            state.questionnaire = questionnaire;
        }
    },
    actions: {
        async getAssigneeAssignment({ commit, dispatch, state }, userAssignmentId) {
            if (state.assignment) return;

            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/BasicUser/GetUserAssignment/${userAssignmentId}`);

                await dispatch("logout", { fromAssignee: true, withoutRefresh: true }) //logout without deleting local storage

                commit('login', {
                    token: res.data.jwt,
                    role: "assignee"
                }); //login as assignee

                axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.jwt}`;

                commit("getAssigneeAssignment", {
                    ...res.data,
                    userAssignmentId
                });

            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getQuestionnaireLanguages({ commit }, questionnaireId) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/BasicUser/GetAvailableLanguages/${questionnaireId}`);

                return res.data.results;
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getQuestionnaire({ commit }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/BasicUser/GetQuestionnaire/${payload.questionnaireId}/${payload.language}`);

                res.data?.sections?.forEach(section => {
                    const questions = section?.questions?.map(question => {
                        const subQuestions = question.subQuestions.map(subQuestion => {
                            return {
                                ...subQuestion,
                                answerValue: subQuestion.inputTypeId == "calnd" && subQuestion.answerValue ? dayjs.utc(subQuestion.answerValue).local().toISOString().substr(0, 10) : subQuestion.answerValue,
                            }
                        })

                        return {
                            ...question,
                            answerValue: question.inputTypeId == "calnd" && question.answerValue ? dayjs.utc(question.answerValue).local().toISOString().substr(0, 10) : question.answerValue,
                            subQuestions
                        }
                    })

                    section.questions = questions;
                })

                commit("getQuestionnaire", res.data);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async answerQuestionnaire({ commit }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/BasicUser/AnswerQuestionnaire`, {
                    id: payload.id,
                    isFinal: payload.isFinal,
                    answers: payload.answers,
                    ...(payload.completionTimeSec && { completionTimeSec: payload.completionTimeSec }),
                });

                commit("getAssigneeAssignment", null);

                if (!payload.forced)
                    commit("toggleSnackbar", {
                        open: true,
                        color: "success",
                        text: payload.isFinal ? "Questionnaire has been submitted!" : "Questionnaire has been saved as draft!",
                        timeout: 5000
                    })
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        }
    }
}
