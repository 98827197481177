<template>
  <div class="component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column">
    <div class="body-1 font-weight-medium mb-5">Monitoring</div>

    <div class="wrapper d-flex flex-column rounded pa-4 flex-grow-1">
      <div class="d-flex align-start">
        <v-menu offset-y v-if="selectedAssignments.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              color="primary"
              depressed
            >
              Actions
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item link @click="onSendProposedFilistosTraining">
              <v-list-item-title
                >Send proposed Filistos Trainings
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-text-field
          outlined
          dense
          label="Search"
          prepend-inner-icon="mdi-magnify"
          hint="Search by user full name"
          persistent-hint
          :value="monitoringParams.search"
          @input="setMonitoringParams({ search: $event })"
          :readonly="loader"
          clearable
          class="align-self-start flex-grow-0"
          :class="{
            'mr-4': $route.name != 'adminMonitoring',
            'mr-2': $route.name == 'adminMonitoring',
          }"
        ></v-text-field>
        <v-checkbox
          @change="onFilterChange($event, 'checkbox')"
          v-if="
            monitoringParams.questionnaire &&
              monitoringParams.questionnaire.questionnaireTypeId == 'psycho'
          "
          v-model="proposedFilistosTrainings"
          :label="`With Proposed Filistos Training`"
          hide-details
          class="mt-1 mr-auto"
        ></v-checkbox>
        <div
          class="d-flex flex-column mr-auto align-self-start"
          v-if="$route.name != 'adminMonitoring'"
        >
          <v-menu top right offset-x>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex align-center mb-2 filter px-2 rounded"
                v-bind="attrs"
                v-on="on"
              >
                <div class="body-2 mr-2">Reviewed:</div>
                <div class="subtitle-2 mr-2">
                  {{ reviewedFilter.viewValue }}
                </div>

                <v-icon small>mdi-chevron-right</v-icon>
              </div>
            </template>

            <div class="white pa-2">
              <div
                class="body-2 list-filter rounded px-1"
                v-for="(filter, index) in reviewedFilters"
                :key="filter.key"
                :class="{
                  active: reviewedFilter == filter.key,
                  'mb-2': index != reviewedFilters.length - 1,
                }"
                @click="onCustomFilterChange('reviewed', filter)"
              >
                {{ filter.viewValue }}
              </div>
            </div>
          </v-menu>

          <v-menu top right offset-x>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex align-center mb-2 filter px-2 rounded"
                v-bind="attrs"
                v-on="on"
              >
                <div class="body-2 mr-2">Notification:</div>
                <div class="subtitle-2 mr-2">{{ notifFilter.viewValue }}</div>

                <v-icon small>mdi-chevron-right</v-icon>
              </div>
            </template>

            <div class="white pa-2">
              <div
                class="body-2 list-filter rounded px-1"
                v-for="(filter, index) in notifFilters"
                :key="filter.key"
                :class="{
                  active: notifFilter == filter.key,
                  'mb-2': index != notifFilters.length - 1,
                }"
                @click="onCustomFilterChange('notif', filter)"
              >
                {{ filter.viewValue }}
              </div>
            </div>
          </v-menu>
        </div>

        <FilterBy
          :fields="filterFields"
          :loader="loader"
          @filterChange="onFilterChange"
          class="mr-2 ml-auto"
        />

        <ShowPerPage
          :currentPerPage="monitoringParams.itemsPerPage"
          :options="showPerPageOptions"
        />
      </div>

      <v-data-table
        item-key="assignUserQuestId"
        v-model="selectedAssignments"
        :headers="headers"
        :items="monitoring.results"
        :server-items-length="monitoring.rowCount"
        class=" mb-4"
        checkbox-color="primary"
        hide-default-footer
        :options.sync="options"
        @update:options="onGetMonitoring(true)"
        :loading="loader"
        :show-select="proposedFilistosTrainings ? true : false"
      >
        <template v-slot:[`item.dateCompleted`]="{ item }">
          {{
            item.dateCompleted
              ? dayjs
                  .utc(item.dateCompleted)
                  .local()
                  .format("DD/MM/YYYY ")
              : "-"
          }}
        </template>

        <template v-slot:[`item.redCount`]="{ item }">
          <div
            v-if="
              ((item.questionnaireTypeId == 'psycho' ||
                item.questionnaireTypeId == 'abillity') &&
                info.currentLogin.gmCanReadResults) ||
                (item.questionnaireTypeId == 'elearn' &&
                  info.currentLogin.gmCanAccessElearning) ||
                (item.questionnaireTypeId == 'form' &&
                  info.currentLogin.gmCanAccessForms) ||
                (item.questionnaireTypeId == 'ability' &&
                  info.currentLogin.gmCanReadResults)
            "
            class="bullet large filred"
          >
            {{ item.redCount }}
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template v-slot:[`item.yellowCount`]="{ item }">
          <div
            v-if="
              ((item.questionnaireTypeId == 'psycho' ||
                item.questionnaireTypeId == 'abillity') &&
                info.currentLogin.gmCanReadResults) ||
                (item.questionnaireTypeId == 'elearn' &&
                  info.currentLogin.gmCanAccessElearning) ||
                (item.questionnaireTypeId == 'form' &&
                  info.currentLogin.gmCanAccessForms) ||
                (item.questionnaireTypeId == 'ability' &&
                  info.currentLogin.gmCanReadResults)
            "
            class="bullet large filyellow"
          >
            {{ item.yellowCount }}
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template v-slot:[`item.isReviewed`]="{ item }">
          <div
            class="bullet small"
            :class="{ filgreen: item.isReviewed, filred: !item.isReviewed }"
          ></div>
        </template>

        <template v-slot:[`item.notifSent`]="{ item }">
          <div
            class="bullet small"
            :class="{ filgreen: item.notifSent, filred: !item.notifSent }"
          ></div>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            v-if="
              ((item.questionnaireTypeId == 'psycho' ||
                item.questionnaireTypeId == 'ability') &&
                info.currentLogin.gmCanReadResults) ||
                (item.questionnaireTypeId == 'elearn' &&
                  info.currentLogin.gmCanAccessElearning) ||
                (item.questionnaireTypeId == 'form' &&
                  info.currentLogin.gmCanAccessForms) ||
                (item.questionnaireTypeId == 'ability' &&
                  info.currentLogin.gmCanReadResults)
            "
            small
            @click="
              $router.push({
                path: `${
                  $route.name == 'adminMonitoring' ? '/' : '/superadmin/'
                }monitoring/${item.assignUserQuestId}`,
              })
            "
            width="60px"
            >report</v-btn
          >
        </template>

        <template v-slot:[`item.filistosTrainingNumber`]="{ item }">
          <v-btn text color="primary" @click="openFilistosTrainingNeeds(item)">
            {{ item.filistosTrainingNumber }}
          </v-btn>
        </template>
      </v-data-table>

      <v-pagination
        class="mt-auto"
        :length="monitoring.pageCount"
        v-model="monitoringParams.page"
        v-if="monitoring.pageCount"
      ></v-pagination>
    </div>
    <v-dialog
      max-width="1250px"
      v-model="filistosTrainingNeedsDialog.open"
      v-if="filistosTrainingNeedsDialog.open"
    >
      <FilistosTrainingNeedsDialog
        v-if="filistosTrainingNeedsDialog.open"
        :questionnaire="filistosTrainingNeedsDialog.questionnaire"
        @closeDialog="closeFilistosTrainingNeeds"
      />
    </v-dialog>

    <v-dialog v-model="confirmDialog" v-if="confirmDialog" max-width="500px">
      <confirm-dialog
        v-if="confirmDialog"
        :selectedAssignments="selectedAssignments"
        @closeDialog="confirmDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ShowPerPage from "@/components/ShowPerPage";
import FilterBy from "@/components/FilterBy";
import FilistosTrainingNeedsDialog from "./FilistosTrainingNeedsDialog.vue";
import ConfirmDialog from "./ConfirmDialog.vue";

export default {
  components: {
    ShowPerPage,
    FilterBy,
    FilistosTrainingNeedsDialog,
    ConfirmDialog,
  },

  data() {
    return {
      confirmDialog: false,
      selectedAssignments: [],
      proposedFilistosTrainings: false,
      filistosTrainingNeedsDialog: {
        open: false,
        questionnaire: null,
      },
      headers: [
        { text: "Client Name", value: "clientName", sortable: false },
        { text: "Full Name", value: "fullName", sortable: false },
        { text: "Position", value: "positionName", sortable: false },
        { text: "Nationality", value: "nationalityName", sortable: false },
        { text: "Date Completed", value: "dateCompleted" },
        {
          text: "Questionnaire Name",
          value: "questionnaireName",
          sortable: false,
        },
        { text: "Red", value: "redCount" },
        { text: "Yellow", value: "yellowCount", sortable: false },
        { text: "Reviewed", value: "isReviewed", sortable: false },
        { text: "Notification Sent", value: "notifSent", sortable: false },
        { text: "", value: "action", sortable: false },
      ],
      loader: false,
      timeout: null,
      isFirstCall: true,
      showPerPageOptions: [],
      reviewedFilters: [
        {
          key: 1,
          viewValue: "All",
          value: "all",
        },
        {
          key: 2,
          viewValue: "Reviewed Only",
          value: true,
        },
        {
          key: 3,
          viewValue: "Not Reviewed Only",
          value: false,
        },
      ],
      notifFilters: [
        {
          key: 1,
          viewValue: "All",
          value: "all",
        },
        {
          key: 2,
          viewValue: "Sent Only",
          value: true,
        },
        {
          key: 3,
          viewValue: "Not Sent Only",
          value: false,
        },
      ],
      reviewedFilter: null,
      notifFilter: null,
    };
  },
  computed: {
    ...mapState({
      monitoringParams: (state) => state.monitoring.monitoringParams,
      monitoring: (state) => state.monitoring.monitoring,
      globalLoader: (state) => state.base.globalLoader,
      allClients: (state) => state.base.allClients,
      countries: (state) => state.base.lists.countries,
      userTypes: (state) => state.base.lists.userTypes,
      questionnaires: (state) => state.base.questionnaires,
      info: (state) => state.base.info,
    }),

    checkGmRoleType() {
      if (this.role == "gm") {
        if (
          this.info.currentLogin.gmCanAccessElearning ||
          this.info.currentLogin.gmCanAccessForms
        ) {
          return "gmCanSeeReport";
        } else if (this.info.currentLogin.gmCanReadResults) {
          return "gmCanSeeEverything";
        } else {
          return "gmCanSeeNothing";
        }
      } else {
        return "adminRights";
      }
    },

    options: {
      get() {
        const { itemsPerPage, page, sortBy, sortDesc } = this.monitoringParams;

        return {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        };
      },
      set(value) {
        let { itemsPerPage, page, sortBy, sortDesc } = value;

        this.setMonitoringParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      },
    },
    filterFields() {
      return [
        {
          key: "dateFrom",
          value: "Date completed from",
          selectedValue: this.monitoringParams.dateFrom
            ? this.dayjs
                .utc(this.monitoringParams.dateFrom)
                .local()
                .toISOString()
                .substr(0, 10)
            : null,
        },
        {
          key: "dateTo",
          value: "Date completed to",
          selectedValue: this.monitoringParams.dateTo
            ? this.dayjs
                .utc(this.monitoringParams.dateTo)
                .local()
                .toISOString()
                .substr(0, 10)
            : null,
        },
        ...[
          this.$route.name != "adminMonitoring" && {
            key: "clientId",
            value: "Client",
            values: this.allClients.map((c) => {
              return {
                itemKey: c.clientId,
                itemValue: c.companyName,
              };
            }),
            selectedValue: this.monitoringParams.clientId,
          },
        ],
        // questionnaireTypeId
        {
          key: "questionnaire",
          value: "Questionnaire",
          values: Array.prototype.concat.apply(
            [],
            this.questionnaires.map((qtype) => {
              return qtype.questionnaires.map((q) => {
                return {
                  itemKey: q,
                  itemValue: q.questionnaireShortName
                    ? q.questionnaireShortName
                    : q.questionnaireName,
                };
              });
            })
          ),
          selectedValue: this.monitoringParams.questionnaire,
        },
        {
          key: "nationalityId",
          value: "Nationality",
          values: this.countries,
          selectedValue: this.monitoringParams.nationalityId,
        },
      ].filter((f) => f);
    },
  },
  async created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setMonitoringParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setMonitoringParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setMonitoringParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setMonitoringParams,
      },
    ];

    this.reviewedFilter = this.reviewedFilters[0];
    this.notifFilter = this.notifFilters[0];

    if (this.$route.name == "adminMonitoring") {
      this.headers = [
        { text: "Full Name", value: "fullName", sortable: false },
        { text: "Position", value: "positionName", sortable: false },
        { text: "Nationality", value: "nationalityName", sortable: false },
        { text: "Date Completed", value: "dateCompleted" },
        {
          text: "Questionnaire Name",
          value: "questionnaireName",
          sortable: false,
        },
        { text: "Red", value: "redCount" },
        { text: "Yellow", value: "yellowCount", sortable: false },
        { text: "", value: "action", sortable: false },
      ];
    }
  },
  methods: {
    ...mapMutations([
      "setMonitoringParams",
      "clearMonitoringState",
      "toggleMonitoringFetched",
    ]),
    ...mapActions(["getMonitoring"]),

    async onSendProposedFilistosTraining() {
      this.confirmDialog = true;
      console.log(this.selectedAssignments);
    },

    openFilistosTrainingNeeds(questionnaire) {
      this.filistosTrainingNeedsDialog = {
        open: true,
        questionnaire,
      };
      console.log(this.filistosTrainingNeedsDialog);
    },

    closeFilistosTrainingNeeds() {
      this.filistosTrainingNeedsDialog = {
        open: false,
        questionnaire: null,
      };
    },

    async onGetMonitoring(refresh) {
      if (this.isFirstCall) {
        this.isFirstCall = false;
        return;
      }

      this.loader = true;

      try {
        await this.getMonitoring(refresh);
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    onFilterChange(e, type) {
      this.setMonitoringParams({
        [e.field]:
          (e.field == "dateFrom" || e.field == "dateTo") && e.value
            ? this.dayjs.utc(e.value).toISOString()
            : e.value,
        hasFilistosTraining: this.proposedFilistosTrainings,
      });

      this.onGetMonitoring(true);

      if (type == "checkbox") {
        if (
          e == true &&
          this.monitoringParams.questionnaire.questionnaireTypeId == "psycho"
        )
          this.headers.push({
            text: "Proposed Filistos Tainings",
            value: "filistosTrainingNumber",
          });
        else if (e == false) {
          this.selectedAssignments = [];
          this.proposedFilistosTrainings = false;
          this.headers.pop();
        }
      }

      if (
        this.monitoringParams.questionnaire &&
        this.monitoringParams.questionnaire.questionnaireTypeId != "psycho"
      ) {
        this.proposedFilistosTrainings = false;
        this.headers.pop();
      }
    },
    onCustomFilterChange(filterType, filter) {
      if (filterType == "reviewed") {
        this.reviewedFilter = filter;
        this.onFilterChange({
          field: "notifSent",
          value: filter.value,
        });
      } else {
        this.notifFilter = filter;
        this.onFilterChange({
          field: "isReviewed",
          value: filter.value,
        });
      }
    },
  },
  watch: {
    "monitoringParams.search"() {
      clearTimeout(this.timeout);

      if (this.loader) return;

      this.timeout = setTimeout(() => {
        this.setMonitoringParams({
          page: 1,
        });

        this.onGetMonitoring(true);
      }, 500);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != "monitoringReport" && to.name != "adminMonitoringReport")
      this.clearMonitoringState();

    this.toggleMonitoringFetched(false);
    next();
  },
};
</script>

<style lang="scss" scoped>
.bullet {
  &.small {
    height: 15px;
    width: 15px;
  }
}

.filter,
.list-filter {
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #eeeeee;
  }
}

.list-filter.active {
  background-color: #eeeeee;
}
</style>
