<template>
  <div class="white dialog-wrapper scrollable-dialog">
    <div class="primary pa-3 d-flex align-start">
      <div class="text-h6 white--text">Merge Users</div>
      <v-btn
        icon
        small
        color="white"
        class="ml-auto"
        @click="$emit('closeDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-form class="d-flex pa-4 align-center justify-start" v-if="step == 1">
      <v-text-field
        v-model="lastName"
        outlined
        hide-details
        dense
        label="Difference of Last Name"
        type="number"
        style="max-width: 200px;"
        class="mr-4"
      ></v-text-field>

      <v-text-field
        v-model="firstName"
        outlined
        hide-details
        dense
        label="Difference of First Name"
        type="number"
        style="max-width: 200px;"
        class="mr-4"
      ></v-text-field>

      <v-text-field
        v-model="email"
        outlined
        hide-details
        dense
        label="Difference of Email"
        type="number"
        style="max-width: 200px;"
        class="mr-auto"
      ></v-text-field>

      <v-btn @click="onSearch" depressed color="filyellow" class="white--text"
        >Search</v-btn
      >
    </v-form>

    <v-progress-circular
      v-if="pageLoader"
      size="80"
      width="4"
      indeterminate
      color="filblue"
      class="align-self-center mt-12"
    ></v-progress-circular>

    <v-expansion-panels class="pa-4" v-if="!pageLoader && step == 1">
      <v-expansion-panel v-for="(item, i) in users" :key="i">
        <v-expansion-panel-header> Group {{ i + 1 }} </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            item-key="clientUserId"
            hide-default-footer
            :headers="headers"
            show-select
            checkbox-color="primary"
            :items="item"
            v-model="selectedUsers[i]"
            :item-class="returnRowStyle"
          >
            <template v-slot:[`header.data-table-select`]> </template>

            <template v-slot:[`item.dateOfBirth`]="{ item }">
              {{
                item.dateOfBirth
                  ? dayjs(item.dateOfBirth).format("DD/MM/YYYY")
                  : "-"
              }}
            </template>

            <template v-slot:[`item.userEmail`]="{ item }">
              {{ item.userEmail ? item.userEmail : "-" }}
            </template>
          </v-data-table>
          <div style="width: 100%" class="d-flex align-end">
            <v-btn
              color="primary"
              class="ml-auto mt-4"
              @click="onPreviewMerge(i)"
              :loading="previewMergeLoader"
              :disabled="selectedUsers[i].length < 2"
            >
              Preview Merge
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div v-if="step == 2" style="width: 100%;" class="d-flex flex-column ">
      <div class="d-flex align-center">
        <v-btn @click="step = 1" icon class="ml-2">
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <div class="body-1 font-weight-medium">Preview Merge</div>
      </div>
      <v-tabs v-model="tab" align-with-title show-arrows class="pa-4">
        <v-tab>
          Personal Details
        </v-tab>

        <v-tab> Contact Details </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" id="single-user-tabs">
        <v-tab-item class="pt-1">
          <v-row v-if="selectedUser" style="width: 100%;" class="pa-4">
            <v-col cols="12" sm="6">
              <v-text-field
                v-if="selectedUser && selectedUser.identityNumber"
                dense
                outlined
                label="Identity*"
                class="mb-1"
                :value="selectedUser.identityNumber"
                readonly
              ></v-text-field>

              <v-text-field
                dense
                outlined
                label="First Name*"
                class="mb-1"
                :value="selectedUser.firstName"
                readonly
              ></v-text-field>

              <v-text-field
                dense
                outlined
                label="Last Name*"
                class="mb-1"
                :value="selectedUser.lastName"
                readonly
              ></v-text-field>

              <v-text-field
                :disabled="
                  Boolean(selectedUser) &&
                    selectedUser.platformAccessId == platformAccess
                "
                dense
                outlined
                label="Username*"
                class="mb-1"
                :value="selectedUser.username"
                v-if="platformAccess != 'basic'"
                readonly
              ></v-text-field>

              <v-text-field
                dense
                outlined
                :label="
                  `Email ${
                    platformAccess && platformAccess != 'basic' ? '*' : ''
                  }`
                "
                type="email"
                :value="selectedUser.userEmail"
                readonly
              ></v-text-field>

              <v-radio-group
                v-model="selectedUser.userTypeId"
                row
                class="mt-0"
                readonly
              >
                <v-radio
                  v-for="type in userTypes"
                  :key="type.itemKey"
                  :value="type.itemKey"
                  :label="type.itemValue"
                  :ripple="false"
                ></v-radio>
              </v-radio-group>

              <v-select
                dense
                outlined
                label="Position*"
                :items="positions"
                item-value="itemKey"
                item-text="itemValue"
                :value="selectedUser.positionId"
                readonly
              ></v-select>

              <v-select
                dense
                outlined
                label="Nationality*"
                :items="countries"
                item-value="itemKey"
                item-text="itemValue"
                class="mb-1"
                :value="selectedUser.nationalityId"
                readonly
              ></v-select>

              <v-select
                dense
                outlined
                label="Language*"
                :items="languages"
                item-value="itemKey"
                item-text="itemValue"
                class="mb-1"
                :value="selectedUser.languageId"
                readonly
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                :value="formattedDate"
                dense
                prepend-inner-icon="mdi-calendar"
                outlined
                label="Birthdate"
                class="mb-1"
                readonly
                :hint="
                  selectedUser.dateOfBirth
                    ? `Age: ${new Date().getFullYear() -
                        dayjs(selectedUser.dateOfBirth).year()}`
                    : ''
                "
                persistent-hint
              ></v-text-field>

              <v-select
                dense
                outlined
                label="Gender"
                :items="genders"
                item-value="itemKey"
                item-text="itemValue"
                class="mb-1"
                :value="selectedUser.gender"
                readonly
              ></v-select>

              <v-select
                dense
                outlined
                label="Status"
                :items="maritalStatuses"
                item-value="itemKey"
                item-text="itemValue"
                class="mb-1"
                :value="selectedUser.marital"
                readonly
              ></v-select>

              <v-text-field
                dense
                outlined
                label="Kids"
                type="number"
                :value="selectedUser.kids"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item class="pt-1">
          <v-row v-if="selectedUser" style="width: 100%;" class="pa-4">
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                outlined
                hide-details
                label="Street"
                class="mb-4"
                :value="selectedUser.addressStreet"
                readonly
              ></v-text-field>

              <v-text-field
                dense
                outlined
                hide-details
                label="Number"
                type="number"
                class="mb-4"
                :value="selectedUser.addressStreetNumber"
                readonly
              ></v-text-field>

              <v-text-field
                dense
                outlined
                hide-details
                label="City"
                class="mb-4"
                :value="selectedUser.addressCity"
                readonly
              ></v-text-field>

              <v-text-field
                dense
                outlined
                hide-details
                label="Region"
                class="mb-4"
                :value="selectedUser.addressRegion"
                readonly
              ></v-text-field>

              <v-select
                dense
                outlined
                hide-details
                label="Country"
                item-text="itemValue"
                item-value="itemKey"
                class="mb-4"
                :items="countries"
                :value="selectedUser.addressCountryId"
                readonly
              ></v-select>

              <v-text-field
                dense
                outlined
                hide-details
                label="Zip Code"
                :value="selectedUser.addressZIP"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                dense
                outlined
                hide-details
                label="Mobile"
                class="mb-4"
                :value="selectedUser.mobile"
                readonly
              ></v-text-field>

              <v-text-field
                dense
                outlined
                hide-details
                label="Phone"
                class="mb-4"
                :value="selectedUser.phone"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div
      v-if="step == 2"
      style="width: 100%;"
      class="d-flex flex-grow-1 flex-shrink-0 pb-4"
    >
      <v-btn
        color="primary"
        class="ml-auto mt-auto mr-4 "
        @click="onMerge()"
        :loading="mergeLoader"
      >
        Merge
      </v-btn>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations } from "vuex";
import PersonalDetails from "@/views/admin/users/user-dialog/PersonalDetails";

export default {
  components: {
    PersonalDetails,
  },

  data() {
    return {
      pageLoader: false,
      previewMergeLoader: false,
      lastName: 1,
      firstName: 10,
      email: 10,
      selectedUsers: [],
      finalUser: [],
      users: [],
      step: 1,
      tab: 0,
      platformAccess: "basic",
      headers: [
        // { text: "Creation Date", value: "creationDate" },
        { text: "Identity", value: "identityNumber" },
        { text: "Last Name", value: "lastName" },
        { text: "First Name", value: "firstName" },
        { text: "Birth Date", value: "dateOfBirth" },
        { text: "Email", value: "userEmail" },
        { text: "Sent Assignments", value: "numAssignments" },
        { text: "Finalized Assignments", value: "numFinalizedAssignments" },
      ],
      mainIndexes: [],
      previewUser: null,
      finalPayload: null,
      mergeLoader: false,
      selectedUser: null,
    };
  },

  computed: {
    ...mapState({
      languages: (state) => state.base.lists.languages,
      countries: (state) => state.base.lists.countries,
      maritalStatuses: (state) => state.base.lists.maritalStatuses,
      genders: (state) => state.base.lists.genders,
      allGroups: (state) => state.base.allGroups,
      userTypes: (state) => state.base.lists.userTypes,
      role: (state) => state.auth.role,
    }),

    positions() {
      if (!this.selectedUser.userTypeId) return [];

      return this.$store.state.base.lists[this.selectedUser.userTypeId];
    },

    formattedDate() {
      return this.selectedUser.birthDate
        ? this.dayjs(this.selectedUser.birthDate).format("DD/MM/YYYY")
        : "";
    },
  },

  methods: {
    ...mapMutations(["toggleSnackbar"]),

    returnRowStyle(item) {
      if (item.isMain) return "row-style";
      else return "";
    },
    async onSearch() {
      this.pageLoader = true;
      this.selectedUsers = [];

      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/clientadmin/GetPossibleMultipleUsers`,
          {
            params: {
              lastNameDistance: this.lastName,
              firstNameDistance: this.firstName,
              emailDistance: this.email,
            },
          }
        );

        this.users = res.data.clientUserToMergeGroups.map((arr) => {
          const temp = arr.map((obj) => {
            return { ...obj, isMain: false };
          });
          return temp;
        });

        this.users.forEach(() => {
          this.selectedUsers.push([]);
          this.mainIndexes.push(null);
        });
        this.step = 1;
      } catch (e) {
        console.log(e);
      }
      this.pageLoader = false;
    },

    async onPreviewMerge(i) {
      this.previewMergeLoader = true;

      let payload = {
        MainUserId: null,
        UserToMergeIds: [],
      };
      this.selectedUsers[i].forEach((el) => {
        if (el.isMain) payload.MainUserId = el.clientUserId;
        else payload.UserToMergeIds.push(el.clientUserId);
      });

      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/clientadmin/PreviewMergeUsersResult`,
          payload
        );

        this.step = 2;
        this.previewUser = res.data;
        const user = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/GetUser/${payload.MainUserId}`
        );
        this.selectedUser = user.data;
        this.finalPayload = { ...payload };
        console.log(this.selectedUser);
      } catch (e) {
        console.log(e);
      }
      this.previewMergeLoader = false;
    },

    async onMerge() {
      this.mergeLoader = true;

      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/clientadmin/MergeUsers`,
          this.finalPayload
        );

        this.toggleSnackbar({
          open: true,
          color: "success",
          text: "Merge has been completed!",
          timeout: 5000,
        });
        this.$emit("closeDialog", this.finalPayload.MainUserId);
      } catch (e) {
        this.toggleSnackbar({
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });
      }
      this.mergeLoader = false;
    },
  },

  watch: {
    selectedUsers(newValue) {
      newValue.forEach((arr, i) => {
        if (arr.length == 1) {
          let found = false;
          this.users[i].forEach((el) => {
            if (el.isMain) {
              let found = false;

              this.selectedUsers[i].find((user) => {
                if (el.clientUserId == user.clientUserId) found = true;
              });

              if (!found) this.selectedUsers[i] = [];
            }
          });

          if (!found)
            this.users[i] = [
              ...this.users[i].map((el, j) => {
                if (el.clientUserId == arr[0].clientUserId) {
                  el.isMain = true;
                  this.mainIndexes[i] = j;
                }
                return el;
              }),
            ];
        } else if (arr.length == 0) {
          this.mainIndexes.forEach((el, index) => {
            if (el != null && i == index) {
              this.users[i][el].isMain = false;

              el = null;
            }
          });
        } else if (arr.length > 1) {
          this.users[i].forEach((el) => {
            if (el.isMain) {
              let found = false;

              this.selectedUsers[i].find((user) => {
                if (el.clientUserId == user.clientUserId) found = true;
              });

              if (!found) this.selectedUsers[i] = [];
            }
          });
        }
      });

      // do something with newValue and oldValue.
    },
    deep: true,
  },
};
</script>

<style>
.row-style {
  /* background-color: rgb(50, 118, 177, 0.3) !important; */
  font-weight: bold;
}
</style>
