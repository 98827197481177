<template>
  <div class="component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column">
    <div class="d-flex align-center mb-5">
      <v-icon class="mr-2">mdi-account-multiple</v-icon>

      <div class="body-1 font-weight-medium">Groups</div>
    </div>

    <div class="wrapper d-flex flex-column rounded mb-5">
      <div class="pa-5 d-flex flex-column flex-md-row align-center">
        <v-alert
          color="primary"
          text
          border="left"
          prominent
          :width="
            $vuetify.breakpoint.mdAndUp
              ? '350px'
              : $vuetify.breakpoint.smOnly
              ? '350px'
              : '100%'
          "
          class="pa-5"
        >
          <v-select
            outlined
            dense
            hide-details
            label="Select Group Type"
            :items="groupTypes"
            v-model="selectedType"
            item-value="itemKey"
            item-text="itemValue"
            :readonly="groupsLoader"
            clearable
          >
          </v-select>
        </v-alert>

        <v-btn
          color="warning"
          class="mx-auto mx-md-0 ml-md-auto"
          depressed
          @click="dialog = { open: true, component: 'GroupDialog' }"
        >
          <v-icon left>mdi-plus</v-icon>
          Add Group
        </v-btn>
      </div>
    </div>

    <v-dialog
      v-model="dialog.open"
      max-width="800px"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <component
        :is="dialog.component"
        @closeDialog="dialog = { open: false, component: null }"
      ></component>
    </v-dialog>

    <GroupsList :selectedType="selectedType" />
  </div>
</template>

<script>
import GroupsList from "@/views/admin/groups/GroupsList";
import GroupDialog from "@/views/admin/groups/group-dialog/GroupDialog";

import { mapState } from "vuex";

export default {
  components: {
    GroupsList,
    GroupDialog,
  },
  data() {
    return {
      selectedType: null,
      dialog: {
        open: false,
        component: null,
      },
    };
  },
  computed: {
    ...mapState({
      groupTypes: (state) => state.base.lists.groupTypes,
      groupsLoader: (state) => state.groups.groupsLoader,
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
