<template>
  <div
    class="wrapper d-flex flex-column rounded flex-grow-1 d-flex flex-column"
    id="dashboard-nationality"
  >
    <div class="section-header d-flex align-center pa-1 rounded-tl rounded-tr">
      <v-icon class="mr-2">mdi-map-marker</v-icon>

      <div class="body-2">User Nationality</div>
    </div>

    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loader"
    ></v-progress-linear>

    <div class="pa-2 align-self-start" v-if="isSuperadmin">
      <v-select
        label="Client"
        outlined
        dense
        hide-details
        :items="allClients"
        item-value="clientId"
        item-text="companyName"
        v-model="client"
        @change="onGetUsersPerNationality"
        clearable
      ></v-select>
    </div>

    <v-tabs grow color="primary" v-model="tab" class="flex-grow-0">
      <v-tab> Map </v-tab>

      <v-tab> List </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items v-model="tab">
      <v-tab-item class="pa-2" eager>
        <MapChart
          :countryData="countryData"
          highColor="#3276b1"
          lowColor="#3276b1"
          countryStrokeColor="#909090"
          defaultCountryFillColor="#c3c3c3"
          :showEmptyValue="false"
          :currencyAdd="false"
        />
      </v-tab-item>

      <v-tab-item>
        <div class="d-flex flex-column list-wrapper">
          <div class="scrollable">
            <v-data-table
              :headers="headers"
              :items="usersPerNationality"
              hide-default-footer
              :items-per-page="-1"
            >
              <template v-slot:[`item.natPercentage`]="{ item }">
                {{ item.natPercentage.toFixed(1) }}
              </template>
            </v-data-table>
            <!-- <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Country</th>

                    <th class="text-left">Users</th>

                    <th class="text-left">Percentage</th>

                    <th class="text-left">Active</th>

                    <th class="text-left">Inactive</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="country in usersPerNationality"
                    :key="country.natKey"
                  >
                    <td>{{ country.natName }}</td>

                    <td>{{ country.natount }}</td>

                    <td>{{ parseFloat(country.natPercentage.toFixed(1)) }}%</td>

                    <td>{{ country.activeCount }}</td>

                    <td>{{ country.inactiveCount }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table> -->
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import MapChart from "vue-chart-map";
import { mapActions, mapState } from "vuex";

export default {
  props: ["isSuperadmin"],
  components: {
    MapChart,
  },
  data() {
    return {
      tab: 0,
      countryData: {},
      client: null,
      loader: false,

      headers: [
        { text: "Country", value: "natName", sortable: true },
        { text: "Users", value: "natount", sortable: true },
        { text: "Percentage", value: "natPercentage", sortable: false },
        { text: "Active", value: "activeCount", sortable: true },
        { text: "Inactive", value: "inactiveCount", sortable: true },
      ],
    };
  },
  computed: {
    ...mapState({
      allClients: (state) => state.base.allClients,
    }),
    usersPerNationality() {
      return this.$store.state[
        this.isSuperadmin ? "superAdminDashboard" : "dashboard"
      ].usersPerNationality;
    },
  },
  created() {
    this.countryData = this.usersPerNationality.reduce(
      (a, v) => ({ ...a, [v.natKey]: v.natount }),
      {}
    );
  },
  methods: {
    ...mapActions(["getSuperAdminUsersPerNationality"]),
    async onGetUsersPerNationality() {
      this.loader = true;

      try {
        await this.getSuperAdminUsersPerNationality({
          clientId: this.client,
        });

        this.countryData = this.usersPerNationality.reduce(
          (a, v) => ({ ...a, [v.natKey]: v.natount }),
          {}
        );
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 600px;
}

.vue-world-map {
  height: 402px; //this is calculated somehow.
}

.list-wrapper {
  height: 100%;
}
</style>
