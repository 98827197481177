var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper rounded flex-grow-1 d-flex flex-column pa-4"},[(_vm.currentDepartment.workPositions.length > 0)?_c('v-row',{staticClass:"px-4 py-4"},_vm._l((_vm.currentDepartment.workPositions),function(rank,i){return _c('v-col',{key:i,staticClass:"d-flex flex-column",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-data-table',{staticClass:"elevation-1 mb-6",attrs:{"disable-select-all":true,"headers":_vm.headers,"items":rank.availablePersons,"hide-default-header":"","hide-default-footer":"","show-select":"","item-key":"clientUserId","checkbox-color":"primary","search":_vm.search[i],"items-per-page":500,"height":"350px"},on:{"click:row":function($event){_vm.dialog = {
            open: true,
            component: 'UserDialog',
            user: $event.clientUserId,
          }}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search","hint":"Search by Identity, Last Name, First Name","persistent-hint":""},model:{value:(_vm.search[i]),callback:function ($$v) {_vm.$set(_vm.search, i, $$v)},expression:"search[i]"}}),_c('div',{staticClass:"d-flex align-center justify-cente pr-2"},[_c('span',{staticClass:"mr-auto ml-4 body-2 font-weight-medium"},[_vm._v(" "+_vm._s(rank.positionName))]),_c('v-text-field',{staticClass:"mt-2 mb-2 ml-auto",style:('width:60px;max-width:60px'),attrs:{"outlined":"","dense":"","hide-details":"","type":"number","min":"0"},model:{value:(_vm.required[i]),callback:function ($$v) {_vm.$set(_vm.required, i, $$v)},expression:"required[i]"}})],1)]},proxy:true},{key:"header",fn:function(){return undefined},proxy:true},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-start align-start",style:('width:70px')},[_c('span',[_vm._v(_vm._s(item.lastName))]),_c('span',[_vm._v(_vm._s("[" + item.identityNumber + "]"))])])]}},{key:"item.greenCount",fn:function(ref){
          var item = ref.item;
return [_c('div',{style:('width:5px')},[_c('span',{staticClass:"filgreen--text"},[_vm._v(" "+_vm._s(item.greenCount)+" ")])])]}},{key:"item.yellowCount",fn:function(ref){
          var item = ref.item;
return [_c('div',{style:('width:5px')},[_c('span',{staticClass:"filyellow--text"},[_vm._v(" "+_vm._s(item.yellowCount)+" ")])])]}},{key:"item.redCount",fn:function(ref){
          var item = ref.item;
return [_c('div',{style:('width:5px')},[_c('span',{staticClass:"filred--text"},[_vm._v(" "+_vm._s(item.redCount)+" ")])])]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('div',{staticClass:"d-flex flex-grow-1 align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center justify-space-between px-4 py-2"},[_c('div',{staticClass:"body-2 font-weight-medium"},[_vm._v(" Selected: "),(_vm.selected[i].length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.selected[i].length)+" ")]):_vm._e()])]),_c('div',{staticClass:"d-flex align-center justify-space-between px-4 mb-2"},[_c('div',{staticClass:"body-2 font-weight-medium"},[_vm._v(" Combinations: "),(
                      _vm.required[i] > 0 &&
                      _vm.selected[i].length > 0 &&
                      _vm.selected[i].length >= _vm.required[i]
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.Factorial(_vm.selected[i].length) / (_vm.Factorial(_vm.required[i]) * _vm.Factorial(_vm.selected[i].length - _vm.required[i])))+" ")]):_vm._e()])])]),_c('v-btn',{staticClass:"white--text ml-auto mr-2",attrs:{"width":"50px","depressed":"","color":"primary","disabled":_vm.selected[i].length == 0},on:{"click":function($event){return _vm.clearSelected(i)}}},[_vm._v("Clear")])],1)]},proxy:true}],null,true),model:{value:(_vm.selected[i]),callback:function ($$v) {_vm.$set(_vm.selected, i, $$v)},expression:"selected[i]"}})],1)}),1):_c('span',{staticClass:"mb-auto"},[_vm._v(" No data available ")]),(_vm.currentDepartment.workPositions.length > 0)?_c('div',{staticClass:"d-flex flex-column align-end"},[_c('div',{staticClass:"body-1 font-weight-medium px-2 mb-2"},[_vm._v(" "+_vm._s(_vm.currentDepartment.departmentName + " Combinations: " + _vm.departmentCombinations)+" ")]),(_vm.warningText)?_c('div',{staticClass:"body-1 font-weight-medium px-2 mb-2 warning--text d-flex align-center"},[_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"warning"}},[_vm._v("mdi-alert")])],1),_vm._v(" Be patient. This might take some time! ")]):_vm._e(),_c('v-btn',{staticClass:"white--text ml-2",attrs:{"width":"150px","depressed":"","color":"filyellow","disabled":_vm.combinationsValidator,"loading":_vm.combinationsLoader},on:{"click":_vm.getCombinations}},[_vm._v("Get Combinations")])],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","fullscreen":_vm.$vuetify.breakpoint.xsOnly && _vm.dialog.component != 'delete'},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[_c(_vm.dialog.component,{tag:"component",attrs:{"selectedUserId":_vm.dialog.user,"isEdit":false},on:{"closeDialog":_vm.onDialogClosed}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }