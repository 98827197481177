import axios from "axios";
import dayjs from "dayjs";

export default {
  state: {
    monitoringParams: {
      hasFilistosTraining: false,
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: [],
      search: "",
      clientId: null,
      questionnaireId: null,
      dateFrom: dayjs()
        .subtract(1, "month")
        .toISOString(),
      dateTo: null,
      showSent: null,
      nationalityId: null,
      isReviewed: null,
      notifSent: null,
    },
    monitoring: {
      results: [],
      rowCount: 0,
    },
    monitoringFetched: false,
  },
  mutations: {
    setMonitoringParams(state, params) {
      state.monitoringParams = {
        ...state.monitoringParams,
        ...params,
      };
    },
    getMonitoring(state, monitoring) {
      state.monitoring = monitoring;
    },
    toggleMonitoringFetched(state, fetched) {
      state.monitoringFetched = fetched;
    },
    clearMonitoringState(state) {
      state.monitoringParams = {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
        search: "",
        clientId: null,
        questionnaireId: null,
        dateFrom: dayjs()
          .subtract(1, "month")
          .toISOString(),
        dateTo: null,
        showSent: null,
        nationalityId: null,
        isReviewed: null,
        notifSent: null,
        hasFilistosTraining: false,
      };
      state.monitoring = {
        results: [],
        rowCount: 0,
      };
      state.monitoringFetched = false;
      state.currentMonitoring = null;
    },
  },
  actions: {
    async getMonitoring({ commit, state, rootState }, refresh) {
      if (state.monitoringFetched && !refresh) return;

      try {
        const role = rootState.auth.role;
        const roleControllers = {
          co: "ClientAdmin",
          gm: "GroupManager",
          filistos: "SuperAdmin",
        };

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${roleControllers[role]}/GetMonitoring`,
          {
            params: {
              pageSize:
                state.monitoringParams.itemsPerPage != -1
                  ? state.monitoringParams.itemsPerPage
                  : 1000,
              pageNo: state.monitoringParams.page,
              ...(state.monitoringParams.sortBy[0] && {
                sortBy: state.monitoringParams.sortBy[0],
              }),
              ...(state.monitoringParams.sortDesc[0] && { dir: "desc" }),
              ...(state.monitoringParams.search && {
                search: state.monitoringParams.search,
              }),
              ...(state.monitoringParams.clientId && {
                clientId: state.monitoringParams.clientId,
              }),
              ...(state.monitoringParams.questionnaire && {
                questionnaireId:
                  state.monitoringParams?.questionnaire?.questionnaireId,
              }),
              ...(state.monitoringParams.dateFrom && {
                dateFrom: state.monitoringParams.dateFrom,
              }),
              ...(state.monitoringParams.dateTo && {
                dateTo: state.monitoringParams.dateTo,
              }),
              ...(state.monitoringParams.showSent && {
                showSent: state.monitoringParams.showSent,
              }),
              ...(state.monitoringParams.nationalityId && {
                nationalityId: state.monitoringParams.nationalityId,
              }),
              ...(state.monitoringParams.isReviewed != "all" && {
                showNotifSent: state.monitoringParams.isReviewed,
              }),
              ...(state.monitoringParams.notifSent != "all" && {
                showReviewed: state.monitoringParams.notifSent,
              }),
              ...(state.monitoringParams.hasFilistosTraining && {
                withProposedFilistosTrainings:
                  state.monitoringParams.hasFilistosTraining,
              }),
            },
          }
        );

        commit("getMonitoring", res.data);
        commit("toggleMonitoringFetched", true);
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async monitorReview({ commit }, reportId) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/SuperAdmin/MonitorReview`,
          {
            assignUserQuestIds: [reportId],
          }
        );

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "'Reviewed' state changed!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async getUsersToNotify({ commit }, reportId) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetClientUsersToNotifyForResult/${reportId}`
        );
        return res.data.results;
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async monitorNotify({ commit }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/SuperAdmin/MonitorNotify`,
          payload
        );

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Notification has been sent!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
  },
};
