<template>
  <div class="component-wrapper px-5 pb-0 d-flex flex-column">
    <div class="subtitle-1 font-weight-medium">
      <div class="text-h6 font-weight-medium" v-if="currentFactor">
        {{ currentFactor.factorName }}
        <span v-if="type == 'questionnaireChildFactor' && currentChildFactor">
          / {{ currentChildFactor.factorName }}
        </span>
      </div>
    </div>

    <v-alert
      text
      dense
      color="warning"
      class="mt-4"
      v-if="
        factor && !factor.subFactors.length && !factor.relatedQuestions.length
      "
    >
      This factor has not subfactors or related questions.
    </v-alert>

    <v-divider class="my-6"></v-divider>

    <v-tabs vertical v-model="tab">
      <v-tab> Factor Data </v-tab>

      <v-tab v-if="factor && factor.flags && factor.flags.length">
        Flags
      </v-tab>

      <v-tab v-if="type != 'questionnaireChildFactor'"> Subfactors </v-tab>

      <v-tab-item>
        <div class="pl-12">
          <FactorData :factor="factor" />
        </div>
      </v-tab-item>

      <v-tab-item
        class="pl-12"
        v-if="factor && factor.flags && factor.flags.length"
      >
        <FactorTexts :factor="factor" />
      </v-tab-item>

      <v-tab-item class="pl-12" v-if="type != 'questionnaireChildFactor'">
        <Subfactors :factor="factor" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import FactorData from "@/views/superadmin/questionnaires/questionnaire/factors/FactorData";
import FactorTexts from "@/views/superadmin/questionnaires/questionnaire/factors/FactorTexts";
import Subfactors from "@/views/superadmin/questionnaires/questionnaire/factors/Subfactors";

export default {
  components: {
    FactorData,
    FactorTexts,
    Subfactors,
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    ...mapState({
      currentFactor: (state) => state.questionnaires.currentFactor,
      currentChildFactor: (state) => state.questionnaires.currentChildFactor,
    }),
    type() {
      return this.$route.name;
    },
    factor() {
      return this.type == "questionnaireChildFactor"
        ? this.currentChildFactor
        : this.currentFactor;
    },
  },
  methods: {
    ...mapMutations(["toggleDiscardDialog", "getFactor", "getChildFactor"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.tab = 0;
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    if (from.name == "questionnaireChildFactor") {
      this.getChildFactor(null);
    } else {
      if (
        to.name != "questionnaireChildFactor" &&
        to.name != "questionnaireFactor"
      ) {
        this.getFactor(null);
      }
    }

    next();
  },
};
</script>

<style lang="scss" scoped>
.tab-content {
  width: 400px;
}
</style>
