<template>
  <div>
    <v-row>
      <v-col
        cols="3"
        v-for="(section, index) in report.gradedSections"
        :key="`${index} - ${section.name}`"
      >
        <div
          class="grey lighten-3 pa-2 rounded d-flex flex-column align-center"
        >
          <div class="subtitle-2">{{ section.name }}</div>

          <v-progress-circular
            :value="parseFloat(section.correctPercentage.toFixed(2))"
            color="filyellow"
            class="my-4"
            size="70"
            width="20"
            rotate="-90"
          ></v-progress-circular>

          <div class="subtitle-2">Score: {{ section.correctAnswerCount }}</div>
        </div>
      </v-col>

      <v-col cols="3">
        <div
          class="grey lighten-3 pa-2 rounded d-flex flex-column align-center"
        >
          <div class="subtitle-2">{{ report.totalGraded.name }}</div>

          <v-progress-circular
            :value="parseFloat(report.totalGraded.correctPercentage.toFixed(2))"
            color="filyellow"
            class="my-4"
            size="70"
            width="20"
            rotate="-90"
          ></v-progress-circular>

          <div class="subtitle-2">
            Score: {{ report.totalGraded.correctAnswerCount }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      report: (state) => state.assignments.report,
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
