import { render, staticRenderFns } from "./SelfEvaluations.vue?vue&type=template&id=ed7e73d6&scoped=true&"
import script from "./SelfEvaluations.vue?vue&type=script&lang=js&"
export * from "./SelfEvaluations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed7e73d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDataTable,VIcon,VPagination,VTextField})
