import store from '@/store';

import SelfEvaluations from '../views/psysup/SelfEvaluations.vue';
import SelfEvaluation from '../views/psysup/SelfEvaluation.vue';
import Cases from '../views/psysup/Cases.vue';
import Case from '../views/psysup/Case.vue';
import ComparisonPossibleToCompare from '../views/admin/comparison/ComparisonPossibleToCompare.vue';
import ComparisonResults from '../views/admin/comparison/ComparisonResults.vue';

export default [
  {
    path: '/psysupport/self-evaluations',
    component: SelfEvaluations,
    meta: { requiresAuth: true, role: 'psysupport' },
    name: 'psysupSelfEvaluations',
  },
  {
    path: '/psysupport/self-evaluations/:examinationId',
    component: SelfEvaluation,
    meta: { requiresAuth: true, role: 'psysupport' },
    name: 'psysupSelfEvaluation',
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('getSelfEval', to.params.examinationId);

        if (
          store.state.psysupport.currentSelfEval?.examinationId ==
          to.params.examinationId
        ) {
          next();
        } else {
          next(`/`);
        }
      } catch (e) {
        next('/');
      }
    },
  },

  {
    path: '/psysupport/cases',
    component: Cases,
    meta: { requiresAuth: true, role: 'psysupport' },
    name: 'psysupCassses',
  },
  {
    path: '/psysupport/cases/:examinationId',
    component: Case,
    meta: { requiresAuth: true, role: 'psysupport' },
    name: 'psysupCase',
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('getSelfEval', to.params.examinationId);

        if (
          store.state.psysupport.currentSelfEval?.examinationId ==
          to.params.examinationId
        ) {
          next();
        } else {
          next(`/`);
        }
      } catch (e) {
        next('/');
      }
    },
  },
  {
    path: '/psysupport/comparison',
    component: ComparisonPossibleToCompare,
    name: 'psysupComparisonPossible',
    meta: { requiresAuth: true, role: 'psysupport' },
  },
  {
    path: '/psysupport/comparison/results',
    component: ComparisonResults,
    name: 'psysupComparisonResults',
    meta: { requiresAuth: true, role: 'psysupport' },
    beforeEnter: async (to, from, next) => {
      try {
        if (store.state.comparison.userQuestsToCompare.length) {
          next();
        } else {
          next('/psysupport/comparison');
        }
      } catch (e) {
        console.log(e);
        next('/');
      }
    },
  },
];
