<template>
  <div class="white dialog-wrapper scrollable-dialog">
    <div class="primary pa-4 d-flex align-start">
      <div class="text-h6 white--text">
        {{
          isEdit && assignment
            ? assignment.assignmentName
            : "Add new Assignment"
        }}
      </div>

      <v-btn
        icon
        small
        color="white"
        class="ml-auto"
        @click="$emit('closeDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-progress-circular
      size="80"
      width="4"
      indeterminate
      color="primary"
      v-if="loader"
      class="ma-auto"
    ></v-progress-circular>

    <template v-else>
      <div
        class="pa-4 pb-0"
        v-if="preselectedUsersBanner || preselectedQuestionnairesBanner"
      >
        <v-alert border="top" color="success" class="mb-0" dense dark>
          {{ form.assigneeUserIds.length }} user{{
            form.assigneeUserIds.length == 1 ? "" : "s"
          }}

          {{
            preselectedQuestionnairesBanner
              ? `and ${form.questionnaireIds.length} questionnaire${
                  form.questionnaireIds.length == 1 ? "" : "s"
                } `
              : ""
          }}

          {{
            form.assigneeUserIds.length == 1 && !form.questionnaireIds.length
              ? "has"
              : "have"
          }}

          been preselected
        </v-alert>
      </div>

      <v-slide-group
        mandatory
        show-arrows
        v-model="tab"
        class="px-4 pt-4"
        v-if="isEdit && !showReport.open"
      >
        <v-slide-item v-slot="{ active, toggle }" value="AssignmentStatus">
          <v-btn
            @click="toggle"
            depressed
            :color="active ? 'filyellow' : ''"
            :class="{
              'white--text': active,
            }"
            class="mr-4"
            small
            >Assignment Status</v-btn
          >
        </v-slide-item>

        <v-slide-item v-slot="{ active, toggle }" value="AssignmentData">
          <v-btn
            @click="toggle"
            depressed
            :color="active ? 'filyellow' : ''"
            :class="{
              'white--text': active,
            }"
            small
            >Edit Assignment
          </v-btn>
        </v-slide-item>

        <div
          class="ml-auto mb-1 d-flex align-center"
          v-if="role == 'co' || role == 'gm'"
        >
          <div class="body-2 mr-2">Created By: {{ assignment.createdBy }}</div>
          <!-- <div class="caption px-1 rounded grey primary white--text px-2">
            {{ assignment.createdBy }}
          </div> -->
        </div>
      </v-slide-group>

      <component
        :is="activeComponent"
        :isEdit="isEdit"
        @closeDialog="$emit('closeDialog', $event)"
        @assignmentUpdated="
          tab = 'AssignmentStatus';
          onGetAssignment($event);
        "
      />
    </template>
  </div>
</template>

<script>
import AssignmentData from "@/views/admin/assignment/assignment-dialog/AssignmentData";
import AssignmentStatus from "@/views/admin/assignment/assignment-dialog/AssignmentStatus";
import AssignmentReport from "@/views/admin/assignment/assignment-dialog/report/AssignmentReport";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: ["isEdit", "assignment", "preselectedUsers"],
  components: {
    AssignmentData,
    AssignmentStatus,
    AssignmentReport,
  },
  data() {
    return {
      tab: "AssignmentStatus",
      loader: false,
    };
  },
  computed: {
    ...mapState({
      showReport: (state) => state.assignments.showReport,
      form: (state) => state.assignments.form,
      preselectedUsersBanner: (state) =>
        state.assignments.preselectedUsersBanner,
      preselectedQuestionnairesBanner: (state) =>
        state.assignments.preselectedQuestionnairesBanner,
      role: (state) => state.auth.role,
    }),
    activeComponent() {
      if (!this.isEdit) return "AssignmentData";

      if (this.showReport.open) return "AssignmentReport";

      return this.tab;
    },
  },
  created() {
    if (this.isEdit) {
      this.onGetAssignment(true);
    }
  },
  methods: {
    ...mapMutations(["clearAssignmentDialogState"]),
    ...mapActions(["getAssignment"]),
    async onGetAssignment(fetch) {
      if (!fetch) return;

      this.loader = true;

      try {
        await this.getAssignment(this.assignment.assignmentId);
      } catch (e) {
        this.$emit("closeDialog");
        console.log(e);
      }

      this.loader = false;
    },
  },
  destroyed() {
    this.clearAssignmentDialogState();
  },
};
</script>

<style lang="scss" scoped>
</style>
