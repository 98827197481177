import store from "@/store";

// ====== DASHBOARD ======
import Dashboard from "../views/superadmin/dashboard/Dashboard.vue";

// ====== CLIENTS ======
import Clients from "../views/superadmin/clients/Clients.vue";
import Client from "../views/superadmin/clients/client/Client.vue";
import ClientBasicData from "../views/superadmin/clients/client/BasicData.vue";
import ClientQuestionnaires from "../views/superadmin/clients/client/Questionnaires.vue";
import Transactions from "../views/superadmin/clients/client/Transactions.vue";
import NewTransaction from "../views/superadmin/clients/client/NewTransaction.vue";
import MobileApps from "../views/superadmin/clients/client/MobileApps.vue";
import Admins from "../views/superadmin/clients/client/Admins.vue";
import Admin from "../views/superadmin/clients/client/Admin.vue";
import Users from "../views/admin/users-new/Users.vue";
import User from "../views/admin/users-new/user/User.vue";
import UserAssignments from "../views/admin/users-new/user/UserAssignments.vue";
import UserHistory from "../views/admin/users-new/user/UserHistory.vue";
import UserData from "../views/admin/users-new/user/UserData.vue";
import UserGroups from "../views/admin/users-new/user/UserGroups.vue";
import UserAssignmentReport from "../views/admin/assignment/assignment-dialog/report/AssignmentReport";

// ====== QUESTIONNAIRES ======
import Questionnaires from "../views/superadmin/questionnaires/Questionnaires.vue";
import QuestionnairesOfType from "../views/superadmin/questionnaires/QuestionnairesOfType.vue";
import Questionnaire from "../views/superadmin/questionnaires/questionnaire/Questionnaire.vue";
import QuestionnaireData from "../views/superadmin/questionnaires/questionnaire/QuestionnaireData.vue";
import Sections from "../views/superadmin/questionnaires/questionnaire/sections/Sections.vue";
import Section from "../views/superadmin/questionnaires/questionnaire/sections/Section.vue";
import Question from "../views/superadmin/questionnaires/questionnaire/sections/questions/Question.vue";
import Factors from "../views/superadmin/questionnaires/questionnaire/factors/Factors.vue";
import Factor from "../views/superadmin/questionnaires/questionnaire/factors/Factor.vue";
import FactorGroups from "../views/superadmin/questionnaires/questionnaire/factor_groups/FactorGroups.vue";

// ====== MONITORING ======
import Monitoring from "../views/superadmin/monitoring/Monitoring.vue";
import AssignmentReport from "@/views/admin/assignment/assignment-dialog/report/AssignmentReport";

// ====== COMPARISON ======
import ComparisonPossibleToCompare from "../views/admin/comparison/ComparisonPossibleToCompare.vue";
import ComparisonResults from "../views/admin/comparison/ComparisonResults.vue";

import Statistics from "../views/admin/statistics/Statistics.vue";

export default [
  {
    path: "/superadmin",
    component: Dashboard,
    name: "superadminDashboard",
    meta: { requiresAuth: true, role: "filistos" },
    beforeEnter: async (to, from, next) => {
      try {
        store.commit("toggleGlobalLoader", true);

        await Promise.all([
          store.dispatch("getSuperAdminUsersPerAccess"),
          store.dispatch("getSuperAdminEvaluatedUsers", {
            questionnaireTypeId: "psycho",
            period: "year",
          }),
          store.dispatch("getSuperAdminUsersPerNationality"),
          store.dispatch("getSuperAdminDashboardAssignments"),
          store.dispatch("getSuperAdminMessages"),
        ]);

        store.commit("toggleGlobalLoader", false);

        next();
      } catch (e) {
        store.commit("toggleGlobalLoader", false);
        store.commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });
        next(false);
      }
    },
  },
  // ====== CLIENTS ======
  {
    path: "/superadmin/clients",
    component: Clients,
    name: "clients",
    meta: { requiresAuth: true, role: "filistos" },
  },
  {
    path: "/superadmin/clients/new",
    component: Client,
    meta: { requiresAuth: true, role: "filistos" },
    children: [
      {
        path: "",
        component: ClientBasicData,
        name: "newUserBasicData",
        meta: { requiresAuth: true, role: "filistos" },
      },
    ],
  },
  {
    path: "/superadmin/clients/:clientId",
    component: Client,
    meta: { requiresAuth: true, role: "filistos" },
    children: [
      {
        path: "",
        component: ClientBasicData,
        name: "editUserBasicData",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch("getClient", { clientId: to.params.clientId });

            if (
              store.state.clients.currentClient?.clientId == to.params.clientId
            ) {
              next();
            } else {
              next(`/superadmin/clients`);
            }
          } catch (e) {
            next("/superadmin/clients");
          }
        },
      },
      {
        path: "questionnaires",
        component: ClientQuestionnaires,
        name: "editQuestionnaires",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch("getClient", { clientId: to.params.clientId });

            if (
              store.state.clients.currentClient?.clientId == to.params.clientId
            ) {
              await store.dispatch("getClientQuestionnaires", {
                clientId: to.params.clientId,
              });

              next();
            } else {
              next(`/superadmin/clients`);
            }
          } catch (e) {
            next("/superadmin/clients");
          }
        },
      },
      {
        path: "transactions",
        component: Transactions,
        name: "editUserTransactions",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch("getClient", { clientId: to.params.clientId });

            if (
              store.state.clients.currentClient?.clientId == to.params.clientId
            ) {
              store.commit("setTransactionsParams", {
                clientId: to.params.clientId,
              });
              await store.dispatch("getTransactions");

              next();
            } else {
              next(`/superadmin/clients`);
            }
          } catch (e) {
            console.log(e);
            next("/superadmin/clients");
          }
        },
      },
      {
        path: "transactions/new",
        component: NewTransaction,
        name: "newUserTransaction",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch("getClient", { clientId: to.params.clientId });

            if (
              store.state.clients.currentClient?.clientId == to.params.clientId
            ) {
              next();
            } else {
              next(`/superadmin/clients`);
            }
          } catch (e) {
            next("/superadmin/clients");
          }
        },
      },
      {
        path: "mobile-apps",
        component: MobileApps,
        name: "editUserMobileApps",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch("getClient", { clientId: to.params.clientId });

            if (
              store.state.clients.currentClient?.clientId == to.params.clientId
            ) {
              await store.dispatch("getMobileAppsOfClient", {
                clientId: to.params.clientId,
              });

              next();
            } else {
              next(`/superadmin/clients`);
            }
          } catch (e) {
            console.log(e);
            next("/superadmin/clients");
          }
        },
      },
      {
        path: "users",
        component: Users,
        name: "editUserUsers",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch("getClient", { clientId: to.params.clientId });

            if (
              store.state.clients.currentClient?.clientId == to.params.clientId
            ) {
              store.commit("USERSNEW_setUsersParams", {
                clientId: to.params.clientId,
              });
              await store.dispatch("USERSNEW_getUsers");

              next();
            } else {
              next(`/superadmin/clients`);
            }
          } catch (e) {
            console.log(e);
            next("/superadmin/clients");
          }
        },
      },
      {
        path: "users/:userId",
        component: User,
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch("getClient", { clientId: to.params.clientId });

            if (
              store.state.clients.currentClient?.clientId == to.params.clientId
            ) {
              await store.dispatch("USERSNEW_getUser", {
                userId: to.params.userId,
              });

              next();
            } else {
              next(`/superadmin/clients`);
            }
          } catch (e) {
            console.log(e);
            next("/superadmin/clients");
          }
        },
        children: [
          {
            path: "history",
            component: UserHistory,
            name: "editUserUserHistory",
            meta: { requiresAuth: true, role: "filistos" },
          },
          {
            path: "history/:reportId",
            component: UserAssignmentReport,
            name: "editUserHistoryReport",
            meta: { requiresAuth: true, role: "filistos" },
          },
          {
            path: "",
            component: UserAssignments,
            name: "editUserUserAssignments",
            meta: { requiresAuth: true, role: "filistos" },
          },
          {
            path: "report/:reportId",
            component: UserAssignmentReport,
            name: "editUserUserReport",
            meta: { requiresAuth: true, role: "filistos" },
          },
          {
            path: "user-data",
            component: UserData,
            name: "editUserUserData",
            meta: { requiresAuth: true, role: "filistos" },
          },
          {
            path: "user-groups",
            component: UserGroups,
            name: "editUserUserGroups",
            meta: { requiresAuth: true, role: "filistos" },
          },
        ],
      },
      {
        path: "admins",
        component: Admins,
        name: "editUserAdmins",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch("getClient", { clientId: to.params.clientId });

            if (
              store.state.clients.currentClient?.clientId == to.params.clientId
            ) {
              store.commit("setAdminsParams", { clientId: to.params.clientId });
              await store.dispatch("getAdmins");

              next();
            } else {
              next(`/superadmin/clients`);
            }
          } catch (e) {
            console.log(e);
            next("/superadmin/clients");
          }
        },
      },
      {
        path: "admins/new",
        component: Admin,
        name: "editUserAdminNew",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch("getClient", { clientId: to.params.clientId });

            if (
              store.state.clients.currentClient?.clientId == to.params.clientId
            ) {
              next();
            } else {
              next(`/superadmin/clients`);
            }
          } catch (e) {
            next("/superadmin/clients");
          }
        },
      },
      {
        path: "admins/:userId",
        component: Admin,
        name: "editUserAdminEdit",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch("getClient", { clientId: to.params.clientId });

            if (
              store.state.clients.currentClient?.clientId == to.params.clientId
            ) {
              await store.dispatch("getAdmin", to.params.userId);

              next();
            } else {
              next(`/superadmin/clients`);
            }
          } catch (e) {
            next("/superadmin/clients");
          }
        },
      },
    ],
  },
  // ====== QUESTIONNAIRES ======
  {
    path: "/superadmin/questionnaires",
    component: Questionnaires,
    meta: { requiresAuth: true, role: "filistos" },
    children: [
      {
        path: "",
        name: "questionnaires",
        redirect: (to) =>
          `/superadmin/questionnaires/${
            store.state.base.questionnaires[0]?.questionnaireTypeId
              ? store.state.base.questionnaires[0]?.questionnaireTypeId
              : "ability"
          }`,
      },
      {
        path: ":questionnaireTypeId",
        component: QuestionnairesOfType,
        name: "questionnairesOfType",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            const questionnaire = store.state.base.questionnaires.find(
              (qtype) =>
                qtype.questionnaireTypeId == to.params.questionnaireTypeId
            );

            if (questionnaire) {
              store.commit("setCurrentQuestionnaireType", questionnaire);
              next();
            } else {
              next("/superadmin/questionnaires");
            }
          } catch (e) {
            console.log(e);
            next("/superadmin/questionnaires");
          }
        },
      },
    ],
  },
  {
    path: "/superadmin/questionnaires/:questionnaireTypeId/:questionnaireId",
    component: Questionnaire,
    meta: { requiresAuth: true, role: "filistos" },
    children: [
      {
        path: "",
        component: QuestionnaireData,
        name: "questionnaireBasicData",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch(
              "getClientAdminQuestionnaire",
              to.params.questionnaireId
            );

            next();
          } catch (e) {
            console.log(e);
            next("/superadmin/questionnaires");
          }
        },
      },
      {
        path: "sections",
        component: Sections,
        meta: { requiresAuth: true, role: "filistos" },
        children: [
          {
            path: "",
            meta: { requiresAuth: true, role: "filistos" },
            beforeEnter: async (to, from, next) => {
              try {
                await store.dispatch(
                  "getClientAdminQuestionnaire",
                  to.params.questionnaireId
                );

                const sectionId =
                  store.state.questionnaires?.currentQuestionnaire?.sections[0]
                    ?.sectionId;

                next(
                  `/superadmin/questionnaires/${to.params.questionnaireTypeId}/${to.params.questionnaireId}/sections/${sectionId}`
                );
              } catch (e) {
                console.log(e);
                next("/superadmin/questionnaires");
              }
            },
          },
          {
            path: ":sectionId",
            component: Section,
            name: "questionnaireSection",
            meta: { requiresAuth: true, role: "filistos" },
            beforeEnter: async (to, from, next) => {
              try {
                await store.dispatch(
                  "getClientAdminQuestionnaire",
                  to.params.questionnaireId
                );

                await store.dispatch("getSection", to.params.sectionId);

                next();
              } catch (e) {
                console.log(e);
                next("/superadmin/questionnaires");
              }
            },
          },
          {
            path: ":sectionId/:questionId",
            component: Question,
            name: "questionnaireQuestion",
            meta: { requiresAuth: true, role: "filistos" },
            beforeEnter: async (to, from, next) => {
              try {
                await store.dispatch(
                  "getClientAdminQuestionnaire",
                  to.params.questionnaireId
                );

                await store.dispatch("getSection", to.params.sectionId);

                await store.dispatch("getQuestion", to.params.questionId);

                next();
              } catch (e) {
                console.log(e);
                next("/superadmin/questionnaires");
              }
            },
          },
        ],
      },
      {
        path: "factors",
        component: Factors,
        name: "questionnaireFactors",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch(
              "getClientAdminQuestionnaire",
              to.params.questionnaireId
            );

            next();
          } catch (e) {
            console.log(e);
            next("/superadmin/questionnaires");
          }
        },
      },
      {
        path: "factors/:factorId",
        component: Factor,
        name: "questionnaireFactor",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch(
              "getClientAdminQuestionnaire",
              to.params.questionnaireId
            );
            await store.dispatch("getFactor", { factorId: to.params.factorId });

            next();
          } catch (e) {
            console.log(e);
            next("/superadmin/questionnaires");
          }
        },
      },
      {
        path: "factors/:factorId/:childFactorId",
        component: Factor,
        name: "questionnaireChildFactor",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch(
              "getClientAdminQuestionnaire",
              to.params.questionnaireId
            );
            await store.dispatch("getFactor", { factorId: to.params.factorId });
            await store.dispatch("getFactor", {
              factorId: to.params.childFactorId,
              isChild: true,
            });

            next();
          } catch (e) {
            console.log(e);
            next("/superadmin/questionnaires");
          }
        },
      },

      {
        path: "factor_groups",
        component: FactorGroups,
        name: "questionnaireFactorGroups",
        meta: { requiresAuth: true, role: "filistos" },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch(
              "getClientAdminQuestionnaire",
              to.params.questionnaireId
            );

            next();
          } catch (e) {
            console.log(e);
            next("/superadmin/questionnaires");
          }
        },
      },
    ],
  },
  {
    path: "/superadmin/monitoring",
    component: Monitoring,
    name: "monitoring",
    meta: { requiresAuth: true, role: "filistos" },
    beforeEnter: async (to, from, next) => {
      try {
        store.commit("toggleGlobalLoader", true);

        await store.dispatch("getMonitoring");

        store.commit("toggleGlobalLoader", false);

        next();
      } catch (e) {
        console.log(e);
        next("/superadmin/");
      }
    },
  },
  {
    path: "/superadmin/monitoring/:reportId",
    component: AssignmentReport,
    name: "monitoringReport",
    meta: { requiresAuth: true, role: "filistos" },
  },
  {
    path: "/superadmin/comparison",
    component: ComparisonPossibleToCompare,
    name: "superAdminComparisonPossible",
    meta: { requiresAuth: true, role: ["filistos"] },
  },
  {
    path: "/superadmin/comparison/results",
    component: ComparisonResults,
    name: "superAdminComparisonResults",
    meta: { requiresAuth: true, role: ["filistos"] },
    beforeEnter: async (to, from, next) => {
      try {
        if (
          store.state.comparison.userQuestsToCompare.length &&
          store.state.comparison.userQuestsParams.questionnaire?.questionnaireId
        ) {
          next();
        } else {
          next("/comparison");
        }
      } catch (e) {
        console.log(e);
        next("/");
      }
    },
  },
  {
    path: "/superadmin/statistics",
    component: Statistics,
    name: "superAdminStatistics",
    meta: { requiresAuth: true, role: ["filistos"] },
  },
];
