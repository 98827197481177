<template>
  <div class="wrapper rounded flex-grow-1 d-flex flex-column">
    <div class="section-header d-flex align-center pa-1 rounded-tl rounded-tr">
      <v-icon class="mr-2">mdi-chart-bar</v-icon>

      <div class="body-2">Total Users</div>
    </div>

    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loader"
    ></v-progress-linear>

    <div class="pa-2 align-self-start" v-if="isSuperadmin">
      <v-select
        label="Client"
        outlined
        dense
        hide-details
        :items="allClients"
        item-value="clientId"
        item-text="companyName"
        v-model="client"
        @change="onGetUsersPerAccess"
        clearable
      ></v-select>
    </div>

    <apexchart
      width="100%"
      height="300px"
      type="donut"
      class="chart"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapActions, mapState } from "vuex";

export default {
  props: ["isSuperadmin"],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      options: {
        colors: ["#3276b1", "#1a345b", "#c19752"],
        labels: ["Basic Users", "Group Managers", "Company Administrators"],
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: 30,
            },
          },
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "right",
        },
      },
      series: [75, 17, 8],
      client: null,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      allClients: (state) => state.base.allClients,
    }),
    usersPerAccess() {
      return this.$store.state[
        this.isSuperadmin ? "superAdminDashboard" : "dashboard"
      ].usersPerAccess;
    },
  },
  created() {
    this.options.labels = this.usersPerAccess.map(
      (category) => category.itemKey
    );

    this.series = this.usersPerAccess.map((category) =>
      parseFloat(category.itemPercentage.toFixed(1))
    );
  },
  methods: {
    ...mapActions(["getSuperAdminUsersPerAccess"]),
    async onGetUsersPerAccess() {
      this.loader = true;

      try {
        await this.getSuperAdminUsersPerAccess({
          clientId: this.client,
        });

        this.options.labels = this.usersPerAccess.map(
          (category) => category.itemKey
        );

        this.series = this.usersPerAccess.map((category) =>
          parseFloat(category.itemPercentage.toFixed(1))
        );
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  margin-top: 68px;
}
</style>
