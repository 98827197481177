var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper px-4 pt-4 grey lighten-3 d-flex flex-column"},[_c('div',{staticClass:"d-flex mb-4"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-finance")]),_c('div',{staticClass:"body-1 font-weight-medium"},[_vm._v("Statistics")])],1),_c('div',{staticClass:"wrapper rounded flex-grow-1 d-flex flex-column pa-4"},[_c('form',{staticClass:"d-flex align-center mb-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.role == 'filistos')?_c('v-select',{staticClass:"flex-grow-0 mr-2",attrs:{"label":"Client","outlined":"","dense":"","hide-details":"","items":_vm.allClients,"item-value":"clientId","item-text":"companyName","clearable":""},model:{value:(_vm.selectedClientId),callback:function ($$v) {_vm.selectedClientId=$$v},expression:"selectedClientId"}}):_vm._e(),(_vm.role != 'psysupport' && _vm.role != 'allpsysup')?_c('v-menu',{attrs:{"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-text-field',{staticClass:"mr-2",attrs:{"outlined":"","dense":"","hide-details":"","label":"Select Questionnaire","clearable":"","readonly":"","append-icon":"mdi-menu-down","value":_vm.selectedQuestionnaire
                  ? _vm.selectedQuestionnaire.questionnaireName
                  : null},on:{"click:clear":function($event){_vm.selectedQuestionnaire = null}}})],1)]}}],null,false,2751678787)},[_c('div',{staticClass:"white pa-4 questionnaire-menu"},_vm._l((_vm.questionnaires),function(questionnaireType){return _c('div',{key:questionnaireType.questionnaireTypeId},[_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(questionnaireType.questionnaireTypeName)+" ")]),_c('div',{staticClass:"pl-2"},_vm._l((questionnaireType.questionnaires),function(questionnaire){return _c('div',{key:questionnaire.questionnaireId},[_c('div',{staticClass:"body-2 py-1 px-2",class:{
                    'font-weight-medium':
                      questionnaire.subQuestionnaires &&
                      questionnaire.subQuestionnaires.length,
                    questionnaire:
                      !questionnaire.subQuestionnaires ||
                      !questionnaire.subQuestionnaires.length,
                  },on:{"click":function($event){(!questionnaire.subQuestionnaires ||
                      !questionnaire.subQuestionnaires.length) &&
                      (_vm.selectedQuestionnaire = questionnaire)}}},[_vm._v(" "+_vm._s(questionnaire.questionnaireName)+" ")]),(
                    questionnaire.subQuestionnaires &&
                      questionnaire.subQuestionnaires.length
                  )?_c('div',{staticClass:"pl-4"},_vm._l((questionnaire.subQuestionnaires),function(subQuestionnaire){return _c('div',{key:subQuestionnaire.questionnaireId},[_c('div',{staticClass:"body-2 py-1 px-2 questionnaire",on:{"click":function($event){_vm.selectedQuestionnaire = subQuestionnaire}}},[_c('span',{staticClass:"font-weight-bold mr-1"},[_vm._v("-")]),_vm._v(" "+_vm._s(subQuestionnaire.questionnaireName)+" ")])])}),0):_vm._e()])}),0)])}),0)]):_vm._e(),_c('v-menu',{ref:"dateFrom",attrs:{"max-width":"290","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"flex-grow-0 mr-2",attrs:{"value":_vm.dateFrom ? _vm.dayjs(_vm.dateFrom).format('DD/MM/YYYY') : '',"dense":"","prepend-inner-icon":"mdi-calendar","outlined":"","label":"Date From","readonly":"","clearable":"","hide-details":""},on:{"click:clear":function($event){_vm.dateFrom = null}}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{on:{"change":function($event){return _vm.$refs.dateFrom.save()}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-menu',{ref:"dateTo",attrs:{"max-width":"290","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"flex-grow-0 mr-2",attrs:{"value":_vm.dateTo ? _vm.dayjs(_vm.dateTo).format('DD/MM/YYYY') : '',"dense":"","prepend-inner-icon":"mdi-calendar","outlined":"","label":"Date To","readonly":"","hide-details":"","clearable":""},on:{"click:clear":function($event){_vm.dateTo = null}}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{on:{"change":function($event){return _vm.$refs.dateTo.save()}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),_c('v-btn',{staticClass:"white--text ml-auto",attrs:{"depressed":"","color":"filyellow","type":"submit","disabled":!_vm.selectedQuestionnaire,"loading":_vm.loader}},[_vm._v(" Search ")])],1),_c('v-btn',{staticClass:"align-self-start",attrs:{"depressed":"","color":"primary","disabled":!_vm.items.length,"loading":_vm.excelLoader},on:{"click":_vm.exportExcel}},[_vm._v("Export excel")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10000,"hide-default-footer":"","hide-default-header":"","id":"statistics-table"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                  var headers = ref.props.headers;
return [_c('thead',{staticClass:"thead"},[_c('tr',_vm._l((headers),function(h,index){return _c('th',{key:index,staticClass:"rotate"},[_c('div',[_c('span',{class:{ 'font-weight-black black--text': !h.child }},[_vm._v(_vm._s(h.text))])])])}),0)])]}},{key:"item",fn:function(item){return [(item.item)?_c('tr',{staticClass:"body"},_vm._l((_vm.headers),function(h,index){return _c('td',{key:index},[(Object(item.item[h.value]) === item.item[h.value])?_c('div',{staticClass:"d-flex align-center",class:{
                link: item.item[h.value] && item.item[h.value].fraction,
              },on:{"click":function($event){return _vm.openDialog(
                  {
                    positionId: item.item.positionId,
                    positionName: item.item.positionName,
                  },
                  {
                    nationalityId: item.item[h.value].natId,
                    nationalityName: h.text,
                  }
                )}}},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.item[h.value].fraction ? item.item[h.value].fraction : "-")+" ")]),(
                  _vm.dialogLoader &&
                    _vm.dialog.positionId == item.item.positionId &&
                    _vm.dialog.nationalityId == item.item[h.value].natId
                )?_c('v-progress-circular',{attrs:{"size":"20","width":"2","indeterminate":"","color":"primary"}}):_vm._e()],1):_c('div',[(
                  Date.parse(item.item[h.value]) && isNaN(item.item[h.value])
                )?_c('div',[_vm._v(" "+_vm._s(_vm.dayjs .utc(item.item[h.value]) .local() .format("DD/MM/YYYY"))+" ")]):_c('div',[_vm._v(" "+_vm._s(item.item[h.value] ? item.item[h.value] : "-")+" ")])])])}),0):_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"max-width":"1250px"},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[_c('div',{staticClass:"white dialog-wrapper scrollable-dialog"},[(!_vm.showReport)?_c('div',{staticClass:"d-flex mx-1 mt-1"},[_c('div',{staticClass:"body-1 font-weight-medium mr-auto ml-1"},[_vm._v(" "+_vm._s(_vm.dialog.positionName)+" | "+_vm._s(_vm.dialog.nationalityName)+" ")]),_c('v-btn',{staticClass:"ml-auto mt-1 mr-1",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog.open = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-btn',{staticClass:"align-self-start ma-2",attrs:{"depressed":"","color":"primary","disabled":!_vm.items.length,"loading":_vm.excelUserLoader},on:{"click":_vm.exportExcelUsers}},[_vm._v("Export excel")]),(!_vm.showReport)?_c('div',{staticClass:"pa-4 pt-0 white"},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"disabled":_vm.tabLoader}},[_vm._v("Not Completed ")]),_c('v-tab',{attrs:{"disabled":_vm.tabLoader}},[_vm._v(" Completed ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-data-table',{staticClass:"mb-3",attrs:{"headers":_vm.assignmentsHeaders,"hide-default-footer":"","items":_vm.unFinilized.data,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.report",fn:function(ref){
                var item = ref.item;
return [(_vm.tab == 1)?_c('v-btn',{attrs:{"small":"","width":"60px"},on:{"click":function($event){_vm.showReport = item.assignUserQuestId}}},[_vm._v("report")]):_c('div',[_c('v-menu',{ref:"remindAllMenu",attrs:{"top":"","right":"","close-on-content-click":false,"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-start mb-2",attrs:{"depressed":"","color":"primary","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-send")]),_vm._v(" Remind ")],1)]}}],null,true)},[_c('div',{staticClass:"white pa-5"},[_c('div',{staticClass:"body-2 mb-4"},[_vm._v(" Are you sure you want to remind the user? ")]),_c('v-btn',{staticClass:"white--text",attrs:{"small":"","depressed":"","loading":_vm.remindLoader,"color":"filyellow"},on:{"click":function($event){return _vm.onRemindAssignment(item)}}},[_vm._v("Remind")])],1)])],1)]}}],null,true)})],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"mb-3",attrs:{"headers":_vm.assignmentsHeaders,"hide-default-footer":"","items":_vm.finalized.data,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.report",fn:function(ref){
                var item = ref.item;
return [(
                    _vm.tab == 1 &&
                      ((_vm.selectedQuestionnaire.questionnaireTypeId ==
                        'elearn' &&
                        item.elearningPassed) ||
                        _vm.selectedQuestionnaire.questionnaireTypeId !=
                          'elearn') &&
                      (_vm.checkGmRoleType == 'adminRights' ||
                        _vm.checkGmRoleType == 'gmCanSeeEverything')
                  )?_c('v-btn',{attrs:{"small":"","width":"60px"},on:{"click":function($event){_vm.showReport = item.assignUserQuestId}}},[_vm._v("report")]):_vm._e(),_c('div',{staticClass:"justify-start d-flex align-center"},[(
                      _vm.tab == 1 &&
                        _vm.selectedQuestionnaire.questionnaireTypeId ==
                          'elearn' &&
                        !item.elearningPassed
                    )?_c('div',{staticClass:"white--text error pa-1 rounded"},[_vm._v(" Failed ")]):_vm._e()])]}}],null,true)})],1)],1)],1):_c('AssignmentReport',{attrs:{"reportIdProp":_vm.showReport},on:{"closeReport":function($event){_vm.showReport = false}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }