<template>
  <div class="wrapper rounded flex-grow-1">
    <div class="section-header d-flex align-center pa-1 rounded-tl rounded-tr">
      <v-icon class="mr-2">mdi-chart-bar</v-icon>

      <div class="body-2">Assignments</div>
    </div>

    <v-tabs
      grow
      color="primary"
      v-model="tab"
      class="flex-grow-0"
      @change="onGetDashboardAssignments"
    >
      <v-tab href="#psycho" :disabled="loader"> Psychometric </v-tab>

      <v-tab href="#ability" :disabled="loader"> Ability </v-tab>

      <v-tab href="#form" :disabled="loader"> Forms </v-tab>

      <v-tab href="#elearn" :disabled="loader"> E-Learning Courses </v-tab>
    </v-tabs>

    <Assignments :isSuperadmin="isSuperadmin" />
  </div>
</template>

<script>
import Assignments from "@/views/admin/dashboard/assignments/Assignments";
import { mapActions, mapMutations } from "vuex";

export default {
  props: ["isSuperadmin"],
  components: {
    Assignments,
  },
  data() {
    return {
      tab: "psycho",
      loader: false,
    };
  },
  methods: {
    ...mapActions([
      "getDashboardAssignments",
      "getSuperAdminDashboardAssignments",
    ]),
    ...mapMutations(["setDashboardAssignmentsParams"]),
    async onGetDashboardAssignments() {
      this.loader = true;

      try {
        this.setDashboardAssignmentsParams({
          questionnaireTypeId: this.tab,
        });

        if (this.isSuperadmin) {
          this.getSuperAdminDashboardAssignments();
        } else {
          this.getDashboardAssignments();
        }
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
