var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"app":"","color":"white","dense":_vm.isLoggedIn,"clipped-left":_vm.isLoggedIn}},[_c('div',{staticClass:"flex-grow-1 d-flex"},[_c('img',{staticClass:"logo",class:{ pointer: _vm.isLoggedIn, 'mx-auto': !_vm.isLoggedIn },attrs:{"src":require("@/assets/logo.png")},on:{"click":function($event){_vm.isLoggedIn &&
            _vm.$router.push({
              path: ("/" + (_vm.role == 'filistos' ? 'filistos' : '')),
            })}}}),(_vm.isLoggedIn)?_c('div',{staticClass:"ml-auto d-flex align-center"},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"ml-4",attrs:{"icon":"","small":"","ripple":false,"color":"filyellow"},on:{"click":function($event){return _vm.toggleSidebar(!_vm.sidebar)}}},[_c('v-icon',[_vm._v("mdi-menu")])],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp && _vm.info)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"d-flex align-center"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-chevron-down")]),_c('div',{staticClass:"d-flex flex-column mr-2"},[_c('div',{staticClass:"body-2 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.info.companyName)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.info.loggedOnUser))])]),_c('img',{staticClass:"client-logo",attrs:{"src":(_vm.baseUrl + "/" + (_vm.info.logoUrl))}})],1)]}}],null,false,527954517),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('div',{staticClass:"white"},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},[(_vm.role == 'co' || _vm.role == 'gm')?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleDialog({
                    open: true,
                    component: 'Licenses',
                    width: '800px',
                  })}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"filyellow"}},[_vm._v("mdi-card-account-details")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("My Licenses")])],1)],1):_vm._e(),(_vm.role == 'co' || _vm.role == 'gm' || _vm.role == 'psysupport')?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleDialog({
                    open: true,
                    component: 'EndUserLicense',
                    width: '800px',
                  })}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"filyellow"}},[_vm._v("mdi-license")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("End User License Agreement")])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"filyellow"}},[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Sign Out")])],1)],1)],1)],1)]):_vm._e()],1):_vm._e()]),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"white pa-5"},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v("mdi-logout")]),_c('div',{staticClass:"text-h6"},[_vm._v("Logout ?")])],1),_c('div',{staticClass:"body-2 mb-4"},[_vm._v(" You can improve your security further after logging out by closing this opened browser ")]),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":""},on:{"click":_vm.onLogout}},[_vm._v("yes")]),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("no")])],1)])])],1),(_vm.isLoggedIn)?_c('SidebarWrapper',{on:{"openSignoutDialog":function($event){_vm.dialog = true}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }