<template>
  <div class="component-wrapper d-flex flex-column align-center justify-center">
    <img src="@/assets/logo.png" class="mb-5" />

    <v-progress-circular
      size="80"
      width="4"
      indeterminate
      color="filblue"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
img{
  width: 280px;
}
</style>