<template>
  <div class="component-wrapper pa-0 d-flex flex-column">
    <v-row>
      <v-col cols="6">
        <div class="d-flex mb-4">
          <v-select
            dense
            outlined
            hide-details
            label="Platform Access"
            :items="platformAccesses"
            item-value="itemKey"
            item-text="itemValue"
            class="flex-1 mr-2"
            v-model="platformAccess"
            :readonly="
              role == 'filistos' ||
              (isEdit && platformAccess == 'gm' && !hasChanges)
            "
          ></v-select>

          <v-select
            dense
            outlined
            label="Permissions"
            :items="permissions"
            item-value="key"
            item-text="value"
            class="flex-1"
            v-model="selectedPermissions"
            multiple
            chips
            deletable-chips
            small-chips
            v-if="platformAccess == 'gm' || platformAccess == 'psysupport'"
            :readonly="role == 'filistos'"
          ></v-select>
        </div>

        <div class="d-flex mb-6">
          <v-switch
            inset
            color="success"
            label="Enabled"
            v-model="isEnabled"
            class="ma-0 pa-0"
            dense
            hide-details
            :readonly="role == 'filistos'"
          ></v-switch>

          <v-divider vertical class="mx-4"></v-divider>

          <v-switch
            inset
            color="success"
            label="Assessor"
            v-model="isAssessor"
            class="ma-0 pa-0"
            dense
            hide-details
            :readonly="role == 'filistos'"
          ></v-switch>

          <v-divider
            vertical
            class="mx-4"
            v-if="platformAccess != 'basic'"
          ></v-divider>

          <v-switch
            v-if="platformAccess != 'basic'"
            inset
            color="success"
            label="Can Login"
            v-model="canLogin"
            class="ma-0 pa-0"
            dense
            hide-details
            :readonly="role == 'filistos'"
          ></v-switch>
        </div>

        <v-text-field
          dense
          outlined
          label="Identity*"
          class="mb-1"
          :readonly="role == 'filistos'"
          v-model="identity"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          label="First Name*"
          class="mb-1"
          :readonly="role == 'filistos'"
          v-model="firstName"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          label="Last Name*"
          class="mb-1"
          :readonly="role == 'filistos'"
          v-model="lastName"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          label="Username*"
          class="mb-1"
          :readonly="role == 'filistos'"
          v-model="username"
          v-if="platformAccess != 'basic'"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          :label="`Email ${
            platformAccess && platformAccess != 'basic' ? '*' : ''
          }`"
          type="email"
          :readonly="role == 'filistos'"
          v-model="email"
        ></v-text-field>

        <v-radio-group
          v-model="positionType"
          row
          class="mt-0"
          :readonly="role == 'filistos'"
        >
          <v-radio
            v-for="type in currentClient.userTypes"
            :key="type.itemKey"
            :value="type.itemKey"
            :label="type.itemValue"
            :ripple="false"
          ></v-radio>
        </v-radio-group>

        <v-select
          dense
          outlined
          label="Position*"
          :items="positions"
          item-value="itemKey"
          item-text="itemValue"
          :readonly="role == 'filistos'"
          v-model="position"
        ></v-select>
      </v-col>

      <v-col cols="6">
        <v-select
          dense
          outlined
          label="Nationality*"
          :items="countries"
          item-value="itemKey"
          item-text="itemValue"
          class="mb-1"
          v-model="nationality"
          :readonly="role == 'filistos'"
        ></v-select>

        <v-select
          dense
          outlined
          label="Language*"
          :items="languages"
          item-value="itemKey"
          item-text="itemValue"
          class="mb-1"
          v-model="language"
          :readonly="role == 'filistos'"
        ></v-select>

        <v-text-field
          dense
          outlined
          label="Mobile"
          class="mb-1"
          v-model="mobile"
          :readonly="role == 'filistos'"
        ></v-text-field>

        <v-menu max-width="290" :close-on-content-click="false" ref="birthDate">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="birthDate ? dayjs(birthDate).format('DD/MM/YYYY') : ''"
              dense
              prepend-inner-icon="mdi-calendar"
              outlined
              label="Birth Date"
              class="mb-1"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="birthDate"
            @change="$refs.birthDate.save()"
            :readonly="role == 'filistos'"
          ></v-date-picker>
        </v-menu>

        <v-select
          dense
          outlined
          label="Gender"
          :items="genders"
          item-value="itemKey"
          item-text="itemValue"
          class="mb-1"
          v-model="gender"
          :readonly="role == 'filistos'"
        ></v-select>

        <v-select
          dense
          outlined
          label="Marital Status"
          :items="maritalStatuses"
          item-value="itemKey"
          item-text="itemValue"
          class="mb-1"
          v-model="marital"
          :readonly="role == 'filistos'"
        ></v-select>

        <v-text-field
          dense
          outlined
          label="Kids"
          type="number"
          v-model.number="kids"
          :readonly="role == 'filistos'"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      permissions: [
        {
          key: "gmCanReadResults",
          value: "Read Test Results",
        },
        {
          key: "gmCanAccessElearning",
          value: "E-Learning certificates",
        },
        {
          key: "gmCanAccessForms",
          value: "Forms",
        },

        {
          key: "gmCanCreateMobileNotifications",
          value: "Create Mobile Notifications",
        },
      ],
      platformAccess: "basic",
      selectedPermissions: null,
      isEnabled: true,
      canLogin: true,
      isAssessor: false,
      identity: "",
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      positionType: null,
      position: null,
      nationality: null,
      language: null,
      mobile: "",
      birthDate: null,
      gender: null,
      marital: null,
      kids: "",
      createdBy: "",
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.usersNew.currentUser,
      platformAccesses: (state) => state.base.lists.platformAccesses,
      role: (state) => state.auth.role,
      countries: (state) => state.base.lists.countries,
      languages: (state) => state.base.lists.languages,
      genders: (state) => state.base.lists.genders,
      maritalStatuses: (state) => state.base.lists.maritalStatuses,
      currentClient: (state) => state.clients.currentClient,
    }),
    positions() {
      if (!this.positionType) return [];

      return this.currentClient?.workPositions?.find(
        (wp) => wp.itemType == this.positionType
      )?.items;
    },
    isEdit() {
      return Boolean(this.currentUser);
    },
  },
  created() {
    if (this.isEdit) {
      this.platformAccess = this.currentUser.platformAccessId;
      this.canLogin = this.currentUser.canLogin;
      this.isAssessor = this.currentUser.isAssessor;
      this.isAssessor = this.currentUser.isAssessor;
      this.isEnabled = this.currentUser.canGetAssignments;
      this.identity = this.currentUser.identityNumber;
      this.firstName = this.currentUser.firstName;
      this.lastName = this.currentUser.lastName;
      this.username = this.currentUser.userName;
      this.email = this.currentUser.userEmail;
      this.positionType = this.currentUser.userTypeId;
      this.position = this.currentUser.positionId;
      this.nationality = this.currentUser.nationalityId;
      this.language = this.currentUser.languageId;
      this.mobile = this.currentUser.mobile;
      this.birthDate = this.currentUser.dateOfBirth
        ? this.dayjs
            .utc(this.currentUser.dateOfBirth)
            .local()
            .toISOString(true)
            .substr(0, 10)
        : null;
      this.gender = this.currentUser.gender;
      this.marital = this.currentUser.marital;
      this.createdBy = this.currentUser.createdBy;
      this.kids =
        this.currentUser.kids != null && this.currentUser.kids != undefined
          ? this.currentUser.kids
          : null;
    }
  },
};
</script>

<style scoped></style>
