<template>
  <div class="wrapper rounded flex-grow-1 d-flex flex-column">
    <div
      class="d-flex flex-column flex-md-row align-center align-md-start justify-start pa-5 pb-1"
    >
      <div class="mr-md-5 mb-1 d-flex align-start">
        <v-text-field
          outlined
          dense
          label="Search"
          class="mr-2"
          prepend-inner-icon="mdi-magnify"
          hint="Search by title"
          persistent-hint
          v-model="assignmentsParams.search"
          :readonly="getAssignmentsLoader"
        ></v-text-field>

        <v-btn
          icon
          color="primary"
          v-if="assignmentsParams.search"
          @click="assignmentsParams.search = ''"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="d-flex flex-column flex-sm-row ml-md-auto mb-2 mb-md-0">
        <ShowPerPage
          class="mr-2"
          :currentPerPage="assignmentsParams.itemsPerPage"
          :options="showPerPageOptions"
        />

        <ColumnVisibility
          :headers="headers"
          @visibilityChanged="onVisibilityChanged"
          @initLoaderChanged="onVisibilityLoaderChanged"
          grid="assignmentsList"
        />

        <!-- <v-btn class="mr-sm-4 mb-4 mb-sm-0" depressed>
          <v-icon left>mdi-cloud-download</v-icon>
          CSV
        </v-btn>

        <v-btn depressed>
          <v-icon left>mdi-cloud-download</v-icon>
          PDF
        </v-btn> -->
      </div>
    </div>

    <v-data-table
      :headers="visibleHeaders"
      :items="
        !getAssignmentsLoader && !visibilityLoader ? assignments.results : []
      "
      :server-items-length="assignments.rowCount"
      class="clickable"
      @click:row="dialog = { open: true, type: 'edit', item: $event }"
      :loading="getAssignmentsLoader || visibilityLoader"
      @update:options="onGetAssignments"
      item-key="assignmentId"
      :options="options"
      hide-default-footer
    >
      <template v-slot:[`item.creationDate`]="{ item }">
        {{
          item.creationDate
            ? dayjs(item.creationDate).format("DD/MM/YYYY")
            : "-"
        }}
      </template>

      <template v-slot:[`item.dateToSend`]="{ item }">
        {{
          item.dateToSend ? dayjs(item.dateToSend).format("DD/MM/YYYY") : "-"
        }}
      </template>

      <template v-slot:[`item.dateToRemind`]="{ item }">
        {{
          item.dateToRemind
            ? dayjs(item.dateToRemind).format("DD/MM/YYYY")
            : "-"
        }}
      </template>

      <template v-slot:[`item.dateToEnd`]="{ item }">
        {{ item.dateToEnd ? dayjs(item.dateToEnd).format("DD/MM/YYYY") : "-" }}
      </template>

      <template v-slot:[`item.send`]="{ item }">
        <div class="d-flex white--text">
          <div class="bullet filgreen disabled mr-2">
            {{ item.totalUserQuestionnaires }}
          </div>

          <div class="bullet filgreen">
            {{ item.completedUserQuestionnaires }}
          </div>
        </div>
      </template>
    </v-data-table>

    <div class="pagination-wrapper py-3 mt-auto">
      <v-pagination
        :length="assignments.pageCount"
        v-model="assignmentsParams.page"
        :disabled="getAssignmentsLoader"
        v-if="assignments.pageCount"
      ></v-pagination>
    </div>

    <v-dialog
      v-model="dialog.open"
      :max-width="dialog.type == 'delete' ? '500px' : '1250px'"
      persistent
    >
      <template v-if="dialog.open">
        <div class="white" v-if="dialog.type == 'delete'">
          <div class="primary pa-3 d-flex align-start">
            <div class="text-h6 white--text">Delete Assignments</div>

            <v-btn
              icon
              small
              color="white"
              class="ml-auto"
              @click="dialog = { open: false, type: null }"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>

          <div class="pa-5">
            <div class="text-h6 mb-2">WARNING</div>

            <div class="body-2 mb-4">
              Assignments can be deleted only if they do not include completed
              questionnaires! Do you want to proceed?
            </div>

            <div class="d-flex">
              <v-btn
                color="filred"
                depressed
                class="mr-2 white--text"
                @click="dialog = { open: false, type: null }"
                >Delete Assignments</v-btn
              >

              <v-btn depressed @click="dialog = { open: false, type: null }"
                >Cancel</v-btn
              >
            </div>
          </div>
        </div>

        <AssignmentDialog
          :isEdit="true"
          :assignment="dialog.item"
          @closeDialog="dialog = { open: false, type: null, item: null }"
          v-else
        />
      </template>
    </v-dialog>
  </div>
</template>

<script>
import AssignmentDialog from "@/views/admin/assignment/assignment-dialog/AssignmentDialog";
import ColumnVisibility from "@/components/ColumnVisibility";
import { mapActions, mapMutations, mapState } from "vuex";
import ShowPerPage from "@/components/ShowPerPage";

export default {
  components: {
    AssignmentDialog,
    ColumnVisibility,
    ShowPerPage,
  },
  data() {
    return {
      headers: [
        { text: "Creation Date", value: "creationDate" },
        { text: "Title", value: "assignmentName" },
        { text: "Date to Send", value: "dateToSend" },
        { text: "Date To Remind", value: "dateToRemind" },
        { text: "Date To End", value: "dateToEnd" },
        { text: "Send Type", value: "sendTypeName" },
        { text: "Email Confirmation to", value: "usersToNotifyIds" },
        { text: "Send/Completed", value: "send" },
      ],
      visibleHeaders: [],
      dialog: { open: false, type: null, item: null },
      timeout: null,
      visibilityLoader: false,
      showPerPageOptions: [],
    };
  },
  computed: {
    ...mapState({
      assignments: (state) => state.assignments.assignments,
      assignmentsParams: (state) => state.assignments.assignmentsParams,
      getAssignmentsLoader: (state) => state.assignments.getAssignmentsLoader,
    }),
    options() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.assignmentsParams;

      return {
        itemsPerPage,
        page,
        sortBy,
        sortDesc,
      };
    },
  },
  created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setAssignmentsParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setAssignmentsParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setAssignmentsParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setAssignmentsParams,
      },
    ];

    console.log(this.showPerPageOptions);
  },
  methods: {
    ...mapActions(["getAssignments"]),
    ...mapMutations(["setAssignmentsParams"]),
    async onGetAssignments(event) {
      if (this.getAssignmentsLoader) return;

      let { itemsPerPage, page, sortBy, sortDesc } = event || {};

      if (event) {
        this.setAssignmentsParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      }

      try {
        await this.getAssignments();
      } catch (e) {
        console.log(e);
      }
    },
    onVisibilityChanged(visibleHeaders) {
      this.visibleHeaders = visibleHeaders
        .filter((header) => header.visible)
        .map((header) => {
          return {
            text: header.text,
            value: header.value,
          };
        });
    },
    onVisibilityLoaderChanged(loader) {
      this.visibilityLoader = loader;
    },
  },
  watch: {
    "assignmentsParams.search"() {
      clearTimeout(this.timeout);

      if (this.getAssignmentsLoader) return;

      this.timeout = setTimeout(() => {
        this.setAssignmentsParams({
          page: 1,
        });
        this.onGetAssignments();
      }, 500);
    },
  },
  destroyed() {
    this.setAssignmentsParams({
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: [],
      search: "",
    });
  },
};
</script>

<style lang="scss" scoped>
.bullet {
  &.disabled {
    opacity: 0.5;
  }
}
</style>
