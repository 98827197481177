<template>
  <div class="d-flex flex-column">
    <div class="border-wrapper pa-4 rounded mb-4">
      <div class="subtitle-1 text-center font-weight-medium mb-1">
        Highest Factor
      </div>

      <v-row no-gutters class="mb-3">
        <v-col class="pa-1 d-flex align-center">
          <div class="subtitle-2">{{ report.highestFactor.factorName }}</div>
        </v-col>

        <v-col class="pa-1 d-flex align-center justify-center">
          <v-progress-linear
            :value="report.highestFactor.aScore"
            :color="getColor(report.highestFactor.colorFlag)"
          ></v-progress-linear>
        </v-col>

        <v-col class="pa-1 d-flex align-center justify-center">
          <div
            class="subtitle-2"
            :class="{
              'filyellow--text': report.highestFactor.colorFlag == 'Y',
              'error--text': report.highestFactor.colorFlag == 'R',
              'success--text': report.highestFactor.colorFlag == 'G',
            }"
          >
            {{ report.highestFactor.aScore }}
          </div>
        </v-col>
      </v-row>

      <div class="body-2 text-center">
        {{ report.highestFactor.factorText }}
      </div>
    </div>

    <div class="d-flex">
      <div class="flex-1 mr-4">
        <div class="border-wrapper pa-4 rounded">
          <div class="subtitle-1 font-weight-medium mb-4">Positive Factors</div>

          <v-row
            v-for="(factor, index) in report.positiveFactors"
            :key="`${index}-${factor.factorId}`"
            no-gutters
          >
            <v-col class="pa-1 d-flex align-center">
              <div class="subtitle-2">{{ factor.factorName }}</div>
            </v-col>

            <v-col class="pa-1 d-flex align-center justify-center">
              <v-progress-linear
                :value="factor.aScore"
                :color="getColor(factor.colorFlag)"
              ></v-progress-linear>
            </v-col>

            <v-col class="pa-1 d-flex align-center justify-center">
              <div
                class="subtitle-2"
                :class="{
                  'filyellow--text': factor.colorFlag == 'Y',
                  'error--text': factor.colorFlag == 'R',
                  'success--text': factor.colorFlag == 'G',
                }"
              >
                {{ factor.aScore }}
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="flex-1">
        <div class="border-wrapper pa-4 rounded">
          <div class="subtitle-1 font-weight-medium mb-4">Negative Factors</div>

          <v-row
            v-for="(factor, index) in report.negativeFactors"
            :key="`${index}-${factor.factorId}`"
            no-gutters
          >
            <v-col class="pa-1 d-flex align-center">
              <div class="subtitle-2">{{ factor.factorName }}</div>
            </v-col>

            <v-col class="pa-1 d-flex align-center justify-center">
              <v-progress-linear
                :value="factor.aScore"
                :color="getColor(factor.colorFlag)"
              ></v-progress-linear>
            </v-col>

            <v-col class="pa-1 d-flex align-center justify-center">
              <div
                class="subtitle-2"
                :class="{
                  'filyellow--text': factor.colorFlag == 'Y',
                  'error--text': factor.colorFlag == 'R',
                  'success--text': factor.colorFlag == 'G',
                }"
              >
                {{ factor.aScore }}
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      report: (state) => state.assignments.report,
    }),
  },
  methods: {
    getColor(color) {
      if (color == "Y") {
        return "filyellow";
      } else if (color == "R") {
        return "error";
      } else {
        return "success";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.border-wrapper {
  border: 1px solid #e0e0e0;
}
</style>
