<template>
  <div
    class="form-wrapper align-self-center mb-5"
    v-if="report.questionnaire.questionnaireTypeId == 'form'"
  >
    <div
      v-for="(section, index) in report.questionnaire.sections"
      :key="section.sectionId"
      :class="{
        'mb-10': index != report.questionnaire.sections.length - 1,
      }"
    >
      <div
        class="subtitle-1 font-weight-medium"
        :class="{
          'mb-1': section.sectionDescription,
          'mb-4': !section.sectionDescription,
        }"
      >
        {{ section.sectionName }}
      </div>

      <div class="body-2 ml-2 mb-3">{{ section.sectionDescription }}</div>

      <Questions
        :questions="section.questions"
        :reportQuestionnaire="reportQuestionnaire"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      report: (state) => state.assignments.report,
    }),
    reportQuestionnaire() {
      if (!this.report?.questionnaire) return {};

      const { sections, ...questionnaire } = this.report.questionnaire;

      return questionnaire;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
