<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(factor, index) in factors"
        :key="`${index}-${factor.factorId}`"
      >
        <v-expansion-panel-header>
          <div class="d-flex align-center">
            <div class="subtitle-2 mr-3">
              {{ factor.factorName }}
            </div>

            <div
              class="bullet caption"
              :class="{
                filyellow: factor.colorFlag == 'Y',
                error: factor.colorFlag == 'R',
                success: factor.colorFlag == 'G',
              }"
            >
              {{ factor.aScore }}
            </div>
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <div class="d-flex flex-column">
            <div class="body-2 mb-4">
              {{ factor.factorText }}
            </div>

            <v-row no-gutters>
              <v-col class="pa-1 d-flex align-center">
                <div class="subtitle-2">{{ factor.factorName }} Score</div>
              </v-col>

              <v-col class="pa-1 d-flex align-center justify-center">
                <v-progress-linear
                  :value="factor.aScore"
                  :color="getColor(factor.colorFlag)"
                ></v-progress-linear>
              </v-col>

              <v-col class="pa-1 d-flex align-center justify-center">
                <div
                  class="subtitle-2"
                  :class="{
                    'filyellow--text': factor.colorFlag == 'Y',
                    'error--text': factor.colorFlag == 'R',
                    'success--text': factor.colorFlag == 'G',
                  }"
                >
                  {{ factor.aScore }}
                </div>
              </v-col>
            </v-row>

            <div class="d-flex mt-1 align-self-start">
              <v-btn
                class="mr-2"
                small
                depressed
                color="primary"
                v-if="factor.factorAction"
                @click="dialog = { open: true, html: factor.factorAction }"
                >Actions</v-btn
              >

              <v-btn
                small
                depressed
                color="primary"
                v-if="factor.discussionPoints"
                @click="dialog = { open: true, html: factor.discussionPoints }"
                >Discussion Points</v-btn
              >
            </div>

            <template v-if="factor.subFactors && factor.subFactors.length">
              <v-divider class="my-4"></v-divider>

              <v-tabs vertical>
                <v-tab
                  v-for="(subfactor, index) in factor.subFactors"
                  :key="`${index}-${subfactor.factorId}`"
                >
                  <div class="d-flex align-center">
                    <div class="subtitle-2 mr-3">
                      {{ subfactor.factorName }}
                    </div>

                    <div
                      class="bullet caption"
                      :class="{
                        filyellow: subfactor.colorFlag == 'Y',
                        error: subfactor.colorFlag == 'R',
                        success: subfactor.colorFlag == 'G',
                      }"
                    >
                      {{ subfactor.aScore }}
                    </div>
                  </div>
                </v-tab>

                <v-tab-item
                  v-for="(subfactor, index) in factor.subFactors"
                  :key="`${index}-${subfactor.factorId}`"
                >
                  <div class="d-flex flex-column pa-5">
                    <div class="body-2 mb-4">{{ subfactor.factorText }}</div>

                    <v-row no-gutters>
                      <v-col class="pa-1 d-flex align-center">
                        <div class="subtitle-2">
                          {{ subfactor.factorName }} Score
                        </div>
                      </v-col>

                      <v-col class="pa-1 d-flex align-center justify-center">
                        <v-progress-linear
                          :value="subfactor.aScore"
                          :color="getColor(subfactor.colorFlag)"
                        ></v-progress-linear>
                      </v-col>

                      <v-col class="pa-1 d-flex align-center justify-center">
                        <div
                          class="subtitle-2"
                          :class="{
                            'filyellow--text': subfactor.colorFlag == 'Y',
                            'error--text': subfactor.colorFlag == 'R',
                            'success--text': subfactor.colorFlag == 'G',
                          }"
                        >
                          {{ subfactor.aScore }}
                        </div>
                      </v-col>
                    </v-row>

                    <div class="d-flex align-self-start mt-1">
                      <v-btn
                        class="mr-2"
                        mr-2
                        small
                        depressed
                        color="primary"
                        v-if="subfactor.factorAction"
                        @click="
                          dialog = { open: true, html: subfactor.factorAction }
                        "
                        >Actions</v-btn
                      >

                      <v-btn
                        small
                        depressed
                        color="primary"
                        v-if="subfactor.discussionPoints"
                        @click="
                          dialog = {
                            open: true,
                            html: subfactor.discussionPoints,
                          }
                        "
                        >Discussion Points</v-btn
                      >
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </template>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="dialog.open" max-width="1200px">
      <div class="white" v-if="dialog.open">
        <v-btn small icon class="mt-1 ml-1" @click="dialog.open = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <div class="pa-4 pt-2">
          <div v-html="dialog.html"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["factors"],
  data() {
    return {
      tab: 0,
      dialog: {
        open: false,
        html: null,
      },
    };
  },
  methods: {
    getColor(color) {
      if (color == "Y") {
        return "filyellow";
      } else if (color == "R") {
        return "error";
      } else {
        return "success";
      }
    },
  },
  watch: {
    "dialog.open"(newValue) {
      if (!newValue) this.dialog.html = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
