<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-text-field
        dense
        outlined
        hide-details
        label="Street"
        class="mb-4"
        :value="contactDetailsForm.street"
        @input="setContactDetailsForm({ value: $event, key: 'street' })"
      ></v-text-field>

      <v-text-field
        dense
        outlined
        hide-details
        label="Number"
        type="number"
        class="mb-4"
        :value="contactDetailsForm.streetNumber"
        @input="setContactDetailsForm({ value: $event, key: 'streetNumber' })"
      ></v-text-field>

      <v-text-field
        dense
        outlined
        hide-details
        label="City"
        class="mb-4"
        :value="contactDetailsForm.city"
        @input="setContactDetailsForm({ value: $event, key: 'city' })"
      ></v-text-field>

      <v-text-field
        dense
        outlined
        hide-details
        label="Region"
        class="mb-4"
        :value="contactDetailsForm.region"
        @input="setContactDetailsForm({ value: $event, key: 'region' })"
      ></v-text-field>

      <v-select
        dense
        outlined
        hide-details
        label="Country"
        item-text="itemValue"
        item-value="itemKey"
        class="mb-4"
        :items="countries"
        :value="contactDetailsForm.country"
        @input="setContactDetailsForm({ value: $event, key: 'country' })"
      ></v-select>

      <v-text-field
        dense
        outlined
        hide-details
        label="Zip Code"
        :value="contactDetailsForm.zipCode"
        @input="setContactDetailsForm({ value: $event, key: 'zipCode' })"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        dense
        outlined
        hide-details
        label="Mobile"
        class="mb-4"
        :value="contactDetailsForm.mobile"
        @input="setContactDetailsForm({ value: $event, key: 'mobile' })"
      ></v-text-field>

      <v-text-field
        dense
        outlined
        hide-details
        label="Phone"
        class="mb-4"
        :value="contactDetailsForm.phone"
        @input="setContactDetailsForm({ value: $event, key: 'phone' })"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["selectedUser", "v", "platformAccess"],
  data() {
    return {
      contactDetailsForm: {
        street: null,
        streetNumber: null,
        city: null,
        region: null,
        country: null,
        zipCode: null,
        mobile: null,
        phone: null,
      },
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.base.lists.countries,
    }),
  },
  created() {
    if (this.selectedUser) {
      this.contactDetailsForm.street = this.selectedUser.addressStreet;
      this.contactDetailsForm.streetNumber =
        this.selectedUser.addressStreetNumber;
      this.contactDetailsForm.city = this.selectedUser.addressCity;
      this.contactDetailsForm.region = this.selectedUser.addressRegion;
      this.contactDetailsForm.country = this.selectedUser.addressCountryId;
      this.contactDetailsForm.zipCode = this.selectedUser.addressZIP;
      this.contactDetailsForm.mobile = this.selectedUser.mobile;
      this.contactDetailsForm.phone = this.selectedUser.phone;
    }

    this.$emit("contactDetailsChanged", this.contactDetailsForm);
  },
  methods: {
    setContactDetailsForm(field) {
      this.contactDetailsForm[field.key] = field.value;
      this.$emit("contactDetailsChanged", this.contactDetailsForm);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
