<template>
  <div class="white dialog-wrapper scrollable-dialog">
    <div class="primary pa-4 d-flex align-start">
      <div class="text-h6 white--text">
        {{
          isEdit && currentNotification
            ? currentNotification.title
            : "Add new Notification"
        }}
      </div>

      <v-btn
        icon
        small
        color="white"
        class="ml-auto"
        @click="$emit('closeDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-progress-circular
      size="80"
      width="4"
      indeterminate
      color="primary"
      v-if="loader"
      class="ma-auto"
    ></v-progress-circular>

    <template v-else>
      <component
        :is="activeComponent"
        :isEdit="isEdit"
        @closeDialog="$emit('closeDialog', $event)"
      />
    </template>
  </div>
</template>
  
  <script>
import NotificationData from "@/views/admin/notifications/notification-dialog/NotificationData";
import NotificationStatus from "@/views/admin/notifications/notification-dialog/NotificationStatus";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: ["isEdit", "notification", "preselectedUsers"],
  components: {
    NotificationData,
    NotificationStatus,
  },
  data() {
    return {
      tab: "NotificationStatus",
      loader: false,
    };
  },
  computed: {
    ...mapState({
      form: (state) => state.notifications.form,
      currentNotification: (state) => state.notifications.notification,
      role: (state) => state.auth.role,
    }),
    activeComponent() {
      if (!this.isEdit) return "NotificationData";

      return this.tab;
    },
  },
  created() {
    if (this.isEdit) {
      this.onGetNotification(true);
    }
  },
  methods: {
    ...mapMutations(["clearAssignmentDialogState"]),
    ...mapActions(["getNotification"]),
    async onGetNotification(fetch) {
      if (!fetch) return;

      this.loader = true;

      try {
        await this.getNotification(this.notification.id);
      } catch (e) {
        this.$emit("closeDialog");
        console.log(e);
      }

      this.loader = false;
    },
  },
  destroyed() {
    this.clearAssignmentDialogState();
  },
};
</script>
  
  <style lang="scss" scoped>
</style>
  