<template>
  <div class="component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column">
    <div class="body-1 font-weight-medium mb-5">Questionnaires</div>

    <div class="wrapper d-flex flex-column rounded pa-4 flex-grow-1">
      <v-tabs class="flex-grow-0 mb-4">
        <v-tab
          v-for="type in questionnaireTypes"
          :key="type.questionnaireTypeId"
          :disabled="globalLoader"
          :to="`/superadmin/questionnaires/${type.questionnaireTypeId}`"
        >
          {{ type.questionnaireTypeName }}
        </v-tab>
      </v-tabs>

      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      globalLoader: (state) => state.base.globalLoader,
      questionnaireTypes: (state) => state.base.questionnaires,
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
