<template>
  <div class="wrapper rounded flex-grow-1 d-flex flex-column">
    <div
      class="d-flex flex-column flex-md-row align-center align-md-start justify-start pa-5 pb-1"
    >
      <v-menu
        offset-y
        v-if="selectedUsers.length"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            depressed
            class="mb-4 mb-md-0 mr-md-4"
            v-bind="attrs"
            v-on="on"
          >
            Actions
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item link @click="onAddToAssignment">
            <v-list-item-title>Add to Assignment</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            @click="dialog = { open: true, component: 'AssignGroupDialog' }"
          >
            <v-list-item-title>Assign to group</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            @click="dialog = { open: true, component: 'InviteToOnTheGo' }"
          >
            <v-list-item-title>Invite to OnTheGo</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            @click="dialog = { open: true, component: 'RemindInvitation' }"
          >
            <v-list-item-title>Remind Invitations</v-list-item-title>
          </v-list-item>

          <v-list-item link @click="onExportExcel">
            <v-list-item-title>Export Excel</v-list-item-title>

            <v-progress-circular
              size="20"
              width="2"
              indeterminate
              color="primary"
              v-if="exportExcelLoader"
            ></v-progress-circular>
          </v-list-item>
        </v-list>
      </v-menu>

      <div class="mr-md-5 mb-1 d-flex align-start">
        <v-text-field
          outlined
          dense
          label="Search"
          class="mr-2"
          prepend-inner-icon="mdi-magnify"
          hint="Search by Identity, Last Name, First Name, Email, Mobile"
          persistent-hint
          :value="usersParams.search"
          @input="setUserParams({ search: $event })"
          :readonly="getUsersLoader"
        ></v-text-field>

        <v-btn
          icon
          color="primary"
          v-if="usersParams.search"
          @click="setUserParams({ search: '' })"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="d-flex flex-column flex-sm-row ml-md-auto mb-2 mb-md-0">
        <ShowPerPage
          class="mr-2"
          :currentPerPage="usersParams.itemsPerPage"
          :options="showPerPageOptions"
        />

        <FilterBy
          class="mr-sm-4 mb-4 mb-sm-0"
          :fields="filterFields"
          :loader="getUsersLoader"
          @filterChange="onFilterChange"
        />

        <ColumnVisibility
          :headers="headers"
          @visibilityChanged="onVisibilityChanged"
          @initLoaderChanged="onVisibilityLoaderChanged"
          grid="usersList"
        />

        <!-- <v-btn class="mr-sm-4 mb-4 mb-sm-0" depressed>
          <v-icon left>mdi-cloud-download</v-icon>
          CSV
        </v-btn>

        <v-btn depressed>
          <v-icon left>mdi-cloud-download</v-icon>
          PDF
        </v-btn> -->
      </div>
    </div>

    <v-data-table
      :headers="visibleHeaders"
      :items="!getUsersLoader && !visibilityLoader ? users.results : []"
      :server-items-length="users.rowCount"
      class="clickable"
      show-select
      checkbox-color="primary"
      v-model="selectedUsers"
      @click:row="
        dialog = {
          open: true,
          component: 'UserDialog',
          user: $event.clientUserId,
        }
      "
      :loading="getUsersLoader || visibilityLoader"
      @update:options="onGetUsers"
      item-key="clientUserId"
      :options="options"
      hide-default-footer
    >
      <template v-slot:[`item.creationDate`]="{ item }">
        {{
          item.creationDate
            ? dayjs(item.creationDate).format("DD/MM/YYYY")
            : "-"
        }}
      </template>

      <template v-slot:[`item.dateOfBirth`]="{ item }">
        {{
          item.dateOfBirth ? dayjs(item.dateOfBirth).format("DD/MM/YYYY") : "-"
        }}
      </template>

      <template v-slot:[`item.userEmail`]="{ item }">
        {{ item.userEmail ? item.userEmail : "-" }}
      </template>

      <template v-slot:[`item.gender`]="{ item }">
        <span v-if="item.gender == 'm'">Male</span>
        <span v-else-if="item.gender == 'f'">Female</span>
        <span v-else-if="item.gender == 'o'">Other</span>
        <span v-else>-</span>
      </template>

      <template v-slot:[`item.marital`]="{ item }">
        {{
          item.marital
            ? item.marital.charAt(0).toUpperCase() + item.marital.slice(1)
            : "-"
        }}
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        {{ item.mobile ? item.mobile : "-" }}
      </template>

      <template v-slot:[`item.canGetAssignments`]="{ item }">
        <div class="bullet small filgreen" v-if="item.canGetAssignments"></div>

        <div class="bullet small filred" v-else></div>
      </template>

      <template v-slot:[`item.onTheGoPin`]="{ item }">
        <v-menu
          offset-x
          offset-y
          top
          left
          :close-on-content-click="false"
          v-if="item.onTheGoPin"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" small> Show Pin </v-btn>
          </template>

          <div class="white py-1 px-2">
            <div class="subtitle-2">
              {{ item.onTheGoPin }}
            </div>
          </div>
        </v-menu>

        <div class="body-2" v-else>-</div>
      </template>

      <template v-slot:[`item.onTheGoSelfSupport`]="{ item }">
        <div class="bullet small filgreen" v-if="item.onTheGoSelfSupport"></div>

        <div class="bullet small filred" v-else></div>
      </template>

      <template v-slot:[`item.onTheGoInvitationStatus`]="{ item }">
        <div class="d-flex justify-start">
          <div
            class="px-1 rounded"
            :class="{
              'success white--text': item.onTheGoInvitationStatus == 'Click',
              'warning lighten-2 white--text':
                item.onTheGoInvitationStatus == 'Open' ||
                item.onTheGoInvitationStatus == 'Delivery',
              'warning white--text':
                item.onTheGoInvitationStatus == 'Sent' ||
                item.onTheGoInvitationStatus == 'Delivery' ||
                item.onTheGoInvitationStatus == 'Dispatched',
              'error white--text':
                item.onTheGoInvitationStatus == 'Bounce' ||
                item.onTheGoInvitationStatus == 'DispatchError',
              'primary white--text': item.onTheGoInvitationStatus == 'Login',
            }"
          >
            {{
              item.onTheGoInvitationStatus ? item.onTheGoInvitationStatus : "-"
            }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.delete`]="{ item, index }">
        <v-menu
          top
          left
          :close-on-content-click="false"
          offset-y
          offset-x
          :ref="`deleteMenu${index}`"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              depressed
              x-small
              v-bind="attrs"
              v-on="on"
              :disabled="Boolean(item.numAssignments)"
              >Delete</v-btn
            >
          </template>

          <div class="white pa-5">
            <div class="body-2 mb-4 error--text">
              Are you sure you want to delete this user?
            </div>

            <v-btn
              small
              depressed
              color="error"
              @click="onDeleteUser(item, index)"
              :loading="deleteUserLoader == item.clientUserId"
              >Delete</v-btn
            >
          </div>
        </v-menu>
      </template>
    </v-data-table>

    <div class="pagination-wrapper py-3 mt-auto">
      <v-pagination
        :length="users.pageCount"
        v-model="usersParams.page"
        :disabled="getUsersLoader"
        v-if="users.pageCount"
      ></v-pagination>
    </div>

    <v-dialog
      v-model="dialog.open"
      :max-width="dialog.component == 'delete' ? '500px' : '1250px'"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly && dialog.component != 'delete'"
    >
      <component
        :is="dialog.component"
        @closeDialog="onDialogClosed"
        @unselectUser="onUnselectUser"
        :selectedUsers="selectedUsers"
        :selectedUserId="dialog.user"
        :isEdit="false"
      ></component>
    </v-dialog>
  </div>
</template>

<script>
import AssignGroupDialog from "@/views/admin/users/AssignGroupDialog";
import UserDialog from "@/views/admin/users/user-dialog/UserDialog";
import ColumnVisibility from "@/components/ColumnVisibility";
import FilterBy from "@/components/FilterBy";
import AssignmentDialog from "@/views/admin/assignment/assignment-dialog/AssignmentDialog";
import InviteToOnTheGo from "@/views/admin/users/InviteToOnTheGo";
import RemindInvitation from "@/views/admin/users/RemindInvitation";
import { mapActions, mapMutations, mapState } from "vuex";
import ShowPerPage from "@/components/ShowPerPage";

export default {
  props: ["selectedGroups", "union"],
  components: {
    AssignGroupDialog,
    UserDialog,
    ColumnVisibility,
    FilterBy,
    AssignmentDialog,
    InviteToOnTheGo,
    RemindInvitation,
    ShowPerPage,
  },
  data() {
    return {
      headers: [
        { text: "Creation Date", value: "creationDate" },
        { text: "Identity", value: "identityNumber" },
        { text: "Last Name", value: "lastName" },
        { text: "First Name", value: "firstName" },
        { text: "Birth Date", value: "dateOfBirth" },
        { text: "Email", value: "userEmail" },
        { text: "Gender", value: "gender" },
        { text: "Nationality", value: "nationalityName" },
        { text: "Language", value: "languageName" },
        { text: "Role", value: "platformAccessName" },
        { text: "Position", value: "positionName" },
        { text: "Marital Status", value: "marital" },
        { text: "Mobile", value: "mobile", sortable: false },
        { text: "Enabled", value: "canGetAssignments" },
        { text: "OnTheGo Pin", value: "onTheGoPin" },
        {
          text: "OnTheGo Self Support",
          value: "onTheGoSelfSupport",
          sortable: false,
        },
        {
          text: "OnTheGo Email",
          value: "onTheGoInvitationStatus",
          sortable: false,
        },
        { text: "Delete", value: "delete" },
      ],
      visibleHeaders: [],
      dialog: {
        open: false,
        component: null,
      },
      selectedUsers: [],
      timeout: null,
      visibilityLoader: false,
      deleteUserLoader: false,
      exportExcelLoader: false,
      showPerPageOptions: [],
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.users.users,
      usersParams: (state) => state.users.usersParams,
      getUsersLoader: (state) => state.users.getUsersLoader,
      countries: (state) => state.base.lists.countries,
      platformAccesses: (state) => state.base.lists.platformAccesses,
      languages: (state) => state.base.lists.languages,
      genders: (state) => state.base.lists.genders,
      userTypes: (state) => state.base.lists.userTypes,
      emailStatuses: (state) => state.base.lists.emailStatuses,
    }),
    mergedProps() {
      return `${this.selectedGroups}|${this.union}`;
    },

    options() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.usersParams;

      return {
        itemsPerPage,
        page,
        sortBy,
        sortDesc,
      };
    },
    filterFields() {
      return [
        {
          key: "nationalityId",
          value: "Nationality",
          values: this.countries,
          selectedValue: this.usersParams.nationalityId,
        },
        {
          key: "platformAccessId",
          value: "Role",
          values: this.platformAccesses,
          selectedValue: this.usersParams.platformAccessId,
        },
        {
          key: "languageId",
          value: "Language",
          values: this.languages,
          selectedValue: this.usersParams.languageId,
        },
        {
          key: "gender",
          value: "Gender",
          values: this.genders,
          selectedValue: this.usersParams.gender,
        },
        {
          key: "onTheGoInvitationStatus",
          value: "OnTheGo Email",
          values: this.emailStatuses,
          selectedValue: this.usersParams.onTheGoInvitationStatus,
        },
        {
          key: "position",
          value: "Position",
          checkboxValues: this.userTypes,
          selectedValue: this.usersParams.position,
        },
      ];
    },
  },
  created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setUserParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setUserParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setUserParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setUserParams,
      },
    ];
  },
  methods: {
    ...mapActions(["getUsers", "deleteUser", "exportUsersExcel"]),
    ...mapMutations([
      "setUserParams",
      "setForm",
      "togglePreselectedUsersBanner",
    ]),
    async onGetUsers(event) {
      if (this.getUsersLoader) return;

      let { itemsPerPage, page, sortBy, sortDesc } = event || {};

      if (event) {
        this.setUserParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      } //this happens because this method is called from either @update:options or selectedGroups watcher or union wathcer

      try {
        await this.getUsers();
      } catch (e) {
        console.log(e);
      }
    },
    onVisibilityChanged(visibleHeaders) {
      this.visibleHeaders = visibleHeaders
        .filter((header) => header.visible)
        .map((header) => {
          return {
            text: header.text,
            value: header.value,
            ...(typeof header.sortable === "boolean" &&
              !header.sortable && {
                sortable: header.sortable,
              }),
          };
        });
    },
    onVisibilityLoaderChanged(loader) {
      this.visibilityLoader = loader;
    },
    onUnselectUser(index) {
      this.selectedUsers.splice(index, 1);
    },
    onDialogClosed(event) {
      if (event?.unselectUsers) {
        this.selectedUsers = [];

        this.setUserParams({
          itemsPerPage: 10,
        });

        this.onGetUsers();
      }

      this.dialog = { open: false, component: null };
    },
    onFilterChange(e) {
      this.setUserParams({
        [e.field]: e.value,
      });

      this.onGetUsers();
    },
    onAddToAssignment() {
      this.setForm({
        key: "assigneeUserIds",
        value: this.selectedUsers.map((user) => {
          return {
            clientUserId: user.clientUserId,
            identityNumber: user.identityNumber,
            firstName: user.firstName,
            lastName: user.lastName,
            userEmail: user.userEmail,
          };
        }),
      });

      this.togglePreselectedUsersBanner(true);

      this.dialog = { open: true, component: "AssignmentDialog" };
    },
    async onDeleteUser(user, index) {
      this.deleteUserLoader = user.clientUserId;

      try {
        await this.deleteUser(user);
        this.onGetUsers();
      } catch (e) {
        console.log(e);
      }

      this.$refs[`deleteMenu${index}`].save();
      this.deleteUserLoader = false;
    },
    async onExportExcel() {
      this.exportExcelLoader = true;

      try {
        await this.exportUsersExcel(
          this.selectedUsers.map((u) => u.clientUserId)
        );
        this.selectedUsers = [];
      } catch (e) {
        console.log(e);
      }

      this.exportExcelLoader = false;
    },
  },
  watch: {
    mergedProps(newValue, oldValue) {
      const [oldPropertyA, oldPropertyB] = oldValue.split("|");
      const [newPropertyA, newPropertyB] = newValue.split("|");

      // console.log("selectedGroupsOld:" + oldPropertyA);

      // console.log("unionOld:" + oldPropertyB);
      // console.log("unionNew:" + newPropertyB);
      const splitNewProperty =
        newPropertyA != "" ? newPropertyA.split(",") : [];

      this.setUserParams({
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
        selectedGroups: splitNewProperty,
        union: newPropertyB,
      });

      this.onGetUsers();
    },

    "usersParams.search"() {
      clearTimeout(this.timeout);

      if (this.getUsersLoader) return;

      this.timeout = setTimeout(() => {
        this.setUserParams({
          page: 1,
        });

        this.onGetUsers();
      }, 500);
    },
  },
  destroyed() {
    this.setUserParams({
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: [],
      search: "",
      selectedGroups: [],
      groupId: null,
      union: "all",
      nationalityId: null,
      platformAccessId: null,
      languageId: null,
      gender: null,
      position: {
        positionType: null,
        positionId: null,
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.bullet {
  &:not(:last-of-type) {
    margin-right: 4px;
  }

  &.small {
    height: 16px;
    width: 16px;
  }
}
</style>
