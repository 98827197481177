<template>
  <div>
    <div class="d-flex align-center mb-2">
      <v-select
        outlined
        hide-details
        dense
        label="Add Group Managers"
        class="mr-5"
        :items="possibleGroupManagers"
        item-value="clientUserId"
        :item-text="
          (item) =>
            `${item.lastName} ${item.firstName} | ${item.identityNumber}`
        "
        :loading="getPossibleGroupManagersLoader"
        multiple
        v-model="selectedGroupManagers"
        chips
        small-chips
      ></v-select>

      <v-btn
        depressed
        color="success"
        :disabled="!selectedGroupManagers.length"
        :loading="addLoader"
        @click="onAddGroupManagersToGroup"
      >
        <v-icon left> mdi-plus </v-icon>
        Add
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="groupManagers"
      :loading="getGroupManagersLoader"
      hide-default-footer
    >
      <template v-slot:[`item.firstName`]="{ item }">
        {{ item.lastName }} {{ item.firstName }}
      </template>

      <template v-slot:[`item.remove`]="{ item }">
        <v-menu
          top
          left
          :close-on-content-click="false"
          offset-y
          offset-x
          v-model="item.deleteMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed color="error" x-small v-bind="attrs" v-on="on">
              Remove</v-btn
            >
          </template>

          <div class="white pa-5">
            <div class="body-2 mb-4 error--text">
              Are you sure you want to remove group manager {{ item.lastName }}
              {{ item.firstName }} <br />
              from group {{ selectedGroup.groupName }}?
            </div>

            <v-btn
              small
              depressed
              color="error"
              @click="onRemoveGroupManagersFromGroup(item.clientUserId)"
              :loading="removeLoader"
              >Remove from Group</v-btn
            >
          </div>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["selectedGroup"],
  data() {
    return {
      headers: [
        { text: "Name", value: "firstName", sortable: false },
        { text: "Identity Number", value: "identityNumber", sortable: false },
        { text: "Email", value: "userEmail", sortable: false },
        { text: "Remove", value: "remove", sortable: false },
      ],
      groupManagers: [],
      possibleGroupManagers: [],
      getGroupManagersLoader: false,
      getPossibleGroupManagersLoader: false,
      selectedGroupManagers: [],
      addLoader: false,
      removeLoader: false,
    };
  },
  created() {
    this.onGetGroupManagersOfGroup();
    this.onGetPossibleGroupManagers();
  },
  methods: {
    ...mapActions([
      "getPossibleGroupManagers",
      "getGroupManagersOfGroup",
      "addGroupManagersToGroup",
      "removeGroupManagersFromGroup",
    ]),
    async onGetGroupManagersOfGroup() {
      this.getGroupManagersLoader = true;

      try {
        this.groupManagers = await this.getGroupManagersOfGroup(
          this.selectedGroup.groupId
        );

        this.groupManagers = this.groupManagers.map((manager) => {
          return {
            ...manager,
            deleteMenu: false,
          };
        });
      } catch (e) {
        console.log(e);
      }

      this.getGroupManagersLoader = false;
    },
    async onGetPossibleGroupManagers() {
      this.getPossibleGroupManagersLoader = true;

      try {
        this.possibleGroupManagers = await this.getPossibleGroupManagers(
          this.selectedGroup.groupId
        );
      } catch (e) {
        console.log(e);
      }

      this.getPossibleGroupManagersLoader = false;
    },
    async onAddGroupManagersToGroup() {
      this.addLoader = true;

      try {
        await this.addGroupManagersToGroup({
          groupId: this.selectedGroup.groupId,
          startDate: new Date().toISOString(),
          addUserIds: this.selectedGroupManagers,
        });

        this.selectedGroupManagers = [];

        this.onGetGroupManagersOfGroup();
        this.onGetPossibleGroupManagers();
      } catch (e) {
        console.log(e);
      }

      this.addLoader = false;
    },
    async onRemoveGroupManagersFromGroup(userId) {
      this.removeLoader = true;

      try {
        await this.removeGroupManagersFromGroup({
          groupId: this.selectedGroup.groupId,
          endDate: new Date().toISOString(),
          userId,
        });

        this.onGetGroupManagersOfGroup();
        this.onGetPossibleGroupManagers();
      } catch (e) {
        console.log(e);
      }

      this.removeLoader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
