var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.role != 'filistos')?_c('div',{staticClass:"d-flex align-start mb-2"},[_c('v-select',{staticClass:"mr-1",attrs:{"outlined":"","dense":"","label":"Add Group","items":_vm.allGroups,"item-value":"groupId","item-text":"groupName","readonly":_vm.addGroupLoader,"error-messages":_vm.addGroupError ? 'User already exists in this group' : ''},on:{"change":function($event){_vm.addGroupError = false}},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}}),_c('v-btn',{attrs:{"depressed":"","color":"success","disabled":!_vm.selectedGroup,"loading":_vm.addGroupLoader},on:{"click":_vm.onAddGroup}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add ")],1)],1):_vm._e(),_c('v-data-table',{staticClass:"mb-5",attrs:{"headers":_vm.headers,"items":_vm.currentUser.groups,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"top":"","left":"","close-on-content-click":false,"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(item.deleteMenu),callback:function ($$v) {_vm.$set(item, "deleteMenu", $$v)},expression:"item.deleteMenu"}},[_c('div',{staticClass:"white pa-5"},[_c('div',{staticClass:"body-2 mb-4 error--text"},[_vm._v(" Are you sure you want to remove user "+_vm._s(_vm.currentUser.firstName)+" "+_vm._s(_vm.currentUser.lastName)+" from group "+_vm._s(item.groupName)+"? ")]),_c('v-btn',{attrs:{"small":"","depressed":"","color":"error","loading":_vm.loader},on:{"click":function($event){return _vm.onRemoveUserFromGroup(item)}}},[_vm._v("Remove from Group")])],1)])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs(item.startDate).format("DD/MM/YYYY"))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }