import axios from "axios";
import dayjs from "dayjs";

export default {
  state: {
    //new assignment
    form: {
      assignmentName: null,
      dateToSend: null,
      dateToRemind: null,
      dateToEnd: null,
      sendTypeId: null,
      usersToNotifyIds: [],
      questionnaireIds: [],
      parentQuestionnaires: [],
      assigneeUserIds: [],
      hasAssessor: false,
      assessorId: null,
    },
    currentAssignment: null,
    initialData: null,
    getAssignmentOfUserLoader: false,
    //assignments list
    assignments: {
      results: [],
      rowCount: 0,
    },
    assignmentsParams: {
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: [],
      search: "",
    },
    getAssignmentsLoader: false,
    assignmentOfUser: null,
    report: null,
    showReport: {
      open: false,
      id: null,
      index: null,
    },
    possitbleUsersToNotify: [],
    preselectedUsersBanner: false,
    preselectedQuestionnairesBanner: false,
    isFormInitialized: false,
  },
  getters: {
    questionnaireIdsMapped(state) {
      return state.form.questionnaireIds.map((questionnaire) => {
        return questionnaire.questionnaireId;
      });
    },
    assigneeUserIdsMapped(state) {
      return state.form.assigneeUserIds.map((user) => {
        return user.clientUserId;
      });
    },
  },
  mutations: {
    //new assignment
    setForm(state, field) {
      state.form[field.key] = field.value;
    },
    setInitialData(state, initialData) {
      state.initialData = initialData;
    },
    getAssignment(state, assignment) {
      state.currentAssignment = assignment;
    },
    toggleGetAssignmentOfUserLoader(state, loader) {
      state.getAssignmentOfUserLoader = loader;
    },
    //assignments list
    getAssignments(state, assignments) {
      state.assignments = assignments;
    },
    setAssignmentsParams(state, params) {
      state.assignmentsParams = {
        ...state.assignmentsParams,
        ...params,
      };
    },
    toggleGetAssignmentsLoader(state, loader) {
      state.getAssignmentsLoader = loader;
    },
    getAssignmentOfUser(state, assignmentOfUser) {
      state.assignmentOfUser = assignmentOfUser;
    },
    getReport(state, report) {
      state.report = report;
    },
    toggleShowReport(state, report) {
      state.showReport = {
        ...state.showReport,
        ...report,
      };
    },
    getPossibleUsersToNotify(state, users) {
      state.possitbleUsersToNotify = users;
    },
    togglePreselectedUsersBanner(state, banner) {
      state.preselectedUsersBanner = banner;
    },
    togglePreselectedQuestionnairesBanner(state, banner) {
      state.preselectedQuestionnairesBanner = banner;
    },
    toggleIsFormInitialized(state, initialized) {
      state.isFormInitialized = initialized;
    },
    clearAssignmentDialogState(state) {
      state.form = {
        assignmentName: null,
        dateToSend: null,
        dateToRemind: null,
        dateToEnd: null,
        sendTypeId: null,
        usersToNotifyIds: null,
        questionnaireIds: [],
        parentQuestionnaires: [],
        assigneeUserIds: [],
        hasAssessor: false,
        assessorId: null,
      };
      state.currentAssignment = null;
      state.initialData = null;
      state.getAssignmentOfUserLoader = false;
      state.getAssignmentsLoader = false;
      state.assignmentOfUser = [];
      state.report = null;
      state.showReport = {
        open: false,
        id: null,
        index: null,
      };
      state.possitbleUsersToNotify = [];
      state.preselectedUsersBanner = false;
      state.preselectedQuestionnairesBanner = false;
      state.isFormInitialized = false;
    },
    clearAssignmentsListState(state) {
      state.assignments = {
        results: [],
        rowCount: 0,
      };

      state.assignmentsParams = {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
        search: "",
      };

      state.getAssignmentsLoader = false;
    },
  },
  actions: {
    async addAssignment({ commit, getters, state, dispatch }) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/AddAssignment`,
          {
            ...state.form,
            sendTypeId: state.form.sendTypeId.itemKey,
            dateToSend: dayjs.utc(state.form.dateToSend).toISOString(),
            dateToRemind: dayjs.utc(state.form.dateToRemind).toISOString(),
            dateToEnd: dayjs.utc(state.form.dateToEnd).toISOString(),
            questionnaireIds: getters.questionnaireIdsMapped,
            assigneeUserIds: getters.assigneeUserIdsMapped,
            usersToNotifyIds: state.form.usersToNotifyIds.map(
              (user) => user.clientUserId
            ),
            assessorId: state.form.hasAssessor
              ? state.form.assessorId
              : undefined,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/vnd.ms-excel",
            },
          }
        );

        if (state.form.sendTypeId.itemKey == "manager") {
          const filename = res.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0];

          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          var fileURL = window.URL.createObjectURL(blob);
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        }

        await dispatch("getInfo");

        dispatch("getAssignments");

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Assignment has been sent!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async getAssignments({ commit, state, rootState }) {
      try {
        const role = rootState.auth.role;

        commit("toggleGetAssignmentsLoader", true);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${
            role == "co" ? "ClientAdmin" : "GroupManager"
          }/GetAssignments`,
          {
            params: {
              pageSize:
                state.assignmentsParams.itemsPerPage != -1
                  ? state.assignmentsParams.itemsPerPage
                  : 1000,
              pageNo: state.assignmentsParams.page,
              ...(state.assignmentsParams.search && {
                search: state.assignmentsParams.search,
              }),
            },
          }
        );

        commit("getAssignments", res.data);

        commit("toggleGetAssignmentsLoader", false);
      } catch (e) {
        commit("toggleGetAssignmentsLoader", false);

        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async getAssignment({ commit, rootState }, assignmentId) {
      const role = rootState.auth.role;

      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${
            role == "co" ? "ClientAdmin" : "GroupManager"
          }/GetAssignment/${assignmentId}`
        );

        commit("getAssignment", res.data);
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async updateAssignment({ commit, state, dispatch }) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/EditAssignment`,
          {
            assignmentId: state.currentAssignment.assignmentId,
            dateToRemind: dayjs.utc(state.form.dateToRemind).toISOString(),
            dateToEnd: dayjs.utc(state.form.dateToEnd).toISOString(),
            usersToNotifyIds: state.form.usersToNotifyIds.map(
              (user) => user.clientUserId
            ),
          }
        );

        dispatch("getAssignments");

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Assignment has been updated!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async getAssignmentOfUser({ commit, state }, payload) {
      try {
        commit("toggleGetAssignmentOfUserLoader", true);

        const assignmentId = payload?.assignmentId
          ? payload?.assignmentId
          : state.currentAssignment.assignmentId;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/GetUserAssignment/${assignmentId}/${payload.userId}`
        );

        commit("toggleGetAssignmentOfUserLoader", false);

        commit("getAssignmentOfUser", res.data);
      } catch (e) {
        commit("toggleGetAssignmentOfUserLoader", false);

        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async getReport({ commit, rootState }, payload) {
      const role = rootState.auth.role;

      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${
            role == "filistos" ? "SuperAdmin" : "ClientAdmin"
          }/GetReportOfUser/${payload.reportId}${
            payload.lang == "el" ? "/el" : ""
          }`
        );
        commit("getReport", res.data.result);
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async remindAssignment({ commit }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientUser/RemindAssignment`,
          payload
        );

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "User has been reminded about his assignment.",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async getPossibleUsersToNotify({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/ClientUser/GetPossibleUsersToNotify`
        );

        commit("getPossibleUsersToNotify", res.data.results);
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async getReportPdf({ commit }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/ClientUser/GetReportPdf/${
            payload.pdfType
          }/${payload.id}${payload.lang == "el" ? "/el" : ""}`,
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/pdf",
            },
          }
        );

        const filename = res.headers["content-disposition"].split(
          "filename="
        )[1];

        let blob = new Blob([res.data], { type: "application/pdf" });
        var fileURL = window.URL.createObjectURL(blob);
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
  },
};
