import axios from 'axios';

export default {
    state: {
        scheduleParams: {
            itemsPerPage: 10,
            page: 1,
            sortBy: [],
            sortDesc: [],
            questionnaire: null,
            dateBefore: null,
            nationalityId: null,
            position: {
                positionType: null,
                positionId: null
            },
            groupId: null
        },
        schedule: {
            results: [],
            rowCount: 0
        },
        scheduleFetched: null,
    },
    mutations: {
        setScheduleParams(state, params) {
            state.scheduleParams = {
                ...state.scheduleParams,
                ...params
            }
        },
        getSchedule(state, schedule) {
            state.schedule = schedule;
        },
        toggleScheduleFetched(state, fetched) {
            state.scheduleFetched = fetched;
        },
        clearScheduleState(state) {
            state.scheduleParams = {
                itemsPerPage: 10,
                page: 1,
                sortBy: [],
                sortDesc: [],
                questionnaire: null,
                dateBefore: null,
                nationalityId: null,
                position: {
                    positionType: null,
                    positionId: null
                },
                groupId: null
            };

            state.schedule = {
                results: [],
                rowCount: 0
            };

            state.scheduleFetched = false;
        }
    },
    actions: {
        async getSchedule({ commit, state, rootState }) {
            const role = rootState.auth.role;

            try {
                const res = await axios.get(
                    `${process.env.VUE_APP_BASE_URL}/${role == 'co' ? 'ClientAdmin' : 'GroupManager'}/GetPossibleUsersToEvaluate`,
                    {
                        params: {
                            pageSize: state.scheduleParams.itemsPerPage != -1 ? state.scheduleParams.itemsPerPage : 1000,
                            pageNo: state.scheduleParams.page,
                            ...(state.scheduleParams.sortBy[0] && { sortBy: state.scheduleParams.sortBy[0] }),
                            ...(state.scheduleParams.sortDesc[0] && { dir: "desc" }),
                            ...(state.scheduleParams.questionnaire.questionnaireId && { questionnaireId: state.scheduleParams.questionnaire.questionnaireId }),
                            ...(state.scheduleParams.dateBefore && { dateBefore: state.scheduleParams.dateBefore }),
                            ...(state.scheduleParams.nationalityId && { nationalityId: state.scheduleParams.nationalityId }),
                            ...(state.scheduleParams.position.positionId && { workPositionId: state.scheduleParams.position.positionId }),
                            ...(state.scheduleParams.groupId && { groupId: state.scheduleParams.groupId }),
                        }
                    }
                );

                commit("getSchedule", res.data);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async exportScheduleExcel({ commit }, payload) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/clientUser/DownloadUsersToEvaluateExcel`, {
                    ...payload
                },
                    {
                        responseType: 'arraybuffer',
                        headers: {
                            'Accept': 'application/vnd.ms-excel'
                        }
                    });

                const filename = res.headers["content-disposition"].split("filename=")[1].split(";")[0];

                let blob = new Blob([res.data], { type: "application/vnd.ms-excel" })
                var fileURL = window.URL.createObjectURL(blob);
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', filename);
                document.body.appendChild(fileLink);
                fileLink.click();

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Excel file has been exported!",
                    timeout: 5000
                })
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        }
    }
}
