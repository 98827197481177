<template>
  <div class="wrapper d-flex flex-column rounded flex-grow-1 pb-2">
    <div class="d-flex align-center pa-2">
      <v-menu
        offset-y
        v-if="selectedUsers.length"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            depressed
            class="mb-4 mb-md-0 mr-md-4"
            v-bind="attrs"
            v-on="on"
          >
            Actions
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item link @click="onAddToAssignment">
            <v-list-item-title>Add to Assignment</v-list-item-title>
          </v-list-item>

          <v-list-item link @click="onExportExcel">
            <v-list-item-title>Export Excel</v-list-item-title>

            <v-progress-circular
              size="20"
              width="2"
              indeterminate
              color="primary"
              v-if="exportExcelLoader"
            ></v-progress-circular>
          </v-list-item>
        </v-list>
      </v-menu>

      <ShowPerPage
        class="mr-2"
        :currentPerPage="scheduleParams.itemsPerPage"
        :options="showPerPageOptions"
      />

      <FilterBy
        :fields="filterFields"
        :loader="loader"
        @filterChange="onFilterChange"
        class="ml-auto"
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="schedule.results"
      :server-items-length="schedule.rowCount"
      class="clickable mb-4"
      hide-default-footer
      :options.sync="options"
      @update:options="onGetSchedule()"
      :loading="loader"
      item-key="clientUserId"
      show-select
      checkbox-color="primary"
      v-model="selectedUsers"
    >
      <template v-slot:[`item.dateCompleted`]="{ item }">
        {{
          item.dateCompleted
            ? dayjs.utc(item.dateCompleted).local().format("DD/MM/YYYY ")
            : "-"
        }}
      </template>
    </v-data-table>

    <v-pagination
      class="mt-auto"
      :length="schedule.pageCount"
      v-model="scheduleParams.page"
      v-if="schedule.pageCount"
    ></v-pagination>

    <v-dialog
      v-model="dialog.open"
      max-width="1250px"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <component
        :is="dialog.component"
        @closeDialog="onDialogClosed"
        :selectedUsers="selectedUsers"
      ></component>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AssignmentDialog from "@/views/admin/assignment/assignment-dialog/AssignmentDialog";
import FilterBy from "@/components/FilterBy";
import ShowPerPage from "@/components/ShowPerPage";

export default {
  components: {
    AssignmentDialog,
    FilterBy,
    ShowPerPage,
  },
  data() {
    return {
      headers: [
        {
          text: "Identity Number",
          value: "identityNumber",
          sortable: false,
        },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Last Date Completed", value: "dateCompleted" },
        { text: "Position", value: "rankName" },
        { text: "Nationality", value: "nationalityName" },
      ],
      loader: false,
      selectedUsers: [],
      dialog: {
        open: false,
        component: null,
      },
      exportExcelLoader: false,
      showPerPageOptions: [],
    };
  },
  computed: {
    ...mapState({
      schedule: (state) => state.schedule.schedule,
      scheduleParams: (state) => state.schedule.scheduleParams,
      userTypes: (state) => state.base.lists.userTypes,
      countries: (state) => state.base.lists.countries,
      allGroups: (state) => state.base.allGroups,
    }),
    options: {
      get() {
        const { itemsPerPage, page, sortBy, sortDesc } = this.scheduleParams;

        return {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        };
      },
      set(value) {
        let { itemsPerPage, page, sortBy, sortDesc } = value;

        this.setScheduleParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      },
    },
    paramsToWatch() {
      return `${this.scheduleParams?.questionnaire?.questionnaireId}${this.scheduleParams.dateBefore}`;
    },
    filterFields() {
      return [
        {
          key: "groupId",
          value: "Group",
          values: this.allGroups,
          selectedValue: this.scheduleParams.groupId,
        },
        {
          key: "nationalityId",
          value: "Nationality",
          values: this.countries,
          selectedValue: this.scheduleParams.nationalityId,
        },
        {
          key: "position",
          value: "Position",
          checkboxValues: this.userTypes,
          selectedValue: this.scheduleParams.position,
        },
      ];
    },
  },
  created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setScheduleParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setScheduleParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setScheduleParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setScheduleParams,
      },
    ];
  },
  methods: {
    ...mapActions(["getSchedule", "exportScheduleExcel"]),
    ...mapMutations([
      "setScheduleParams",
      "setForm",
      "togglePreselectedUsersBanner",
      "togglePreselectedQuestionnairesBanner",
    ]),
    onFilterChange(e) {
      this.setScheduleParams({
        [e.field]: e.value,
      });

      this.onGetSchedule();
    },
    async onGetSchedule() {
      this.loader = true;

      try {
        await this.getSchedule();

        this.$emit("setInitialData", {
          dateBefore: this.scheduleParams.dateBefore,
          questionnaireId: this.scheduleParams.questionnaire?.questionnaireId,
        });
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    onDialogClosed(event) {
      if (event?.unselectUsers) {
        this.selectedUsers = [];

        this.getSchedule();
      }

      this.dialog = { open: false, component: null };
    },
    onAddToAssignment() {
      this.setForm({
        key: "assigneeUserIds",
        value: this.selectedUsers.map((user) => {
          return {
            clientUserId: user.clientUserId,
            identityNumber: user.identityNumber,
            firstName: user.firstName,
            lastName: user.lastName,
            userEmail: user.userEmail,
          };
        }),
      });

      this.setForm({
        key: "questionnaireIds",
        value: [this.scheduleParams.questionnaire],
      });

      this.togglePreselectedUsersBanner(true);
      this.togglePreselectedQuestionnairesBanner(true);

      this.dialog = { open: true, component: "AssignmentDialog" };
    },
    async onExportExcel() {
      this.exportExcelLoader = true;

      try {
        await this.exportScheduleExcel({
          clientUserIds: this.selectedUsers.map((u) => u.clientUserId),
          questionnaireId: this.scheduleParams?.questionnaire?.questionnaireId,
          dateBefore: this.scheduleParams?.dateBefore,
        });
        this.selectedUsers = [];
      } catch (e) {
        console.log(e);
      }

      this.exportExcelLoader = false;
    },
  },
  watch: {
    paramsToWatch() {
      this.onGetSchedule();
      this.selectedUsers = [];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
