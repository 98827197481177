<template>
  <form
    @submit.prevent="submit"
    class="d-flex flex-column flex-grow-1 grey lighten-5 pb-2"
  >
    <v-row>
      <v-col cols="6">
        <div class="pt-2">
          <v-text-field
            outlined
            dense
            hide-details
            label="ID"
            class="mb-4 flex-grow-0"
            :value="currentQuestion.questionId"
            disabled
          ></v-text-field>

          <v-text-field
            outlined
            dense
            hide-details
            label="Input Type"
            class="mb-4 flex-grow-0"
            :value="currentQuestion.inputTypeId"
            disabled
          ></v-text-field>

          <v-text-field
            outlined
            dense
            hide-details
            label="Answers"
            class="mb-4 flex-grow-0"
            :value="currentQuestion.answerChoiceScaleId"
            disabled
          ></v-text-field>

          <v-text-field
            dense
            outlined
            label="Order"
            hide-details
            class="mb-4"
            v-model.number="order"
            :error="$v.order.$dirty && $v.order.$invalid"
          >
          </v-text-field>

          <v-checkbox
            v-model="isMandatory"
            label="Mandatory"
            dense
            hide-details
            class="ma-0 pa-0 mb-4"
            :ripple="false"
          ></v-checkbox>

          <v-checkbox
            v-model="isReverse"
            label="Reverse"
            dense
            hide-details
            class="ma-0 pa-0 mb-4"
            :ripple="false"
          ></v-checkbox>
        </div>
      </v-col>

      <v-col cols="6">
        <v-tabs
          vertical
          background-color="grey lighten-5"
          color="filyellow"
          v-model="langTab"
          id="question-texts"
        >
          <v-tab
            v-for="questionText in currentQuestion.questionTexts"
            :key="questionText.langId"
            :href="`#${questionText.langId}`"
            :disabled="hasTextsChanges"
            >{{ questionText.langName }}</v-tab
          >

          <v-tab-item
            v-for="questionText in currentQuestion.questionTexts"
            :key="questionText.langId"
            :value="questionText.langId"
          >
            <form
              class="px-4 pb-4 pt-2 d-flex flex-column grey lighten-5"
              @submit.prevent="submit"
            >
              <div>
                <tiptap-vuetify
                  v-model="text"
                  :extensions="extensions"
                  placeholder="Text"
                  class="mb-5"
                  :error="$v.text.$dirty && $v.text.$invalid"
                />

                <tiptap-vuetify
                  v-model="subText"
                  :extensions="extensions"
                  placeholder="Subtext"
                  class="mb-5"
                />
                <!-- 
                <v-text-field
                  dense
                  outlined
                  label="Text"
                  hide-details
                  class="mb-4"
                  v-model="text"
                  :error="$v.text.$dirty && $v.text.$invalid"
                >
                </v-text-field>

                <v-textarea
                  dense
                  outlined
                  label="Subtext"
                  hide-details
                  rows="3"
                  no-resize
                  class="mb-4"
                  v-model="subText"
                >
                </v-textarea> -->
              </div>
            </form>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <div
      class="body-2 font-weight-medium error--text mb-4"
      v-if="$v.$dirty && $v.$invalid"
    >
      Please fill the required fields.
    </div>

    <v-btn
      depressed
      color="filyellow"
      class="white--text align-self-center"
      type="submit"
      :disabled="!hasChanges"
      :loading="loader"
    >
      Save
    </v-btn>
  </form>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  History,
  Image,
} from "tiptap-vuetify";

import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: { TiptapVuetify },

  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        Paragraph,
        HardBreak,
        Image,
      ],
      langTab: "en",
      text: "<p></p>",
      subText: "<p></p>",
      initialData: null,
      initialTextData: null,
      loader: false,
      order: null,
      isMandatory: false,
      isReverse: false,
    };
  },
  validations: {
    order: { required },
    text: { required },
  },
  computed: {
    ...mapState({
      currentSection: (state) => state.questionnaires.currentSection,
      currentQuestion: (state) => state.questionnaires.currentQuestion,
    }),
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !=
        JSON.stringify({
          text: this.text,
          subText: this.subText,
          order: this.order,
          isMandatory: this.isMandatory,
          isReverse: this.isReverse,
        })
      );
    },
    hasTextsChanges() {
      return (
        JSON.stringify(this.initialTextData) !=
        JSON.stringify({
          text: this.text,
          subText: this.subText,
        })
      );
    },
  },
  methods: {
    ...mapMutations(["getQuestion"]),
    ...mapActions(["updateQuestion"]),
    initQuestionTextsData(langId) {
      const questionText = this.currentQuestion.questionTexts.find(
        (qt) => qt.langId == langId
      );

      this.text = questionText?.text;
      this.subText = questionText?.subText;

      this.initialTextData = {
        text: this.text,
        subText: this.subText,
      };

      this.order = this.currentQuestion.showOrder;
      this.isMandatory = this.currentQuestion.isMandatory;
      this.isReverse = this.currentQuestion.isReverse;

      this.initialData = {
        text: this.text,
        subText: this.subText,
        order: this.order,
        isMandatory: this.isMandatory,
        isReverse: this.isReverse,
      };
    },
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        await this.updateQuestion({
          sectionId: this.currentSection.sectionId,
          questionId: this.currentQuestion.questionId,
          showOrder: this.order,
          isMandatory: this.isMandatory,
          isReverse: this.isReverse,
          languageId: this.langTab,
          questionText: this.text,
          questionSubText: this.subText,
        });

        this.initQuestionTextsData(this.langTab);
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  watch: {
    langTab: {
      immediate: true,
      handler(newValue) {
        this.initQuestionTextsData(newValue);
      },
    },
  },
  destroyed() {
    this.getQuestion(null);
  },
};
</script>

<style lang="scss" scoped>
</style>
