<template>
  <div class="d-flex flex-column flex-grow-1 pa-4 pt-2">
    <v-menu
      top
      right
      :close-on-content-click="false"
      offset-y
      offset-x
      ref="remindAllMenu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          depressed
          color="primary"
          class="align-self-start mb-2"
          small
        >
          <v-icon left>mdi-send</v-icon>
          Remind All
        </v-btn>
      </template>

      <div class="white pa-5">
        <div class="body-2 mb-4">
          Are you sure you want to remind all users about the assignment?
        </div>

        <v-btn
          small
          depressed
          @click="onRemindAssignment({ userId: null, index: null })"
          :loading="remindAllLoader"
          color="filyellow"
          class="white--text"
          >Remind</v-btn
        >
      </div>
    </v-menu>

    <div
      class="d-flex align-center my-1"
      v-if="currentAssignment && currentAssignment.assessorName"
    >
      <div class="subtitle-1 font-weight-medium mr-2">Assessor:</div>
      <div class="body-1">{{ currentAssignment.assessorName }}</div>
    </div>

    <div class="scrollable">
      <v-expansion-panels
        v-model="panel"
        :disabled="getAssignmentOfUserLoader"
        class="assignment-status-questionnaires"
      >
        <v-expansion-panel
          v-for="(user, index) in currentAssignment.assigneeUsers"
          :key="user.clientUserId"
        >
          <v-expansion-panel-header
            :class="{ 'grey lighten-4': panel === index }"
          >
            <div class="d-flex align-center">
              <div class="body-2 font-weight-medium">
                {{ index + 1 }}. {{ user.firstName }} {{ user.lastName }}
              </div>

              <div class="text-h6 mx-4">|</div>

              <div class="body-2 mr-2">Email Status:</div>

              <div
                class="caption px-1 rounded"
                :class="{
                  'success white--text':
                    user.lastMailStatus == 'Sent' ||
                    user.lastMailStatus == 'Delivery' ||
                    user.lastMailStatus == 'Open' ||
                    user.lastMailStatus == 'Click',
                  'grey lighten-1 ': !user.lastMailStatus,
                  'error white--text': user.lastMailStatus == 'Bounce',
                }"
              >
                {{ user.lastMailStatus ? user.lastMailStatus : "Unknown" }}
              </div>

              <template v-if="!user.hasCompletedAll">
                <div class="text-h6 mx-4">|</div>

                <v-menu
                  top
                  right
                  :close-on-content-click="false"
                  offset-y
                  offset-x
                  ref="remindMenus"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      color="primary"
                      x-small
                    >
                      <v-icon small left>mdi-send</v-icon>
                      Remind
                    </v-btn>
                  </template>

                  <div class="white pa-5">
                    <div class="body-2 mb-4">
                      Are you sure you want to remind user
                      <span class="font-weight-bold"
                        >{{ user.firstName }} {{ user.lastName }}</span
                      >
                      about the assignment?
                    </div>

                    <v-btn
                      small
                      depressed
                      @click="
                        onRemindAssignment({ userId: user.clientUserId, index })
                      "
                      :loading="remindLoader"
                      color="filyellow"
                      class="white--text"
                      >Remind</v-btn
                    >
                  </div>
                </v-menu>
              </template>
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="py-2">
            <v-progress-circular
              size="30"
              width="3"
              indeterminate
              color="primary"
              v-if="getAssignmentOfUserLoader"
            ></v-progress-circular>

            <AssignmentDetails
              :user="user"
              v-if="!getAssignmentOfUserLoader && assignmentOfUser"
              :panel="panel"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AssignmentDetails from "@/views/admin/assignment/assignment-dialog/AssignmentDetails";

export default {
  components: {
    AssignmentDetails,
  },
  data() {
    return {
      panel: null,
      headers: [
        { text: "Questionnaire Type", value: "questionnaireTypeName" },
        { text: "Questionnaire Title", value: "questionnaireName" },
        { text: "Completed", value: "isFinalized" },
        { text: "Completion Date", value: "dateCompleted" },
        { text: "Execution Time", value: "executionTimeMinSec" },
        { text: "Green", value: "green" },
        { text: "Yellow", value: "yellow" },
        { text: "Red", value: "red" },
        { text: "Report", value: "report" },
      ],
      remindLoader: false,
      remindAllLoader: false,
    };
  },
  computed: {
    ...mapState({
      currentAssignment: (state) => state.assignments.currentAssignment,
      getAssignmentOfUserLoader: (state) =>
        state.assignments.getAssignmentOfUserLoader,
      assignmentOfUser: (state) => state.assignments.assignmentOfUser,
      showReport: (state) => state.assignments.showReport,
    }),
  },
  created() {
    if (this.showReport.id) {
      this.panel = this.showReport.index;

      this.toggleShowReport({
        id: null,
        index: null,
      });
    }
  },
  methods: {
    ...mapMutations(["toggleShowReport"]),
    ...mapActions(["getAssignmentOfUser", "remindAssignment"]),
    async onRemindAssignment({ userId, index }) {
      if (!userId) {
        this.remindAllLoader = true;
      } else {
        this.remindLoader = true;
      }

      try {
        await this.remindAssignment({
          assignmentId: this.currentAssignment.assignmentId,
          ...(userId && { userIds: [userId] }),
        });
      } catch (e) {
        console.log(e);
      }

      if (!userId) {
        this.remindAllLoader = false;
        this.$refs.remindAllMenu.save();
      } else {
        this.remindLoader = false;
        this.$refs.remindMenus[index].save();
      }
    },
  },
  watch: {
    async panel(newValue) {
      if (
        newValue !== null &&
        newValue !== undefined &&
        this.currentAssignment?.assigneeUsers[newValue]?.clientUserId !=
          this.assignmentOfUser?.userId
      ) {
        await this.getAssignmentOfUser({
          userId: this.currentAssignment?.assigneeUsers[newValue]?.clientUserId,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bullet {
  &.small {
    height: 12px;
    width: 12px;
  }
}
</style>
