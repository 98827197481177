<template>
  <div>
    <v-list dense nav :disabled="globalLoader" class="grey lighten-5">
      <v-list-item
        v-for="(question, index) in currentSection.questions"
        :key="question.questionId"
        :to="`/superadmin/questionnaires/${$route.params.questionnaireTypeId}/${$route.params.questionnaireId}/sections/${$route.params.sectionId}/${question.questionId}`"
      >
        <div class="d-flex flex-grow-1">
          <span class="font-weight-bold mr-2">{{ index + 1 }}.</span>

          <span>{{ question.questionText }}</span>

          <v-icon class="ml-auto" color="filyellow">mdi-chevron-right</v-icon>
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      currentSection: (state) => state.questionnaires.currentSection,
      globalLoader: (state) => state.base.globalLoader,
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
