<template>
  <div class="component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column">
    <div class="body-1 font-weight-medium mb-5">Clients</div>

    <div class="wrapper rounded mb-5 d-flex justify-end pa-5">
      <v-btn color="warning" depressed to="/superadmin/clients/new">
        <v-icon left>mdi-plus</v-icon>
        Add a new Client
      </v-btn>
    </div>

    <div class="wrapper d-flex flex-column rounded pa-4 flex-grow-1">
      <div class="d-flex align-center">
        <v-text-field
          outlined
          dense
          label="Search"
          prepend-inner-icon="mdi-magnify"
          hint="Search by Client Name or client VAT"
          persistent-hint
          :value="clientsParams.search"
          @input="setClientsParams({ search: $event })"
          :readonly="loader"
          clearable
          class="align-self-start flex-grow-0 mr-auto"
        ></v-text-field>

        <ShowPerPage
          :currentPerPage="clientsParams.itemsPerPage"
          :options="showPerPageOptions"
        />
      </div>

      <v-data-table
        :headers="headers"
        :items="clients.results"
        :server-items-length="clients.rowCount"
        class="clickable mb-4"
        checkbox-color="primary"
        hide-default-footer
        @click:row="
          !globalLoader &&
            $router.push(`/superadmin/clients/${$event.clientId}`)
        "
        :options.sync="options"
        @update:options="onGetClients(true)"
        :loading="loader"
      >
        <template v-slot:[`item.isEnabled`]="{ item }">
          <div
            class="bullet small"
            :class="{
              success: item.isEnabled,
              error: !item.isEnabled,
            }"
          ></div>
        </template>
      </v-data-table>

      <v-pagination
        class="mt-auto"
        :length="clients.pageCount"
        v-model="clientsParams.page"
        v-if="clients.pageCount"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ShowPerPage from "@/components/ShowPerPage";

export default {
  components: {
    ShowPerPage,
  },
  data() {
    return {
      headers: [
        { text: "Client Name", value: "companyName" },
        { text: "Client VAT", value: "vat", sortable: false },
        { text: "Status", value: "isEnabled" },
      ],
      loader: false,
      timeout: null,
      isFirstCall: true,
      showPerPageOptions: [],
    };
  },
  computed: {
    ...mapState({
      clientsParams: (state) => state.clients.clientsParams,
      clients: (state) => state.clients.clients,
      globalLoader: (state) => state.base.globalLoader,
    }),
    options: {
      get() {
        const { itemsPerPage, page, sortBy, sortDesc } = this.clientsParams;

        return {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        };
      },
      set(value) {
        let { itemsPerPage, page, sortBy, sortDesc } = value;

        this.setClientsParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      },
    },
  },
  async created() {
    this.loader = true;

    try {
      await this.getClients(false);
    } catch (e) {
      console.log(e);
    }

    this.loader = false;

    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setClientsParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setClientsParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setClientsParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setClientsParams,
      },
    ];
  },
  methods: {
    ...mapMutations(["setClientsParams"]),
    ...mapActions(["getClients"]),
    async onGetClients(refresh) {
      if (this.isFirstCall) {
        this.isFirstCall = false;
        return;
      }

      this.loader = true;

      try {
        await this.getClients(refresh);
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  watch: {
    "clientsParams.search"() {
      clearTimeout(this.timeout);

      if (this.loader) return;

      this.timeout = setTimeout(() => {
        this.setClientsParams({
          page: 1,
        });

        this.onGetClients(true);
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.bullet {
  &.small {
    height: 15px;
    width: 15px;
  }
}
</style>
