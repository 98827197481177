var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1 pa-4 pt-2"},[_c('v-menu',{ref:"remindAllMenu",attrs:{"top":"","right":"","close-on-content-click":false,"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-start mb-2",attrs:{"depressed":"","color":"primary","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-send")]),_vm._v(" Remind All ")],1)]}}])},[_c('div',{staticClass:"white pa-5"},[_c('div',{staticClass:"body-2 mb-4"},[_vm._v(" Are you sure you want to remind all users about the assignment? ")]),_c('v-btn',{staticClass:"white--text",attrs:{"small":"","depressed":"","loading":_vm.remindAllLoader,"color":"filyellow"},on:{"click":function($event){return _vm.onRemindAssignment({ userId: null, index: null })}}},[_vm._v("Remind")])],1)]),(_vm.currentAssignment && _vm.currentAssignment.assessorName)?_c('div',{staticClass:"d-flex align-center my-1"},[_c('div',{staticClass:"subtitle-1 font-weight-medium mr-2"},[_vm._v("Assessor:")]),_c('div',{staticClass:"body-1"},[_vm._v(_vm._s(_vm.currentAssignment.assessorName))])]):_vm._e(),_c('div',{staticClass:"scrollable"},[_c('v-expansion-panels',{staticClass:"assignment-status-questionnaires",attrs:{"disabled":_vm.getAssignmentOfUserLoader},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.currentAssignment.assigneeUsers),function(user,index){return _c('v-expansion-panel',{key:user.clientUserId},[_c('v-expansion-panel-header',{class:{ 'grey lighten-4': _vm.panel === index }},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"body-2 font-weight-medium"},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(user.firstName)+" "+_vm._s(user.lastName)+" ")]),_c('div',{staticClass:"text-h6 mx-4"},[_vm._v("|")]),_c('div',{staticClass:"body-2 mr-2"},[_vm._v("Email Status:")]),_c('div',{staticClass:"caption px-1 rounded",class:{
                'success white--text':
                  user.lastMailStatus == 'Sent' ||
                  user.lastMailStatus == 'Delivery' ||
                  user.lastMailStatus == 'Open' ||
                  user.lastMailStatus == 'Click',
                'grey lighten-1 ': !user.lastMailStatus,
                'error white--text': user.lastMailStatus == 'Bounce',
              }},[_vm._v(" "+_vm._s(user.lastMailStatus ? user.lastMailStatus : "Unknown")+" ")]),(!user.hasCompletedAll)?[_c('div',{staticClass:"text-h6 mx-4"},[_vm._v("|")]),_c('v-menu',{ref:"remindMenus",refInFor:true,attrs:{"top":"","right":"","close-on-content-click":false,"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"primary","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-send")]),_vm._v(" Remind ")],1)]}}],null,true)},[_c('div',{staticClass:"white pa-5"},[_c('div',{staticClass:"body-2 mb-4"},[_vm._v(" Are you sure you want to remind user "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(user.firstName)+" "+_vm._s(user.lastName))]),_vm._v(" about the assignment? ")]),_c('v-btn',{staticClass:"white--text",attrs:{"small":"","depressed":"","loading":_vm.remindLoader,"color":"filyellow"},on:{"click":function($event){return _vm.onRemindAssignment({ userId: user.clientUserId, index: index })}}},[_vm._v("Remind")])],1)])]:_vm._e()],2)]),_c('v-expansion-panel-content',{staticClass:"py-2"},[(_vm.getAssignmentOfUserLoader)?_c('v-progress-circular',{attrs:{"size":"30","width":"3","indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.getAssignmentOfUserLoader && _vm.assignmentOfUser)?_c('AssignmentDetails',{attrs:{"user":user,"panel":_vm.panel}}):_vm._e()],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }