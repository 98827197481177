<template>
  <v-row class="scrollable">
    <v-col cols="12" sm="6">
      <v-text-field
        dense
        outlined
        label="Identity*"
        class="mb-1"
        :value="personalDetailsForm.identity"
        @input="setPersonalDetailsForm({ value: $event, key: 'identity' })"
        :error-messages="identityErrors"
      ></v-text-field>

      <v-text-field
        dense
        outlined
        label="First Name*"
        class="mb-1"
        :value="personalDetailsForm.firstName"
        @input="setPersonalDetailsForm({ value: $event, key: 'firstName' })"
        :error-messages="
          v.$dirty && !v.form.personalDetails.firstName.required
            ? 'This field is required'
            : ''
        "
      ></v-text-field>

      <v-text-field
        dense
        outlined
        label="Last Name*"
        class="mb-1"
        :value="personalDetailsForm.lastName"
        @input="setPersonalDetailsForm({ value: $event, key: 'lastName' })"
        :error-messages="
          v.$dirty && !v.form.personalDetails.lastName.required
            ? 'This field is required'
            : ''
        "
      ></v-text-field>

      <v-text-field
        :disabled="
          Boolean(selectedUser) &&
            selectedUser.platformAccessId == platformAccess
        "
        dense
        outlined
        label="Username*"
        class="mb-1"
        :value="personalDetailsForm.username"
        @input="setPersonalDetailsForm({ value: $event, key: 'username' })"
        :error-messages="usernameErrors"
        v-if="platformAccess != 'basic'"
      ></v-text-field>

      <v-text-field
        dense
        outlined
        :label="
          `Email ${platformAccess && platformAccess != 'basic' ? '*' : ''}`
        "
        type="email"
        :value="personalDetailsForm.email"
        @input="setPersonalDetailsForm({ value: $event, key: 'email' })"
        :error-messages="emailErrors"
      ></v-text-field>

      <v-radio-group v-model="positionType" row class="mt-0">
        <v-radio
          v-for="type in userTypes"
          :key="type.itemKey"
          :value="type.itemKey"
          :label="type.itemValue"
          :ripple="false"
        ></v-radio>
      </v-radio-group>

      <v-select
        dense
        outlined
        label="Position*"
        :items="positions"
        item-value="itemKey"
        item-text="itemValue"
        :value="personalDetailsForm.position"
        @input="setPersonalDetailsForm({ value: $event, key: 'position' })"
        :error-messages="
          v.$dirty && !v.form.personalDetails.position.required
            ? 'This field is required'
            : ''
        "
      ></v-select>

      <v-select
        dense
        outlined
        label="Group(s)*"
        :items="allGroups"
        item-value="groupId"
        item-text="groupName"
        multiple
        v-if="role == 'gm' && !isEdit"
        :value="personalDetailsForm.groups"
        @input="setPersonalDetailsForm({ value: $event, key: 'groups' })"
        :error-messages="
          v.$dirty && !v.form.personalDetails.groups.required
            ? 'This field is required'
            : ''
        "
      ></v-select>

      <v-select
        dense
        outlined
        label="Nationality*"
        :items="countries"
        item-value="itemKey"
        item-text="itemValue"
        class="mb-1"
        :value="personalDetailsForm.nationality"
        @input="setPersonalDetailsForm({ value: $event, key: 'nationality' })"
        :error-messages="
          v.$dirty && !v.form.personalDetails.nationality.required
            ? 'This field is required'
            : ''
        "
      ></v-select>

      <v-select
        dense
        outlined
        label="Language*"
        :items="languages"
        item-value="itemKey"
        item-text="itemValue"
        class="mb-1"
        :value="personalDetailsForm.language"
        @input="setPersonalDetailsForm({ value: $event, key: 'language' })"
        :error-messages="
          v.$dirty && !v.form.personalDetails.language.required
            ? 'This field is required'
            : ''
        "
      ></v-select>
    </v-col>

    <v-col cols="12" sm="6">
      <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        max-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="formattedDate"
            dense
            prepend-inner-icon="mdi-calendar"
            outlined
            clearable
            label="Birthdate"
            class="mb-1"
            readonly
            v-bind="attrs"
            v-on="on"
            @click:clear="
              setPersonalDetailsForm({ value: null, key: 'birthDate' })
            "
            :hint="
              personalDetailsForm.birthDate
                ? `Age: ${new Date().getFullYear() -
                    dayjs(personalDetailsForm.birthDate).year()}`
                : ''
            "
            persistent-hint
          ></v-text-field>
        </template>

        <v-date-picker
          :value="personalDetailsForm.birthDate"
          @input="setPersonalDetailsForm({ value: $event, key: 'birthDate' })"
          @change="dateMenu = false"
          :max="maxBirthDate"
        ></v-date-picker>
      </v-menu>

      <v-select
        dense
        outlined
        label="Gender"
        :items="genders"
        item-value="itemKey"
        item-text="itemValue"
        class="mb-1"
        :value="personalDetailsForm.gender"
        @input="setPersonalDetailsForm({ value: $event, key: 'gender' })"
      ></v-select>

      <v-select
        dense
        outlined
        label="Status"
        :items="maritalStatuses"
        item-value="itemKey"
        item-text="itemValue"
        class="mb-1"
        :value="personalDetailsForm.maritalStatus"
        @input="setPersonalDetailsForm({ value: $event, key: 'maritalStatus' })"
      ></v-select>

      <v-text-field
        dense
        outlined
        label="Kids"
        type="number"
        :value="personalDetailsForm.kids"
        @input="setPersonalDetailsForm({ value: $event, key: 'kids' })"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["selectedUser", "v", "platformAccess"],
  data() {
    return {
      personalDetailsForm: {
        identity: null,
        firstName: null,
        lastName: null,
        username: null,
        email: null,
        position: null,
        groups: [],
        nationality: null,
        language: null,
        birthDate: null,
        gender: null,
        maritalStatus: null,
        kids: null,
      },
      dateMenu: false,
      positionType: null,
      maxBirthDate: new Date(new Date().getFullYear() - 12, 0, 1).toISOString(),
    };
  },
  computed: {
    ...mapState({
      languages: (state) => state.base.lists.languages,
      countries: (state) => state.base.lists.countries,
      maritalStatuses: (state) => state.base.lists.maritalStatuses,
      genders: (state) => state.base.lists.genders,
      allGroups: (state) => state.base.allGroups,
      userTypes: (state) => state.base.lists.userTypes,
      role: (state) => state.auth.role,
    }),
    formattedDate() {
      return this.personalDetailsForm.birthDate
        ? this.dayjs(this.personalDetailsForm.birthDate).format("DD/MM/YYYY")
        : "";
    },
    positions() {
      if (!this.positionType) return [];

      return this.$store.state.base.lists[this.positionType];
    },
    isEdit() {
      return !!this.selectedUser;
    },
    identityErrors() {
      const errors = [];

      if (!this.v.form.personalDetails.identity.$dirty) return errors;
      if (!this.v.form.personalDetails.identity.required)
        errors.push("This field is required");
      if (!this.v.form.personalDetails.identity.uniqueIdentity)
        errors.push("This identity already exists.");
      return errors;
    },
    usernameErrors() {
      const errors = [];

      if (!this.v.form.personalDetails.username.$dirty) return errors;
      if (!this.v.form.personalDetails.username.required)
        errors.push("This field is required");
      if (!this.v.form.personalDetails.username.uniqueUsername)
        errors.push("This username already exists.");
      return errors;
    },
    emailErrors() {
      const errors = [];

      if (!this.v.form.personalDetails.email.$dirty) return errors;
      if (!this.v.form.personalDetails.email.required)
        errors.push("This field is required");
      if (!this.v.form.personalDetails.email.email)
        errors.push("Must be a valid email");
      if (!this.v.form.personalDetails.email.uniqueEmail)
        errors.push("This email already exists.");
      return errors;
    },
  },
  created() {
    if (this.selectedUser) {
      this.personalDetailsForm.identity = this.selectedUser.identityNumber;
      this.personalDetailsForm.firstName = this.selectedUser.firstName;
      this.personalDetailsForm.lastName = this.selectedUser.lastName;
      this.personalDetailsForm.username = this.selectedUser.userName;
      this.personalDetailsForm.email = this.selectedUser.userEmail;
      this.positionType = this.selectedUser.userTypeId;
      this.personalDetailsForm.position = this.selectedUser.positionId;
      this.personalDetailsForm.nationality = this.selectedUser.nationalityId;
      this.personalDetailsForm.language = this.selectedUser.languageId;

      this.personalDetailsForm.birthDate = this.selectedUser.dateOfBirth
        ? this.dayjs
            .utc(this.selectedUser.dateOfBirth)
            .toISOString(true)
            .substr(0, 10)
        : null;
      this.personalDetailsForm.gender = this.selectedUser.gender;
      this.personalDetailsForm.maritalStatus = this.selectedUser.marital;
      this.personalDetailsForm.kids =
        this.selectedUser.kids != null && this.selectedUser.kids != undefined
          ? this.selectedUser.kids
          : null;
    }

    this.$emit("personalDetailsChanged", this.personalDetailsForm);
  },
  methods: {
    setPersonalDetailsForm(field) {
      this.personalDetailsForm[field.key] = field.value;
      this.$emit("personalDetailsChanged", this.personalDetailsForm);
    },
  },
};
</script>

<style lang="scss" scoped></style>
