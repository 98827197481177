var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',_vm._l((_vm.factors),function(factor,index){return _c('v-expansion-panel',{key:(index + "-" + (factor.factorId))},[_c('v-expansion-panel-header',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"subtitle-2 mr-3"},[_vm._v(" "+_vm._s(factor.factorName)+" ")]),_c('div',{staticClass:"bullet caption",class:{
              filyellow: factor.colorFlag == 'Y',
              error: factor.colorFlag == 'R',
              success: factor.colorFlag == 'G',
            }},[_vm._v(" "+_vm._s(factor.aScore)+" ")])])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"body-2 mb-4"},[_vm._v(" "+_vm._s(factor.factorText)+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-1 d-flex align-center"},[_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(factor.factorName)+" Score")])]),_c('v-col',{staticClass:"pa-1 d-flex align-center justify-center"},[_c('v-progress-linear',{attrs:{"value":factor.aScore,"color":_vm.getColor(factor.colorFlag)}})],1),_c('v-col',{staticClass:"pa-1 d-flex align-center justify-center"},[_c('div',{staticClass:"subtitle-2",class:{
                  'filyellow--text': factor.colorFlag == 'Y',
                  'error--text': factor.colorFlag == 'R',
                  'success--text': factor.colorFlag == 'G',
                }},[_vm._v(" "+_vm._s(factor.aScore)+" ")])])],1),_c('div',{staticClass:"d-flex mt-1 align-self-start"},[(factor.factorAction)?_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){_vm.dialog = { open: true, html: factor.factorAction }}}},[_vm._v("Actions")]):_vm._e(),(factor.discussionPoints)?_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){_vm.dialog = { open: true, html: factor.discussionPoints }}}},[_vm._v("Discussion Points")]):_vm._e()],1),(factor.subFactors && factor.subFactors.length)?[_c('v-divider',{staticClass:"my-4"}),_c('v-tabs',{attrs:{"vertical":""}},[_vm._l((factor.subFactors),function(subfactor,index){return _c('v-tab',{key:(index + "-" + (subfactor.factorId))},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"subtitle-2 mr-3"},[_vm._v(" "+_vm._s(subfactor.factorName)+" ")]),_c('div',{staticClass:"bullet caption",class:{
                      filyellow: subfactor.colorFlag == 'Y',
                      error: subfactor.colorFlag == 'R',
                      success: subfactor.colorFlag == 'G',
                    }},[_vm._v(" "+_vm._s(subfactor.aScore)+" ")])])])}),_vm._l((factor.subFactors),function(subfactor,index){return _c('v-tab-item',{key:(index + "-" + (subfactor.factorId))},[_c('div',{staticClass:"d-flex flex-column pa-5"},[_c('div',{staticClass:"body-2 mb-4"},[_vm._v(_vm._s(subfactor.factorText))]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-1 d-flex align-center"},[_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(subfactor.factorName)+" Score ")])]),_c('v-col',{staticClass:"pa-1 d-flex align-center justify-center"},[_c('v-progress-linear',{attrs:{"value":subfactor.aScore,"color":_vm.getColor(subfactor.colorFlag)}})],1),_c('v-col',{staticClass:"pa-1 d-flex align-center justify-center"},[_c('div',{staticClass:"subtitle-2",class:{
                          'filyellow--text': subfactor.colorFlag == 'Y',
                          'error--text': subfactor.colorFlag == 'R',
                          'success--text': subfactor.colorFlag == 'G',
                        }},[_vm._v(" "+_vm._s(subfactor.aScore)+" ")])])],1),_c('div',{staticClass:"d-flex align-self-start mt-1"},[(subfactor.factorAction)?_c('v-btn',{staticClass:"mr-2",attrs:{"mr-2":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){_vm.dialog = { open: true, html: subfactor.factorAction }}}},[_vm._v("Actions")]):_vm._e(),(subfactor.discussionPoints)?_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){_vm.dialog = {
                          open: true,
                          html: subfactor.discussionPoints,
                        }}}},[_vm._v("Discussion Points")]):_vm._e()],1)],1)])})],2)]:_vm._e()],2)])],1)}),1),_c('v-dialog',{attrs:{"max-width":"1200px"},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open)?_c('div',{staticClass:"white"},[_c('v-btn',{staticClass:"mt-1 ml-1",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.dialog.open = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('div',{staticClass:"pa-4 pt-2"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.dialog.html)}})])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }