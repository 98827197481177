<template>
  <div class="wrapper rounded flex-grow-1 d-flex flex-column">
    <div
      class="d-flex flex-column flex-md-row align-center align-md-start justify-start pa-5 pb-1"
    >
      <div class="mr-md-5 mb-1 d-flex align-start">
        <!-- <v-text-field
          outlined
          dense
          label="Search"
          class="mr-2"
          prepend-inner-icon="mdi-magnify"
          hint="Search by title"
          persistent-hint
          v-model="notificationsParams.search"
          :readonly="getNotificationsLoader"
        ></v-text-field> -->

        <v-btn
          icon
          color="primary"
          v-if="notificationsParams.search"
          @click="notificationsParams.search = ''"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="d-flex flex-column flex-sm-row ml-md-auto mb-2 mb-md-0">
        <ShowPerPage
          class="mr-2"
          :currentPerPage="notificationsParams.itemsPerPage"
          :options="showPerPageOptions"
        />

        <!-- <v-btn class="mr-sm-4 mb-4 mb-sm-0" depressed>
            <v-icon left>mdi-cloud-download</v-icon>
            CSV
          </v-btn>
  
          <v-btn depressed>
            <v-icon left>mdi-cloud-download</v-icon>
            PDF
          </v-btn> -->
      </div>
    </div>

    <v-data-table
      :headers="headers"
      :items="!getNotificationsLoader ? notifications.results : []"
      :server-items-length="notifications.rowCount"
      class="clickable"
      @click:row="dialog = { open: true, type: 'edit', item: $event }"
      :loading="getNotificationsLoader"
      @update:options="onGetNotifications"
      item-key="id"
      :options="options"
      hide-default-footer
    >
      <template v-slot:[`item.title`]="{ item }">
        {{
          item.title.length > 70
            ? item.title.substring(0, 60) + "..."
            : item.title
        }}
      </template>
      <template v-slot:[`item.dateSent`]="{ item }">
        {{ item.dateSent ? dayjs(item.dateSent).format("DD/MM/YYYY") : "-" }}
      </template>

      <template v-slot:[`item.importance`]="{ item }">
        <span
          style="text-transform: capitalize"
          class="primary px-2 pa-1 rounded-pill white--text"
        >
          {{ item.importance }}
        </span>
      </template>

      <template v-slot:[`item.send`]="{ item }">
        <div class="d-flex white--text">
          <div class="bullet filgreen disabled mr-2">
            {{ item.sentToNum }}
          </div>

          <div class="bullet filgreen">
            {{ item.openedByNum }}
          </div>
        </div>
      </template>
    </v-data-table>

    <div class="pagination-wrapper py-3 mt-auto">
      <v-pagination
        :length="notifications.pageCount"
        v-model="notificationsParams.page"
        :disabled="getNotificationsLoader"
        v-if="notifications.pageCount"
      ></v-pagination>
    </div>

    <v-dialog
      v-model="dialog.open"
      :max-width="dialog.type == 'delete' ? '500px' : '1250px'"
      persistent
    >
      <template v-if="dialog.open">
        <div class="white" v-if="dialog.type == 'delete'">
          <div class="primary pa-3 d-flex align-start">
            <div class="text-h6 white--text">Delete Notifications</div>

            <v-btn
              icon
              small
              color="white"
              class="ml-auto"
              @click="dialog = { open: false, type: null }"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>

          <div class="pa-5">
            <div class="text-h6 mb-2">WARNING</div>

            <div class="body-2 mb-4">
              <!-- Assignments can be deleted only if they do not include completed
              questionnaires! Do you want to proceed? -->
              Are you sure you want to delete notifications?
            </div>

            <div class="d-flex">
              <v-btn
                color="filred"
                depressed
                class="mr-2 white--text"
                @click="dialog = { open: false, type: null }"
                >Delete Notifications</v-btn
              >

              <v-btn depressed @click="dialog = { open: false, type: null }"
                >Cancel</v-btn
              >
            </div>
          </div>
        </div>

        <NotificationDialog
          :isEdit="true"
          :notification="dialog.item"
          @closeDialog="dialog = { open: false, type: null, item: null }"
          v-else
        />
      </template>
    </v-dialog>
  </div>
</template>
  
  <script>
import NotificationDialog from "@/views/admin/notifications/notification-dialog/NotificationDialog";
import { mapActions, mapMutations, mapState } from "vuex";
import ShowPerPage from "@/components/ShowPerPage";

export default {
  components: {
    NotificationDialog,
    ShowPerPage,
  },
  data() {
    return {
      headers: [
        { text: "Title", value: "title" },
        { text: "Send Date", value: "dateSent" },
        { text: "Sender", value: "senderName", width: "350px" },
        { text: "Importance", value: "importance" },

        { text: "Send/Opened", value: "send" },
      ],
      dialog: { open: false, type: null, item: null },
      timeout: null,
      showPerPageOptions: [],
    };
  },
  computed: {
    ...mapState({
      notifications: (state) => state.notifications.notifications,
      notificationsParams: (state) => state.notifications.notificationsParams,
      getNotificationsLoader: (state) =>
        state.notifications.getNotificationsLoader,
    }),
    options() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.notificationsParams;

      return {
        itemsPerPage,
        page,
        sortBy,
        sortDesc,
      };
    },
  },
  created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setNotificationsParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setNotificationsParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setNotificationsParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setNotificationsParams,
      },
    ];
  },
  methods: {
    ...mapActions(["getNotifications"]),
    ...mapMutations(["setNotificationsParams"]),
    async onGetNotifications(event) {
      if (this.getNotificationsLoader) return;

      let { itemsPerPage, page, sortBy, sortDesc } = event || {};

      if (event) {
        this.setNotificationsParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      }

      try {
        await this.getNotifications();
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    "notificationsParams.search"() {
      clearTimeout(this.timeout);

      if (this.getNotificationsLoader) return;

      this.timeout = setTimeout(() => {
        this.setNotificationsParams({
          page: 1,
        });
        this.onGetNotifications();
      }, 500);
    },
  },
  destroyed() {
    this.setNotificationsParams({
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: [],
      search: "",
    });
  },
};
</script>
  
  <style lang="scss" scoped>
.bullet {
  &.disabled {
    opacity: 0.5;
  }
}
</style>
  