<template>
  <div class="py-5">
    <div class="text-h6">Results</div>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left subtitle-2">Completion Date</th>

            <th class="text-left subtitle-2">Passing Score</th>

            <th class="text-left subtitle-2">Gained Score</th>

            <th class="text-left subtitle-2">Execution Time</th>

            <th class="text-left subtitle-2">Results</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              {{
                dayjs.utc(report.completionDate).local().format("DD/MM/YYYY")
              }}
            </td>

            <td>{{ report.ps }}</td>

            <td>{{ report.sp }}</td>

            <td>{{ report.fut }}</td>

            <td class="d-flex justify-start align-center">
              <div
                class="px-1 white--text rounded caption"
                :class="{ success: report.pass, error: !report.pass }"
              >
                {{ report.pass ? "Passed" : "Failed" }}
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      report: (state) => state.assignments.report,
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
