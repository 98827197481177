import axios from "axios";

export default {
  state: {
    users: {
      results: [],
      rowCount: 0,
    },
    usersParams: {
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: [],
      search: "",
      selectedGroups: [], //multiple group filter
      groupId: null, //single group filter
      union: "all",
      nationalityId: null,
      platformAccessId: null,
      languageId: null,
      gender: null,
      position: {
        positionType: null,
        positionId: null,
      },
      withMobileLicense: false,
      onTheGoInvitationStatus: null,
    },
    getUsersLoader: false,
  },
  mutations: {
    getUsers(state, users) {
      state.users = users;
    },
    setUserParams(state, params) {
      state.usersParams = {
        ...state.usersParams,
        ...params,
      };
    },
    togglegetUsersLoader(state, loader) {
      state.getUsersLoader = loader;
    },
    clearUsersState(state) {
      state.users = {
        results: [],
        rowCount: 0,
      };

      state.usersParams = {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
        search: "",
        selectedGroups: [],
        groupId: null,
        union: "all",
        nationalityId: null,
        platformAccessId: null,
        languageId: null,
        gender: null,
        position: {
          positionType: null,
          positionId: null,
        },
      };

      state.stategetUsersLoader = false;
    },
  },
  actions: {
    //GET ALL USERS
    async getUsers({ commit, state, rootState }, getAll = false) {
      const role = rootState.auth.role;
      try {
        if (!getAll) commit("togglegetUsersLoader", true);

        //users in selected groups

        let selectedGroupsParams = `${
          state.usersParams.selectedGroups.length ? "?" : ""
        }`;
        state.usersParams.selectedGroups.forEach((group, index) => {
          selectedGroupsParams += `${
            state.usersParams.union == "notin"
              ? "notGroupId=" + group
              : "groupId=" + group
          }${index < state.usersParams.selectedGroups.length - 1 ? "&" : ""}`;
        });

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${
            role == "co" ? "ClientAdmin" : "GroupManager"
          }/GetUsers${selectedGroupsParams ? selectedGroupsParams : ""}`,
          {
            params: {
              pageSize:
                state.usersParams.itemsPerPage != -1 && !getAll
                  ? state.usersParams.itemsPerPage
                  : 10000,
              pageNo: state.usersParams.page,
              ...(state.usersParams.sortBy[0] && {
                sortBy: state.usersParams.sortBy[0],
              }),
              ...(state.usersParams.sortDesc[0] && { dir: "desc" }),
              ...(state.usersParams.search && {
                search: state.usersParams.search,
              }),
              ...(state.usersParams.nationalityId && {
                nationalityId: state.usersParams.nationalityId,
              }),
              ...(state.usersParams.platformAccessId && {
                platformAccessId: state.usersParams.platformAccessId,
              }),
              ...(state.usersParams.languageId && {
                languageId: state.usersParams.languageId,
              }),
              ...(state.usersParams.gender && {
                gender: state.usersParams.gender,
              }),
              ...(state.usersParams.position.positionId && {
                positionId: state.usersParams.position.positionId,
              }),
              ...(state.usersParams.union && {
                groupUnionType:
                  state.usersParams.union != "notin" &&
                  state.usersParams.selectedGroups.length > 1
                    ? state.usersParams.union
                    : null,
              }),

              ...(state.usersParams.onTheGoInvitationStatus && {
                onTheGoInvitationStatus:
                  state.usersParams.onTheGoInvitationStatus,
              }),
              withMobileLicense: state.usersParams.withMobileLicense
                ? state.usersParams.withMobileLicense
                : null,
            },
          }
        );

        if (!getAll) {
          commit("getUsers", res.data);
          commit("togglegetUsersLoader", false);
        }

        if (getAll) return res.data.results;
      } catch (e) {
        commit("togglegetUsersLoader", false);

        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    //GET ONE USER
    async getUserById({ commit, rootState }, id) {
      try {
        const role = rootState.auth.role;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${
            role == "co" ? "ClientAdmin" : "GroupManager"
          }/GetUser/${id}`
        );

        return res.data;
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    //ASSIGN USERS TO GROUP
    async assignUsersToGroup({ commit }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/AddUsersToGroup`,
          payload
        );

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Added to group successfully!",
          timeout: 5000,
        });
      } catch (e) {
        if (e.response?.data?.error?.errorCode != 10405) {
          commit("toggleSnackbar", {
            open: true,
            color: "error",
            text: "Something went wrong. Please try again later.",
            timeout: 5000,
          });
        }

        throw e;
      }
    },
    //REMOVE USER FROM GROUP
    async removeUserFromGroup({ commit }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/RemoveUserFromGroup`,
          payload
        );
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    //ADD USER
    async addUser({ commit, dispatch, rootState }, payload) {
      try {
        const role = rootState.auth.role;

        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/${
            role == "co" ? "ClientAdmin" : "GroupManager"
          }/AddUser`,
          payload
        );

        await dispatch("getInfo");

        dispatch("getUsers");

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "User has been added successfully!",
          timeout: 5000,
        });
      } catch (e) {
        if (
          e.response.data.error.errorCode != 20403 &&
          e.response.data.error.errorCode != 20402 &&
          e.response.data.error.errorCode != 20412
        ) {
          commit("toggleSnackbar", {
            open: true,
            color: "error",
            text: "Something went wrong. Please try again later.",
            timeout: 5000,
          });
        }

        throw e;
      }
    },
    //EDIT USER
    async editUser({ commit, dispatch }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/UpdateUser`,
          payload
        );

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "User has been updated successfully!",
          timeout: 5000,
        });

        dispatch("getUsers");
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async inviteToOnTheGo({ commit }, userIds) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/EnableMobileUsers`,
          {
            mobileAppId: "onthego",
            userIds,
          }
        );

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Invitations have been sent successfully!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async downloadExcelTemplate({ commit, rootState }) {
      try {
        const role = rootState.auth.role;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${
            role == "co" ? "ClientAdmin" : "GroupManager"
          }/DownloadUsersImportExcel`,
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/vnd.ms-excel",
            },
          }
        );

        const filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0];

        let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
        var fileURL = window.URL.createObjectURL(blob);
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async importUsersFromExcel({ commit, dispatch }, excel) {
      try {
        const formData = new FormData();

        formData.set("File", excel);

        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientUser/ImportUsersFromExcel`,
          formData
        );

        dispatch("getUsers");

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "All users have been imported successfully!",
          timeout: 5000,
        });
      } catch (e) {
        const errorCodes = [20402, 20403, 20404, 20405, 20406, 20407, 20408];

        if (!errorCodes.includes(e?.response?.data?.error?.errorCode))
          commit("toggleSnackbar", {
            open: true,
            color: "error",
            text: "Something went wrong. Please try again later.",
            timeout: 5000,
          });

        throw e;
      }
    },
    async remindInvitation({ commit }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientUser/RemindInvitation `,
          payload
        );

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "All users have been sent a remind email!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async deleteUser({ commit }, user) {
      try {
        await axios.get(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/DeleteUser/${user.clientUserId}`
        );
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async exportUsersExcel({ commit }, clientUserIds) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/clientUser/DownloadUsersExcel`,
          {
            clientUserIds,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/vnd.ms-excel",
            },
          }
        );

        const filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0];

        let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
        var fileURL = window.URL.createObjectURL(blob);
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Excel file has been exported!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
  },
};
