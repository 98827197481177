<template>
  <div class="wrapper rounded flex-grow-1 d-flex flex-column">
    <div class="section-header d-flex align-center pa-1 rounded-tl rounded-tr">
      <v-icon class="mr-2">mdi-chart-bar</v-icon>

      <div class="body-2">Evaluated Users</div>
    </div>

    <div class="pa-2 align-self-start" v-if="isSuperadmin">
      <v-select
        label="Client"
        outlined
        dense
        hide-details
        :items="allClients"
        item-value="clientId"
        item-text="companyName"
        v-model="client"
        @change="onGetEvaluatedUsers"
        clearable
      ></v-select>
    </div>

    <v-tabs
      grow
      color="primary"
      v-model="tab"
      class="flex-grow-0"
      @change="onGetEvaluatedUsers"
    >
      <v-tab href="#psycho" :disabled="loader"> Psychometric </v-tab>

      <v-tab href="#ability" :disabled="loader"> Ability </v-tab>

      <v-tab href="#form" :disabled="loader"> Forms </v-tab>

      <v-tab href="#elearn" :disabled="loader"> E-Learning Courses </v-tab>
    </v-tabs>

    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loader"
    ></v-progress-linear>

    <apexchart
      width="100%"
      height="300px"
      type="donut"
      :options="options"
      :series="series"
    ></apexchart>

    <div class="px-5">
      <v-radio-group v-model="period" row dense @change="onGetEvaluatedUsers">
        <v-radio label="Last Year" value="year"></v-radio>

        <v-radio label="Last Month" value="month"></v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapActions, mapState } from "vuex";

export default {
  props: ["isSuperadmin"],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      tab: "psycho",
      period: "year",
      options: {
        colors: ["#33807b", "#A90329"],
        labels: ["Evaluated", "Non evaluated"],
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: 30,
            },
          },
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "right",
        },
      },
      series: [75, 25],
      loader: false,
      client: null,
    };
  },
  computed: {
    ...mapState({
      allClients: (state) => state.base.allClients,
    }),
    evaluatedUsers() {
      return this.$store.state[
        this.isSuperadmin ? "superAdminDashboard" : "dashboard"
      ].evaluatedUsers;
    },
  },
  created() {
    this.series = [
      parseFloat(this.evaluatedUsers.evaluatedPercentage.toFixed(1)),
      parseFloat(this.evaluatedUsers.notEvaluatedPercentage.toFixed(1)),
    ];
  },
  methods: {
    ...mapActions(["getEvaluatedUsers", "getSuperAdminEvaluatedUsers"]),
    async onGetEvaluatedUsers() {
      this.loader = true;

      try {
        if (this.isSuperadmin) {
          await this.getSuperAdminEvaluatedUsers({
            questionnaireTypeId: this.tab,
            period: this.period,
            clientId: this.client,
          });
        } else {
          await this.getEvaluatedUsers({
            questionnaireTypeId: this.tab,
            period: this.period,
          });
        }

        this.series = [
          parseFloat(this.evaluatedUsers.evaluatedPercentage.toFixed(1)),
          parseFloat(this.evaluatedUsers.notEvaluatedPercentage.toFixed(1)),
        ];
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
