<template>
  <div class="d-flex flex-column flex-grow-1">
    <div
      class="stepper d-flex pa-5"
      :class="{
        'pb-8': $vuetify.breakpoint.smAndUp,
        'pb-2': $vuetify.breakpoint.xsOnly,
      }"
    >
      <div class="step" @click="changeStep(1)">
        <div
          class="
            step-number
            d-flex
            align-center
            justify-center
            text-h6
            rounded
            mb-1
          "
          :class="{ done: step > 1, current: step == 1, edit: isEdit }"
        >
          1
        </div>

        <div class="step-text first" v-if="$vuetify.breakpoint.smAndUp">
          Basic Information
        </div>
      </div>

      <template
        v-if="
          !isEdit &&
          (!preselectedUsersBanner || !preselectedQuestionnairesBanner)
        "
      >
        <v-divider></v-divider>

        <div class="step" @click="changeStep(2)">
          <div
            class="
              step-number
              d-flex
              align-center
              justify-center
              text-h6
              rounded
              mb-1
            "
            :class="{ done: step > 2, current: step == 2, unreached: step < 2 }"
          >
            2
          </div>

          <div class="step-text" v-if="$vuetify.breakpoint.smAndUp">
            Questionnaires
          </div>
        </div>

        <v-divider></v-divider>

        <div class="step" @click="changeStep(3)">
          <div
            class="
              step-number
              d-flex
              align-center
              justify-center
              text-h6
              rounded
              mb-1
            "
            :class="{ done: step > 3, current: step == 3, unreached: step < 3 }"
          >
            3
          </div>

          <div class="step-text" v-if="$vuetify.breakpoint.smAndUp">Users</div>
        </div>
      </template>

      <v-divider></v-divider>

      <div class="step" @click="changeStep(4)">
        <div
          class="
            step-number
            d-flex
            align-center
            justify-center
            text-h6
            rounded
            mb-1
          "
          :class="{
            done: step > 4,
            current: step == 4,
            unreached: step < 4,
            edit: isEdit,
          }"
        >
          {{
            isEdit ||
            (preselectedUsersBanner && preselectedQuestionnairesBanner)
              ? 2
              : 4
          }}
        </div>

        <div class="step-text" v-if="$vuetify.breakpoint.smAndUp">
          {{ isEdit ? "Overview" : "Save" }}
        </div>
      </div>
    </div>

    <div
      class="px-5 mb-4"
      v-if="
        (step1Error && step == 1) ||
        (step2Error && step == 2) ||
        (step3Error && step == 3)
      "
    >
      <div
        class="
          d-flex
          align-center
          error
          white--text
          pa-1
          body-2
          font-weight-medium
          rounded
        "
      >
        <span v-if="step == 1"> Please fill the required fields </span>
        <span v-if="step == 2"> Please select at least one questionnaire </span>
        <span v-if="step == 3"> Please select at least one user. </span>
      </div>
    </div>

    <div class="scrollable pa-5 pb-0 pt-0 d-flex flex-column">
      <component :is="activeComponent" :v="$v" :isEdit="isEdit" />
    </div>

    <div class="d-flex justify-space-between px-5 py-3 mt-auto">
      <v-btn
        class="mr-5"
        small
        depressed
        @click="changeStep(step - 1)"
        v-if="step != 1"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Previous
      </v-btn>

      <div class="d-flex align-center ml-auto">
        <v-btn
          small
          depressed
          color="warning"
          class="mr-2"
          v-if="isEdit && hasChanges"
          :loading="updateLoader"
          @click="onUpdateAssignment"
        >
          Update Assignment
          <v-icon right> mdi-content-save </v-icon>
        </v-btn>

        <v-btn
          small
          depressed
          @click="changeStep(step + 1)"
          :color="step < 4 ? '' : isEdit ? '' : 'success '"
          :class="{ 'white--text': step == 4 && !isEdit }"
          :loading="!isEdit && loader"
          :disabled="form.hasAssessor && !form.assessorId && step == 4"
        >
          <template v-if="step < 4">
            <span> Next </span>
            <v-icon right>mdi-arrow-right</v-icon>
          </template>

          <template v-else>
            <span> {{ isEdit ? "Close" : "Create Assignment" }} </span>

            <v-icon right v-if="!isEdit">mdi-check</v-icon>
          </template>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import BasicInformation from "@/views/admin/assignment/assignment-dialog/BasicInformation";
import Questionnares from "@/views/admin/assignment/assignment-dialog/Questionnares";
import Users from "@/views/admin/assignment/assignment-dialog/Users";
import Save from "@/views/admin/assignment/assignment-dialog/Save";
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

const questionnairesValidator = (value) => value.length;

const usersValidator = function () {
  return this.form.assigneeUserIds.length;
};

export default {
  props: ["isEdit"],
  components: {
    BasicInformation,
    Questionnares,
    Users,
    Save,
  },
  data() {
    return {
      step: 1,
      loader: false,
      updateLoader: false,
    };
  },
  computed: {
    ...mapState({
      form: (state) => state.assignments.form,
      currentAssignment: (state) => state.assignments.currentAssignment,
      initialData: (state) => state.assignments.initialData,
      preselectedUsersBanner: (state) =>
        state.assignments.preselectedUsersBanner,
      preselectedQuestionnairesBanner: (state) =>
        state.assignments.preselectedQuestionnairesBanner,
    }),
    activeComponent() {
      if (this.step == 1) return "BasicInformation";
      if (this.step == 2) return "Questionnares";
      if (this.step == 3) return "Users";
      if (this.step == 4) return "Save";

      return "BasicInformation";
    },
    step1Error() {
      return (
        (this.$v.form.assignmentName.$dirty &&
          this.$v.form.assignmentName.$invalid) ||
        (this.$v.form.dateToSend.$dirty && this.$v.form.dateToSend.$invalid) ||
        (this.$v.form.dateToRemind.$dirty &&
          this.$v.form.dateToRemind.$invalid) ||
        (this.$v.form.dateToEnd.$dirty && this.$v.form.dateToEnd.$invalid) ||
        (this.$v.form.sendTypeId.$dirty && this.$v.form.sendTypeId.$invalid) ||
        (this.$v.form.usersToNotifyIds.$dirty &&
          this.$v.form.usersToNotifyIds.$invalid)
      );
    },
    step2Error() {
      return (
        this.$v.form.questionnaireIds.$dirty &&
        this.$v.form.questionnaireIds.$invalid
      );
    },
    step3Error() {
      return (
        this.$v.form.assigneeUserIds.$dirty &&
        this.$v.form.assigneeUserIds.$invalid
      );
    },
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !=
        JSON.stringify({
          dateToRemind: this.form.dateToRemind,
          dateToEnd: this.form.dateToEnd,
          usersToNotifyIds: this.form.usersToNotifyIds
            ? [...this.form.usersToNotifyIds].sort((a, b) =>
                a.userEmail > b.userEmail
                  ? 1
                  : b.userEmail > a.userEmail
                  ? -1
                  : 0
              )
            : null,
        })
      );
    },
  },
  validations: {
    form: {
      assignmentName: { required },
      dateToSend: { required },
      dateToRemind: { required },
      dateToEnd: { required },
      sendTypeId: { required },
      usersToNotifyIds: { required },
      questionnaireIds: { questionnairesValidator },
      assigneeUserIds: { usersValidator },
    },
  },
  methods: {
    ...mapMutations([
      "setForm",
      "clearNewAssignmentState",
      "setUserParams",
      "setInitialData",
    ]),
    ...mapActions(["addAssignment", "updateAssignment"]),
    async changeStep(step) {
      if (step == this.step || step <= 0) return; //if same do nothing

      //IF IS EDIT
      if (
        this.isEdit ||
        (this.preselectedUsersBanner && this.preselectedQuestionnairesBanner)
      ) {
        if (this.step == 1) {
          this.$v.form.dateToRemind.$touch();
          this.$v.form.dateToEnd.$touch();

          if (
            this.$v.form.dateToRemind.$invalid ||
            this.$v.form.dateToEnd.$invalid
          )
            return;

          this.step = 4;
          return;
        }

        if (this.step == 4) {
          if (step == 1 || step == 3) {
            this.step = 1;
            return;
          } else {
            this.$emit("assignmentUpdated", false);
          }

          return;
        }
      }

      if (step < this.step) {
        //if u want to navigate to previous step just do it.
        this.step = step;
        return;
      }

      //check if CURRENT step is NOT the last
      if (this.step != 4) {
        //then u want to navigate to next step

        if (step >= 2) {
          //if u want to go to 2nd
          this.$v.form.assignmentName.$touch();
          this.$v.form.dateToSend.$touch();
          this.$v.form.dateToRemind.$touch();
          this.$v.form.dateToEnd.$touch();
          this.$v.form.sendTypeId.$touch();
          this.$v.form.usersToNotifyIds.$touch();

          if (
            this.$v.form.assignmentName.$invalid ||
            this.$v.form.dateToSend.$invalid ||
            this.$v.form.dateToRemind.$invalid ||
            this.$v.form.dateToEnd.$invalid ||
            this.$v.form.sendTypeId.$invalid ||
            this.$v.form.usersToNotifyIds.$invalid
          )
            return;

          this.step = 2;
        }

        if (step >= 3) {
          //if u want to go to 3rd

          this.$v.form.questionnaireIds.$touch();

          if (this.$v.form.questionnaireIds.$invalid) return;

          this.step = 3;
        }

        if (step >= 4) {
          this.$v.form.assigneeUserIds.$touch();

          if (this.$v.form.assigneeUserIds.$invalid) return;

          this.step = 4;
        }
      } else {
        //else u want to submit

        try {
          this.loader = true;
          await this.addAssignment();
          this.$emit("closeDialog", { unselectUsers: true });
          this.loader = false;
          return;
        } catch (e) {
          this.loader = false;
          console.log(e);
        }
      }
    },
    async onUpdateAssignment() {
      this.$v.form.dateToRemind.$touch();
      this.$v.form.dateToEnd.$touch();
      this.$v.form.usersToNotifyIds.$touch();

      if (
        this.$v.form.dateToRemind.$invalid ||
        this.$v.form.dateToEnd.$invalid ||
        this.$v.form.usersToNotifyIds.$invalid
      )
        return;

      this.updateLoader = true;

      try {
        await this.updateAssignment();
        this.$emit("assignmentUpdated", true);

        this.setInitialData({
          dateToRemind: this.form.dateToRemind,
          dateToEnd: this.form.dateToEnd,
          usersToNotifyIds: [...this.form.usersToNotifyIds].sort((a, b) =>
            a.userEmail > b.userEmail ? 1 : b.userEmail > a.userEmail ? -1 : 0
          ),
        });
      } catch (e) {
        console.log(e);
      }

      this.updateLoader = false;
    },
  },
  destroyed() {
    this.setUserParams({
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: [],
      search: "",
      selectedGroups: [],
      groupId: null,
      union: "all",
      nationalityId: null,
      platformAccessId: null,
      languageId: null,
      gender: null,
      position: {
        positionType: null,
        positionId: null,
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.stepper {
  overflow-x: hidden;

  .v-divider {
    margin-top: 15px;
  }

  .step {
    position: relative;
    cursor: pointer;
  }

  .step-number {
    height: 30px;
    width: 30px;

    &.done {
      background-color: var(--v-primary-base);
      border: 2px solid var(--v-primary-base);
      color: white;

      &.edit {
        background-color: transparent;
        border: 2px solid rgba(0, 0, 0, 0.12);
        color: #616161;
      }
    }

    &.current {
      background-color: var(--v-filyellow-base);
      border: 2px solid var(--v-filyellow-base);
      color: white;

      &.edit {
        background-color: var(--v-primary-base);
        border: 2px solid var(--v-primary-base);
      }
    }

    &.unreached {
      border: 2px solid rgba(0, 0, 0, 0.12);
      color: #616161;
    }
  }

  .step-text {
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 1;

    font-size: 11px;
    font-weight: 500;
    width: 88px;

    &.first {
      left: 0;
      transform: none;
      text-align: start;
    }

    &.last {
      right: 0;
      left: auto;
      transform: none;
    }
  }
}

.dialog-wrapper {
  overflow-x: hidden;
}
</style>
