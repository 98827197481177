var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-grow-1 d-flex flex-column"},[_c('div',{staticClass:"scrollable"},[_c('div',{staticClass:"d-flex pt-1"},[_c('v-text-field',{staticClass:"flex-grow-0",attrs:{"label":"Search","hint":"Search by assignment title","persistent-hint":"","outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","clearable":""},on:{"input":function($event){_vm.page = 1}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('ShowPerPage',{staticClass:"ml-auto",attrs:{"labelPerPage":"Assignments","currentPerPage":_vm.pageSize,"options":_vm.showPerPageOptions}})],1),(_vm.currentUser.assignments && _vm.currentUser.assignments.length)?_c('v-expansion-panels',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.paginatedAssignments),function(assignment,index){return _c('v-expansion-panel',{key:assignment.assignmentId},[_c('v-expansion-panel-header',{class:{ 'grey lighten-3': _vm.tab === index }},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(assignment.assignmentName)+" ")]),_c('div',{staticClass:"text-h6 mx-4"},[_vm._v("|")]),_c('div',{staticClass:"body-2 mr-2"},[_vm._v("Email Status:")]),_c('div',{staticClass:"caption px-1 rounded",class:{
                'success white--text':
                  assignment.lastMailStatus == 'Sent' ||
                  assignment.lastMailStatus == 'Delivery' ||
                  assignment.lastMailStatus == 'Open' ||
                  assignment.lastMailStatus == 'Click',
                'grey lighten-1 ': !assignment.lastMailStatus,
                'error white--text': assignment.lastMailStatus == 'Bounce',
              }},[_vm._v(" "+_vm._s(assignment.lastMailStatus ? assignment.lastMailStatus : "Unknown")+" ")])])]),_c('v-expansion-panel-content',{staticClass:"pt-4"},[(_vm.getAssignmentOfUserLoader)?_c('v-progress-circular',{attrs:{"size":"30","width":"3","indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.getAssignmentOfUserLoader && _vm.assignmentOfUser)?_c('AssignmentDetails',{attrs:{"user":_vm.currentUser,"panel":_vm.tab}}):_vm._e()],1)],1)}),1):_vm._e(),(_vm.currentUser.assignments && !_vm.currentUser.assignments.length)?_c('div',{staticClass:"text-h6 text-center"},[_vm._v(" There are no available asssignments for this user. ")]):_vm._e()],1),(_vm.pages > 0)?_c('v-pagination',{attrs:{"length":_vm.pages},on:{"input":function($event){_vm.tab = null}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }