<template>
  <div class="component-wrapper px-4 pt-4 grey lighten-3 d-flex flex-column">
    <div class="d-flex mb-4">
      <v-icon class="mr-2">mdi-puzzle</v-icon>

      <div class="body-1 font-weight-medium">Personnel Matrix</div>
    </div>

    <div class="wrapper rounded flex-grow-1 d-flex flex-column pa-4">
      <form class="d-flex align-center mb-2">
        <div class="d-flex flex-column flex-grow-1 mr-4">
          <div class="d-flex align-center mb-2">
            <v-menu
              max-width="290"
              v-if="role != 'psysupport' && role != 'allpsysup'"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    label="Select Questionnaire"
                    clearable
                    class="mr-2"
                    readonly
                    append-icon="mdi-menu-down"
                    :value="
                      selectedQuestionnaire
                        ? selectedQuestionnaire.questionnaireName
                        : null
                    "
                    @click:clear="
                      selectedQuestionnaire = null;
                      setFilters({
                        questionnaire: selectedQuestionnaire,
                        dateFrom: dateFrom,
                        positionType: positionType,
                        factorGroupIds: selectedFactorGroups,
                      });
                      isFetched(false);
                      $router.push('/crew-combinations');
                    "
                  >
                  </v-text-field>
                </div>
              </template>

              <div class="white questionnaire-menu">
                <div
                  v-for="questionnaireType in questionnaires"
                  :key="questionnaireType.questionnaireTypeId"
                >
                  <div
                    class="pl-2"
                    v-if="questionnaireType.questionnaireTypeId == 'psycho'"
                  >
                    <div
                      v-for="questionnaire in questionnaireType.questionnaires"
                      :key="questionnaire.questionnaireId"
                    >
                      <div
                        class="body-2 py-1 px-2"
                        :class="{
                          'font-weight-medium':
                            questionnaire.subQuestionnaires &&
                            questionnaire.subQuestionnaires.length,
                          questionnaire:
                            !questionnaire.subQuestionnaires ||
                            !questionnaire.subQuestionnaires.length,
                        }"
                        @click="
                          (!questionnaire.subQuestionnaires ||
                            !questionnaire.subQuestionnaires.length) &&
                            (selectedQuestionnaire = questionnaire);
                          setQuestionnaire(questionnaire);
                          setFilters({
                            questionnaire: selectedQuestionnaire,
                            dateFrom: dateFrom,
                            positionType: positionType,
                            factorGroupIds: selectedFactorGroups,
                          });
                          isFetched(false);
                          $router.push('/crew-combinations');
                        "
                      >
                        {{ questionnaire.questionnaireName }}
                      </div>

                      <div
                        class="pl-4"
                        v-if="
                          questionnaire.subQuestionnaires &&
                          questionnaire.subQuestionnaires.length
                        "
                      >
                        <div
                          v-for="subQuestionnaire in questionnaire.subQuestionnaires"
                          :key="subQuestionnaire.questionnaireId"
                        >
                          <div
                            class="body-2 py-1 px-2 questionnaire"
                            @click="
                              selectedQuestionnaire = subQuestionnaire;
                              setFilters({
                                questionnaire: selectedQuestionnaire,
                                dateFrom: dateFrom,
                                positionType: positionType,
                                factorGroupIds: selectedFactorGroups,
                              });
                              setQuestionnaire(subQuestionnaire);
                              isFetched(false);
                              $router.push('/crew-combinations');
                            "
                          >
                            <span class="font-weight-bold mr-1">-</span>
                            {{ subQuestionnaire.questionnaireName }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-menu>

            <v-select
              label="Factor Groups"
              outlined
              dense
              hide-details
              :items="factorGroups"
              item-value="factorGroupId"
              item-text="factorGroupName"
              class="flex-grow-0 mr-2"
              clearable
              v-if="(role == 'co' || role == 'gm') && info.hasFactorGroups"
              v-model="selectedFactorGroups"
              :disabled="!selectedQuestionnaire"
              :loading="factorGroupLoader"
              multiple
              chips
              deletable-chips
              scrollable
            >
            </v-select>

            <v-menu
              max-width="290"
              :close-on-content-click="false"
              ref="dateFrom"
              v-model="menuDateFrom"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateFrom ? dayjs(dateFrom).format('DD/MM/YYYY') : ''"
                  dense
                  prepend-inner-icon="mdi-calendar"
                  outlined
                  label="Evaluation From"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  hide-details
                  class="flex-grow-0 mr-2"
                  @click:clear="
                    dateFrom = null;
                    setFilters({
                      questionnaire: selectedQuestionnaire,
                      dateFrom: dateFrom,
                      positionType: positionType,
                      factorGroupIds: selectedFactorGroups,
                    });
                    isFetched(false);
                    $router.push('/crew-combinations');
                  "
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="dateFrom"
                @input="
                  menuDateFrom = false;
                  setFilters({
                    questionnaire: selectedQuestionnaire,
                    dateFrom: dateFrom,
                    positionType: positionType,
                    factorGroupIds: selectedFactorGroups,
                  });
                "
              ></v-date-picker>
            </v-menu>

            <v-radio-group
              v-model="positionType"
              class="flex-grow-0 pa-0 ma-0 mr-2"
              dense
              hide-details
              v-if="role != 'psysupport' && role != 'allpsysup'"
              @change="
                setFilters({
                  questionnaire: selectedQuestionnaire,
                  dateFrom: dateFrom,
                  positionType: positionType,
                  factorGroupIds: selectedFactorGroups,
                })
              "
            >
              <v-radio
                v-for="type in userTypes"
                :key="type.itemKey"
                :value="type.itemKey"
                :label="type.itemValue"
                :ripple="false"
                class="mb-1"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>

        <v-btn
          depressed
          color="filyellow"
          class="white--text"
          :disabled="!selectedQuestionnaire || !dateFrom || !positionType"
          @click="fetchDepartments"
          >Get Departments</v-btn
        >
      </form>

      <!-- TABS  -->
      <!-- TABS  -->
      <!-- TABS  -->

      <v-progress-circular
        size="80"
        width="4"
        indeterminate
        color="primary"
        v-if="loader"
        class="ma-auto"
      ></v-progress-circular>

      <template v-else>
        <v-tabs
          v-if="
            fetched &&
            departments.results.length > 0 &&
            selectedQuestionnaire != null &&
            dateFrom != null &&
            positionType != null
          "
          class="flex-grow-0"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-for="tab in departmentTitles"
            :key="tab.departmentId"
            :to="'/crew-combinations/' + tab.departmentId.toLowerCase()"
          >
            {{ tab.departmentName }}
          </v-tab>
        </v-tabs>

        <router-view
          v-if="
            selectedQuestionnaire != null &&
            dateFrom != null &&
            positionType != null
          "
        ></router-view>
        <span
          justify="start"
          align="start"
          v-if="fetched && departments.results.length == 0"
          >No data available</span
        >
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import axios from "axios";
export default {
  beforeRouteUpdate(to, from, next) {
    if (from.fullPath != "/crew-combinations/get/results") {
      this.saveSelected(null);
    }
    try {
      if (this.departments?.results?.length > 0) {
        next();
      } else {
        next("/crew-combinations");
      }
    } catch (e) {
      console.log(e);
      next("/");
    }
  },

  created() {
    if (this.departments?.length == 0) {
      this.$router.push("/crew-combinations");
    }
    if (this.filters != null) {
      this.selectedQuestionnaire = this.filters.questionnaire;
      this.dateFrom = this.filters.dateFrom;
      this.positionType = this.filters.positionType;
      this.selectedFactorGroups = this.filters.factorGroupIds;
    }
  },
  data() {
    return {
      positionType: null,
      selectedQuestionnaire: null,
      dateFrom: this.dayjs().subtract(2, "months").toISOString().substr(0, 10),
      menuDateFrom: false,
      loader: false,
      tab: null,
      factorGroups: [],
      selectedFactorGroups: [],
      factorGroupLoader: false,
    };
  },

  computed: {
    ...mapGetters(["departmentTitles"]),

    ...mapState({
      questionnaires: (state) => state.base.questionnaires,
      role: (state) => state.auth.role,
      userTypes: (state) => state.base.lists.userTypes,
      filters: (state) => state.crew_combinations.filters,
      departments: (state) => state.crew_combinations.departments,
      crewCombinationsQuestionnaires: (state) =>
        state.crew_combinations.questionnaires,
      fetched: (state) => state.crew_combinations.fetched,
      info: (state) => state.base.info,
    }),
  },

  watch: {
    selectedQuestionnaire(newValue) {
      if (!newValue) {
        this.isFirstCall = true;
        this.userQuestsFetched = false;
        this.assignments = [];
        this.selectedAssignments = [];
      } else {
        if (this.initAssignments) {
          this.initAssignments = false;
        } else {
          this.selectedAssignments = [];
        }
        this.onQuestionnaireChange();
      }
    },
  },

  methods: {
    ...mapActions(["getDepartments"]),
    ...mapMutations([
      "setQuestionnaire",
      "isFetched",
      "setFilters",
      "saveSelected",
    ]),

    async onQuestionnaireChange() {
      if (!this.selectedQuestionnaire) return;

      this.factorGroupLoader = true;

      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/clientuser/GetFactorGroups`,
          {
            params: {
              questionnaireId: this.selectedQuestionnaire.questionnaireId,
            },
          }
        );
        this.factorGroups = res.data.results;
      } catch (e) {
        console.log(e);
      }

      this.factorGroupLoader = false;
    },

    async fetchDepartments() {
      try {
        this.loader = true;
        console.log(this.selectedFactorGroups);
        await this.getDepartments({
          userTypeId: this.positionType,
          questionnaireId: this.selectedQuestionnaire?.questionnaireId,
          dateFrom: this.dateFrom,
          factorGroupIds: this.selectedFactorGroups,
        });

        this.isFetched(true);
        this.loader = false;

        if (this.departments.results.length > 0)
          this.$router.push(
            `/crew-combinations/${this.departments?.results[0]?.departmentId}`
          );
        else {
          this.$router.push(`/crew-combinations`);
        }
      } catch (e) {
        this.loader = false;
        this.isFetched(false);

        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  width: 100%;
}
</style>