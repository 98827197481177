import axios from 'axios';

export default {
  state: {
    token: localStorage.getItem('token') || null,
    role: localStorage.getItem('role') || null,
  },
  getters: {
    isLoggedIn: (state) =>
      !!state.token && state.role && state.role != 'assignee',
  },
  mutations: {
    login(state, user) {
      state.token = user.token;
      state.role = user.role;
    },
    logout(state) {
      state.token = null;
      state.role = null;
    },
  },
  actions: {
    async login({ commit, dispatch }, credentials) {
      const res = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/User/login`,
        credentials
      );

      const token = res.data.jwt;
      const role = res.data.userRole;

      localStorage.setItem('token', token);
      localStorage.setItem('role', role);

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      if (role == 'co' || role == 'gm') {
        await Promise.all([
          dispatch('getInfo', role),
          dispatch('getQuestionnaires', role),
          dispatch('getGroupsList', { role }),
        ]);
      }

      if (role == 'psysupport') {
        await Promise.all([dispatch('getInfo', role)]);
      }

      if (role == 'filistos') {
        await Promise.all([
          dispatch('getInfo', role),
          dispatch('getQuestionnaires', role),
          dispatch('getClientsList', role),
        ]);
      }

      if (role == 'allpsysup') {
        await Promise.all([
          dispatch('getInfo', role),
          dispatch('getClientsList', role),
        ]);
      }

      commit('login', {
        token,
        role,
      });

      return role;
    },
    async sendForgotPasswordEmail({ commit }, email) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/User/AskPasswordReset`,
          {
            userEmail: email,
          }
        );

        commit('toggleSnackbar', {
          open: true,
          color: 'success',
          text:
            'Email has been sent! Follow the instructions to change your password.',
          timeout: 5000,
        });
      } catch (e) {
        commit('toggleSnackbar', {
          open: true,
          color: 'error',
          text:
            'Something went wrong on application initialization. Please try again later',
          timeout: -1,
        });

        throw e;
      }
    },
    async resetPassword({ commit }, payload) {
      try {
        await axios.post(`${process.env.VUE_APP_BASE_URL}/User/SetPassword`, {
          passwordResetToken: payload.token,
          newPassword: payload.password,
        });

        commit('toggleSnackbar', {
          open: true,
          color: 'success',
          text: 'Your password has been changed! You can now login.',
          timeout: 5000,
        });
      } catch (e) {
        commit('toggleSnackbar', {
          open: true,
          color: 'error',
          text:
            'Something went wrong on application initialization. Please try again later',
          timeout: -1,
        });

        throw e;
      }
    },
    async logout({ commit }, payload) {
      commit('logout');
      commit('clearBaseState');

      commit('clearDashboardState');
      commit('clearUsersState');
      commit('clearGroupsState');
      commit('clearAssignmentsListState');

      commit('clearSuperAdminDashboardState');
      commit('clearClientsState');

      delete axios.defaults.headers.common['Authorization'];

      if (!payload?.fromAssignee) {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
      }

      if (!payload?.withoutRefresh) {
        location.reload();
      }
    },
  },
};
