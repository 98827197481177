<template>
  <div class="wrapper d-flex flex-column rounded">
    <div class="d-flex flex-column flex-md-row align-md-center pa-5">
      <v-alert
        color="primary"
        text
        border="left"
        prominent
        :width="$vuetify.breakpoint.mdAndUp ? '350px' : '100%'"
        class="pa-5"
      >
        <v-select
          outlined
          dense
          hide-details
          label="Select Client"
          :items="clients"
          v-model="client"
          class="mb-5"
          v-if="isSuperAdmin"
        >
        </v-select>

        <v-select
          outlined
          dense
          hide-details
          label="Select Group Type"
          :items="groupTypes"
          v-model="selectedGroupType"
          @change="onGroupTypeChange"
          item-value="itemKey"
          item-text="itemValue"
          class="mb-5"
          :loading="getGroupsLoader"
          clearable
        >
        </v-select>

        <v-select
          outlined
          dense
          hide-details
          label="Select Group"
          :items="groups"
          v-model="selectedGroups"
          :loading="getGroupsLoader"
          @change="onGroupChange"
          item-value="groupId"
          item-text="groupName"
          multiple
          chips
          small-chips
          deletable-chips
        >
        </v-select>

        <v-radio-group
          v-model="union"
          row
          class="mt-5"
          dense
          hide-details
          v-if="selectedGroups.length > 0"
          @change="$emit('groupUnionChanged', $event)"
        >
          <v-radio value="all" label="All" :ripple="false"></v-radio>
          <v-radio value="common" label="Common" :ripple="false"></v-radio>
          <v-radio
            value="notin"
            label="Not In"
            :ripple="false"
            :disabled="selectedGroups.length > 1"
          ></v-radio>
        </v-radio-group>
      </v-alert>
      <div class="d-flex flex-column ml-auto">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              class="mx-auto mx-md-0 ml-md-auto mb-4"
              depressed
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>mdi-plus</v-icon>
              Add User(s)
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item
              link
              @click="dialog = { open: true, component: 'UserDialog' }"
            >
              <v-list-item-title>Single User</v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              @click="dialog = { open: true, component: 'UploadExcel' }"
            >
              <v-list-item-title>Upload Excel</v-list-item-title>
            </v-list-item>

            <v-list-item link @click="downloadExcelTemplate">
              <v-list-item-title>Download Excel Template</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          v-if="info.hasMergeUsers"
          color="warning"
          class="mx-md-0 ml-md-auto"
          depressed
          @click="mergeDialog = true"
        >
          Merge Users
        </v-btn>
      </div>
    </div>

    <v-dialog
      v-model="dialog.open"
      :max-width="dialog.component == 'UserDialog' ? '1250px' : '800px'"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <template v-if="dialog.open">
        <UserDialog
          v-if="dialog.component == 'UserDialog'"
          @closeDialog="dialog = { open: false, component: null }"
        />

        <div class="white d-flex flex-column" v-else>
          <v-btn
            icon
            class="ml-auto mt-1 mr-1"
            @click="dialog = { open: false, component: null }"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <div class="pa-4">
            <form @submit.prevent="submit">
              <v-file-input
                label="Upload Excel"
                outlined
                dense
                hide-details
                prepend-icon=""
                accept=".xl*"
                class="mb-5"
                v-model="excel"
              ></v-file-input>

              <div
                class="subtitle-2 error--text mb-5"
                v-if="usernameExists.length"
              >
                Users with the following usernames already exist:
                {{ usernameExists.join(",") }}
              </div>

              <div
                class="subtitle-2 error--text mb-5"
                v-if="identityExists.length"
              >
                Users with the following identity numbers already exist:
                {{ identityExists.join(",") }}
              </div>

              <div
                class="subtitle-2 error--text mb-5"
                v-if="noMobileAppLicense"
              >
                Not enough mobile app licenses.
              </div>

              <div
                class="subtitle-2 error--text mb-5"
                v-if="noMobileSubscription"
              >
                No mobile app subscription found.
              </div>

              <div
                class="subtitle-2 error--text mb-5"
                v-if="duplicateUsernames"
              >
                There are duplicate Usernames in Excel.
              </div>

              <div
                class="subtitle-2 error--text mb-5"
                v-if="duplicateIdentities"
              >
                There are duplicate Identities in Excel.
              </div>

              <div class="subtitle-2 error--text mb-5" v-if="requiredFields">
                Missing mandatory info in Excel.
              </div>

              <v-btn
                color="filyellow"
                depressed
                class="white--text"
                type="submit"
                :disabled="!excel"
                :loading="loader"
                >Import Users</v-btn
              >
            </form>
          </div>
        </div>
      </template>
    </v-dialog>

    <v-dialog v-model="mergeDialog" v-if="mergeDialog">
      <MergeModal @closeDialog="onCloseDialog" v-if="mergeDialog" />
    </v-dialog>

    <v-dialog v-model="dialog2.open" persistent>
      <UserDialog
        @closeDialog="dialog2 = { open: false, user: null }"
        :selectedUserId="dialog2.user"
        :isEdit="false"
      ></UserDialog>
    </v-dialog>
  </div>
</template>

<script>
import UserDialog from "@/views/admin/users/user-dialog/UserDialog";
import MergeModal from "@/components/MergeModal";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    UserDialog,
    MergeModal,
  },
  data() {
    return {
      mergeDialog: false,
      notGroupId: false,
      dialog: { open: false, component: null },
      dialog2: { open: false, user: null },
      getGroupsLoader: false,
      selectedGroupType: null,
      groups: [],
      selectedGroups: [],
      clients: ["Client 1", "Client 2", "Client 3", "Client 4"],
      client: null,
      union: "all",
      excel: null,
      loader: false,
      usernameExists: [],
      identityExists: [],
      noMobileAppLicense: false,
      noMobileSubscription: false,
      duplicateUsernames: false,
      duplicateIdentities: false,
      requiredFields: false,
    };
  },
  computed: {
    ...mapState({
      groupTypes: (state) => state.base.lists.groupTypes,
      info: (state) => state.base.info,
    }),
    isSuperAdmin() {
      return this.$route.path.includes("filistos");
    },
  },
  created() {
    this.onGroupTypeChange();
  },
  methods: {
    ...mapActions([
      "getGroupsList",
      "downloadExcelTemplate",
      "importUsersFromExcel",
    ]),

    async onCloseDialog(id) {
      this.mergeDialog = false;
      if (id) {
        this.dialog2 = { open: true, user: id };
      }
    },

    async onGroupTypeChange(groupTypeId) {
      this.getGroupsLoader = true;

      try {
        this.groups = await this.getGroupsList({ groupTypeId });

        this.selectedGroups = [];

        this.$emit("groupChanged", this.selectedGroups);
      } catch (e) {
        console.log(e);
      }

      this.getGroupsLoader = false;
    },
    async submit() {
      this.usernameExists = [];
      this.identityExists = [];
      this.noMobileAppLicense = false;
      this.noMobileSubscription = false;
      this.duplicateUsernames = false;
      this.duplicateIdentities = false;
      this.requiredFields = false;

      this.loader = true;

      try {
        await this.importUsersFromExcel(this.excel);

        this.dialog = { open: false, component: null };
      } catch (e) {
        console.log(e?.response?.data?.error?.errorCode);

        if (e?.response?.data?.error?.errorCode == 20402)
          this.usernameExists = e?.response?.data?.result?.existingUsernames;

        if (e?.response?.data?.error?.errorCode == 20403)
          this.identityExists = e?.response?.data?.result?.existingIdentities;

        if (e?.response?.data?.error?.errorCode == 20404)
          this.noMobileAppLicense = true;

        if (e?.response?.data?.error?.errorCode == 20405)
          this.noMobileSubscription = true;

        if (e?.response?.data?.error?.errorCode == 20406)
          this.duplicateUsernames = true;

        if (e?.response?.data?.error?.errorCode == 20407)
          this.duplicateIdentities = true;

        if (e?.response?.data?.error?.errorCode == 20408)
          this.requiredFields = true;

        console.log(e);
      }

      this.loader = false;
    },

    async onGroupChange() {
      console.log(this.union, this.selectedGroups.length);

      if (this.selectedGroups.length > 1 && this.union == "notin") {
        this.union = "all";
        this.$emit("groupUnionChanged", "all");
      }
      this.$emit("groupChanged", this.selectedGroups);
    },
  },
  watch: {
    "dialog.open"(newValue) {
      if (!newValue) {
        this.excel = null;
        this.usernameExists = [];
        this.identityExists = [];
        this.noMobileAppLicense = false;
        this.noMobileSubscription = false;
        this.duplicateUsernames = false;
        this.duplicateIdentities = false;
        this.requiredFields = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
