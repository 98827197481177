import axios from "axios";

export default {
  state: {
    groups: {
      results: [],
      rowCount: 0,
    },
    groupsParams: {
      groupTypeId: null,
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: [],
      search: "",
      withManagers: false,
    },
    groupsLoader: false,
  },
  mutations: {
    getGroups(state, groups) {
      state.groups = groups;
    },
    setGroupsParams(state, params) {
      state.groupsParams = {
        ...state.groupsParams,
        ...params,
      };
    },
    toggleGroupsLoader(state, loader) {
      state.groupsLoader = loader;
    },
    clearGroupsState(state) {
      state.groups = {
        results: [],
        rowCount: 0,
      };

      state.groupsParams = {
        groupTypeId: null,
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
        search: "",
        withManagers: false,
      };

      state.groupsLoader = false;
    },
  },
  actions: {
    //GET GROUPS BY GROUP TYPE
    async getGroups({ commit, state }) {
      //for groups page data table... different from base module "getGroupsList"
      try {
        commit("toggleGroupsLoader", true);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/GetGroups`,
          {
            params: {
              pageSize:
                state.groupsParams.itemsPerPage != -1
                  ? state.groupsParams.itemsPerPage
                  : 1000,
              pageNo: state.groupsParams.page,
              ...(state.groupsParams.sortBy[0] && {
                sortBy: state.groupsParams.sortBy[0],
              }),
              ...(state.groupsParams.sortDesc[0] && { dir: "desc" }),
              ...(state.groupsParams.search && {
                search: state.groupsParams.search,
              }),
              ...(state.groupsParams.groupTypeId && {
                groupTypeId: state.groupsParams.groupTypeId,
              }),
              ...(state.groupsParams.withManagers && {
                withManagers: state.groupsParams.withManagers,
              }),
            },
          }
        );

        commit("getGroups", res.data);

        commit("toggleGroupsLoader", false);
      } catch (e) {
        commit("toggleGroupsLoader", false);

        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    //ADD GROUP
    async addGroup({ commit, dispatch }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/AddGroup`,
          payload
        );

        dispatch("getGroups");
        dispatch("getGroupsList", { refresh: true });

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Group has been added successfully!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    //EDIT GROUP
    async editGroup({ commit, dispatch }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/UpdateGroup`,
          payload
        );

        dispatch("getGroups");
        dispatch("getGroupsList", { refresh: true });

        commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Group has been updated successfully!",
          timeout: 5000,
        });
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    //GET ALLOWED PARENT GROUPS
    async getAllowedParentGroups({ commit }, groupTypeId) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/GetAllowedParentGroups/${groupTypeId}`
        );

        return res.data?.results;
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    //GET USERS OF GROUP
    async getUsersOfGroup({ commit }, params) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/GetUsers`,
          { params }
        );

        return {
          results: res.data.results,
          count: res.data.rowCount,
        };
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    //GET POSSIBLE GROUP MANAGERS
    async getPossibleGroupManagers({ commit }, groupId) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/GetPossibleGroupManagers/${groupId}`
        );

        return res.data.results;
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    //GET GROUP MANAGERS OF GROUP
    async getGroupManagersOfGroup({ commit }, groupId) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/GetGroupManagersOfGroup/${groupId}`
        );

        return res.data.results;
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    //ADD GROUP MANAGERS TO GROUP
    async addGroupManagersToGroup({ commit }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/AddGroupManagersToGroup`,
          payload
        );
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    //REMOVE GROUP MANAGERS FROM GROUP
    async removeGroupManagersFromGroup({ commit }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/RemoveGroupManagerFromGroup`,
          payload
        );
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
  },
};
