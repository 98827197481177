<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="wrapper rounded d-flex justify-end mb-5">
      <v-btn color="warning" depressed @click="openDialog(true, 'add', null)">
        <v-icon left>mdi-plus</v-icon>
        Add Factor Group
      </v-btn>
    </div>
    <div class="d-flex align-start">
      <v-select
        outlined
        dense
        label="Clients"
        clearable
        class="flex-grow-0 mr-2"
        v-model="factorGroupParams.clientId"
        :items="allClients"
        item-text="companyName"
        item-value="clientId"
        :readonly="factorGroupLoader"
        hide-details
      ></v-select>
      <v-text-field
        outlined
        dense
        label="Search"
        prepend-inner-icon="mdi-magnify"
        hint="Search by factor group title"
        persistent-hint
        clearable
        class="flex-grow-0 mr-auto"
        v-model="factorGroupParams.search"
        :readonly="factorGroupLoader"
      ></v-text-field>

      <ShowPerPage
        :currentPerPage="factorGroups.pageSize"
        :options="showPerPageOptions"
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="!factorGroupLoader ? factorGroups.results : []"
      :server-items-length="factorGroups.rowCount"
      class="clickable"
      @click:row="openDialog(true, 'edit', $event)"
      :loading="factorGroupLoader"
      @update:options="getFactorGroups"
      item-key="id"
      :options="options"
      hide-default-footer
    >
      <template v-slot:[`item.client`]="{ item }">
        <div>
          {{ item.clientName ? item.clientName : "-" }}
        </div>
      </template>
    </v-data-table>

    <v-pagination
      :length="factorGroups.pageCount"
      v-model="factorGroupParams.page"
      :disabled="factorGroupLoader"
      v-if="factorGroups.pageCount"
    ></v-pagination>

    <v-dialog
      width="1200px"
      v-model="dialog.open"
      v-if="dialog.open && dialog.type == 'add'"
    >
      <FactorGroupModal
        @closeDialog="closeDialog"
        @getFactorGroups="getFactorGroups"
        v-if="dialog.open && dialog.type == 'add'"
        :factorsArray="factors"
      />
    </v-dialog>

    <v-dialog
      width="1200px"
      v-model="dialog.open"
      v-if="dialog.open && dialog.type == 'edit'"
    >
      <FactorGroupModal
        @closeDialog="closeDialog"
        @getFactorGroups="getFactorGroups"
        :isEdit="true"
        :factorGroup="dialog.factorGroup"
        v-if="dialog.open && dialog.type == 'edit'"
        :factorsArray="factors"
      />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ShowPerPage from "@/components/ShowPerPage";
import FactorGroupModal from "./FactorGroupModal.vue";

export default {
  components: {
    ShowPerPage,
    FactorGroupModal,
  },
  //2.5.8

  async created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setPerPage,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setPerPage,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setPerPage,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setPerPage,
      },
    ];

    const res = await axios.get(
      `${process.env.VUE_APP_BASE_URL}/superadmin/GetFactorsOfQuestionnaire/${this.currentQuestionnaire.questionnaireId}`
    );
    this.factors = res.data.results;
  },

  data() {
    return {
      factorGroupParams: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
        search: "",
        clientId: "",
      },
      headers: [
        { text: "Name", value: "factorGroupName", sortable: false },
        { text: "Client", value: "client", sortable: false },
      ],
      pageSize: 10,
      page: 1,
      showPerPageOptions: [],
      search: null,
      dialog: {
        open: false,
        type: null,
        factorGroup: null,
      },

      factors: [],
      factorGroupLoader: false,
      factorGroups: [],
      timeout: null,
    };
  },
  computed: {
    ...mapState({
      currentQuestionnaire: (state) =>
        state.questionnaires.currentQuestionnaire,
      allClients: (state) => state.base.allClients,
    }),
    options() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.factorGroupParams;

      return {
        itemsPerPage,
        page,
        sortBy,
        sortDesc,
      };
    },
  },

  watch: {
    "factorGroupParams.search"() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.factorGroupParams.page = 1;
        this.getFactorGroups();
      }, 500);
    },

    "factorGroupParams.clientId"() {
      this.factorGroupParams.page = 1;
      this.getFactorGroups();
    },
  },

  methods: {
    openDialog(open, type, factorGroup) {
      this.dialog = {
        open,
        type,
        factorGroup,
      };
    },

    closeDialog() {
      this.dialog = {
        open: null,
        type: null,
        factorGroup: null,
      };
    },
    async setPerPage(options) {
      this.factorGroupParams.page = options.page;
      this.factorGroupParams.itemsPerPage =
        options.itemsPerPage == -1 ? 1000 : options.itemsPerPage;
    },

    async getFactorGroups() {
      this.factorGroupLoader = true;
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/superadmin/GetFactorGroups`,
          {
            params: {
              questionnaireId: this.currentQuestionnaire.questionnaireId,
              pageSize:
                this.factorGroupParams.itemsPerPage != -1
                  ? this.factorGroupParams.itemsPerPage
                  : 1000,
              pageNo: this.factorGroupParams.page,
              ...(this.factorGroupParams.search && {
                search: this.factorGroupParams.search,
              }),
              clientId: this.factorGroupParams.clientId,
            },
          }
        );
        this.factorGroups = res.data;
      } catch (e) {
        console.log(e);
      }

      this.factorGroupLoader = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
