<template>
  <div class="flex-grow-1 d-flex flex-column">
    <div class="d-flex flex-1">
      <div class="d-flex flex-column flex-1">
        <div class="mr-5 mb-5 elevation-2 pa-5 rounded">
          <div
            class="d-flex align-center justify-space-between panel-header"
            @click="basicInfoPanel = !basicInfoPanel"
          >
            <div class="subtitle-2">Basic Information</div>

            <v-icon>
              {{ basicInfoPanel ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </div>

          <div class="d-flex pt-5 mb-5" v-if="basicInfoPanel">
            <div class="d-flex flex-column mr-5">
              <div class="body-2 mb-1">Title:</div>
              <div class="body-2 mb-1">Date to Send:</div>
              <div class="body-2 mb-1">Date to Remind:</div>
              <div class="body-2 mb-1">Date End:</div>
              <div class="body-2 mb-1">Type:</div>
              <div class="body-2">Email Confirmation to:</div>
            </div>

            <div class="d-flex flex-column">
              <div class="body-2 font-weight-medium mb-1">
                {{ form.assignmentName ? form.assignmentName : "-" }}
              </div>

              <div class="body-2 font-weight-medium mb-1">
                {{
                  form.dateToSend
                    ? dayjs.utc(form.dateToSend).local().format("DD/MM/YYYY")
                    : "-"
                }}
              </div>

              <div class="body-2 font-weight-medium mb-1">
                {{
                  form.dateToRemind
                    ? dayjs.utc(form.dateToRemind).local().format("DD/MM/YYYY")
                    : "-"
                }}
              </div>

              <div class="body-2 font-weight-medium mb-1">
                {{
                  form.dateToEnd
                    ? dayjs.utc(form.dateToEnd).local().format("DD/MM/YYYY")
                    : "-"
                }}
              </div>

              <div class="body-2 font-weight-medium mb-1">
                {{ form.sendTypeId ? form.sendTypeId.itemValue : "-" }}
              </div>

              <div class="d-flex flex-column">
                <div
                  class="d-flex flex-column"
                  v-for="(user, index) in form.usersToNotifyIds"
                  :key="user.clientUserId"
                >
                  <div class="body-2 font-weight-medium">
                    {{ user.firstName }} {{ user.lastName }}
                  </div>

                  <div class="caption">{{ user.userEmail }}</div>

                  <v-divider
                    class="my-1"
                    v-if="index != form.usersToNotifyIds.length - 1"
                  ></v-divider>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="mb-5 mr-5 elevation-2 pa-5 rounded d-flex flex-column"
          :class="{ 'flex-1': questionnairesPanel }"
        >
          <div
            class="d-flex align-center justify-space-between panel-header"
            @click="questionnairesPanel = !questionnairesPanel"
            :class="{ 'mb-5': questionnairesPanel }"
          >
            <div class="subtitle-2">Questionnaires</div>

            <v-icon>
              {{ questionnairesPanel ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </div>

          <template v-if="questionnairesPanel">
            <div
              class="body-2"
              v-if="
                (!isEdit && !form.questionnaireIds.length) ||
                (isEdit && !currentAssignment.questionnaires.length)
              "
            >
              No questionnaires added.
            </div>

            <div class="scrollable" v-else>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Title</th>
                      <th class="text-left">Type</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="questionnaire in isEdit
                        ? currentAssignment.questionnaires
                        : form.questionnaireIds"
                      :key="questionnaire.questionnaireId"
                    >
                      <td>
                        {{
                          questionnaire.questionnaireShortName
                            ? questionnaire.questionnaireShortName
                            : questionnaire.questionnaireName
                        }}
                      </td>
                      <td>{{ questionnaire.questionnaireTypeName }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </template>
        </div>
      </div>

      <div class="d-flex flex-column flex-1">
        <div
          class="mr-5 mb-5 elevation-2 pa-5 rounded d-flex flex-column"
          :class="{ 'flex-1': usersPanel }"
        >
          <div
            class="d-flex align-center justify-space-between panel-header"
            @click="usersPanel = !usersPanel"
            :class="{ 'mb-5': usersPanel }"
          >
            <div class="subtitle-2">Users</div>

            <v-icon>
              {{ usersPanel ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </div>

          <template v-if="usersPanel">
            <div
              class="body-2"
              v-if="
                (!isEdit && !form.assigneeUserIds.length) ||
                (isEdit && !currentAssignment.assigneeUsers.length)
              "
            >
              No users added.
            </div>

            <div class="scrollable" v-else>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Identity</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Email</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="user in isEdit
                        ? currentAssignment.assigneeUsers
                        : form.assigneeUserIds"
                      :key="user.clientUserId"
                    >
                      <td>{{ user.identityNumber }}</td>
                      <td>{{ user.firstName }} {{ user.lastName }}</td>
                      <td>{{ user.userEmail ? user.userEmail : "-" }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </template>
        </div>

        <div
          class="mr-5 mb-5 elevation-2 pa-5 rounded d-flex flex-column"
          :class="{ 'flex-1': assessorsPanel }"
          v-if="hasAssessor"
        >
          <div
            class="d-flex align-center justify-space-between panel-header"
            @click="assessorsPanel = !assessorsPanel"
            :class="{ 'mb-5': assessorsPanel }"
          >
            <div class="subtitle-2">Assessors</div>

            <v-icon>
              {{ assessorsPanel ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </div>

          <template v-if="assessorsPanel">
            <div class="scrollable">
              <v-radio-group
                :value="form.assessorId"
                @change="setForm({ value: $event, key: 'assessorId' })"
              >
                <v-radio
                  v-for="assessor in possibleAssessors"
                  :key="assessor.clientUserId"
                  :value="assessor.clientUserId"
                  :label="`${assessor.firstName} ${assessor.lastName}`"
                  :ripple="false"
                ></v-radio>
              </v-radio-group>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
  props: ["isEdit"],
  data() {
    return {
      basicInfoPanel: true,
      questionnairesPanel: true,
      usersPanel: true,
      assessorsPanel: true,
      hasAssessor: false,
      possibleAssessors: [],
      selectedAssessor: [],
    };
  },
  computed: {
    ...mapState({
      form: (state) => state.assignments.form,
      currentAssignment: (state) => state.assignments.currentAssignment,
    }),
    ...mapGetters(["assigneeUserIdsMapped"]),
  },
  async created() {
    this.hasAssessor = Boolean(
      this.form.questionnaireIds.find((q) => q.hasAssessor)
    );

    if (this.hasAssessor) {
      this.possibleAssessors = (
        await axios.get(
          `${process.env.VUE_APP_BASE_URL}/clientuser/GetPossibleAssessors`
        )
      ).data?.results;
    }

    this.setForm({ value: this.hasAssessor, key: "hasAssessor" });
  },
  methods: {
    ...mapMutations(["setForm"]),
  },
};
</script>

<style lang="scss" scoped>
.scrollable {
  height: 208px;
}

.panel-header {
  cursor: pointer;
}
</style>
