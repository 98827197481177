<template>
  <div class="pt-1">
    <div class="d-flex align-center mb-5" v-if="$vuetify.breakpoint.xsOnly">
      <div class="subtitle-1 font-weight-medium">Step 1</div>

      <div class="body-1 mx-2">-</div>

      <div class="body-1">Basic Information</div>
    </div>

    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          hide-details
          dense
          label="Title"
          class="mb-4"
          :disabled="isEdit"
          :value="form.assignmentName"
          @input="setForm({ value: $event, key: 'assignmentName' })"
          :error="
            v.form.assignmentName.$dirty && v.form.assignmentName.$invalid
          "
        ></v-text-field>

        <v-menu
          v-model="dateToSendMenu"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateToSendFormatted"
              dense
              prepend-inner-icon="mdi-calendar"
              hide-details
              outlined
              label="Date to Send"
              class="mb-4"
              readonly
              v-bind="attrs"
              v-on="on"
              :error="v.form.dateToSend.$dirty && v.form.dateToSend.$invalid"
              :disabled="isEdit"
            ></v-text-field>
          </template>

          <v-date-picker
            :value="form.dateToSend"
            @input="setForm({ value: $event, key: 'dateToSend' })"
            @change="onDateToSendChanged"
            :min="new Date().toISOString().substr(0, 10)"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="dateToRemindMenu"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateToRemindFormatted"
              dense
              prepend-inner-icon="mdi-calendar"
              hide-details
              outlined
              label="Date to Remind"
              class="mb-4"
              readonly
              v-bind="attrs"
              v-on="on"
              :error="
                v.form.dateToRemind.$dirty && v.form.dateToRemind.$invalid
              "
            ></v-text-field>
          </template>

          <v-date-picker
            :value="form.dateToRemind"
            @input="setForm({ value: $event, key: 'dateToRemind' })"
            @change="dateToRemindMenu = false"
            :min="
              form.dateToSend
                ? dayjs.utc(form.dateToSend).local().toISOString().substr(0, 10)
                : null
            "
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="dateToEndMenu"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateToEndFormatted"
              dense
              prepend-inner-icon="mdi-calendar"
              hide-details
              outlined
              label="Date End"
              class="mb-4"
              readonly
              v-bind="attrs"
              v-on="on"
              :error="v.form.dateToEnd.$dirty && v.form.dateToEnd.$invalid"
            ></v-text-field>
          </template>

          <v-date-picker
            :value="form.dateToEnd"
            @input="setForm({ value: $event, key: 'dateToEnd' })"
            @change="dateToEndMenu = false"
            :min="
              form.dateToSend
                ? dayjs.utc(form.dateToSend).local().toISOString().substr(0, 10)
                : null
            "
          ></v-date-picker>
        </v-menu>

        <v-select
          outlined
          hide-details
          dense
          label="Type"
          class="mb-4"
          :items="assignmentSendTypes"
          item-text="itemValue"
          :value="form.sendTypeId"
          @input="setForm({ value: $event, key: 'sendTypeId' })"
          return-object
          :error="v.form.sendTypeId.$dirty && v.form.sendTypeId.$invalid"
          :disabled="isEdit"
        >
        </v-select>

        <v-select
          outlined
          dense
          label="Email Confirmation to"
          :value="form.usersToNotifyIds"
          @input="setForm({ value: $event, key: 'usersToNotifyIds' })"
          :loading="usersToNotifyLoader"
          :items="possitbleUsersToNotify"
          item-text="userEmail"
          return-object
          multiple
          chips
          small-chips
          deletable-chips
          :error="
            v.form.usersToNotifyIds.$dirty && v.form.usersToNotifyIds.$invalid
          "
        >
          <template v-slot:item="{ item, attrs }">
            <div class="d-flex align-center">
              <v-checkbox
                :input-value="attrs.inputValue"
                readonly
                dense
                class="pa-0 ma-0 mr-2"
                hide-details
              >
              </v-checkbox>

              <div class="d-flex flex-column py-2">
                <div class="subtitle-2">
                  {{ item.firstName }} {{ item.lastName }}
                </div>
                <div class="caption">{{ item.userEmail }}</div>
              </div>
            </div>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: ["isEdit", "v"],
  data() {
    return {
      dateToSendMenu: false,
      dateToRemindMenu: false,
      dateToEndMenu: false,
      usersToNotifyLoader: false,
    };
  },
  computed: {
    ...mapState({
      assignmentSendTypes: (state) => state.base.lists.assignmentSendTypes,
      form: (state) => state.assignments.form,
      currentAssignment: (state) => state.assignments.currentAssignment,
      info: (state) => state.base.info,
      possitbleUsersToNotify: (state) =>
        state.assignments.possitbleUsersToNotify,
      isFormInitialized: (state) => state.assignments.isFormInitialized,
    }),
    dateToSendFormatted() {
      return this.form.dateToSend
        ? this.dayjs(this.form.dateToSend).format("DD/MM/YYYY")
        : "";
    },
    dateToRemindFormatted() {
      return this.form.dateToRemind
        ? this.dayjs(this.form.dateToRemind).format("DD/MM/YYYY")
        : "";
    },
    dateToEndFormatted() {
      return this.form.dateToEnd
        ? this.dayjs(this.form.dateToEnd).format("DD/MM/YYYY")
        : "";
    },
  },
  async created() {
    if (!this.isFormInitialized) {
      this.toggleIsFormInitialized(true);

      this.setForm({
        value: this.isEdit
          ? this.currentAssignment.assignmentName
          : `Assignment - ${this.dayjs().format("DD/MM/YYYY")}`,
        key: "assignmentName",
      });

      this.setForm({
        value: this.isEdit
          ? this.dayjs
              .utc(this.currentAssignment.dateToSend)
              .local()
              .toISOString()
              .substr(0, 10)
          : this.dayjs().toISOString().substr(0, 10),
        key: "dateToSend",
      });

      this.setForm({
        value: this.isEdit
          ? this.dayjs
              .utc(this.currentAssignment.dateToRemind)
              .local()
              .toISOString()
              .substr(0, 10)
          : this.dayjs().add(3, "days").toISOString().substr(0, 10),
        key: "dateToRemind",
      });

      this.setForm({
        value: this.isEdit
          ? this.dayjs
              .utc(this.currentAssignment.dateToEnd)
              .local()
              .toISOString()
              .substr(0, 10)
          : this.dayjs().add(6, "months").toISOString().substr(0, 10),
        key: "dateToEnd",
      });

      const sendTypeObject = this.assignmentSendTypes.find(
        (assignmentSendType) =>
          assignmentSendType.itemKey ==
          (this.isEdit
            ? this.currentAssignment.sendTypeId
            : this.info.defaultAssignSendTypeId)
      );

      if (sendTypeObject) {
        this.setForm({
          value: sendTypeObject,
          key: "sendTypeId",
        });
      }

      this.setForm({
        value: this.isEdit ? this.currentAssignment.usersToNotify : [],
        key: "usersToNotifyIds",
      });

      this.setInitialData({
        dateToRemind: this.form.dateToRemind,
        dateToEnd: this.form.dateToEnd,
        usersToNotifyIds: [...this.form.usersToNotifyIds].sort((a, b) =>
          a.userEmail > b.userEmail ? 1 : b.userEmail > a.userEmail ? -1 : 0
        ),
      });

      this.usersToNotifyLoader = true;

      try {
        await this.getPossibleUsersToNotify();
      } catch (e) {
        console.log(e);
      }

      this.usersToNotifyLoader = false;
    }
  },
  methods: {
    ...mapMutations(["setForm", "setInitialData", "toggleIsFormInitialized"]),
    ...mapActions(["getPossibleUsersToNotify"]),
    onDateToSendChanged() {
      this.dateToSendMenu = false;

      const dateToSend = this.dayjs(this.form.dateToSend);
      const dateToRemind = this.dayjs(this.form.dateToRemind);
      const dateToEnd = this.dayjs(this.form.dateToEnd);

      if (dateToSend.diff(dateToRemind, "days") >= 0)
        this.setForm({
          value: null,
          key: "dateToRemind",
        });

      if (dateToSend.diff(dateToEnd, "days") >= 0)
        this.setForm({
          value: null,
          key: "dateToEnd",
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
