<template>
  <div class="component-wrapper d-flex justify-center align-start pt-5 px-5">
    <div
      class="inner d-flex flex-column flex-sm-row align-center align-sm-start"
    >
      <div class="text text-center text-sm-start mr-sm-10 order-1 order-sm-0">
        <div class="text-h6 mb-8 filred--text">ACT</div>

        <div class="subtitle-1 font-weight-medium mb-1">
          Assess, Select, Grow
        </div>

        <div class="body-2 mb-5">
          Simplify employee selection and scale your workforce using ACT
        </div>

        <div class="subtitle-1 font-weight-medium mb-1">
          About Act - Are you up to date?
        </div>

        <div class="body-2">
          ACT is a user-friendly recruitment and employee management online
          platform which has been designed to assist you expand your talent base
          and make the most of your personnel. ACT is an efficient tool offered
          to various organizations seeking for qualified and reliable performers
          by offering excellent support functions tailored to employee screening
          and selection.
        </div>
      </div>

      <div class="login-box rounded mb-10 mb-sm-0">
        <div class="header pa-2">
          <div class="subtitle-1">Login</div>
        </div>

        <form @submit.prevent="submit" novalidate>
          <div class="pt-8 pb-4 px-4">
            <v-text-field
              outlined
              dense
              label="Username"
              append-icon="mdi-account"
              v-model="userName"
              type="email"
              class="mb-1"
              :error="wrongCredentials"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              label="Password"
              append-icon="mdi-lock"
              v-model="userPassword"
              type="password"
              class="mb-1"
              :error="wrongCredentials"
            ></v-text-field>

            <div
              class="body-2 link primary--text mb-4"
              @click="$router.push({ path: '/forgot' })"
            >
              Forgot you password ?
            </div>

            <div class="body-2 error--text mb-4" v-if="wrongCredentials">
              Wrong username or password, please try again.
            </div>

            <!-- <a class="primary--text body-2 link">Forgot password?</a> -->
            <div class="body-2">
              By logging in you agree to the
              <span
                class="primary--text link"
                @click="
                  toggleDialog({
                    open: true,
                    component: 'EndUserLicense',
                    width: '800px',
                  })
                "
                >End User License Agreement</span
              >
            </div>
          </div>

          <div class="bottom pa-4 d-flex">
            <v-btn
              color="primary"
              depressed
              class="ml-auto"
              :disabled="!userName || !userPassword"
              type="submit"
              :loading="loader"
              >Login</v-btn
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      userName: null,
      userPassword: null,
      loader: false,
      wrongCredentials: false,
    };
  },
  computed: {
    ...mapState({
      failedNavigationTo: (state) => state.base.failedNavigationTo,
    }),
  },
  methods: {
    ...mapMutations(["toggleDialog", "setFailedNavigationTo"]),
    ...mapActions(["login"]),
    async submit() {
      this.wrongCredentials = false;

      this.loader = true;

      try {
        const role = await this.login({
          userName: this.userName,
          userPassword: this.userPassword,
        });

        if (this.failedNavigationTo) {
          this.$router.push({ path: this.failedNavigationTo });
          this.setFailedNavigationTo(false);
          return;
        }

        if (role == "filistos") {
          this.$router.push({ path: "/superadmin" });
        } else if (role == "allpsysup") {
          this.$router.push({ path: "/allpsysup" });
        } else {
          this.$router.push({ path: "/" });
        }
      } catch (e) {
        if (e.response?.data?.error.errorCode == 10401)
          this.wrongCredentials = true;
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  max-width: 1164px;
}

.text {
  flex: 1;
}

.login-box {
  width: 360px;
  overflow: hidden;
  border: 1px solid #eee;
}

.header {
  background-color: #f5f5f5;
  border-bottom: 1px solid #eee;
}

.bottom {
  border-top: 1px solid #eee;
}

.test {
  height: 200px;
}
</style>
