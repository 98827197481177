<template>
  <div class="wrapper rounded flex-grow-1 d-flex flex-column pa-4">
    <!-- <pre>{{ workPositions }}</pre> -->
    <v-row class="px-4 py-4" v-if="currentDepartment.workPositions.length > 0">
      <v-col
        cols="12"
        sm="6"
        md="3"
        class="d-flex flex-column"
        v-for="(rank, i) in currentDepartment.workPositions"
        :key="i"
      >
        <!-- DATA TABLE -->

        <v-data-table
          v-model="selected[i]"
          :disable-select-all="true"
          :headers="headers"
          :items="rank.availablePersons"
          hide-default-header
          hide-default-footer
          class="elevation-1 mb-6"
          show-select
          item-key="clientUserId"
          checkbox-color="primary"
          :search="search[i]"
          :items-per-page="500"
          height="350px"
          @click:row="
            dialog = {
              open: true,
              component: 'UserDialog',
              user: $event.clientUserId,
            }
          "
        >
          <template v-slot:top>
            <v-text-field
              v-model="search[i]"
              label="Search"
              class="mx-4"
              hint="Search by Identity, Last Name, First Name"
              persistent-hint
            ></v-text-field>

            <div class="d-flex align-center justify-cente pr-2">
              <span class="mr-auto ml-4 body-2 font-weight-medium">
                {{ rank.positionName }}</span
              >

              <v-text-field
                v-model="required[i]"
                outlined
                dense
                hide-details
                type="number"
                min="0"
                :style="'width:60px;max-width:60px'"
                class="mt-2 mb-2 ml-auto"
              ></v-text-field>
            </div>
          </template>

          <template v-slot:header> </template>

          <template v-slot:[`item.name`]="{ item }">
            <div
              class="d-flex flex-column justify-start align-start"
              :style="'width:70px'"
            >
              <span>{{ item.lastName }}</span>

              <span>{{ "[" + item.identityNumber + "]" }}</span>
            </div>
          </template>

          <template v-slot:[`item.greenCount`]="{ item }">
            <div :style="'width:5px'">
              <span class="filgreen--text">
                {{ item.greenCount }}
              </span>
            </div>
          </template>

          <template v-slot:[`item.yellowCount`]="{ item }">
            <div :style="'width:5px'">
              <span class="filyellow--text">
                {{ item.yellowCount }}
              </span>
            </div>
          </template>

          <template v-slot:[`item.redCount`]="{ item }">
            <div :style="'width:5px'">
              <span class="filred--text">
                {{ item.redCount }}
              </span>
            </div>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <div class="d-flex flex-grow-1 align-center">
              <div class="d-flex flex-column">
                <div
                  class="d-flex align-center justify-space-between px-4 py-2"
                >
                  <div class="body-2 font-weight-medium">
                    Selected:
                    <span v-if="selected[i].length > 0">
                      {{ selected[i].length }}
                    </span>
                  </div>
                </div>
                <div
                  class="d-flex align-center justify-space-between px-4 mb-2"
                >
                  <div class="body-2 font-weight-medium">
                    Combinations:
                    <span
                      v-if="
                        required[i] > 0 &&
                        selected[i].length > 0 &&
                        selected[i].length >= required[i]
                      "
                    >
                      {{
                        Factorial(selected[i].length) /
                        (Factorial(required[i]) *
                          Factorial(selected[i].length - required[i]))
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <v-btn
                width="50px"
                depressed
                color="primary"
                class="white--text ml-auto mr-2"
                :disabled="selected[i].length == 0"
                @click="clearSelected(i)"
                >Clear</v-btn
              >
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <span v-else class="mb-auto"> No data available </span>
    <div
      class="d-flex flex-column align-end"
      v-if="currentDepartment.workPositions.length > 0"
    >
      <div class="body-1 font-weight-medium px-2 mb-2">
        {{
          currentDepartment.departmentName +
          " Combinations: " +
          departmentCombinations
        }}
      </div>

      <div
        v-if="warningText"
        class="body-1 font-weight-medium px-2 mb-2 warning--text d-flex align-center"
      >
        <span>
          <v-icon color="warning" class="mr-1">mdi-alert</v-icon>
        </span>
        Be patient. This might take some time!
      </div>

      <v-btn
        width="150px"
        depressed
        color="filyellow"
        class="white--text ml-2"
        @click="getCombinations"
        :disabled="combinationsValidator"
        :loading="combinationsLoader"
        >Get Combinations</v-btn
      >
    </div>

    <v-dialog
      v-model="dialog.open"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly && dialog.component != 'delete'"
    >
      <component
        @closeDialog="onDialogClosed"
        :is="dialog.component"
        :selectedUserId="dialog.user"
        :isEdit="false"
      ></component>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UserDialog from "@/views/admin/users/user-dialog/UserDialog";

export default {
  components: {
    UserDialog,
  },

  created() {
    this.initTableValues(this.currentDepartment);
  },

  data() {
    return {
      headers: [
        { text: "name", value: "name", sort: true },
        { text: "greenCount", value: "greenCount" },
        { text: "yellowCount", value: "yellowCount" },
        { text: "redCount", value: "redCount" },

        { text: "", value: "data-table-select" },
        { text: "firstName", value: "firstName", align: " d-none" },
        { text: "lastName", value: "lastName", align: " d-none" },
        { text: "identityNumber", value: "identityNumber", align: " d-none" },
      ],

      warningText: false,
      dialog: {
        open: false,
        component: null,
      },

      selected: [],
      required: [],
      workPositions: [],
      search: [],
      combinationsLoader: false,
    };
  },

  watch: {
    currentDepartment(newVal) {
      this.initTableValues(newVal);
    },
  },

  computed: {
    ...mapState({
      departments: (state) => state.crew_combinations.departments,
      savedSelected: (state) => state.crew_combinations.savedSelected,
      filters: (state) => state.crew_combinations.filters,
    }),

    currentDepartment() {
      return this.departments.results.find(
        (el) => el.departmentId == this.$route.params.department_name
      );
    },

    combinationsValidator() {
      for (let i = 0; i < this.currentDepartment.workPositions.length; i++) {
        if (this.selected[i].length < this.required[i]) {
          return true;
        }
      }
      return false;
    },

    departmentCombinations() {
      let combinationsArray = [];
      for (let i = 0; i < this.currentDepartment.workPositions.length; i++) {
        if (this.required[i] > 0 && this.selected[i].length >= this.required[i])
          combinationsArray[i] =
            this.Factorial(this.selected[i].length) /
            (this.Factorial(this.required[i]) *
              this.Factorial(this.selected[i].length - this.required[i]));
      }
      if (combinationsArray.length > 0)
        return combinationsArray.reduce((a, b) => a * b);
      else return 0;
    },
  },

  methods: {
    ...mapActions(["getCrewCombinationResults"]),

    async getCombinations() {
      try {
        this.combinationsLoader = true;
        this.warningText = true;

        const parsedIds = this.filters.factorGroupIds.map((el) => parseInt(el));
        let payload = {
          departmentId: this.currentDepartment.departmentId,
          departmentName: this.currentDepartment.departmentName,
          workPositions: [],
          factorGroupIds: parsedIds,
        };

        for (let i = 0; i < this.currentDepartment.workPositions.length; i++) {
          if (this.required[i] > 0) {
            payload.workPositions.push({
              positionId: this.workPositions[i].positionId,
              positionName: this.workPositions[i].positionName,
              required: this.required[i],
              selectedPersons: this.selected[i],
            });
          }
        }

        await this.getCrewCombinationResults(payload);

        this.combinationsLoader = false;
        this.$router.push("/crew-combinations/get/results");
      } catch (e) {
        this.combinationsLoader = false;

        console.log(e);
      }
    },

    onDialogClosed() {
      this.dialog = { open: false, component: null };
    },

    initTableValues(table) {
      this.selected = [];
      this.required = [];
      this.workPositions = [];
      this.search = [];
      this.invalid = {
        text: "",
        value: true,
      };

      for (let i = 0; i < table.workPositions.length; i++) {
        if (this.savedSelected == null) {
          this.selected.push([]);
          this.required.push(table.workPositions[i].defaultRequired);
        } else {
          if (
            table.workPositions[i]?.positionName ==
            this.savedSelected.workPositions[i]?.positionName
          ) {
            this.selected[i] =
              this.savedSelected.workPositions[i].selectedPersons;
            this.required[i] = this.savedSelected.workPositions[i].required;
          } else {
            this.selected.push([]);
            this.required.push(table.workPositions[i].defaultRequired);
          }
        }
        this.search.push("");
        this.workPositions.push({
          positionId: table.workPositions[i].positionId,
          positionName: table.workPositions[i].positionName,
        });
      }
    },

    async clearSelected(index) {
      this.selected[index].push({});
      this.selected[index] = [];
    },

    Factorial(num) {
      var rval = 1;
      for (var i = 2; i <= num; i++) rval = rval * i;
      return rval;
    },
  },

  props: {
    ranks: Array,
  },
};
</script>

<style>
</style>