<template>
  <div class="white dialog-wrapper scrollable-dialog small">
    <div class="primary pa-3 d-flex align-start">
      <div class="text-h6 white--text">Enable CASE</div>

      <v-btn
        icon
        small
        color="white"
        class="ml-auto"
        @click="$emit('closeDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <div class="pa-5 flex-grow-1 d-flex flex-column">
      <v-simple-table class="scrollable">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left"></th>

              <th class="text-left">First Name</th>

              <th class="text-left">Last Name</th>

              <th class="text-left">Email</th>

              <th class="text-left">OnTheGo Pin</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, index) in localSelectedUsers"
              :key="user.name"
              :class="{ 'error white--text': user.notAdded }"
            >
              <td>
                <v-btn
                  icon
                  small
                  :color="user.notAdded ? 'white' : 'error'"
                  @click="unselectUser(index)"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </td>

              <td>{{ user.firstName }} {{ user.notAdded }}</td>

              <td>
                {{ user.lastName }}
              </td>

              <td>
                {{ user.userEmail ? user.userEmail : "-" }}
              </td>

              <td>
                {{ user.onTheGoPin ? user.onTheGoPin : "-" }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-btn
        class="align-self-start mb-2"
        color="primary"
        small
        depressed
        :loading="loader"
        @click="onInvite"
        >Enable CASE</v-btn
      >

      <div class="caption">
        * Users that are not invited to OnTheGo will be automatically ignored.
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["selectedUsers"],
  data() {
    return {
      localSelectedUsers: [],
      loader: false,
    };
  },
  computed: {
    ...mapState({
      currentClient: (state) => state.clients.currentClient,
    }),
  },
  created() {
    this.localSelectedUsers = [...this.selectedUsers];
  },
  methods: {
    ...mapActions(["USERSNEW_inviteHubSupport"]),
    unselectUser(index) {
      this.localSelectedUsers.splice(index, 1);

      this.$emit("unselectUser", index);
    },
    async onInvite() {
      this.loader = true;

      const userIds = this.localSelectedUsers
        .filter((user) => user.onTheGoPin)
        .map((user) => user.clientUserId);

      try {
        await this.USERSNEW_inviteHubSupport({
          mobileAppId: "onthego",
          clientId: this.currentClient.clientId,
          userIds,
        });
        this.$emit("closeDialog", { unselectUsers: true });
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  flex: 1;
}
</style>
