<template>
  <div class="d-flex flex-column">
    <v-btn
      small
      icon
      class="align-self-end mt-2 mr-2"
      @click="$emit('closeDialog')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="pa-4 pt-0 d-flex flex-column">
      <v-progress-circular
        size="60"
        width="3"
        indeterminate
        color="primary"
        v-if="loader"
        class="align-self-center"
      ></v-progress-circular>

      <template v-else>
        <div class="d-flex flex-column pa-4">
          <div class="d-flex align-center mb-4">
            <div class="subtitle-1 font-weight-medium mr-1">Title:</div>

            <div class="body-1">
              {{ currentMessage.messageTitle }}
            </div>
          </div>

          <div class="d-flex align-center mb-4">
            <div class="subtitle-1 font-weight-medium mr-1">Importance:</div>

            <div class="body-2 text-capitalize">
              {{ currentMessage.importance }}
            </div>
          </div>

          <div class="subtitle-1 font-weight-medium mb-1">Message:</div>

          <div v-html="currentMessage.messageBody"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: ["message"],
  data() {
    return {
      loader: false,
    };
  },
  computed: {
    ...mapState({
      currentMessage: (state) => state.dashboard.currentMessage,
    }),
  },
  async created() {
    this.loader = true;

    try {
      await this.getMessage(this.message);
    } catch (e) {
      console.log(e);
    }

    this.loader = false;
  },
  methods: {
    ...mapActions(["getMessage"]),
    ...mapMutations(["setCurrentMessage"]),
  },
  destroyed() {
    this.setCurrentMessage(null);
  },
};
</script>

<style lang="scss" scoped>
</style>
