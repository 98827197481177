<template>
  <div class="component-wrapper d-flex justify-center align-start pt-12 px-5">
    <div class="inner d-flex flex-column">
      <div class="text-h6 text-center mb-1">Password Reminder</div>

      <div class="body-2 text-center mb-8">
        We will send you an email to set a new password
      </div>

      <form novalidate class="d-flex flex-column" @submit.prevent="submit">
        <v-text-field
          label="Your Email"
          type="email"
          dense
          outlined
          hide-details
          class="mb-4"
          v-model="email"
        >
        </v-text-field>

        <v-btn
          class="align-self-center white--text"
          color="filyellow"
          depressed
          :loading="loader"
          type="submit"
          :disabled="$v.$invalid"
        >
          Send
        </v-btn>
      </form>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      loader: false,
    };
  },
  validations: {
    email: { required, email },
  },
  methods: {
    ...mapActions(["sendForgotPasswordEmail"]),
    async submit() {
      this.loader = true;

      try {
        await this.sendForgotPasswordEmail(this.email);
        this.$router.push({ path: "/login" });
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
