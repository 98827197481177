var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{class:{ 'pt-8': _vm.isCollapsed && _vm.$vuetify.breakpoint.mdAndUp },attrs:{"app":"","clipped":"","color":"filblue","mini-variant":_vm.$vuetify.breakpoint.mdAndUp && _vm.isCollapsed,"temporary":_vm.$vuetify.breakpoint.smAndDown,"mobile-breakpoint":"959","id":"sidebar"},nativeOn:{"mouseenter":function($event){return _vm.toggleExpandOnHover(false)},"mouseleave":function($event){return _vm.toggleExpandOnHover(true)}},model:{value:(_vm.sidebar),callback:function ($$v) {_vm.sidebar=$$v},expression:"sidebar"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('v-list',{staticClass:"pt-5",attrs:{"dense":"","nav":""}},[(_vm.info)?_c('v-list-group',{staticClass:"white--text",scopedSlots:_vm._u([{key:"appendIcon",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-down")])]},proxy:true},{key:"activator",fn:function(){return [_c('div',{staticClass:"d-flex align-center mb-4"},[_c('img',{staticClass:"logo mr-5",attrs:{"src":(_vm.baseUrl + "/" + (_vm.info.logoUrl))}}),_c('div',{staticClass:"white--text"},[_c('div',{staticClass:"body-1 font-weight-medium mb-1"},[_vm._v(" "+_vm._s(_vm.info.companyName)+" ")]),_c('div',{staticClass:"body-2 username"},[_vm._v(_vm._s(_vm.info.loggedOnUser))])])])]},proxy:true}],null,false,666247843)},[(_vm.role == 'co' || _vm.role == 'gm')?_c('v-list-item',{staticClass:"white--text",attrs:{"link":""},on:{"click":function($event){return _vm.toggleDialog({
              open: true,
              component: 'Licenses',
              width: '800px',
            })}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-card-account-details")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("My Licenses")])],1)],1):_vm._e(),(_vm.role == 'co' || _vm.role == 'gm' || _vm.role == 'psysupport')?_c('v-list-item',{staticClass:"white--text",attrs:{"link":""},on:{"click":function($event){return _vm.toggleDialog({
              open: true,
              component: 'EndUserLicense',
              width: '800px',
            })}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-license")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("End User License Agreement")])],1)],1):_vm._e(),_c('v-list-item',{staticClass:"white--text",attrs:{"link":""},on:{"click":function($event){return _vm.$emit('openSignoutDialog')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Sign Out")])],1)],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"divider my-2"}):_vm._e(),(!_vm.isCollapsed || _vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"d-flex pt-1 pr-1"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"white","small":"","icon":"","ripple":false},on:{"click":_vm.togglePin}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.isPinned ? "mdi-pin-off" : "mdi-pin"))])],1):_vm._e()],1):_vm._e(),(_vm.role == 'co' || _vm.role == 'gm' || _vm.role == 'psysupport')?_c('AdminSidebar',{attrs:{"isCollapsed":_vm.isCollapsed}}):_vm._e(),(_vm.role == 'filistos' || _vm.role == 'allpsysup')?_c('SuperAdminSidebar'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }