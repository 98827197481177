var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white dialog-wrapper scrollable-dialog small"},[_c('div',{staticClass:"primary pa-3 d-flex align-start"},[_c('div',{staticClass:"text-h6 white--text"},[_vm._v("Remind Invitations")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","small":"","color":"white"},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"pa-5 flex-grow-1 d-flex flex-column"},[_c('v-simple-table',{staticClass:"scrollable",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"}),_c('th',{staticClass:"text-left"},[_vm._v("First Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Last Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Email")]),_c('th',{staticClass:"text-left"},[_vm._v("OnTheGo Pin")]),_c('th',{staticClass:"text-left"},[_vm._v("OnTheGo Email")])])]),_c('tbody',_vm._l((_vm.localSelectedUsers),function(user,index){return _c('tr',{key:user.name,class:{ 'error white--text': user.notAdded }},[_c('td',[_c('v-btn',{attrs:{"icon":"","small":"","color":user.notAdded ? 'white' : 'error'},on:{"click":function($event){return _vm.unselectUser(index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1),_c('td',[_vm._v(_vm._s(user.firstName)+" "+_vm._s(user.notAdded))]),_c('td',[_vm._v(" "+_vm._s(user.lastName)+" ")]),_c('td',[_vm._v(" "+_vm._s(user.userEmail ? user.userEmail : "-")+" ")]),_c('td',[_vm._v(" "+_vm._s(user.onTheGoPin ? user.onTheGoPin : "-")+" ")]),_c('td',[_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticClass:"px-1 rounded",class:{
                    'success white--text':
                      user.onTheGoInvitationStatus == 'Click',
                    'warning lighten-2 white--text':
                      user.onTheGoInvitationStatus == 'Open' ||
                      user.onTheGoInvitationStatus == 'Delivery',
                    'warning white--text':
                      user.onTheGoInvitationStatus == 'Sent' ||
                      user.onTheGoInvitationStatus == 'Delivery' ||
                      user.onTheGoInvitationStatus == 'Dispatched',
                    'error white--text':
                      user.onTheGoInvitationStatus == 'Bounce' ||
                      user.onTheGoInvitationStatus == 'DispatchError',
                    'primary white--text':
                      user.onTheGoInvitationStatus == 'Login',
                  }},[_vm._v(" "+_vm._s(user.onTheGoInvitationStatus ? user.onTheGoInvitationStatus : "-")+" ")])])])])}),0)]},proxy:true}])}),_c('v-btn',{staticClass:"align-self-start mb-2",attrs:{"color":"primary","small":"","depressed":"","loading":_vm.loader},on:{"click":_vm.onRemind}},[_vm._v("Remind")]),_c('div',{staticClass:"caption"},[_vm._v(" * Users that do not have a pin or their email status is \"click\" will be automatically ignored. ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }