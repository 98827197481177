import { render, staticRenderFns } from "./AssignmentDetails.vue?vue&type=template&id=56e7e19a&scoped=true&"
import script from "./AssignmentDetails.vue?vue&type=script&lang=js&"
export * from "./AssignmentDetails.vue?vue&type=script&lang=js&"
import style0 from "./AssignmentDetails.vue?vue&type=style&index=0&id=56e7e19a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56e7e19a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VDataTable})
