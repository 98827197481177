import store from '@/store'

import Assignee from '../views/assignee/Assignee.vue'
import Questionnaire from '../views/assignee/questionnaire/Questionnaire.vue'
import ELearning from '../views/assignee/ELearning.vue'

export default [
    {
        path: '/assignee/:userAssignmentId',
        component: Assignee,
        meta: { requiresUnauth: true, role: "assignee" },
    },
    {
        path: '/assignee/:userAssignmentId/questionnaire/:questionnaireId/:language',
        component: Questionnaire,
        meta: { requiresUnauth: true, role: "assignee" },
        beforeEnter: async (to, from, next) => {
            const found = store.state.assignee.assignment?.questionnaires?.find(q => q.assignUserQuesId == to.params.questionnaireId);

            if (found) {
                next()
            } else {
                next(`/assignee/${to.params.userAssignmentId}`);
            }
        }
    },
    {
        path: '/assignee/:userAssignmentId/e-learning/:questionnaireId',
        component: ELearning,
        meta: { requiresUnauth: true, role: "assignee" },
        beforeEnter: async (to, from, next) => {
            const found = store.state.assignee.assignment?.questionnaires?.find(q => q.assignUserQuesId == to.params.questionnaireId);

            if (found) {
                store.commit("getQuestionnaire", found);
                next()
            } else {
                next(`/assignee/${to.params.userAssignmentId}`);
            }
        }
    },
] 
