<template>
  <div class="white pa-4 d-flex flex-column align-center">
    <div class="subtitle-1 font-weight-medium text-center mb-1">
      You have unsaved changes
    </div>

    <div class="body-2 text-center mb-3">
      Are you sure you want to leave? All unsaved changes will be lost if you
      leave this page.
    </div>

    <v-btn
      class="mt-4 mb-2 white--text"
      depressed
      color="filyellow"
      @click="toggleDiscardDialog({ open: false })"
      >Stay on this page</v-btn
    >

    <v-btn class="standard-size" color="error" text @click="onDiscard"
      >Discard changes and leave</v-btn
    >
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      discardDialog: (state) => state.base.discardDialog,
    }),
  },
  methods: {
    ...mapMutations(["toggleDiscardDialog"]),
    onDiscard() {
      this.toggleDiscardDialog({
        open: false,
        allowLeave: true,
      });

      if (this.discardDialog.to) {
        this.$router.push({ path: this.discardDialog.to });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
