<template>
  <div class="component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column">
    <div class="d-flex flex-column flex-lg-row align-lg-center mb-5">
      <div class="d-flex mb-5 mb-lg-0">
        <v-icon class="mr-2">mdi-account</v-icon>

        <div class="body-1 font-weight-medium">Users</div>
      </div>

      <div class="d-flex ml-lg-auto flex-wrap flex-sm-nowrap align-center">
        <v-progress-circular
          size="30"
          width="3"
          indeterminate
          color="primary"
          v-if="loader"
        ></v-progress-circular>

        <template v-if="info">
          <div class="d-flex flex-column mb-5 mb-sm-0 statistic">
            <div class="caption">TOTAL USERS</div>

            <div class="d-flex align-center">
              <v-icon class="mr-1" color="secondary">mdi-account</v-icon>

              <div class="body-1 secondary--text">
                {{ info.totalUsers }}
              </div>
            </div>
          </div>

          <div class="divider mx-3 mb-5 mb-sm-0"></div>

          <div class="d-flex flex-column mb-5 mb-sm-0 statistic">
            <div class="caption">TOTAL GROUPS</div>

            <div class="d-flex align-center">
              <v-icon class="mr-1" color="secondary"
                >mdi-account-multiple</v-icon
              >

              <div class="body-1 secondary--text">
                {{ info.totalGroups }}
              </div>
            </div>
          </div>

          <div class="divider mx-3" v-if="$vuetify.breakpoint.smAndUp"></div>

          <div class="break-line"></div>

          <div class="d-flex flex-column statistic">
            <div class="caption">
              QUESTIONNAIRES <br />
              ASSIGNED
            </div>

            <div class="d-flex align-center">
              <v-icon class="mr-1" color="filpurple"
                >mdi-format-list-numbered</v-icon
              >

              <div class="body-1 filpurple--text">
                {{ info.assignedQuest }}
              </div>
            </div>
          </div>

          <div class="divider mx-3"></div>

          <div class="d-flex flex-column statistic">
            <div class="caption">
              QUESTIONNAIRES <br />
              COMPLETED
            </div>

            <div class="d-flex align-center">
              <v-icon class="mr-1" color="success"
                >mdi-checkbox-marked-circle</v-icon
              >

              <div class="body-1 success--text">
                {{ info.completedQuest }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <NewUser
      class="mb-5"
      @groupChanged="onGroupChanged"
      @groupUnionChanged="onGroupUnionChanged"
    />

    <UsersList :selectedGroups="selectedGroups" :union="union" />
  </div>
</template>

<script>
import NewUser from "@/views/admin/users/NewUser";
import UsersList from "@/views/admin/users/UsersList";
import { mapState } from "vuex";

export default {
  components: {
    NewUser,
    UsersList,
  },
  data() {
    return {
      selectedGroups: [],
      union: "all",
      loader: false,
    };
  },
  computed: {
    ...mapState({
      info: (state) => state.base.info,
    }),
  },
  methods: {
    onGroupChanged(selectedGroups) {
      this.selectedGroups = selectedGroups;
    },
    onGroupUnionChanged(union) {
      this.union = union;
    },
  },
};
</script>

<style lang="scss" scoped>
.break-line {
  @media only screen and (max-width: 600px) {
    flex-basis: 100%;
  }
}

.divider {
  background-color: rgba(0, 0, 0, 0.12);
  width: 1px;
  align-self: stretch;
}

.statistic {
  @media only screen and (max-width: 600px) {
    width: 100px;
  }
}
</style>
