var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-btn',{staticClass:"white--text align-self-end",attrs:{"color":"filyellow","depressed":"","to":("/superadmin/clients/" + (_vm.$route.params.clientId) + "/transactions/new")}},[_vm._v("Add Purchase")]),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"align-self-start flex-grow-0 mr-auto",attrs:{"outlined":"","dense":"","label":"Search","prepend-inner-icon":"mdi-magnify","hint":"Search by Questionnaire","persistent-hint":"","value":_vm.transactionsParams.search,"readonly":_vm.loader,"clearable":""},on:{"input":function($event){return _vm.setTransactionsParams({ search: $event })}}}),_c('ShowPerPage',{staticClass:"mr-2",attrs:{"currentPerPage":_vm.transactionsParams.itemsPerPage,"options":_vm.showPerPageOptions}}),_c('FilterBy',{attrs:{"fields":_vm.filterFields,"loader":_vm.loader},on:{"filterChange":_vm.onFilterChange}})],1),_c('v-data-table',{staticClass:"clickable mb-4",attrs:{"headers":_vm.headers,"items":_vm.transactions.results,"server-items-length":_vm.transactions.rowCount,"checkbox-color":"primary","hide-default-footer":"","options":_vm.options,"loading":_vm.loader},on:{"update:options":[function($event){_vm.options=$event},function($event){return _vm.onGetTransactions(true)}]},scopedSlots:_vm._u([{key:"item.transactionDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs.utc(item.transactionDate).local().format("DD/MM/YYYY HH:mm"))+" ")]}},{key:"item.assignmentName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assignmentName ? item.assignmentName : "-")+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isUnlimited ? "Unlimited" : item.quantity)+" ")]}},{key:"item.expiryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isUnlimited ? _vm.dayjs.utc(item.expiryDate).local().format("DD/MM/YYYY") : "-")+" ")]}}],null,true)}),(_vm.transactions.pageCount)?_c('v-pagination',{staticClass:"mt-auto",attrs:{"length":_vm.transactions.pageCount},model:{value:(_vm.transactionsParams.page),callback:function ($$v) {_vm.$set(_vm.transactionsParams, "page", $$v)},expression:"transactionsParams.page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }