import axios from "axios";
import dayjs from "dayjs";

export default {
  state: {
    departments: [],
    questionnaire: null,
    crewCombinationsResults: [],
    savedSelected: null,
    filters: null,

    fetched: false,
  },

  getters: {
    departmentTitles: (state) => {
      return state.departments.results.map((el) => {
        return {
          departmentId: el.departmentId,
          departmentName: el.departmentName,
        };
      });
    },
  },

  mutations: {
    getDepartments(state, departments) {
      state.departments = departments;
    },

    setQuestionnaire(state, questionnaire) {
      state.questionnaire = questionnaire;
    },

    getCrewCombinationResults(state, crewCombinationsResults) {
      state.crewCombinationsResults = crewCombinationsResults;
    },

    isFetched(state, fetched) {
      state.fetched = fetched;
    },

    saveSelected(state, selected) {
      state.savedSelected = selected;
    },

    setFilters(state, filters) {
      state.filters = filters;
    },
  },

  actions: {
    async getDepartments({ commit, state, rootState }, payload) {
      try {
        const role = rootState.auth.role;

        const roleControllers = {
          co: "ClientAdmin",
          gm: "GroupManager",
          filistos: "SuperAdmin",
          psysupport: "psysup",
          allpsysup: "SuperPsySup",
        };
        console.log(payload.factorGroupIds);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${roleControllers[role]}/Getdepartments`,
          {
            params: {
              userTypeId: payload.userTypeId,
              questionnaireId: payload.questionnaireId,
              // factorGroupIds: payload.factorGroupIds,
              evaluatedFrom: dayjs.utc(payload.dateFrom).toISOString(),
            },
          }
        );

        commit("getDepartments", res.data);
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });
        throw e;
      }
    },

    async getCrewCombinationResults({ commit, state, rootState }, payload) {
      try {
        const role = rootState.auth.role;
        const roleControllers = {
          co: "ClientAdmin",
          gm: "GroupManager",
          psysupport: "psysup",
          allpsysup: "SuperPsySup",
          filistos: "SuperAdmin",
        };

        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/${roleControllers[role]}/CombineResults`,
          {
            ...payload,
          }
        );

        commit("getCrewCombinationResults", res.data);
        commit("saveSelected", payload);
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },

    async combinationGetExcel({ commit, state, rootState }) {
      try {
        const role = rootState.auth.role;
        const roleControllers = {
          co: "ClientAdmin",
          gm: "GroupManager",
          psysupport: "psysup",
          allpsysup: "SuperPsySup",
          filistos: "SuperAdmin",
        };

        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/${roleControllers[role]}/DownloadCombinationsToExcel`,
          {
            // questionnaireId:
            //     role == 'psysupport' || role == 'allpsysup' ? 'selfsupport' : state.userQuestsParams.questionnaire.questionnaireId,
            // assignUserQuestIds: state.userQuestsToCompare,
            // ...(state.userQuestsParams.nationalityId && {
            //     nationalityId: state.userQuestsParams.nationalityId,
            // }),
            // ...(state.userQuestsParams.position.positionId && {
            //     workPositionId: state.userQuestsParams.position.positionId,
            // }),
            ...state.savedSelected,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/vnd.ms-excel",
            },
          }
        );

        const filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0];

        let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
        var fileURL = window.URL.createObjectURL(blob);
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
  },
};
