<template>
  <div class="d-flex flex-column flex-wrap">
    <v-file-input
      label="Upload Image"
      outlined
      dense
      hide-details
      accept="image/*"
      class="mb-5"
      v-model="image"
      @change="selectImage"
    ></v-file-input>
    <!-- <v-img
      v-if="image"
      :alt="image"
      :src="url"
      class="ma-2 selectable"
      max-height="100"
      max-width="100"
      @click="selectImage(image)"
    /> -->
  </div>
</template>
  
  <script>
/**
 * Example of a custom Image selector
 * Key is to emit a select-file event when a file needs to be added
 */
import { VImg } from "vuetify/lib";
import axios from "axios";
export default {
  components: { VImg },

  name: "Upload",
  data() {
    // Some public domain images from wikimedia.
    return {
      image: [],
      url: null,
    };
  },
  methods: {
    async selectImage() {
      if (this.image) this.url = URL.createObjectURL(this.image);
      else {
        this.url = null;
      }
      let formData = new FormData();
      formData.set("FileUpload", this.image);
      const res = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/system/UploadNotifImage`,
        formData
      );
      const imageElement = {
        src: `https://act2.filistosascot.com/back/${res.data}`,
        alt: "image",
      };

      this.$emit("select-file", imageElement);
    },

    previewImage() {},
  },
};
</script>
  
  <style >
.selectable {
  cursor: pointer;
}

.v-icon.notranslate.material-icons.theme--dark {
  visibility: hidden;
  /* margin-right: 30px; */
}

.v-icon.notranslate.material-icons.theme--dark:after {
  content: "\2715";
  position: absolute;
  visibility: visible;
  top: 0;
  margin-top: 4px;
  padding-right: 3px;
  color: white;
  transform: none;

  /* vuetify style override */
  opacity: 1;
  background-color: transparent;
  border-radius: 0;
  rotate: -2deg;
}
</style>