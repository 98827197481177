import store from "@/store";

import Dashboard from "../views/admin/dashboard/Dashboard.vue";
import Groups from "../views/admin/groups/Groups.vue";
import Users from "../views/admin/users/Users.vue";
import Assignment from "../views/admin/assignment/Assignment.vue";
import Schedule from "../views/admin/schedule/Schedule.vue";
import ComparisonPossibleToCompare from "../views/admin/comparison/ComparisonPossibleToCompare.vue";
import ComparisonResults from "../views/admin/comparison/ComparisonResults.vue";
import CrewCombinations from "../views/admin/crew-combinations/CrewCombinations.vue";
import CrewCombinationsDepartment from "../views/admin/crew-combinations/CrewCombinationsDepartment.vue";
import CrewCombinationsResults from "../views/admin/crew-combinations/CrewCombinationsResults.vue";
import Notifications from "@/views/admin/notifications/Notifications.vue";
// ====== MONITORING ======
import Monitoring from "../views/superadmin/monitoring/Monitoring.vue";
import AssignmentReport from "@/views/admin/assignment/assignment-dialog/report/AssignmentReport";

import Report from "../views/admin/assignment/assignment-dialog/report/AssignmentReport";

import Statistics from "../views/admin/statistics/Statistics";

export default [
  {
    path: "/",
    component: Dashboard,
    meta: { requiresAuth: true, role: ["co", "gm", "psysupport"] },
    beforeEnter: async (to, from, next) => {
      try {
        store.commit("toggleGlobalLoader", true);

        await Promise.all([
          store.dispatch("getUsersPerAccess"),
          store.dispatch("getEvaluatedUsers", {
            questionnaireTypeId: "psycho",
            period: "year",
          }),
          store.dispatch("getUsersPerNationality"),
          store.dispatch("getDashboardAssignments"),
          store.dispatch("getMessages"),
        ]);

        store.commit("toggleGlobalLoader", false);

        next();
      } catch (e) {
        store.commit("toggleGlobalLoader", false);
        store.commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });
        next(false);
      }
    },
  },
  {
    path: "/groups",
    component: Groups,
    meta: { requiresAuth: true, role: ["co"] },
  },
  {
    path: "/users",
    component: Users,
    meta: { requiresAuth: true, role: ["co", "gm"] },
  },
  {
    path: "/assignment",
    component: Assignment,
    meta: { requiresAuth: true, role: ["co", "gm"] },
  },
  {
    path: "/notifications",
    component: Notifications,
    meta: { requiresAuth: true, role: ["co", "gm"] },
  },
  {
    path: "/schedule",
    component: Schedule,
    meta: { requiresAuth: true, role: ["co", "gm"] },
  },
  {
    path: "/monitoring",
    component: Monitoring,
    name: "adminMonitoring",
    meta: { requiresAuth: true, role: ["co", "gm"] },
    beforeEnter: async (to, from, next) => {
      try {
        store.commit("toggleGlobalLoader", true);

        await store.dispatch("getMonitoring");

        store.commit("toggleGlobalLoader", false);

        next();
      } catch (e) {
        console.log(e);
        next("/");
      }
    },
  },
  {
    path: "/monitoring/:reportId",
    component: AssignmentReport,
    name: "adminMonitoringReport",
    meta: { requiresAuth: true, role: ["co", "gm"] },
  },
  {
    path: "/comparison",
    component: ComparisonPossibleToCompare,
    name: "adminComparisonPossible",
    meta: { requiresAuth: true, role: ["co", "gm"] },
  },
  {
    path: "/comparison/results",
    component: ComparisonResults,
    name: "adminComparisonResults",
    meta: { requiresAuth: true, role: ["co", "gm"] },
    beforeEnter: async (to, from, next) => {
      try {
        if (
          store.state.comparison.userQuestsToCompare.length &&
          store.state.comparison.userQuestsParams.questionnaire?.questionnaireId
        ) {
          next();
        } else {
          next("/comparison");
        }
      } catch (e) {
        console.log(e);
        next("/");
      }
    },
  },
  {
    path: "/report/:reportId",
    component: Report,
    name: "reportLink",
    meta: { requiresAuth: true, role: ["co", "gm"] },
  },
  {
    path: "/statistics",
    component: Statistics,
    name: "adminStatistics",
    meta: { requiresAuth: true, role: ["co", "gm"] },
  },

  {
    path: "/crew-combinations",
    component: CrewCombinations,
    meta: { requiresAuth: true, role: ["co", "gm"] },

    beforeEnter: async (to, from, next) => {
      if (from.fullPath != "/crew-combinations/get/results") {
        store.commit("setFilters", null);
        store.commit("getDepartments", []);
        store.commit("isFetched", false);
      }
      next();
    },

    children: [
      {
        path: ":department_name",
        component: CrewCombinationsDepartment,
        name: "adminCrewCombinations",
        meta: {
          requiresAuth: true,
          role: ["co", "gm"],
        },

        beforeEnter: async (to, from, next) => {
          if (from.fullPath != "/crew-combinations/get/results") {
            store.commit("saveSelected", null);
          }
          try {
            if (
              store.state.crew_combinations.departments?.results?.length > 0
            ) {
              next();
            } else {
              next("/crew-combinations");
            }
          } catch (e) {
            console.log(e);
            next("/");
          }
        },
      },
    ],
  },

  {
    path: "/crew-combinations/get/results",
    component: CrewCombinationsResults,
    name: "adminCrewCombinationResults",
    meta: { requiresAuth: true, role: ["co", "gm"] },
    beforeEnter: async (to, from, next) => {
      try {
        if (
          store.state.crew_combinations.crewCombinationsResults?.results
            ?.length > 0
        ) {
          next();
        } else {
          next("/crew-combinations");
        }
      } catch (e) {
        console.log(e);
        next("/");
      }
    },
  },
];
