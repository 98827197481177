var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{staticClass:"flex-grow-1",attrs:{"color":"primary","left":"","value":Boolean(_vm.countFilters),"content":_vm.countFilters,"overlap":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","width":"100%"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v(" Filter ")],1)],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('div',{staticClass:"white pa-5"},_vm._l((_vm.fields),function(field){return _c('div',{key:field.key},[(field.key == 'position')?[_c('v-radio-group',{staticClass:"mt-0 mb-2",attrs:{"row":"","dense":"","hide-details":""},model:{value:(_vm.positionType),callback:function ($$v) {_vm.positionType=$$v},expression:"positionType"}},_vm._l((_vm.userTypes),function(type){return _c('v-radio',{key:type.itemKey,attrs:{"value":type.itemKey,"label":type.itemValue,"ripple":false}})}),1),_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"","label":"Position","items":_vm.positions,"item-value":"itemKey","item-text":"itemValue","clearable":"","readonly":_vm.loader,"value":field.selectedValue.positionId},on:{"change":function($event){return _vm.onFilterChange($event, field.key)}}})]:(field.key == 'dateFrom' || field.key == 'dateTo')?_c('div',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"value":_vm.getFormattedDate(field.selectedValue),"dense":"","prepend-inner-icon":"mdi-calendar","hide-details":"","outlined":"","clearable":"","label":field.value,"readonly":""},on:{"click:clear":function($event){return _vm.onFilterChange(null, field.key)}}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"value":field.selectedValue},on:{"change":function($event){_vm.fromMenu = false;
                _vm.onFilterChange($event, field.key);}}})],1)],1):_c('v-select',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","hide-details":"","label":field.value,"items":field.values,"item-value":field.key == 'groupId' ? 'groupId' : 'itemKey',"item-text":field.key == 'groupId' ? 'groupName' : 'itemValue',"clearable":"","readonly":_vm.loader,"value":field.selectedValue},on:{"change":function($event){return _vm.onFilterChange($event, field.key)}}})],2)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }