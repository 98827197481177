<template>
  <div class="wrapper d-flex flex-column rounded flex-grow-1">
    <div
      class="
        d-flex
        flex-column flex-md-row
        align-center align-md-start
        justify-start
        pa-5
        pb-1
      "
    >
      <div class="mr-md-5 mb-1 d-flex align-start">
        <v-text-field
          outlined
          dense
          class="flex-grow-0 mr-sm-5 mb-1 mb-sm-0"
          label="Search"
          hint="Search by Title"
          persistent-hint
          prepend-inner-icon="mdi-magnify"
          :value="groupsParams.search"
          @input="setGroupsParams({ search: $event })"
          :readonly="groupsLoader"
        ></v-text-field>

        <v-btn
          icon
          color="primary"
          v-if="groupsParams.search"
          @click="setGroupsParams({ search: '' })"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="d-flex flex-column flex-sm-row ml-md-auto mb-2 mb-md-0">
        <ShowPerPage
          class="mr-2"
          :currentPerPage="groupsParams.itemsPerPage"
          :options="showPerPageOptions"
        />

        <ColumnVisibility
          :headers="headers"
          @visibilityChanged="onVisibilityChanged"
          @initLoaderChanged="onVisibilityLoaderChanged"
          grid="groupsList"
        />

        <!-- <v-btn class="mr-sm-4 mb-4 mb-sm-0" depressed>
          <v-icon left>mdi-cloud-download</v-icon>
          CSV
        </v-btn>

        <v-btn depressed>
          <v-icon left>mdi-cloud-download</v-icon>
          PDF
        </v-btn> -->
      </div>
    </div>

    <v-data-table
      :headers="visibleHeaders"
      :items="!groupsLoader && !visibilityLoader ? groups.results : []"
      :server-items-length="groups.rowCount"
      class="clickable"
      @click:row="dialog = { open: true, item: $event }"
      :loading="groupsLoader || visibilityLoader"
      @update:options="onGetGroups"
      :options="options"
      hide-default-footer
    >
      <template v-slot:[`item.groupDescription`]="{ item }">
        {{ item.groupDescription ? item.groupDescription : "-" }}
      </template>

      <template v-slot:[`item.creationDate`]="{ item }">
        {{
          item.creationDate
            ? dayjs(item.creationDate).format("DD/MM/YYYY")
            : "-"
        }}
      </template>
    </v-data-table>

    <div class="pagination-wrapper py-3 mt-auto">
      <v-pagination
        :length="groups.pageCount"
        v-model="groupsParams.page"
        :disabled="groupsLoader"
        v-if="groups.pageCount"
      ></v-pagination>
    </div>

    <v-dialog
      v-model="dialog.open"
      max-width="800px"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <GroupDialog
        v-if="dialog.open"
        :selectedGroup="dialog.item"
        @closeDialog="dialog = { open: false, item: null }"
      />
    </v-dialog>
  </div>
</template>

<script>
import ColumnVisibility from "@/components/ColumnVisibility";
import GroupDialog from "@/views/admin/groups/group-dialog/GroupDialog";
import { mapActions, mapMutations, mapState } from "vuex";
import ShowPerPage from "@/components/ShowPerPage";

export default {
  props: ["selectedType"],
  components: {
    ColumnVisibility,
    GroupDialog,
    ShowPerPage,
  },
  data() {
    return {
      headers: [
        { text: "Title", value: "groupName" },
        { text: "Description", value: "groupDescription", sortable: false },
        { text: "Creation Date", value: "creationDate" },
        { text: "Type", value: "groupTypeName" },
      ],
      visibleHeaders: [],
      dialog: {
        open: false,
        item: null,
      },
      timeout: null,
      visibilityLoader: false,
      showPerPageOptions: [],
    };
  },
  computed: {
    ...mapState({
      groupsParams: (state) => state.groups.groupsParams,
      groups: (state) => state.groups.groups,
      groupsLoader: (state) => state.groups.groupsLoader,
    }),
    options() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.groupsParams;

      return {
        itemsPerPage,
        page,
        sortBy,
        sortDesc,
      };
    },
  },
  created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setGroupsParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setGroupsParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setGroupsParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setGroupsParams,
      },
    ];
  },
  methods: {
    ...mapMutations(["setGroupsParams", "setVisibleColumns"]),
    ...mapActions(["getGroups"]),
    async onGetGroups(event) {
      if (this.groupsLoader) return;

      let { itemsPerPage, page, sortBy, sortDesc } = event || {};

      if (event) {
        this.setGroupsParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      } //this happens because this method is called from either @update:options or selectedType watcher

      try {
        await this.getGroups();
      } catch (e) {
        console.log(e);
      }
    },
    onVisibilityChanged(visibleHeaders) {
      this.visibleHeaders = visibleHeaders
        .filter((header) => header.visible)
        .map((header) => {
          return {
            text: header.text,
            value: header.value,
            ...(typeof header.sortable === "boolean" &&
              !header.sortable && {
                sortable: header.sortable,
              }),
          };
        });
    },
    onVisibilityLoaderChanged(loader) {
      this.visibilityLoader = loader;
    },
  },
  watch: {
    selectedType(newValue) {
      this.setGroupsParams({
        groupTypeId: newValue,
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
      });

      this.onGetGroups();
    },
    "groupsParams.search"() {
      clearTimeout(this.timeout);

      if (this.groupsLoader) return;

      this.timeout = setTimeout(() => {
        this.setGroupsParams({
          page: 1,
        });

        this.onGetGroups();
      }, 500);
    },
  },
  destroyed() {
    this.setGroupsParams({
      groupTypeId: null,
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: [],
      search: "",
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
