<template>
  <div class="component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column">
    <div
      class="d-flex flex-column flex-lg-row align-start align-lg-center mb-sm-5"
    >
      <div class="d-flex mb-5 mb-lg-0">
        <v-icon class="mr-2">mdi-send</v-icon>

        <div class="body-1 font-weight-medium">Assignments</div>
      </div>

      <div class="d-flex ml-lg-auto flex-wrap flex-sm-nowrap align-center">
        <div class="d-flex flex-column mb-5 mb-sm-0 statistic">
          <div class="caption">ASSIGNMENTS</div>

          <div class="d-flex align-center">
            <v-icon class="mr-1" color="secondary">mdi-send</v-icon>

            <div class="body-1 secondary--text">
              {{ info.totalAssignments }}
            </div>
          </div>
        </div>

        <div class="divider mx-3 mb-5 mb-sm-0"></div>

        <div class="d-flex flex-column mb-5 mb-sm-0 statistic">
          <div class="caption">QUESTIONNAIRES SEND</div>

          <div class="d-flex align-center">
            <v-icon class="mr-1" color="filpurple"
              >mdi-format-list-numbered</v-icon
            >

            <div class="body-1 filpurple--text">{{ info.assignedQuest }}</div>
          </div>
        </div>

        <div class="divider mx-3" v-if="$vuetify.breakpoint.smAndUp"></div>

        <div class="break-line"></div>

        <div class="d-flex flex-column mb-5 mb-sm-0 statistic">
          <div class="caption">COMPLETED</div>

          <div class="d-flex align-center">
            <v-icon class="mr-1" color="success"
              >mdi-checkbox-marked-circle</v-icon
            >

            <div class="body-1 success--text">{{ info.completedQuest }}</div>
          </div>
        </div>
      </div>
    </div>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="warning"
          depressed
          class="align-self-lg-end align-self-start mb-5"
          v-bind="attrs"
          v-on="on"
        >
          Add Assignment
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item link @click="dialog = true">
          <v-list-item-title>Add Assignment</v-list-item-title>
        </v-list-item>

        <!-- <v-list-item link>
          <v-list-item-title>Import Assignments</v-list-item-title>
        </v-list-item>

        <v-list-item link>
          <v-list-item-title>Import Assignments Answers</v-list-item-title>
        </v-list-item>

        <v-list-item link>
          <v-list-item-title>Calculate Assignments Results</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-menu>

    <AssignmentsList />

    <v-dialog
      v-model="dialog"
      max-width="1250px"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <AssignmentDialog
        @closeDialog="dialog = false"
        v-if="dialog"
        :isEdit="false"
      />
    </v-dialog>
  </div>
</template>

<script>
import AssignmentsList from "@/views/admin/assignment/AssignmentsList";
import AssignmentDialog from "@/views/admin/assignment/assignment-dialog/AssignmentDialog";
import { mapState } from "vuex";

export default {
  components: {
    AssignmentsList,
    AssignmentDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      info: (state) => state.base.info,
    }),
  },
};
</script>

<style lang="scss" scoped>
.break-line {
  @media only screen and (max-width: 600px) {
    flex-basis: 100%;
  }
}

.divider {
  background-color: rgba(0, 0, 0, 0.12);
  width: 1px;
  align-self: stretch;
}

.statistic {
  @media only screen and (max-width: 600px) {
    width: 100px;
  }
}
</style>
