<template>
  <form @submit.prevent="submit">
    <div
      v-for="(flag, index) in factor.flags"
      :key="flag.colorFlag"
      :class="{ 'mb-4': index != factor.flags.length - 1 }"
      class="d-flex flex-column"
    >
      <div
        class="d-flex justify-space-between flag-header px-2 py-1 rounded"
        :class="{
          filyellow: flag.colorFlag == 'Y',
          filgreen: flag.colorFlag == 'G',
          filred: flag.colorFlag == 'R',
          disabled: flagOpen && hasChanges,
        }"
        @click="toggleFlagOpen(flag.colorFlag)"
      >
        <div class="subtitle-1 font-weight-medium white--text">
          {{ flag.colorFlag }}
        </div>

        <v-icon color="white">mdi-chevron-down</v-icon>
      </div>

      <div
        class="flag-body d-flex flex-column"
        v-if="flagOpen == flag.colorFlag"
      >
        <v-tabs v-model="rankTab" class="mb-5">
          <v-tab
            v-for="rank in flag.rankLevels"
            :key="rank.rankLevelId"
            :href="`#${rank.rankLevelId}`"
            :disabled="hasChanges"
            @click="rankTabChange(rank)"
          >
            {{ rank.rankLevelName }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="rankTab">
          <v-tab-item
            v-for="rank in flag.rankLevels"
            :key="rank.rankLevelId"
            :value="rank.rankLevelId"
          >
            <div class="d-flex align-center justify-start mb-6">
              <div class="d-flex justify-start pt-2 mr-4">
                <v-text-field
                  dense
                  hide-details
                  outlined
                  label="Step 1"
                  v-model.number="step1"
                  class="mr-4 flex-grow-0"
                >
                </v-text-field>

                <v-text-field
                  dense
                  hide-details
                  outlined
                  label="Step 2"
                  v-model.number="step2"
                  class="flex-grow-0"
                >
                </v-text-field>
              </div>

              <div class="d-flex">
                <v-btn
                  color="primary"
                  depressed
                  :disabled="!hasChanges"
                  class="mr-4"
                  @click="initializeForm"
                  >Reset</v-btn
                >

                <v-btn
                  color="primary"
                  depressed
                  :disabled="!hasChanges"
                  type="submit"
                  :loading="loader"
                  >Save</v-btn
                >
              </div>
            </div>

            <v-tabs vertical v-model="langTab">
              <div class="mr-4">
                <v-tab
                  v-for="factorTextLang in rank.factorTexts"
                  :key="factorTextLang.langId"
                  :href="`#${factorTextLang.langId}`"
                  :disabled="hasChanges"
                >
                  {{ factorTextLang.langName }}
                </v-tab>
              </div>

              <v-tab-item
                v-for="factorTextLang in rank.factorTexts"
                :key="factorTextLang.langId"
                :value="factorTextLang.langId"
              >
                <div class="d-flex mb-8">
                  <div class="d-flex flex-column mr-4 html-wrapper">
                    <div class="subtitle-2 mb-4">Text</div>

                    <tiptap-vuetify
                      v-model="text"
                      :extensions="extensions"
                      class="d-flex flex-column flex-1"
                    />
                  </div>

                  <div class="d-flex flex-column html-wrapper">
                    <div class="subtitle-2 mb-4">Action</div>

                    <tiptap-vuetify
                      :extensions="extensions"
                      v-model="action"
                      class="d-flex flex-column flex-1"
                    />
                  </div>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </form>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  History,
  Image,
} from "tiptap-vuetify";
import { mapActions } from "vuex";

export default {
  components: { TiptapVuetify },
  props: ["factor"],
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        Paragraph,
        HardBreak,
        Image,
      ],
      flagOpen: null,
      rankTab: "0",
      langTab: "en",
      step1: null,
      step2: null,
      text: null,
      action: null,
      initialData: null,
      loader: false,
      factorFlagId: null,
      firstTime: true,
    };
  },
  computed: {
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !=
        JSON.stringify({
          step1: this.step1,
          step2: this.step2,
          ...(this.text && { text: this.text }),
          ...(this.action && { action: this.action }),
        })
      );
    },
  },
  methods: {
    ...mapActions(["updateFactorFlag"]),
    getColor(color) {
      if (color == "Y") return "filyellow";
      if (color == "G") return "filgreen";
      return "filred";
    },

    toggleFlagOpen(flag) {
      if (this.hasChanges && this.flagOpen) return;

      this.flagOpen == flag ? (this.flagOpen = null) : (this.flagOpen = flag);
    },

    initializeForm(firstTime) {
      const openFlag = this.factor.flags.find(
        (flag) => flag.colorFlag == this.flagOpen
      );

      if (firstTime) {
        this.rankTab = openFlag.rankLevels[0].rankLevelId;
        this.firstTime = false;
      }
      const openRank = openFlag.rankLevels.find(
        (rank) => rank.rankLevelId == this.rankTab
      );

      this.factorFlagId = openRank.factorFlagId;

      this.step1 = openRank.lowLimit;
      this.step2 = openRank.highLimit;

      const openLang = openRank.factorTexts.find(
        (lang) => lang.langId == this.langTab
      );

      this.text = openLang.text;
      this.action = openLang.action;

      this.initialData = {
        step1: this.step1,
        step2: this.step2,
        ...(this.text && { text: this.text }),
        ...(this.action && { action: this.action }),
      };
    },

    rankTabChange(rank) {
      const openFlag = this.factor.flags.find(
        (flag) => flag.colorFlag == this.flagOpen
      );

      const openRank = openFlag.rankLevels.find(
        (rank1) => rank1.rankLevelId == rank.rankLevelId
      );

      this.factorFlagId = openRank.factorFlagId;

      this.step1 = openRank.lowLimit;
      this.step2 = openRank.highLimit;

      const openLang = openRank.factorTexts.find(
        (lang) => lang.langId == this.langTab
      );

      this.text = openLang.text;
      this.action = openLang.action;

      this.initialData = {
        step1: this.step1,
        step2: this.step2,
        ...(this.text && { text: this.text }),
        ...(this.action && { action: this.action }),
      };
    },

    async submit() {
      this.loader = true;

      console.log(this.$route.name);

      try {
        await this.updateFactorFlag({
          isChild: this.$route.name == "questionnaireChildFactor",
          factorId: this.factor.factorId,
          factorFlagId: this.factorFlagId,
          languageId: this.langTab,
          lowLimit: this.step1,
          highLimit: this.step2,
          text: this.text,
          action: this.action,
        });

        this.initializeForm();
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  watch: {
    flagOpen(newValue, oldValue) {
      if (newValue != oldValue) this.rankTab = "0";

      if (newValue) {
        this.initializeForm();
      }
    },
    rankTab() {
      // if (this.flagOpen) this.initializeForm();
    },
    langTab() {
      if (this.flagOpen) this.initializeForm();
    },
  },
};
</script>

<style lang="scss" >
.flag-header {
  cursor: pointer;

  &.disabled {
    opacity: 0.6;
  }
}

.html-wrapper {
  width: 50%;
  height: 400px;
  overflow-y: auto;
}

.tiptap-vuetify-editor {
  .v-card {
    flex: 1;
  }
}
</style>
