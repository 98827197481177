<template>
  <div class="white dialog-wrapper scrollable-dialog small">
    <div class="primary pa-3 d-flex align-start">
      <div class="text-h6 white--text">Remind Invitations</div>

      <v-btn
        icon
        small
        color="white"
        class="ml-auto"
        @click="$emit('closeDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <div class="pa-5 flex-grow-1 d-flex flex-column">
      <v-simple-table class="scrollable">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left"></th>

              <th class="text-left">First Name</th>

              <th class="text-left">Last Name</th>

              <th class="text-left">Email</th>

              <th class="text-left">OnTheGo Pin</th>

              <th class="text-left">OnTheGo Email</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, index) in localSelectedUsers"
              :key="user.name"
              :class="{ 'error white--text': user.notAdded }"
            >
              <td>
                <v-btn
                  icon
                  small
                  :color="user.notAdded ? 'white' : 'error'"
                  @click="unselectUser(index)"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </td>

              <td>{{ user.firstName }} {{ user.notAdded }}</td>

              <td>
                {{ user.lastName }}
              </td>

              <td>
                {{ user.userEmail ? user.userEmail : "-" }}
              </td>

              <td>
                {{ user.onTheGoPin ? user.onTheGoPin : "-" }}
              </td>

              <td>
                <div class="d-flex justify-start">
                  <div
                    class="px-1 rounded"
                    :class="{
                      'success white--text':
                        user.onTheGoInvitationStatus == 'Click',
                      'warning lighten-2 white--text':
                        user.onTheGoInvitationStatus == 'Open' ||
                        user.onTheGoInvitationStatus == 'Delivery',
                      'warning white--text':
                        user.onTheGoInvitationStatus == 'Sent' ||
                        user.onTheGoInvitationStatus == 'Delivery' ||
                        user.onTheGoInvitationStatus == 'Dispatched',
                      'error white--text':
                        user.onTheGoInvitationStatus == 'Bounce' ||
                        user.onTheGoInvitationStatus == 'DispatchError',
                      'primary white--text':
                        user.onTheGoInvitationStatus == 'Login',
                    }"
                  >
                    {{
                      user.onTheGoInvitationStatus
                        ? user.onTheGoInvitationStatus
                        : "-"
                    }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-btn
        class="align-self-start mb-2"
        color="primary"
        small
        depressed
        :loading="loader"
        @click="onRemind"
        >Remind</v-btn
      >

      <div class="caption">
        * Users that do not have a pin or their email status is "click" will be
        automatically ignored.
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["selectedUsers"],
  data() {
    return {
      localSelectedUsers: [],
      loader: false,
    };
  },
  created() {
    this.localSelectedUsers = [...this.selectedUsers];
  },
  methods: {
    ...mapActions(["remindInvitation"]),
    unselectUser(index) {
      this.localSelectedUsers.splice(index, 1);

      this.$emit("unselectUser", index);
    },
    async onRemind() {
      this.loader = true;

      const userIds = this.localSelectedUsers
        .filter(
          (user) => user.onTheGoPin && user.onTheGoInvitationStatus != "Click"
        )
        .map((user) => user.clientUserId);

      try {
        await this.remindInvitation({
          mobileAppId: "onthego",
          userIds,
        });
        this.$emit("closeDialog", { unselectUsers: true });
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  flex: 1;
}
</style>
