<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Section</th>
            <th class="text-left">Total Questions</th>
            <th class="text-left">Answered Questions</th>
            <th class="text-left">Correct Answers</th>
            <th class="text-left">Success %</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(section, index) in report.gradedSections"
            :key="`${index} - ${section.name}`"
          >
            <td>{{ section.name }}</td>
            <td>{{ section.totalQuestCount }}</td>
            <td>{{ section.answeredQuestCount }}</td>
            <td>{{ section.correctAnswerCount }}</td>
            <td>{{ parseFloat(section.correctPercentage.toFixed(2)) }}</td>
          </tr>

          <tr class="font-weight-bold">
            <td>{{ report.totalGraded.name }}</td>
            <td>{{ report.totalGraded.totalQuestCount }}</td>
            <td>{{ report.totalGraded.answeredQuestCount }}</td>
            <td>{{ report.totalGraded.correctAnswerCount }}</td>
            <td>
              {{ parseFloat(report.totalGraded.correctPercentage.toFixed(2)) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      report: (state) => state.assignments.report,
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
