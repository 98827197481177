<template>
  <div class="component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column">
    <div class="d-flex mb-5">
      <v-icon class="mr-2">mdi-account</v-icon>

      <div class="body-1 font-weight-medium">Cases</div>
    </div>

    <div class="wrapper d-flex flex-column rounded pa-4 flex-grow-1">
      <div class="d-flex">
        <v-text-field
          outlined
          dense
          label="Search"
          prepend-inner-icon="mdi-magnify"
          hint="Search by Last Name"
          persistent-hint
          :value="selfEvalsParams.search"
          @input="setSelfEvalsParams({ search: $event })"
          :readonly="loader"
          clearable
          class="align-self-start flex-grow-0 mr-auto"
        ></v-text-field>

        <ShowPerPage
          class="mr-2"
          :currentPerPage="selfEvalsParams.itemsPerPage"
          :options="showPerPageOptions"
        />

        <FilterBy
          :fields="filterFields"
          :loader="loader"
          @filterChange="onFilterChange"
        />
      </div>

      <v-data-table
        :headers="headers"
        :items="selfEvals.results"
        :server-items-length="selfEvals.rowCount"
        class="clickable mb-4"
        checkbox-color="primary"
        hide-default-footer
        @click:row="
          !globalLoader &&
            $router.push(
              `/${role == 'allpsysup' ? 'allpsysup' : 'psysupport'}/cases/${
                $event.examinationId
              }`
            )
        "
        :options.sync="options"
        @update:options="onGetSelfEvals()"
        :loading="loader"
      >
        <template v-slot:[`item.ondate`]="{ item }">
          {{ dayjs.utc(item.ondate).local().format("DD/MM/YYYY HH:mm") }}
        </template>
      </v-data-table>

      <v-pagination
        class="mt-auto"
        :length="selfEvals.pageCount"
        v-model="selfEvalsParams.page"
        v-if="selfEvals.pageCount"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import FilterBy from "@/components/FilterBy";
import ShowPerPage from "@/components/ShowPerPage";

export default {
  components: {
    FilterBy,
    ShowPerPage,
  },
  data() {
    return {
      headers: [
        { text: "Date", value: "ondate" },
        { text: "First Name", value: "firstName", sortable: false },
        { text: "Last Name", value: "lastName", sortable: false },
      ],
      loader: false,
      timeout: null,
      isFirstCall: true,
      showPerPageOptions: [],
    };
  },
  computed: {
    ...mapState({
      selfEvalsParams: (state) => state.psysupport.selfEvalsParams,
      selfEvals: (state) => state.psysupport.selfEvals,
      globalLoader: (state) => state.base.globalLoader,
      allClients: (state) => state.base.allClients,
      role: (state) => state.auth.role,
    }),
    options: {
      get() {
        const { itemsPerPage, page, sortBy, sortDesc } = this.selfEvalsParams;

        return {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        };
      },
      set(value) {
        let { itemsPerPage, page, sortBy, sortDesc } = value;

        this.setSelfEvalsParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      },
    },
    filterFields() {
      return [
        ...(this.role == "allpsysup"
          ? [
              {
                key: "clientId",
                value: "Client",
                values: this.allClients.map((client) => {
                  return {
                    itemKey: client.clientId,
                    itemValue: client.companyName,
                  };
                }),
                selectedValue: this.selfEvalsParams.clientId,
              },
            ]
          : []),
        {
          key: "dateFrom",
          value: "From",
          selectedValue: this.selfEvalsParams.dateFrom
            ? this.dayjs
                .utc(this.selfEvalsParams.dateFrom)
                .local()
                .toISOString()
                .substr(0, 10)
            : null,
        },
        {
          key: "dateTo",
          value: "To",
          selectedValue: this.selfEvalsParams.dateTo
            ? this.dayjs
                .utc(this.selfEvalsParams.dateTo)
                .local()
                .toISOString()
                .substr(0, 10)
            : null,
        },
      ];
    },
  },
  async created() {
    if (this.role == "allpsysup")
      this.headers.push({
        text: "Client",
        value: "clientName",
        sortable: false,
      });

    this.loader = true;

    try {
      await this.getSelfEvals("hub");
    } catch (e) {
      console.log(e);
    }

    this.loader = false;

    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setSelfEvalsParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setSelfEvalsParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setSelfEvalsParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setSelfEvalsParams,
      },
    ];
  },
  methods: {
    ...mapMutations(["setSelfEvalsParams"]),
    ...mapActions(["getSelfEvals"]),
    async onGetSelfEvals() {
      if (this.isFirstCall) {
        this.isFirstCall = false;
        return;
      }

      this.loader = true;

      try {
        await this.getSelfEvals("hub");
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    onFilterChange(e) {
      this.setSelfEvalsParams({
        [e.field]:
          (e.field == "dateFrom" || e.field == "dateTo") && e.value
            ? this.dayjs.utc(e.value).toISOString()
            : e.value,
      });

      this.onGetSelfEvals();
    },
  },
  watch: {
    "selfEvalsParams.search"() {
      clearTimeout(this.timeout);

      if (this.loader) return;

      this.timeout = setTimeout(() => {
        this.setSelfEvalsParams({
          page: 1,
        });

        this.onGetSelfEvals();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
