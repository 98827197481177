var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper d-flex flex-column pb-0"},[_c('div',{staticClass:"text-h6 text-center mb-4"},[_vm._v("New Purchase")]),_c('form',{staticClass:"flex-grow-1 d-flex flex-column",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"scrollable"},_vm._l((_vm.questionnaireTypes),function(type,index){return _c('div',{key:type.questionnaireTypeId,class:{ 'mb-4': index != _vm.questionnaireTypes.length - 1 }},[_c('div',{staticClass:"subtitle-2 grey lighten-4 rounded pa-2"},[_vm._v(" "+_vm._s(type.questionnaireTypeName)+" ("+_vm._s(type.questionnaires.length)+") ")]),_c('v-list',{staticClass:"new-purchase",attrs:{"dense":""}},_vm._l((type.questionnaires),function(questionnaire){return _c('v-list-item',{key:questionnaire.questionnaireId},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex align-center",class:{
                  'primary lighten-3':
                    _vm.groupHeads[questionnaire.questionnaireId] &&
                    _vm.groupHeads[questionnaire.questionnaireId].children.length,
                }},[_c('v-checkbox',{staticClass:"pa-0 ma-0 mr-4",attrs:{"dense":"","hide-details":"","ripple":false,"value":questionnaire.questionnaireId,"readonly":""},on:{"click":function($event){return _vm.onCheckboxClick(questionnaire)}},model:{value:(_vm.selectedQuestionnaires),callback:function ($$v) {_vm.selectedQuestionnaires=$$v},expression:"selectedQuestionnaires"}}),_c('v-list-item-title',{staticClass:"flex-grow-0 mr-5 pl-1"},[_vm._v(_vm._s(questionnaire.questionnaireName))]),(questionnaire.isGroupHead)?_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.openQuestionnaireGroup == questionnaire.questionnaireId
                      ? (_vm.openQuestionnaireGroup = null)
                      : (_vm.openQuestionnaireGroup =
                          questionnaire.questionnaireId)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),(_vm.transactionsReduced[questionnaire.questionnaireId])?_c('div',{staticClass:"d-flex"},[_c('v-chip',{staticClass:"mr-1",attrs:{"small":"","color":"primary"}},[_c('div',{staticClass:"subtitle-2 mr-1"},[_vm._v("Quantity:")]),_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm.transactionsReduced[questionnaire.questionnaireId] .isUnlimited ? "Unlimited" : _vm.transactionsReduced[questionnaire.questionnaireId] .quantity)+" ")])]),(
                      _vm.transactionsReduced[questionnaire.questionnaireId]
                        .isUnlimited
                    )?_c('v-chip',{staticClass:"mr-1",attrs:{"small":"","color":"primary"}},[_c('div',{staticClass:"subtitle-2 mr-1"},[_vm._v("Until:")]),_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm.dayjs( _vm.transactionsReduced[questionnaire.questionnaireId] .expiryDate ).format("DD/MM/YYYY"))+" ")])]):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":"","color":"filyellow"},on:{"click":function($event){return _vm.editItem(questionnaire)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1):_vm._e()],1),(_vm.openQuestionnaireGroup == questionnaire.questionnaireId)?_c('v-list',{staticClass:"new-purchase",attrs:{"dense":""}},_vm._l((questionnaire.subQuestionnaires),function(subquestionnaire){return _c('v-list-item',{key:subquestionnaire.questionnaireId},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex align-center"},[(!subquestionnaire.isGroupHead)?_c('v-checkbox',{staticClass:"pa-0 ma-0 mr-4",attrs:{"dense":"","hide-details":"","ripple":false,"value":subquestionnaire.questionnaireId,"readonly":"","disabled":_vm.selectedQuestionnaires.includes(
                            questionnaire.questionnaireId
                          )},on:{"click":function($event){return _vm.onCheckboxClick(subquestionnaire)}},model:{value:(_vm.selectedQuestionnaires),callback:function ($$v) {_vm.selectedQuestionnaires=$$v},expression:"selectedQuestionnaires"}}):_vm._e(),_c('v-list-item-title',{staticClass:"flex-grow-0 mr-5"},[_vm._v(_vm._s(subquestionnaire.questionnaireName))]),(subquestionnaire.isGroupHead)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.openQuestionnaireGroup ==
                          subquestionnaire.questionnaireId
                            ? (_vm.openQuestionnaireGroup = null)
                            : (_vm.openQuestionnaireGroup =
                                subquestionnaire.questionnaireId)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),(
                          _vm.transactionsReduced[
                            subquestionnaire.questionnaireId
                          ] &&
                            !_vm.selectedQuestionnaires.includes(
                              questionnaire.questionnaireId
                            )
                        )?_c('div',{staticClass:"d-flex"},[_c('v-chip',{staticClass:"mr-1",attrs:{"small":"","color":"primary"}},[_c('div',{staticClass:"subtitle-2 mr-1"},[_vm._v("Quantity:")]),_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm.transactionsReduced[ subquestionnaire.questionnaireId ].isUnlimited ? "Unlimited" : _vm.transactionsReduced[ subquestionnaire.questionnaireId ].quantity)+" ")])]),(
                            _vm.transactionsReduced[
                              subquestionnaire.questionnaireId
                            ].isUnlimited
                          )?_c('v-chip',{staticClass:"mr-1",attrs:{"small":"","color":"primary"}},[_c('div',{staticClass:"subtitle-2 mr-1"},[_vm._v("Until:")]),_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm.dayjs( _vm.transactionsReduced[ subquestionnaire.questionnaireId ].expiryDate ).format("DD/MM/YYYY"))+" ")])]):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":"","color":"filyellow"},on:{"click":function($event){return _vm.editItem(subquestionnaire)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1):_vm._e()],1)])],1)}),1):_vm._e()],1)],1)}),1)],1)}),0),_c('v-btn',{staticClass:"align-self-center white--text",attrs:{"color":"filyellow","depressed":"","type":"submit","disabled":!this.transactions.length,"loading":_vm.loader}},[_vm._v("New Purchase")])],1),_c('v-dialog',{attrs:{"max-width":"450px"},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open)?_c('div',{staticClass:"white d-flex flex-column"},[_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"header"},[_c('v-btn',{staticClass:"right back-btn",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = {
                open: false,
                questionnnaire: null,
                groupHeadId: null,
              }}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1),_c('div',{staticClass:"subtitle-1 font-weight-medium text-center"},[_vm._v(" "+_vm._s(_vm.openQuestionnaire.questionnaireName)+" ")])],1)]),_c('div',{staticClass:"pa-4 d-flex flex-column"},[(!_vm.isUnlimited)?_c('v-text-field',{staticClass:"mb-4",attrs:{"outlined":"","dense":"","hide-details":"","label":"Quantity","type":"number"},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=_vm._n($$v)},expression:"quantity"}}):_vm._e(),(_vm.isUnlimited)?_c('v-menu',{ref:"expiryDate",attrs:{"max-width":"290","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4 flex-grow-0",attrs:{"value":_vm.expiryDate ? _vm.dayjs(_vm.expiryDate).format('DD/MM/YYYY') : '',"dense":"","prepend-inner-icon":"mdi-calendar","hide-details":"","outlined":"","label":"Expiry Date","readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,3714401018)},[_c('v-date-picker',{on:{"change":function($event){return _vm.$refs.expiryDate.save()}},model:{value:(_vm.expiryDate),callback:function ($$v) {_vm.expiryDate=$$v},expression:"expiryDate"}})],1):_vm._e(),_c('v-checkbox',{staticClass:"pa-0 ma-0 mb-4",attrs:{"dense":"","hide-details":"","ripple":false,"label":"Unlimited"},model:{value:(_vm.isUnlimited),callback:function ($$v) {_vm.isUnlimited=$$v},expression:"isUnlimited"}}),_c('v-btn',{staticClass:"align-self-center white--text",attrs:{"color":"filyellow","depressed":"","disabled":(_vm.isUnlimited && !_vm.expiryDate) || (!_vm.isUnlimited && !_vm.quantity)},on:{"click":_vm.saveDialog}},[_vm._v("Save")])],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }