import axios from 'axios';

export default {
    state: {
        currentQuestionnaireType: null,
        currentQuestionnaire: null,
        currentFactor: null,
        currentChildFactor: null,
        currentSection: null,
        currentQuestion: null,
        currentQuestionnaireFactors: null,
    },
    mutations: {
        setCurrentQuestionnaireType(state, questionnaireType) {
            state.currentQuestionnaireType = questionnaireType
        },
        setCurrentQuestionnaire(state, questionnaire) {
            state.currentQuestionnaire = questionnaire
        },
        getFactor(state, factor) {
            state.currentFactor = factor;
        },
        getChildFactor(state, factor) {
            state.currentChildFactor = factor;
        },
        getSection(state, section) {
            state.currentSection = section;
        },
        getQuestion(state, question) {
            state.currentQuestion = question;
        },
        getAllFactorsOfQuestionnaire(state, factors) {
            state.currentQuestionnaireFactors = factors;
        }
    },
    actions: {
        async getClientAdminQuestionnaire({ commit, state }, questionnaireId) {
            try {
                if (state.currentQuestionnaire?.questionnaireId == questionnaireId) return;

                commit("toggleGlobalLoader", true);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetQuestionnaire/${questionnaireId}`);

                commit("setCurrentQuestionnaire", res.data)
                commit("toggleGlobalLoader", false);
            } catch (e) {
                commit("toggleGlobalLoader", false);
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async updateQuestionnaire({ commit, dispatch }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/updateQuestionnaire`, payload);

                dispatch("getQuestionnaires");

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Questionnaire has been updated",
                    timeout: 5000
                })
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getFactor({ commit, state }, payload) {
            try {
                if (state?.currentFactor?.factorId == payload.factorId) return;

                commit("toggleGlobalLoader", true);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetFactor/${payload.factorId}`);

                if (!payload.isChild) {
                    commit("getFactor", res.data);
                } else {
                    commit("getChildFactor", res.data);
                }

                commit("toggleGlobalLoader", false);
            } catch (e) {
                commit("toggleGlobalLoader", false);
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async updateFactor({ commit, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/updateFactor`, payload);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetQuestionnaire/${state.currentQuestionnaire.questionnaireId}`);

                commit("setCurrentQuestionnaire", res.data)

                const res1 = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetFactor/${payload.factorId}`);

                if (!payload.isChild) {
                    commit("getFactor", res1.data);
                } else {
                    commit("getChildFactor", res1.data);
                }

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Factor has been updated!",
                    timeout: 5000
                })
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async updateFactorFlag({ commit }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/UpdateFactorFlag`, payload);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetFactor/${payload.factorId}`);

                if (!payload.isChild) {
                    commit("getFactor", res.data);
                } else {
                    commit("getChildFactor", res.data);
                }

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Factor flag has been updated!",
                    timeout: 5000
                })
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getSection({ commit, state }, sectionId) {
            if (state?.currentSection?.sectionId == sectionId) return;

            try {
                commit("toggleGlobalLoader", true);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetQuestionnaireSection/${sectionId}`);

                commit("getSection", res.data);

                commit("toggleGlobalLoader", false);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                commit("toggleGlobalLoader", false);

                throw e;
            }
        },
        async updateSection({ commit }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/UpdateSection`, payload);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetQuestionnaireSection/${payload.sectionId}`);

                commit("getSection", res.data);

                const res1 = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetQuestionnaire/${payload.questionnaireId}`);

                commit("setCurrentQuestionnaire", res1.data)

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Section has been updated!",
                    timeout: 5000
                })
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getQuestion({ commit, state }, questionId) {
            if (state?.currentQuestion?.questionId == questionId) return;

            try {
                commit("toggleGlobalLoader", true);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetQuestion/${questionId}`);

                commit("getQuestion", res.data);

                commit("toggleGlobalLoader", false);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                commit("toggleGlobalLoader", false);

                throw e;
            }
        },
        async updateQuestion({ commit }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/UpdateQuestion`, payload);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetQuestionnaireSection/${payload.sectionId}`);

                commit("getSection", res.data);

                const res1 = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetQuestion/${payload.questionId}`);

                commit("getQuestion", res1.data);

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Question has been updated!",
                    timeout: 5000
                })
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getAllFactorsOfQuestionnaire({ commit, state }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetParentFactorsOfQuestionnaire`, {
                    params: {
                        questionnaireId: state.currentQuestionnaire.questionnaireId
                    }
                });

                commit("getAllFactorsOfQuestionnaire", res.data.results);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        }
    }
}
