var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"stepper d-flex pa-5",class:{
      'pb-8': _vm.$vuetify.breakpoint.smAndUp,
      'pb-2': _vm.$vuetify.breakpoint.xsOnly,
    }},[_c('div',{staticClass:"step",on:{"click":function($event){return _vm.changeStep(1)}}},[_c('div',{staticClass:"\n          step-number\n          d-flex\n          align-center\n          justify-center\n          text-h6\n          rounded\n          mb-1\n        ",class:{ done: _vm.step > 1, current: _vm.step == 1, edit: _vm.isEdit }},[_vm._v(" 1 ")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"step-text first"},[_vm._v(" Basic Information ")]):_vm._e()]),(
        !_vm.isEdit &&
        (!_vm.preselectedUsersBanner || !_vm.preselectedQuestionnairesBanner)
      )?[_c('v-divider'),_c('div',{staticClass:"step",on:{"click":function($event){return _vm.changeStep(2)}}},[_c('div',{staticClass:"\n            step-number\n            d-flex\n            align-center\n            justify-center\n            text-h6\n            rounded\n            mb-1\n          ",class:{ done: _vm.step > 2, current: _vm.step == 2, unreached: _vm.step < 2 }},[_vm._v(" 2 ")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"step-text"},[_vm._v(" Questionnaires ")]):_vm._e()]),_c('v-divider'),_c('div',{staticClass:"step",on:{"click":function($event){return _vm.changeStep(3)}}},[_c('div',{staticClass:"\n            step-number\n            d-flex\n            align-center\n            justify-center\n            text-h6\n            rounded\n            mb-1\n          ",class:{ done: _vm.step > 3, current: _vm.step == 3, unreached: _vm.step < 3 }},[_vm._v(" 3 ")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"step-text"},[_vm._v("Users")]):_vm._e()])]:_vm._e(),_c('v-divider'),_c('div',{staticClass:"step",on:{"click":function($event){return _vm.changeStep(4)}}},[_c('div',{staticClass:"\n          step-number\n          d-flex\n          align-center\n          justify-center\n          text-h6\n          rounded\n          mb-1\n        ",class:{
          done: _vm.step > 4,
          current: _vm.step == 4,
          unreached: _vm.step < 4,
          edit: _vm.isEdit,
        }},[_vm._v(" "+_vm._s(_vm.isEdit || (_vm.preselectedUsersBanner && _vm.preselectedQuestionnairesBanner) ? 2 : 4)+" ")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"step-text"},[_vm._v(" "+_vm._s(_vm.isEdit ? "Overview" : "Save")+" ")]):_vm._e()])],2),(
      (_vm.step1Error && _vm.step == 1) ||
      (_vm.step2Error && _vm.step == 2) ||
      (_vm.step3Error && _vm.step == 3)
    )?_c('div',{staticClass:"px-5 mb-4"},[_c('div',{staticClass:"\n        d-flex\n        align-center\n        error\n        white--text\n        pa-1\n        body-2\n        font-weight-medium\n        rounded\n      "},[(_vm.step == 1)?_c('span',[_vm._v(" Please fill the required fields ")]):_vm._e(),(_vm.step == 2)?_c('span',[_vm._v(" Please select at least one questionnaire ")]):_vm._e(),(_vm.step == 3)?_c('span',[_vm._v(" Please select at least one user. ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"scrollable pa-5 pb-0 pt-0 d-flex flex-column"},[_c(_vm.activeComponent,{tag:"component",attrs:{"v":_vm.$v,"isEdit":_vm.isEdit}})],1),_c('div',{staticClass:"d-flex justify-space-between px-5 py-3 mt-auto"},[(_vm.step != 1)?_c('v-btn',{staticClass:"mr-5",attrs:{"small":"","depressed":""},on:{"click":function($event){return _vm.changeStep(_vm.step - 1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Previous ")],1):_vm._e(),_c('div',{staticClass:"d-flex align-center ml-auto"},[(_vm.isEdit && _vm.hasChanges)?_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","depressed":"","color":"warning","loading":_vm.updateLoader},on:{"click":_vm.onUpdateAssignment}},[_vm._v(" Update Assignment "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-content-save ")])],1):_vm._e(),_c('v-btn',{class:{ 'white--text': _vm.step == 4 && !_vm.isEdit },attrs:{"small":"","depressed":"","color":_vm.step < 4 ? '' : _vm.isEdit ? '' : 'success ',"loading":!_vm.isEdit && _vm.loader,"disabled":_vm.form.hasAssessor && !_vm.form.assessorId && _vm.step == 4},on:{"click":function($event){return _vm.changeStep(_vm.step + 1)}}},[(_vm.step < 4)?[_c('span',[_vm._v(" Next ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])]:[_c('span',[_vm._v(" "+_vm._s(_vm.isEdit ? "Close" : "Create Assignment")+" ")]),(!_vm.isEdit)?_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check")]):_vm._e()]],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }