var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('v-select',{staticClass:"mr-5",attrs:{"outlined":"","hide-details":"","dense":"","label":"Add Group Managers","items":_vm.possibleGroupManagers,"item-value":"clientUserId","item-text":function (item) { return ((item.lastName) + " " + (item.firstName) + " | " + (item.identityNumber)); },"loading":_vm.getPossibleGroupManagersLoader,"multiple":"","chips":"","small-chips":""},model:{value:(_vm.selectedGroupManagers),callback:function ($$v) {_vm.selectedGroupManagers=$$v},expression:"selectedGroupManagers"}}),_c('v-btn',{attrs:{"depressed":"","color":"success","disabled":!_vm.selectedGroupManagers.length,"loading":_vm.addLoader},on:{"click":_vm.onAddGroupManagersToGroup}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add ")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.groupManagers,"loading":_vm.getGroupManagersLoader,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastName)+" "+_vm._s(item.firstName)+" ")]}},{key:"item.remove",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"top":"","left":"","close-on-content-click":false,"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"error","x-small":""}},'v-btn',attrs,false),on),[_vm._v(" Remove")])]}}],null,true),model:{value:(item.deleteMenu),callback:function ($$v) {_vm.$set(item, "deleteMenu", $$v)},expression:"item.deleteMenu"}},[_c('div',{staticClass:"white pa-5"},[_c('div',{staticClass:"body-2 mb-4 error--text"},[_vm._v(" Are you sure you want to remove group manager "+_vm._s(item.lastName)+" "+_vm._s(item.firstName)+" "),_c('br'),_vm._v(" from group "+_vm._s(_vm.selectedGroup.groupName)+"? ")]),_c('v-btn',{attrs:{"small":"","depressed":"","color":"error","loading":_vm.removeLoader},on:{"click":function($event){return _vm.onRemoveGroupManagersFromGroup(item.clientUserId)}}},[_vm._v("Remove from Group")])],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }