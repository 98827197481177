import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth";
import base from "./base";

//ADMIN
import dashboard from "./admin/dashboard";
import users from "./admin/users";
import usersNew from "./admin/users-new";
import groups from "./admin/groups";
import assignments from "./admin/assignments";
import schedule from "./admin/schedule";
import comparison from "./admin/comparison";
import crew_combinations from "./admin/crew_combinations";
import notifications from "./admin/notifications";

//ASSIGNEE
import assignee from "./assignee";

//SUPERADMIN
import superAdminDashboard from "./superadmin/dashboard";
import clients from "./superadmin/clients";
import questionnaires from "./superadmin/questionnaires";
import monitoring from "./superadmin/monitoring";

//PSYSUPPORT
import psysupport from "./psysupport";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    base,
    //ADMIN
    dashboard,
    users,
    usersNew,
    groups,
    assignments,
    schedule,
    crew_combinations,
    notifications,
    //ASSIGNEE
    assignee,
    //SUPERADMIN
    superAdminDashboard,
    clients,
    questionnaires,
    monitoring,
    //PSYSUPPORT
    psysupport,
    comparison,
  },
});
