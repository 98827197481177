<template>
  <div class="component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column">
    <div class="d-flex align-center mb-5">
      <v-icon class="mr-2">mdi-calendar</v-icon>

      <div class="body-1 font-weight-medium">Scheduling</div>
    </div>

    <div class="wrapper d-flex flex-column rounded mb-5">
      <div class="pa-5 d-flex flex-column flex-md-row align-center">
        <v-alert color="primary" text border="left" prominent class="pa-5 mb-0">
          <form class="d-flex align-center" @submit.prevent="submit">
            <v-menu max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    label="Select Questionnaire"
                    clearable
                    class="mr-4"
                    readonly
                    append-icon="mdi-menu-down"
                    :value="
                      selectedQuestionnaire
                        ? selectedQuestionnaire.questionnaireName
                        : null
                    "
                    @click:clear="selectedQuestionnaire = null"
                  >
                  </v-text-field>
                </div>
              </template>

              <div class="white pa-4 questionnaire-menu">
                <div
                  v-for="questionnaireType in questionnaires"
                  :key="questionnaireType.questionnaireTypeId"
                >
                  <div class="subtitle-2">
                    {{ questionnaireType.questionnaireTypeName }}
                  </div>

                  <div class="pl-2">
                    <div
                      class="body-2 py-1 px-2 questionnaire"
                      v-for="questionnaire in questionnaireType.questionnaires"
                      :key="questionnaire.questionnaireId"
                      @click="selectedQuestionnaire = questionnaire"
                    >
                      {{ questionnaire.questionnaireName }}
                    </div>
                  </div>
                </div>
              </div>
            </v-menu>

            <v-menu max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    label="Period"
                    clearable
                    class="mr-4"
                    readonly
                    append-icon="mdi-menu-down"
                    :value="selectedPeriod ? selectedPeriod.viewValue : null"
                    @click:clear="selectedPeriod = null"
                  >
                  </v-text-field>
                </div>
              </template>

              <div class="white pa-2 period-menu">
                <div v-for="period in periods" :key="period.value">
                  <div
                    class="body-2 py-1 px-2 period"
                    @click="selectedPeriod = period"
                  >
                    {{ period.viewValue }}
                  </div>
                </div>
              </div>
            </v-menu>

            <v-menu max-width="290" :close-on-content-click="false" ref="date">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="date ? dayjs(date).format('DD/MM/YYYY') : ''"
                  dense
                  prepend-inner-icon="mdi-calendar"
                  hide-details
                  outlined
                  label="Date"
                  class="flex-grow-0 mr-4"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @click:clear="date = null"
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="date"
                @change="$refs.date.save()"
                color="primary"
              ></v-date-picker>
            </v-menu>

            <v-btn
              class="white--text"
              depressed
              color="filyellow"
              :loading="loader"
              type="submit"
              :disabled="!date || !selectedQuestionnaire || !hasChanges"
              >Search</v-btn
            >
          </form>
        </v-alert>
      </div>
    </div>

    <ScheduleList
      v-if="scheduleFetched"
      @setInitialData="initialData = $event"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ScheduleList from "@/views/admin/schedule/ScheduleList";

export default {
  components: {
    ScheduleList,
  },
  data() {
    return {
      date: null,
      selectedQuestionnaire: null,
      selectedPeriod: null,
      periods: [
        {
          value: 1,
          viewValue: "1 Month Ago",
        },
        {
          value: 2,
          viewValue: "2 Months Ago",
        },
        {
          value: 3,
          viewValue: "3 Months Ago",
        },
        {
          value: 6,
          viewValue: "6 Months Ago",
        },
        {
          value: 12,
          viewValue: "12 Months Ago",
        },
        {
          value: 18,
          viewValue: "18 Months Ago",
        },
      ],
      loader: false,
      initialData: null,
    };
  },
  computed: {
    ...mapState({
      questionnaires: (state) => state.base.questionnaires,
      scheduleFetched: (state) => state.schedule.scheduleFetched,
    }),
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !=
        JSON.stringify({
          dateBefore: this.dayjs.utc(this.date).toISOString(),
          questionnaireId: this.selectedQuestionnaire.questionnaireId,
        })
      );
    },
  },
  methods: {
    ...mapActions(["getSchedule"]),
    ...mapMutations(["setScheduleParams", "toggleScheduleFetched"]),
    async submit() {
      this.loader = true;

      try {
        this.setScheduleParams({
          dateBefore: this.dayjs.utc(this.date).toISOString(),
          questionnaire: this.selectedQuestionnaire,
        });

        this.toggleScheduleFetched(true);
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  watch: {
    selectedPeriod(newValue) {
      if (newValue) {
        this.date = this.dayjs()
          .subtract(newValue.value, "months")
          .format("YYYY-MM-DD");
      }
    },
    date(newValue) {
      if (!newValue) this.selectedPeriod = null;

      let matchPeriod = false;

      this.periods.forEach((period) => {
        if (
          this.dayjs()
            .subtract(period.value, "months")
            .isSame(this.dayjs(newValue), "day")
        )
          matchPeriod = true;
      });

      if (!matchPeriod) this.selectedPeriod = null;
    },
  },
  destroyed() {
    this.$store.commit("clearScheduleState");
  },
};
</script>

<style lang="scss" scoped>
.questionnaire-menu {
  height: 400px;
  overflow-y: auto;
}

.period-menu {
  height: 200px;
  overflow-y: auto;
}

.questionnaire,
.period {
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
}
</style>
