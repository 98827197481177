var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white d-flex flex-column"},[_c('div',{staticClass:"primary pa-4 d-flex align-start"},[_c('div',{staticClass:"text-h6 white--text"},[_vm._v(" Add new Assignment ")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","small":"","color":"white"},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"d-flex pa-8"},[_c('v-expansion-panels',{staticClass:"mr-6",attrs:{"multiple":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"subtitle-2"},[_vm._v(" Basic Information ")])])]),_c('v-expansion-panel-content',{staticClass:"pt-4"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"6"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"outlined":"","hide-details":"","dense":"","label":"Selected User","value":_vm.selectedUser.fullName,"readonly":""}}),_c('v-text-field',{staticClass:"mb-4",attrs:{"outlined":"","hide-details":"","dense":"","label":"Title","error-messages":_vm.titleErrors},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"value":_vm.dateToSendFormatted,"dense":"","prepend-inner-icon":"mdi-calendar","hide-details":"","outlined":"","label":"Date to Send","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateToSendMenu),callback:function ($$v) {_vm.dateToSendMenu=$$v},expression:"dateToSendMenu"}},[_c('v-date-picker',{attrs:{"min":new Date().toISOString().substr(0, 10)},on:{"change":_vm.onDateToSendChanged},model:{value:(_vm.dateToSend),callback:function ($$v) {_vm.dateToSend=$$v},expression:"dateToSend"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"value":_vm.dateToRemindFormatted,"dense":"","prepend-inner-icon":"mdi-calendar","hide-details":"","outlined":"","label":"Date to Remind","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateToRemindMenu),callback:function ($$v) {_vm.dateToRemindMenu=$$v},expression:"dateToRemindMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.dateToSend
                      ? _vm.dayjs
                          .utc(_vm.dateToSend)
                          .local()
                          .toISOString()
                          .substr(0, 10)
                      : null},on:{"change":function($event){_vm.dateToRemindMenu = false}},model:{value:(_vm.dateToRemind),callback:function ($$v) {_vm.dateToRemind=$$v},expression:"dateToRemind"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"value":_vm.dateToEndFormatted,"dense":"","prepend-inner-icon":"mdi-calendar","hide-details":"","outlined":"","label":"Date End","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateToEndMenu),callback:function ($$v) {_vm.dateToEndMenu=$$v},expression:"dateToEndMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.dateToSend
                      ? _vm.dayjs
                          .utc(_vm.dateToSend)
                          .local()
                          .toISOString()
                          .substr(0, 10)
                      : null},on:{"change":function($event){_vm.dateToEndMenu = false}},model:{value:(_vm.dateToEnd),callback:function ($$v) {_vm.dateToEnd=$$v},expression:"dateToEnd"}})],1),_c('v-select',{staticClass:"mb-4",attrs:{"outlined":"","hide-details":"","dense":"","label":"Type","items":_vm.assignmentSendTypes,"item-text":"itemValue","return-object":"","error-messages":_vm.sendTypeIdErrors},model:{value:(_vm.sendTypeId),callback:function ($$v) {_vm.sendTypeId=$$v},expression:"sendTypeId"}}),_c('v-select',{attrs:{"outlined":"","dense":"","label":"Email Confirmation to","value":_vm.usersToNotifyIds,"loading":_vm.usersToNotifyLoader,"items":_vm.possitbleUsersToNotify,"item-text":"userEmail","return-object":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","hide-details":"","error-messages":_vm.usersToNotifyIdsErrors},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                      var item = ref.item;
                      var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-checkbox',{staticClass:"pa-0 ma-0 mr-2",attrs:{"input-value":attrs.inputValue,"readonly":"","dense":"","hide-details":""}}),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item.userEmail))])])],1)]}}]),model:{value:(_vm.usersToNotifyIds),callback:function ($$v) {_vm.usersToNotifyIds=$$v},expression:"usersToNotifyIds"}})],1)],1)],1)],1)],1),_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.secondTab),callback:function ($$v) {_vm.secondTab=$$v},expression:"secondTab"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"subtitle-2"},[_vm._v(" E-Learnings ")])]),_c('v-expansion-panel-content',{staticClass:"pt-4"},[_c('v-data-table',{attrs:{"items":_vm.eLearnings,"headers":_vm.headers,"hide-default-footer":""}})],1)],1)],1)],1),_c('v-btn',{staticClass:"align-self-end mx-8 my-4",attrs:{"color":"success"},on:{"click":_vm.onAddAssignment}},[_vm._v(" Add Assignment ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }