import axios from 'axios';

export default {
    state: {
        usersPerAccess: null,
        evaluatedUsers: null,
        usersPerNationality: null,
        dashboardAssignments: null,
        dashboardAssignmentsParams: {
            questionnaireTypeId: "psycho",
            year: new Date().getFullYear(),
            month: null
        },
        messagesParams: {
            itemsPerPage: 5,
            page: 1,
            sortBy: [],
            sortDesc: [],
            search: "",
        },
        messages: {
            results: [],
            rowCount: 0
        },
        messagesFetched: false,
        currentMessage: null,
    },
    mutations: {
        getUsersPerAccess(state, users) {
            state.usersPerAccess = users;
        },
        getEvaluatedUsers(state, evaluatedUsers) {
            state.evaluatedUsers = evaluatedUsers;
        },
        getUsersPerNationality(state, users) {
            state.usersPerNationality = users;
        },
        getDashboardAssignments(state, assignments) {
            state.dashboardAssignments = assignments;
        },
        setDashboardAssignmentsParams(state, params) {
            state.dashboardAssignmentsParams = {
                ...state.dashboardAssignmentsParams,
                ...params
            }
        },
        setMessagesParams(state, params) {
            state.messagesParams = {
                ...state.messagesParams,
                ...params
            }
        },
        getMessages(state, messages) {
            state.messages = messages;
        },
        toggleMessagesFetched(state, fetched) {
            state.messagesFetched = fetched;
        },
        setCurrentMessage(state, message) {
            state.currentMessage = message;
        },
        clearDashboardState(state) {
            state.usersPerAccess = null;
            state.evaluatedUsers = null;
            state.usersPerNationality = null;
            state.dashboardAssignments = null;
            state.dashboardAssignmentsParams = {
                questionnaireTypeId: "psycho",
                year: new Date().getFullYear(),
                month: null
            };
            state.messagesParams = {
                itemsPerPage: 5,
                page: 1,
                sortBy: [],
                sortDesc: [],
                search: "",
            };
            state.messages = {
                results: [],
                rowCount: 0
            };
            state.messagesFetched = false;
            state.currentMessage = null;
        }
    },
    actions: {
        async getUsersPerAccess({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/ClientUser/GetUsersPerAccess`);

                commit("getUsersPerAccess", res.data.results);
            } catch (e) {
                throw e;
            }
        },
        async getEvaluatedUsers({ commit }, params) {
            try {
                const res = await axios.get(
                    `${process.env.VUE_APP_BASE_URL}/ClientUser/DashboardEvaluatedUsers`,
                    {
                        params
                    }
                );

                commit("getEvaluatedUsers", res.data);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getUsersPerNationality({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/ClientUser/GetUsersPerNationality`);

                commit("getUsersPerNationality", res.data.results);
            } catch (e) {
                throw e;
            }

        },
        async getDashboardAssignments({ commit, state }) {
            try {
                const res = await axios.get(
                    `${process.env.VUE_APP_BASE_URL}/ClientUser/DashBoardAssignments`,
                    {
                        params: {
                            questionnaireTypeId: state.dashboardAssignmentsParams.questionnaireTypeId,
                            year: state.dashboardAssignmentsParams.year,
                            month: state.dashboardAssignmentsParams.month
                        }
                    }
                );

                commit("getDashboardAssignments", res.data);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getMessages({ commit, state }, refresh) {
            if (state.messagesFetched && !refresh) return;

            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/ClientUser/GetSAMessages`,
                    {
                        params: {
                            pageSize: state.messagesParams.itemsPerPage != -1 ? state.messagesParams.itemsPerPage : 1000,
                            pageNo: state.messagesParams.page,
                            ...(state.messagesParams.sortBy[0] && { sortBy: state.messagesParams.sortBy[0] }),
                            ...(state.messagesParams.sortDesc[0] && { dir: "desc" }),
                            ...(state.messagesParams.search && { search: state.messagesParams.search }),
                        }
                    });

                commit("getMessages", res.data);
                commit("toggleMessagesFetched", true);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getMessage({ commit, dispatch }, message) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/ClientUser/GetSAMessage/${message.messageId}`);

                if (!message.dateOpened)
                    dispatch("getMessages", true);

                commit("setCurrentMessage", res.data);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async deleteMessage({ commit, dispatch }, messageId) {
            try {
                await axios.get(`${process.env.VUE_APP_BASE_URL}/ClientUser/HideSAMessage/${messageId}`);

                await dispatch("getMessages", true);

                commit("toggleSnackbar", {
                    open: true,
                    color: "warning",
                    text: "Message has been deleted.",
                    timeout: 5000
                })
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        }
    }
}
