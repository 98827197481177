import axios from "axios";
import dayjs from "dayjs";

export default {
  state: {
    userQuestsParams: {
      itemsPerPage: 10000,
      page: 1,
      sortBy: [],
      sortDesc: [],
      questionnaire: null,
      nationalityId: null,
      position: {
        positionType: null,
        positionIds: [],
      },
      assignments: [],
      client: null,
    },
    userQuests: {
      results: [],
      rowCount: 0,
    },
    userQuestsToCompare: [],
    positionsToCompare: [],
    comparisonResults: [],
    factorGroupIds: [],
  },
  mutations: {
    setUserQuestsParams(state, params) {
      state.userQuestsParams = {
        ...state.userQuestsParams,
        ...params,
      };
    },
    getUserQuests(state, userQuests) {
      state.userQuests = userQuests;
    },
    setUserQuestsToCompare(state, userQuestsToCompare) {
      state.userQuestsToCompare = userQuestsToCompare;
    },
    setFactorGroupIds(state, factorGroupIds) {
      state.factorGroupIds = factorGroupIds;
    },

    setPositionsToCompare(state, positionsToCompare) {
      state.positionsToCompare = positionsToCompare;
    },
    getComparisonResults(state, comparisonResults) {
      state.comparisonResults = comparisonResults;
    },
    clearComparisonState(state) {
      state.userQuestsParams = {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
        questionnaire: null,
        nationalityId: null,
        position: {
          positionType: null,
          positionId: null,
        },
        assignments: [],
        client: null,
      };
      state.userQuests = {
        results: [],
        rowCount: 0,
      };
      state.userQuestsToCompare = [];
      state.comparisonResults = [];
    },
  },
  actions: {
    async getUserQuests({ commit, state, rootState }) {
      try {
        const role = rootState.auth.role;

        const roleControllers = {
          co: "ClientAdmin",
          gm: "GroupManager",
          filistos: "SuperAdmin",
          psysupport: "psysup",
          allpsysup: "SuperPsySup",
        };

        let assignmentParams = `${
          state.userQuestsParams.assignments.length ? "?" : ""
        }`;

        state.userQuestsParams.assignments.forEach((assignment, index) => {
          assignmentParams += `assignmentId=${assignment}${
            index < state.userQuestsParams.assignments.length - 1 ? "&" : ""
          }`;
        });

        let positionParams = null;
        if (state.userQuestsParams?.position?.positionIds?.length > 0) {
          if (state.userQuestsParams.assignments.length == 0) {
            positionParams = "?";
          } else {
            positionParams = "&";
          }
        }
        if (state.userQuestsParams.position.positionIds.length > 0)
          state.userQuestsParams.position.positionIds.forEach(
            (position, index) => {
              positionParams += `workPositionIds=${position}${
                index < state.userQuestsParams.position.positionIds.length - 1
                  ? "&"
                  : ""
              }`;
            }
          );
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${
            roleControllers[role]
          }/GetPossibleToCompare${assignmentParams ? assignmentParams : ""}${
            positionParams ? positionParams : ""
          }`,
          {
            params: {
              pageSize:
                state.userQuestsParams.itemsPerPage != -1
                  ? state.userQuestsParams.itemsPerPage
                  : 10000,
              pageNo: state.userQuestsParams.page,
              ...(state.userQuestsParams.sortBy[0] && {
                sortBy: state.userQuestsParams.sortBy[0],
              }),
              ...(state.userQuestsParams.sortDesc[0] && { dir: "desc" }),
              ...(state.userQuestsParams.questionnaire && {
                questionnaireId:
                  state.userQuestsParams.questionnaire.questionnaireId,
              }),
              ...(state.userQuestsParams.nationalityId && {
                nationalityId: state.userQuestsParams.nationalityId,
              }),
              ...(state.userQuestsParams.position.positionId && {
                workPositionId: state.userQuestsParams.position.positionId,
              }),
              ...(state.userQuestsParams.dateFrom && {
                dateFrom: dayjs
                  .utc(state.userQuestsParams.dateFrom)
                  .toISOString(),
              }),
              ...(state.userQuestsParams.dateTo && {
                dateTo: dayjs.utc(state.userQuestsParams.dateTo).toISOString(),
              }),
              ...(state.userQuestsParams.assignmentId && {
                assignmentId: state.userQuestsParams.assignmentId,
              }),
              ...(state.userQuestsParams.client && {
                clientId: state.userQuestsParams.client.clientId,
              }),
              ...(state.userQuestsParams.selectedApp && {
                questionnaireId: state.userQuestsParams.selectedApp,
              }),
            },
          }
        );

        commit("getUserQuests", res.data);
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async getComparisonAssignmentsList({ commit, rootState }, params) {
      try {
        const role = rootState.auth.role;
        const roleControllers = {
          co: "ClientAdmin",
          gm: "GroupManager",
          filistos: "SuperAdmin",
        };
        console.log(params);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${roleControllers[role]}/GetAssignmentsToCompare`,
          {
            params,
          }
        );

        return res.data.results;
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async getComparisonColumns({ commit, state }, payload) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/System/GetComparisonColumns`,
          {
            questionnaireId: payload.questionnaireId,
            factorGroupIds: payload.factorGroupIds,
          }
        );

        return res.data.results;
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async getComparisonResults({ commit, state, rootState }, payload) {
      try {
        const role = rootState.auth.role;
        const roleControllers = {
          co: "ClientAdmin",
          gm: "GroupManager",
          psysupport: "psysup",
          allpsysup: "SuperPsySup",
          filistos: "SuperAdmin",
        };

        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/${roleControllers[role]}/CompareResults`,
          {
            ...payload,
            ...(state.userQuestsParams.nationalityId && {
              nationalityId: state.userQuestsParams.nationalityId,
            }),
          }
        );

        commit("getComparisonResults", res.data);
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async comparisonGetExcel({ commit, state, rootState }) {
      try {
        const role = rootState.auth.role;
        const roleControllers = {
          co: "ClientAdmin",
          gm: "GroupManager",
          psysupport: "psysup",
          allpsysup: "SuperPsySup",
          filistos: "SuperAdmin",
        };

        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/${roleControllers[role]}/DownloadComparisonToExcel`,
          {
            questionnaireId:
              role == "psysupport" || role == "allpsysup"
                ? state.userQuestsParams.selectedApp
                : state.userQuestsParams.questionnaire.questionnaireId,
            assignUserQuestIds: state.userQuestsToCompare,
            ...(state.userQuestsParams.nationalityId && {
              nationalityId: state.userQuestsParams.nationalityId,
            }),
            positionIds: state.positionsToCompare,
            factorGroupIds: state.factorGroupIds,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/vnd.ms-excel",
            },
          }
        );

        const filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0];

        let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
        var fileURL = window.URL.createObjectURL(blob);
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
    async getComparisonClient({ commit, rootState }, clientId) {
      try {
        const role = rootState.auth.role;
        const roleControllers = {
          allpsysup: "SuperPsySup",
          filistos: "SuperAdmin",
        };

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${roleControllers[role]}/GetClient/${clientId}`
        );
        return res.data;
      } catch (e) {
        commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
  },
};
