<template>
  <div class="pt-1">
    <v-select
      outlined
      dense
      label="Group Type*"
      class="mb-1"
      :items="groupTypes"
      item-value="itemKey"
      item-text="itemValue"
      :value="groupDataForm.type"
      @input="setGroupDataForm({ value: $event, key: 'type' })"
      :error-messages="
        v.$dirty && !v.form.groupData.type.required
          ? 'This field is required'
          : ''
      "
      @change="onGroupTypeChange"
      :readonly="allowedParentGroupsLoader"
      :disabled="isEdit"
    ></v-select>

    <v-text-field
      outlined
      dense
      label="Group Name*"
      class="mb-1"
      :value="groupDataForm.name"
      @input="setGroupDataForm({ value: $event, key: 'name' })"
      :error-messages="
        v.$dirty && !v.form.groupData.name.required
          ? 'This field is required'
          : ''
      "
      :disabled="isEdit"
    ></v-text-field>

    <v-text-field
      outlined
      dense
      label="Group Description"
      class="mb-1"
      :value="groupDataForm.description"
      @input="setGroupDataForm({ value: $event, key: 'description' })"
    ></v-text-field>

    <v-menu v-model="dateMenu" :close-on-content-click="false" max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formattedDate"
          dense
          prepend-inner-icon="mdi-calendar"
          outlined
          label="Creation Date"
          class="mb-1"
          readonly
          v-bind="attrs"
          v-on="on"
          :disabled="isEdit"
        ></v-text-field>
      </template>

      <v-date-picker
        :value="groupDataForm.creationDate"
        @input="setGroupDataForm({ value: $event, key: 'creationDate' })"
        @change="dateMenu = false"
        :disabled="isEdit"
      ></v-date-picker>
    </v-menu>

    <v-select
      outlined
      dense
      label="Parent Group"
      class="mb-1"
      :items="allowedParentGroups"
      item-value="groupId"
      item-text="groupName"
      :value="groupDataForm.parentGroup"
      @input="setGroupDataForm({ value: $event, key: 'parentGroup' })"
      :disabled="!groupDataForm.type"
      :loading="allowedParentGroupsLoader"
      @click:clear="setGroupDataForm({ value: null, key: 'parentGroup' })"
      clearable
    ></v-select>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["selectedGroup", "v"],
  data() {
    return {
      dateMenu: false,
      groupDataForm: {
        type: null,
        name: null,
        description: null,
        creationDate: new Date().toISOString().substr(0, 10),
        parentGroup: null,
      },
      allowedParentGroupsLoader: false,
      allowedParentGroups: [],
    };
  },
  computed: {
    groupTypes() {
      const groupTypes = [...this.$store.state.base.lists.groupTypes];
      groupTypes.splice(0, 1);

      return groupTypes;
    },
    formattedDate() {
      return this.groupDataForm.creationDate
        ? this.dayjs(this.groupDataForm.creationDate).format("DD/MM/YYYY")
        : "";
    },
    isEdit() {
      return !!this.selectedGroup;
    },
  },
  created() {
    if (this.selectedGroup) {
      this.onGroupTypeChange(this.selectedGroup.groupTypeId);

      this.groupDataForm.type = this.selectedGroup.groupTypeId;
      this.groupDataForm.name = this.selectedGroup.groupName;
      this.groupDataForm.description = this.selectedGroup.groupDescription;
      this.groupDataForm.creationDate = this.selectedGroup.creationDate
        ? this.dayjs(this.selectedGroup.creationDate)
            .toISOString(true)
            .substr(0, 10)
        : null;
      this.groupDataForm.parentGroup = this.selectedGroup.parentGroupId
        ? this.selectedGroup.parentGroupId
        : null;

      this.$emit("groupDataChanged", this.groupDataForm);
    }
  },
  methods: {
    ...mapActions(["getAllowedParentGroups"]),
    async onGroupTypeChange(event) {
      this.allowedParentGroupsLoader = true;

      try {
        this.allowedParentGroups = await this.getAllowedParentGroups(event);
      } catch (e) {
        console.log(e);
      }

      this.allowedParentGroupsLoader = false;
    },
    setGroupDataForm(field) {
      this.groupDataForm[field.key] = field.value;
      this.$emit("groupDataChanged", this.groupDataForm);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
