<template>
  <div class="d-flex flex-column flex-grow-1">
    <div
      class="stepper d-flex pa-5"
      :class="{
        'pb-8': $vuetify.breakpoint.smAndUp,
        'pb-2': $vuetify.breakpoint.xsOnly,
      }"
    >
      <div class="step" @click="changeStep(1)">
        <div
          class="step-number d-flex align-center justify-center text-h6 rounded mb-1"
          :class="{ done: step > 1, current: step == 1, edit: isEdit }"
        >
          1
        </div>

        <div class="step-text first" v-if="$vuetify.breakpoint.smAndUp">
          Basic Information
        </div>
      </div>

      <template
        v-if="
          !isEdit &&
          (!preselectedUsersBanner || !preselectedQuestionnairesBanner)
        "
      >
        <v-divider></v-divider>

        <div class="step" @click="changeStep(2)">
          <div
            class="step-number d-flex align-center justify-center text-h6 rounded mb-1"
            :class="{ done: step > 2, current: step == 2, unreached: step < 2 }"
          >
            2
          </div>

          <div class="step-text" v-if="$vuetify.breakpoint.smAndUp">Users</div>
        </div>
      </template>

      <v-divider></v-divider>

      <div class="step" @click="changeStep(3)">
        <div
          class="step-number d-flex align-center justify-center text-h6 rounded mb-1"
          :class="{
            done: step > 3,
            current: step == 3,
            unreached: step < 3,
            edit: isEdit,
          }"
        >
          {{
            isEdit ||
            (preselectedUsersBanner && preselectedQuestionnairesBanner)
              ? 2
              : 3
          }}
        </div>

        <div class="step-text" v-if="$vuetify.breakpoint.smAndUp">
          {{ isEdit ? "Overview" : "Save" }}
        </div>
      </div>
    </div>

    <div
      class="px-5 mb-4"
      v-if="(step1Error && step == 1) || (step2Error && step == 2)"
    >
      <div
        class="d-flex align-center error white--text pa-1 body-2 font-weight-medium rounded"
      >
        <span v-if="step == 1"> Please fill the required fields </span>
        <span v-if="step == 2"> Please select at least one user. </span>
      </div>
    </div>

    <div class="scrollable pa-5 pb-0 pt-0 d-flex flex-column">
      <component :is="activeComponent" :v="$v" :isEdit="isEdit" />
    </div>

    <div class="d-flex justify-space-between px-5 py-3 mt-auto">
      <v-btn
        class="mr-5"
        small
        depressed
        @click="changeStep(step - 1)"
        v-if="step != 1"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Previous
      </v-btn>

      <div class="d-flex align-center ml-auto">
        <v-btn
          small
          depressed
          @click="changeStep(step + 1)"
          :color="step < 3 ? '' : isEdit ? '' : 'success '"
          :class="{ 'white--text': step == 3 && !isEdit }"
          :loading="!isEdit && loader"
          :disabled="form.hasAssessor && !form.assessorId && step == 3"
        >
          <template v-if="step < 3">
            <span> Next </span>
            <v-icon right>mdi-arrow-right</v-icon>
          </template>

          <template v-else>
            <span> {{ isEdit ? "Close" : "Create Notification" }} </span>

            <v-icon right v-if="!isEdit">mdi-check</v-icon>
          </template>
        </v-btn>
      </div>
    </div>
  </div>
</template>
  
  <script>
import BasicInformation from "@/views/admin/notifications/notification-dialog/BasicInformation";
import Users from "@/views/admin/notifications/notification-dialog/Users";
import Save from "@/views/admin/notifications/notification-dialog/Save";
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

const usersValidator = function () {
  return this.form.sendToClientUserIds.length;
};

export default {
  props: ["isEdit"],
  components: {
    BasicInformation,
    Users,
    Save,
  },
  data() {
    return {
      step: 1,
      loader: false,
      updateLoader: false,
    };
  },
  computed: {
    ...mapState({
      form: (state) => state.notifications.form,
      currentAssignment: (state) => state.assignments.currentAssignment,
      initialData: (state) => state.assignments.initialData,
      preselectedUsersBanner: (state) =>
        state.assignments.preselectedUsersBanner,
      preselectedQuestionnairesBanner: (state) =>
        state.assignments.preselectedQuestionnairesBanner,
    }),
    activeComponent() {
      if (this.step == 1) return "BasicInformation";
      if (this.step == 2) return "Users";
      if (this.step == 3) return "Save";

      return "BasicInformation";
    },

    step1Error() {
      return (
        (this.$v.form.title.$dirty && this.$v.form.title.$invalid) ||
        (this.$v.form.body.$dirty && this.$v.form.body.$invalid) ||
        (this.$v.form.importance.$dirty && this.$v.form.importance.$invalid)
      );
    },
    step2Error() {
      return (
        this.$v.form.sendToClientUserIds.$dirty &&
        this.$v.form.sendToClientUserIds.$invalid
      );
    },
  },
  validations: {
    form: {
      title: { required },
      body: { required },
      importance: { required },
      sendToClientUserIds: { usersValidator },
    },
  },
  methods: {
    ...mapMutations([
      "setNotificationForm",
      "clearNewAssignmentState",
      "setUserParams",
      "setInitialData",
    ]),
    ...mapActions(["addNotification"]),
    async changeStep(step) {
      if (step == this.step || step <= 0) return; //if same do nothing

      if (step < this.step) {
        //if u want to navigate to previous step just do it.
        this.step = step;
        return;
      }

      //check if CURRENT step is NOT the last
      if (this.step != 3) {
        //then u want to navigate to next step

        if (step >= 2) {
          //if u want to go to 2nd
          this.$v.form.title.$touch();
          this.$v.form.body.$touch();
          this.$v.form.importance.$touch();

          if (
            this.$v.form.title.$invalid ||
            this.$v.form.body.$invalid ||
            this.$v.form.importance.$invalid
          )
            return;

          this.step = 2;
        }

        if (step >= 3) {
          //if u want to go to 3rd

          this.$v.form.sendToClientUserIds.$touch();

          if (this.$v.form.sendToClientUserIds.$invalid) return;

          this.step = 3;
        }
      } else {
        //else u want to submit

        try {
          this.loader = true;
          await this.addNotification();
          this.$emit("closeDialog", { unselectUsers: true });
          this.loader = false;
          return;
        } catch (e) {
          this.loader = false;
          console.log(e);
        }
      }
    },
  },
  destroyed() {
    this.setUserParams({
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: [],
      search: "",
      selectedGroups: [],
      groupId: null,
      union: "all",
      nationalityId: null,
      platformAccessId: null,
      languageId: null,
      gender: null,
      position: {
        positionType: null,
        positionId: null,
      },
    });
  },
};
</script>
  
  <style lang="scss" scoped>
.stepper {
  overflow-x: hidden;

  .v-divider {
    margin-top: 15px;
  }

  .step {
    position: relative;
    cursor: pointer;
  }

  .step-number {
    height: 30px;
    width: 30px;

    &.done {
      background-color: var(--v-primary-base);
      border: 2px solid var(--v-primary-base);
      color: white;

      &.edit {
        background-color: transparent;
        border: 2px solid rgba(0, 0, 0, 0.12);
        color: #616161;
      }
    }

    &.current {
      background-color: var(--v-filyellow-base);
      border: 2px solid var(--v-filyellow-base);
      color: white;

      &.edit {
        background-color: var(--v-primary-base);
        border: 2px solid var(--v-primary-base);
      }
    }

    &.unreached {
      border: 2px solid rgba(0, 0, 0, 0.12);
      color: #616161;
    }
  }

  .step-text {
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 1;

    font-size: 11px;
    font-weight: 500;
    width: 88px;

    &.first {
      left: 0;
      transform: none;
      text-align: start;
    }

    &.last {
      right: 0;
      left: auto;
      transform: none;
    }
  }
}

.dialog-wrapper {
  overflow-x: hidden;
}
</style>
  