<template>
  <div>
    <v-menu offset-y :close-on-content-click="false" v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          v-bind="attrs"
          v-on="on"
          :disabled="initLoader"
          :loading="loader"
        >
          Column Visibility
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item v-for="header in headers" :key="header.value">
          <v-list-item-action>
            <v-checkbox
              :value="header.value"
              v-model="visibleColumns"
              @change="emitHeaders"
              :readonly="
                visibleColumns.length == 1 &&
                visibleColumns.includes(header.value)
              "
            ></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ header.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["headers", "grid"],
  data() {
    return {
      visibleColumns: [],
      initialVisibleColumns: [],
      initLoader: false,
      loader: false,
      menu: false,
    };
  },
  async created() {
    this.toggleInitLoader(true);

    try {
      this.visibleColumns = await this.getVisibleColumns(this.grid);

      if (!this.visibleColumns.length) {
        const allColumns = this.headers.map((header) => header.value);

        this.visibleColumns = await this.setVisibleColumns({
          gridId: this.grid,
          visibleColumnNames: allColumns,
        });
      }

      this.initialVisibleColumns = [...this.visibleColumns];

      this.emitHeaders();
    } catch (e) {
      console.log(e);
    }

    this.toggleInitLoader(false);
  },
  methods: {
    ...mapActions(["getVisibleColumns", "setVisibleColumns"]),
    toggleInitLoader(loader) {
      this.initLoader = loader;
      this.$emit("initLoaderChanged", loader);
    },
    async onChangeVisibleColumns() {
      this.loader = true;

      try {
        await this.setVisibleColumns({
          gridId: this.grid,
          visibleColumnNames: this.visibleColumns,
        });
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    emitHeaders() {
      const headersMapped = this.headers.map((header) => {
        const isVisible = this.visibleColumns.includes(header.value);

        return {
          ...header,
          visible: isVisible,
        };
      });

      this.$emit("visibilityChanged", headersMapped);
    },
  },
  watch: {
    menu(newValue) {
      if (!newValue) {
        if (
          JSON.stringify(this.initialVisibleColumns.sort()) !==
          JSON.stringify(this.visibleColumns.sort())
        ) {
          this.onChangeVisibleColumns();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>