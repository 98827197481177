<template>
  <div class="scrollable">
    <v-list dense nav :disabled="globalLoader">
      <div
        v-for="questionnaire in currentQuestionnaireType.questionnaires"
        :key="questionnaire.questionnaireId"
      >
        <v-list-group no-action v-if="questionnaire.isGroupHead">
          <template v-slot:activator>
            <v-list-item-content class="group-header">
              <div class="d-flex align-center">
                <v-list-item-title class="flex-grow-0 mr-2">{{
                  questionnaire.questionnaireName
                }}</v-list-item-title>

                <v-btn
                  icon
                  color="filyellow"
                  small
                  @click.stop
                  :to="`/superadmin/questionnaires/${currentQuestionnaireType.questionnaireTypeId}/${questionnaire.questionnaireId}`"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="subQuestionnaire in questionnaire.subQuestionnaires"
            :key="subQuestionnaire.questionnaireId"
            :to="`/superadmin/questionnaires/${currentQuestionnaireType.questionnaireTypeId}/${subQuestionnaire.questionnaireId}`"
          >
            <v-list-item-content>
              <v-list-item-title>{{
                subQuestionnaire.questionnaireName
              }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon> mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else
          :to="`/superadmin/questionnaires/${currentQuestionnaireType.questionnaireTypeId}/${questionnaire.questionnaireId}`"
        >
          <v-list-item-content>
            <v-list-item-title>{{
              questionnaire.questionnaireName
            }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon> mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      globalLoader: (state) => state.base.globalLoader,
      questionnaires: (state) => state.base.questionnaires,
      currentQuestionnaireType: (state) =>
        state.questionnaires.currentQuestionnaireType,
    }),
  },
  methods: {
    ...mapMutations(["setCurrentQuestionnaireType"]),
  },
  beforeRouteUpdate(to, from, next) {
    try {
      const questionnaire = this.questionnaires.find(
        (qtype) => qtype.questionnaireTypeId == to.params.questionnaireTypeId
      );

      if (questionnaire) {
        this.setCurrentQuestionnaireType(questionnaire);
        next();
      } else {
        next("/superadmin/questionnaires");
      }
    } catch (e) {
      console.log(e);
      next("/superadmin/questionnaires");
    }
  },
  destroyed() {
    this.setCurrentQuestionnaireType(null);
  },
};
</script>

<style lang="scss" scoped>
.group-header {
  .v-list-item__title {
    flex-basis: auto;
  }
}
</style>
