<template>
  <div class="d-flex flex-column">
    <v-btn icon class="align-self-end mt-2 mr-2" @click="$emit('closeDialog')">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="pa-4">
      <form @submit.prevent="submit" class="d-flex flex-column">
        <v-select
          label="Clients"
          class="mb-4"
          outlined
          dense
          hide-details
          :items="allClients"
          item-value="clientId"
          item-text="companyName"
          multiple
          chips
          deletable-chips
          small-chips
          v-model="clients"
        ></v-select>

        <v-select
          class="mb-4"
          label="Roles"
          outlined
          dense
          hide-details
          :items="platformAccesses"
          item-value="itemKey"
          item-text="itemValue"
          multiple
          chips
          deletable-chips
          small-chips
          v-model="roles"
        ></v-select>

        <v-select
          class="mb-4"
          label="Importance"
          outlined
          dense
          hide-details
          :items="importances"
          item-value="key"
          item-text="value"
          v-model="importance"
        ></v-select>

        <v-text-field
          outlined
          dense
          label="Title"
          v-model="title"
        ></v-text-field>

        <tiptap-vuetify
          v-model="content"
          :extensions="extensions"
          placeholder="Send a message"
          class="mb-5"
        />

        <v-btn
          depressed
          color="filyellow"
          class="white--text align-self-center"
          type="submit"
          :disabled="$v.$invalid"
          :loading="loader"
          >Send Message</v-btn
        >
      </form>
    </div>
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  History,
  Image,
} from "tiptap-vuetify";

import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: { TiptapVuetify },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        Paragraph,
        HardBreak,
        Image,
      ],
      importances: [
        { key: "low", value: "Low" },
        { key: "medium", value: "Medium" },
        { key: "high", value: "High" },
      ],
      clients: [],
      roles: [],
      importance: null,
      content: null,
      title: null,
      loader: false,
    };
  },
  validations: {
    clients: { required },
    roles: { required },
    importance: { required },
    content: { required },
    title: { required },
  },
  computed: {
    ...mapState({
      platformAccesses: (state) => state.base.lists.platformAccesses,
      allClients: (state) => state.base.allClients,
    }),
  },
  methods: {
    ...mapActions(["newMessage"]),
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        await this.newMessage({
          messageTitle: this.title,
          messageBody: this.content,
          importance: this.importance,
          sendToClientIds: this.clients,
          sendToPlatformAccessIds: this.roles,
        });

        this.$emit("closeDialog");
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
