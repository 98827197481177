import axios from 'axios';

export default {
    state: {
        usersPerAccess: null,
        evaluatedUsers: null,
        usersPerNationality: null,
        dashboardAssignments: null,
        messagesParams: {
            itemsPerPage: 5,
            page: 1,
            sortBy: [],
            sortDesc: [],
            dateFrom: null,
            dateTo: null,
            importance: null,
            search: "",
        },
        messages: {
            results: [],
            rowCount: 0
        },
        messagesFetched: false,
        currentMessage: null,
    },
    mutations: {
        getSuperAdminUsersPerAccess(state, users) {
            state.usersPerAccess = users;
        },
        getSuperAdminEvaluatedUsers(state, evaluatedUsers) {
            state.evaluatedUsers = evaluatedUsers;
        },
        getSuperAdminUsersPerNationality(state, users) {
            state.usersPerNationality = users;
        },
        getSuperAdminDashboardAssignments(state, assignments) {
            state.dashboardAssignments = assignments;
        },
        setSuperAdminMessagesParams(state, params) {
            state.messagesParams = {
                ...state.messagesParams,
                ...params
            }
        },
        getSuperAdminMessages(state, messages) {
            state.messages = messages;
        },
        toggleSuperAdminMessagesFetched(state, fetched) {
            state.messagesFetched = fetched;
        },
        setSuperAdminCurrentMessage(state, message) {
            state.currentMessage = message;
        },
        clearSuperAdminDashboardState(state) {
            state.usersPerAccess = null;
            state.evaluatedUsers = null;
            state.usersPerNationality = null;
            state.dashboardAssignments = null;
            state.messagesParams = {
                itemsPerPage: 5,
                page: 1,
                sortBy: [],
                sortDesc: [],
                dateFrom: null,
                dateTo: null,
                importance: null,
                search: "",
            };
            state.messages = {
                results: [],
                rowCount: 0
            };
            state.messagesFetched = false;
            state.currentMessage = null;
        }
    },
    actions: {
        async getSuperAdminUsersPerAccess({ commit, rootState }, params) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/${rootState.auth.role == 'allpsysup' ? 'SuperPsySup' : 'SuperAdmin'}/GetUsersPerAccess`,
                    {
                        params
                    }
                );

                commit("getSuperAdminUsersPerAccess", res.data.results);
            } catch (e) {
                throw e;
            }
        },
        async getSuperAdminEvaluatedUsers({ commit, rootState }, params) {
            try {
                const res = await axios.get(
                    `${process.env.VUE_APP_BASE_URL}/${rootState.auth.role == 'allpsysup' ? 'SuperPsySup' : 'SuperAdmin'}/DashboardEvaluatedUsers`,
                    {
                        params
                    }
                );

                commit("getSuperAdminEvaluatedUsers", res.data);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getSuperAdminUsersPerNationality({ commit, rootState }, params) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/${rootState.auth.role == 'allpsysup' ? 'SuperPsySup' : 'SuperAdmin'}/GetUsersPerNationality`, {
                    params
                });

                commit("getSuperAdminUsersPerNationality", res.data.results);
            } catch (e) {
                throw e;
            }
        },
        async getSuperAdminDashboardAssignments({ commit, rootState }, params) {
            try {
                const res = await axios.get(
                    `${process.env.VUE_APP_BASE_URL}/${rootState.auth.role == 'allpsysup' ? 'SuperPsySup' : 'SuperAdmin'}/DashBoardAssignments`,
                    {
                        params: {
                            questionnaireTypeId: rootState.dashboard.dashboardAssignmentsParams.questionnaireTypeId,
                            year: rootState.dashboard.dashboardAssignmentsParams.year,
                            month: rootState.dashboard.dashboardAssignmentsParams.month,
                            ...(params && { ...params }),
                        }
                    }
                );

                commit("getSuperAdminDashboardAssignments", res.data);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async newMessage({ commit, dispatch }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/SendSAMessage`, payload);

                dispatch("getSuperAdminMessages", true);

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Message has been successfully sent!",
                    timeout: 5000
                })
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getSuperAdminMessages({ commit, state }, refresh) {
            if (state.messagesFetched && !refresh) return;

            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetSAMessagesSent`,
                    {
                        params: {
                            pageSize: state.messagesParams.itemsPerPage != -1 ? state.messagesParams.itemsPerPage : 1000,
                            pageNo: state.messagesParams.page,
                            ...(state.messagesParams.sortBy[0] && { sortBy: state.messagesParams.sortBy[0] }),
                            ...(state.messagesParams.sortDesc[0] && { dir: "desc" }),
                            ...(state.messagesParams.dateFrom && { fromDate: state.messagesParams.dateFrom }),
                            ...(state.messagesParams.dateTo && { toDate: state.messagesParams.dateTo }),
                            ...(state.messagesParams.importance && { importance: state.messagesParams.importance }),
                            ...(state.messagesParams.search && { search: state.messagesParams.search }),
                        }
                    });

                commit("getSuperAdminMessages", res.data);
                commit("toggleSuperAdminMessagesFetched", true);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        },
        async getSuperAdminMessage({ commit }, messageId) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/SuperAdmin/GetSAMessage/${messageId}`);

                commit("setSuperAdminCurrentMessage", res.data);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })

                throw e;
            }
        }
    }
}
