<template>
  <div class="component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column">
    <div
      class="d-flex flex-column flex-lg-row align-start align-lg-center mb-sm-5"
    >
      <div class="d-flex mb-5 mb-lg-0">
        <v-icon class="mr-2">mdi-bell</v-icon>

        <div class="body-1 font-weight-medium">Notifications</div>
      </div>
    </div>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="warning"
          depressed
          class="align-self-lg-end align-self-start mb-5"
          v-bind="attrs"
          v-on="on"
        >
          Add Notification
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item link @click="dialog = true">
          <v-list-item-title>Add Notification</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <NotificationsList />

    <v-dialog
      v-model="dialog"
      max-width="1250px"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <NotificationDialog
        @closeDialog="dialog = false"
        v-if="dialog"
        :isEdit="false"
      />
    </v-dialog>
  </div>
</template>
  
  <script>
import NotificationsList from "@/views/admin/notifications/NotificationsList";
import NotificationDialog from "@/views/admin/notifications/notification-dialog/NotificationDialog";
import { mapState } from "vuex";

export default {
  components: {
    NotificationsList,
    NotificationDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      info: (state) => state.base.info,
    }),
  },
};
</script>
  
  <style lang="scss" scoped>
.break-line {
  @media only screen and (max-width: 600px) {
    flex-basis: 100%;
  }
}

.divider {
  background-color: rgba(0, 0, 0, 0.12);
  width: 1px;
  align-self: stretch;
}

.statistic {
  @media only screen and (max-width: 600px) {
    width: 100px;
  }
}
</style>
  