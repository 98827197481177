<template>
  <div class="wrapper rounded flex-grow-1 d-flex flex-column">
    <div class="section-header d-flex align-center pa-1 rounded-tl rounded-tr">
      <v-icon class="mr-2">mdi-message-text</v-icon>

      <div class="body-2">Messages</div>
    </div>

    <div class="pb-4 d-flex flex-column flex-grow-1">
      <div class="pa-2">
        <ShowPerPage
          :currentPerPage="messagesParams.itemsPerPage"
          :options="showPerPageOptions"
        />
      </div>

      <div class="scrollable pa-4 mb-2">
        <v-data-table
          :headers="headers"
          :items="messages.results"
          :server-items-length="messages.rowCount"
          class="clickable"
          hide-default-footer
          @click:row="
            dialog = { open: true, component: 'Message', message: $event }
          "
          :options.sync="options"
          @update:options="onGetMessages(true)"
          :loading="loader"
          :item-class="isUnread"
          id="messages-table"
        >
          <template v-slot:[`item.delete`]="{ item, index }">
            <v-menu
              top
              left
              :close-on-content-click="false"
              offset-y
              offset-x
              :ref="`deleteMenu${index}`"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!item.dateOpened"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>

              <div class="white pa-5">
                <div class="body-2 mb-4 error--text">
                  Are you sure you want to delete this message?
                </div>

                <v-btn
                  small
                  depressed
                  color="error"
                  @click="onDeleteMessage(item, index)"
                  :loading="deleteLoader == item.messageId"
                  >Delete</v-btn
                >
              </div>
            </v-menu>
          </template>

          <template v-slot:[`item.dateSent`]="{ item }">
            <div
              class="body-2"
              :class="{ 'font-weight-bold': !item.dateOpened }"
            >
              {{ dayjs.utc(item.dateSent).local().format("DD/MM/YYYY HH:mm") }}
            </div>
          </template>

          <template v-slot:[`item.messageTitle`]="{ item }">
            <div
              class="body-2 trimmed"
              :class="{ 'font-weight-bold': !item.dateOpened }"
            >
              {{ item.messageTitle }}
            </div>
          </template>
        </v-data-table>
      </div>

      <v-pagination
        :length="messages.pageCount"
        v-model="messagesParams.page"
        v-if="messages.pageCount"
      ></v-pagination>
    </div>

    <v-dialog
      v-model="dialog.open"
      max-width="800px"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <div class="white" v-if="dialog.open">
        <Message
          :message="dialog.message"
          @closeDialog="
            dialog = { open: false, component: null, message: null }
          "
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Message from "@/views/admin/dashboard/messages/Message";
import { mapActions, mapMutations, mapState } from "vuex";
import ShowPerPage from "@/components/ShowPerPage";

export default {
  components: {
    Message,
    ShowPerPage,
  },
  data() {
    return {
      dialog: {
        open: false,
        component: null,
        message: null,
      },
      headers: [
        {
          text: "",
          value: "delete",
          sortable: false,
        },
        {
          text: "Time",
          value: "dateSent",
        },
        {
          text: "Title",
          value: "messageTitle",
          sortable: false,
        },
      ],
      loader: false,
      isFirstCall: true,
      deleteLoader: null,
      showPerPageOptions: [],
    };
  },
  computed: {
    ...mapState({
      messages: (state) => state.dashboard.messages,
      messagesParams: (state) => state.dashboard.messagesParams,
    }),
    options: {
      get() {
        const { itemsPerPage, page, sortBy, sortDesc } = this.messagesParams;

        return {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        };
      },
      set(value) {
        let { itemsPerPage, page, sortBy, sortDesc } = value;

        this.setMessagesParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      },
    },
  },
  created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setMessagesParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setMessagesParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setMessagesParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setMessagesParams,
      },
    ];
  },
  methods: {
    ...mapMutations(["setMessagesParams"]),
    ...mapActions(["getMessages", "deleteMessage"]),
    isUnread(item) {
      return item.dateOpened ? "" : "unread";
    },
    async onGetMessages(refresh) {
      if (this.isFirstCall) {
        this.isFirstCall = false;
        return;
      }

      this.loader = true;

      try {
        await this.getMessages(refresh);
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    async onDeleteMessage(message, index) {
      this.deleteLoader = message.messageId;

      try {
        await this.deleteMessage(message.messageId);
        this.$refs[`deleteMenu${index}`].save();
      } catch (e) {
        console.log(e);
      }

      this.deleteLoader = null;
    },
  },
  watch: {
    "messagesParams.search"() {
      clearTimeout(this.timeout);

      if (this.loader) return;

      this.timeout = setTimeout(() => {
        this.setMessagesParams({
          page: 1,
        });

        this.onGetMessages(true);
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 500px;
}

.trimmed {
  width: 600px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;

  @media only screen and (max-width: 1904px) {
    width: 400px;
  }

  @media only screen and (max-width: 600px) {
    width: 160px;
  }
}
</style>
