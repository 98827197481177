<template>
  <div class="component-wrapper px-4 pt-4 grey lighten-3 d-flex flex-column">
    <div class="header d-flex justify-center mb-4">
      <v-btn class="back-btn" icon small @click="$router.go(-1)">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <div class="body-1 font-weight-medium text-center">
        Personnel Matrix Results
      </div>
    </div>

    <div class="wrapper rounded flex-grow-1 d-flex flex-column pa-4">
      <v-progress-circular
        size="120"
        width="4"
        indeterminate
        color="primary"
        class="ma-auto"
        v-if="loader"
      ></v-progress-circular>

      <template v-else>
        <div class="d-flex justify-end">
          <v-btn
            depressed
            color="primary"
            class="white--text"
            @click="onCombinationGetExcel"
            :loading="excelLoader"
            >Export Excel</v-btn
          >
        </div>

        <v-data-table
          :headers="headers"
          :items="crewCombinationsResults.results"
          :items-per-page="10000"
          hide-default-footer
          hide-default-header
        >
          <template v-slot:header="{ props: { headers } }">
            <thead class="thead">
              <tr>
                <th
                  class="rotate mr-8"
                  v-for="(h, index) in headers"
                  :key="index"
                >
                  <div>
                    <span
                      :class="{ 'font-weight-black black--text': !h.child }"
                      >{{ h.text }}</span
                    >
                  </div>
                </th>
              </tr>
            </thead>
          </template>

          <template v-slot:item="item">
            <tr v-if="item.item" class="body">
              <td
                :class="{
                  'filred-bg':
                    (Object(item.item[h.value]) === item.item[h.value] &&
                      item.item[h.value].color == 'R') ||
                    h.value == 'R',
                  'filyellow-bg':
                    (Object(item.item[h.value]) === item.item[h.value] &&
                      item.item[h.value].color == 'Y') ||
                    h.value == 'Y',
                  'filgreen-bg':
                    (Object(item.item[h.value]) === item.item[h.value] &&
                      item.item[h.value].color == 'G') ||
                    h.value == 'G',
                }"
                v-for="(h, index) in headers"
                :key="index"
              >
                <div
                  v-if="Object(item.item[h.value]) === item.item[h.value]"
                  :class="{
                    'font-weight-bold':
                      item.item[h.value].statistics == 'S' ||
                      item.item[h.value].statistics == 'A',
                  }"
                >
                  {{
                    item.item[h.value].aScore ? item.item[h.value].aScore : "-"
                  }}
                </div>

                <div v-else>
                  <!-- <div v-if="Date.parse(item.item[h.value])">
                    {{
                      dayjs.utc(item.item[h.value]).local().format("DD/MM/YYYY")
                    }}
                  </div> -->
                  <!-- <div v-if="item.item[h.value] == 'G'">
                    <span class="filgreen--text"> 1 </span>
                  </div> -->
                  <!-- <span>{{ item.item }}</span> -->

                  <div
                    v-if="h.value != 'R' && h.value != 'G' && h.value != 'Y'"
                  >
                    {{ item.item[h.value] ? item.item[h.value] : "-" }}
                  </div>
                  <div v-else>
                    <span class="pr-3"> </span>
                    {{ item.item[h.value] ? +item.item[h.value] : "-" }}
                    <span class="pl-3"> </span>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      headers: [],
      items: [],
      loader: false,
      excelLoader: false,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.auth.role,
      filters: (state) => state.crew_combinations.filters,

      crewCombinationsResults: (state) =>
        state.crew_combinations.crewCombinationsResults,
      crewCombinationsQuestionnaire: (state) =>
        state.crew_combinations.questionnaire,
    }),
    backPath() {
      const roles = {
        co: "/",
        gm: "/",
        psysupport: "/psysupport/",
        allpsysup: "/allpsysup/",
        filistos: "/superadmin/",
      };

      return `${roles[this.role]}crew-combinations`;
    },
  },
  async created() {
    this.loader = true;

    try {
      const res = await this.getComparisonColumns({
        questionnaireId:
          this.role == "psysupport" || this.role == "allpsysup"
            ? "selfsupport"
            : this.crewCombinationsQuestionnaire.questionnaireId,
        factorGroupIds: this.filters.factorGroupIds,
      });

      this.headers = res.filter((obj) => {
        return (
          obj.value !== "position" &&
          obj.value !== "nationality" &&
          obj.value !== "date"
        );
      });

      this.headers.splice(1, 0, {
        child: true,
        sortable: true,
        text: "Red Count",
        value: "R",
      });

      this.headers.splice(1, 0, {
        child: true,
        sortable: true,
        text: "Yellow Count",
        value: "Y",
      });

      this.headers.splice(1, 0, {
        child: true,
        sortable: true,
        text: "Green Count",
        value: "G",
      });

      // console.log(this.headers);
    } catch (e) {
      console.log(e);
      this.$router.go(-1);
    }

    this.loader = false;
  },
  methods: {
    ...mapActions(["getComparisonColumns", "combinationGetExcel"]),
    async onCombinationGetExcel() {
      this.excelLoader = true;

      try {
        await this.combinationGetExcel();
      } catch (e) {
        console.log(e);
      }

      this.excelLoader = false;
    },
  },
};
</script>

<style lang="scss">
.thead {
  height: 140px;
}

th.rotate {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
  padding: 0 !important;
}

th.rotate > div {
  transform: 
    /* Magic Numbers */ translate(50%, 54px)
    /* 45 is really 360 - 45 */ rotate(270deg);
  width: 30px;
}
th.rotate > div > span {
  padding: 5px 10px;
}

tr.body {
  td {
    padding: 0 8px !important;
    border-right: 1px solid #ccc;
    white-space: nowrap;
  }
}

.filred-bg {
  background-color: rgb(255, 208, 208);
}

.filyellow-bg {
  background-color: rgb(255, 255, 208);
}

.filgreen-bg {
  background-color: rgb(208, 255, 208);
}
</style>
