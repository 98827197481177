<template>
  <div>
    <div class="text-h6" v-if="!questionnaireTypes.length">
      No questionnaires available
    </div>

    <div
      v-for="(type, index) in questionnaireTypes"
      :key="type.questionnaireTypeId"
      :class="{ 'mb-4': index != questionnaireTypes.length - 1 }"
    >
      <div class="subtitle-2 grey lighten-4 rounded pa-2">
        {{ type.questionnaireTypeName }} ({{ type.questionnaires.length }})
      </div>

      <v-data-table
        :headers="headers"
        :items="type.questionnaires"
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:[`item.remainingUnits`]="{ item }">
          {{ item.isUnlimited ? "Unlimited" : item.remainingUnits }}
        </template>

        <template v-slot:[`item.expiry`]="{ item }">
          {{
            item.isUnlimited
              ? dayjs.utc(item.expiry).local().format("DD/MM/YYYY")
              : "-"
          }}
        </template>

        <template v-slot:[`item.return`]="{ item }">
          <v-btn
            v-if="!item.isUnlimited"
            small
            depressed
            color="primary"
            @click="dialog = { open: true, item }"
            >Return</v-btn
          >

          <div class="mock" v-else></div>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="dialog.open" max-width="500px">
      <div class="white d-flex flex-column" v-if="dialog.open">
        <v-btn
          icon
          small
          class="ml-auto mt-1 mr-1"
          @click="dialog = { open: false, item: null }"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <div class="px-2 pb-4 pt-0 d-flex flex-column align-center">
          <div class="text-h6">{{ dialog.item.questionnaireName }}</div>

          <div class="d-flex align-center mb-4">
            <div class="subtitle-2 mr-2">Remaining Units:</div>
            <div class="body-2">{{ dialog.item.remainingUnits }}</div>
          </div>

          <form class="d-flex align-center" @submit.prevent="onReturn">
            <v-text-field
              outlined
              dense
              hide-details
              label="Units to return"
              type="number"
              v-model.number="returnUnits"
              class="mr-2"
            ></v-text-field>

            <v-btn
              type="submit"
              color="primary"
              depressed
              small
              :loading="returnLoader"
              >Return</v-btn
            >
          </form>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "questionnaireName",
          width: "50%",
          sortable: false,
        },
        {
          text: "Remaining Units",
          value: "remainingUnits",
          width: "50%",
          sortable: false,
        },
        { text: "Expiration Date", value: "expiry" },
        { text: "", value: "return" },
      ],
      dialog: {
        open: false,
        item: null,
      },
      returnLoader: false,
      returnUnits: 0,
    };
  },
  computed: {
    ...mapState({
      questionnaireTypes: (state) => state.clients.questionnaires,
      currentClient: (state) => state.clients.currentClient,
    }),
  },
  methods: {
    ...mapMutations(["toggleSnackbar"]),
    ...mapActions(["getClientQuestionnaires"]),
    async onReturn() {
      try {
        this.returnLoader = true;

        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/superadmin/AddQuestionnaireReturn`,
          {
            clientId: this.currentClient.clientId,
            questionnaireId: this.dialog.item.questionnaireId,
            quantity: this.returnUnits,
          }
        );

        await this.getClientQuestionnaires({
          clientId: this.currentClient.clientId,
          refresh: true,
        });

        this.toggleSnackbar({
          open: true,
          color: "success",
          text: "Units have been returned.",
          timeout: 5000,
        });

        this.dialog = {
          open: false,
          item: null,
        };
      } catch (e) {
        console.log(e);

        this.toggleSnackbar({
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });
      }

      this.returnLoader = false;
    },
  },
  watch: {
    "dialog.open"(newValue) {
      if (!newValue) {
        this.returnUnits = 0;
        this.dialog.item = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mock {
  width: 61px;
}
</style>
