<template>
  <v-app id="filistos">
    <Navigation v-if="!initLoader" />

    <v-main>
      <v-progress-linear
        indeterminate
        color="primary"
        v-if="globalLoader"
      ></v-progress-linear>

      <InitLoader v-if="initLoader" />

      <template v-else>
        <router-view></router-view>
      </template>
    </v-main>

    <v-snackbar
      v-model="snackbar.open"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      <div class="d-flex align-center">
        <div class="body-2 mr-2">{{ snackbar.text }}</div>
        <v-btn
          text
          small
          class="ml-auto"
          @click="
            toggleSnackbar({
              open: false,
            })
          "
          >Close</v-btn
        >
      </div>
    </v-snackbar>

    <v-dialog v-model="dialog.open" :max-width="dialog.width">
      <div class="white dialog-wrapper scrollable-dialog" v-if="dialog.open">
        <component :is="dialog.component" />
      </div>
    </v-dialog>

    <v-dialog v-model="discardDialog.open" max-width="500px">
      <DiscardDialog />
    </v-dialog>

    <Footer v-if="!initLoader" />
  </v-app>
</template>

<script>
import InitLoader from "@/components/InitLoader";
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";
import EndUserLicense from "@/components/EndUserLicense";
import Licenses from "@/components/Licenses";
import DiscardDialog from "@/components/DiscardDialog";
import { mapActions, mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
  components: {
    Navigation,
    Footer,
    InitLoader,
    EndUserLicense,
    Licenses,
    DiscardDialog,
  },
  computed: {
    ...mapState({
      snackbar: (state) => state.base.snackbar,
      dialog: (state) => state.base.dialog,
      globalLoader: (state) => state.base.globalLoader,
      role: (state) => state.auth.role,
      discardDialog: (state) => state.base.discardDialog,
      initLoader: (state) => state.base.initLoader,
    }),
  },
  async created() {
    //====== AXIOS INTERCEPTOR ======
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response.status === 401) {
          await this.logout();
          this.$router.push({ path: "/login" });
        }

        throw error;
      }
    );
  },
  methods: {
    ...mapActions(["init", "logout"]),
    ...mapMutations(["toggleSnackbar", "toggleDialog"]),
  },
};
</script>

<style lang="scss">
#filistos.v-application {
  .v-btn:not(.v-btn--icon),
  .v-tab {
    letter-spacing: normal !important;
    text-transform: none !important;
    font-weight: 400 !important;
  }

  *:not(.v-icon),
  *:not(.v-icon):before,
  *:not(.v-icon):after {
    letter-spacing: normal !important;
  }

  .v-main__wrap {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 415px) {
    .v-data-footer {
      .v-data-footer__select {
        margin: 0 !important;
      }

      .v-input.v-select.v-input--hide-details {
        margin-left: 10px !important;
      }

      .v-data-footer__pagination {
        margin: 0 10px 0 auto !important;
      }

      .v-btn--icon.v-size--default {
        height: 24px;
        width: 24px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .v-stepper__step {
      flex-basis: 0 !important;
    }

    .v-stepper--alt-labels .v-stepper__header .v-divider {
      margin: 35px -20px 0 !important;
    }
  }

  .v-select__selections {
    padding-top: 10px !important;
  }

  .v-chip--select {
    &:not(:last-of-type) {
      margin-bottom: 5px !important;
    }
  }

  .v-select__selections {
    max-height: 150px;
    overflow-y: auto;
  }

  .v-navigation-drawer {
    transition-duration: 0.4s !important;
  }
}

#dashboard-nationality {
  .v-tabs-items {
    height: 100%;

    .v-window__container {
      height: 100%;

      .v-window-item {
        height: 100%;
      }
    }
  }
}

#sidebar {
  .v-list-item__title {
    font-size: 15px !important;
  }
}

.v-data-table.clickable {
  tr {
    cursor: pointer !important;
  }
}

//GLOBAL STYLES
.component-wrapper {
  flex-grow: 1;
  position: relative;
  padding-bottom: 68px;
}

.wrapper {
  background-color: #fff;
  // width: 200%;
  // overflow-x: scroll;
}

.section-header {
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
}

.link {
  cursor: pointer;
  color: var(--v-primary-base);
  transition: all 0.2s;

  &:hover {
    text-decoration: underline;
    color: var(--v-primary-lighten1);
  }
}

.flex1 {
  flex: 1;
}

.scrollable {
  flex: 1 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  height: 0px;
}

.bullet {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.radio-btn[type="radio"] {
  display: none;
}

.radio-btn-label {
  border: 1px solid #bdbdbd;
  height: 34px;
  cursor: pointer;

  img {
    height: 100%;
  }
}

.radio-btn[type="radio"]:checked + .radio-btn-label {
  border: 1px solid #ff0000;
}

.dialog-wrapper {
  height: 100%;

  &.scrollable-dialog {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 600px) {
      height: 90vh;

      &.small {
        height: 60vh;
      }
    }
  }
}

#messages-table {
  .unread {
    background-color: #e0e0e0 !important;
  }
}

.pagination-wrapper {
  max-width: 80%;
  align-self: center;
}

.questionnaires-expansion-panel {
  .v-expansion-panel {
    .v-expansion-panel-header {
      min-height: auto !important;
    }
  }

  .v-expansion-panel-content__wrap {
    padding-bottom: 0 !important;
  }
}

.assignment-status-questionnaires {
  .v-expansion-panel-header {
    padding: 16px !important;
    min-height: auto !important;
  }

  .v-expansion-panel-content__wrap {
    padding: 0 16px !important;
  }
}

.header {
  position: relative;
}

.back-btn {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  &.right {
    left: auto;
    right: 0;
  }
}

.questionnaires-radio-group {
  .v-input--radio-group__input {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    align-items: flex-start !important;

    .v-label {
      align-self: flex-start !important;
    }
  }
}

.flex-1 {
  flex: 1;
}

#single-user-tabs,
.scrollable-tabs {
  flex-grow: 1 !important;
  display: flex;
  flex-direction: column;

  .v-window__container {
    flex-grow: 1 !important;
    display: flex;
    flex-direction: column;

    .v-window-item.v-window-item--active {
      flex-grow: 1 !important;
      display: flex;
      flex-direction: column;
    }
  }
}

#question-texts {
  .v-window.v-item-group.theme--light.v-tabs-items {
    background-color: #fafafa !important;
  }
}
</style>
