<template>
  <div>
    <v-select
      v-model="statusFilter"
      dense
      outlined
      hide-details
      label="Status"
      :items="[
        { name: 'All', value: 'all' },
        { name: 'Green', value: 'green' },
        { name: 'Yellow and Red', value: 'yellowandred' },
      ]"
      item-value="value"
      item-text="name"
      clearable
      class="my-2"
      @change="onFilterChange($event)"
      style="width: 300px"
    ></v-select>

    <div
      v-for="(factor, index) in graphReport.factors"
      :key="`${index}-${factor.factorId}`"
      :class="{ 'mb-8': index != graphReport.factors.length - 1 }"
    >
      <v-row no-gutters class="mb-2">
        <v-col class="pa-1 d-flex align-center" cols="4">
          <div class="subtitle-2 mr-2">
            {{ index + 1 }}. {{ factor.factorName }}
          </div>

          <div class="black--text">
            <div class="caption font-weight-bold">({{ factor.aScore }})</div>
          </div>
        </v-col>

        <v-col class="pa-1 pl-0 d-flex align-center justify-center" cols="8">
          <v-tooltip
            top
            right
            nudge-bottom="5px"
            :color="getFactorColor(factor.colorFlag)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-progress-linear
                :value="factor.aScore"
                :color="getFactorColor(factor.colorFlag)"
                height="20px"
                v-bind="attrs"
                v-on="on"
              ></v-progress-linear>
            </template>

            <div class="d-flex flex-column align-center">
              <div class="subtitle-2">{{ factor.factorName }}</div>
              <div class="subtitle-2">{{ factor.aScore }}</div>
            </div>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        v-for="(subfactor, index) in factor.subFactors"
        :key="`${index}-${subfactor.factorId}`"
        :class="{ 'mb-1': index != factor.subFactors.length - 1 }"
      >
        <v-col class="pl-5 d-flex align-center" cols="4">
          <div class="caption mr-3">{{ subfactor.factorName }}</div>

          <div
            :class="
              statusFilter ==
              {
                'filyellow--text': subfactor.colorFlag == 'Y',
                'error--text': subfactor.colorFlag == 'R',
                'success--text': subfactor.colorFlag == 'G',
              }
            "
          >
            <div class="caption font-weight-bold">({{ subfactor.aScore }})</div>
          </div>
        </v-col>

        <v-col class="d-flex align-center justify-center" cols="8">
          <v-tooltip
            top
            right
            nudge-bottom="5px"
            :color="getColor(subfactor.colorFlag)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-progress-linear
                :value="subfactor.aScore"
                :color="getColor(subfactor.colorFlag)"
                height="10px"
                v-bind="attrs"
                v-on="on"
              ></v-progress-linear>
            </template>

            <div class="d-flex flex-column align-center">
              <div class="subtitle-2">{{ subfactor.factorName }}</div>
              <div class="subtitle-2">{{ subfactor.aScore }}</div>
            </div>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  created() {
    this.graphReport = { ...this.report };
  },

  data() {
    return {
      graphReport: [],
      statusFilter: "all",
    };
  },
  computed: {
    ...mapState({
      report: (state) => state.assignments.report,
    }),
  },
  methods: {
    getFactorColor(color) {
      if (this.statusFilter == "all" || status == "null") {
        if (color == "Y") {
          return "filyellow";
        } else if (color == "R") {
          return "error";
        } else {
          return "success";
        }
      } else return "white";
    },

    getColor(color) {
      if (color == "Y") {
        return "filyellow";
      } else if (color == "R") {
        return "error";
      } else {
        return "success";
      }
    },

    onFilterChange(status) {
      if (status == "all" || status == null) {
        this.graphReport.factors = [...this.report.factors];
      } else if (status == "green") {
        this.graphReport.factors = [
          ...this.report.factors.map((factor) => {
            return {
              ...factor,
              subFactors: factor.subFactors.filter(
                (subfactor) => subfactor.colorFlag === "G"
              ),
            };
          }),
        ];

        this.graphReport.factors = [
          ...this.graphReport.factors.filter((factor) => {
            if (factor.subFactors.length > 0) return factor;
          }),
        ];
      } else if (status == "yellowandred") {
        this.graphReport.factors = [
          ...this.report.factors.map((factor) => {
            return {
              ...factor,
              subFactors: factor.subFactors.filter(
                (subfactor) =>
                  subfactor.colorFlag === "Y" || subfactor.colorFlag === "R"
              ),
            };
          }),
        ];

        this.graphReport.factors = [
          ...this.graphReport.factors.filter((factor) => {
            if (factor.subFactors.length > 0) return factor;
          }),
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
