<template>
  <div class="white dialog-wrapper small scrollable-dialog">
    <div class="primary pa-3 d-flex align-start">
      <div class="text-h6 white--text">
        {{ isEdit ? "Group Details" : "Add Group" }}
      </div>

      <v-btn
        icon
        small
        color="white"
        class="ml-auto"
        @click="$emit('closeDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <div class="pa-5 pb-0">
      <v-tabs v-model="tab" align-with-title show-arrows v-if="isEdit">
        <v-tab> Group Data </v-tab>

        <v-tab v-if="isEdit"> Group Managers </v-tab>
      </v-tabs>
    </div>

    <div class="px-5 scrollable">
      <GroupData
        @groupDataChanged="onGroupDataChanged"
        :v="$v"
        v-if="!isEdit"
      />

      <v-tabs-items v-model="tab" class="mb-5" v-else>
        <v-tab-item class="pt-5" :eager="true">
          <GroupData
            @groupDataChanged="onGroupDataChanged"
            :v="$v"
            :selectedGroup="selectedGroup"
          />
        </v-tab-item>

        <v-tab-item class="pt-5" :eager="true">
          <GroupManagers :selectedGroup="selectedGroup" />
        </v-tab-item>
      </v-tabs-items>
    </div>

    <div class="pa-5 mt-auto">
      <v-alert
        border="left"
        color="error"
        dark
        dense
        class="mb-4"
        v-if="$v.$dirty && $v.$invalid"
      >
        Some fields are invalid
      </v-alert>

      <div class="d-flex align-center">
        <div
          class="body-2"
          :class="{ 'error--text': $v.$dirty && $v.$invalid }"
          v-if="!isEdit"
        >
          * Required Fields
        </div>

        <v-btn
          depressed
          :color="!isEdit || hasChanges ? 'success' : ''"
          class="ml-auto"
          @click="onSubmit"
          :loading="loader"
          >{{ !isEdit || hasChanges ? "Save" : "Close" }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import GroupData from "@/views/admin/groups/group-dialog/GroupData";
import GroupManagers from "@/views/admin/groups/group-dialog/GroupManagers";

import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  props: ["selectedGroup"],
  components: {
    GroupData,
    GroupManagers,
  },
  data() {
    return {
      loader: false,
      form: {
        groupData: {
          type: null,
          name: null,
          description: null,
          creationDate: null,
          parentGroup: null,
        },
      },
      tab: 0,
      initialData: null,
    };
  },
  validations: {
    form: {
      groupData: {
        type: { required },
        name: { required },
      },
    },
  },
  computed: {
    isEdit() {
      return !!this.selectedGroup;
    },
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !=
        JSON.stringify({
          description: this.form.groupData.description,
          parentGroup: this.form.groupData.parentGroup,
        })
      );
    },
  },
  mounted() {
    this.initialData = {
      description: this.form.groupData.description,
      parentGroup: this.form.groupData.parentGroup,
    };
  },
  methods: {
    ...mapActions(["addGroup", "editGroup"]),
    onGroupDataChanged(groupData) {
      this.form.groupData = {
        ...groupData,
      };
    },
    async onSubmit() {
      if (this.isEdit && !this.hasChanges) return this.$emit("closeDialog");

      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        if (!this.isEdit) {
          await this.addGroup({
            groupTypeId: this.form.groupData.type,
            groupName: this.form.groupData.name,
            groupDescription: this.form.groupData.description,
            creationDate: this.dayjs
              .utc(this.form.groupData.creationDate)
              .toISOString(),
            parentGroupId: this.form.groupData.parentGroup,
          });
        } else {
          await this.editGroup({
            groupId: this.selectedGroup.groupId,
            groupTypeId: this.form.groupData.type,
            groupName: this.form.groupData.name,
            groupDescription: this.form.groupData.description,
            creationDate: this.dayjs
              .utc(this.form.groupData.creationDate)
              .toISOString(),
            parentGroupId: this.form.groupData.parentGroup,
          });
        }

        this.$emit("closeDialog");
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
