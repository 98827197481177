<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="primary pa-3">
      <div class="header white--text d-flex justify-center">
        <v-btn icon class="back-btn right">
          <v-icon color="white" @click="toggleDialog({ open: false })"
            >mdi-close</v-icon
          >
        </v-btn>

        <div class="text-h6 text-center">End User License Agreement</div>
      </div>
    </div>

    <div class="scrollable pa-5">
      <p class="body-1">
        This End User License Agreement (EULA) applies to the Application CASE
        (hereinafter called “CASE” or the “Application”) provided by the company
        under the distinctive title “FILISTOS ASCOT” (hereinafter called
        “FILISTOS ASCOT” or the “Company”).
      </p>

      <p class="body-1">
        BY CLICKING THE ACCEPTANCE BUTTON OR BY DOWNLOADING, INSTALLING OR USING
        THE APPLICATION, YOU ASSENT TO AND ARE BOUND BY THIS EULA. YOU ALSO
        ACCEPT AND ASSENT TO THE APPLICATION’S PRIVACY POLICY AND TERMS OF USE
        AS AMENDED FROM TIME TO TIME.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">1. Definitions</div>

      <p class="body-1">
        a. “Current Version” means the executable code version of the
        Application, including all features of the application for which the
        licence is granted, i.e., associated software, Upgrades, Updates,
        plug-ins, Documentation, and any associated online services provided by
        FILISTOS ASCOT. b. “Licensee” means any natural person or legal entity
        that owns a company and wishes to use CASE, to its employees /
        co-workers/ partners/ customers and to enjoy all benefits of the licence
        granted, as described herein. c. “Company” / “FILISTOS ASCOT” means the
        company under the distinctive title “FILISTOS ASCOT” with registered
        offices at Nemesseos 6 Metamorfosi 14452, Greece email:
        info@filistosascot.com T:+30 6936664801, who is the sole and exclusive
        owner of the Application and its features. d. “Update” means a revision
        to the Application designated by a change in the version number to the
        right of the decimal place. e. “Upgrade” means a revision to the
        Application designated by a change in the version number to the left of
        the decimal place.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">2. License Grant.</div>

      <p class="body-1">
        Subject to the terms and conditions of this EULA, FILISTOS ASCOT grants
        Licensee a non-exclusive, and non-transferable license to install and
        use the Current Version solely in order to use CASE in the effective
        way.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">
        3. Upgrades and Updates.
      </div>

      <p class="body-1">
        In its discretion, FILISTOS ASCOT may make Updates or Upgrades to the
        Application. FILISTOS ASCOT may, in its discretion, provide any Updates
        or Upgrades subject to its then current forms of end user license
        agreement, which may contain additional or different terms. This license
        does not entitle Licensee to Updates or Upgrades.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">
        4. Restrictions on Use.
      </div>

      <p class="body-1">
        Licensee may not: (i) modify or create any derivative works of the
        Application. (ii) decompile, disassemble, or reverse engineer the
        Application, or defeat, bypass, or otherwise circumvent any software
        protection mechanisms in the Application (except to the extent
        applicable laws specifically prohibit such restriction); (iii) attempt
        to access or use any other features of the Application if Licensee has
        not paid the applicable license fee or is not granted for such use. (iv)
        redistribute, encumber, sell, rent, lease, sublicense, or otherwise
        transfer the Application. (v) use the Application in a timesharing or
        service bureau arrangement; or (vi) remove or alter any trademark, logo,
        copyright or other proprietary notices, symbols, or labels in the
        Application. None of the content of the Application may be recorded,
        copied, reproduced, translated, derived, or otherwise transformed,
        distributed, rented, publicly performed, retransmitted, presented to the
        public or otherwise made available to the public without our express
        written consent. Licensees are not allowed to attempt to extract the
        source code of the Application and make any derivative versions.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">
        5. Responsibility for Copyright Content.
      </div>

      <p class="body-1">
        Licensee alone is responsible for ensuring that any information, photo,
        audio or video content that he/she uploads at the Application is
        properly obtained, licensed and used under copyright and all other
        applicable law. Licensee agrees not to use the Application to reproduce,
        display, perform, or distribute content in any manner that violates any
        laws or any third-party rights. All content, trade names, trademarks and
        other distinctive features and content included in or made available
        through the Application, including but not limited to the texts,
        graphics, sound, images, logos, interface, look & feel, software and
        structure, are subject to exclusive intellectual property rights of
        FILISTOS ASCOT protected under applicable intellectual property laws.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">6. Payment.</div>

      <p class="body-1">
        There is a licence fee for the Current Version and selected
        features/plugins of CASE and the granting of the licence, annually
        prepaid as agreed based on the price list or the offer you were
        provided.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">
        7. Consent to Electronic Notice.
      </div>

      <p class="body-1">
        If for any purpose under this EULA, you provide FILISTOS ASCOT with your
        email address, you agree to receive all notices and communications
        (“Notices”) from FILISTOS ASCOT in electronic form at such email
        address. Delivery of any Notice from FILISTOS ASCOT is effective when
        sent by FILISTOS ASCOT, regardless of whether you receive or read the
        Notice. You can update your email address by using the features of your
        user account.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">8. Ownership.</div>

      <p class="body-1">
        This EULA grants a license only and transfers to Licensee no ownership
        interest. FILISTOS ASCOT reserves all ownership rights as well as all
        other rights not expressly granted. FILISTOS ASCOT may use in any manner
        and without limitation all comments, suggestions, complaints, and other
        feedback that Licensee provides relating to the Application. All rights
        not expressly granted to Licensee are reserved to FILISTOS ASCOT.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">9. Indemnification.</div>

      <p class="body-1">
        Licensee agrees to indemnify, hold harmless, and at FILISTOS ASCOT’s
        request, to defend FILISTOS ASCOT from any and all costs, damages and
        reasonable attorneys’ fees resulting from any breach of this EULA or any
        allegation or claim that Licensee’s use of the Application has violated
        any right of any third party or violated any law.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">10. Termination.</div>

      <p class="body-1">
        Should Licensee breach this EULA, Licensee’s right to use the
        Application will terminate automatically without notice. Both parties
        have the right to terminate this EULA with a prior written notice of 3
        months at no cost and without reason. The respective rights and
        obligations of FILISTOS ASCOT and Licensee under the following
        provisions will survive termination: 4 (Restrictions on Use), 5
        (Responsibility for Copyright Content), 7 (Consent to Electronic
        Notice), 8 (Ownership), 9 (Indemnification), 10 (Termination), 11
        (Disclaimer of Warranty), 12 (Limitation of Liability), 14 (third party
        services or products) and 15 (Miscellaneous). Upon termination, Licensee
        will erase all copies of the Application.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">
        11. Disclaimer of Warranty.
      </div>

      <p class="body-1">
        The Application is provided “as is”. FILISTOS ASCOT disclaims all
        warranties whether express or implied. THE ENTIRE RISK ARISING OUT OF
        USE OR PERFORMANCE OF THE APPLICATION REMAINS WITH YOU. FILISTOS ASCOT
        does not make any representations regarding the results of the use of
        the Application.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">
        12. Limitation of Liability.
      </div>

      <p class="body-1">
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
        FILISTOS ASCOT OR ITS DIRECTORS, EMPLOYEES, DISTRIBUTORS, SUPPLIERS,
        AGENTS OR RESELLERS OR SUPPLIERS (“FILISTOS ASCOT PARTIES”) BE LIABLE
        FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY
        DAMAGES, EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY THEREOF.
        THE FILISTOS ASCOT PARTIES’ ENTIRE LIABILITY WILL NOT EXCEED IN THE
        AGGREGATE THE SUM OF THE FEES LICENSEE PAID FOR THIS LICENSE.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">13. Injunctive Relief.</div>

      <p class="body-1">
        Licensee acknowledges and agrees that, notwithstanding any other
        provisions of this EULA, any breach or threatened breach of this by
        Licensee will cause FILISTOS ASCOT irreparable damage for which recovery
        of money damages would be inadequate and that FILISTOS ASCOT therefore
        may obtain timely injunctive relief to protect its rights under this
        EULA in addition to any and all other remedies available at law.
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">
        14. Third Party Services or Products
      </div>

      <p class="body-1">
        a. The Application may contain links or be integrated with 3rd party
        applications, other web sites, resources, and advertisers. FILISTOS
        ASCOT is not responsible in any way for such external sites. b. Third
        parties may offer from time-to-time applications or services to access
        or interact with CASE. Licensee’s use of such third-party applications
        will be at Licensee’s own risk and subject to the terms and conditions
        of those third parties. FILISTOS ASCOT MAKES NO REPRESENTATION OR
        WARRANTIES REGARDING THIRD PARTY PRODUCTS EVEN IF DOWNLOADED FROM CASE
        OR BY MEANS OF A LINK ON THE APPLICATION (API).
      </p>

      <div class="subtitle-1 font-weight-bold mb-2">15. Miscellaneous</div>

      <p class="body-1">
        a. Licensee represents and warrants that Licensee has all required legal
        capacity to enter into this EULA as a binding agreement. b. This EULA is
        subject to the laws of Greece. Licensee agrees that the exclusive
        jurisdiction and venue for any claim or dispute relating to or arising
        out of this EULA or its subject matter will be in the courts located in
        Athens, Greece and Licensee consents to the personal jurisdiction in
        such courts. c. If any provision in this EULA should be held illegal or
        unenforceable, the other provisions of this EULA will remain in full
        force and effect. d. A waiver by either party of any term or condition
        of this EULA or any breach thereof, in any one instance, will not waive
        such term or condition or any subsequent breach thereof. e. Licensee may
        not assign or otherwise transfer by operation of law or otherwise this
        EULA or any rights or obligations herein. FILISTOS ASCOT may assign this
        EULA to any entity at its sole discretion. This EULA will be binding
        upon and will inure to the benefit of the parties, their successors and
        permitted assigns. f. Neither party will be in default or be liable for
        any delay, failure in performance (excepting any obligation to pay) or
        interruption of service resulting directly or indirectly from any cause
        beyond its reasonable control. g. This EULA, as amended from time to
        time in writing, constitutes the entire agreement between the parties
        concerning the subject matter hereof.
      </p>

      <div class="subtitle-2">FILISTOS ASCOT 2022</div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations(["toggleDialog"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
