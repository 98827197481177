var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column"},[_c('div',{staticClass:"body-1 font-weight-medium mb-5"},[_vm._v("Monitoring")]),_c('div',{staticClass:"wrapper d-flex flex-column rounded pa-4 flex-grow-1"},[_c('div',{staticClass:"d-flex align-start"},[(_vm.selectedAssignments.length > 0)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,false,3224413579)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.onSendProposedFilistosTraining}},[_c('v-list-item-title',[_vm._v("Send proposed Filistos Trainings ")])],1)],1)],1):_vm._e(),_c('v-text-field',{staticClass:"align-self-start flex-grow-0",class:{
          'mr-4': _vm.$route.name != 'adminMonitoring',
          'mr-2': _vm.$route.name == 'adminMonitoring',
        },attrs:{"outlined":"","dense":"","label":"Search","prepend-inner-icon":"mdi-magnify","hint":"Search by user full name","persistent-hint":"","value":_vm.monitoringParams.search,"readonly":_vm.loader,"clearable":""},on:{"input":function($event){return _vm.setMonitoringParams({ search: $event })}}}),(
          _vm.monitoringParams.questionnaire &&
            _vm.monitoringParams.questionnaire.questionnaireTypeId == 'psycho'
        )?_c('v-checkbox',{staticClass:"mt-1 mr-auto",attrs:{"label":"With Proposed Filistos Training","hide-details":""},on:{"change":function($event){return _vm.onFilterChange($event, 'checkbox')}},model:{value:(_vm.proposedFilistosTrainings),callback:function ($$v) {_vm.proposedFilistosTrainings=$$v},expression:"proposedFilistosTrainings"}}):_vm._e(),(_vm.$route.name != 'adminMonitoring')?_c('div',{staticClass:"d-flex flex-column mr-auto align-self-start"},[_c('v-menu',{attrs:{"top":"","right":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center mb-2 filter px-2 rounded"},'div',attrs,false),on),[_c('div',{staticClass:"body-2 mr-2"},[_vm._v("Reviewed:")]),_c('div',{staticClass:"subtitle-2 mr-2"},[_vm._v(" "+_vm._s(_vm.reviewedFilter.viewValue)+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)]}}],null,false,689933611)},[_c('div',{staticClass:"white pa-2"},_vm._l((_vm.reviewedFilters),function(filter,index){return _c('div',{key:filter.key,staticClass:"body-2 list-filter rounded px-1",class:{
                active: _vm.reviewedFilter == filter.key,
                'mb-2': index != _vm.reviewedFilters.length - 1,
              },on:{"click":function($event){return _vm.onCustomFilterChange('reviewed', filter)}}},[_vm._v(" "+_vm._s(filter.viewValue)+" ")])}),0)]),_c('v-menu',{attrs:{"top":"","right":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center mb-2 filter px-2 rounded"},'div',attrs,false),on),[_c('div',{staticClass:"body-2 mr-2"},[_vm._v("Notification:")]),_c('div',{staticClass:"subtitle-2 mr-2"},[_vm._v(_vm._s(_vm.notifFilter.viewValue))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)]}}],null,false,4023232412)},[_c('div',{staticClass:"white pa-2"},_vm._l((_vm.notifFilters),function(filter,index){return _c('div',{key:filter.key,staticClass:"body-2 list-filter rounded px-1",class:{
                active: _vm.notifFilter == filter.key,
                'mb-2': index != _vm.notifFilters.length - 1,
              },on:{"click":function($event){return _vm.onCustomFilterChange('notif', filter)}}},[_vm._v(" "+_vm._s(filter.viewValue)+" ")])}),0)])],1):_vm._e(),_c('FilterBy',{staticClass:"mr-2 ml-auto",attrs:{"fields":_vm.filterFields,"loader":_vm.loader},on:{"filterChange":_vm.onFilterChange}}),_c('ShowPerPage',{attrs:{"currentPerPage":_vm.monitoringParams.itemsPerPage,"options":_vm.showPerPageOptions}})],1),_c('v-data-table',{staticClass:" mb-4",attrs:{"item-key":"assignUserQuestId","headers":_vm.headers,"items":_vm.monitoring.results,"server-items-length":_vm.monitoring.rowCount,"checkbox-color":"primary","hide-default-footer":"","options":_vm.options,"loading":_vm.loader,"show-select":_vm.proposedFilistosTrainings ? true : false},on:{"update:options":[function($event){_vm.options=$event},function($event){return _vm.onGetMonitoring(true)}]},scopedSlots:_vm._u([{key:"item.dateCompleted",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.dateCompleted ? _vm.dayjs .utc(item.dateCompleted) .local() .format("DD/MM/YYYY ") : "-")+" ")]}},{key:"item.redCount",fn:function(ref){
              var item = ref.item;
return [(
            ((item.questionnaireTypeId == 'psycho' ||
              item.questionnaireTypeId == 'abillity') &&
              _vm.info.currentLogin.gmCanReadResults) ||
              (item.questionnaireTypeId == 'elearn' &&
                _vm.info.currentLogin.gmCanAccessElearning) ||
              (item.questionnaireTypeId == 'form' &&
                _vm.info.currentLogin.gmCanAccessForms) ||
              (item.questionnaireTypeId == 'ability' &&
                _vm.info.currentLogin.gmCanReadResults)
          )?_c('div',{staticClass:"bullet large filred"},[_vm._v(" "+_vm._s(item.redCount)+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.yellowCount",fn:function(ref){
          var item = ref.item;
return [(
            ((item.questionnaireTypeId == 'psycho' ||
              item.questionnaireTypeId == 'abillity') &&
              _vm.info.currentLogin.gmCanReadResults) ||
              (item.questionnaireTypeId == 'elearn' &&
                _vm.info.currentLogin.gmCanAccessElearning) ||
              (item.questionnaireTypeId == 'form' &&
                _vm.info.currentLogin.gmCanAccessForms) ||
              (item.questionnaireTypeId == 'ability' &&
                _vm.info.currentLogin.gmCanReadResults)
          )?_c('div',{staticClass:"bullet large filyellow"},[_vm._v(" "+_vm._s(item.yellowCount)+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.isReviewed",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"bullet small",class:{ filgreen: item.isReviewed, filred: !item.isReviewed }})]}},{key:"item.notifSent",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"bullet small",class:{ filgreen: item.notifSent, filred: !item.notifSent }})]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [(
            ((item.questionnaireTypeId == 'psycho' ||
              item.questionnaireTypeId == 'ability') &&
              _vm.info.currentLogin.gmCanReadResults) ||
              (item.questionnaireTypeId == 'elearn' &&
                _vm.info.currentLogin.gmCanAccessElearning) ||
              (item.questionnaireTypeId == 'form' &&
                _vm.info.currentLogin.gmCanAccessForms) ||
              (item.questionnaireTypeId == 'ability' &&
                _vm.info.currentLogin.gmCanReadResults)
          )?_c('v-btn',{attrs:{"small":"","width":"60px"},on:{"click":function($event){return _vm.$router.push({
              path: ((_vm.$route.name == 'adminMonitoring' ? '/' : '/superadmin/') + "monitoring/" + (item.assignUserQuestId)),
            })}}},[_vm._v("report")]):_vm._e()]}},{key:"item.filistosTrainingNumber",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.openFilistosTrainingNeeds(item)}}},[_vm._v(" "+_vm._s(item.filistosTrainingNumber)+" ")])]}}],null,true),model:{value:(_vm.selectedAssignments),callback:function ($$v) {_vm.selectedAssignments=$$v},expression:"selectedAssignments"}}),(_vm.monitoring.pageCount)?_c('v-pagination',{staticClass:"mt-auto",attrs:{"length":_vm.monitoring.pageCount},model:{value:(_vm.monitoringParams.page),callback:function ($$v) {_vm.$set(_vm.monitoringParams, "page", $$v)},expression:"monitoringParams.page"}}):_vm._e()],1),(_vm.filistosTrainingNeedsDialog.open)?_c('v-dialog',{attrs:{"max-width":"1250px"},model:{value:(_vm.filistosTrainingNeedsDialog.open),callback:function ($$v) {_vm.$set(_vm.filistosTrainingNeedsDialog, "open", $$v)},expression:"filistosTrainingNeedsDialog.open"}},[(_vm.filistosTrainingNeedsDialog.open)?_c('FilistosTrainingNeedsDialog',{attrs:{"questionnaire":_vm.filistosTrainingNeedsDialog.questionnaire},on:{"closeDialog":_vm.closeFilistosTrainingNeeds}}):_vm._e()],1):_vm._e(),(_vm.confirmDialog)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[(_vm.confirmDialog)?_c('confirm-dialog',{attrs:{"selectedAssignments":_vm.selectedAssignments},on:{"closeDialog":function($event){_vm.confirmDialog = false}}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }