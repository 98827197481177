var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper rounded flex-grow-1 d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-center align-md-start justify-start pa-5 pb-1"},[_c('div',{staticClass:"mr-md-5 mb-1 d-flex align-start"},[(_vm.notificationsParams.search)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.notificationsParams.search = ''}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column flex-sm-row ml-md-auto mb-2 mb-md-0"},[_c('ShowPerPage',{staticClass:"mr-2",attrs:{"currentPerPage":_vm.notificationsParams.itemsPerPage,"options":_vm.showPerPageOptions}})],1)]),_c('v-data-table',{staticClass:"clickable",attrs:{"headers":_vm.headers,"items":!_vm.getNotificationsLoader ? _vm.notifications.results : [],"server-items-length":_vm.notifications.rowCount,"loading":_vm.getNotificationsLoader,"item-key":"id","options":_vm.options,"hide-default-footer":""},on:{"click:row":function($event){_vm.dialog = { open: true, type: 'edit', item: $event }},"update:options":_vm.onGetNotifications},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title.length > 70 ? item.title.substring(0, 60) + "..." : item.title)+" ")]}},{key:"item.dateSent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dateSent ? _vm.dayjs(item.dateSent).format("DD/MM/YYYY") : "-")+" ")]}},{key:"item.importance",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary px-2 pa-1 rounded-pill white--text",staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.importance)+" ")])]}},{key:"item.send",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex white--text"},[_c('div',{staticClass:"bullet filgreen disabled mr-2"},[_vm._v(" "+_vm._s(item.sentToNum)+" ")]),_c('div',{staticClass:"bullet filgreen"},[_vm._v(" "+_vm._s(item.openedByNum)+" ")])])]}}],null,true)}),_c('div',{staticClass:"pagination-wrapper py-3 mt-auto"},[(_vm.notifications.pageCount)?_c('v-pagination',{attrs:{"length":_vm.notifications.pageCount,"disabled":_vm.getNotificationsLoader},model:{value:(_vm.notificationsParams.page),callback:function ($$v) {_vm.$set(_vm.notificationsParams, "page", $$v)},expression:"notificationsParams.page"}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":_vm.dialog.type == 'delete' ? '500px' : '1250px',"persistent":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open)?[(_vm.dialog.type == 'delete')?_c('div',{staticClass:"white"},[_c('div',{staticClass:"primary pa-3 d-flex align-start"},[_c('div',{staticClass:"text-h6 white--text"},[_vm._v("Delete Notifications")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","small":"","color":"white"},on:{"click":function($event){_vm.dialog = { open: false, type: null }}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"pa-5"},[_c('div',{staticClass:"text-h6 mb-2"},[_vm._v("WARNING")]),_c('div',{staticClass:"body-2 mb-4"},[_vm._v(" Are you sure you want to delete notifications? ")]),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2 white--text",attrs:{"color":"filred","depressed":""},on:{"click":function($event){_vm.dialog = { open: false, type: null }}}},[_vm._v("Delete Notifications")]),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.dialog = { open: false, type: null }}}},[_vm._v("Cancel")])],1)])]):_c('NotificationDialog',{attrs:{"isEdit":true,"notification":_vm.dialog.item},on:{"closeDialog":function($event){_vm.dialog = { open: false, type: null, item: null }}}})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }