<template>
  <div>
    <v-list dense nav>
      <div v-for="(link, index) in navLinks" :key="index">
        <v-list-item
          class="white--text"
          :class="{
            'mb-1': index != navLinks.length - 1,
            active: link.name && link.name.includes($route.name),
          }"
          :to="link.path"
          v-if="link.path"
          active-class="sidebar-activelink"
          exact
        >
          <v-list-item-icon>
            <v-icon color="white">{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["isCollapsed"],
  data() {
    return {
      navLinks: [
        {
          icon: "mdi-view-dashboard",
          text: "Dashboard",
          path: "/",
        },
        {
          icon: "mdi-account-multiple",
          text: "Groups",
          path: "/groups",
        },
        {
          icon: "mdi-account",
          text: "Users",
          path: "/users",
        },
        {
          icon: "mdi-calendar",
          text: "Scheduling",
          path: "/schedule",
        },
        {
          icon: "mdi-send",
          text: "Assignments",
          path: "/assignment",
        },
        // {
        //   icon: "mdi-bell",
        //   text: "Notifications",
        //   path: "/notifications",
        // },
        {
          icon: "mdi-monitor",
          text: "Monitoring",
          path: "/monitoring",
          name: ["adminMonitoring", "adminMonitoringReport"],
        },
        {
          icon: "mdi-chart-bar",
          text: "Comparison",
          path: "/comparison",
          name: ["adminComparisonPossible", "adminComparisonResults"],
        },

        {
          icon: "mdi-puzzle",
          text: "Personnel Matrix",
          path: "/crew-combinations",
          name: ["adminCrewCombinations", "adminCrewCombinationResults"],
        },

        {
          icon: "mdi-finance",
          text: "Statistics",
          path: "/statistics",
          name: ["adminStatistics"],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.auth.role,
      info: (state) => state.base.info,
    }),

    checkGmRoleType() {
      if (this.role == "gm") {
        if (
          this.info.currentLogin.gmCanAccessElearning ||
          this.info.currentLogin.gmCanAccessForms
        ) {
          return "gmCanSeeReport";
        } else if (this.info.currentLogin.gmCanReadResults) {
          return "gmCanSeeEverything";
        } else {
          return "gmCanSeeNothing";
        }
      } else {
        return "adminRights";
      }
    },
  },
  created() {
    if (this.role == "gm") {
      const index = this.navLinks.findIndex((link) => link.path == "/groups");
      this.navLinks.splice(index, 1);
      if (this.info.hasMobileNotifications)
        this.navLinks.splice(4, 0, {
          icon: "mdi-bell",
          text: "Notifications",
          path: "/notifications",
        });
      if (!this.info.currentLogin.gmCanReadResults) {
        const j = this.navLinks.findIndex((link) => link.path == "/comparison");
        this.navLinks.splice(j, 1);
      }
    }

    if (this.role == "co") {
      if (this.info.hasMobileNotifications)
        this.navLinks.splice(5, 0, {
          icon: "mdi-bell",
          text: "Notifications",
          path: "/notifications",
        });
    }

    if (this.role == "psysupport") {
      this.navLinks = [
        {
          icon: "mdi-view-dashboard",
          text: "Dashboard",
          path: "/",
        },
        {
          icon: "mdi-account",
          text: "Self Evaluations",
          path: "/psysupport/self-evaluations",
          name: ["psysupSelfEvaluations", "psysupSelfEvaluation"],
        },
        {
          icon: "mdi-account-multiple",
          text: "Cases",
          path: "/psysupport/cases",
          name: ["psysupCases", "psysupCase"],
        },
        {
          icon: "mdi-chart-bar",
          text: "Comparison",
          path: "/psysupport/comparison",
          name: ["psysupComparisonPossible", "psysupComparisonResults"],
        },
      ];
    }
  },
};
</script>

<style lang="scss">
.sidebar-activelink {
  background-color: rgba(#fff, 0.1);
}
</style>
