import store from '@/store'

import Dashboard from '../views/superadmin/dashboard/Dashboard.vue'

import SelfEvaluations from '../views/psysup/SelfEvaluations.vue'
import SelfEvaluation from '../views/psysup/SelfEvaluation.vue'

import Cases from '../views/psysup/Cases.vue'
import Case from '../views/psysup/Case.vue'
import ComparisonPossibleToCompare from "../views/admin/comparison/ComparisonPossibleToCompare.vue";
import ComparisonResults from "../views/admin/comparison/ComparisonResults.vue";

export default [
    {
        path: '/allpsysup',
        component: Dashboard,
        name: "allpsysupDashboard",
        meta: { requiresAuth: true, role: "allpsysup" },
        beforeEnter: async (to, from, next) => {
            try {
                store.commit("toggleGlobalLoader", true);

                await Promise.all([
                    store.dispatch("getSuperAdminUsersPerAccess"),
                    store.dispatch("getSuperAdminEvaluatedUsers", {
                        questionnaireTypeId: "psycho",
                        period: "year",
                    }),
                    store.dispatch("getSuperAdminUsersPerNationality"),
                    store.dispatch("getSuperAdminDashboardAssignments"),
                    store.dispatch("getSuperAdminMessages"),
                ]);

                store.commit("toggleGlobalLoader", false);

                next();
            } catch (e) {
                store.commit("toggleGlobalLoader", false);
                store.commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: 5000
                })
                next(false);
            }
        }
    },
    {
        path: '/allpsysup/self-evaluations',
        component: SelfEvaluations,
        meta: { requiresAuth: true, role: "allpsysup" },
        name: "allpsysupSelfEvaluations"
    },
    {
        path: '/allpsysup/self-evaluations/:examinationId',
        component: SelfEvaluation,
        meta: { requiresAuth: true, role: "allpsysup" },
        name: "allpsysupSelfEvaluation",
        beforeEnter: async (to, from, next) => {
            try {
                await store.dispatch("getSelfEval", to.params.examinationId);

                if (store.state.psysupport.currentSelfEval?.examinationId == to.params.examinationId) {
                    next()
                } else {
                    next(`/`);
                }
            } catch (e) {
                next('/')
            }
        }
    },

    {
        path: '/allpsysup/cases',
        component: Cases,
        meta: { requiresAuth: true, role: "allpsysup" },
        name: "allpsysupCases"
    },
    {
        path: '/allpsysup/cases/:examinationId',
        component: Case,
        meta: { requiresAuth: true, role: "allpsysup" },
        name: "allpsysupCase",
        beforeEnter: async (to, from, next) => {
            try {
                await store.dispatch("getSelfEval", to.params.examinationId);

                if (store.state.psysupport.currentSelfEval?.examinationId == to.params.examinationId) {
                    next()
                } else {
                    next(`/`);
                }
            } catch (e) {
                next('/')
            }
        }
    },
    {
        path: "/allpsysup/comparison",
        component: ComparisonPossibleToCompare,
        name: "allPsysupComparisonPossible",
        meta: { requiresAuth: true, role: "allpsysup" },
    },
    {
        path: "/allpsysup/comparison/results",
        component: ComparisonResults,
        name: "allPsysupComparisonResults",
        meta: { requiresAuth: true, role: "allpsysup" },
        beforeEnter: async (to, from, next) => {
            try {
                if (store.state.comparison.userQuestsToCompare.length) {
                    next();
                } else {
                    next("/allpsysupport/comparison");
                }
            } catch (e) {
                console.log(e);
                next("/");
            }
        },
    },
]
