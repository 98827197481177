<template>
  <div
    class="flex-grow-1 px-4 grey lighten-5 rounded pt-2"
    v-if="currentSection"
  >
    <div class="d-flex justify-start">
      <v-text-field
        outlined
        dense
        hide-details
        label="ID"
        class="mb-2 flex-grow-0"
        :value="currentSection.sectionId"
        disabled
      ></v-text-field>
    </div>

    <v-tabs
      v-model="tab"
      class="mb-4"
      background-color="grey lighten-5"
      color="filyellow"
    >
      <v-tab> Texts </v-tab>

      <v-tab> Questions </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-tabs
          vertical
          background-color="grey lighten-5"
          color="filyellow"
          v-model="langTab"
        >
          <v-tab
            v-for="sectionText in currentSection.sectionTexts"
            :key="sectionText.langId"
            :href="`#${sectionText.langId}`"
            :disabled="hasChanges"
            >{{ sectionText.langName }}</v-tab
          >

          <v-tab-item
            v-for="sectionText in currentSection.sectionTexts"
            :key="sectionText.langId"
            :value="sectionText.langId"
          >
            <form
              class="px-4 pb-4 pt-2 d-flex flex-column grey lighten-5"
              @submit.prevent="submit"
            >
              <div class="section-form">
                <v-text-field
                  dense
                  outlined
                  label="Title"
                  hide-details
                  class="mb-4"
                  v-model="title"
                >
                </v-text-field>

                <v-textarea
                  dense
                  outlined
                  label="Description"
                  hide-details
                  rows="3"
                  no-resize
                  class="mb-4"
                  v-model="description"
                >
                </v-textarea>

                <div class="subtitle-2 mb-2">Section Instructions</div>

                <tiptap-vuetify
                  v-model="intro"
                  :extensions="extensions"
                  placeholder="Send a message"
                  class="mb-5"
                />

                <v-btn
                  depressed
                  color="filyellow"
                  class="white--text"
                  type="submit"
                  :disabled="!hasChanges"
                  :loading="loader"
                >
                  Save
                </v-btn>
              </div>
            </form>
          </v-tab-item>
        </v-tabs>
      </v-tab-item>

      <v-tab-item>
        <Questions />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Questions from "@/views/superadmin/questionnaires/questionnaire/sections/Questions.vue";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  History,
  Image,
} from "tiptap-vuetify";

export default {
  components: {
    Questions,
    TiptapVuetify,
  },
  data() {
    return {
      tab: 0,
      title: "",
      description: "",
      intro: "",
      langTab: "en",
      initialData: null,
      loader: false,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        Paragraph,
        HardBreak,
        Image,
      ],
    };
  },
  computed: {
    ...mapState({
      currentQuestionnaire: (state) =>
        state.questionnaires.currentQuestionnaire,
      currentSection: (state) => state.questionnaires.currentSection,
    }),
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !=
        JSON.stringify({
          title: this.title,
          description: this.description,
          intro: this.intro,
        })
      );
    },
  },
  methods: {
    ...mapMutations(["getSection"]),
    ...mapActions(["updateSection"]),
    initiSectionTextsData(langId) {
      const sectionText = this.currentSection.sectionTexts.find(
        (st) => st.langId == langId
      );

      this.title = sectionText?.title;
      this.description = sectionText?.description;
      this.intro = sectionText?.introHtml;

      this.initialData = {
        title: this.title,
        description: this.description,
        intro: this.intro,
      };
    },
    async submit() {
      this.loader = true;

      try {
        await this.updateSection({
          questionnaireId: this.currentQuestionnaire.questionnaireId,
          sectionId: this.currentSection.sectionId,
          languageId: this.langTab,
          sectionName: this.title,
          sectionDescription: this.description,
          introHtml: this.intro,
        });

        this.initiSectionTextsData(this.langTab);
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  watch: {
    langTab: {
      immediate: true,
      handler(newValue) {
        this.initiSectionTextsData(newValue);
      },
    },
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      await this.$store.dispatch("getSection", to.params.sectionId);

      this.langTab = "en";
      this.initiSectionTextsData(this.langTab);

      next();
    } catch (e) {
      console.log(e);
      next("/superadmin/questionnaires");
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != "questionnaireQuestion") this.getSection(null);

    next();
  },
};
</script>

<style lang="scss" scoped>
.section-form {
  width: 600px;
}
</style>
