import { render, staticRenderFns } from "./PsychoIndividualAbility.vue?vue&type=template&id=b8282d72&scoped=true&"
import script from "./PsychoIndividualAbility.vue?vue&type=script&lang=js&"
export * from "./PsychoIndividualAbility.vue?vue&type=script&lang=js&"
import style0 from "./PsychoIndividualAbility.vue?vue&type=style&index=0&id=b8282d72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8282d72",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})
