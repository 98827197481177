import axios from 'axios';

export default {
    state: {
        selfEvalsParams: {
            itemsPerPage: 10,
            page: 1,
            sortBy: [],
            sortDesc: [],
            search: '',
            dateFrom: null,
            dateTo: null,
            mobileAppId: null,
            clientId: null,
        },
        selfEvals: {
            results: [],
            rowCount: 0,
        },
        selfEvalsFethed: false,
        currentSelfEval: null,
    },
    mutations: {
        setSelfEvalsParams(state, params) {
            state.selfEvalsParams = {
                ...state.selfEvalsParams,
                ...params,
            };
        },
        getSelfEvals(state, selfEvals) {
            state.selfEvals = selfEvals;
        },
        toggleSelfEvalsFetched(state, fetched) {
            state.selfEvalsFethed = fetched;
        },
        setCurrentSelfEval(state, selfEval) {
            state.currentSelfEval = selfEval;
        },
        clearSelfEvalsSTate(state) {
            state.selfEvalsParams = {
                itemsPerPage: 10,
                page: 1,
                sortBy: [],
                sortDesc: [],
                search: '',
                dateFrom: null,
                dateTo: null,
                mobileAppId: null,
                clientId: null,
            };
            state.selfEvals = {
                results: [],
                rowCount: 0,
            };
            state.selfEvalsFethed = false;
            state.currentSelfEval = null;
        },
    },
    actions: {
        async getSelfEvals({ commit, state, rootState }, mobileAppId) {

            try {
                const res = await axios.get(
                    `${process.env.VUE_APP_BASE_URL}/${rootState.auth.role == 'allpsysup' ? 'SuperPsySup' : 'psysup'
                    }/GetSelfEvaluations`,
                    {
                        params: {
                            pageSize:
                                state.selfEvalsParams.itemsPerPage != -1
                                    ? state.selfEvalsParams.itemsPerPage
                                    : 1000,
                            pageNo: state.selfEvalsParams.page,
                            ...(state.selfEvalsParams.sortBy[0] && {
                                sortBy: state.selfEvalsParams.sortBy[0],
                            }),
                            ...(state.selfEvalsParams.sortDesc[0] && { dir: 'desc' }),
                            ...(state.selfEvalsParams.search && {
                                search: state.selfEvalsParams.search,
                            }),
                            ...(state.selfEvalsParams.dateFrom && {
                                fromDate: state.selfEvalsParams.dateFrom,
                            }),
                            ...(state.selfEvalsParams.dateTo && {
                                toDate: state.selfEvalsParams.dateTo,
                            }),

                            examTypeId: mobileAppId,

                            ...(state.selfEvalsParams.clientId && {
                                clientId: state.selfEvalsParams.clientId,
                            }),
                        },
                    }
                );

                commit('getSelfEvals', res.data);
                commit('toggleSelfEvalsFetched', true);
            } catch (e) {
                commit('toggleSnackbar', {
                    open: true,
                    color: 'error',
                    text: 'Something went wrong. Please try again later.',
                    timeout: 5000,
                });

                throw e;
            }
        },
        async getSelfEval({ commit, state, rootState }, examinationId) {
            try {
                if (state.currentSelfEval) return;

                commit('toggleGlobalLoader', true);

                const res = await axios.get(
                    `${process.env.VUE_APP_BASE_URL}/${rootState.auth.role == 'allpsysup' ? 'SuperPsySup' : 'psysup'
                    }/GetSelfEvaluation/${examinationId}`
                );

                res.data.factors = res.data.factors.map((factor) => {
                    const parser = new DOMParser();
                    const elem = parser.parseFromString(
                        factor.interventionHtml,
                        'text/html'
                    );
                    return {
                        ...factor,
                        interventionHtml: elem.body.innerText,
                    };
                });

                commit('setCurrentSelfEval', res.data);
                commit('toggleGlobalLoader', false);
            } catch (e) {
                commit('toggleGlobalLoader', false);
                commit('toggleSnackbar', {
                    open: true,
                    color: 'error',
                    text: 'Something went wrong. Please try again later.',
                    timeout: 5000,
                });

                throw e;
            }
        },
    },
};
