<template>
  <div class="d-flex">
    <v-menu offset-y :close-on-content-click="false" v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          color="primary"
          left
          :value="Boolean(countFilters)"
          :content="countFilters"
          class="flex-grow-1"
          overlap
        >
          <v-btn depressed v-bind="attrs" v-on="on" width="100%">
            <v-icon left>mdi-filter</v-icon>
            Filter
          </v-btn>
        </v-badge>
      </template>

      <div class="white pa-5">
        <div v-for="field in fields" :key="field.key">
          <template v-if="field.key == 'position'">
            <v-radio-group
              v-model="positionType"
              row
              class="mt-0 mb-2"
              dense
              hide-details
            >
              <v-radio
                v-for="type in userTypes"
                :key="type.itemKey"
                :value="type.itemKey"
                :label="type.itemValue"
                :ripple="false"
              ></v-radio>
            </v-radio-group>

            <v-select
              dense
              outlined
              hide-details
              label="Position"
              :items="positions"
              item-value="itemKey"
              item-text="itemValue"
              clearable
              @change="onFilterChange($event, field.key)"
              :readonly="loader"
              :value="field.selectedValue.positionId"
            ></v-select>
          </template>

          <div v-else-if="field.key == 'dateFrom' || field.key == 'dateTo'">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="getFormattedDate(field.selectedValue)"
                  dense
                  prepend-inner-icon="mdi-calendar"
                  hide-details
                  outlined
                  clearable
                  :label="field.value"
                  class="mb-2"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="onFilterChange(null, field.key)"
                ></v-text-field>
              </template>

              <v-date-picker
                :value="field.selectedValue"
                @change="
                  fromMenu = false;
                  onFilterChange($event, field.key);
                "
              ></v-date-picker>
            </v-menu>
          </div>

          <v-select
            v-else
            outlined
            dense
            hide-details
            class="mb-2"
            :label="field.value"
            :items="field.values"
            :item-value="field.key == 'groupId' ? 'groupId' : 'itemKey'"
            :item-text="field.key == 'groupId' ? 'groupName' : 'itemValue'"
            clearable
            @change="onFilterChange($event, field.key)"
            :readonly="loader"
            :value="field.selectedValue"
          ></v-select>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["fields", "loader"],
  data() {
    return {
      menu: false,
      positionType: null,
      fromMenu: false,
      toMenu: false,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.auth.role,
    }),
    userTypes() {
      return this.role == "filistos"
        ? this.$store.state.clients.currentClient?.userTypes
        : this.$store.state.base.lists?.userTypes;
    },
    positions() {
      if (!this.positionType) return [];

      if (this.role == "filistos") {
        return this.$store.state.clients.currentClient?.workPositions?.find(
          (wp) => wp.itemType == this.positionType
        )?.items;
      }

      return this.$store.state.base.lists[this.positionType];
    },
    countFilters() {
      return this.fields.filter(
        (field) =>
          (field.selectedValue && typeof field.selectedValue != "object") ||
          field.selectedValue?.positionId
      ).length;
    },
  },
  created() {
    const positionField = this.fields.find((field) => field.key == "position");
    this.positionType = positionField?.selectedValue?.positionType;
  },
  methods: {
    onFilterChange(value, field) {
      if (field == "position") {
        this.$emit("filterChange", {
          value: {
            positionType: this.positionType,
            positionId: value,
          },
          field,
        });
      } else {
        this.$emit("filterChange", {
          value,
          field,
        });
      }
    },
    getFormattedDate(date) {
      return date ? this.dayjs(date).format("DD/MM/YYYY") : "";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
