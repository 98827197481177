<template>
  <v-app-bar absolute bottom color="filblue" dense clipped-left id="footer">
    <div class="flex-grow-1 d-flex justify-center">
      <div class="white--text text-center body-2">
        Filistos Ascot - ACT Platform &copy; {{ year }}
      </div>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
#footer {
  z-index: 5;
}
</style>
