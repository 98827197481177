var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.selectedUser.trainingNeeds,"hide-default-footer":"","mobile-breakpoint":"0","items-per-page":1000},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"dense":"","hide-details":"","ripple":false,"value":{
          clientUserId: item.clientUserId,
          identityNumber: item.identityNumber,
          firstName: item.firstName,
          lastName: item.lastName,
          userEmail: item.userEmail,
        }},model:{value:(_vm.form.assigneeUserIds),callback:function ($$v) {_vm.$set(_vm.form, "assigneeUserIds", $$v)},expression:"form.assigneeUserIds"}})]}},{key:"item.userEmail",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.userEmail ? item.userEmail : "-")+" ")]}},{key:"item.languageName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.languageName ? item.languageName : "-")+" ")]}}],null,true)},[_c('template',{slot:"header.data-table-select"},[_c('div',{staticStyle:{"cursor":"none"}})])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }