<template>
  <div class="component-wrapper pb-0 d-flex flex-column">
    <form class="flex-grow-1 d-flex flex-column" @submit.prevent="submit">
      <v-row class="scrollable mb-8">
        <v-col cols="6">
          <v-text-field
            outlined
            dense
            hide-details
            label="ID"
            class="mb-4"
            :value="currentQuestionnaire.questionnaireId"
            disabled
          ></v-text-field>

          <v-text-field
            outlined
            dense
            hide-details
            label="Full Name"
            class="mb-4"
            v-model="fullName"
            :error="$v.fullName.$dirty && $v.fullName.$invalid"
          ></v-text-field>

          <v-text-field
            outlined
            dense
            hide-details
            label="Short Name"
            class="mb-4"
            v-model="shortName"
          ></v-text-field>

          <div
            class="d-flex flex-column mb-4"
            v-if="
              currentQuestionnaire.questionnaireTypeId == 'ability' &&
              !currentQuestionnaire.isCollectionHead
            "
          >
            <div class="subtitle-2 mb-2">Duration</div>

            <div class="d-flex">
              <v-text-field
                outlined
                dense
                hide-details
                label="Minutes"
                class="mr-4"
                v-model.number="minutes"
                type="number"
                :error="$v.timeLimitSec.$dirty && $v.timeLimitSec.$invalid"
              ></v-text-field>

              <v-text-field
                outlined
                dense
                hide-details
                label="Seconds"
                class="mb-4"
                v-model.number="seconds"
                type="number"
                :error="$v.timeLimitSec.$dirty && $v.timeLimitSec.$invalid"
              ></v-text-field>
            </div>
          </div>

          <v-text-field
            outlined
            dense
            hide-details
            label="Url"
            class="mb-4"
            v-model="url"
            v-if="
              currentQuestionnaire.questionnaireTypeId == 'elearn' &&
              !currentQuestionnaire.isGroupHead
            "
            :error="$v.url.$dirty && $v.url.$invalid"
          ></v-text-field>

          <div
            class="d-flex flex-column mb-4"
            v-if="currentQuestionnaire.isCollectionHead"
          >
            <div class="subtitle-2 mb-2">Parts</div>

            <v-list dense>
              <v-list-item
                v-for="questionnaire in currentQuestionnaire.partQuestionnaires"
                :key="questionnaire.questionnaireId"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    questionnaire.questionnaireName
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>

        <v-col cols="6">
          <div class="subtitle-2 mb-2">Description</div>

          <tiptap-vuetify
            :extensions="extensions"
            class="mb-4"
            v-model="description"
          />
        </v-col>
      </v-row>

      <div
        class="body-2 font-weight-medium error--text mb-4"
        v-if="$v.$dirty && $v.$invalid"
      >
        Please fill the required fields.
      </div>

      <v-btn
        type="submit"
        color="filyellow"
        class="white--text align-self-center"
        depressed
        :loading="loader"
        :disabled="!hasChanges"
        >Save</v-btn
      >
    </form>
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  History,
  Image,
} from "tiptap-vuetify";
import { mapActions, mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  components: { TiptapVuetify },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        Paragraph,
        HardBreak,
        Image,
      ],
      fullName: "",
      shortName: "",
      description: "",
      minutes: null,
      seconds: null,
      url: null,
      loader: false,
      initialData: null,
    };
  },
  validations: {
    fullName: { required },
    timeLimitSec: {
      required: requiredIf(function () {
        return (
          this.currentQuestionnaire.questionnaireTypeId == "ability" &&
          !this.currentQuestionnaire.isCollectionHead
        );
      }),
    },
    url: {
      required: requiredIf(function () {
        return (
          this.currentQuestionnaire.questionnaireTypeId == "elearn" &&
          !this.currentQuestionnaire.isGroupHead
        );
      }),
    },
  },
  computed: {
    ...mapState({
      currentQuestionnaire: (state) =>
        state.questionnaires.currentQuestionnaire,
    }),
    timeLimitSec() {
      if (
        this.currentQuestionnaire.questionnaireTypeId == "ability" &&
        !this.currentQuestionnaire.isCollectionHead
      ) {
        if (!this.minutes && !this.seconds) {
          return null;
        } else {
          return this.minutes * 60 + this.seconds;
        }
      } else {
        return null;
      }
    },
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !=
        JSON.stringify({
          questionnaireName: this.fullName,
          questionnaireShortName: this.shortName,
          descriptionHtml: this.description,
          ...(this.timeLimitSec && { timeLimitSec: this.timeLimitSec }),
          ...(this.currentQuestionnaire.url && {
            url: this.url,
          }),
        })
      );
    },
  },
  created() {
    this.fullName = this.currentQuestionnaire.questionnaireName;
    this.shortName = this.currentQuestionnaire.questionnaireShortName;
    this.description = this.currentQuestionnaire.descriptionHtml
      ? this.currentQuestionnaire.descriptionHtml
      : "";

    if (
      this.currentQuestionnaire.questionnaireTypeId == "ability" &&
      !this.currentQuestionnaire.isCollectionHead
    ) {
      if (this.currentQuestionnaire.timeLimitSec) {
        const duraction = this.dayjs(this.currentQuestionnaire.timeLimitSec)
          .add(this.currentQuestionnaire.timeLimitSec, "seconds")
          .format("mm:ss");

        this.minutes = parseInt(duraction.toString().split(":")[0]);
        this.seconds = parseInt(duraction.toString().split(":")[1]);
      }
    }

    if (this.currentQuestionnaire.questionnaireTypeId == "elearn") {
      this.url = this.currentQuestionnaire.url;
    }

    this.initialData = {
      questionnaireName: this.fullName,
      questionnaireShortName: this.shortName,
      descriptionHtml: this.description,
      ...(this.timeLimitSec && { timeLimitSec: this.timeLimitSec }),
      ...(this.currentQuestionnaire.url && {
        url: this.url,
      }),
    };
  },
  methods: {
    ...mapActions(["updateQuestionnaire"]),
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        await this.updateQuestionnaire({
          questionnaireId: this.currentQuestionnaire.questionnaireId,
          questionnaireName: this.fullName,
          questionnaireShortName: this.shortName,
          descriptionHtml: this.description,
          ...(this.timeLimitSec && { timeLimitSec: this.timeLimitSec }),
          ...(this.currentQuestionnaire.url && {
            url: this.url,
          }),
        });

        this.initialData = {
          questionnaireName: this.fullName,
          questionnaireShortName: this.shortName,
          descriptionHtml: this.description,
          ...(this.timeLimitSec && { timeLimitSec: this.timeLimitSec }),
          ...(this.currentQuestionnaire.url && {
            url: this.url,
          }),
        };
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  watch: {
    description(newValue) {
      if (newValue == "<p></p>") this.description = "";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
