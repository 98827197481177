<template>
  <div class="d-flex">
    <v-tabs vertical>
      <v-tab
        v-for="(tab, index) in tab.reportItems"
        :key="`${index}-${tab.itemId}`"
      >
        <div class="subtitle-2 mr-3">
          {{ tab.ItemName }}
        </div>
      </v-tab>

      <v-tab-item
        v-for="(tab, index) in tab.reportItems"
        :key="`${index}-${tab.itemId}`"
      >
        <v-tabs color="filyellow" v-if="tab.subItems">
          <v-tab
            v-for="(subitem, index) in tab.subItems"
            :key="`${index}-${subitem.itemId}`"
          >
            <div class="subtitle-2 mr-3">
              {{ subitem.ItemName }}
            </div>
          </v-tab>

          <v-tab-item
            v-for="(subitem, index) in tab.subItems"
            :key="`${index}-${subitem.itemId}`"
            class="mt-4"
          >
            <PsychoFactors :factors="subitem.factors" />
          </v-tab-item>
        </v-tabs>

        <PsychoFactors :factors="tab.factors" v-else />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import PsychoFactors from "@/views/admin/assignment/assignment-dialog/report/psycho/PsychoFactors";

export default {
  components: {
    PsychoFactors,
  },
  props: ["tab"],
  created() {
    console.log(this.tab);
  },
};
</script>

<style lang="scss" scoped>
</style>
