var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-1"},[(_vm.$vuetify.breakpoint.xsOnly)?_c('div',{staticClass:"d-flex align-center mb-5"},[_c('div',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v("Step 1")]),_c('div',{staticClass:"body-1 mx-2"},[_vm._v("-")]),_c('div',{staticClass:"body-1"},[_vm._v("Basic Information")])]):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"outlined":"","hide-details":"","dense":"","label":"Title","disabled":_vm.isEdit,"value":_vm.form.assignmentName,"error":_vm.v.form.assignmentName.$dirty && _vm.v.form.assignmentName.$invalid},on:{"input":function($event){return _vm.setForm({ value: $event, key: 'assignmentName' })}}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"value":_vm.dateToSendFormatted,"dense":"","prepend-inner-icon":"mdi-calendar","hide-details":"","outlined":"","label":"Date to Send","readonly":"","error":_vm.v.form.dateToSend.$dirty && _vm.v.form.dateToSend.$invalid,"disabled":_vm.isEdit}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateToSendMenu),callback:function ($$v) {_vm.dateToSendMenu=$$v},expression:"dateToSendMenu"}},[_c('v-date-picker',{attrs:{"value":_vm.form.dateToSend,"min":new Date().toISOString().substr(0, 10)},on:{"input":function($event){return _vm.setForm({ value: $event, key: 'dateToSend' })},"change":_vm.onDateToSendChanged}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"value":_vm.dateToRemindFormatted,"dense":"","prepend-inner-icon":"mdi-calendar","hide-details":"","outlined":"","label":"Date to Remind","readonly":"","error":_vm.v.form.dateToRemind.$dirty && _vm.v.form.dateToRemind.$invalid}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateToRemindMenu),callback:function ($$v) {_vm.dateToRemindMenu=$$v},expression:"dateToRemindMenu"}},[_c('v-date-picker',{attrs:{"value":_vm.form.dateToRemind,"min":_vm.form.dateToSend
              ? _vm.dayjs.utc(_vm.form.dateToSend).local().toISOString().substr(0, 10)
              : null},on:{"input":function($event){return _vm.setForm({ value: $event, key: 'dateToRemind' })},"change":function($event){_vm.dateToRemindMenu = false}}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"value":_vm.dateToEndFormatted,"dense":"","prepend-inner-icon":"mdi-calendar","hide-details":"","outlined":"","label":"Date End","readonly":"","error":_vm.v.form.dateToEnd.$dirty && _vm.v.form.dateToEnd.$invalid}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateToEndMenu),callback:function ($$v) {_vm.dateToEndMenu=$$v},expression:"dateToEndMenu"}},[_c('v-date-picker',{attrs:{"value":_vm.form.dateToEnd,"min":_vm.form.dateToSend
              ? _vm.dayjs.utc(_vm.form.dateToSend).local().toISOString().substr(0, 10)
              : null},on:{"input":function($event){return _vm.setForm({ value: $event, key: 'dateToEnd' })},"change":function($event){_vm.dateToEndMenu = false}}})],1),_c('v-select',{staticClass:"mb-4",attrs:{"outlined":"","hide-details":"","dense":"","label":"Type","items":_vm.assignmentSendTypes,"item-text":"itemValue","value":_vm.form.sendTypeId,"return-object":"","error":_vm.v.form.sendTypeId.$dirty && _vm.v.form.sendTypeId.$invalid,"disabled":_vm.isEdit},on:{"input":function($event){return _vm.setForm({ value: $event, key: 'sendTypeId' })}}}),_c('v-select',{attrs:{"outlined":"","dense":"","label":"Email Confirmation to","value":_vm.form.usersToNotifyIds,"loading":_vm.usersToNotifyLoader,"items":_vm.possitbleUsersToNotify,"item-text":"userEmail","return-object":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","error":_vm.v.form.usersToNotifyIds.$dirty && _vm.v.form.usersToNotifyIds.$invalid},on:{"input":function($event){return _vm.setForm({ value: $event, key: 'usersToNotifyIds' })}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
              var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-checkbox',{staticClass:"pa-0 ma-0 mr-2",attrs:{"input-value":attrs.inputValue,"readonly":"","dense":"","hide-details":""}}),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item.userEmail))])])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }