<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="pl-4 d-flex align-center">
      <div class="subtitle-1 font-weight-medium">
        {{ currentUser.firstName }} {{ currentUser.lastName }} ({{
          currentUser.identityNumber
        }})
      </div>

      <div class="mx-2">|</div>

      <div>
        {{ currentUser.platformAccessName }}
      </div>

      <div class="text-hint ml-auto" v-if="$route.name == 'editUserUserData'">
        Created by {{ currentUser.createdBy }}
      </div>
    </div>

    <v-tabs align-with-title show-arrows class="flex-grow-0 mb-5">
      <v-tab
        v-if="isEdit"
        exact
        :to="`/superadmin/clients/${$route.params.clientId}/users/${$route.params.userId}/history`"
      >
        History
      </v-tab>
      <v-tab
        v-if="isEdit"
        active-class="activeAssignment"
        :class="{
          activeAssignment:
            $route.name == 'editUserUserAssignments' ||
            $route.name == 'editUserUserReport',
        }"
        exact
        :to="`/superadmin/clients/${$route.params.clientId}/users/${$route.params.userId}`"
      >
        Assignments
      </v-tab>

      <v-tab
        exact
        :to="`/superadmin/clients/${$route.params.clientId}/users/${$route.params.userId}/user-data`"
      >
        User Data
      </v-tab>

      <v-tab
        exact
        :to="`/superadmin/clients/${$route.params.clientId}/users/${$route.params.userId}/user-groups`"
        v-if="isEdit"
      >
        Group(s) Belonging
      </v-tab>
    </v-tabs>

    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  created() {
    console.log(this.role);
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.usersNew.currentUser,
      role: (state) => state.auth.role,
    }),
    isEdit() {
      return Boolean(this.currentUser);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
