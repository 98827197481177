<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="selectedUser.trainingNeeds"
      hide-default-footer
      mobile-breakpoint="0"
      :items-per-page="1000"
    >
      <template slot="header.data-table-select">
        <div style="cursor: none"></div>
      </template>

      <template v-slot:[`item.data-table-select`]="{ item }">
        <v-checkbox
          dense
          hide-details
          class="pa-0 ma-0"
          :ripple="false"
          :value="{
            clientUserId: item.clientUserId,
            identityNumber: item.identityNumber,
            firstName: item.firstName,
            lastName: item.lastName,
            userEmail: item.userEmail,
          }"
          v-model="form.assigneeUserIds"
        ></v-checkbox>
      </template>

      <template v-slot:[`item.userEmail`]="{ item }">
        {{ item.userEmail ? item.userEmail : "-" }}
      </template>

      <template v-slot:[`item.languageName`]="{ item }">
        {{ item.languageName ? item.languageName : "-" }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["selectedUser"],
  data() {
    return {
      headers: [
        { text: "Code", value: "trainingCode" },
        { text: "Type", value: "trainingType" },
        { text: "Description", value: "trainingDescription" },
      ],
    };
  },
  created() {
    console.log(this.selectedUser.trainingNeeds);
  },
};
</script>

<style lang="scss" scoped></style>
