<template>
  <div class="d-flex flex-column statistics align-self-center mb-7">
    <template v-if="isFiltered">
      <div class="bar-chart mb-4">
        <div class="d-flex mb-1">
          <div class="caption font-weight-medium">Answered / Assigned</div>
          <div class="caption ml-auto">
            {{ dashboardAssignments.sumFinalizedAssignmentItems }} /
            {{ dashboardAssignments.sumAssignmentItems }}
          </div>
        </div>

        <v-progress-linear
          background-color="filred"
          color="filgreen"
          value="37"
        ></v-progress-linear>
      </div>

      <div class="bar-chart">
        <div class="d-flex mb-1">
          <div class="caption font-weight-medium">Evaluated Users</div>
          <div class="caption ml-auto">
            {{ dashboardAssignments.evaluatedUsers }}/{{
              dashboardAssignments.allUsers
            }}
          </div>
        </div>

        <v-progress-linear
          background-color="filred"
          color="filgreen"
          value="41"
        ></v-progress-linear>
      </div>
    </template>

    <template v-else>
      <h1 class="text-center">TBD</h1>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["isFiltered", "isSuperadmin"],
  computed: {
    ...mapState({
      dashboardAssignmentsParams: (state) =>
        state.dashboard.dashboardAssignmentsParams,
    }),
    dashboardAssignments() {
      return this.$store.state[
        this.isSuperadmin ? "superAdminDashboard" : "dashboard"
      ].dashboardAssignments;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
