<template>
  <div class="component-wrapper pa-0 pt-4 d-flex flex-column">
    <div class="d-flex">
      <div
        class="d-flex flex-column align-center elevation-4 rounded pa-4 flex-1"
        v-for="(app, index) in apps"
        :key="app.id"
        :class="{ 'mr-8': index != apps.length - 1 }"
      >
        <img
          :src="require(`@/assets/${app.img}`)"
          class="subscription-img rounded mb-4"
        />

        <div class="text-h6 mb-2">{{ app.name }}</div>

        <template
          v-if="dayjs.utc(mobileApps[app.id].dateTo).local().isBefore(dayjs())"
        >
          <div
            class="
              error
              white--text
              text-center
              d-flex
              align-center
              justify-center
              rounded
              pa-2
              mb-2
            "
          >
            <div class="subtitle-2 mr-1">No active subscription</div>
          </div>

          <v-btn
            depressed
            color="filyellow"
            class="white--text mt-auto"
            small
            @click="openDialog(mobileApps[app.id], false)"
            >Add Subscription</v-btn
          >
        </template>

        <template v-else>
          <div
            class="
              success
              white--text
              text-center
              d-flex
              align-center
              justify-center
              rounded
              pa-2
              mb-2
            "
          >
            <div class="subtitle-2 mr-1">Active Until:</div>
            <div class="body-2">
              {{
                dayjs
                  .utc(mobileApps[app.id].dateTo)
                  .local()
                  .format("DD/MM/YYYY")
              }}
            </div>
          </div>

          <ul class="mb-2">
            <li>
              <div class="d-flex align-center">
                <div class="subtitle-2 mr-2">Total Licenses:</div>

                <div class="body-2">
                  {{ mobileApps[app.id].totalLicences }}
                </div>
              </div>
            </li>

            <li>
              <div class="d-flex align-center">
                <div class="subtitle-2 mr-2">In Use Licenses:</div>

                <div class="body-2">
                  {{ mobileApps[app.id].inUseLicences }}
                </div>
              </div>
            </li>
          </ul>

          <v-btn
            depressed
            color="filyellow"
            class="white--text mt-auto"
            small
            @click="openDialog(mobileApps[app.id], true)"
            >Edit Subscription</v-btn
          >
        </template>
      </div>
    </div>

    <v-dialog v-model="dialog.open" max-width="800px">
      <div class="white" v-if="dialog.open">
        <div class="pa-2">
          <div class="header">
            <div class="subtitle-1 text-center">
              {{ dialog.mobileApp.mobileAppName }}
            </div>

            <v-btn
              icon
              small
              class="back-btn right"
              @click="dialog.open = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>

        <form class="pa-4 d-flex flex-column" @submit.prevent="submit">
          <v-menu max-width="290" :close-on-content-click="false" ref="dateTo">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateTo ? dayjs(dateTo).format('DD/MM/YYYY') : ''"
                dense
                prepend-inner-icon="mdi-calendar"
                hide-details
                outlined
                label="Date To"
                class="mb-4 flex-grow-0"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="dateTo"
              @change="$refs.dateTo.save()"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            v-model.number="totalLicenses"
            label="Total Licenses"
            outlined
            dense
            hide-details
            class="flex-grow-0 mb-4"
            type="number"
          ></v-text-field>

          <v-textarea
            v-model="comments"
            label="Comments"
            outlined
            dense
            rows="3"
            hide-details
            no-resize
            class="flex-grow-0 mb-4"
          ></v-textarea>

          <v-btn
            type="submit"
            depressed
            color="filyellow"
            class="white--text align-self-center"
            :disabled="!dateTo || !totalLicenses"
            :loading="loader"
            >Update Subscription</v-btn
          >
        </form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      apps: [
        {
          id: "onthego",
          name: "Filistos Ascot OnTheGo",
          img: "onthego.png",
        },
        {
          id: "hub",
          name: "Filistos Ascot Hub",
          img: "hub.png",
        },
      ],
      dialog: {
        open: false,
        mobileApp: null,
        isEdit: false,
      },
      comments: null,
      dateTo: null,
      totalLicenses: null,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      mobileApps: (state) => state.clients.mobileApps,
    }),
  },
  methods: {
    ...mapActions(["updateMobileAppSubscription"]),
    openDialog(mobileApp, isEdit) {
      this.dialog = {
        open: true,
        mobileApp,
        isEdit,
      };

      if (isEdit) {
        this.comments = mobileApp.comments;
        this.dateTo = this.dayjs
          .utc(mobileApp.dateTo)
          .local()
          .toISOString()
          .substr(0, 10);
        this.totalLicenses = mobileApp.totalLicences;
      }
    },
    async submit() {
      this.loader = true;

      try {
        await this.updateMobileAppSubscription({
          clientId: this.$route.params.clientId,
          subscriptionId: this.dialog.mobileApp.subscriptionId,
          comments: this.comments,
          dateTo: this.dayjs.utc(this.dateTo).local().toISOString(),
          totalLicences: this.totalLicenses,
        });

        this.dialog.open = false;
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  watch: {
    "dialog.open"(newValue) {
      if (!newValue) {
        this.dialog.mobileApp = null;
        this.dialog.isEdit = false;

        this.comments = null;
        this.dateTo = null;
        this.totalLicenses = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-img {
  width: 150px;
}
</style>
