<template>
  <div v-if="factor">
    <v-btn depressed color="filyellow" class="white--text mb-4"
      >Add Subfactor</v-btn
    >

    <div class="subtitle-1 font-weight-medium" v-if="!factor.subFactors.length">
      No subfactors
    </div>

    <v-list dense>
      <v-list-item
        v-for="(subfactor, index) in factor.subFactors"
        :key="subfactor.factorId"
        link
        :to="`/superadmin/questionnaires/${$route.params.questionnaireTypeId}/${$route.params.questionnaireId}/factors/${$route.params.factorId}/${subfactor.factorId}`"
      >
        <v-list-item-content>
          <v-list-item-title
            >{{ index + 1 }}. {{ subfactor.factorName }}</v-list-item-title
          >
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: ["factor"],
};
</script>

<style lang="scss" scoped>
</style>
