var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.questions),function(question,index){return _c('div',{key:question.questionId},[(question.inputTypeId == 'label')?_c('div',{staticClass:"rounded pa-4 pt-2",class:{
        'grey lighten-5':
          ((_vm.parentIndex == null || _vm.parentIndex == undefined) &&
            index % 2 == 0) ||
          (_vm.parentIndex != null &&
            _vm.parentIndex != undefined &&
            _vm.parentIndex % 2 == 0),
      }},[_c('div',{staticClass:"subtitle-2",domProps:{"innerHTML":_vm._s(question.questionText)}})]):_vm._e(),(question.inputTypeId == 'tbox')?_c('div',{staticClass:"rounded pa-4 pt-2",class:{
        'grey lighten-5':
          ((_vm.parentIndex == null || _vm.parentIndex == undefined) &&
            index % 2 == 0) ||
          (_vm.parentIndex != null &&
            _vm.parentIndex != undefined &&
            _vm.parentIndex % 2 == 0),
      }},[_c('div',{staticClass:"body-2",domProps:{"innerHTML":_vm._s(question.questionText)}}),_c('div',{staticClass:"caption ml-2",domProps:{"innerHTML":_vm._s(question.questionSubText)}}),_c('v-text-field',{staticClass:"mt-1",attrs:{"dense":"","outlined":"","hide-details":"","error":_vm.v &&
          _vm.v.$each[index] &&
          _vm.v.$each[index].answerValue.$dirty &&
          _vm.v.$each[index].answerValue.$invalid,"readonly":_vm.questionnaire.isFinal},on:{"input":function($event){return _vm.onInput($event, question)}},model:{value:(question.answerValue),callback:function ($$v) {_vm.$set(question, "answerValue", $$v)},expression:"question.answerValue"}})],1):_vm._e(),(question.inputTypeId == 'drop')?_c('div',{staticClass:"rounded pa-4 pt-2",class:{
        'grey lighten-5':
          ((_vm.parentIndex == null || _vm.parentIndex == undefined) &&
            index % 2 == 0) ||
          (_vm.parentIndex != null &&
            _vm.parentIndex != undefined &&
            _vm.parentIndex % 2 == 0),
      }},[_c('div',{staticClass:"body-2",domProps:{"innerHTML":_vm._s(question.questionText)}}),_c('div',{staticClass:"caption ml-2",domProps:{"innerHTML":_vm._s(question.questionSubText)}}),_c('v-select',{staticClass:"mt-1",attrs:{"dense":"","outlined":"","hide-details":"","items":question.answerChoices,"item-text":"display","item-value":"id","error":_vm.v &&
          _vm.v.$each[index] &&
          _vm.v.$each[index].answerValue.$dirty &&
          _vm.v.$each[index].answerValue.$invalid,"readonly":_vm.questionnaire.isFinal,"clearable":!_vm.questionnaire.isFinal},on:{"change":function($event){return _vm.onInput($event, question)}},model:{value:(question.answerValue),callback:function ($$v) {_vm.$set(question, "answerValue", $$v)},expression:"question.answerValue"}})],1):_vm._e(),(question.inputTypeId == 'tarea')?_c('div',{staticClass:"rounded pa-4 pt-2",class:{
        'grey lighten-5':
          ((_vm.parentIndex == null || _vm.parentIndex == undefined) &&
            index % 2 == 0) ||
          (_vm.parentIndex != null &&
            _vm.parentIndex != undefined &&
            _vm.parentIndex % 2 == 0),
      }},[_c('div',{staticClass:"body-2",domProps:{"innerHTML":_vm._s(question.questionText)}}),_c('div',{staticClass:"caption ml-2",domProps:{"innerHTML":_vm._s(question.questionSubText)}}),_c('v-textarea',{staticClass:"mt-1",attrs:{"dense":"","outlined":"","hide-details":"","rows":"2","no-resize":"","error":_vm.v &&
          _vm.v.$each[index] &&
          _vm.v.$each[index].answerValue.$dirty &&
          _vm.v.$each[index].answerValue.$invalid,"readonly":_vm.questionnaire.isFinal},on:{"input":function($event){return _vm.onInput($event, question)}},model:{value:(question.answerValue),callback:function ($$v) {_vm.$set(question, "answerValue", $$v)},expression:"question.answerValue"}})],1):_vm._e(),(question.inputTypeId == 'calnd')?_c('div',{staticClass:"rounded pa-4 pt-2",class:{
        'grey lighten-5':
          ((_vm.parentIndex == null || _vm.parentIndex == undefined) &&
            index % 2 == 0) ||
          (_vm.parentIndex != null &&
            _vm.parentIndex != undefined &&
            _vm.parentIndex % 2 == 0),
      }},[_c('div',{staticClass:"body-2",domProps:{"innerHTML":_vm._s(question.questionText)}}),_c('div',{staticClass:"caption ml-2",domProps:{"innerHTML":_vm._s(question.questionSubText)}}),_c('v-menu',{attrs:{"max-width":"290","disabled":_vm.questionnaire.isFinal,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"flex-grow-0 mt-1",attrs:{"outlined":"","dense":"","hide-details":"","value":_vm.formattedDate(question.answerValue),"prepend-inner-icon":"mdi-calendar","clearable":!_vm.questionnaire.isFinal,"readonly":"","color":"filyellow","error":_vm.v &&
              _vm.v.$each[index] &&
              _vm.v.$each[index].answerValue.$dirty &&
              _vm.v.$each[index].answerValue.$invalid},on:{"click:clear":function($event){question.answerValue = null}}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(question.menu),callback:function ($$v) {_vm.$set(question, "menu", $$v)},expression:"question.menu"}},[_c('v-date-picker',{attrs:{"color":"filyellow","readonly":_vm.questionnaire.isFinal},on:{"change":function($event){_vm.onInput($event, question);
            question.menu = false;}},model:{value:(question.answerValue),callback:function ($$v) {_vm.$set(question, "answerValue", $$v)},expression:"question.answerValue"}})],1)],1):_vm._e(),(question.inputTypeId == 'radio')?_c('div',{staticClass:"rounded pa-4 pt-2",class:{
        'grey lighten-5':
          !question.imageUrl &&
          (((_vm.parentIndex == null || _vm.parentIndex == undefined) &&
            index % 2 == 0) ||
            (_vm.parentIndex != null &&
              _vm.parentIndex != undefined &&
              _vm.parentIndex % 2 == 0)),
      }},[(question.imageUrl)?[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"body-2 font-weight-medium"},[_vm._v(" "+_vm._s(index + 1)+" / "+_vm._s(_vm.questions.length)+" ")]),_c('img',{staticClass:"mb-4",attrs:{"src":(_vm.baseUrl + "/" + (question.imageUrl))}}),_c('v-row',_vm._l((question.answerChoices),function(answer){return _c('v-col',{key:((question.questionId) + "-" + (answer.value)),attrs:{"cols":"4"}},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.answerValue),expression:"question.answerValue"}],attrs:{"type":"radio","hidden":"","disabled":_vm.questionnaire.isFinal},domProps:{"value":answer.id,"checked":_vm._q(question.answerValue,answer.id)},on:{"change":[function($event){return _vm.$set(question, "answerValue", answer.id)},function($event){return _vm.onInput('does not matter', question)}]}}),_c('div',{staticClass:"fake-checkbox mb-2"}),_c('img',{staticClass:"answer-img",class:{ final: _vm.questionnaire.isFinal },attrs:{"src":(_vm.baseUrl + "/" + (answer.imageUrl))}})])])}),1)],1),_c('v-divider')]:[_c('v-radio-group',{staticClass:"ma-0 pa-0 questionnaires-radio-group",attrs:{"column":"","dense":"","hide-details":"","row":"","error":_vm.v &&
            _vm.v.$each[index] &&
            _vm.v.$each[index].answerValue.$dirty &&
            _vm.v.$each[index].answerValue.$invalid,"readonly":_vm.questionnaire.isFinal},on:{"change":function($event){return _vm.onInput($event, question)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"body-1",domProps:{"innerHTML":_vm._s(question.questionText)}}),_c('div',{staticClass:"body-2 ml-2",domProps:{"innerHTML":_vm._s(question.questionSubText)}})]},proxy:true}],null,true),model:{value:(question.answerValue),callback:function ($$v) {_vm.$set(question, "answerValue", $$v)},expression:"question.answerValue"}},[_c('div',{staticClass:"d-flex flex-wrap mt-2"},_vm._l((question.answerChoices),function(answer){return _c('v-radio',{key:((question.questionId) + "-" + (answer.value)),attrs:{"label":answer.display,"value":answer.id,"ripple":false,"color":"filyellow"}})}),1)])]],2):_vm._e(),(question.inputTypeId == 'chbox')?_c('div',{staticClass:"rounded pa-4 pt-2",class:{
        'grey lighten-5':
          ((_vm.parentIndex == null || _vm.parentIndex == undefined) &&
            index % 2 == 0) ||
          (_vm.parentIndex != null &&
            _vm.parentIndex != undefined &&
            _vm.parentIndex % 2 == 0),
      }},[_c('div',{staticClass:"body-2",domProps:{"innerHTML":_vm._s(question.questionText)}}),_c('div',{staticClass:"caption ml-2",domProps:{"innerHTML":_vm._s(question.questionSubText)}}),_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"","items":question.answerChoices,"item-text":"display","item-value":"id","multiple":"","chips":"","deletable-chips":"","small-chips":"","error":_vm.v &&
          _vm.v.$each[index] &&
          _vm.v.$each[index].answerValue.$dirty &&
          _vm.v.$each[index].answerValue.$invalid,"readonly":_vm.questionnaire.isFinal},on:{"input":function($event){return _vm.onInput($event, question)}},model:{value:(question.answerValue),callback:function ($$v) {_vm.$set(question, "answerValue", $$v)},expression:"question.answerValue"}})],1):_vm._e(),(question.subQuestions && question.subQuestions.length)?_c('Questions',{staticClass:"ml-8",attrs:{"questions":question.subQuestions,"v":_vm.v ? _vm.v.$each[index].subQuestions : null,"parentIndex":index,"reportQuestionnaire":_vm.reportQuestionnaire},on:{"inputChanged":function($event){return _vm.$emit('inputChanged', $event)}}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }