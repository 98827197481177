var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper rounded flex-grow-1 d-flex flex-column"},[_c('div',{staticClass:"section-header d-flex align-center pa-1 rounded-tl rounded-tr"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-message-text")]),_c('div',{staticClass:"body-2"},[_vm._v("Messages")])],1),_c('div',{staticClass:"pb-4 d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"pa-2"},[_c('ShowPerPage',{attrs:{"currentPerPage":_vm.messagesParams.itemsPerPage,"options":_vm.showPerPageOptions}})],1),_c('div',{staticClass:"scrollable pa-4 mb-2"},[_c('v-data-table',{staticClass:"clickable",attrs:{"headers":_vm.headers,"items":_vm.messages.results,"server-items-length":_vm.messages.rowCount,"hide-default-footer":"","options":_vm.options,"loading":_vm.loader,"item-class":_vm.isUnread,"id":"messages-table"},on:{"click:row":function($event){_vm.dialog = { open: true, component: 'Message', message: $event }},"update:options":[function($event){_vm.options=$event},function($event){return _vm.onGetMessages(true)}]},scopedSlots:_vm._u([{key:"item.delete",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-menu',{ref:("deleteMenu" + index),attrs:{"top":"","left":"","close-on-content-click":false,"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error","disabled":!item.dateOpened}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('div',{staticClass:"white pa-5"},[_c('div',{staticClass:"body-2 mb-4 error--text"},[_vm._v(" Are you sure you want to delete this message? ")]),_c('v-btn',{attrs:{"small":"","depressed":"","color":"error","loading":_vm.deleteLoader == item.messageId},on:{"click":function($event){return _vm.onDeleteMessage(item, index)}}},[_vm._v("Delete")])],1)])]}},{key:"item.dateSent",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-2",class:{ 'font-weight-bold': !item.dateOpened }},[_vm._v(" "+_vm._s(_vm.dayjs.utc(item.dateSent).local().format("DD/MM/YYYY HH:mm"))+" ")])]}},{key:"item.messageTitle",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-2 trimmed",class:{ 'font-weight-bold': !item.dateOpened }},[_vm._v(" "+_vm._s(item.messageTitle)+" ")])]}}],null,true)})],1),(_vm.messages.pageCount)?_c('v-pagination',{attrs:{"length":_vm.messages.pageCount},model:{value:(_vm.messagesParams.page),callback:function ($$v) {_vm.$set(_vm.messagesParams, "page", $$v)},expression:"messagesParams.page"}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"800px","persistent":"","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open)?_c('div',{staticClass:"white"},[_c('Message',{attrs:{"message":_vm.dialog.message},on:{"closeDialog":function($event){_vm.dialog = { open: false, component: null, message: null }}}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }