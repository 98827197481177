<template>
  <div class="white d-flex flex-column">
    <div class="primary pa-4 d-flex align-start">
      <div class="text-h6 white--text">
        Add new Assignment
      </div>

      <v-btn
        icon
        small
        color="white"
        class="ml-auto"
        @click="$emit('closeDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="d-flex pa-8">
      <v-expansion-panels multiple v-model="tab" class="mr-6">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="d-flex align-center">
              <div class="subtitle-2">
                Basic Information
              </div>
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="pt-4">
            <v-row no-gutters>
              <v-col cols="12" md="12" sm="6">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  label="Selected User"
                  class="mb-4"
                  :value="selectedUser.fullName"
                  readonly
                >
                </v-text-field>

                <v-text-field
                  outlined
                  hide-details
                  dense
                  label="Title"
                  class="mb-4"
                  v-model="title"
                  :error-messages="titleErrors"
                ></v-text-field>

                <v-menu
                  v-model="dateToSendMenu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="dateToSendFormatted"
                      dense
                      prepend-inner-icon="mdi-calendar"
                      hide-details
                      outlined
                      label="Date to Send"
                      class="mb-4"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="dateToSend"
                    @change="onDateToSendChanged"
                    :min="new Date().toISOString().substr(0, 10)"
                  ></v-date-picker>
                </v-menu>

                <v-menu
                  v-model="dateToRemindMenu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="dateToRemindFormatted"
                      dense
                      prepend-inner-icon="mdi-calendar"
                      hide-details
                      outlined
                      label="Date to Remind"
                      class="mb-4"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="dateToRemind"
                    @change="dateToRemindMenu = false"
                    :min="
                      dateToSend
                        ? dayjs
                            .utc(dateToSend)
                            .local()
                            .toISOString()
                            .substr(0, 10)
                        : null
                    "
                  ></v-date-picker>
                </v-menu>

                <v-menu
                  v-model="dateToEndMenu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="dateToEndFormatted"
                      dense
                      prepend-inner-icon="mdi-calendar"
                      hide-details
                      outlined
                      label="Date End"
                      class="mb-4"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="dateToEnd"
                    @change="dateToEndMenu = false"
                    :min="
                      dateToSend
                        ? dayjs
                            .utc(dateToSend)
                            .local()
                            .toISOString()
                            .substr(0, 10)
                        : null
                    "
                  ></v-date-picker>
                </v-menu>

                <v-select
                  outlined
                  hide-details
                  dense
                  label="Type"
                  class="mb-4"
                  :items="assignmentSendTypes"
                  item-text="itemValue"
                  v-model="sendTypeId"
                  return-object
                  :error-messages="sendTypeIdErrors"
                >
                </v-select>

                <v-select
                  v-model="usersToNotifyIds"
                  outlined
                  dense
                  label="Email Confirmation to"
                  :value="usersToNotifyIds"
                  :loading="usersToNotifyLoader"
                  :items="possitbleUsersToNotify"
                  item-text="userEmail"
                  return-object
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  hide-details=""
                  :error-messages="usersToNotifyIdsErrors"
                >
                  <template v-slot:item="{ item, attrs }">
                    <div class="d-flex align-center">
                      <v-checkbox
                        :input-value="attrs.inputValue"
                        readonly
                        dense
                        class="pa-0 ma-0 mr-2"
                        hide-details
                      >
                      </v-checkbox>

                      <div class="d-flex flex-column py-2">
                        <div class="subtitle-2">
                          {{ item.firstName }} {{ item.lastName }}
                        </div>
                        <div class="caption">{{ item.userEmail }}</div>
                      </div>
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels multiple v-model="secondTab">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="subtitle-2">
              E-Learnings
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="pt-4">
            <v-data-table
              :items="eLearnings"
              :headers="headers"
              hide-default-footer
            >
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-btn
      class="align-self-end mx-8 my-4"
      color="success"
      @click="onAddAssignment"
    >
      Add Assignment
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  props: {
    selectedUser: Object,
    eLearnings: Array,
  },

  async created() {
    this.title = `Assignment from Filistos Training Needs - ${this.dayjs().format(
      "DD/MM/YYYY"
    )}`;
    this.dateToSend = this.dayjs()
      .toISOString()
      .substr(0, 10);
    this.dateToRemind = this.dayjs()
      .add(3, "days")
      .toISOString()
      .substr(0, 10);
    this.dateToEnd = this.dayjs()
      .add(6, "months")
      .toISOString()
      .substr(0, 10);

    this.usersToNotifyLoader = true;

    try {
      await this.getPossibleUsersToNotify();
    } catch (e) {
      console.log(e);
    }

    this.usersToNotifyLoader = false;
  },

  data() {
    return {
      title: null,
      tab: [0],
      secondTab: [0],
      sendTypeId: null,
      usersToNotifyIds: [],
      dateToSendMenu: false,
      dateToRemindMenu: false,
      dateToEndMenu: false,
      dateToSend: null,
      dateToRemind: null,
      dateToEnd: null,
      usersToNotifyLoader: false,
      headers: [{ text: "Title", value: "recommendedElearningName" }],
    };
  },

  validations: {
    title: { required },
    sendTypeId: { required },
    usersToNotifyIds: { required },
  },

  computed: {
    ...mapState({
      assignmentSendTypes: (state) => state.base.lists.assignmentSendTypes,
      currentAssignment: (state) => state.assignments.currentAssignment,
      info: (state) => state.base.info,
      possitbleUsersToNotify: (state) =>
        state.assignments.possitbleUsersToNotify,
    }),
    titleErrors() {
      if (!this.$v.title.$dirty) return "";

      if (!this.$v.title.$invalid) return "";

      return "Required field";
    },

    sendTypeIdErrors() {
      if (!this.$v.sendTypeId.$dirty) return "";

      if (!this.$v.sendTypeId.$invalid) return "";

      return "Required field";
    },

    usersToNotifyIdsErrors() {
      if (!this.$v.usersToNotifyIds.$dirty) return "";

      if (!this.$v.usersToNotifyIds.$invalid) return "";

      return "Required field";
    },

    dateToSendFormatted() {
      return this.dateToSend
        ? this.dayjs(this.dateToSend).format("DD/MM/YYYY")
        : "";
    },
    dateToRemindFormatted() {
      return this.dateToRemind
        ? this.dayjs(this.dateToRemind).format("DD/MM/YYYY")
        : "";
    },
    dateToEndFormatted() {
      return this.dateToEnd
        ? this.dayjs(this.dateToEnd).format("DD/MM/YYYY")
        : "";
    },
  },

  methods: {
    ...mapActions(["getPossibleUsersToNotify"]),

    onDateToSendChanged() {
      this.dateToSendMenu = false;

      const dateToSend = this.dayjs(this.dateToSend);
      const dateToRemind = this.dayjs(this.dateToRemind);
      const dateToEnd = this.dayjs(this.dateToEnd);

      if (dateToSend.diff(dateToRemind, "days") >= 0) this.dateToRemind = null;

      if (dateToSend.diff(dateToEnd, "days") >= 0) this.dateToEnd = null;
    },

    async onAddAssignment() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/ClientAdmin/AddAssignment`,
          {
            assignmentName: this.title,
            sendTypeId: this.sendTypeId.itemKey,
            dateToSend: this.dayjs.utc(this.dateToSend).toISOString(),
            dateToRemind: this.dayjs.utc(this.dateToRemind).toISOString(),
            dateToEnd: this.dayjs.utc(this.dateToEnd).toISOString(),
            questionnaireIds: this.eLearnings.map(
              (el) => el.recommendedElearningId
            ),
            assigneeUserIds: [this.selectedUser.clientUserId],
            usersToNotifyIds: this.usersToNotifyIds.map(
              (user) => user.clientUserId
            ),
            hasAssessor: false,
          },
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/vnd.ms-excel",
            },
          }
        );

        if (this.sendTypeId.itemKey == "manager") {
          const filename = res.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0];

          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          var fileURL = window.URL.createObjectURL(blob);
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        }

        this.$store.commit("toggleSnackbar", {
          open: true,
          color: "success",
          text: "Assignment has been sent!",
          timeout: 5000,
        });
        this.$emit("closeDialog");
      } catch (e) {
        this.$store.commit("toggleSnackbar", {
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });

        throw e;
      }
    },
  },
};
</script>

<style></style>
