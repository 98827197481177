<template>
  <div class="white">
    <div class="primary pa-3 d-flex align-start">
      <div class="text-h6 white--text">
        {{ isEdit ? "Edit Factor Group" : "Add Factor Group" }}
      </div>
      <v-btn
        icon
        small
        color="white"
        class="ml-auto"
        @click="$emit('closeDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="white d-flex flex-column pa-5" style="height: 500px">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="Title"
            dense
            outlined
            class="mb-4"
            v-model="name"
            :error-messages="nameErrors"
          >
          </v-text-field>
          <v-select
            v-model="factors"
            dense
            outlined
            clearable
            class="mb-2"
            :items="factorsArray"
            item-text="factorName"
            item-value="factorId"
            multiple
            label="Factors"
            chips
            :error-messages="factorsErrors"
            deletable-chips
            return-object
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters align="center">
                      <span
                        :class="
                          item.subFactorsCount > 0 ? 'font-weight-bold' : 'ml-6'
                        "
                        >{{ item.factorName }}</span
                      >
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>

          <v-select
            v-model="clientId"
            dense
            outlined
            clearable
            class="mb-2"
            :items="allClients"
            item-text="companyName"
            item-value="clientId"
            label="Client"
            :disabled="isEdit"
          ></v-select>
        </v-col>
      </v-row>

      <div class="wrapper rounded d-flex justify-center mb-5">
        <v-btn
          :loading="loader"
          color="warning"
          depressed
          @click="onSubmit"
          :disabled="isEdit && !hasChanges"
        >
          {{ isEdit ? "Save" : "Add Factor Group" }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import { mapMutations, mapState } from "vuex";
export default {
  props: ["isEdit", "factorGroup", "factorsArray"],

  created() {
    if (this.isEdit) {
      const propFactors = this.factorGroup.factors.map((el) => {
        return {
          factorName: el?.factorName,
          factorId: el?.factorId,
        };
      });

      this.name = this.factorGroup?.factorGroupName;
      this.factors = propFactors;
      this.clientId = this.factorGroup?.clientId;

      this.initialData = {
        name: this.factorGroup?.factorGroupName,
        factors: propFactors,
        clientId: this.factorGroup?.clientId,
      };
    }
  },

  data() {
    return {
      name: null,
      factors: [],
      clientId: null,
      loader: false,
      initialData: {
        name: null,
        factors: [],
        clientId: null,
      },
    };
  },
  validations: {
    name: {
      required,
    },

    factors: {
      required,
    },
  },

  computed: {
    ...mapState({
      ...mapState({
        currentQuestionnaire: (state) =>
          state.questionnaires.currentQuestionnaire,
        allClients: (state) => state.base.allClients,
      }),
    }),

    hasChanges() {
      return (
        JSON.stringify(this.initialData) !=
        JSON.stringify({
          name: this.name,
          factors: this.factors,
          clientId: this.clientId,
        })
      );
    },

    nameErrors() {
      if (!this.$v.name.$dirty) return "";

      if (!this.$v.name.$invalid) return "";

      return "Required field";
    },

    factorsErrors() {
      if (!this.$v.factors.$dirty) return "";

      if (!this.$v.factors.$invalid) return "";

      return "Required field";
    },
  },

  methods: {
    ...mapMutations(["toggleSnackbar"]),

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loader = true;
      let factors = this.factors.map((el) => el.factorId);
      try {
        if (this.isEdit) {
          const payload = {
            factorGroupName: this.name,
            factorIds: factors,
            factorGroupId: this.factorGroup?.factorGroupId,
            clientId: this.factorGroup?.clientId,
          };
          await axios.post(
            `${process.env.VUE_APP_BASE_URL}/superadmin/UpdateFactorGroup`,
            payload
          );
        } else {
          const payload = {
            factorGroupName: this.name,
            questionnaireId: this.currentQuestionnaire.questionnaireId,
            factorIds: factors,
          };
          if (this.clientId) payload.clientId = this.clientId;
          await axios.post(
            `${process.env.VUE_APP_BASE_URL}/superadmin/AddFactorGroup`,
            payload
          );
        }

        this.toggleSnackbar({
          open: true,
          color: "success",
          text: `Factor group has been ${this.isEdit ? "updated" : "created"}!`,
          timeout: 5000,
        });

        this.$emit("getFactorGroups");
        this.$emit("closeDialog");
      } catch (e) {
        this.toggleSnackbar({
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });
        console.log(e);
      }

      this.loader = false;
    },
  },
};
</script>

<style scoped></style>
