<template>
  <div class="component-wrapper d-flex flex-column pb-0">
    <div class="text-h6 text-center mb-4">New Purchase</div>

    <form @submit.prevent="submit" class="flex-grow-1 d-flex flex-column">
      <div class="scrollable">
        <div
          v-for="(type, index) in questionnaireTypes"
          :key="type.questionnaireTypeId"
          :class="{ 'mb-4': index != questionnaireTypes.length - 1 }"
        >
          <div class="subtitle-2 grey lighten-4 rounded pa-2">
            {{ type.questionnaireTypeName }} ({{ type.questionnaires.length }})
          </div>

          <v-list dense class="new-purchase">
            <v-list-item
              v-for="questionnaire in type.questionnaires"
              :key="questionnaire.questionnaireId"
            >
              <v-list-item-content>
                <div
                  class="d-flex align-center"
                  :class="{
                    'primary lighten-3':
                      groupHeads[questionnaire.questionnaireId] &&
                      groupHeads[questionnaire.questionnaireId].children.length,
                  }"
                >
                  <v-checkbox
                    v-model="selectedQuestionnaires"
                    class="pa-0 ma-0 mr-4"
                    dense
                    hide-details
                    :ripple="false"
                    :value="questionnaire.questionnaireId"
                    readonly
                    @click="onCheckboxClick(questionnaire)"
                  ></v-checkbox>

                  <v-list-item-title class="flex-grow-0 mr-5 pl-1">{{
                    questionnaire.questionnaireName
                  }}</v-list-item-title>

                  <v-btn
                    v-if="questionnaire.isGroupHead"
                    small
                    icon
                    @click="
                      openQuestionnaireGroup == questionnaire.questionnaireId
                        ? (openQuestionnaireGroup = null)
                        : (openQuestionnaireGroup =
                            questionnaire.questionnaireId)
                    "
                    class="mr-2"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>

                  <div
                    v-if="transactionsReduced[questionnaire.questionnaireId]"
                    class="d-flex"
                  >
                    <v-chip small class="mr-1" color="primary">
                      <div class="subtitle-2 mr-1">Quantity:</div>
                      <div class="subtitle-2">
                        {{
                          transactionsReduced[questionnaire.questionnaireId]
                            .isUnlimited
                            ? "Unlimited"
                            : transactionsReduced[questionnaire.questionnaireId]
                                .quantity
                        }}
                      </div>
                    </v-chip>

                    <v-chip
                      small
                      color="primary"
                      class="mr-1"
                      v-if="
                        transactionsReduced[questionnaire.questionnaireId]
                          .isUnlimited
                      "
                    >
                      <div class="subtitle-2 mr-1">Until:</div>
                      <div class="subtitle-2">
                        {{
                          dayjs(
                            transactionsReduced[questionnaire.questionnaireId]
                              .expiryDate
                          ).format("DD/MM/YYYY")
                        }}
                      </div>
                    </v-chip>

                    <v-btn
                      icon
                      small
                      color="filyellow"
                      @click="editItem(questionnaire)"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                </div>

                <v-list
                  dense
                  class="new-purchase"
                  v-if="openQuestionnaireGroup == questionnaire.questionnaireId"
                >
                  <v-list-item
                    v-for="subquestionnaire in questionnaire.subQuestionnaires"
                    :key="subquestionnaire.questionnaireId"
                  >
                    <v-list-item-content>
                      <div class="d-flex align-center">
                        <v-checkbox
                          v-if="!subquestionnaire.isGroupHead"
                          v-model="selectedQuestionnaires"
                          class="pa-0 ma-0 mr-4"
                          dense
                          hide-details
                          :ripple="false"
                          :value="subquestionnaire.questionnaireId"
                          readonly
                          @click="onCheckboxClick(subquestionnaire)"
                          :disabled="
                            selectedQuestionnaires.includes(
                              questionnaire.questionnaireId
                            )
                          "
                        ></v-checkbox>

                        <v-list-item-title class="flex-grow-0 mr-5">{{
                          subquestionnaire.questionnaireName
                        }}</v-list-item-title>

                        <v-btn
                          v-if="subquestionnaire.isGroupHead"
                          small
                          icon
                          @click="
                            openQuestionnaireGroup ==
                            subquestionnaire.questionnaireId
                              ? (openQuestionnaireGroup = null)
                              : (openQuestionnaireGroup =
                                  subquestionnaire.questionnaireId)
                          "
                        >
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>

                        <div
                          v-if="
                            transactionsReduced[
                              subquestionnaire.questionnaireId
                            ] &&
                              !selectedQuestionnaires.includes(
                                questionnaire.questionnaireId
                              )
                          "
                          class="d-flex"
                        >
                          <v-chip small class="mr-1" color="primary">
                            <div class="subtitle-2 mr-1">Quantity:</div>
                            <div class="subtitle-2">
                              {{
                                transactionsReduced[
                                  subquestionnaire.questionnaireId
                                ].isUnlimited
                                  ? "Unlimited"
                                  : transactionsReduced[
                                      subquestionnaire.questionnaireId
                                    ].quantity
                              }}
                            </div>
                          </v-chip>

                          <v-chip
                            small
                            color="primary"
                            class="mr-1"
                            v-if="
                              transactionsReduced[
                                subquestionnaire.questionnaireId
                              ].isUnlimited
                            "
                          >
                            <div class="subtitle-2 mr-1">Until:</div>
                            <div class="subtitle-2">
                              {{
                                dayjs(
                                  transactionsReduced[
                                    subquestionnaire.questionnaireId
                                  ].expiryDate
                                ).format("DD/MM/YYYY")
                              }}
                            </div>
                          </v-chip>

                          <v-btn
                            icon
                            small
                            color="filyellow"
                            @click="editItem(subquestionnaire)"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </div>

      <v-btn
        class="align-self-center white--text"
        color="filyellow"
        depressed
        type="submit"
        :disabled="!this.transactions.length"
        :loading="loader"
        >New Purchase</v-btn
      >
    </form>

    <v-dialog v-model="dialog.open" max-width="450px">
      <div class="white d-flex flex-column" v-if="dialog.open">
        <div class="pa-2">
          <div class="header">
            <v-btn
              icon
              small
              class="right back-btn"
              @click="
                dialog = {
                  open: false,
                  questionnnaire: null,
                  groupHeadId: null,
                }
              "
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>

            <div class="subtitle-1 font-weight-medium text-center">
              {{ openQuestionnaire.questionnaireName }}
            </div>
          </div>
        </div>

        <div class="pa-4 d-flex flex-column">
          <v-text-field
            outlined
            dense
            hide-details
            v-model.number="quantity"
            label="Quantity"
            type="number"
            class="mb-4"
            v-if="!isUnlimited"
          ></v-text-field>

          <v-menu
            max-width="290"
            :close-on-content-click="false"
            ref="expiryDate"
            v-if="isUnlimited"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="
                  expiryDate ? dayjs(expiryDate).format('DD/MM/YYYY') : ''
                "
                dense
                prepend-inner-icon="mdi-calendar"
                hide-details
                outlined
                label="Expiry Date"
                class="mb-4 flex-grow-0"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="expiryDate"
              @change="$refs.expiryDate.save()"
            ></v-date-picker>
          </v-menu>

          <v-checkbox
            v-model="isUnlimited"
            class="pa-0 ma-0 mb-4"
            dense
            hide-details
            :ripple="false"
            label="Unlimited"
          ></v-checkbox>

          <v-btn
            @click="saveDialog"
            color="filyellow"
            depressed
            class="align-self-center white--text"
            :disabled="
              (isUnlimited && !expiryDate) || (!isUnlimited && !quantity)
            "
            >Save</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      dialog: {
        open: false,
        questionnnaire: null,
        groupHeadId: null,
      },
      selectedQuestionnaires: [],
      quantity: null,
      isUnlimited: false,
      expiryDate: null,
      transactions: [],
      openQuestionnaire: null,
      openQuestionnaireGroup: null,
      groupHeads: {},
      loader: false,
    };
  },
  computed: {
    ...mapState({
      questionnaireTypes: (state) => state.base.questionnaires,
    }),
    transactionsReduced() {
      return this.transactions.reduce(
        (a, v) => ({ ...a, [v.questionnaireId]: v }),
        {}
      );
    },
  },
  methods: {
    ...mapActions(["newTransaction"]),
    onCheckboxClick(questionnaire) {
      if (this.selectedQuestionnaires.includes(questionnaire.questionnaireId)) {
        // if questionnaire is selected

        this.selectedQuestionnaires = this.selectedQuestionnaires.filter(
          (q) => q != questionnaire.questionnaireId
        ); //remove from selected questionnaires

        this.transactions = this.transactions.filter(
          (t) => t.questionnaireId != questionnaire.questionnaireId
        ); //remove transaction with this questionnaire

        if (this.groupHeads[questionnaire.groupHeadId]) {
          this.groupHeads[questionnaire.groupHeadId].children = this.groupHeads[
            questionnaire.groupHeadId
          ].children.filter((child) => child != questionnaire.questionnaireId);
        } //remove highlighting of parent

        if (questionnaire.isGroupHead) {
          // if questionnaire is groupHead

          const subQuestionnairesIds = questionnaire.subQuestionnaires.map(
            (q) => q.questionnaireId
          ); //map all subquestionnaire ids

          this.selectedQuestionnaires = this.selectedQuestionnaires.filter(
            (q) => !subQuestionnairesIds.includes(q)
          ); //remove from selected questionnaires

          this.transactions = this.transactions.filter(
            (t) => !subQuestionnairesIds.includes(t.questionnaireId)
          ); //remove transaction with this questionnaire
        }
      } else {
        this.openQuestionnaire = questionnaire;

        this.dialog = {
          open: true,
          questionnaire: questionnaire,
          groupHeadId: questionnaire.groupHeadId,
        };
      }
    },
    editItem(questionnaire) {
      this.openQuestionnaire = questionnaire;

      this.quantity = this.transactionsReduced[
        questionnaire.questionnaireId
      ].quantity;
      this.isUnlimited = this.transactionsReduced[
        questionnaire.questionnaireId
      ].isUnlimited;
      this.expiryDate = this.transactionsReduced[
        questionnaire.questionnaireId
      ].expiryDate;

      this.dialog = {
        open: true,
        questionnaire,
        groupHeadId: questionnaire.groupHeadId,
      };
    },
    saveDialog() {
      this.transactions.push({
        questionnaireId: this.dialog.questionnaire.questionnaireId,
        quantity: this.isUnlimited ? null : this.quantity,
        expiryDate: !this.isUnlimited ? null : this.expiryDate,
        isUnlimited: this.isUnlimited,
      }); // save transaction with this questionnaire

      if (this.dialog.groupHeadId) {
        const found = this.groupHeads[this.dialog.groupHeadId];

        if (!found) {
          this.groupHeads[this.dialog.groupHeadId] = {
            id: this.dialog.groupHeadId,
            children: [this.dialog.questionnaire.questionnaireId],
          };
        } else {
          this.groupHeads[this.dialog.groupHeadId].children.push(
            this.dialog.questionnaire.questionnaireId
          );
        }
      }

      if (this.dialog.questionnaire.isGroupHead) {
        // if selected questionnaire is group head
        const subQuestionnairesIds = this.dialog.questionnaire.subQuestionnaires.map(
          (q) => q.questionnaireId
        ); //map all subquestionnaire ids

        this.transactions = this.transactions.filter(
          (t) => !subQuestionnairesIds.includes(t.questionnaireId)
        ); //remove transactions with subquestionnaires

        delete this.groupHeads[this.dialog.questionnaire.questionnaireId]; //remove groupHeads object (for highlighting)
      }

      if (
        !this.selectedQuestionnaires.includes(
          this.dialog.questionnaire.questionnaireId
        )
      ) {
        this.selectedQuestionnaires.push(
          this.dialog.questionnaire.questionnaireId
        );
      } // if questionnaire is not selected then add it to selectedQuestionnaires

      if (this.dialog.questionnaire.isGroupHead) {
        // if questionnaire is groupHead

        this.dialog.questionnaire.subQuestionnaires.forEach((q) => {
          // for each subquestionnaire

          this.transactions.push({
            questionnaireId: q.questionnaireId,
            quantity: this.isUnlimited ? null : this.quantity,
            expiryDate: !this.isUnlimited ? null : this.expiryDate,
            isUnlimited: this.isUnlimited,
          }); // add subquestionnaire to transactions with the same quantity as the parent

          if (!this.selectedQuestionnaires.includes(q.questionnaireId)) {
            this.selectedQuestionnaires.push(q.questionnaireId);
          }
          // add the subquestionnaire to selectedQuestionnaires
        });
      }

      this.dialog.open = false;
    },
    async submit() {
      this.loader = true;

      try {
        await this.newTransaction({
          clientId: this.$route.params.clientId,
          transactions: this.transactions,
        });

        this.$router.push({
          path: `/superadmin/clients/${this.$route.params.clientId}/transactions`,
        });
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  watch: {
    "dialog.open"(newValue) {
      if (!newValue) {
        this.quantity = null;
        this.isUnlimited = false;
        this.expiryDate = null;
        this.openQuestionnaire = null;
      }
    },
  },
};
</script>

<style lang="scss">
.new-purchase {
  .v-list-item__title {
    flex-basis: auto;
  }
}
</style>
