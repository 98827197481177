<template>
  <div class="flex-grow-1 d-flex flex-column">
    <div class="scrollable px-4 pb-4">
      <div class="d-flex pt-1">
        <v-text-field
          label="Search"
          hint="Search by assignment title"
          persistent-hint
          outlined
          class="flex-grow-0"
          dense
          prepend-inner-icon="mdi-magnify"
          clearable
          v-model="search"
          @input="page = 1"
        ></v-text-field>

        <ShowPerPage
          class="ml-auto"
          labelPerPage="Assignments"
          :currentPerPage="pageSize"
          :options="showPerPageOptions"
        />
      </div>

      <v-expansion-panels
        v-model="tab"
        v-if="selectedUser.assignments && selectedUser.assignments.length"
      >
        <v-expansion-panel
          v-for="(assignment, index) in paginatedAssignments"
          :key="assignment.assignmentId"
        >
          <v-expansion-panel-header
            :class="{ 'grey lighten-3': tab === index }"
          >
            <div class="d-flex align-center">
              <div class="subtitle-2">
                {{ assignment.assignmentName }}
              </div>

              <div class="text-h6 mx-4">|</div>

              <div class="body-2 mr-2">Email Status:</div>

              <div
                class="caption px-1 rounded"
                :class="{
                  'success white--text':
                    assignment.lastMailStatus == 'Sent' ||
                    assignment.lastMailStatus == 'Delivery' ||
                    assignment.lastMailStatus == 'Open' ||
                    assignment.lastMailStatus == 'Click',
                  'grey lighten-1 ': !assignment.lastMailStatus,
                  'error white--text': assignment.lastMailStatus == 'Bounce',
                }"
              >
                {{
                  assignment.lastMailStatus
                    ? assignment.lastMailStatus
                    : "Unknown"
                }}
              </div>
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="pt-4">
            <v-progress-circular
              size="30"
              width="3"
              indeterminate
              color="primary"
              v-if="getAssignmentOfUserLoader"
            ></v-progress-circular>

            <AssignmentDetails
              :user="selectedUser"
              v-if="!getAssignmentOfUserLoader && assignmentOfUser"
              :panel="tab"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div
        class="text-h6 text-center"
        v-if="selectedUser.assignments && !selectedUser.assignments.length"
      >
        There are no available asssignments for this user.
      </div>
    </div>

    <v-pagination
      v-model="page"
      :length="pages"
      @input="tab = null"
      v-if="pages > 0"
    ></v-pagination>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AssignmentDetails from "@/views/admin/assignment/assignment-dialog/AssignmentDetails";
import ShowPerPage from "@/components/ShowPerPage";

export default {
  props: ["selectedUser"],
  components: {
    AssignmentDetails,
    ShowPerPage,
  },
  data() {
    return {
      tab: null,
      pageSize: 10,
      page: 1,
      search: null,
      showPerPageOptions: [],
    };
  },
  computed: {
    ...mapState({
      assignmentOfUser: (state) => state.assignments.assignmentOfUser,
      getAssignmentOfUserLoader: (state) =>
        state.assignments.getAssignmentOfUserLoader,
      showReport: (state) => state.assignments.showReport,
    }),
    searchAssignments() {
      return this.search
        ? this.selectedUser.assignments.filter((assignment) =>
            assignment.assignmentName
              .toLowerCase()
              .includes(this.search.toLowerCase())
          )
        : null;
    },
    totalAssignments() {
      return this.search
        ? this.searchAssignments
        : this.selectedUser.assignments;
    },
    paginatedAssignments() {
      return this.totalAssignments.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      );
    },
    pages() {
      return Math.ceil(this.selectedUser.assignments.length / this.pageSize);
    },
  },
  created() {
    if (this.showReport.id) {
      this.tab = this.showReport.index;

      this.toggleShowReport({
        id: null,
        index: null,
      });
    }

    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setPerPage,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setPerPage,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setPerPage,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setPerPage,
      },
    ];
  },
  methods: {
    ...mapMutations(["toggleShowReport"]),
    ...mapActions(["getAssignmentOfUser"]),
    setPerPage(options) {
      this.page = options.page;
      this.pageSize = options.itemsPerPage == -1 ? 1000 : options.itemsPerPage;
    },
  },
  watch: {
    async tab(newValue) {
      if (newValue !== null && newValue !== undefined) {
        const index = (this.page - 1) * this.pageSize + newValue;

        if (
          newValue !== null &&
          newValue !== undefined &&
          this.selectedUser.assignments[index].assignmentId !=
            this.assignmentOfUser?.assignmentId
        ) {
          await this.getAssignmentOfUser({
            userId: this.selectedUser.clientUserId,
            assignmentId: this.selectedUser.assignments[index].assignmentId,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
