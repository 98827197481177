var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper rounded flex-grow-1 d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"mr-2 subtitle-2"},[_vm._v("Selected Users:")]),_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.form.sendToClientUserIds.length))])]),_c('div',{staticClass:"d-flex align-start mb-2"},[_c('div',{staticClass:"mr-5 d-flex align-start mr-auto"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"outlined":"","dense":"","label":"Search","prepend-inner-icon":"mdi-magnify","hint":"Search by Identity, Last Name, First Name, Email","persistent-hint":"","value":_vm.usersParams.search,"readonly":_vm.getUsersLoader},on:{"input":function($event){return _vm.setUserParams({ search: $event })}}}),(_vm.usersParams.search)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.setUserParams({ search: '' })}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('FilterBy',{attrs:{"fields":_vm.filterFields,"loader":_vm.getUsersLoader},on:{"filterChange":_vm.onFilterChange}}),_c('ShowPerPage',{staticClass:"mx-2",attrs:{"currentPerPage":_vm.usersParams.itemsPerPage,"options":_vm.showPerPageOptions}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","depressed":"","loading":_vm.actionsLoader}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.onSelectAll}},[_c('v-list-item-title',[_vm._v("Select all "+_vm._s(_vm.hasFilter || _vm.usersParams.search ? "(Filtered)" : ""))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.onUnselectAll}},[_c('v-list-item-title',[_vm._v("Unselect all "+_vm._s(_vm.hasFilter || _vm.usersParams.search ? "(Filtered)" : ""))])],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":!_vm.getUsersLoader ? _vm.users.results : [],"server-items-length":_vm.users.rowCount,"loading":_vm.getUsersLoader,"options":_vm.options,"hide-default-footer":"","show-select":"","mobile-breakpoint":"0"},on:{"update:options":_vm.onGetUsers},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"dense":"","hide-details":"","ripple":false,"value":{
          clientUserId: item.clientUserId,
          identityNumber: item.identityNumber,
          firstName: item.firstName,
          lastName: item.lastName,
          userEmail: item.userEmail,
        }},model:{value:(_vm.form.sendToClientUserIds),callback:function ($$v) {_vm.$set(_vm.form, "sendToClientUserIds", $$v)},expression:"form.sendToClientUserIds"}})]}},{key:"item.userEmail",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.userEmail ? item.userEmail : "-")+" ")]}},{key:"item.languageName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.languageName ? item.languageName : "-")+" ")]}}],null,true)},[_c('template',{slot:"header.data-table-select"},[_c('div',{staticStyle:{"cursor":"none"}})])],2),(_vm.usersParams.itemsPerPage != -1)?_c('div',{staticClass:"pagination-wrapper py-3 mt-auto"},[(_vm.users.pageCount)?_c('v-pagination',{attrs:{"length":_vm.users.pageCount,"disabled":_vm.getUsersLoader},model:{value:(_vm.usersParams.page),callback:function ($$v) {_vm.$set(_vm.usersParams, "page", $$v)},expression:"usersParams.page"}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }