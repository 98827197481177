<template>
  <div class="white dialog-wrapper scrollable-dialog small">
    <div class="primary pa-3 d-flex align-start">
      <div class="text-h6 white--text">Group Assign</div>

      <v-btn
        icon
        small
        color="white"
        class="ml-auto"
        @click="$emit('closeDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <div class="d-flex flex-column flex-sm-row pa-5 flex-grow-1">
      <div class="column d-flex flex-column mb-5 mb-sm-0 mr-sm-5">
        <div class="subtitle-1 font-weight-medium mb-5">Selected Users</div>

        <v-simple-table class="scrollable">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"></th>

                <th class="text-left">Name</th>

                <th class="text-left">Surname</th>

                <th class="text-left">Email</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(user, index) in localSelectedUsers"
                :key="user.name"
                :class="{ 'error white--text': user.notAdded }"
              >
                <td>
                  <v-btn
                    icon
                    small
                    :color="user.notAdded ? 'white' : 'error'"
                    @click="unselectUser(index)"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </td>

                <td>{{ user.firstName }} {{ user.notAdded }}</td>

                <td>
                  {{ user.lastName }}
                </td>

                <td>
                  {{ user.userEmail ? user.userEmail : "-" }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="body-2 error--text mt-5" v-if="hasError">
          Some users already exist in this group
        </div>

        <div
          class="body-2 error--text mt-5"
          v-if="$v.$dirty && !$v.localSelectedUsers.required"
        >
          There are no users selected
        </div>
      </div>

      <div class="column d-flex flex-column">
        <div class="subtitle-1 font-weight-medium mb-5">Available Groups</div>

        <v-select
          dense
          outlined
          label="Available Groups"
          :items="allGroups"
          v-model="group"
          item-value="groupId"
          item-text="groupName"
          class="mb-1 flex-grow-0"
          :error-messages="
            $v.$dirty && !$v.group.required ? 'This field is required' : ''
          "
        ></v-select>

        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedDate"
              dense
              prepend-inner-icon="mdi-calendar"
              hide-details
              outlined
              label="Start Date"
              class="mb-4 flex-grow-0"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="startDate"
            @change="dateMenu = false"
          ></v-date-picker>
        </v-menu>
      </div>
    </div>

    <div class="d-flex pa-5 pt-0">
      <v-btn
        color="warning"
        class="ml-auto"
        depressed
        @click="submit"
        :loading="loader"
        >Assign to Group</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  props: ["selectedUsers"],
  data() {
    return {
      group: null,
      startDate: new Date().toISOString().substr(0, 10),
      dateMenu: false,
      loader: false,
      localSelectedUsers: [],
    };
  },
  validations: {
    group: { required },
    localSelectedUsers: { required },
  },
  computed: {
    ...mapState({
      allGroups: (state) => state.base.allGroups,
    }),
    formattedDate() {
      return this.startDate
        ? this.dayjs(this.startDate).format("DD/MM/YYYY")
        : "";
    },
    hasError() {
      const found = this.localSelectedUsers.find((user) => user.notAdded);

      return !!found;
    },
  },
  created() {
    this.localSelectedUsers = [...this.selectedUsers];
  },
  methods: {
    ...mapActions(["assignUsersToGroup"]),
    unselectUser(index) {
      this.localSelectedUsers.splice(index, 1);

      this.$emit("unselectUser", index);
    },
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        await this.assignUsersToGroup({
          addUserIds: this.localSelectedUsers.map((user) => user.clientUserId),
          groupId: this.group,
          startDate: this.dayjs.utc(this.startDate).toISOString(),
        });

        this.$emit("closeDialog", { unselectUsers: true });
      } catch (e) {
        if (e.response?.data?.error?.errorCode == 10405) {
          this.localSelectedUsers = this.localSelectedUsers.map((user) => {
            const found = e.response?.data?.result?.notAddedUsers.find(
              (notAddedUser) =>
                notAddedUser.clientUserId.toLowerCase() ==
                user.clientUserId.toLowerCase()
            );

            return {
              ...user,
              notAdded: !!found,
            };
          });
        } else {
          this.$emit("closeDialog", { unselectUsers: true });
        }
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  flex: 1;
}
</style>
