import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#3276b1",
        secondary: "#57889c",
        accent: "#82B1FF",
        error: "#c26565",
        info: "#2196F3",
        success: "#739e73",
        warning: "#b1811d",
        filred: "#A90329",
        filblue: "#1a345b",
        filyellow: "#c19752",
        filladi: "#71843f",
        filgreen: "#33807b",
        filpurple: "#6e587a",
      },
    },
  },
});
