<template>
  <div class="component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column">
    <div class="wrapper rounded flex-grow-1 d-flex flex-column pa-4">
      <div class="header mb-4">
        <v-btn class="back-btn" icon @click="onBack">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <div class="subtitle-1 text-center">
          {{ isEdit ? currentClient.companyName : "Add New Client" }}
        </div>
      </div>

      <v-tabs class="flex-grow-0 mb-4" v-if="isEdit">
        <v-tab
          :to="`/superadmin/clients/${$route.params.clientId}`"
          exact
          :disabled="globalLoader"
        >
          Basic Data
        </v-tab>

        <v-tab
          :to="`/superadmin/clients/${$route.params.clientId}/questionnaires`"
          exact
          :disabled="globalLoader"
        >
          Questionnaires
        </v-tab>

        <v-tab
          :to="`/superadmin/clients/${$route.params.clientId}/transactions`"
          :disabled="globalLoader"
        >
          Transactions
        </v-tab>

        <v-tab
          :to="`/superadmin/clients/${$route.params.clientId}/mobile-apps`"
          exact
          :disabled="globalLoader"
        >
          Mobile Apps
        </v-tab>

        <v-tab
          :to="`/superadmin/clients/${$route.params.clientId}/users`"
          :disabled="globalLoader"
        >
          Users
        </v-tab>

        <v-tab
          :to="`/superadmin/clients/${$route.params.clientId}/admins`"
          :disabled="globalLoader"
        >
          Administrators
        </v-tab>
      </v-tabs>

      <router-view />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      currentClient: (state) => state.clients.currentClient,
      tabLoader: (state) => state.clients.tabLoader,
      globalLoader: (state) => state.base.globalLoader,
    }),
    isEdit() {
      return this.$route?.params?.clientId;
    },
  },
  methods: {
    ...mapMutations([
      "setCurrentClient",
      "toggleQuestionnairesFetched",
      "toggleTransactionsFetched",
      "toggleMobileAppsFetched",
      "toggleAdminsFetched",
    ]),
    onBack() {
      if (this.$route.name == "newUserTransaction") {
        this.$router.push({
          path: `/superadmin/clients/${this.$route.params.clientId}/transactions`,
        });
      } else if (
        this.$route.name == "editUserAdminNew" ||
        this.$route.name == "editUserAdminEdit"
      ) {
        this.$router.push({
          path: `/superadmin/clients/${this.$route.params.clientId}/admins`,
        });
      } else if (
        this.$route.name == "editUserUserAssignments" ||
        this.$route.name == "editUserUserData" ||
        this.$route.name == "editUserUserGroups"
      ) {
        this.$router.push({
          path: `/superadmin/clients/${this.$route.params.clientId}/users`,
        });
      } else if (this.$route.name == "editUserUserReport") {
        this.$router.push({
          path: `/superadmin/clients/${this.$route.params.clientId}/users/${this.$route.params.userId}`,
        });
      } else {
        this.$router.push({
          path: "/superadmin/clients",
        });
      }
    },
  },
  destroyed() {
    this.setCurrentClient(null);
    this.toggleQuestionnairesFetched(false);
    this.toggleTransactionsFetched(false);
    this.toggleMobileAppsFetched(false);
    this.toggleAdminsFetched(false);
  },
};
</script>

<style lang="scss" scoped>
.preview {
  height: 70px;
  width: 70px;
  object-fit: cover;
  border-radius: 4px;
}
</style>
