var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-1"},[_c('v-select',{staticClass:"mb-1",attrs:{"outlined":"","dense":"","label":"Group Type*","items":_vm.groupTypes,"item-value":"itemKey","item-text":"itemValue","value":_vm.groupDataForm.type,"error-messages":_vm.v.$dirty && !_vm.v.form.groupData.type.required
        ? 'This field is required'
        : '',"readonly":_vm.allowedParentGroupsLoader,"disabled":_vm.isEdit},on:{"input":function($event){return _vm.setGroupDataForm({ value: $event, key: 'type' })},"change":_vm.onGroupTypeChange}}),_c('v-text-field',{staticClass:"mb-1",attrs:{"outlined":"","dense":"","label":"Group Name*","value":_vm.groupDataForm.name,"error-messages":_vm.v.$dirty && !_vm.v.form.groupData.name.required
        ? 'This field is required'
        : '',"disabled":_vm.isEdit},on:{"input":function($event){return _vm.setGroupDataForm({ value: $event, key: 'name' })}}}),_c('v-text-field',{staticClass:"mb-1",attrs:{"outlined":"","dense":"","label":"Group Description","value":_vm.groupDataForm.description},on:{"input":function($event){return _vm.setGroupDataForm({ value: $event, key: 'description' })}}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mb-1",attrs:{"value":_vm.formattedDate,"dense":"","prepend-inner-icon":"mdi-calendar","outlined":"","label":"Creation Date","readonly":"","disabled":_vm.isEdit}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"value":_vm.groupDataForm.creationDate,"disabled":_vm.isEdit},on:{"input":function($event){return _vm.setGroupDataForm({ value: $event, key: 'creationDate' })},"change":function($event){_vm.dateMenu = false}}})],1),_c('v-select',{staticClass:"mb-1",attrs:{"outlined":"","dense":"","label":"Parent Group","items":_vm.allowedParentGroups,"item-value":"groupId","item-text":"groupName","value":_vm.groupDataForm.parentGroup,"disabled":!_vm.groupDataForm.type,"loading":_vm.allowedParentGroupsLoader,"clearable":""},on:{"input":function($event){return _vm.setGroupDataForm({ value: $event, key: 'parentGroup' })},"click:clear":function($event){return _vm.setGroupDataForm({ value: null, key: 'parentGroup' })}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }