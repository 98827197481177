<template>
  <div class="d-flex flex-column">
    <v-btn
      color="filyellow"
      class="white--text align-self-end"
      depressed
      :to="`/superadmin/clients/${$route.params.clientId}/transactions/new`"
      >Add Purchase</v-btn
    >

    <v-divider class="my-4"></v-divider>

    <div class="d-flex">
      <v-text-field
        outlined
        dense
        label="Search"
        prepend-inner-icon="mdi-magnify"
        hint="Search by Questionnaire"
        persistent-hint
        :value="transactionsParams.search"
        @input="setTransactionsParams({ search: $event })"
        :readonly="loader"
        clearable
        class="align-self-start flex-grow-0 mr-auto"
      ></v-text-field>

      <ShowPerPage
        class="mr-2"
        :currentPerPage="transactionsParams.itemsPerPage"
        :options="showPerPageOptions"
      />

      <FilterBy
        :fields="filterFields"
        :loader="loader"
        @filterChange="onFilterChange"
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="transactions.results"
      :server-items-length="transactions.rowCount"
      class="clickable mb-4"
      checkbox-color="primary"
      hide-default-footer
      :options.sync="options"
      @update:options="onGetTransactions(true)"
      :loading="loader"
    >
      <template v-slot:[`item.transactionDate`]="{ item }">
        {{ dayjs.utc(item.transactionDate).local().format("DD/MM/YYYY HH:mm") }}
      </template>

      <template v-slot:[`item.assignmentName`]="{ item }">
        {{ item.assignmentName ? item.assignmentName : "-" }}
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        {{ item.isUnlimited ? "Unlimited" : item.quantity }}
      </template>

      <template v-slot:[`item.expiryDate`]="{ item }">
        {{
          item.isUnlimited
            ? dayjs.utc(item.expiryDate).local().format("DD/MM/YYYY")
            : "-"
        }}
      </template>
    </v-data-table>

    <v-pagination
      class="mt-auto"
      :length="transactions.pageCount"
      v-model="transactionsParams.page"
      v-if="transactions.pageCount"
    ></v-pagination>
  </div>
</template>

<script>
import FilterBy from "@/components/FilterBy";
import { mapActions, mapMutations, mapState } from "vuex";
import ShowPerPage from "@/components/ShowPerPage";

export default {
  components: {
    FilterBy,
    ShowPerPage,
  },
  data() {
    return {
      headers: [
        { text: "Date", value: "transactionDate" },
        { text: "Questionnaire", value: "questionnaireName" },
        { text: "Assignment", value: "assignmentName" },
        { text: "Type", value: "transactionTypeName", sortable: false },
        { text: "Units", value: "quantity", sortable: false },
        { text: "Expiration Date", value: "expiryDate" },
      ],
      loader: false,
      timeout: null,
      isFirstCall: true,
      showPerPageOptions: [],
    };
  },
  computed: {
    ...mapState({
      transactionsParams: (state) => state.clients.transactionsParams,
      transactions: (state) => state.clients.transactions,
    }),
    options: {
      get() {
        const { itemsPerPage, page, sortBy, sortDesc } =
          this.transactionsParams;

        return {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        };
      },
      set(value) {
        let { itemsPerPage, page, sortBy, sortDesc } = value;

        this.setTransactionsParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      },
    },
    filterFields() {
      return [
        {
          key: "dateFrom",
          value: "From",
          selectedValue: this.transactionsParams.dateFrom
            ? this.dayjs
                .utc(this.transactionsParams.dateFrom)
                .local()
                .toISOString()
                .substr(0, 10)
            : null,
        },
        {
          key: "dateTo",
          value: "To",
          selectedValue: this.transactionsParams.dateTo
            ? this.dayjs
                .utc(this.transactionsParams.dateTo)
                .local()
                .toISOString()
                .substr(0, 10)
            : null,
        },
        {
          key: "transactionTypeId",
          value: "Type",
          values: [
            { itemKey: "purch", itemValue: "Purchase" },
            { itemKey: "assign", itemValue: "Assignment" },
            { itemKey: "return", itemValue: "Return" },
          ],
          selectedValue: this.transactionsParams.transactionTypeId,
        },
      ];
    },
  },
  created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setTransactionsParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setTransactionsParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setTransactionsParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setTransactionsParams,
      },
    ];
  },
  methods: {
    ...mapMutations(["setTransactionsParams"]),
    ...mapActions(["getTransactions"]),
    async onGetTransactions(refresh) {
      if (this.isFirstCall) {
        this.isFirstCall = false;
        return;
      }

      this.loader = true;

      try {
        await this.getTransactions(refresh);
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    onFilterChange(e) {
      this.setTransactionsParams({
        [e.field]:
          (e.field == "dateFrom" || e.field == "dateTo") && e.value
            ? this.dayjs.utc(e.value).toISOString()
            : e.value,
      });

      this.onGetTransactions(true);
    },
  },
  watch: {
    "transactionsParams.search"() {
      clearTimeout(this.timeout);

      if (this.loader) return;

      this.timeout = setTimeout(() => {
        this.setTransactionsParams({
          page: 1,
        });

        this.onGetTransactions(true);
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
