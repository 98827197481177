<template>
  <div class="pt-1">
    <div class="d-flex align-center mb-5" v-if="$vuetify.breakpoint.xsOnly">
      <div class="subtitle-1 font-weight-medium">Step 1</div>

      <div class="body-1 mx-2">-</div>

      <div class="body-1">Basic Information</div>
    </div>

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          hide-details
          dense
          label="Title"
          class="mb-4"
          :value="form.title"
          @input="setNotificationForm({ value: $event, key: 'title' })"
          :error="v.form.title.$dirty && v.form.title.$invalid"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          class="mb-4"
          label="Importance"
          outlined
          dense
          hide-details
          :items="importances"
          item-value="key"
          item-text="value"
          :value="form.importance"
          @input="setNotificationForm({ value: $event, key: 'importance' })"
          :error="v.form.importance.$dirty && v.form.importance.$invalid"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <tiptap-vuetify
          :extensions="extensions"
          placeholder="Write a message"
          class="mb-5"
          :style="
            v.form.body.$dirty && v.form.body.$invalid
              ? 'border: 2px solid #c26565; border-radius: 3px'
              : ''
          "
          :value="form.body"
          @input="setNotificationForm({ value: $event, key: 'body' })"
        />
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
import { mapActions, mapMutations, mapState } from "vuex";
import FileSelector from "@/components/FileSelector.vue";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  History,
  Image,
} from "tiptap-vuetify";

export default {
  props: ["isEdit", "v"],

  components: {
    TiptapVuetify,
    FileSelector,
  },

  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        Paragraph,
        HardBreak,
        [
          Image,
          {
            options: {
              imageSourcesOverride: true,
              imageSources: [
                {
                  component: FileSelector,
                  name: "Upload",
                },
              ],
            },
          },
        ],
      ],
      importances: [
        { key: "low", value: "Low" },
        { key: "medium", value: "Medium" },
        { key: "high", value: "High" },
      ],
      dateToSendMenu: false,
      dateToRemindMenu: false,
      dateToEndMenu: false,
      usersToNotifyLoader: false,
    };
  },
  computed: {
    ...mapState({
      form: (state) => state.notifications.form,
      info: (state) => state.base.info,
      possitbleUsersToNotify: (state) =>
        state.assignments.possitbleUsersToNotify,
    }),
  },
  async created() {},
  methods: {
    ...mapMutations(["setNotificationForm"]),
    ...mapActions([""]),
  },
};
</script>
  
  <style lang="scss" scoped>
</style>
  