<template>
  <form
    class="flex-grow-1 d-flex flex-column"
    @submit.prevent="submit"
    novalidate
    autocomplete="off"
  >
    <v-row>
      <v-col cols="6">
        <div class="subtitle-2 mb-5">Company Data</div>

        <v-text-field
          outlined
          dense
          class="mb-1"
          label="Company Name"
          v-model="companyName"
          :error-messages="
            $v.$dirty && $v.companyName.$invalid ? 'This field is required' : ''
          "
        >
        </v-text-field>

        <v-file-input
          outlined
          dense
          label="Logo"
          prepend-icon=""
          prepend-inner-icon="mdi-camera"
          accept="image/png, image/jpeg"
          v-model="logo"
          class="mb-1"
          :error-messages="
            $v.$dirty && $v.logo.$invalid ? 'This field is required' : ''
          "
        ></v-file-input>

        <img :src="preview" v-if="preview" class="preview mb-5" />

        <v-text-field
          outlined
          dense
          class="mb-1"
          label="VAT"
          v-model="vat"
          :error-messages="
            $v.$dirty && $v.vat.$invalid ? 'This field is required' : ''
          "
        >
        </v-text-field>

        <v-select
          outlined
          dense
          class="mb-1"
          label="Mobile Notifications"
          :items="statuses"
          item-text="value"
          item-value="key"
          v-model="hasMobileNotifications"
          :error-messages="
            $v.$dirty && $v.hasMobileNotifications.$invalid
              ? 'This field is required'
              : ''
          "
        >
        </v-select>

        <v-select
          outlined
          dense
          class="mb-1"
          label="Status"
          :items="statuses"
          item-text="value"
          item-value="key"
          v-model="isEnabled"
          :error-messages="
            $v.$dirty && $v.isEnabled.$invalid ? 'This field is required' : ''
          "
        >
        </v-select>

        <v-select
          outlined
          dense
          class="mb-1"
          label="Filistos Training Needs"
          :items="statuses"
          item-text="value"
          item-value="key"
          v-model="hasFilistosTrainingNeeds"
          :error-messages="
            $v.$dirty && $v.isEnabled.$invalid ? 'This field is required' : ''
          "
        >
        </v-select>

        <v-select
          outlined
          dense
          class="mb-1"
          label="Factor Groups"
          :items="statuses"
          item-text="value"
          item-value="key"
          v-model="hasFactorGroups"
          :error-messages="
            $v.$dirty && $v.isEnabled.$invalid ? 'This field is required' : ''
          "
        >
        </v-select>

        <v-select
          outlined
          dense
          class="mb-1"
          label="Merge Users"
          :items="statuses"
          item-text="value"
          item-value="key"
          v-model="hasMergeUsers"
          :error-messages="
            $v.$dirty && $v.isEnabled.$invalid ? 'This field is required' : ''
          "
        >
        </v-select>
      </v-col>

      <v-col cols="6">
        <div class="subtitle-2 mb-5">Company Administrator Data</div>

        <v-text-field
          dense
          outlined
          label="Identity*"
          class="mb-1"
          v-model="identity"
          :error-messages="
            $v.$dirty && !$v.identity.required ? 'This field is required' : ''
          "
          :disabled="isEdit"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          label="First Name*"
          class="mb-1"
          v-model="firstName"
          :error-messages="
            $v.$dirty && !$v.firstName.required ? 'This field is required' : ''
          "
          :disabled="isEdit"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          label="Last Name*"
          class="mb-1"
          v-model="lastName"
          :error-messages="
            $v.$dirty && !$v.lastName.required ? 'This field is required' : ''
          "
          :disabled="isEdit"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          label="Username*"
          class="mb-1"
          v-model="username"
          :error-messages="usernameErrors"
          :disabled="isEdit"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          label="Email*"
          class="mb-1"
          v-model="email"
          :error-messages="emailErrors"
          :disabled="isEdit"
        ></v-text-field>

        <v-select
          dense
          outlined
          label="Nationality*"
          :items="countries"
          item-value="itemKey"
          item-text="itemValue"
          class="mb-1"
          v-model="nationality"
          :error-messages="
            $v.$dirty && !$v.nationality.required
              ? 'This field is required'
              : ''
          "
          :disabled="isEdit"
        ></v-select>

        <v-select
          dense
          outlined
          label="Language*"
          :items="languages"
          item-value="itemKey"
          item-text="itemValue"
          class="mb-1"
          v-model="language"
          :error-messages="
            $v.$dirty && !$v.language.required ? 'This field is required' : ''
          "
          :disabled="isEdit"
        ></v-select>

        <v-text-field
          dense
          outlined
          label="Mobile"
          class="mb-1"
          v-model="mobile"
          :disabled="isEdit"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-btn
      class="align-self-center white--text"
      color="filyellow"
      depressed
      type="submit"
      :loading="loader"
      :disabled="isEdit && !hasChanges"
      >{{ isEdit ? "Update Client" : "Create Client" }}</v-btn
    >
  </form>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required, email, requiredIf } from "vuelidate/lib/validators";

const uniqueUsername = function() {
  return !this.usernameExists;
};

const uniqueEmail = function() {
  return !this.emailExists;
};

export default {
  data() {
    return {
      companyName: "",
      logo: null,
      vat: "",
      isEnabled: true,
      hasMobileNotifications: false,
      identity: "",
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      nationality: null,
      language: null,
      mobile: "",
      initialData: null,
      statuses: [
        {
          key: true,
          value: "Enabled",
        },
        {
          key: false,
          value: "Disabled",
        },
      ],
      loader: false,
      usernameExists: false,
      emailExists: false,
      hasFilistosTrainingNeeds: false,
      hasFactorGroups: false,
      hasMergeUsers: false,
    };
  },
  validations: {
    companyName: { required },
    logo: {
      required: requiredIf(function() {
        return !this.isEdit;
      }),
    },
    vat: { required },
    isEnabled: { required },
    hasMobileNotifications: { required },
    identity: {
      required: requiredIf(function() {
        return !this.isEdit;
      }),
    },
    firstName: {
      required: requiredIf(function() {
        return !this.isEdit;
      }),
    },
    lastName: {
      required: requiredIf(function() {
        return !this.isEdit;
      }),
    },
    username: {
      required: requiredIf(function() {
        return !this.isEdit;
      }),
      uniqueUsername,
    },
    email: {
      required: requiredIf(function() {
        return !this.isEdit;
      }),
      email,
      uniqueEmail,
    },
    nationality: {
      required: requiredIf(function() {
        return !this.isEdit;
      }),
    },
    language: {
      required: requiredIf(function() {
        return !this.isEdit;
      }),
    },
  },
  computed: {
    ...mapState({
      countries: (state) => state.base.lists.countries,
      languages: (state) => state.base.lists.languages,
      discardDialog: (state) => state.base.discardDialog,
      currentClient: (state) => state.clients.currentClient,
    }),
    isEdit() {
      return this.$route.name == "editUserBasicData";
    },
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !=
        JSON.stringify({
          companyName: this.companyName,
          logo: this.logo,
          vat: this.vat,
          isEnabled: this.isEnabled,
          hasMobileNotifications: this.hasMobileNotifications,
          hasFilistosTrainingNeeds: this.hasFilistosTrainingNeeds,
          hasFactorGroups: this.hasFactorGroups,
          hasMergeUsers: this.hasMergeUsers,
          identity: this.identity,
          firstName: this.firstName,
          lastName: this.lastName,
          username: this.username,
          email: this.email,
          nationality: this.nationality,
          language: this.language,
          mobile: this.mobile,
        })
      );
    },
    preview() {
      if (this.isEdit) {
        if (this.logo) return URL.createObjectURL(this.logo);

        if (!this.currentClient.logoUrl) return null;

        return `${process.env.VUE_APP_BASE_URL.split("/api")[0]}/${
          this.currentClient.logoUrl
        }`;
      } else {
        if (!this.logo) return null;

        return URL.createObjectURL(this.logo);
      }
    },
    usernameErrors() {
      const errors = [];

      if (!this.$v.username.$dirty) return errors;
      if (!this.$v.username.required) errors.push("This field is required");
      if (!this.$v.username.uniqueUsername)
        errors.push("This username already exists.");
      return errors;
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) errors.push("This field is required");
      if (!this.$v.email.email) errors.push("Must be a valid email");
      if (!this.$v.email.uniqueEmail) errors.push("This email already exists.");
      return errors;
    },
  },
  created() {
    if (this.isEdit) {
      console.log(this.currentClient);
      this.companyName = this.currentClient?.companyName;
      this.vat = this.currentClient?.vat;
      this.isEnabled = this.currentClient?.isEnabled;
      this.hasMobileNotifications = this.currentClient?.hasMobileNotifications;
      this.hasFilistosTrainingNeeds = this.currentClient?.hasFilistosTrainingNeeds;
      this.hasFactorGroups = this.currentClient?.hasFactorGroups;
      this.hasMergeUsers = this.currentClient?.hasMergeUsers;
      this.identity = this.currentClient.identityNumber;
      this.firstName = this.currentClient.firstName;
      this.lastName = this.currentClient.lastName;
      this.username = this.currentClient.username;
      this.email = this.currentClient.email;
      this.nationality = this.currentClient.nationalityId;
      this.language = this.currentClient.languageId;
      this.mobile = this.currentClient.mobile;
    }

    this.initialData = {
      companyName: this.companyName,
      logo: this.logo,
      vat: this.vat,
      isEnabled: this.isEnabled,
      hasMobileNotifications: this.hasMobileNotifications,
      hasFilistosTrainingNeeds: this.hasFilistosTrainingNeeds,
      hasFactorGroups: this.hasFactorGroups,
      hasMergeUsers: this.hasMergeUsers,
      identity: this.identity,
      firstName: this.firstName,
      lastName: this.lastName,
      username: this.username,
      email: this.email,
      nationality: this.nationality,
      language: this.language,
      mobile: this.mobile,
    };
  },
  methods: {
    ...mapMutations(["toggleDiscardDialog"]),
    ...mapActions(["addClient", "updateClient"]),
    async submit() {
      this.usernameExists = false;
      this.emailExists = false;

      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        if (this.isEdit) {
          await this.updateClient({
            clientId: this.$route.params.clientId,
            companyName: this.companyName,
            ...(this.logo && { logo: this.logo }),
            vat: this.vat,
            isEnabled: this.isEnabled,
            hasMobileNotifications: this.hasMobileNotifications,
            hasFilistosTrainingNeeds: this.hasFilistosTrainingNeeds,
            hasFactorGroups: this.hasFactorGroups,
            hasMergeUsers: this.hasMergeUsers,
          });

          this.companyName = this.currentClient?.companyName;
          this.vat = this.currentClient?.vat;
          this.isEnabled = this.currentClient?.isEnabled;
          this.logo = null;

          this.initialData = {
            ...this.initialData,
            companyName: this.companyName,
            vat: this.vat,
            logo: this.logo,
            isEnabled: this.isEnabled,
            hasMobileNotifications: this.hasMobileNotifications,
          };
        } else {
          await this.addClient({
            companyName: this.companyName,
            logo: this.logo,
            vat: this.vat,
            isEnabled: this.isEnabled,
            hasMobileNotifications: this.hasMobileNotifications,
            hasFilistosTrainingNeeds: this.hasFilistosTrainingNeeds,
            hasFactorGroups: this.hasFactorGroups,
            hasMergeUsers: this.hasMergeUsers,
            platformAccessId: "co",
            canLogin: true,
            identityNumber: this.identity,
            firstName: this.firstName,
            lastName: this.lastName,
            username: this.username,
            email: this.email,
            nationalityId: this.nationality,
            languageId: this.language,
            mobile: this.mobile,
          });

          this.toggleDiscardDialog({
            allowLeave: true,
          });

          this.$router.push({ path: "/superadmin/clients" });
        }
      } catch (e) {
        if (e.response.data.error.errorCode == 20402) {
          this.usernameExists = true;
        }

        if (e.response.data.error.errorCode == 20412) {
          this.emailExists = true;
        }
      }

      this.loader = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    //DISCARD DIALOG LOGIC
    if (this.hasChanges && !this.discardDialog.allowLeave) {
      this.toggleDiscardDialog({
        open: true,
        to: to?.path,
      });

      next(false);
    } else {
      this.toggleDiscardDialog({
        allowLeave: false,
        to: null,
      });

      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.preview {
  height: 70px;
  width: 70px;
  object-fit: cover;
  border-radius: 4px;
}
</style>
