var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper px-4 pt-4 grey lighten-3 d-flex flex-column"},[_c('div',{staticClass:"header d-flex justify-center mb-4"},[_c('v-btn',{staticClass:"back-btn",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('div',{staticClass:"body-1 font-weight-medium text-center"},[_vm._v(" Personnel Matrix Results ")])],1),_c('div',{staticClass:"wrapper rounded flex-grow-1 d-flex flex-column pa-4"},[(_vm.loader)?_c('v-progress-circular',{staticClass:"ma-auto",attrs:{"size":"120","width":"4","indeterminate":"","color":"primary"}}):[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","color":"primary","loading":_vm.excelLoader},on:{"click":_vm.onCombinationGetExcel}},[_vm._v("Export Excel")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.crewCombinationsResults.results,"items-per-page":10000,"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',{staticClass:"thead"},[_c('tr',_vm._l((headers),function(h,index){return _c('th',{key:index,staticClass:"rotate mr-8"},[_c('div',[_c('span',{class:{ 'font-weight-black black--text': !h.child }},[_vm._v(_vm._s(h.text))])])])}),0)])]}},{key:"item",fn:function(item){return [(item.item)?_c('tr',{staticClass:"body"},_vm._l((_vm.headers),function(h,index){return _c('td',{key:index,class:{
                'filred-bg':
                  (Object(item.item[h.value]) === item.item[h.value] &&
                    item.item[h.value].color == 'R') ||
                  h.value == 'R',
                'filyellow-bg':
                  (Object(item.item[h.value]) === item.item[h.value] &&
                    item.item[h.value].color == 'Y') ||
                  h.value == 'Y',
                'filgreen-bg':
                  (Object(item.item[h.value]) === item.item[h.value] &&
                    item.item[h.value].color == 'G') ||
                  h.value == 'G',
              }},[(Object(item.item[h.value]) === item.item[h.value])?_c('div',{class:{
                  'font-weight-bold':
                    item.item[h.value].statistics == 'S' ||
                    item.item[h.value].statistics == 'A',
                }},[_vm._v(" "+_vm._s(item.item[h.value].aScore ? item.item[h.value].aScore : "-")+" ")]):_c('div',[(h.value != 'R' && h.value != 'G' && h.value != 'Y')?_c('div',[_vm._v(" "+_vm._s(item.item[h.value] ? item.item[h.value] : "-")+" ")]):_c('div',[_c('span',{staticClass:"pr-3"}),_vm._v(" "+_vm._s(item.item[h.value] ? +item.item[h.value] : "-")+" "),_c('span',{staticClass:"pl-3"})])])])}),0):_vm._e()]}}])})]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }