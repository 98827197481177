<template>
  <div class="component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column">
    <div class="wrapper rounded flex-grow-1 d-flex flex-column pa-4">
      <div class="header mb-8">
        <v-btn class="back-btn" icon @click="onBack">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <div class="subtitle-1 text-center">
          {{ currentQuestionnaire.questionnaireName }}
        </div>
      </div>

      <v-tabs class="flex-grow-0 mb-5">
        <v-tab
          :to="`/superadmin/questionnaires/${$route.params.questionnaireTypeId}/${$route.params.questionnaireId}`"
          exact
          :disabled="globalLoader"
        >
          Questionnaire Data
        </v-tab>

        <v-tab
          :to="sectionsNavigate"
          :disabled="globalLoader"
          v-if="
            !currentQuestionnaire.isCollectionHead &&
            !currentQuestionnaire.isGroupHead &&
            currentQuestionnaire.questionnaireTypeId != 'elearn'
          "
        >
          Sections
        </v-tab>

        <v-tab
          :to="`/superadmin/questionnaires/${$route.params.questionnaireTypeId}/${$route.params.questionnaireId}/factors`"
          :disabled="globalLoader"
          v-if="currentQuestionnaire.questionnaireTypeId == 'psycho'"
        >
          Factors
        </v-tab>

        <v-tab
          :to="`/superadmin/questionnaires/${$route.params.questionnaireTypeId}/${$route.params.questionnaireId}/factor_groups`"
          :disabled="globalLoader"
          v-if="currentQuestionnaire.questionnaireTypeId == 'psycho'"
        >
          Factor Groups
        </v-tab>
      </v-tabs>

      <router-view />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      currentQuestionnaire: (state) =>
        state.questionnaires.currentQuestionnaire,
      globalLoader: (state) => state.base.globalLoader,
    }),
    sectionsNavigate() {
      const sectionId = this.currentQuestionnaire?.sections[0]?.sectionId
        ? this.currentQuestionnaire?.sections[0]?.sectionId
        : "";
      return `/superadmin/questionnaires/${
        this.$route.params.questionnaireTypeId
      }/${this.$route.params.questionnaireId}/sections${
        sectionId ? "/" + sectionId : ""
      }`;
    },
  },
  methods: {
    ...mapMutations(["setCurrentQuestionnaire"]),
    onBack() {
      if (this.$route.name == "questionnaireFactor") {
        this.$router.push({
          path: `/superadmin/questionnaires/${this.$route.params.questionnaireTypeId}/${this.$route.params.questionnaireId}/factors`,
        });
      } else if (this.$route.name == "questionnaireChildFactor") {
        this.$router.push({
          path: `/superadmin/questionnaires/${this.$route.params.questionnaireTypeId}/${this.$route.params.questionnaireId}/factors/${this.$route.params.factorId}`,
        });
      } else if (this.$route.name == "questionnaireQuestion") {
        this.$router.push({
          path: `/superadmin/questionnaires/${this.$route.params.questionnaireTypeId}/${this.$route.params.questionnaireId}/sections/${this.$route.params.sectionId}`,
        });
      } else {
        this.$router.push({
          path: `/superadmin/questionnaires/${this.$route.params.questionnaireTypeId}`,
        });
      }
    },
  },
  destroyed() {
    this.setCurrentQuestionnaire(null);
  },
};
</script>

<style lang="scss" scoped>
.preview {
  height: 70px;
  width: 70px;
  object-fit: cover;
  border-radius: 4px;
}
</style>
