<template>
  <form
    class="component-wrapper pa-0 d-flex flex-column"
    @submit.prevent="submit"
  >
    <div class="text-h6 align-self-center text-center mb-4">
      {{ isEdit ? "Edit" : "New" }} User
    </div>

    <v-row>
      <v-col cols="6">
        <v-select
          dense
          outlined
          label="Platform Access"
          :items="platformAccessesMapped"
          item-value="itemKey"
          item-text="itemValue"
          class="flex-grow-0 mb-1"
          v-model="platformAccess"
          :error-messages="
            $v.$dirty && !$v.platformAccess.required
              ? 'This field is required'
              : ''
          "
          :disabled="isEdit"
        ></v-select>

        <div class="d-flex mb-6">
          <v-switch
            inset
            color="success"
            label="Can Login"
            v-model="canLogin"
            class="ma-0 pa-0"
            dense
            hide-details
          ></v-switch>

          <v-divider vertical class="mx-4"></v-divider>

          <v-switch
            inset
            color="success"
            label="Main Administrator"
            v-model="isClientAdmin"
            class="ma-0 pa-0"
            dense
            hide-details
            v-if="platformAccess == 'co'"
            :disabled="isClientAdmin && !hasChanges"
          ></v-switch>
        </div>

        <v-text-field
          dense
          outlined
          label="Identity*"
          class="mb-1"
          v-model="identity"
          :error-messages="identityErrors"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          label="First Name*"
          class="mb-1"
          v-model="firstName"
          :error-messages="
            $v.$dirty && !$v.firstName.required ? 'This field is required' : ''
          "
        ></v-text-field>

        <v-text-field
          dense
          outlined
          label="Last Name*"
          class="mb-1"
          v-model="lastName"
          :error-messages="
            $v.$dirty && !$v.lastName.required ? 'This field is required' : ''
          "
        ></v-text-field>

        <v-text-field
          dense
          outlined
          label="Username*"
          class="mb-1"
          v-model="username"
          :error-messages="usernameErrors"
          :disabled="isEdit"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          label="Email*"
          class="mb-1"
          v-model="email"
          :error-messages="emailErrors"
        ></v-text-field>

        <v-radio-group v-model="positionType" row class="mt-0">
          <v-radio
            v-for="type in currentClient.userTypes"
            :key="type.itemKey"
            :value="type.itemKey"
            :label="type.itemValue"
            :ripple="false"
          ></v-radio>
        </v-radio-group>

        <v-select
          dense
          outlined
          label="Position*"
          :items="positions"
          item-value="itemKey"
          item-text="itemValue"
          v-model="position"
          :error-messages="
            $v.$dirty && !$v.position.required ? 'This field is required' : ''
          "
        ></v-select>
      </v-col>

      <v-col cols="6">
        <v-select
          dense
          outlined
          label="Nationality*"
          :items="countries"
          item-value="itemKey"
          item-text="itemValue"
          class="mb-1"
          v-model="nationality"
          :error-messages="
            $v.$dirty && !$v.nationality.required
              ? 'This field is required'
              : ''
          "
        ></v-select>

        <v-select
          dense
          outlined
          label="Language*"
          :items="languages"
          item-value="itemKey"
          item-text="itemValue"
          class="mb-1"
          v-model="language"
          :error-messages="
            $v.$dirty && !$v.language.required ? 'This field is required' : ''
          "
        ></v-select>

        <v-text-field
          dense
          outlined
          label="Mobile"
          class="mb-1"
          v-model="mobile"
        ></v-text-field>

        <v-menu max-width="290" :close-on-content-click="false" ref="birthDate">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="birthDate ? dayjs(birthDate).format('DD/MM/YYYY') : ''"
              dense
              prepend-inner-icon="mdi-calendar"
              outlined
              label="Birth Date"
              class="mb-1"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="birthDate"
            @change="$refs.birthDate.save()"
          ></v-date-picker>
        </v-menu>

        <v-select
          dense
          outlined
          label="Gender"
          :items="genders"
          item-value="itemKey"
          item-text="itemValue"
          class="mb-1"
          v-model="gender"
        ></v-select>

        <v-select
          dense
          outlined
          label="Marital Status"
          :items="maritalStatuses"
          item-value="itemKey"
          item-text="itemValue"
          class="mb-1"
          v-model="marital"
        ></v-select>

        <v-text-field
          dense
          outlined
          label="Kids"
          type="number"
          v-model.number="kids"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-btn
      class="align-self-center white--text"
      color="filyellow"
      depressed
      type="submit"
      :loading="loader"
      :disabled="isEdit && !hasChanges"
      >{{ isEdit ? "Edit" : "Add" }} User</v-btn
    >
  </form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapActions, mapMutations, mapState } from "vuex";

const uniqueIdentity = function () {
  return !this.identityExists;
};

const uniqueUsername = function () {
  return !this.usernameExists;
};

const uniqueEmail = function () {
  return !this.emailExists;
};

export default {
  data() {
    return {
      platformAccess: null,
      canLogin: true,
      isClientAdmin: false,
      identity: "",
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      positionType: null,
      position: null,
      nationality: null,
      language: null,
      mobile: "",
      birthDate: null,
      gender: null,
      marital: null,
      kids: "",
      identityExists: false,
      usernameExists: false,
      emailExists: false,
      loader: false,
      initialData: null,
    };
  },
  validations: {
    platformAccess: { required, uniqueIdentity },
    identity: { required, uniqueIdentity },
    firstName: { required },
    lastName: { required },
    username: { required, uniqueUsername },
    email: { required, email, uniqueEmail },
    position: { required },
    nationality: { required },
    language: { required },
  },
  computed: {
    ...mapState({
      countries: (state) => state.base.lists.countries,
      languages: (state) => state.base.lists.languages,
      genders: (state) => state.base.lists.genders,
      maritalStatuses: (state) => state.base.lists.maritalStatuses,
      platformAccesses: (state) => state.base.lists.platformAccesses,
      currentClient: (state) => state.clients.currentClient,
      currentAdmin: (state) => state.clients.currentAdmin,
      discardDialog: (state) => state.base.discardDialog,
    }),
    isEdit() {
      return this.$route.name == "editUserAdminEdit";
    },
    platformAccessesMapped() {
      return this.platformAccesses.filter(
        (p) => p.itemKey == "co" || p.itemKey == "psysupport"
      );
    },
    positions() {
      if (!this.positionType) return [];

      return this.currentClient?.workPositions?.find(
        (wp) => wp.itemType == this.positionType
      )?.items;
    },
    identityErrors() {
      const errors = [];

      if (!this.$v.identity.$dirty) return errors;
      if (!this.$v.identity.required) errors.push("This field is required");
      if (!this.$v.identity.uniqueIdentity)
        errors.push("This identity already exists.");
      return errors;
    },
    usernameErrors() {
      const errors = [];

      if (!this.$v.username.$dirty) return errors;
      if (!this.$v.username.required) errors.push("This field is required");
      if (!this.$v.username.uniqueUsername)
        errors.push("This username already exists.");
      return errors;
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) errors.push("This field is required");
      if (!this.$v.email.email) errors.push("Must be a valid email");
      if (!this.$v.email.uniqueEmail) errors.push("This email already exists.");
      return errors;
    },
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !=
        JSON.stringify({
          platformAccess: this.platformAccess,
          canLogin: this.canLogin,
          ...(this.platformAccess == "co" && {
            isClientAdmin: this.isClientAdmin,
          }),
          identity: this.identity,
          firstName: this.firstName,
          lastName: this.lastName,
          username: this.username,
          email: this.email,
          position: this.position,
          nationality: this.nationality,
          language: this.language,
          mobile: this.mobile,
          dateOfBirth: this.birthDate,
          gender: this.gender,
          marital: this.marital,
          kids: this.kids,
        })
      );
    },
  },
  created() {
    if (this.isEdit) {
      this.platformAccess = this.currentAdmin.platformAccessId;
      this.canLogin = this.currentAdmin.canLogin;
      this.isClientAdmin = this.currentAdmin.isClientAdmin;
      this.identity = this.currentAdmin.identityNumber;
      this.firstName = this.currentAdmin.firstName;
      this.lastName = this.currentAdmin.lastName;
      this.username = this.currentAdmin.userName;
      this.email = this.currentAdmin.userEmail;
      this.positionType = this.currentAdmin.userTypeId;
      this.position = this.currentAdmin.positionId;
      this.nationality = this.currentAdmin.nationalityId;
      this.language = this.currentAdmin.languageId;
      this.mobile = this.currentAdmin.mobile;
      this.birthDate = this.currentAdmin.dateOfBirth
        ? this.dayjs
            .utc(this.currentAdmin.dateOfBirth)
            .local()
            .toISOString(true)
            .substr(0, 10)
        : null;
      this.gender = this.currentAdmin.gender;
      this.marital = this.currentAdmin.marital;
      this.kids =
        this.currentAdmin.kids != null && this.currentAdmin.kids != undefined
          ? this.currentAdmin.kids
          : null;
    }

    this.initialData = {
      platformAccess: this.platformAccess,
      canLogin: this.canLogin,
      ...(this.platformAccess == "co" && {
        isClientAdmin: this.isClientAdmin,
      }),
      identity: this.identity,
      firstName: this.firstName,
      lastName: this.lastName,
      username: this.username,
      email: this.email,
      position: this.position,
      nationality: this.nationality,
      language: this.language,
      mobile: this.mobile,
      dateOfBirth: this.birthDate,
      gender: this.gender,
      marital: this.marital,
      kids: this.kids,
    };
  },
  methods: {
    ...mapActions(["addEditAdmin"]),
    ...mapMutations(["toggleDiscardDialog"]),
    async submit() {
      this.identityExists = false;
      this.usernameExists = false;
      this.emailExists = false;

      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        await this.addEditAdmin({
          isEdit: this.isEdit,
          clientId: this.currentClient.clientId,
          ...(this.isEdit && { clientUserId: this.currentAdmin.clientUserId }),
          platformAccessId: this.platformAccess,
          canLogin: this.canLogin,
          ...(this.platformAccess == "co" && {
            isClientAdmin: this.isClientAdmin,
          }),
          identityNumber: this.identity,
          firstName: this.firstName,
          lastName: this.lastName,
          username: this.username,
          userEmail: this.email,
          positionId: this.position,
          nationalityId: this.nationality,
          languageId: this.language,
          mobile: this.mobile,
          dateOfBirth: this.birthDate
            ? this.dayjs.utc(this.birthDate).toISOString()
            : null,
          gender: this.gender,
          marital: this.marital,
          kids: Number(this.kids),
        });

        this.toggleDiscardDialog({
          allowLeave: true,
        });

        this.$router.push({
          path: `/superadmin/clients/${this.$route.params.clientId}/admins`,
        });
      } catch (e) {
        if (e.response.data.error.errorCode == 20403) {
          this.identityExists = true;
        }

        if (e.response.data.error.errorCode == 20402) {
          this.usernameExists = true;
        }

        if (e.response.data.error.errorCode == 20412) {
          this.emailExists = true;
        }
      }

      this.loader = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    //DISCARD DIALOG LOGIC
    if (this.hasChanges && !this.discardDialog.allowLeave) {
      this.toggleDiscardDialog({
        open: true,
        to: to?.path,
      });

      next(false);
    } else {
      this.toggleDiscardDialog({
        allowLeave: false,
        to: null,
      });

      next();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
