<template>
  <div>
    <v-menu max-width="290" offset-y bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed v-bind="attrs" v-on="on">
          {{ labelPerPage }} per page:
          {{
            currentPerPage == 1000 || currentPerPage == -1
              ? "All"
              : currentPerPage
          }}
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          v-for="(option, index) in options"
          :key="index"
          link
          @click="
            option.setPerPage({
              page: 1,
              itemsPerPage: option.itemsPerPage,
            })
          "
        >
          <v-list-item-title>{{
            option.itemsPerPage == -1 ? "All" : option.itemsPerPage
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["labelPerPage", "currentPerPage", "options"],
};
</script>

<style lang="scss" scoped>
</style>
