var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-btn',{staticClass:"white--text align-self-end",attrs:{"color":"filyellow","depressed":"","to":("/superadmin/clients/" + (_vm.$route.params.clientId) + "/admins/new")}},[_vm._v("Add User")]),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"align-self-start flex-grow-0 mr-auto",attrs:{"outlined":"","dense":"","label":"Search","prepend-inner-icon":"mdi-magnify","hint":"Search by Identity, Last Name, First Name, Email, Mobile","persistent-hint":"","value":_vm.adminsParams.search,"readonly":_vm.loader,"clearable":""},on:{"input":function($event){return _vm.setAdminsParams({ search: $event })}}}),_c('ShowPerPage',{staticClass:"mr-2",attrs:{"currentPerPage":_vm.adminsParams.itemsPerPage,"options":_vm.showPerPageOptions}}),_c('FilterBy',{staticClass:"mr-2",attrs:{"fields":_vm.filterFields,"loader":_vm.loader},on:{"filterChange":_vm.onFilterChange}}),_c('ColumnVisibility',{attrs:{"headers":_vm.headers,"grid":"adminsList"},on:{"visibilityChanged":_vm.onVisibilityChanged,"initLoaderChanged":_vm.onVisibilityLoaderChanged}})],1),_c('v-data-table',{staticClass:"clickable mb-4",attrs:{"headers":_vm.visibleHeaders,"items":_vm.admins.results,"server-items-length":_vm.admins.rowCount,"checkbox-color":"primary","hide-default-footer":"","options":_vm.options,"loading":_vm.loader,"item-class":_vm.clientAdminRowClass},on:{"update:options":[function($event){_vm.options=$event},function($event){return _vm.onGetAdmins(true)}],"click:row":function($event){!_vm.globalLoader &&
        _vm.$router.push({
          path: ("/superadmin/clients/" + (_vm.$route.params.clientId) + "/admins/" + ($event.clientUserId)),
        })}},scopedSlots:_vm._u([{key:"item.creationDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.creationDate ? _vm.dayjs.utc(item.creationDate).local().format("DD/MM/YYYY") : "-")+" ")]}},{key:"item.dateOfBirth",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.dateOfBirth ? _vm.dayjs.utc(item.dateOfBirth).local().format("DD/MM/YYYY") : "-")+" ")]}},{key:"item.userEmail",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.userEmail ? item.userEmail : "-")+" ")]}},{key:"item.gender",fn:function(ref){
        var item = ref.item;
return [(item.gender == 'm')?_c('span',[_vm._v("Male")]):(item.gender == 'f')?_c('span',[_vm._v("Female")]):(item.gender == 'o')?_c('span',[_vm._v("Other")]):_c('span',[_vm._v("-")])]}},{key:"item.canLogin",fn:function(ref){
        var item = ref.item;
return [(item.canLogin)?_c('div',{staticClass:"bullet small filgreen"}):_c('div',{staticClass:"bullet small filred"})]}},{key:"item.marital",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.marital ? item.marital.charAt(0).toUpperCase() + item.marital.slice(1) : "-")+" ")]}},{key:"item.mobile",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.mobile ? item.mobile : "-")+" ")]}},{key:"item.delete",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('v-menu',{ref:("deleteMenu" + index),attrs:{"top":"","left":"","close-on-content-click":false,"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","depressed":"","x-small":"","disabled":item.isClientAdmin}},'v-btn',attrs,false),on),[_vm._v("Delete")])]}}],null,true)},[_c('div',{staticClass:"white pa-5"},[_c('div',{staticClass:"body-2 mb-4 error--text"},[_vm._v(" Are you sure you want to delete this user? ")]),_c('v-btn',{attrs:{"small":"","depressed":"","color":"error","loading":_vm.deleteUserLoader == item.clientUserId},on:{"click":function($event){return _vm.onDeleteUser(item, index)}}},[_vm._v("Delete")])],1)])]}}],null,true)}),(_vm.admins.pageCount)?_c('v-pagination',{staticClass:"mt-auto",attrs:{"length":_vm.admins.pageCount},model:{value:(_vm.adminsParams.page),callback:function ($$v) {_vm.$set(_vm.adminsParams, "page", $$v)},expression:"adminsParams.page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }