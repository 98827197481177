<template>
  <div class="component-wrapper px-5 pt-5 grey lighten-3 d-flex flex-column">
    <div class="wrapper rounded flex-grow-1 d-flex flex-column pa-4">
      <div class="header mb-8">
        <v-btn
          class="back-btn"
          icon
          @click="
            $router.push({
              path: `/${
                role == 'allpsysup' ? 'allpsysup' : 'psysupport'
              }/cases`,
            })
          "
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <div class="subtitle-1 text-center">
          {{ currentSelfEval.firstName }} {{ currentSelfEval.lastName }} ({{
            dayjs
              .utc(currentSelfEval.ondate)
              .local()
              .format("DD/MM/YYYY HH:mm")
          }})
        </div>
      </div>

      <v-textarea
        label="Comments"
        readonly
        class="flex-grow-0 align-self-center comments"
        outlined
        dense
        rows="2"
        no-resize
        :value="currentSelfEval.comments"
      >
      </v-textarea>

      <v-row class="flex-grow-0">
        <v-col
          v-for="factor in currentSelfEval.factors"
          :key="factor.factorId"
          class="d-flex flex-column"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <div
            class="
              elevation-3
              rounded
              pa-2
              flex-grow-1
              d-flex
              flex-column
              align-center
              factor-card
            "
            @click="
              activeFactor && activeFactor.factorId == factor.factorId
                ? (activeFactor = null)
                : (activeFactor = factor)
            "
            :class="{
              active: activeFactor && activeFactor.factorId == factor.factorId,
            }"
          >
            <div class="result-wrapper mb-1">
              <v-progress-circular
                size="120"
                width="5"
                color="filyellow"
                :value="factor.percentage"
              ></v-progress-circular>

              <div class="result-text">
                <div class="result font-weight-bold primary--text text-h5">
                  {{ Math.round(factor.percentage) }}%
                </div>
              </div>
            </div>

            <div class="body-1 primary--text text-center mb-1">
              {{ factor.factorName }}
            </div>

            <div class="body-2 text-center">
              {{ factor.factorDescription }}
            </div>
          </div>
        </v-col>
      </v-row>

      <div class="mt-12" v-if="activeFactor">
        <div class="text-h6 text-center mb-6">
          Intervetions ({{ activeFactor.factorName }})
        </div>

        <div v-html="activeFactor.interventionHtml"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      activeFactor: null,
    };
  },
  computed: {
    ...mapState({
      currentSelfEval: (state) => state.psysupport.currentSelfEval,
      role: (state) => state.auth.role,
    }),
  },
  methods: {
    ...mapMutations(["setCurrentSelfEval"]),
  },
  destroyed() {
    this.setCurrentSelfEval(null);
  },
};
</script>

<style lang="scss" scoped>
.factor-card {
  cursor: pointer;
  transition: all 0.2s;

  &.active {
    background-color: #f5f5f5;
  }

  &:hover {
    background-color: #f5f5f5;
  }
}
.result-wrapper {
  position: relative;

  .result-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.comments {
  width: 450px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
</style>
