import { render, staticRenderFns } from "./MobileApps.vue?vue&type=template&id=7c1f5d86&scoped=true&"
import script from "./MobileApps.vue?vue&type=script&lang=js&"
export * from "./MobileApps.vue?vue&type=script&lang=js&"
import style0 from "./MobileApps.vue?vue&type=style&index=0&id=7c1f5d86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c1f5d86",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VDatePicker,VDialog,VIcon,VMenu,VTextField,VTextarea})
