import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import adminRoutes from "@/router/admin";
import superAdminRoutes from "@/router/superadmin";
import assigneeRoutes from "@/router/assignee";
import psysupportRoutes from "@/router/psysup";
import allpsysupportRoutes from "@/router/allpsysup";

import Login from "@/views/Login";
import ForgotPassword from "@/views/ForgotPassword";
import ForgotPasswordPage from "@/views/ForgotPasswordPage";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name != "NavigationDuplicated") {
      throw err;
    }
  });
};

const routes = [
  ...adminRoutes,
  ...superAdminRoutes,
  ...assigneeRoutes,
  ...psysupportRoutes,
  ...allpsysupportRoutes,
  {
    path: "/login",
    component: Login,
    meta: { requiresUnauth: true },
  },
  {
    path: "/forgot",
    component: ForgotPassword,
    meta: { requiresUnauth: true },
  },
  {
    path: "/resetpassword/:token",
    component: ForgotPasswordPage,
    meta: { requiresUnauth: true },
  },
  {
    path: "/setpassword/:token",
    component: ForgotPasswordPage,
    meta: { requiresUnauth: true },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const roles = ["co", "filistos", "assignee", "gm", "psysupport", "allpsysup"];

const rolesObj = {
  co: "",
  gm: "gm",
  assignee: "assignee",
  filistos: "superadmin",
  psysupport: "psysupport",
  allpsysup: "allpsysup",
};

router.beforeEach(async (to, from, next) => {
  if (to.path.includes("/assignee") && to.params.userAssignmentId) {
    try {
      await store.dispatch("getAssigneeAssignment", to.params.userAssignmentId);
    } catch (e) {
      console.log(e);
    }
  }

  let redirect;

  //AUTHENTICATED ROUTES
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      store.commit("setFailedNavigationTo", to.path);

      redirect = "/login";
    } else {
      //if role is not supported then logout and navigate to login
      if (!roles.includes(store.state.auth.role)) {
        await store.dispatch("logout");
        redirect = "login";
      } else {
        if (to.meta.role.includes(store.state.auth.role)) {
          //if(role is the same with the meta tag proceed)
          redirect = null;
        } else {
          redirect = `/${rolesObj[store.state.auth.role]}`; //else safely(cause of the above check) navigate him to his role
        }
      }
    }
  }
  //UN-AUTHENTICATED ROUTES
  else if (to.matched.some((record) => record.meta.requiresUnauth)) {
    if (store.getters.isLoggedIn) {
      redirect = "/";
    } else {
      redirect = null;
    }
  }

  await store.dispatch("init");

  if (redirect) {
    next(redirect);
  } else {
    next();
  }
});

export default router;
