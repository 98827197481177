<template>
  <div class="white dialog-wrapper scrollable-dialog">
    <div class="primary pa-3 d-flex align-start">
      <div class="text-h6 white--text" v-if="!isEdit">Add Users</div>

      <div class="white--text" v-if="isEdit && selectedUser">
        <div class="subtitle-1 font-weight-medium">
          {{ selectedUser.firstName }} {{ selectedUser.lastName }}
        </div>

        <div class="body-2">
          {{ selectedUser.identityNumber }}
        </div>
      </div>

      <v-btn
        icon
        small
        color="white"
        class="ml-auto"
        @click="$emit('closeDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <AssignmentReport v-if="showReport.open" />

    <template v-else>
      <div
        class="loader-wrapper d-flex align-center justify-center"
        v-if="loader"
      >
        <v-progress-circular
          size="80"
          width="4"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>

      <template v-else>
        <div class="d-flex flex-column pa-5">
          <div
            class="d-flex flex-column flex-md-row align-md-center align-start mb-4"
          >
            <v-select
              dense
              outlined
              hide-details
              label="Platform Access"
              :items="platformAccessesMapped"
              item-value="itemKey"
              item-text="itemValue"
              class="flex-grow-0 mb-3 mb-md-0 mr-md-3"
              v-model="platformAccess"
              @change="onPlatformAccessChanged"
            ></v-select>

            <v-select
              dense
              outlined
              hide-details
              label="Permissions"
              :items="permissions"
              item-value="key"
              item-text="value"
              class="flex-grow-0 mb-3 mb-md-0 mr-md-3"
              v-model="selectedPermissions"
              multiple
              chips
              deletable-chips
              small-chips
              v-if="platformAccess == 'gm' || platformAccess == 'psysupport'"
            ></v-select>

            <v-switch
              inset
              color="success"
              :label="isEnabled ? 'Enabled' : 'Disabled'"
              v-model="isEnabled"
              class="ma-0 pa-0 mr-4"
              dense
              hide-details
            ></v-switch>

            <v-switch
              inset
              color="success"
              label="Assessor"
              v-model="isAssessor"
              class="ma-0 pa-0 mr-4"
              dense
              hide-details
            ></v-switch>

            <v-switch
              inset
              color="success"
              label="Can Login"
              v-model="canLogin"
              class="ma-0 pa-0"
              dense
              hide-details
              v-if="platformAccess != 'basic'"
            ></v-switch>

            <div class="body-2 ml-auto" v-if="isEdit">
              Created By {{ selectedUser.createdBy }}
            </div>
          </div>

          <v-tabs v-model="tab" align-with-title show-arrows>
            <v-tab v-if="isEdit"> History </v-tab>

            <v-tab v-if="isEdit"> Assignments </v-tab>

            <v-tab
              :class="{
                'error--text': $v.$dirty && $v.form.personalDetails.$error,
              }"
            >
              Personal Details
            </v-tab>

            <v-tab> Contact Details </v-tab>

            <v-tab v-if="isEdit"> Group(s) Belonging </v-tab>

            <v-tab v-if="isEdit && selectedUser.trainingNeeds">
              Training Needs
            </v-tab>

            <v-tab v-if="isEdit">
              Filistos Proposed Trainings
            </v-tab>
          </v-tabs>
        </div>

        <div
          class="scrollable d-flex flex-column"
          :class="{ 'pa-4': tab != 0 || !isEdit }"
        >
          <v-tabs-items v-model="tab" id="single-user-tabs">
            <v-tab-item class="pt-1" v-if="isEdit">
              <History :selectedUser="selectedUser" />
            </v-tab-item>

            <v-tab-item class="pt-1" v-if="isEdit">
              <Assignments :selectedUser="selectedUser" />
            </v-tab-item>

            <v-tab-item class="pt-1" :eager="true">
              <PersonalDetails
                :platformAccess="platformAccess"
                :selectedUser="selectedUser"
                @personalDetailsChanged="onPersonalDetailsChanged"
                :v="$v"
              />
            </v-tab-item>

            <v-tab-item class="pt-1" :eager="true">
              <ContactDetails
                :platformAccess="platformAccess"
                :selectedUser="selectedUser"
                @contactDetailsChanged="onContactDetailsChanged"
                :v="$v"
              />
            </v-tab-item>

            <v-tab-item class="pt-1" :eager="true" v-if="isEdit">
              <UserGroups
                :selectedUser="selectedUser"
                @groupAdded="onGroupAdded"
              />
            </v-tab-item>

            <v-tab-item
              class="pt-1"
              :eager="true"
              v-if="isEdit && selectedUser.trainingNeeds"
            >
              <TrainingNeeds :selectedUser="selectedUser" />
            </v-tab-item>

            <v-tab-item class="pt-1" :eager="true" v-if="isEdit">
              <FilistosTrainingNeeds :selectedUser="selectedUser" />
            </v-tab-item>
          </v-tabs-items>
        </div>

        <div class="pa-4">
          <v-alert
            border="left"
            color="error"
            dark
            dense
            class="mb-4"
            v-if="$v.$dirty && $v.$invalid"
          >
            Some fields are invalid
          </v-alert>

          <div class="d-flex flex-column flex-sm-row align-center">
            <div
              class="body-2 mb-2 mb-sm-0"
              :class="{ 'error--text': $v.$dirty && $v.$invalid }"
            >
              * Required Fields
            </div>

            <div class="ml-sm-auto d-flex justify-center">
              <!-- <v-btn class="mr-2" depressed color="primary">
              <v-icon left>mdi-cloud-download</v-icon>
              CSV
            </v-btn>

            <v-btn depressed color="primary" class="mr-2">
              <v-icon left>mdi-cloud-download</v-icon>
              PDF
            </v-btn> -->

              <v-btn
                depressed
                :color="!isEdit || hasChanges ? 'success' : ''"
                @click="onSubmit"
                :loading="saveLoader"
                >{{ !isEdit || hasChanges ? "Save" : "Close" }}</v-btn
              >
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import History from "@/views/admin/users/user-dialog/History";
import Assignments from "@/views/admin/users/user-dialog/Assignments";
import PersonalDetails from "@/views/admin/users/user-dialog/PersonalDetails";
import ContactDetails from "@/views/admin/users/user-dialog/ContactDetails";
import UserGroups from "@/views/admin/users/user-dialog/UserGroups";
import TrainingNeeds from "@/views/admin/users/user-dialog/TrainingNeeds";
import FilistosTrainingNeeds from "@/views/admin/users/user-dialog/FilistosTrainingNeeds";
import AssignmentReport from "@/views/admin/assignment/assignment-dialog/report/AssignmentReport";

import { mapActions, mapState, mapMutations } from "vuex";
import { required, email, requiredIf } from "vuelidate/lib/validators";

const uniqueIdentity = function() {
  return !this.identityExists;
};

const uniqueUsername = function() {
  return !this.usernameExists;
};

const uniqueEmail = function() {
  return !this.emailExists;
};

export default {
  props: ["selectedUserId"],
  components: {
    History,
    Assignments,
    PersonalDetails,
    ContactDetails,
    UserGroups,
    AssignmentReport,
    TrainingNeeds,
    FilistosTrainingNeeds,
  },
  data() {
    return {
      tab: 0,
      selectedUser: null,
      loader: false,
      form: {
        personalDetails: {},
        contactDetails: {},
      },
      saveLoader: false,
      platformAccess: "basic",
      isEnabled: true,
      isAssessor: false,
      canLogin: true,
      permissions: [
        {
          key: "gmCanReadResults",
          value: "Read Test Results",
        },
        {
          key: "gmCanAccessElearning",
          value: "E-Learning certificates",
        },
        {
          key: "gmCanAccessForms",
          value: "Forms",
        },
        {
          key: "gmCanCreateMobileNotifications",
          value: "Create Mobile Notifications",
        },
      ],
      selectedPermissions: [],
      tempSelectedPermissions: [],
      initialData: null,
      identityExists: false,
      usernameExists: false,
      emailExists: false,
    };
  },
  validations: {
    form: {
      personalDetails: {
        identity: { required, uniqueIdentity },
        firstName: { required },
        lastName: { required },
        username: {
          required: requiredIf(function() {
            return this.platformAccess && this.platformAccess != "basic";
          }),
          uniqueUsername,
        },
        email: {
          required: requiredIf(function() {
            return this.platformAccess && this.platformAccess != "basic";
          }),
          email,
          uniqueEmail,
        },
        position: { required },
        nationality: { required },
        language: { required },
        groups: {
          required: requiredIf(function() {
            return !this.isEdit && this.role == "gm";
          }),
        },
      },
    },
  },
  computed: {
    ...mapState({
      platformAccesses: (state) => state.base.lists.platformAccesses,
      showReport: (state) => state.assignments.showReport,
      role: (state) => state.auth.role,
      info: (state) => state.base.info,
    }),
    platformAccessesMapped() {
      if (this.role == "filistos") return this.platformAccesses;

      return this.platformAccesses.filter(
        (p) => p.itemKey == "basic" || p.itemKey == "gm"
      );
    },
    isEdit() {
      return !!this.selectedUserId;
    },
    hasChanges() {
      let initial = Object.fromEntries(
        Object.entries({
          ...this.initialData,
        }).filter(([_, v]) => v)
      ); //initial data without null props

      let current = Object.fromEntries(
        Object.entries({
          ...this.form.personalDetails,
          ...this.form.contactDetails,
          platformAccess: this.platformAccess,
          selectedPermissions: [...this.selectedPermissions].sort(),
          isEnabled: this.isEnabled,
          isAssessor: this.isAssessor,
          canLogin: this.canLogin,
        }).filter(([_, v]) => v)
      ); //current data without null props

      return JSON.stringify(initial) != JSON.stringify(current);
    },
  },
  async created() {
    if (this.isEdit) {
      await this.getSelectedUser();

      this.initialData = {
        ...this.form.personalDetails,
        ...this.form.contactDetails,
        platformAccess: this.platformAccess,
        selectedPermissions: [...this.selectedPermissions].sort(),
        isEnabled: this.isEnabled,
        isAssessor: this.isAssessor,
        canLogin: this.canLogin,
      };
    }
  },
  mounted() {
    if (!this.isEdit) {
      this.initialData = {
        ...this.form.personalDetails,
        ...this.form.contactDetails,
        platformAccess: this.platformAccess,
        selectedPermissions: [...this.selectedPermissions].sort(),
        isEnabled: this.isEnabled,
        isAssessor: this.isAssessor,
        canLogin: this.canLogin,
      };
    }
  },
  methods: {
    ...mapMutations(["clearAssignmentDialogState"]),
    ...mapActions(["getUserById", "addUser", "editUser"]),
    onPersonalDetailsChanged(personalDetails) {
      this.form.personalDetails = {
        ...personalDetails,
      };
    },
    onContactDetailsChanged(contactDetails) {
      this.form.contactDetails = {
        ...contactDetails,
      };
    },
    async getSelectedUser() {
      this.loader = true;

      try {
        this.selectedUser = await this.getUserById(this.selectedUserId);

        this.platformAccess = this.selectedUser.platformAccessId;
        this.isEnabled = this.selectedUser.canGetAssignments;
        this.isAssessor = this.selectedUser.isAssessor;
        this.canLogin = this.selectedUser.canLogin;

        const selectedPermissions = {
          gmCanReadResults: this.selectedUser.gmCanReadResults,
          gmCanAccessElearning: this.selectedUser.gmCanAccessElearning,
          gmCanAccessForms: this.selectedUser.gmCanAccessForms,
          gmCanCreateMobileNotifications: this.selectedUser
            .gmCanCreateMobileNotifications,
        };

        this.selectedPermissions = [];

        Object.keys(selectedPermissions).forEach((permission) => {
          if (selectedPermissions[permission])
            this.selectedPermissions.push(permission);
        });
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    onGroupAdded() {
      this.getSelectedUser();
    },
    onPlatformAccessChanged(event) {
      if (event == "co" || event == "basic") {
        this.tempSelectedPermissions = [...this.selectedPermissions];
        this.selectedPermissions = [];
      }

      if (event == "gm" || event == "psysupport") {
        this.selectedPermissions = [...this.tempSelectedPermissions];
      }
    },
    async onSubmit() {
      if (this.isEdit && !this.hasChanges) return this.$emit("closeDialog");

      this.identityExists = false;
      this.usernameExists = false;
      this.emailExists = false;

      this.$v.$touch();

      if (this.$v.$invalid) return;

      try {
        this.saveLoader = true;

        let initialPermissions = {
          gmCanReadResults: false,
          gmCanAccessElearning: false,
          gmCanAccessForms: false,
          gmCanCreateMobileNotifications: false,
        };

        let mappedSelectedPermissions = {};

        if (
          this.platformAccess == "gm" ||
          this.platformAccess == "psysupport"
        ) {
          mappedSelectedPermissions = this.selectedPermissions.reduce(
            (a, v) => ({ ...a, [v]: true }),
            {}
          );
        }

        if (!this.isEdit) {
          await this.addUser({
            platformAccessId: this.platformAccess,
            ...initialPermissions,
            ...mappedSelectedPermissions,
            canGetAssignments: this.isEnabled,
            isAssessor: this.isAssessor,
            canLogin: this.canLogin,
            //PERSONAL DETAILS
            identityNumber: this.form.personalDetails.identity,
            firstName: this.form.personalDetails.firstName,
            lastName: this.form.personalDetails.lastName,
            username: this.form.personalDetails.username,
            userEmail: this.form.personalDetails.email,
            positionId: this.form.personalDetails.position,
            ...(!this.isEdit &&
              this.role == "gm" && {
                addInGroupIds: this.form.personalDetails.groups,
              }),
            nationalityId: this.form.personalDetails.nationality,
            languageId: this.form.personalDetails.language,
            dateOfBirth: this.form.personalDetails.birthDate
              ? this.dayjs
                  .utc(this.form.personalDetails.birthDate)
                  .toISOString()
              : null,
            gender: this.form.personalDetails.gender,
            marital: this.form.personalDetails.maritalStatus,
            kids: Number(this.form.personalDetails.kids),
            //CONTACT DETAILS
            addressStreet: this.form.contactDetails.street,
            addressStreetNumber: this.form.contactDetails.streetNumber,
            addressCity: this.form.contactDetails.city,
            addressRegion: this.form.contactDetails.region,
            addressCountryId: this.form.contactDetails.country,
            addressZIP: this.form.contactDetails.zipCode,
            mobile: this.form.contactDetails.mobile,
            phone: this.form.contactDetails.phone,
          });
        } else {
          await this.editUser({
            clientUserId: this.selectedUserId,
            platformAccessId: this.platformAccess,
            ...initialPermissions,
            ...mappedSelectedPermissions,
            canGetAssignments: this.isEnabled,
            isAssessor: this.isAssessor,
            canLogin: this.canLogin,
            //PERSONAL DETAILS
            identityNumber: this.form.personalDetails.identity,
            firstName: this.form.personalDetails.firstName,
            lastName: this.form.personalDetails.lastName,
            username: this.form.personalDetails.username,
            userEmail: this.form.personalDetails.email,
            positionId: this.form.personalDetails.position,
            nationalityId: this.form.personalDetails.nationality,
            languageId: this.form.personalDetails.language,
            dateOfBirth: this.form.personalDetails.birthDate
              ? this.dayjs
                  .utc(this.form.personalDetails.birthDate)
                  .toISOString()
              : null,
            gender: this.form.personalDetails.gender,
            marital: this.form.personalDetails.maritalStatus,
            kids: Number(this.form.personalDetails.kids),
            //CONTACT DETAILS
            addressStreet: this.form.contactDetails.street,
            addressStreetNumber: this.form.contactDetails.streetNumber,
            addressCity: this.form.contactDetails.city,
            addressRegion: this.form.contactDetails.region,
            addressCountryId: this.form.contactDetails.country,
            addressZIP: this.form.contactDetails.zipCode,
            mobile: this.form.contactDetails.mobile,
            phone: this.form.contactDetails.phone,
          });
        }

        this.$emit("closeDialog");
      } catch (e) {
        if (e.response.data.error.errorCode == 20403) {
          this.identityExists = true;
        }

        if (e.response.data.error.errorCode == 20402) {
          this.usernameExists = true;
        }

        if (e.response.data.error.errorCode == 20412) {
          this.emailExists = true;
        }
      }

      this.saveLoader = false;
    },
  },
  destroyed() {
    this.clearAssignmentDialogState();
  },
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
  flex: 1;
}
</style>
