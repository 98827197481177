<template>
  <div class="flex-grow-1 px-4 grey lighten-5 rounded d-flex flex-column">
    <v-tabs
      v-model="tab"
      class="mb-4 flex-grow-0"
      background-color="grey lighten-5"
      color="filyellow"
    >
      <v-tab> Question Data </v-tab>

      <v-tab> Question Factors </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="scrollable-tabs">
      <v-tab-item>
        <QuestionData />
      </v-tab-item>

      <v-tab-item>
        <QuestionFactors />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import QuestionData from "@/views/superadmin/questionnaires/questionnaire/sections/questions/QuestionData.vue";
import QuestionFactors from "@/views/superadmin/questionnaires/questionnaire/sections/questions/QuestionFactors.vue";

export default {
  components: {
    QuestionData,
    QuestionFactors,
  },
  data() {
    return {
      tab: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
