<template>
  <div>
    <v-app-bar app color="white" :dense="isLoggedIn" :clipped-left="isLoggedIn">
      <div class="flex-grow-1 d-flex">
        <img
          class="logo"
          :class="{ pointer: isLoggedIn, 'mx-auto': !isLoggedIn }"
          src="@/assets/logo.png"
          @click="
            isLoggedIn &&
              $router.push({
                path: `/${role == 'filistos' ? 'filistos' : ''}`,
              })
          "
        />

        <div class="ml-auto d-flex align-center" v-if="isLoggedIn">
          <v-btn
            icon
            small
            :ripple="false"
            color="filyellow"
            class="ml-4"
            v-if="$vuetify.breakpoint.smAndDown"
            @click="toggleSidebar(!sidebar)"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>

          <v-menu
            offset-y
            v-model="menu"
            v-if="$vuetify.breakpoint.mdAndUp && info"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex align-center"
                v-ripple
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2">mdi-chevron-down</v-icon>

                <div class="d-flex flex-column mr-2">
                  <div class="body-2 font-weight-medium">
                    {{ info.companyName }}
                  </div>
                  <div class="caption">{{ info.loggedOnUser }}</div>
                </div>

                <img :src="`${baseUrl}/${info.logoUrl}`" class="client-logo" />
              </div>
            </template>

            <div class="white">
              <v-list dense class="pa-0">
                <!-- <v-list-item link>
                  <v-list-item-icon>
                    <v-icon color="filyellow">mdi-file-word</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Quick Manual</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item link>
                  <v-list-item-icon>
                    <v-icon color="filyellow">mdi-fullscreen</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Fullscreen</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->

                <v-list-item
                  link
                  @click="
                    toggleDialog({
                      open: true,
                      component: 'Licenses',
                      width: '800px',
                    })
                  "
                  v-if="role == 'co' || role == 'gm'"
                >
                  <v-list-item-icon>
                    <v-icon color="filyellow">mdi-card-account-details</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>My Licenses</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  link
                  @click="
                    toggleDialog({
                      open: true,
                      component: 'EndUserLicense',
                      width: '800px',
                    })
                  "
                  v-if="role == 'co' || role == 'gm' || role == 'psysupport'"
                >
                  <v-list-item-icon>
                    <v-icon color="filyellow">mdi-license</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      >End User License Agreement</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item link @click="dialog = true">
                  <v-list-item-icon>
                    <v-icon color="filyellow">mdi-logout</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Sign Out</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-menu>
        </div>
      </div>

      <v-dialog v-model="dialog" max-width="500px">
        <div class="white pa-5">
          <div class="d-flex align-center mb-2">
            <v-icon color="error" class="mr-2">mdi-logout</v-icon>

            <div class="text-h6">Logout ?</div>
          </div>

          <div class="body-2 mb-4">
            You can improve your security further after logging out by closing
            this opened browser
          </div>

          <div class="d-flex">
            <v-btn depressed class="mr-2" @click="onLogout">yes</v-btn>

            <v-btn depressed @click="dialog = false">no</v-btn>
          </div>
        </div>
      </v-dialog>
    </v-app-bar>

    <SidebarWrapper v-if="isLoggedIn" @openSignoutDialog="dialog = true" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import SidebarWrapper from "@/components/sidebar/SidebarWrapper";

export default {
  components: {
    SidebarWrapper,
  },
  data() {
    return {
      menu: false,
      dialog: false,
      baseUrl: process.env.VUE_APP_BASE_URL.split("/api")[0],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapState({
      role: (state) => state.auth.role,
      sidebar: (state) => state.base.sidebar,
      info: (state) => state.base.info,
    }),
  },
  methods: {
    ...mapMutations(["toggleSidebar", "toggleDialog", "toggleDiscardDialog"]),
    ...mapActions(["logout"]),
    onLogout() {
      this.logout();
      this.dialog = false;
      this.toggleDiscardDialog({ allowLeave: true });
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 56px;

  @media only screen and (max-width: 959px) {
    height: 48px;
  }

  &.pointer {
    cursor: pointer;
  }
}

.v-toolbar--dense {
  .logo {
    height: 40px;
  }
}

.client-logo {
  height: 40px;
  width: 40px;
  object-fit: cover;
}
</style>
