var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper px-4 pt-4 grey lighten-3 d-flex flex-column"},[_c('div',{staticClass:"d-flex mb-4"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-puzzle")]),_c('div',{staticClass:"body-1 font-weight-medium"},[_vm._v("Personnel Matrix")])],1),_c('div',{staticClass:"wrapper rounded flex-grow-1 d-flex flex-column pa-4"},[_c('form',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-4"},[_c('div',{staticClass:"d-flex align-center mb-2"},[(_vm.role != 'psysupport' && _vm.role != 'allpsysup')?_c('v-menu',{attrs:{"max-width":"290","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-text-field',{staticClass:"mr-2",attrs:{"outlined":"","dense":"","hide-details":"","label":"Select Questionnaire","clearable":"","readonly":"","append-icon":"mdi-menu-down","value":_vm.selectedQuestionnaire
                      ? _vm.selectedQuestionnaire.questionnaireName
                      : null},on:{"click:clear":function($event){_vm.selectedQuestionnaire = null;
                    _vm.setFilters({
                      questionnaire: _vm.selectedQuestionnaire,
                      dateFrom: _vm.dateFrom,
                      positionType: _vm.positionType,
                      factorGroupIds: _vm.selectedFactorGroups,
                    });
                    _vm.isFetched(false);
                    _vm.$router.push('/crew-combinations');}}})],1)]}}],null,false,1891532502)},[_c('div',{staticClass:"white questionnaire-menu"},_vm._l((_vm.questionnaires),function(questionnaireType){return _c('div',{key:questionnaireType.questionnaireTypeId},[(questionnaireType.questionnaireTypeId == 'psycho')?_c('div',{staticClass:"pl-2"},_vm._l((questionnaireType.questionnaires),function(questionnaire){return _c('div',{key:questionnaire.questionnaireId},[_c('div',{staticClass:"body-2 py-1 px-2",class:{
                        'font-weight-medium':
                          questionnaire.subQuestionnaires &&
                          questionnaire.subQuestionnaires.length,
                        questionnaire:
                          !questionnaire.subQuestionnaires ||
                          !questionnaire.subQuestionnaires.length,
                      },on:{"click":function($event){(!questionnaire.subQuestionnaires ||
                          !questionnaire.subQuestionnaires.length) &&
                          (_vm.selectedQuestionnaire = questionnaire);
                        _vm.setQuestionnaire(questionnaire);
                        _vm.setFilters({
                          questionnaire: _vm.selectedQuestionnaire,
                          dateFrom: _vm.dateFrom,
                          positionType: _vm.positionType,
                          factorGroupIds: _vm.selectedFactorGroups,
                        });
                        _vm.isFetched(false);
                        _vm.$router.push('/crew-combinations');}}},[_vm._v(" "+_vm._s(questionnaire.questionnaireName)+" ")]),(
                        questionnaire.subQuestionnaires &&
                        questionnaire.subQuestionnaires.length
                      )?_c('div',{staticClass:"pl-4"},_vm._l((questionnaire.subQuestionnaires),function(subQuestionnaire){return _c('div',{key:subQuestionnaire.questionnaireId},[_c('div',{staticClass:"body-2 py-1 px-2 questionnaire",on:{"click":function($event){_vm.selectedQuestionnaire = subQuestionnaire;
                            _vm.setFilters({
                              questionnaire: _vm.selectedQuestionnaire,
                              dateFrom: _vm.dateFrom,
                              positionType: _vm.positionType,
                              factorGroupIds: _vm.selectedFactorGroups,
                            });
                            _vm.setQuestionnaire(subQuestionnaire);
                            _vm.isFetched(false);
                            _vm.$router.push('/crew-combinations');}}},[_c('span',{staticClass:"font-weight-bold mr-1"},[_vm._v("-")]),_vm._v(" "+_vm._s(subQuestionnaire.questionnaireName)+" ")])])}),0):_vm._e()])}),0):_vm._e()])}),0)]):_vm._e(),((_vm.role == 'co' || _vm.role == 'gm') && _vm.info.hasFactorGroups)?_c('v-select',{staticClass:"flex-grow-0 mr-2",attrs:{"label":"Factor Groups","outlined":"","dense":"","hide-details":"","items":_vm.factorGroups,"item-value":"factorGroupId","item-text":"factorGroupName","clearable":"","disabled":!_vm.selectedQuestionnaire,"loading":_vm.factorGroupLoader,"multiple":"","chips":"","deletable-chips":"","scrollable":""},model:{value:(_vm.selectedFactorGroups),callback:function ($$v) {_vm.selectedFactorGroups=$$v},expression:"selectedFactorGroups"}}):_vm._e(),_c('v-menu',{ref:"dateFrom",attrs:{"max-width":"290","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"flex-grow-0 mr-2",attrs:{"value":_vm.dateFrom ? _vm.dayjs(_vm.dateFrom).format('DD/MM/YYYY') : '',"dense":"","prepend-inner-icon":"mdi-calendar","outlined":"","label":"Evaluation From","readonly":"","clearable":"","hide-details":""},on:{"click:clear":function($event){_vm.dateFrom = null;
                  _vm.setFilters({
                    questionnaire: _vm.selectedQuestionnaire,
                    dateFrom: _vm.dateFrom,
                    positionType: _vm.positionType,
                    factorGroupIds: _vm.selectedFactorGroups,
                  });
                  _vm.isFetched(false);
                  _vm.$router.push('/crew-combinations');}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateFrom),callback:function ($$v) {_vm.menuDateFrom=$$v},expression:"menuDateFrom"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuDateFrom = false;
                _vm.setFilters({
                  questionnaire: _vm.selectedQuestionnaire,
                  dateFrom: _vm.dateFrom,
                  positionType: _vm.positionType,
                  factorGroupIds: _vm.selectedFactorGroups,
                });}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),(_vm.role != 'psysupport' && _vm.role != 'allpsysup')?_c('v-radio-group',{staticClass:"flex-grow-0 pa-0 ma-0 mr-2",attrs:{"dense":"","hide-details":""},on:{"change":function($event){return _vm.setFilters({
                questionnaire: _vm.selectedQuestionnaire,
                dateFrom: _vm.dateFrom,
                positionType: _vm.positionType,
                factorGroupIds: _vm.selectedFactorGroups,
              })}},model:{value:(_vm.positionType),callback:function ($$v) {_vm.positionType=$$v},expression:"positionType"}},_vm._l((_vm.userTypes),function(type){return _c('v-radio',{key:type.itemKey,staticClass:"mb-1",attrs:{"value":type.itemKey,"label":type.itemValue,"ripple":false}})}),1):_vm._e()],1)]),_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","color":"filyellow","disabled":!_vm.selectedQuestionnaire || !_vm.dateFrom || !_vm.positionType},on:{"click":_vm.fetchDepartments}},[_vm._v("Get Departments")])],1),(_vm.loader)?_c('v-progress-circular',{staticClass:"ma-auto",attrs:{"size":"80","width":"4","indeterminate":"","color":"primary"}}):[(
          _vm.fetched &&
          _vm.departments.results.length > 0 &&
          _vm.selectedQuestionnaire != null &&
          _vm.dateFrom != null &&
          _vm.positionType != null
        )?_c('v-tabs',{staticClass:"flex-grow-0"},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.departmentTitles),function(tab){return _c('v-tab',{key:tab.departmentId,attrs:{"to":'/crew-combinations/' + tab.departmentId.toLowerCase()}},[_vm._v(" "+_vm._s(tab.departmentName)+" ")])})],2):_vm._e(),(
          _vm.selectedQuestionnaire != null &&
          _vm.dateFrom != null &&
          _vm.positionType != null
        )?_c('router-view'):_vm._e(),(_vm.fetched && _vm.departments.results.length == 0)?_c('span',{attrs:{"justify":"start","align":"start"}},[_vm._v("No data available")]):_vm._e()]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }