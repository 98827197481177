<template>
  <div class="wrapper rounded flex-grow-1 d-flex flex-column">
    <div class="d-flex align-start mb-2">
      <v-menu
        offset-y
        v-if="selectedUsers.length"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            depressed
            class="mb-4 mb-md-0 mr-md-4"
            v-bind="attrs"
            v-on="on"
          >
            Actions
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            link
            @click="dialog = { open: true, component: 'InviteToSelfEval' }"
          >
            <v-list-item-title>Enable Self Support</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            @click="dialog = { open: true, component: 'DisableSelfEval' }"
          >
            <v-list-item-title>Disable Self Support </v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            @click="dialog = { open: true, component: 'InviteToHub' }"
          >
            <v-list-item-title>Enable CASE</v-list-item-title>
          </v-list-item>

          <v-list-item link @click="onExportExcel">
            <v-list-item-title>Export Excel</v-list-item-title>

            <v-progress-circular
              size="20"
              width="2"
              indeterminate
              color="primary"
              v-if="exportExcelLoader"
            ></v-progress-circular>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-text-field
        outlined
        dense
        label="Search"
        class="mr-2 flex-grow-0"
        prepend-inner-icon="mdi-magnify"
        hint="Search by Identity, Last Name, First Name, Email, Mobile"
        persistent-hint
        :value="usersParams.search"
        @input="USERSNEW_setUsersParams({ search: $event })"
        :readonly="loader"
        clearable
      ></v-text-field>

      <div class="d-flex flex-column flex-sm-row ml-md-auto mb-2 mb-md-0">
        <ShowPerPage
          class="mr-2"
          :currentPerPage="usersParams.itemsPerPage"
          :options="showPerPageOptions"
        />

        <FilterBy
          class="mr-sm-4 mb-4 mb-sm-0"
          :fields="filterFields"
          :loader="loader"
          @filterChange="onFilterChange"
        />

        <ColumnVisibility
          :headers="headers"
          @visibilityChanged="onVisibilityChanged"
          grid="usersList"
        />
      </div>
    </div>

    <v-data-table
      :headers="visibleHeaders"
      :items="users.results"
      :server-items-length="users.rowCount"
      class="mb-2 clickable"
      show-select
      checkbox-color="primary"
      v-model="selectedUsers"
      :loading="loader"
      item-key="clientUserId"
      :options.sync="options"
      @update:options="onGetUsers(true)"
      hide-default-footer
      @click:row="
        !globalLoader &&
          $router.push({
            path: `/superadmin/clients/${$route.params.clientId}/users/${$event.clientUserId}`,
          })
      "
    >
      <template v-slot:[`item.creationDate`]="{ item }">
        {{
          item.creationDate
            ? dayjs(item.creationDate).format("DD/MM/YYYY")
            : "-"
        }}
      </template>

      <template v-slot:[`item.dateOfBirth`]="{ item }">
        {{
          item.dateOfBirth ? dayjs(item.dateOfBirth).format("DD/MM/YYYY") : "-"
        }}
      </template>

      <template v-slot:[`item.userEmail`]="{ item }">
        {{ item.userEmail ? item.userEmail : "-" }}
      </template>

      <template v-slot:[`item.gender`]="{ item }">
        <span v-if="item.gender == 'm'">Male</span>
        <span v-else-if="item.gender == 'f'">Female</span>
        <span v-else-if="item.gender == 'o'">Other</span>
        <span v-else>-</span>
      </template>

      <template v-slot:[`item.marital`]="{ item }">
        {{
          item.marital
            ? item.marital.charAt(0).toUpperCase() + item.marital.slice(1)
            : "-"
        }}
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        {{ item.mobile ? item.mobile : "-" }}
      </template>

      <template v-slot:[`item.canGetAssignments`]="{ item }">
        <div class="bullet small filgreen" v-if="item.canGetAssignments"></div>

        <div class="bullet small filred" v-else></div>
      </template>

      <template v-slot:[`item.onTheGoPin`]="{ item }">
        <v-menu
          offset-x
          offset-y
          top
          left
          :close-on-content-click="false"
          v-if="item.onTheGoPin"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" small> Show Pin </v-btn>
          </template>

          <div class="white py-1 px-2">
            <div class="subtitle-2">
              {{ item.onTheGoPin }}
            </div>
          </div>
        </v-menu>

        <div class="body-2" v-else>-</div>
      </template>

      <template v-slot:[`item.onTheGoSelfSupport`]="{ item }">
        <div class="bullet small filgreen" v-if="item.onTheGoSelfSupport"></div>

        <div class="bullet small filred" v-else></div>
      </template>

      <template v-slot:[`item.hubSupport`]="{ item }">
        <div class="bullet small filgreen" v-if="item.hubSupport"></div>

        <div class="bullet small filred" v-else></div>
      </template>

      <template v-slot:[`item.onTheGoInvitationStatus`]="{ item }">
        <div class="d-flex justify-start">
          <div
            class="px-1 rounded"
            :class="{
              'success white--text': item.onTheGoInvitationStatus == 'Click',
              'warning lighten-2 white--text':
                item.onTheGoInvitationStatus == 'Open' ||
                item.onTheGoInvitationStatus == 'Delivery',
              'warning white--text':
                item.onTheGoInvitationStatus == 'Sent' ||
                item.onTheGoInvitationStatus == 'Delivery' ||
                item.onTheGoInvitationStatus == 'Dispatched',
              'error white--text':
                item.onTheGoInvitationStatus == 'Bounce' ||
                item.onTheGoInvitationStatus == 'DispatchError',
              'primary white--text': item.onTheGoInvitationStatus == 'Login',
            }"
          >
            {{
              item.onTheGoInvitationStatus ? item.onTheGoInvitationStatus : "-"
            }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.delete`]="{ item, index }">
        <v-menu
          top
          left
          :close-on-content-click="false"
          offset-y
          offset-x
          :ref="`deleteMenu${index}`"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              depressed
              x-small
              v-bind="attrs"
              v-on="on"
              :disabled="Boolean(item.numAssignments)"
              >Delete</v-btn
            >
          </template>

          <div class="white pa-5">
            <div class="body-2 mb-4 error--text">
              Are you sure you want to delete this user?
            </div>

            <v-btn
              small
              depressed
              color="error"
              @click="onDeleteUser(item, index)"
              :loading="deleteUserLoader == item.clientUserId"
              >Delete</v-btn
            >
          </div>
        </v-menu>
      </template>
    </v-data-table>

    <v-pagination
      class="mt-auto"
      :length="users.pageCount"
      v-model="usersParams.page"
      :disabled="loader"
      v-if="users.pageCount"
    ></v-pagination>

    <v-dialog
      v-model="dialog.open"
      :max-width="dialog.component == 'delete' ? '500px' : '1250px'"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly && dialog.component != 'delete'"
    >
      <component
        :is="dialog.component"
        @closeDialog="onDialogClosed"
        @unselectUser="onUnselectUser"
        :selectedUsers="selectedUsers"
        :selectedUserId="dialog.user"
        :isEdit="false"
      ></component>
    </v-dialog>
  </div>
</template>

<script>
import DisableSelfEval from "@/views/admin/users-new/dialogs/DisableSelfEval";

import InviteToSelfEval from "@/views/admin/users-new/dialogs/InviteToSelfEval";
import InviteToHub from "@/views/admin/users-new/dialogs/InviteToHub";
import ColumnVisibility from "@/components/ColumnVisibility";
import FilterBy from "@/components/FilterBy";
import ShowPerPage from "@/components/ShowPerPage";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: ["selectedGroups", "union"],
  components: {
    DisableSelfEval,
    InviteToSelfEval,
    InviteToHub,
    ColumnVisibility,
    FilterBy,
    ShowPerPage,
  },
  data() {
    return {
      headers: [
        { text: "Creation Date", value: "creationDate", sortable: true },
        { text: "Identity", value: "identityNumber", sortable: true },
        { text: "Last Name", value: "lastName", sortable: true },
        { text: "First Name", value: "firstName", sortable: true },
        { text: "Birth Date", value: "dateOfBirth", sortable: true },
        { text: "Email", value: "userEmail", sortable: true },
        { text: "Gender", value: "gender", sortable: true },
        { text: "Nationality", value: "nationalityName", sortable: true },
        { text: "Language", value: "languageName", sortable: true },
        { text: "Role", value: "platformAccessName", sortable: true },
        { text: "Position", value: "positionName", sortable: true },
        { text: "Marital Status", value: "marital" },
        { text: "Mobile", value: "mobile", sortable: false },
        { text: "Enabled", value: "canGetAssignments" },
        { text: "OnTheGo Pin", value: "onTheGoPin", sortable: false },
        {
          text: "OnTheGo Self Support",
          value: "onTheGoSelfSupport",
        },
        {
          text: "OnTheGo Case ",
          value: "hubSupport",
        },
        // { text: "CASE", value: "hubSupport" },
        {
          text: "OnTheGo Email",
          value: "onTheGoInvitationStatus",
          sortable: false,
        },
        { text: "Delete", value: "delete", sortable: false },
      ],
      loader: false,
      timeout: null,
      isFirstCall: true,
      showPerPageOptions: [],
      visibleHeaders: [],
      dialog: {
        open: false,
        component: null,
      },
      selectedUsers: [],
      visibilityLoader: false,
      deleteUserLoader: false,
      exportExcelLoader: false,
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.usersNew.users,
      usersParams: (state) => state.usersNew.usersParams,
      globalLoader: (state) => state.base.globalLoader,
      countries: (state) => state.base.lists.countries,
      platformAccesses: (state) => state.base.lists.platformAccesses,
      languages: (state) => state.base.lists.languages,
      genders: (state) => state.base.lists.genders,
      userTypes: (state) => state.base.lists.userTypes,
      emailStatuses: (state) => state.base.lists.emailStatuses,
    }),
    options: {
      get() {
        const { itemsPerPage, page, sortBy, sortDesc } = this.usersParams;

        return {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        };
      },
      set(value) {
        let { itemsPerPage, page, sortBy, sortDesc } = value;

        this.USERSNEW_setUsersParams({
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
        });
      },
    },
    filterFields() {
      return [
        {
          key: "nationalityId",
          value: "Nationality",
          values: this.countries,
          selectedValue: this.usersParams.nationalityId,
        },
        {
          key: "platformAccessId",
          value: "Role",
          values: this.platformAccesses,
          selectedValue: this.usersParams.platformAccessId,
        },
        {
          key: "languageId",
          value: "Language",
          values: this.languages,
          selectedValue: this.usersParams.languageId,
        },
        {
          key: "gender",
          value: "Gender",
          values: this.genders,
          selectedValue: this.usersParams.gender,
        },
        {
          key: "onTheGoInvitationStatus",
          value: "OnTheGo Email",
          values: this.emailStatuses,
          selectedValue: this.usersParams.onTheGoInvitationStatus,
        },
        {
          key: "position",
          value: "Position",
          checkboxValues: this.userTypes,
          selectedValue: this.usersParams.position,
        },
      ];
    },
  },
  created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.USERSNEW_setUsersParams,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.USERSNEW_setUsersParams,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.USERSNEW_setUsersParams,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.USERSNEW_setUsersParams,
      },
    ];
  },
  methods: {
    ...mapActions([
      "USERSNEW_getUsers",
      "deleteUser",
      "USERSNEW_exportUsersExcel",
    ]),
    ...mapMutations([
      "USERSNEW_setUsersParams",
      "setForm",
      "togglePreselectedUsersBanner",
    ]),
    async onGetUsers(refresh) {
      if (this.isFirstCall) {
        this.isFirstCall = false;
        return;
      }

      this.loader = true;

      try {
        await this.USERSNEW_getUsers(refresh);
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
    onFilterChange(e) {
      this.USERSNEW_setUsersParams({
        [e.field]: e.value,
      });

      this.onGetUsers(true);
    },
    onVisibilityChanged(visibleHeaders) {
      this.visibleHeaders = visibleHeaders
        .filter((header) => header.visible)
        .map((header) => {
          return {
            text: header.text,
            value: header.value,
            ...(!header.sortable && {
              sortable: header.sortable,
            }),
          };
        });
    },
    onUnselectUser(index) {
      this.selectedUsers.splice(index, 1);
    },
    onDialogClosed(event) {
      if (event?.unselectUsers) {
        this.selectedUsers = [];

        this.USERSNEW_setUsersParams({
          itemsPerPage: 10,
        });

        this.onGetUsers();
      }

      this.dialog = { open: false, component: null };
    },
    async onDeleteUser(user, index) {
      this.deleteUserLoader = user.clientUserId;

      try {
        await this.deleteUser(user);
        this.onGetUsers();
      } catch (e) {
        console.log(e);
      }

      this.$refs[`deleteMenu${index}`].save();
      this.deleteUserLoader = false;
    },
    async onExportExcel() {
      this.exportExcelLoader = true;

      try {
        await this.USERSNEW_exportUsersExcel(
          this.selectedUsers.map((u) => u.clientUserId)
        );
        this.selectedUsers = [];
      } catch (e) {
        console.log(e);
      }

      this.exportExcelLoader = false;
    },
  },
  watch: {
    "usersParams.search"() {
      clearTimeout(this.timeout);

      if (this.loader) return;

      this.timeout = setTimeout(() => {
        this.USERSNEW_setUsersParams({
          page: 1,
        });

        this.onGetUsers();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.bullet {
  &:not(:last-of-type) {
    margin-right: 4px;
  }

  &.small {
    height: 16px;
    width: 16px;
  }
}
</style>
